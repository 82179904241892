import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { UserHelper } from 'src/app/core/user-helper';
import { CustomSliderPanelComponent } from 'src/app/shared/custom-slider-panel/custom-slider-panel.component';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';
import { Constants } from 'src/constants';
import { FormAddTeamComponent } from '../form-add-team/form-add-team.component';

@Component({
  selector: 'app-team-list-table',
  templateUrl: './team-list-table.component.html',
  styleUrls: ['./team-list-table.component.scss'],
})
export class TeamListTableComponent implements OnInit, OnChanges {
  @ViewChild('add_team_panel', { static: false }) addTeamPanel: CustomSliderPanelComponent;
  @ViewChild('add_team_form', { static: false }) addTeamForm: FormAddTeamComponent;
  @ViewChild('edit_team_panel', { static: false }) editTeamPanel: CustomSliderPanelComponent;
  @ViewChild('edit_team_form', { static: false }) editTeamForm: FormAddTeamComponent;

  @Input() hasUpdatePermission: boolean;
  @Input() vendorNumber?: string;
  @Output() assignedVendorTeam = new EventEmitter<JMOBJ.VendorTeam>();

  vendorTeamList: JMOBJ.VendorTeam[] = [];
  selectedTeam?: JMOBJ.VendorTeam;
  routeTeamId: string; // TBC: Not sure routeTeamId using
  routeTeamName: string = ''; // TBC: Not sure routeTeamName using

  //Team List Table
  tablexParam: {} = {};
  tablexFilter: {} = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize: number = 10;
  currentPage: number = 1;
  pageCount: number = 1;
  allColHeaders: any = [];
  selectedRows: any = [];
  selectedTableRows: any = [];

  protected route: ActivatedRoute;

  constructor(injector: Injector) {
    this.route = injector.get(ActivatedRoute);
  }

  ngOnInit(): void {
    this.routeTeamId = this.route.snapshot.paramMap.get('teamId');
    this.routeTeamName = this.route.snapshot.paramMap.get('teamName');

    this.initTableHeader();
    this.initTablex();
  }

  // ----------- relaod ----------- //
  ngOnChanges(changes: SimpleChanges): void {
    this.reloadTeamListTable();
  }

  private reloadTeamListTable() {
    if (this.vendorNumber) {
      this.requestVendorTeamListByVendor();
    } else {
      this.vendorTeamList = [];
      this.resetTable();
      this.renderTable();
    }
  }

  // ----------- API ----------- //
  private async requestVendorTeamListByVendor() {
    if (!UserHelper.hasPermission(JMENUM.VpPermission.TEAM_VIEW)) {
      return;
    }

    let request = new JM.JMRequestContractsGetVendorTeamListByVendor();
    request.vendorNumber = this.vendorNumber;
    request.parameters = ['_id', 'name', 'email', 'phone', 'fax', 'contractList', 'vendorNumber'];
    request.pageSize = this.currentPageSize;
    request.pageNumber = this.currentPage;
    request.filter = this.tablexFilter;

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseContractsGetVendorTeamListByVendor = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.vendorTeamList = response.payload.records;
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
    this.renderTable();

    // if (this.routeTeamId && this.vendorTeamList.length > 0) {
    //   this.routeTeamName = this.vendorTeamList[0]['name'];
    // }
  }

  private async requestRemoveVendorTeam(vendorTeamId: string) {
    let request = new JM.JMRequestContractsRemoveVendorTeam();
    request.id = vendorTeamId;

    const response: JM.JMResponseContractsRemoveVendorTeam = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.settings-team.deleted-team'));
    this.requestVendorTeamListByVendor();
  }

  // ----------- UI function ----------- //
  onClearTeamName() {
    this.routeTeamId = undefined;
    this.requestVendorTeamListByVendor();
  }

  onClickAddTeamButton() {
    this.addTeamForm.initTeamCreationForm();
    this.addTeamPanel.toggle();
  }

  onDeleteTeamButton(team: JMOBJ.VendorTeam) {
    let button = {
      msg: {
        content: 'popup.confirm-to-delete',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestRemoveVendorTeam(team._id);
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(button);
  }

  onEditTeamButton(vendorTeam: JMOBJ.VendorTeam) {
    this.editTeamForm.initTeamEditionForm(vendorTeam._id);
    this.editTeamPanel.toggle();
  }

  onAddedVendorTeam(vendorTeam: JMOBJ.VendorTeam) {
    this.requestVendorTeamListByVendor();
    this.addTeamPanel.close();
  }

  onUpdatedVendorTeam(vendorTeam: JMOBJ.VendorTeam) {
    this.requestVendorTeamListByVendor();
    this.editTeamPanel.close();
  }

  // ----------- Tablex UI function ----------- //
  onRowSelected = (index, row) => {
    if (!this.vendorTeamList) {
      return;
    }
    this.selectedTeam = this.vendorTeamList.find((team) => team._id == row[0]);
    this.assignedVendorTeam.emit(this.selectedTeam);
  };

  onRowUnselected = (index, row) => {
    this.selectedTeam = undefined;
    this.assignedVendorTeam.emit(this.selectedTeam);
  };

  onFilterChanged = (event, index, header, filter) => {
    for (let key in filter) {
      if (!filter[key]) {
        delete this.tablexFilter[key];
      } else {
        this.tablexFilter[key] = filter[key];
      }
    }
    this.currentPage = 1;
    this.resetTable();
    this.requestVendorTeamListByVendor();
  };

  onFilterClear = () => {
    this.tablexFilter = {};
    this.tablexParam['filter'] = {};
    this.resetTable();
    this.requestVendorTeamListByVendor();
  };

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.resetTable();
    this.requestVendorTeamListByVendor();
  };

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.resetTable();
    this.requestVendorTeamListByVendor();
  };

  private resetTable() {
    this.selectedTableRows = [];
    this.tablexParam['selectedRowIndex'] = undefined;
    this.tablexParam['highlightedRows'] = [];
    this.selectedTeam = undefined;
    this.assignedVendorTeam.emit(this.selectedTeam);
  }

  private renderTable() {
    this.tablexParam['content'] = this.vendorTeamList.map((team, index) => {
      let button = [];
      if (this.hasUpdatePermission) {
        button = [
          {
            id: 'edit-team-' + team._id,
            name: '',
            disable: false,
            title: 'Edit',
            class: 'btn',
            icon: 'fa-pencil-alt fas amber font-size-xl',
            onClicked: () => {
              this.onEditTeamButton(team);
            },
          },
          {
            id: 'delete-team-' + team._id,
            name: '',
            disable: false,
            title: 'Delete',
            class: 'btn',
            icon: 'fas fa-times red font-size-xl',
            onClicked: () => {
              this.onDeleteTeamButton(team);
            },
          },
        ];
      }
      const row = [team._id, team.name, team.email, team.phone, team.fax, button];

      return row;
    });
  }

  private initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableClearFilter: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      enableSort: false,
      minifyButton: true,
      tableRow: 'row',
      tableClass: 'team-list-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowSelected: this.onRowSelected,
      onRowUnselected: this.onRowUnselected,
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };

    this.initTableHeader();
  }

  private initTableHeader() {
    let enableAction = this.hasUpdatePermission ? 'col' : 'd-none';
    this.allColHeaders = [
      {
        id: 'objId',
        name: '',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'd-none ',
      },
      {
        id: 'name',
        name: 'pages.settings-team.table-column.team-name',
        enableSort: false,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'email',
        name: 'pages.settings-team.table-column.email',
        enableSort: false,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'phone',
        name: 'pages.settings-team.table-column.hotline',
        enableSort: false,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'fax',
        name: 'pages.settings-team.table-column.fax',
        enableSort: false,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'action',
        name: 'pages.settings-team.table-column.action',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Buttons,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: enableAction,
      },
    ];
  }
}
