<div class="wrapper">
  <app-nav [breadcrumbs]="breadcrumbs"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="content cm-task-view-content action-btn-padding">
    <div class="component-wrap row">
      <div class="col-12 col-xl-6">
        <app-cm-task-description class="d-block mb-5" [jobCard]="jobCard" [sn]="sn"></app-cm-task-description>
        <app-cm-client-info class="d-block mb-5"
          [jobCard]="jobCard"
          [sn]="sn"
        ></app-cm-client-info>
        <!-- <app-cm-equipment-info class="d-block mb-5" [jobCard]="jobCard" [sn]="sn"></app-cm-equipment-info> -->
      </div>

      <div class="col-12 col-xl-6">
        <app-cm-task-progress
          class="d-block mb-4"
          [jobCard]="jobCard"
          [sn]="sn"
          [contractSeverity]="contractSeverity"
        ></app-cm-task-progress>
        <app-cm-progress-details class="d-block mb-5" [jobCard]="jobCard" [sn]="sn"></app-cm-progress-details>
        <!-- Hidden additional information in Phrase 1 -->
        <app-cm-additional-info *ngIf="false" class="d-block mb-5" [jobCard]="jobCard" [sn]="sn">
        </app-cm-additional-info>
        <app-attachment-info #jobcardAttachmentDetailElem
          *ngIf="jobCard.attachments && jobCard.attachments.length > 0"
          [attachmentList]="jobCard.attachments"
          [currentPageMode]="pageMode"
        ></app-attachment-info>
      </div>
    </div>

    <!-- Action Sidebar -->
    <ng-container *ngIf="actionSidebar.dataList && actionSidebar.dataList.length > 0">
      <app-action-sidebar-v2 [actionButtonList]="actionSidebar.dataList" [isDisabled]="actionSidebar.isDisabled"
        [isLoading]="actionSidebar.isLoading" (clickedButton)="onClickedActionButton($event)">
      </app-action-sidebar-v2>
    </ng-container>

    <!--CM task complete editor -->
    <app-custom-slider-panel #cm_task_complete_editor_panel [widthPx]=600
      [customClass]="'cm_task_complete_editor_panel'">
      <cm-task-complete-editor [sn]="sn" [jobCard]="jobCard" (updatedJobCard)="onCompletedJobCard($event)">
      </cm-task-complete-editor>
    </app-custom-slider-panel>

    <!--CM task refer -->
    <app-custom-slider-panel #cm_task_refer_editor_panel [widthPx]=600
      [customClass]="'cm_task_refer_editor_panel'">
      <cm-task-refer-editor [jobCard]="jobCard" (updatedJobCard)="onReferJobCard($event)">
      </cm-task-refer-editor>
    </app-custom-slider-panel>
  </div>

</div>