export enum TablexColumnType {
  Text = 'text',
  Html = 'html',
  Hyperlink = 'hyperlink',
  Buttons = 'buttons',
  MultiLine = 'multiLine',
  Input = 'input',
  CheckBox = 'checkbox',
  Icon = 'icon',
  DateTime = 'dateTime',
  BorderLabel = 'borderLabel',
  BorderLabelAddButton = 'borderLabelAddButton',
}

export enum TablexColumnHorizontalAlign {
  Center = 'text-center',
  Left = 'text-left',
  Right = 'text-right',
}

export enum TablexColumnVerticalAlign {
  Baseline = 'align-baseline',
  Top = 'align-top',
  Middle = 'align-middle',
  Bottom = 'align-bottom',
  TextTop = 'align-text-top',
  TextBottom = 'align-text-bottom',
}

export enum TablexColumnFilterType {
  Text = 'text',
  Dropdown = 'dropdown',
}

export class TablexColumnFilterOption {
  change: Function = undefined;
  onScrollToEnd: Function = undefined;
  onSearch: Function = undefined;
  onClear: Function = undefined;
  searchFn: Function = undefined;

  isLoading: boolean = undefined;

  multiple: boolean = undefined;
  placeholder: string = undefined;

  items: {}[] = undefined;
  bindValue: string = undefined;
  bindLabel: string = undefined;

  selectedValue = undefined; // string or string[]

  constructor() {
    this.change = () => {};
    this.onScrollToEnd = () => {};
    this.onSearch = () => {};
    this.onClear = () => {};
    this.searchFn = () => {};
    this.isLoading = undefined;
    this.multiple = false;
    this.placeholder = '';
    this.items = [];
    this.bindValue = undefined;
    this.bindLabel = undefined;
    this.selectedValue = undefined;
  }
}
