<div class="tab-menu-wrap mb-4">
    <ng-container *ngFor="let item of tabMenuItemList" >
        <button *ngIf="item.isEnabled" 
            id="{{item.id}}" 
            class="tab-menu-button btn m-2"
            [ngClass]="{'selected': item.isSelectedTab, 'unselected': !item.isSelectedTab}"
            (click)="onItemClicked(item)">
            {{ item.title  | translate }}
        </button>
    </ng-container>
</div>
<!-- <hr class="underline mb-4" /> -->