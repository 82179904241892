import { Component, Input, OnInit } from '@angular/core';
import { JM, JMENUM, JMOBJ, JMREASON } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Constants } from '../../../../constants';
import { trimString } from '../../../core/Formatter';
import { InputControl } from '../../../shared/form.model';
import { Session } from 'src/app/core/session';

@Component({
  selector: 'app-cm-progress-details',
  templateUrl: './cm-progress-details.component.html',
  styleUrls: ['./cm-progress-details.component.scss'],
})
export class CmProgressDetailsComponent implements OnInit {
  @Input() jobCard: JMOBJ.JobCard;
  @Input() sn: JMOBJ.ServiceNotification;
  @Input() pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.VIEW;

  // input
  contractorRemarksInput = new CmTaskContractorRemarksInput();

  constructor() {}

  ngOnInit(): void {}

  //------------------------
  // for parent component using
  public setCreateStandaloneCmTaskData(request: JM.JMRequestSnCreateStandaloneCmTask) {
    const contractorRemarks = this.contractorRemarksInput.value;
    request.contractorRemarks = contractorRemarks && !!contractorRemarks.trim() ? contractorRemarks.trim() : undefined;
  }

  public setUpdateCmTaskData(request: JM.JMRequestJobCardsUpdateCmTask) {
    const contractorRemarks = this.contractorRemarksInput.value;
    request.contractorRemarks = contractorRemarks && !!contractorRemarks.trim() ? contractorRemarks.trim() : null;
  }

  public setValuesInEditMode(jobCard: JMOBJ.JobCard) {
    if (jobCard.contractorRemarks) {
      this.contractorRemarksInput.value = this.jobCard.contractorRemarks;
    }
  }

  public resetFields() {
    this.resetInput();
  }

  public validateMandatoryFields(): boolean {
    this.setAllFieldsNoError();
    return !this.isMissingMandatoryField();
  }

  public validateData(): boolean {
    this.setAllFieldsNoError();

    // checking - mandatory fields
    if (this.isMissingMandatoryField()) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.missing-mandatory-fields'));
      return false;
    }

    return true;
  }

  //------------------------
  // checking function
  private isMissingMandatoryField(): boolean {
    let hasError = false;

    if (this.contractorRemarksInput.isMandatory && !trimString(this.contractorRemarksInput.value)) {
      this.contractorRemarksInput.isError = true;
      hasError = true;
    }

    return hasError;
  }

  //------------------------
  // reset function
  private resetInput() {
    this.resetProgressInput();
  }

  private resetProgressInput() {
    this.contractorRemarksInput.isDisabled = false;
    this.contractorRemarksInput.isError = false;
    this.contractorRemarksInput.isMandatory = false;
    this.contractorRemarksInput.value = undefined;
  }

  private setAllFieldsNoError() {
    this.contractorRemarksInput.isError = false;
  }

  //------------------------
  // UI function
  onChangeReportByContractor() {
    if (this.contractorRemarksInput.value) {
      this.contractorRemarksInput.value = this.contractorRemarksInput.value.trim();
    }
  }

  //------------------------
  // get property
  get isCreateMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.CREATE;
  }
  get isEditMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.EDIT;
  }
  get isViewMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.VIEW;
  }
  get isCompletedStatus(): boolean {
    return this.jobCard && this.jobCard.status === JMENUM.JobCardStatus.COMPLETED;
  }
  get isSubmittedForCompletionStatus(): boolean {
    return this.jobCard && this.jobCard.status === JMENUM.JobCardStatus.SUBMITTED_FOR_COMPLETION;
  }
  get isRejectedStatus(): boolean {
    return this.jobCard && (this.jobCard.status === JMENUM.JobCardStatus.REJECTED || this.jobCard.status === JMENUM.JobCardStatus.REWORKING);
  }

  get textAreaMaxLength(): number {
    return Constants.TEXTAREA_MAX_LENGTH;
  }

  get completeReasonString(): string {
    if (!this.jobCard || !this.jobCard.completeCode) {
      return '';
    }
    const description = JMREASON.JOB_COMPLETE_REASON_LIST.getDescription(this.jobCard.completeCode);
    const reason =
      description && description[Session.selectedLanguage] ? `[${description[Session.selectedLanguage]}] ` : '';
    const remark = this.jobCard.completeRemarks ? this.jobCard.completeRemarks : '';
    return `${reason}${remark}`;
  }

  get followUpReasonString(): string {
    if (!this.jobCard || !this.jobCard.followUpReasonCode) {
      return '';
    }
    const description = JMREASON.JOB_FOLLOW_UP_REASON_LIST.getDescription(this.jobCard.followUpReasonCode);
    return description && description[Session.selectedLanguage] ? `${description[Session.selectedLanguage]}` : '';
  }

  get rejectReasonString(): string {
    if (!this.jobCard || !this.jobCard.rejectRemarks) {
      return '';
    }
    // const description = JMREASON.JOB_REJECT_REASON_LIST.getDescription(this.jobCard.rejectCode);
    // const reason =
      // description && description[Session.selectedLanguage] ? `[${description[Session.selectedLanguage]}] ` : '';

    // const remark = this.jobCard.rejectRemarks ? this.jobCard.rejectRemarks : '';
    // return `${reason}${remark}`;
    return this.jobCard.rejectRemarks;
  }
}

class CmTaskContractorRemarksInput extends InputControl {
  value: string;

  constructor() {
    super();
  }
}
