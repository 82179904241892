<div class="font-size-xl m-0">
  {{ "component.pm-plan-particulars.title" | translate}}
</div>
<hr class="underline" class="mt-1 mb-3" />

<div class="row mx-0 align-items-center">
  <div class="col-4 col-sm-2 text-sm-right gray-title m-0 mb-4 px-0 pl-1">
    {{ (plan.planCoverage === "location" ? "component.pm-plan-particulars.number-of-locations" : "component.pm-plan-particulars.number-of-equipment") | translate }}
  </div>
  <div *ngIf="!editable?.equipmentCount" class="col-8 col-sm-4 text-left m-0 mb-4 overflow-auto">
    <app-display-text-field id="plan-part-equipment-count" [emptyHandleDisplay]="" [ngClass]="{'input-error':errorFields?.equipmentCount}"
      [textField]="plan?.minPeriodEquipmentQty == plan?.maxPeriodEquipmentQty ? plan?.minPeriodEquipmentQty: plan?.minPeriodEquipmentQty + ' - ' + plan?.maxPeriodEquipmentQty">
    </app-display-text-field>
    <ng-container *ngIf="plan.planCoverage == PlanCoverage.EQUIPMENT && plan.status == PMStatus.DRAFT ">
      <ng-container *ngIf="hasUpdatePermission">
        (<a href="/pm/plans/{{plan.pmPlanNumber}}/equipment-list">{{ "component.pm-plan-particulars.detail" | translate }}</a>)
      </ng-container>
      <ng-container *ngIf="!hasUpdatePermission">
        (<a href="/pm/plans/{{plan.pmPlanNumber}}/equipment-list-view">{{ "component.pm-plan-particulars.detail" | translate }}</a>)
      </ng-container>
    </ng-container>
  </div>

  <div class="col-4 col-sm-2 text-sm-right gray-title m-0 mb-4 px-0 pl-1">
    <span class="mandatory-mark">*</span>
    {{ "component.pm-plan-particulars.handling-team" | translate }}
  </div>
  <div *ngIf="!editable?.vendorTeam" class="col-8 col-sm-4 text-left m-0 mb-4 overflow-auto">
    <app-display-text-field
      id="plan-part-handling-team"
      [textField]="plan?.vendorTeam?.name"
      [ngClass]="{'input-error':errorFields?.vendorTeam}"
    >
    </app-display-text-field>
  </div>
  <div *ngIf="editable?.vendorTeam" class="col-8 col-sm-4 col-8 text-left m-0 mb-4">
    <ng-select
      id="edit-plan-handling-team"
      class="px-0"
      bindLabel="label"
      bindValue="value" 
      [class.input-error]="errorFields?.vendorTeam"
      [items]="teamOptions" 
      [loading]="isLoadingTeam"
      [disabled]="isDisabledTeam"
      (scrollToEnd)="onTeamScrollToEnd()"
      (clear)="onClearTeam()"
      (search)="onSearchTeam($event)"
      (change)="onChangeTeam($event)"
      [(ngModel)]="handlingTeam" >
    </ng-select>
  </div>
</div>
