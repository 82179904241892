import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { stringToNgbDate } from 'src/app/core/Formatter';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { DateInputI } from './date-input.model';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
})
export class DateInputComponent implements OnInit, OnChanges {
  @ViewChild('customDatePicker', { static: false }) customDatePicker: any;

  @Input() customClass?: string;
  @Input() alertMsg?: string;
  @Input() field?: string; // field name
  @Input() input: NgbDateStruct; // ngModel
  @Input() inputId: string; // id for HTML use
  @Input() isError: boolean = false;
  @Input() disabled? = undefined;
  @Input() isShowAlertMsg: boolean = false;

  @Output() updatedDate: EventEmitter<DateInputI> = new EventEmitter();

  isErrorField: boolean = false;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    this.isErrorField = this.isError;
  }

  //------------------------
  // event function
  formatDate(value: any) {
    if (!value) {
      this.isErrorField = true;
      this.showAlertToastMessage();
      this.emitUpdatedDate(undefined);
      return;
    }
    if (typeof value !== 'string') {
      this.formatDateByNgbDate(value);
      return;
    }

    if (typeof value === 'string') {
      this.formatDateByString(value);
      return;
    }
  }

  onBlurInput() {}

  onClickDatePicker(datepicker: any) {
    setTimeout(() => {
      document.getElementById(datepicker).focus();
    }, 100);
  }

  private formatDateByString(value: string) {
    value = value.replace(/-|\//g, '');

    const isValidDate = moment(value, 'YYYYMMDD', true).isValid();
    const isValidUnixYear = moment(value).year() >= 1970;

    if (isValidDate && isValidUnixYear) {
      const ngbDate = stringToNgbDate(value);

      this.isErrorField = false;
      this.customDatePicker.navigateTo(ngbDate);
      this.emitUpdatedDate(ngbDate);
    } else {
      this.isErrorField = true;
      this.showAlertToastMessage();
      this.emitUpdatedDate(undefined);
    }
  }

  private formatDateByNgbDate(ngbDate: NgbDate) {
    const isValidUnixYear = ngbDate.year && ngbDate.year >= 1970;

    if (isValidUnixYear) {
      this.isErrorField = false;
      this.customDatePicker.navigateTo(ngbDate);
      this.emitUpdatedDate(ngbDate);
    } else {
      this.isErrorField = true;
      this.showAlertToastMessage();
      this.emitUpdatedDate(undefined);
    }
  }

  private emitUpdatedDate(ngbDate?: NgbDateStruct) {
    this.updatedDate.emit({
      fieldName: this.field,
      date: ngbDate,
    });
  }

  private showAlertToastMessage() {
    if (this.isShowAlertMsg) {
      const msg = this.alertMsg ? this.alertMsg : JMLanguage.translate('global.invalid-date');
      AppDelegate.toastMsg().showMsg(msg);
    }
  }
}
