import { Component, Injector, OnInit } from '@angular/core';
import { JM } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/core/session';
import * as APP from 'src/app/core/AppDelegate';
import { Constants } from 'src/constants';
import { environment } from 'src/environments/environment';
import { JMLanguage, Language } from 'src/app/core/JMLanguage/JMLanguage';
import { BasePage } from '../../core/base';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BasePage implements OnInit {
  systemVersion: string;

  rememberMe: boolean = false;
  loginForm: any = {};
  isLoggingIn: boolean = false;

  errorFields: any = {};
  loginFailed: boolean = false;
  showPassword: boolean = false;

  latestNewsMessage: string;
  latestNewsCreationTime: Date;
  uiIOSDownloadLink: string = environment.IOS_DOWNLOAD_LINK;
  uiAndroidDownloadLink: string = environment.ANDROID_DOWNLOAD_LINK;

  supportInfo: any;

  languageOptions: {
    value: string;
    label: string;
  }[] = undefined;

  uiSelectedLanguage: Language = undefined;

  constructor(private injector: Injector) {
    super(injector, false);
  }

  ngOnInit() {
    if (Session.userToken) {
      APP.AppDelegate.routeToPage('/home', null, {
        replaceUrl: true,
      });
    }

    this.uiSelectedLanguage = JMLanguage.getCurrentLanguage();
    this.languageOptions = [
      {
        value: Language.EN,
        label: 'English',
      },
      { value: Language.ZH, label: '中文' },
    ];

    if (Session.rememberUsername) {
      this.loginForm.username = Session.rememberUsername;
      this.rememberMe = true;
    }

    this.systemVersion = Constants.SYSTEM_VERSION;
    this.supportInfo = Constants.SUPPORT_INFO;

    // get latest news
    this.requestLatestNews();
  }

  //-------------------------------
  // request function
  requestLatestNews() {
    let request = new JM.JMRequestSystemConfigsLatestNews();
    request.pageSize = 1;
    request.pageNumber = 1;
    request.appname = Constants.SYSTEM_NAME;

    JM.JMConnector.sendSystemConfigsLatestNews(request, (error, result: JM.JMResponseSystemConfigsLatestNews) => {
      if (error) {
        return;
      }

      if (!result || !result.code || result.code != 200 || !result.payload) {
        if (result.code === 99503) {
          const redirUrl = result['vp-url'];
          window.location = redirUrl;
        }
        return;
      }

      if (!result.payload.records || !result.payload.records.length) {
        return;
      }

      this.latestNewsMessage = result.payload.records[0].message;
      this.latestNewsCreationTime = result.payload.records[0].createdAt;
    });
  }

  //-------------------------------
  // callback function
  onLanguageChanged() {
    JMLanguage.changeLanguage(this.uiSelectedLanguage);
    Session.setSelectedLanguage(this.uiSelectedLanguage);
    window.location.reload();
  }

  //-------------------------------
  onLoginClicked() {
    this.errorFields = {};
    this.loginFailed = false;
 
    // for remember me use
    const username = this.loginForm.username;

    if (this.checkInputValid()) {
      this.isLoggingIn = true;
      APP.AppDelegate.login(this.loginForm.username, this.loginForm.password, (response, error) => {
        this.isLoggingIn = false;
        if (!response || response.code !== 200) {
          this.loginFailed = true;
        } else {
          Session.setRememberUsername(this.rememberMe ? username : '');
        }
      });
    } else {
      if (!this.loginForm.username) {
        this.errorFields.username = true;
      }

      if (!this.loginForm.password) {
        this.errorFields.password = true;
      }

      APP.AppDelegate.toastMsg().showMsg(JMLanguage.translate('page.login.toast.wrong-input'));
    }
  }

  onShowPasswordBtnClicked() {
    this.showPassword = !this.showPassword;
  }

  checkInputValid() {
    this.loginForm.username = this.loginForm.username ? this.loginForm.username.trim() : null;
    this.loginForm.password = this.loginForm.password ? this.loginForm.password.trim() : null;

    if (!this.loginForm.username || !this.loginForm.password) {
      return false;
    }

    return true;
  }
}