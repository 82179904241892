<div class="login-page-wrap">
  <div class="container page-container d-flex justify-content-center text-center px-md-5 px-3">
    <div class="row">
      <div class="login-page-left-wrap col-12 col-lg-6">
        <div class="login-wrap text-left d-flex flex-column justify-content-between">
          <div class="login-header mb-4 text-center ">{{ 'system.title' | translate }}</div>
          <form class="login-input-wrap">
            <div class="input-wrap d-flex align-items-center mb-3 mx-auto">
              <i class="fas fa-user-circle input-icon"></i>
              <input id="login-username-input" type="text" class="form-control mb-0" [ngClass]="{'input-error': errorFields.username}" [(ngModel)]="loginForm.username" placeholder="{{'page.login.username-placeholder' | translate}}" [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="input-wrap d-flex align-items-center mb-2 mx-auto">
              <i class="fas fa-star-of-life input-icon"></i>
              <input id="login-password-input" class="form-control mb-0" [type]="showPassword ? 'text' : 'password'" [ngClass]="{'input-error': errorFields.password}" [(ngModel)]="loginForm.password" placeholder="{{'page.login.password-placeholder' | translate}}" [ngModelOptions]="{standalone: true}" />
              <button id="login-show-password-button" type="button" class="btn-show-password" (click)="onShowPasswordBtnClicked()">
                <i class="fas" [ngClass]="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
              </button>
            </div>
            
            <div class="mb-md-4 mb-5 custom-control custom-checkbox">
              <input id="login-remember-me-checkbox" type="checkbox" [(ngModel)]="rememberMe" [ngModelOptions]="{standalone: true}" class="custom-control-input"/>
              <label class="custom-control-label" for="login-remember-me-checkbox">{{ 'page.login.remember-username' | translate }}</label>
            </div>

            <div>
              <div *ngIf="loginFailed" class="error-msg-label text-right red">{{ 'page.login.error-msg.login-failed' | translate }}</div>
            </div>

            <div class="row px-0 mx-0 align-items-center justify-content-between">
              <div class="px-0">
                <ng-select id="id_ng-select_language" 
                  [items]="languageOptions"
                  bindValue="value"
                  bindLabel="label"
                  [ngModelOptions]="{standalone: true}"
                  [clearable]="false"
                  [(ngModel)]="uiSelectedLanguage"
                  (change)="onLanguageChanged()"
                >
                </ng-select>
              </div>
              <div class=" px-0">
                <button id="login-button" class="standard brand-blue" [disabled]="isLoggingIn" (click)="onLoginClicked()">
                  <div *ngIf="isLoggingIn" class="d-inline-block loading-spinner spinner-small">
                    {{ 'global.loading' | translate }}
                  </div>
                  <ng-container *ngIf="!isLoggingIn">
                      <i class="fa fa-sign-in mr-2"></i>{{ 'page.login.button.login' | translate }}
                  </ng-container>
                </button>
              </div>
            </div>
          </form>
  
        </div>

        <div class="row pt-3 pb-2 ml-1">
          <span id="sys-version">{{ 'page.login.version' | translate }} {{ systemVersion }}</span>
        </div>

        <div class="row justify-content-between download-btn-wrap m-0">
          <div class="col pl-0">
            <a id="login_ios-app_button" class="download-btn ios-btn" [attr.href]="uiIOSDownloadLink | safe :'resourceUrl'">
              <img src="/assets/svg/download_button_ios.svg">
            </a>
          </div>
          <div class="col pr-0">
            <a id="login_android-app_button" class="download-btn android-btn" href="{{ uiAndroidDownloadLink }}">
              <img src="/assets/svg/download_button_android.svg">
            </a>
          </div>
        </div>
      </div>
  
      <div class="col-12 col-lg-6 news-wrap text-left d-block d-lg-inline-block pt-3">
        <div>
          <h4>{{ 'page.login.news-title' | translate }}</h4>
          <div *ngIf="latestNewsMessage" [innerHTML]="latestNewsMessage | safe: 'html'"></div>
          <span *ngIf="latestNewsCreationTime" class="text-right d-block">{{ latestNewsCreationTime | date:'dd/MM/yyyy HH:mm' }}</span>
        </div>
        <!--Contact Information-->
        <div class="support-info pt-4">
          <p><i class="fas fa-headphones pr-2"></i>{{ 'pages.login.system-support' | translate }}</p>
          <p><i class="fas fa-envelope pr-2"></i>{{ supportInfo.ldapId }}</p>
          <p><i class="fas fa-envelope pr-2"></i>{{ supportInfo.email }}</p>
          <p><i class="fas fa-phone pr-2"></i>{{ supportInfo.tel }}</p>
        </div>
      </div>
    </div>

  </div>
</div>