<div [ngClass]="{'edit-mode': enableEditReminderSession}">
  <div class="font-size-xl m-0">
    {{ "component.pm-plan-reminders.title" | translate}}
  </div>

  <hr class="underline" class="mt-1 mb-3" />

  <div class="row mx-0 col-12 reminder-item p-3 mb-2 justify-content-between align-items-center">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="to-be-overdue-reminder_checkbox"
        [(ngModel)]="toBeOverhaulActiveStatus" [disabled]="!enableEditReminderSession"
        (ngModelChange)="toggleActiveStatus()">
      <label class="custom-control-label" for="to-be-overdue-reminder_checkbox">
      </label>
      <div class="d-inline-block px-0 ml-15">{{ "component.pm-plan-reminders.to-be-overdue-reminder" | translate }}
      </div>
    </div>
    <div class="d-inline-flex">
      <div class="mx-4">
        {{ componentPlanRemindersIn }}
        <input #toBeOverhaulDaysEle type="number" class="days-input form-control d-inline px-1 disable-gray-text" *ngIf="pageMode !== JMPageMode.VIEW"
          [(ngModel)]="toBeOverhaulDays" min="0" [class.input-error]="errorFields.toBeOverhaulDays"
          (ngModelChange)="onChangeIntervalAndCheckMin('toBeOverhaulDays', toBeOverhaulDaysEle, 0)" [disabled]="!toBeOverhaulActiveStatus || !enableEditReminderSession"/>
        <span id="to-be-overhaul-days" *ngIf="plan?.vpToBeOverdueReminder && (pageMode === JMPageMode.VIEW)" class="px-2">
          {{ plan.vpToBeOverdueReminder.toBeDueDay }}
        </span>
        {{ "component.pm-plan-reminders.days-to-overdue" | translate }}
      </div>
      <div>
        {{ "component.pm-plan-reminders.send-every" | translate }}
        <input #toBeOverhaulIntervalEle type="number" class="days-input form-control d-inline px-1 disable-gray-text" *ngIf="pageMode !== JMPageMode.VIEW"
          [(ngModel)]="toBeOverhaulInterval" min="0" [class.input-error]="errorFields.toBeOverhaulInterval"
          (ngModelChange)="onChangeIntervalAndCheckMin('toBeOverhaulInterval', toBeOverhaulIntervalEle, 1)" [disabled]="!toBeOverhaulActiveStatus || !enableEditReminderSession"/>
        <span id="to-be-overdue-interval" *ngIf="plan?.vpToBeOverdueReminder && (pageMode === JMPageMode.VIEW)"
          class="px-2">
          {{ plan.vpToBeOverdueReminder.interval }}
        </span>
        {{ "component.pm-plan-reminders.days" | translate }}
      </div>
    </div>
  </div>

  <div class="row mx-0 col-12 reminder-item p-3 justify-content-between align-items-center">
    <div class="custom-control custom-checkbox">
      <input type="checkbox" class="custom-control-input" id="overdue-period-reminder_checkbox"
        [(ngModel)]="overdueActiveStatus" [disabled]="!enableEditReminderSession"
        (ngModelChange)="toggleActiveStatus()">
      <label class="custom-control-label" for="overdue-period-reminder_checkbox">
      </label>
      <div class="d-inline-block px-0 ml-15">{{ "component.pm-plan-reminders.overdue-period-reminder" | translate }}
      </div>
    </div>
    <div class="d-inline-flex">
      <div>
        {{ "component.pm-plan-reminders.send-every" | translate }}
        <input #overdueIntervalEle type="number" class="days-input form-control d-inline px-1 disable-gray-text" *ngIf="pageMode !== JMPageMode.VIEW"
          [(ngModel)]="overdueInterval" min="0" [class.input-error]="errorFields.overdueInterval"
         (ngModelChange)="onChangeIntervalAndCheckMin('overdueInterval', overdueIntervalEle, 1)" [disabled]="!overdueActiveStatus || !enableEditReminderSession"/>
        <span id="overdue-interval" *ngIf="plan?.vpOverduePeriodReminder && (pageMode === JMPageMode.VIEW)" class="px-2">
          {{ plan.vpOverduePeriodReminder.interval }}
        </span>
        {{ "component.pm-plan-reminders.days" | translate }}
      </div>
    </div>
  </div>

  <div class="row mx-0 align-items-center ">
    <div class="col-3 col-sm-2 text-left text-sm-right  gray-title m-0 mt-4 mb-4 px-0 pl-1">
      <span class="mandatory-mark" *ngIf="plan && plan.contract?.contractNumber && (overdueActiveStatus || toBeOverhaulActiveStatus)">*</span>
      {{ "component.pm-plan-reminders.recipients" | translate }}
    </div>
    <div *ngIf="!editable?.reminderRecipients" class="col-9 col-sm-10 text-left m-0 mt-4 mb-4 overflow-auto">
      <app-display-text-field id="plan-reminders-recipients" [textFieldArray]="plan?.vpReminderRecipients || []">
      </app-display-text-field>
    </div>
    <div *ngIf="editable?.reminderRecipients" class="col-9 col-sm-10 text-left m-0 ">
      <ng-select id="edit-plan-recipients"  [multiple]="true" [addTag]="recipientAddFunction" [class.input-error]="errorFields?.reminderRecipients"
        [loading]="isLoadingPost" [disabled]="isDisabledRecipients || (!overdueActiveStatus && !toBeOverhaulActiveStatus) || !enableEditReminderSession"
        (scrollToEnd)="onPostScrollToEnd()" (search)="onSearchPost($event)" [(ngModel)]="plan.vpReminderRecipients" (clear)="clearRecipientFilter()" (open)="clearRecipientFilter()">
        <ng-option *ngFor="let recipient of recipientsOptions" [value]="recipient.value || recipient" >{{recipient.label || recipient}}</ng-option>
      </ng-select>
    </div>
  </div>
</div>