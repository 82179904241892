import { Component, OnInit, ViewChild } from '@angular/core';
import { JM, JMOBJ, JMENUM } from '@ccep/CCEPConnector-ts';
import { VPUserSettings } from '@enum/vp-user-settings.enum';
import { UserHelper } from 'src/app/core/user-helper';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Constants } from 'src/constants';
import { ContractListComponent } from '../../pages/components/contract-list/contract-list.component';
import { TeamListTableComponent } from '../shared/team-list-table/team-list-table.component';
import { TeamMemberTableComponent } from '../shared/team-member-table/team-member-table.component';

@Component({
  selector: 'app-settings-team',
  templateUrl: './settings-team.component.html',
  styleUrls: ['./settings-team.component.scss'],
})
export class SettingsTeamComponent implements OnInit {
  // @ViewChild('contract_list', { static: false }) contractListElem: ContractListComponent; // TODO: hide contract List table
  @ViewChild('team_list', { static: false }) teamListElem: TeamListTableComponent;
  @ViewChild('member_list', { static: false }) memberListElem: TeamMemberTableComponent;

  hasUpdatePermission: boolean = false;

  selectedTeam?: JMOBJ.VendorTeam;
  contractListParam = {};

  selectedVendorNumber?: string;
  vendorList: JMOBJ.Vendor[] = [];
  isLoadingVendorList = false;

  private vendorSearch: {
    keywords: string;
    search$: Subject<any[]>;
    totalPageNumber: number;
    pageNumber: number;
    pageSize: number;
  } = {
    search$: new Subject<any[]>(),
    keywords: '',
    totalPageNumber: 0,
    pageNumber: 1,
    pageSize: 100,
  };

  constructor() {}

  ngOnInit(): void {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }
    this.hasUpdatePermission = UserHelper.hasPermission(JMENUM.VpPermission.TEAM_UPDATE);
    this.contractListParam['userSettingListName'] = VPUserSettings.VP_TEAM_SETTING_CONTRACT_LIST_COLUMNS;

    this.requestVendorSummary(false);
    this.setVendorSearchObserver();
  }

  // ----------- API ----------- //
  private async requestVendorSummary(isAppend: boolean = false) {
    let request = new JM.JMRequestContractsGetVendorList();
    request.parameters = ['vendorName', 'vendorNumber'];
    request.pageSize = this.vendorSearch.pageSize;
    request.pageNumber = this.vendorSearch.pageNumber;

    if (!!this.vendorSearch.keywords) {
      request.filter = {
        vendorName: this.vendorSearch.keywords,
      };
    }

    this.isLoadingVendorList = true;
    const response: JM.JMResponseContractsGetVendorList = await AppDelegate.sendJMRequest(request);
    this.isLoadingVendorList = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.vendorSearch.totalPageNumber = Math.ceil(response.payload.totalCount / request.pageSize);
    if (isAppend) {
      this.vendorList = this.vendorList.concat(response.payload.records);
    } else {
      this.vendorList = response.payload.records;

      if (this.vendorList.length) {
        this.selectedVendorNumber = response.payload.records[0].vendorNumber;
      }
    }
  }

  // ----------- UI event ----------- //
  onSelectedTeam(vendorTeam?: JMOBJ.VendorTeam) {
    this.selectedTeam = vendorTeam;

    this.reloadContractTable();
  }

  // hide contract table.
  private reloadContractTable() {
    // if (vendorTeam && this.contractListElem) {
    //   this.contractListElem.requestContractListByContract(vendorTeam.contractList);
    // }
  }

  // ----------- Vendor dropdown event ----------- //
  onVendorScrollToEnd() {
    if (this.vendorSearch.pageNumber < this.vendorSearch.totalPageNumber) {
      this.vendorSearch.pageNumber++;
      this.requestVendorSummary(true);
    }
  }

  onSearchVendor(event) {
    this.vendorSearch.keywords = event.term;
    this.vendorSearch.search$.next();
  }

  private clearContractOption() {
    this.vendorList = [];
    this.vendorSearch.pageNumber = 1;
    this.vendorSearch.totalPageNumber = 0;
  }

  private setVendorSearchObserver() {
    this.vendorSearch.search$.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.clearContractOption();
      this.requestVendorSummary();
    });
  }
}
