<div class="pm-equipment-list-search-criteria-container">
  <div class="d-flex justify-content-between mb-2 align-items-center">
    <div class="font-size-xl align-self-end">
      {{ "component.pm-equipment-list-search-criteria.title" | translate }}
    </div>

    <div class="d-flex justify-content-end">
      <button class="btn icon-btn brand-blue img-btn" (click)="onClickSearch()" [disabled]="currentErrorList.length">
        <div *ngIf="isSearching" class="d-flex align-items-center loading-spinner spinner-small blue"></div>
        <i class="fas fa-search white font-size-l"></i>
      </button>

      <button class="btn icon-btn ml-2 d-inline-block d-xl-none navbar-toggler" (click)="isCollapse = !isCollapse">
        <i class="fas fa-chevron-up" [class.d-none]="isCollapse"></i>
        <i class="fas fa-chevron-down" [class.d-none]="!isCollapse"></i>
      </button>
    </div>
  </div>
  <hr class="underline" class="underline" />

  <div class="navbar navbar-expand-xl px-0">
    <div id="search-field-wrap" class="row justify-content-between mx-0 collapse navbar-collapse align-items-start w-100"
      [ngbCollapse]="isCollapse">
      <form [formGroup]="criteriaForm" class="w-100">
        <div class="col-12 pl-0 pr-0 pr-md-3 pr-xl-0">
          <ng-select placeholder="{{'component.pm-equipment-list-search-criteria.contract-no' | translate }}"
            bindLabel="contractNumber" bindValue="contractNumber" [readonly]="false" [items]="contractInput.optionList"
            (search)="onContractSearch($event)" (clear)="onContractClear()" multiple="true"
            (scrollToEnd)="onContractScrollToEnd()" formControlName="contractNumberList">
          </ng-select>
          <ng-select class="mt-2" placeholder="{{'component.pm-equipment-list-search-criteria.plan-no' | translate }}"
            bindLabel="pmPlanNumber" bindValue="pmPlanNumber" [items]="pmPlanInput.optionList"
            (search)="onPmPlanSearch($event)" (clear)="onPmPlanClear()" (scrollToEnd)="onPmPlanScrollToEnd()"
            formControlName="pmPlanNumberList">
          </ng-select>

          <hr class="d-block d-xl-block" />

          <div class="mt-3">
            <div class="gray-40 mb-2">
              {{ "component.pm-equipment-list-search-criteria.status" | translate }}
            </div>
            <div class="d-inline-block">
              <label class="checkbox-tag brand-blue" [class.checked]="criteriaForm.get('isOutstanding').value">
                {{ "pm-equipment.status.outstanding" | translate }}
                <input type="checkbox" hidden="true" name="job-status" id="status-isOutstanding"
                  formControlName="isOutstanding" />
              </label>
            </div>
            <div class="d-inline-block">
              <label class="checkbox-tag brand-blue" [class.checked]="criteriaForm.get('isCompleted').value">
                {{ "pm-equipment.status.completed" | translate }}
                <input type="checkbox" hidden="true" name="job-status" id="status-isCompleted"
                  formControlName="isCompleted" />
              </label>
            </div>
          </div>

          <hr class="d-block d-xl-block" />

          <div class="col-12 col-md-6 col-xl-12  pr-0 pl-0 pl-md-3 pl-xl-0">
            <div class="d-flex d-md-block d-xl-flex align-items-start">
              <span class="d-inline d-md-block d-xl-inline gray-40 col-auto pl-0">
                {{ "component.pm-equipment-list-search-criteria.period-start-from" | translate }}
              </span>
            </div>
            <app-date-input [input]="criteriaForm.get('periodStartDateFrom').value" [inputId]="'periodStartDateFrom'"
              [field]="'periodStartDateFrom'" (updatedDate)="onBlurDateInput($event)">
            </app-date-input>
            <div class="d-flex d-md-block d-xl-flex align-items-start mt-2">
              <span class="d-inline d-md-block d-xl-inline gray-40 col-auto pl-0">
                {{ "component.pm-equipment-list-search-criteria.period-start-to" | translate }}
              </span>
            </div>
            <app-date-input [input]="criteriaForm.get('periodStartDateTo').value" [inputId]="'periodStartDateTo'"
              [field]="'periodStartDateTo'" (updatedDate)="onBlurDateInput($event)">
            </app-date-input>
          </div>
          <ng-container *ngIf="currentErrorList.includes('wrongStartDate')">
            <div class="red font-italic">
              {{ 'component.pm-equipment-list-search-criteria.error-msg.wrong-start-date'| translate }}
            </div>
          </ng-container>
          <div class="col-12 col-md-6 col-xl-12  pr-0 pl-0 pl-md-3 pl-xl-0">
            <div class="d-flex d-md-block d-xl-flex align-items-start mt-2">
              <span class="d-inline d-md-block d-xl-inline gray-40 col-auto pl-0">
                {{ "component.pm-equipment-list-search-criteria.period-end-from" | translate }}
              </span>
            </div>
            <app-date-input [input]="criteriaForm.get('periodEndDateFrom').value" [inputId]="'periodEndDateFrom'"
              [field]="'periodEndDateFrom'" (updatedDate)="onBlurDateInput($event)">
            </app-date-input>
            <div class="d-flex d-md-block d-xl-flex align-items-start mt-2">
              <span class="d-inline d-md-block d-xl-inline gray-40 col-auto pl-0">
                {{ "component.pm-equipment-list-search-criteria.period-end-to" | translate }}
              </span>
            </div>
            <app-date-input [input]="criteriaForm.get('periodEndDateTo').value" [inputId]="'periodEndDateTo'"
              [field]="'periodEndDateTo'" (updatedDate)="onBlurDateInput($event)">
            </app-date-input>
          </div>
          <ng-container *ngIf="currentErrorList.includes('wrongEndDate')">
            <div class="red font-italic">
              {{ 'component.pm-equipment-list-search-criteria.error-msg.wrong-end-date'| translate }}
            </div>
          </ng-container>
          <ng-container *ngIf="currentErrorList.includes('wrongDate')">
            <div class="red font-italic">
              {{ 'component.pm-equipment-list-search-criteria.error-msg.wrong-date'| translate }}
            </div>
          </ng-container>
          <hr class="d-block d-xl-block" />

          <input id="search-createdBy" class="form-control mb-2"
            placeholder="{{'component.pm-equipment-list-search-criteria.equipment-no' | translate}}"
            formControlName="equipmentNumber">

          <input id="search-createdBy" class="form-control mb-2"
            placeholder="{{'component.pm-equipment-list-search-criteria.equipment-desc' | translate}}"
            formControlName="equipmentDesc">

          <ng-select placeholder="{{'component.pm-equipment-list-search-criteria.location' | translate }}"
            bindLabel="label" bindValue="value"  [items]="locationOptions" (open)="clearLocationFilter()"
            (search)="onSearchLocation($event)" (clear)="clearLocationFilter()" [loading]="isLoadingLocation"
            (scrollToEnd)="onLocationScrollToEnd()" formControlName="locationCodeList">
          </ng-select>
          
          <ng-select class="mt-2" placeholder="{{'component.pm-equipment-list-search-criteria.client' | translate }}"
            bindLabel="label" bindValue="value" [items]="clientOptions" (open)="clearClientFilter()"
            (search)="onSearchClient($event)" (clear)="clearClientFilter()" [loading]="isLoadingClient"
            (scrollToEnd)="onClientScrollToEnd()" formControlName="clientList">
          </ng-select>
          <ng-select class="mt-2"
            placeholder="{{'component.pm-equipment-list-search-criteria.equipment-type' | translate }}"
            bindLabel="label" bindValue="value"  [items]="equipmentTypeOptionsFull"
              formControlName="equipmentType">
          </ng-select>

        </div>
      </form>
    </div>
  </div>

</div>
