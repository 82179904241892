import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent implements OnInit {
  @Input() id: string = '';
  @Input() color: string = '';
  @Input() icon: string = '';
  @Input() isDisabled: boolean = false;
  @Input() isLoading: boolean = false;

  @Output() onClick: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit() {}

  onClicked() {
    this.onClick.emit();
  }
}
