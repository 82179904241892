import { Component, Input, OnInit } from '@angular/core';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { UserHelper } from 'src/app/core/user-helper';
import { TabMenuItems } from 'src/app/shared/tab-menu/tab-menu-items';
import { TabMenuItem } from './tab-menu.model';
@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss'],
})
export class TabMenuComponent implements OnInit {
  @Input() contentType: string;
  @Input() activeTabId?: string;

  routeUrl: string = '';
  tabMenuItemList: TabMenuItem[] = [];

  constructor() {}

  ngOnInit() {
    this.routeUrl = AppDelegate.getRoute();
    this.generateDisplayItem();
  }

  private generateDisplayItem() {
    this.tabMenuItemList = [];
    const sourceItemList = TabMenuItems[this.contentType];

    if (sourceItemList && sourceItemList.length) {
      for (const sourceItem of sourceItemList) {
        if (sourceItem) {
          const isMatchActiveTabId = this.activeTabId && sourceItem.id === this.activeTabId;
          const isMatchRouteUrl = this.routeUrl === sourceItem.route;

          const tabMenuItem: TabMenuItem = {
            id: sourceItem.id,
            title: sourceItem.title,
            route: sourceItem.route,
            isSelectedTab: isMatchActiveTabId || isMatchRouteUrl,
            isEnabled: UserHelper.hasPagePermission(sourceItem.route),
          };

          this.tabMenuItemList.push(tabMenuItem);
        }
      }
    }
  }

  // ---------------------------
  onItemClicked(item: TabMenuItem) {
    if (item && item.route && !item.isSelectedTab) {
      AppDelegate.routeToPage(item.route);
    }
  }
}
