<div class="d-flex justify-content-between">
  <div class="font-size-xl">
    {{ 'component.cm-progress-details.title' | translate }}
  </div>
</div>
<hr class="underline" />

<div class="row mb-3 mx-0 align-items-center" *ngIf="isCreateMode || isEditMode">
  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    <span *ngIf="contractorRemarksInput.isMandatory" class="red">*</span>
    {{ 'component.progress-details.label.progress-reported-by-contractor' | translate }}
  </div>
  <div class="col-9 col-md-10 mt-4">
    <textarea id="edit-cm-progress-details-progress-reported-by-contractor" class="form-control" rows="3"
      [maxlength]="textAreaMaxLength" [class.input-error]="contractorRemarksInput.isError"
      [(ngModel)]="contractorRemarksInput.value" (change)="onChangeReportByContractor()"></textarea>
  </div>
</div>

<!-- Readonly fields -->
<div class="row mb-3 mx-0 align-items-center" *ngIf="isViewMode || isEditMode">
  <ng-container *ngIf="isViewMode">
    <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
      {{ 'component.progress-details.label.progress-reported-by-contractor' | translate }}
    </div>
    <div class="col-9 col-md-10 mt-4">
      <app-display-text-field id="cm-progress-details-progress-reported-by-contractor"
        [textField]="jobCard?.contractorRemarks">
      </app-display-text-field>
    </div>
  </ng-container>

  <ng-container *ngIf="!!followUpReasonString">
    <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
      {{ 'component.progress-details.label.follow-up-reason' | translate }}
    </div>
    <div class="col-9 col-md-10 mt-4">
      <app-display-text-field id="cm-progress-details-follow-up-reason" [textField]="followUpReasonString">
      </app-display-text-field>
    </div>
  </ng-container>

  <!-- TODO: TBC -->
  <ng-container *ngIf="false">
    <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label ">
      {{ 'component.progress-details.label.remarks' | translate }}
    </div>
    <div class="col-9 col-md-10 mt-4">
      <app-display-text-field id="cm-progress-details-remarks" [textField]="">
      </app-display-text-field>
    </div>
  </ng-container>

  <ng-container *ngIf="isSubmittedForCompletionStatus || isCompletedStatus">
    <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
      {{ 'component.progress-details.label.complete-reason' | translate }}
    </div>
    <div class="col-9 col-md-10 mt-4">
      <app-display-text-field id="cm-progress-details-complete-reason" [textField]="completeReasonString">
      </app-display-text-field>
    </div>
  </ng-container>

  <ng-container *ngIf="isRejectedStatus">
    <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
      {{ 'component.progress-details.label.reject-reason' | translate }}
    </div>
    <div class="col-9 col-md-10 mt-4">
      <app-display-text-field id="cm-progress-details-reject-reason" [textField]="rejectReasonString">
      </app-display-text-field>
    </div>
  </ng-container>





</div>