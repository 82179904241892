<div class="reset-password-container">
    <div class="login-page-box">
        <div class="header">
            <div class="title">{{ 'page.login.reset-password.title' | translate}}</div>
            <div class="system">{{ 'system.short-title' | translate}}</div>
        </div>
        <div class="content">
            <form [formGroup]="resetPasswordForm" (ngSubmit)="clickResetPasswordBtn()">
                <div class="input-row">
                    <label class="label" for="login_existing_password_inputbox">
                        {{'page.login.reset-password.existing-password' | translate}}
                    </label>
                    <input id="login_existing_password_inputbox" class="form-control {{invalidControlObj.existingPassword && 'invalid-control'}}"
                        placeholder="{{ 'page.login.reset-password.existing-password' | translate}}"
                        type="password" formControlName="existingPassword">
                </div>
                <div class="input-row">
                    <label class="label" for="login_new_password_inputbox">
                        {{'page.login.reset-password.new-password' | translate}}
                    </label>
                    <input id="login_new_password_inputbox" class="form-control {{invalidControlObj.newPassword && 'invalid-control'}}"
                        placeholder="{{ 'page.login.reset-password.new-password' | translate}}" type="password"
                        formControlName="newPassword">
                </div>
                <div class="input-row">
                    <label class="label" for="login_confirm_password_inputbox">
                        {{'page.login.reset-password.confirm-password' | translate}}
                    </label>
                    <input id="login_confirm_password_inputbox" class="form-control {{invalidControlObj.confirmPassword && 'invalid-control'}}"
                        placeholder="{{ 'page.login.reset-password.confirm-password' | translate}}" type="password"
                        formControlName="confirmPassword">
                </div>
                <div [ngClass]="{hidden: !showPasswordError}" class="mt-3">
                    <app-password-error-list #passwordErrorList>
                    </app-password-error-list>
                </div>
                <div class="submit-btn">
                    <button class="standard brand-blue" type="submit"
                        [disabled]="isResettingPassword || !isLocalAccount">
                        <i *ngIf="isResettingPassword" class="loading-spinner"></i>
                        <i *ngIf="!isResettingPassword" class="fa fa-check"></i>
                        {{ 'page.login.reset-password.update' | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>