import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { TablexColumnType } from 'src/app/shared/tablex/tablexColumnType';
import { TablexColumnHorizontalAlign, TablexColumnVerticalAlign } from '../../../shared/tablex/tablexColumnType';
import { PmJobService } from '../pm-job.service';

@Component({
  selector: 'app-pm-job-ccs-info',
  templateUrl: './pm-job-ccs-info.component.html',
  styleUrls: ['./pm-job-ccs-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PmJobCcsInfoComponent implements OnInit {
  @Input() pageMode: JMENUM.JMPageMode;
  @Input() job: JMOBJ.PmJob;
  @Output() onEquipmentRemove: EventEmitter<any> = new EventEmitter();

  pmJobService: PmJobService;

  tablexParam: any = {};

  constructor(pmJobService: PmJobService) {
    this.pmJobService = pmJobService;
  }

  ngOnInit() {
    this.tablexParam.tableRow = 'd-flex align-items-stretch justify-content-center';
    this.renderTableHeader();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.job) {
      this.renderTableContent();
    }
  }

  renderTableHeader() {
    this.tablexParam.headers = [
      {
        name: 'component.pm-job-ccs-info.table.header.equipment-id',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-3 d-flex align-items-center justify-content-center',
      },
      {
        name: 'component.pm-job-ccs-info.table.header.so',
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-3 d-flex align-items-center justify-content-center',
      },
      {
        name: 'component.pm-job-ccs-info.table.header.error',
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-3 d-flex align-items-center justify-content-center',
      },
      {
        name: 'component.pm-job-ccs-info.table.header.delete',
        type: TablexColumnType.Buttons,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-3 d-flex align-items-center justify-content-center',
        // class: 'd-none', // TODO: hidden delete column
      },
    ]
  }

  renderTableContent() {
    if (!this.job || !this.job.equipmentList) return;
    
    this.tablexParam.content = this.job.equipmentList.filter(element=> element['ccsStatus']).map((element, index) => {
      let timeSheetEquipNumber = this.job.timesheet && this.job.timesheet['equipmentNumber'];
      let elEquipmentNumber = element['equipmentNumber'];
      let isSubmittedTimeSheet = timeSheetEquipNumber === elEquipmentNumber

      return [
        elEquipmentNumber,
        element.ccsServiceOrderNumber ? element.ccsServiceOrderNumber : JMLanguage.translate("component.pm-job-ccs-info." + element.ccsStatus),
        element.ccsErrorMessage ? `<p class='error'>Error message: ${element.ccsErrorMessage}</p>` : '',
        [{
          name: isSubmittedTimeSheet ? JMLanguage.translate('component.pm-job-ccs-info.popup.delete-equipment.warning') : '',
          icon: isSubmittedTimeSheet ? '' : 'fa fa-square fa-trash',
          class: isSubmittedTimeSheet ? 'none' : 'rectangle brand-red',
          show: this.job.status === JMENUM.PMJobStatus.REWORKING && element['ccsStatus'] !== JMENUM.PMJobEquipmentCcsStatus.PENDING ? true : false,
          disable: isSubmittedTimeSheet,
          onClicked: () => this.onTrashButtonClick(element),
        }]
      ];
    });
  }

  onTrashButtonClick(equipment) {
    const data = {
      msg: {
        content: 'component.pm-job-ccs-info.popup.delete-equipment.title',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestRemoveEquipment(equipment);
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };

    AppDelegate.popUpDialog().open(data);
  }

  requestRemoveEquipment = async (equipment) => {
    // TODO: to be test
    let response = await this.pmJobService.deleteCcsSoEquipment(this.job, equipment.equipmentNumber);
    if (response && response.payload) {
      this.onEquipmentRemove.emit();
    }
  }
}
