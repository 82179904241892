import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { StringValidator } from 'src/app/core/string-validator';
import { Constants } from 'src/constants';

@Component({
  selector: 'sn-refer-form',
  templateUrl: './sn-refer-form.component.html',
  styleUrls: ['./sn-refer-form.component.scss'],
})
export class SnReferFormComponent implements OnInit, OnChanges {
  @Input() sn: JMOBJ.ServiceNotification;
  @Output() updatedSn = new EventEmitter<JMOBJ.ServiceNotification>();

  // data
  referForm = this.fb.group({
    targetTeam: [undefined, [Validators.required]],
    remarks: ['', [Validators.required, StringValidator.noWhiteSpaceBothEnd]],
  });

  vendorTeamList: JMOBJ.VendorTeam[] = [];
  submitBtn = {
    isLoading: false,
  };
  display = {
    assignedTeam: null,
  };

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.requestVendorTeamList();
    this.resetForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes.sn.currentValue.team)
    if (changes.sn.firstChange) {
      // TODO: wait sn.vendorTeam ready
      // if (this.sn.team && this.sn.team._id) {
      //   this.requestVendorTeam(this.sn.team._id);
      // }
    }
  }

  private resetForm() {
    this.referForm.reset({
      reason: undefined,
      remarks: '',
    });
  }

  //------------------------
  // API
  private requestVendorTeamList() {
    // TODO
  }

  private requestVendorTeam(vendorTeamId: string) {
    // TODO
    this.display.assignedTeam = 'xxxxxx';
  }

  private requestReferSn() {
    // TODO:
    this.updatedSn.emit(this.sn);
    this.resetForm();
  }

  //------------------------
  // button callback
  onSubmitBtnClicked() {
    const data = {
      msg: {
        content: 'component.sn-cancel-editor.pop-up.confirm-to-refer',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestReferSn();
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(data);
  }

  //------------------------
  // get property
  get targetTeam() {
    return this.referForm.get('targetTeam');
  }
  get remarks() {
    return this.referForm.get('remarks');
  }

  get inputBoxMaxLength() {
    return Constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }
}
