<div class="d-flex justify-content-between my-1">
  <div class="font-size-xl">
    {{ "component.severity-criteria-list-table.title" | translate }}
  </div>

  <div class="d-flex align-items-center">
    <app-icon-button *ngIf="hasUpdatePermission" [id]="'delete-criteria-button'" [color]="'red'" [icon]="'fa-times'"
      [isDisabled]="!selectedContractSeverityCriteria" (onClick)="onClickedDeleteButton()"></app-icon-button>
    <app-icon-button *ngIf="hasUpdatePermission" [id]="'add-criteria-button'" [color]="'blue'" [icon]="'fa-plus'"
      [isDisabled]="!contractSeverity?._id" (onClick)="onClickedAddButton()"></app-icon-button>
  </div>
</div>
<hr class="underline" />

<app-tablex class="" [tablexParam]="tablexParam"></app-tablex>

<slider-panel #add_criteria_panel [widthPx]=700>
  <app-severity-criteria-editor #add_criteria_form [contractSeverity]="contractSeverity"
    (addedCriteria)="onAddedCriteria($event)"></app-severity-criteria-editor>
</slider-panel>