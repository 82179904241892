import { JMENUM } from '@ccep/CCEPConnector-ts';
import { ActionButtonI } from 'src/app/shared/action-sidebar-v2/action-sidebar-v2.model';

export const CmTaskViewActionButtonList: { [x: string]: ActionButtonI } = {
  [JMENUM.VpCmTaskAction.COMPLETE]: {
    action: JMENUM.VpCmTaskAction.COMPLETE,
    permissionList: [], // TODO: update to JMENUM.VpPermission.CMTASK_COMPLETE
    actionDescription: 'action.button.complete',
    buttonId: 'right-tool-bar_complete_button',
    imageUrl: '/assets/svg/btn_action_job_complete.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  [JMENUM.VpCmTaskAction.UPDATE]: {
    action: JMENUM.VpCmTaskAction.UPDATE,
    permissionList: [JMENUM.VpPermission.CMTASK_UPDATE],
    actionDescription: 'action.button.edit',
    buttonId: 'right-tool-bar_edit_button',
    imageUrl: '/assets/svg/btn_action_edit.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  [JMENUM.VpCmTaskAction.REFER]: {
    action: JMENUM.VpCmTaskAction.REFER,
    permissionList: [JMENUM.VpPermission.CMTASK_REFER],
    actionDescription: 'action.button.refer',
    buttonId: 'right-tool-bar_refer_button',
    imageUrl: '/assets/svg/btn_action_reassign.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  // TODO: TBC - move to edit page
  // [JMENUM.VpCmTaskAction.UPDATE_EQUIPMENT]: {
  //   action: JMENUM.VpCmTaskAction.UPDATE_EQUIPMENT,
  //   permissionList: [JMENUM.VpPermission.CMTASK_CREATE],
  //   actionDescription: 'action.button.equipment',
  //   buttonId: 'right-tool-bar_update-equipment_button',
  //   imageUrl: '/assets/svg/btn_action_equipment.svg',
  //   isEnable: true,
  //   isShow: true,
  //   handler: null,
  //   popUpTitle: '',
  //   popUpButtonList: []
  // },
  [JMENUM.VpCmTaskAction.ADD_ATTACHMENT]: {
    action: JMENUM.VpCmTaskAction.ADD_ATTACHMENT,
    permissionList: [JMENUM.VpPermission.CMTASK_UPDATE],
    actionDescription: 'action.button.attachment',
    buttonId: 'right-tool-bar_attachment_button',
    imageUrl: '/assets/svg/btn_action_attach.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  ['exportPdf']: {
    action: 'exportPdf', // TODO: TBC - add action to JMENUM.VpSnAction?
    permissionList: [JMENUM.VpPermission.CMTASK_CREATE],
    actionDescription: 'action.button.export-pdf',
    buttonId: 'right-tool-bar_export_button',
    imageUrl: '/assets/svg/btn_action_download.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
};
