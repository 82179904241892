import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage, Language } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';
import { TablexColumnFilterOption, TablexColumnFilterType, TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from 'src/app/shared/tablex/tablexColumnType';
import { Constants } from 'src/constants';

@Component({
  selector: 'contract-routing-coverage-list-table',
  templateUrl: './contract-routing-coverage-list-table.component.html',
  styleUrls: ['./contract-routing-coverage-list-table.component.scss']
})
export class ContractRoutingCoverageListTableComponent implements OnInit {

  @Input() contract?: JMOBJ.MaintenanceTermContract;
  @Output() assignedRoutingCoverage = new EventEmitter<RoutingCoverageI>();

  routingCoverageList: RoutingCoverageI[] = [];
  locationDescriptionMap: Map<string, JMOBJ.Location> = new Map();

  // Table
  tablexParam: {} = {};
  tablexFilter: {
    client?: string
    district?: string
    location?: string
    equipmentCategory?: string
    equipmentType?: string
  } = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize: number = 10;
  currentPage: number = 1;
  pageCount: number = 1;
  allColHeaders: any = [];
  selectedRows: any = [];
  selectedTableRows: any = [];
  clientOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  districtOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  locationOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  equipCatOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  equipTypeOptions: TablexColumnFilterOption = new TablexColumnFilterOption();

  // same as tablexFilter property
  tablexHeaderId = {
    client: 'client',
    district: 'district',
    location: 'location',
    equipmentCategory: 'equipmentCategory',
    equipmentType: 'equipmentType',
  }

  // location Dropdown
  locationList: JMOBJ.Location[] = [];
  private locationSearch: {
    keywords: string;
    search$: Subject<any[]>;
    totalPageNumber: number;
    pageNumber: number;
    pageSize: number;
  } = {
      search$: new Subject<any[]>(),
      keywords: '',
      totalPageNumber: 0,
      pageNumber: 1,
      pageSize: 10,
    };
  constructor() { }

  ngOnInit(): void {
    this.requestLocationOptions();

    this.initTableHeader();
    this.initFilterOptions();
    this.initTablex();

    this.setLocationSearchObserver();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reloadTable();
  }

  //=================================================
  // relaod
  private reloadTable() {
    this.initTableHeader();
    this.initTablex();
    this.resetTable();

    if (this.contract) {
      this.requestRoutingCoverageList();
    } else {
      this.routingCoverageList = [];
      this.tablexParam['totalRowCount'] = 0;
      this.tablexParam['pageCount'] = 0;
      this.renderTable();
    }
  }

  //=================================================
  // API
  private async requestRoutingCoverageList() {
    const request = new JM.JMRequestRoutingRulesGetContractRoutingCoverages();
    request.contractNumber = this.contract.contractNumber;
    request.pageSize = this.currentPageSize;
    request.pageNumber = this.currentPage;

    request.clientList = this.tablexFilter.client ? [this.tablexFilter.client] : undefined;
    request.districtList = this.tablexFilter.district ? [this.tablexFilter.district] : undefined;
    request.locationList = this.tablexFilter.location ? [this.tablexFilter.location] : undefined;
    request.equipmentCategoryList = this.tablexFilter.equipmentCategory ? [this.tablexFilter.equipmentCategory] : undefined;
    request.equipmentTypeList = this.tablexFilter.equipmentType ? [this.tablexFilter.equipmentType] : undefined;

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseRoutingRulesGetContractRoutingCoverages = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.routingCoverageList = response.payload.records;
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
    this.renderTable();
    this.getLocationDescription();
  }

  private getLocationDescription() {
    const locationCodeSet = new Set<string>();

    for (const coverage of this.routingCoverageList) {
      for (const location of coverage.locations) {
        if (!this.locationDescriptionMap.has(location)) {
          locationCodeSet.add(location);
        }
      }
    }

    if (locationCodeSet.size > 0) {
      this.requestLocationDescription(Array.from(locationCodeSet));
    }
  }

  private async requestLocationDescription(locationCodeList: string[]) {
    let request = new JM.JMRequestLocationsLocationSummary();
    request.location = locationCodeList;
    request.includeSummary = true;
    request.pageSize = 1000;
    request.pageNumber = 1;
    request.locationOnly = true;
    request.parameters = ['code', 'description'];

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    for (const location of response.payload.records) {
      this.locationDescriptionMap.set(location.code, location);
    }

    this.renderTable();
  }

  private async requestLocationOptions() {
    let request = new JM.JMRequestLocationsLocationSummary();
    request.includeSummary = true;
    request.pageSize = this.locationSearch.pageSize;
    request.pageNumber = this.locationSearch.pageNumber;
    request.locationOnly = true;
    request.parameters = ['code', 'description'];

    if (!!this.locationSearch.keywords) {
      request.locationDescription = this.locationSearch.keywords;
    }

    this.locationOptions.isLoading = true;
    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.locationOptions.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.locationSearch.totalPageNumber = Math.ceil(response.payload.totalCount / request.pageSize);

    const options = response.payload.records.map((location) => {
      const description = location.description[Session.selectedLanguage]
        ? location.description[Session.selectedLanguage]
        : location.description.en;
      const name = `${description} (${location.code})`;
      const obj = {
        value: location.code,
        label: name,
        descriptionEn: location.description.en ? location.description.en : '',
        descriptionZh: location.description.zh ? location.description.zh : '',
      };
      return obj;
    });
    this.locationOptions.items = this.locationOptions.items.concat(options);
  }

  //=================================================
  // Tablex UI function
  onRowSelected = (index, row) => {
    if (!this.routingCoverageList) {
      return;
    }

    const routingCriteria = this.routingCoverageList[index];
    this.emitAssignedRoutingCoverage(routingCriteria);
  };

  onRowUnselected = (index, row) => {
    this.emitAssignedRoutingCoverage(undefined);
  };

  onFilterChanged = (event, index, header, filter) => {
    for (let key in filter) {
      if (!filter[key]) {
        delete this.tablexFilter[key];
      } else {
        this.tablexFilter[key] = filter[key];
      }
    }
    this.currentPage = 1;
    this.resetTable();
    this.requestRoutingCoverageList();
  };

  onFilterClear = () => {
    this.tablexFilter = {};
    this.tablexParam['filter'] = {};
    this.resetTable();
    this.requestRoutingCoverageList();
  };

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.resetTable();
    this.requestRoutingCoverageList();
  };

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.resetTable();
    this.requestRoutingCoverageList();
  };

  private resetTable() {
    this.selectedTableRows = [];
    this.tablexParam['selectedRowIndex'] = undefined;
    this.tablexParam['highlightedRows'] = [];
    this.emitAssignedRoutingCoverage(undefined);
  }

  private renderTable() {
    this.tablexParam['content'] = this.routingCoverageList.map((coverage, index) => {

      const getLocationDescription = (locationCode: string) => {
        const sufffix = `(${locationCode})`;

        if (this.locationDescriptionMap.has(locationCode)) {
          const obj = this.locationDescriptionMap.get(locationCode);
          const description = obj.description[Session.selectedLanguage];
          return `${description} ${sufffix}`;
        }
        return sufffix;
      }

      const client = coverage.client;
      const district = coverage.district ? JMLanguage.translate(`enum.district.${coverage.district}`) : '';
      const equipmentCategory = coverage.equipmentCategory;
      const equipmentTypeArray = coverage.equipmentTypes;

      let locationArray = [];
      if (coverage.locations) {
        for (const location of coverage.locations) {
          locationArray.push(getLocationDescription(location));
        }
      }

      const row = [client, district, locationArray, equipmentCategory, equipmentTypeArray];

      return row;
    });
  }

  private initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableClearFilter: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      enableSort: false,
      minifyButton: true,
      tableRow: 'row',
      tableClass: 'routing-criteria-list-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowSelected: this.onRowSelected,
      onRowUnselected: this.onRowUnselected,
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };

    this.initTableHeader();
  }

  private initTableHeader() {
    this.allColHeaders = [
      {
        id: this.tablexHeaderId.client,
        name: 'component.contract-routing-coverage-list-table.table-column.client',
        enableFilter: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.clientOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col-2 ',
      },
      {
        id: this.tablexHeaderId.district,
        name: 'component.contract-routing-coverage-list-table.table-column.district',
        enableFilter: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.districtOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col-2 ',
      },
      {
        id: this.tablexHeaderId.location,
        name: 'component.contract-routing-coverage-list-table.table-column.location',
        enableFilter: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.locationOptions,
        showOptionTitle: true,
        type: TablexColumnType.MultiLine,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col-4 ',
      },
      {
        id: this.tablexHeaderId.equipmentCategory,
        name: 'component.contract-routing-coverage-list-table.table-column.equipment-category',
        enableFilter: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.equipCatOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col-2 ',
      },
      {
        id: this.tablexHeaderId.equipmentType,
        name: 'component.contract-routing-coverage-list-table.table-column.equipment-type',
        enableFilter: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.equipTypeOptions,
        showOptionTitle: true,
        type: TablexColumnType.MultiLine,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col-2 ',
      },
    ];
  }

  //=================================================
  // tablex filter dropdown function
  private initFilterOptions() {
    this.resetDistrictFilter();
    this.resetLocationFilter();
    this.resetClientFilter();
    this.resetEquipmentCategoryFilter();
    this.resetEquipmentTypeFilter();
  }

  private onOptionFilterChanged = (option, i, header) => {
    this.resetTable();

    const headerCol = header.id;
    if (headerCol == 'location') {
      this.resetLocationOptions();
    }

    // update routing coverage list
    if (header.filterDropdownOption.selectedValue) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }
    this.currentPage = 1;
    this.requestRoutingCoverageList();
  };

  private clearLocationOption() {
    this.locationOptions.items = [];
    this.locationSearch.pageNumber = 1;
    this.locationSearch.totalPageNumber = 0;
  }

  private setLocationSearchObserver() {
    this.locationSearch.search$.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.clearLocationOption();
      this.requestLocationOptions();
    });
  }

  private resetLocationOptions() {
    this.locationSearch.keywords = '';
    this.locationSearch.search$.next();
  }

  private resetClientFilter() {
    const onFilterClientOptions = (term: string, item: any) => {
      return item.label.toLowerCase().includes(term.toLowerCase());
    };

    this.clientOptions.items = Object.values(Session.getClient()).map((c: JMOBJ.Client) => {
      return {
        value: c.clientShortName,
        label: c.clientShortName,
      }
    });
    this.clientOptions.bindLabel = 'label';
    this.clientOptions.bindValue = 'value';
    this.clientOptions.change = this.onOptionFilterChanged;
    this.clientOptions.searchFn = onFilterClientOptions;
  }

  private resetDistrictFilter() {
    const onFilterDistrictOptions = (term: string, item: any) => {
      return (
        item.value == term.toLowerCase() ||
        item.descriptionEn.toLowerCase().includes(term.toLowerCase()) ||
        item.descriptionZh.toLowerCase().includes(term.toLowerCase())
      );
    };

    this.districtOptions.items = JM.JMConnector.getAllDistrictCode().map((code) => {
      return {
        value: code,
        label: JMLanguage.translate(`enum.district.${code}`),
        descriptionEn: JMLanguage.translate(`enum.district.${code}`, null, Language.EN),
        descriptionZh: JMLanguage.translate(`enum.district.${code}`, null, Language.ZH)
      };
    });
    this.districtOptions.bindLabel = 'label';
    this.districtOptions.bindValue = 'value';
    this.districtOptions.change = this.onOptionFilterChanged;
    this.districtOptions.searchFn = onFilterDistrictOptions;
  }

  private resetEquipmentCategoryFilter() {
    const onFilterEquipCatOptions = (term: string, item: any) => {
      return item.label.toLowerCase().includes(term.toLowerCase());
    };

    this.equipCatOptions.items = Object.values(Session.getEquipmentCategory()).map((obj: JMOBJ.EquipmentCategory) => {
      return {
        value: obj.code,
        label: obj.code,
      }
    });
    this.equipCatOptions.bindLabel = 'label';
    this.equipCatOptions.bindValue = 'value';
    this.equipCatOptions.change = this.onOptionFilterChanged;
    this.equipCatOptions.searchFn = onFilterEquipCatOptions;
  }

  private resetEquipmentTypeFilter() {
    const onFilterEquipTypeOptions = (term: string, item: any) => {
      return item.label.toLowerCase().includes(term.toLowerCase());
    };

    this.equipTypeOptions.items = Object.values(Session.getEquipmentType()).map((obj: JMOBJ.EquipmentType) => {
      return {
        value: obj.code,
        label: obj.code,
      }
    });
    this.equipTypeOptions.bindLabel = 'label';
    this.equipTypeOptions.bindValue = 'value';
    this.equipTypeOptions.change = this.onOptionFilterChanged;
    this.equipTypeOptions.searchFn = onFilterEquipTypeOptions;
  }

  private resetLocationFilter() {
    const onLocationScrollToEnd = () => {
      if (this.locationSearch.pageNumber < this.locationSearch.totalPageNumber) {
        this.locationSearch.pageNumber++;
        this.requestLocationOptions();
      }
    };
    const onSearchLocationOptions = (event) => {
      this.locationSearch.keywords = event.term;
      this.locationSearch.search$.next();
    };
    const onFilterLocationOptions = (term: string, item: any) => {
      return (
        item.value.toLowerCase().includes(term.toLowerCase()) ||
        item.descriptionEn.toLowerCase().includes(term.toLowerCase()) ||
        item.descriptionZh.toLowerCase().includes(term.toLowerCase())
      );
    };

    this.locationOptions.items = [];
    this.locationOptions.bindLabel = 'label';
    this.locationOptions.bindValue = 'value';
    this.locationOptions.change = this.onOptionFilterChanged;
    this.locationOptions.onScrollToEnd = onLocationScrollToEnd;
    this.locationOptions.onSearch = onSearchLocationOptions;
    this.locationOptions.searchFn = onFilterLocationOptions;
  }

  //=================================================
  // Emit function
  private emitAssignedRoutingCoverage(coverage?: RoutingCoverageI) {
    this.assignedRoutingCoverage.emit(coverage);
  }

}

export interface RoutingCoverageI {
  client: string,
  district: string,
  locations: string[],
  equipmentCategory: string,
  equipmentTypes: string[]
}