<form [formGroup]="referForm">
  <div class="container-fluid mb-3">
    <div class="d-flex align-items-center justify-content-between my-3">
      <h3>
        {{ 'component.sn-refer-form.title' | translate }}
      </h3>
      <app-loading-button  
        [id]="'refer_sn_button'"
        [text]="'global.submit'"
        [buttonClass]="'w-2 standard brand-amber'"
        [iconClass]="'fa-check'"
        [isDisabled]="referForm.invalid || submitBtn.isLoading"
        [isLoading]="submitBtn.isLoading"
        (clickedButton)="onSubmitBtnClicked()"
      ></app-loading-button>
    </div>

    <div class="row align-items-center my-3">
      <div class="col-12 mt-3">
        {{ 'component.sn-refer-form.assigned-team' | translate}}
      </div>
      <div class="col-12">
        {{display.assignedTeam}}
      </div>
      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'component.sn-refer-form.refer-to' | translate}}
      </div>
      <div class="col-12">
        <ng-select
          id="refer_team_input"
          formControlName="targetTeam"
          bindLabel="name"
          bindValue="vendorNumber"
          [items]="vendorTeamList"
        ></ng-select>
      </div>
      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'component.sn-refer-form.remarks' | translate}}
      </div>
      <div class="col-12">
        <textarea
          id="refer_remarks_input"
          class="form-control"
          rows="5"
          formControlName="remarks"
          [maxlength]="inputBoxMaxLength">
        </textarea>
        <span 
          *ngIf="remarks?.value?.length >= inputBoxMaxLength" 
          class="red small"
        >
          {{ "slider.textarea.max-length" | translate:[inputBoxMaxLength]}}
        </span>
      </div>
    </div>

    <div class="row align-items-center my-3">
      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'global.mandatory-fields' | translate}}
      </div>
    </div>
  </div>
</form>
