<div class="font-size-xl m-0 mb-2">
  <div class="overflow-hidden">
    <div class="d-inline-block">
      {{ "component.pm-plan-summary.title" | translate}} 
      <span *ngIf="plan?.pmPlanNumber && plan?.status" id="pm-plan-status" class="font-size-l px">
        ({{ "pm-plan.status."+plan.status | translate }})
      </span>
    </div>
    <div class="overflow-hidden d-inline-block float-right">
      <span id="plan-summary-plan-number" *ngIf="plan?.pmPlanNumber">
        {{ plan.pmPlanNumber }}
      </span>
      <span id="empty-plan-number" *ngIf="!plan?.pmPlanNumber" >
        {{ "component.pm-plan-summary.plan-number" | translate }}
      </span>
    </div>
  </div>
</div>
<hr class="underline mb-0" />

<ng-container *ngIf="pageMode == JMPageMode.VIEW; then viewMode; else editMode">
</ng-container>

<!-- View -->
<ng-template #viewMode>
  <div class="row mx-0 align-items-start position-relative">
    <div class="mandatory-label"><span class="mandatory-mark">*</span>{{ "global.mandatory-fields" | translate }}</div>
  
    <div class="col-12 row mx-0 px-0 align-items-center mt-4">
      <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mb-4 px-0 pl-1">
        {{ "component.pm-plan-summary.contract" | translate }}
      </div>
      <div *ngIf="!editable?.contract" class="col-8 col-sm-4 col-xl-4 text-left m-0 mb-4 overflow-auto">
        <app-display-text-field id="plan-summary-contract"
          [ngClass]="{'input-error':errorFields?.contract}"
          [textField]="plan?.contract?.contractNumber"
        >
        </app-display-text-field>
      </div>
      <div *ngIf="plan?.contract?.liftTrapped" class="col-8 col-sm-4 col-xl-4 text-left m-0 mb-4 overflow-auto">
        ({{ "component.pm-plan-summary.isLiftContract" | translate }})
      </div>
      <!-- <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mb-4 px-0 pl-1">
        {{ "component.pm-plan-summary.sla" | translate }}
      </div>
      <div *ngIf="!editable?.agreementNumber" class="col-8 col-sm-4 col-xl-4 text-left m-0 mb-4 overflow-auto">
        <app-display-text-field id="plan-summary-sla" [textField]="plan?.agreementNumber" [ngClass]="{'input-error':errorFields?.agreementNumber}">
        </app-display-text-field>
      </div> -->
    </div>

    <div class="col-4 col-sm-2 text-sm-right gray-title m-0 mb-4 px-0 pl-1">
      <span class="mandatory-mark">*</span>
      {{ "component.pm-plan-summary.plan-description" | translate }}
    </div>
    <div *ngIf="!editable?.planDescription" class="col-8 col-sm-10  text-left m-0 mb-4 overflow-auto">
      <app-display-text-field id="plan-summary-plan-description" [textField]="plan?.planDescription" [ngClass]="{'input-error':errorFields?.planDescription}">
      </app-display-text-field>
    </div>
  
    <div class="col-12 col-xl-6 row mx-0 px-0 align-items-center">
      <div class="col-4 col-sm-2 col-xl-4 text-sm-right  gray-title m-0 mb-4 px-0 pl-1">
        <span class="mandatory-mark">*</span>
        {{ "component.pm-plan-summary.schedule-type" | translate }}
      </div>
      <div *ngIf="!editable?.scheduleType" class="col-8 col-sm-4 col-xl-8 text-left m-0 mb-4 overflow-auto">
        <app-display-text-field
          id="plan-summary-schedule-type"
          [textField]="scheduleType"
          [ngClass]="{'input-error':errorFields?.scheduleType}"
        >
        </app-display-text-field>
      </div>
      <!-- <div class="col-4 col-sm-2 col-xl-4 text-sm-right  gray-title m-0 mb-4 px-0 pl-1">
        <span class="mandatory-mark">*</span>
        {{ "component.pm-plan-summary.plan-coverage" | translate }}
      </div>
      <div *ngIf="!editable?.planCoverage" class="col-8 col-sm-4 col-xl-8 text-left m-0 mb-4 overflow-auto">
        <app-display-text-field id="plan-summary-plan-coverage" [textField]="planCoverage" 
          [ngClass]="{'input-error':errorFields?.planCoverage}">
        </app-display-text-field>
        <i class="fas fa-info-circle font-size-l blue ml-2" title=""></i>
      </div> -->
      <div class="col-4 col-sm-2 col-xl-4 text-sm-right  gray-title m-0 mb-4 px-0 pl-1">
        <span class="mandatory-mark">*</span>
        {{ "component.pm-plan-summary.frequency" | translate }}
      </div>
      <div *ngIf="!editable?.frequency" class="col-8 col-sm-4 col-xl-8 text-left m-0 mb-4 overflow-auto">
        <app-display-text-field
          id="plan-summary-frequency"
          [textField]="frequency" 
          [ngClass]="{'input-error':errorFields?.frequency}"
        >
        </app-display-text-field>
      </div>
    </div>

    <div class="col-12 col-xl-6 row mx-0 px-0 align-items-center">
      <div class="col-4 col-sm-2 col-xl-4 text-sm-right  gray-title m-0 mb-4 px-0 pl-1">
        <span class="mandatory-mark">*</span>
        {{ "component.pm-plan-summary.start-date" | translate }}
      </div>
      <div *ngIf="!editable?.startDate" class="col-8 col-sm-4 col-xl-8 text-left m-0 mb-4 overflow-auto">
        <app-display-text-field id="plan-summary-start-date" [textField]="plan?.startDate | dateWeek" 
          [ngClass]="{'input-error':errorFields?.startDate}">
        </app-display-text-field>
      </div>

      <div class="col-4 col-sm-2 col-xl-4 text-sm-right  gray-title m-0 mb-4 px-0 pl-1">
        <span class="mandatory-mark">*</span>
        {{ "component.pm-plan-summary.end-date" | translate }}
      </div>
      <div *ngIf="!editable?.endDate" class="col-8 col-sm-4 col-xl-8 text-left m-0 mb-4 overflow-auto">
        <app-display-text-field id="plan-summary-end-date" [textField]="plan?.endDate | dateWeek" 
          [ngClass]="{'input-error':errorFields?.endDate}">
        </app-display-text-field>
      </div>

      <div *ngIf="plan?.scheduleType == ScheduleType.OVERHAUL || plan?.scheduleType == ScheduleType.NORMAL" class="col-4 col-sm-2 col-xl-4 text-sm-right  gray-title m-0 mb-4 px-0 pl-1">
        <span class="mandatory-mark">*</span>
        {{ "component.pm-plan-summary.second-period-start-date" | translate }}
      </div>
      <div *ngIf="(plan?.scheduleType == ScheduleType.OVERHAUL || plan?.scheduleType == ScheduleType.NORMAL )&& !editable?.secondStartDate"
        class="col-8 col-sm-4 col-xl-8 text-left m-0 mb-4 overflow-auto">
        <app-display-text-field id="plan-summary-second-start-date" [textField]="plan?.secondStartDate | dateWeek" 
          [ngClass]="{'input-error':errorFields?.secondStartDate}">
        </app-display-text-field>
      </div>
    </div>
  </div>
</ng-template>

<!-- Create || Edit -->
<ng-template #editMode>
  <div class="row mx-0 align-items-center position-relative">
    <div class="mandatory-label"><span class="mandatory-mark">*</span>{{ "global.mandatory-fields" | translate }}</div>

    <div class="col-12 row mx-0 px-0 align-items-center ">
      <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 mb-4 px-0 pl-1">
        <span class="mandatory-mark">*</span>
        {{ "component.pm-plan-summary.contract" | translate }}
      </div>
      <div *ngIf="editable?.contract" class="col-8 col-sm-4 text-left m-0 mt-4 mb-4">
        <ng-select
          id="edit-plan-work-centre"
          class="px-0" 
          bindLabel="label"
          bindValue="value"
          [class.input-error]="errorFields?.contract"
          [items]="contractOptions"
          [loading]="isLoadingContractOptions"
          [ngModelOptions]="{standalone: true}"
          (scrollToEnd)="onContractScrollToEnd()"
          (clear)="onClearContract()"
          (search)="onSearchContractOptions($event)"
          (change)="onChangeContract($event)"
          [(ngModel)]="contractNumber"
        >
        </ng-select>
      </div>
      <div *ngIf="!editable?.contract" class="col-8 col-sm-4 col-xl-4 text-left m-0 mt-4 mb-4 overflow-auto">
        <app-display-text-field id="plan-summary-contract" [textField]="plan?.contract?.contractNumber" [ngClass]="{'input-error':errorFields?.contract}">
        </app-display-text-field>
      </div>
      <div *ngIf="plan?.contract?.liftTrapped" class="col-8 col-sm-4 col-xl-4 text-left m-0 overflow-auto">
        ({{ "component.pm-plan-summary.isLiftContract" | translate }})
      </div>
      <!-- <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 mb-4 px-0 pl-1">
        <span class="mandatory-mark">*</span>
        {{ "component.pm-plan-summary.sla" | translate }}
      </div>
      <div *ngIf="editable?.agreementNumber" class="col-8 col-sm-4 text-left m-0 mt-4 mb-4">
        <ng-select id="edit-plan-sla" class="px-0" [class.input-error]="errorFields?.agreementNumber"
          [items]="slaOptions" bindLabel="label" bindValue="value" 
          [(ngModel)]="plan.agreementNumber" [disabled]="isDisabledSLA" (change)="onChangeSLA($event)">
        </ng-select>
      </div>
      <div *ngIf="!editable?.agreementNumber" class="col-8 col-sm-4 col-xl-4 text-left m-0 mb-4 mt-4 overflow-auto">
        <app-display-text-field id="plan-summary-sla" [textField]="plan?.agreementNumber" [ngClass]="{'input-error':errorFields?.agreementNumber}">
        </app-display-text-field>
      </div> -->
    </div>

    <div class="col-4 col-sm-2 text-sm-right gray-title m-0 mb-4 px-0 pl-1">
      <span class="mandatory-mark">*</span>
      {{ "component.pm-plan-summary.plan-description" | translate }}
    </div>
    <div *ngIf="editable?.planDescription" class="col-8 col-sm-10  text-left m-0 mb-4">
      <textarea *ngIf="editable?.planDescription" id="edit-pm-plan-description"class="form-control" rows="3" [maxlength]="descriptionMaxLength"
        [(ngModel)]="planDescription" (change)="onChangeDescription()" [class.input-error]="errorFields.planDescription"
      ></textarea>
    </div>
    <div *ngIf="!editable?.planDescription" class="col-8 col-sm-10  text-left m-0 mb-4 overflow-auto">
      <app-display-text-field id="plan-summary-plan-description" [textField]="plan?.planDescription" [ngClass]="{'input-error':errorFields?.planDescription}">
      </app-display-text-field>
    </div>
  
    <div class="col-4 col-sm-2 text-sm-right  gray-title m-0 mb-4 px-0 pl-1">
      <span class="mandatory-mark">*</span>
      {{ "component.pm-plan-summary.schedule-type" | translate }}
    </div>
    <div *ngIf="editable?.scheduleType" class="col-sm-10 col-8 text-left m-0 mb-4">
      <div class="mr-2 btn-group btn-group-toggle" id="edit-plan-schedule-type" ngbRadioGroup name="plan-schedule-type" 
        [(ngModel)]="plan.scheduleType" (change)="onChangeScheduleType()" [class.input-error]="errorFields?.scheduleType">
        <label ngbButtonLabel class="radio-tag brand-blue">
          <input ngbButton type="radio" [value]="ScheduleType.NORMAL" id="edit-plan-schedule-type-normal">
          {{ "pm-plan.schedule-type.normal" | translate }}
        </label>
        <label ngbButtonLabel class="radio-tag brand-blue">
          <input ngbButton type="radio" [value]="ScheduleType.OVERHAUL" id="edit-plan-schedule-type-overhaul">
          {{ "pm-plan.schedule-type.overhaul" | translate }}
        </label>
        <label ngbButtonLabel class="radio-tag brand-blue">
          <input ngbButton type="radio" [value]="ScheduleType.ONCE" id="edit-plan-schedule-type-once">
          {{ "pm-plan.schedule-type.once" | translate }}
        </label>
      </div>
    </div>
    <div *ngIf="!editable?.scheduleType" class="col-8 col-sm-4 text-left m-0 mb-4">
      <app-display-text-field id="plan-summary-scheduleType" [textField]="scheduleType">
      </app-display-text-field>
    </div>

    <!-- <div class="col-4 col-sm-2 text-sm-right gray-title m-0 mb-4 px-0 pl-1">
      <span class="mandatory-mark">*</span>
      {{ "component.pm-plan-summary.plan-coverage" | translate }}
    </div>
    <div *ngIf="editable?.planCoverage" class="col-sm-10 col-8 text-left m-0 mb-4">
      <div class="mr-2 btn-group btn-group-toggle" id="edit-plan-coverage" ngbRadioGroup name="plan-coverage" 
        [(ngModel)]="plan.planCoverage" [class.input-error]="errorFields?.planCoverage" (change)="onChangeCoverage()">
        <label ngbButtonLabel class="radio-tag brand-blue">
          <input ngbButton type="radio" [value]="PlanCoverage.EQUIPMENT" id="edit-plan-coverage-equipment">
          {{ "pm.plan-coverage.equipment" | translate }}
        </label>
        <label ngbButtonLabel class="radio-tag brand-blue">
          <input ngbButton type="radio" [value]="PlanCoverage.LOCATION" id="edit-plan-coverage-location">
          {{ "pm.plan-coverage.location" | translate }}
        </label>
      </div>
    </div>
    <div *ngIf="!editable?.planCoverage" class="col-sm-4 col-8 text-left m-0 mb-4">
      <app-display-text-field id="plan-summary-planCoverage" [textField]="planCoverage">
      </app-display-text-field>
    </div> -->
    
    <div class="col-4 col-sm-2 text-sm-right  gray-title m-0 mb-4 px-0 pl-1">
      <span class="mandatory-mark">*</span>
      {{ "component.pm-plan-summary.start-date" | translate }}
    </div>
    <div *ngIf="editable?.startDate" class="col-sm-4 col-8 text-left m-0 mb-4">
      <app-date-input
        #startDateElem
        class="mt-1 d-inline-block w-100" 
        [class.input-error]="errorFields?.startDate"
        [input]="startDate"
        [inputId]="'startDate'"
        [field]="'startDate'" 
        (updatedDate)="onBlurDateInput($event)"
      >
      </app-date-input>
    </div>
    <div *ngIf="!editable?.startDate" class="col-8 col-sm-4 text-left m-0 mb-4 overflow-auto">
      <app-display-text-field id="plan-summary-start-date" [textField]="plan?.startDate | dateWeek" >
      </app-display-text-field>
    </div>

    <div class="col-4 col-sm-2 text-sm-right  gray-title m-0 mb-4 px-0 pl-1">
      <span class="mandatory-mark">*</span>
      {{ "component.pm-plan-summary.end-date" | translate }}
    </div>
    <div *ngIf="editable?.endDate" class="col-sm-4 col-8 text-left m-0 mb-4">
      <app-date-input
        #endDateElem
        class="mt-1 d-inline-block w-100"
        [class.input-error]="errorFields?.endDate"
        [input]="endDate"
        [inputId]="'endDate'"
        [field]="'endDate'" 
        (updatedDate)="onBlurDateInput($event)"
      >
      </app-date-input>
    </div>
    <div *ngIf="!editable?.endDate" class="col-8 col-sm-4 text-left m-0 mb-4 overflow-auto">
      <app-display-text-field id="plan-summary-end-date" [textField]="plan?.endDate | dateWeek">
      </app-display-text-field>
    </div>

    <ng-container *ngIf="plan?.scheduleType == ScheduleType.OVERHAUL || plan?.scheduleType == ScheduleType.NORMAL">
      <div class="col-4 col-sm-2 text-sm-right gray-title m-0 mb-4 px-0 pl-1">
        {{ "component.pm-plan-summary.second-period-start-date" | translate }}
      </div>
      <div *ngIf="editable?.secondStartDate" class="col-sm-4 col-8 text-left m-0 mb-4">
        <app-date-input class="mt-1 d-inline-block w-100" #secondStartDateElem [class.input-error]="errorFields?.secondStartDate"
          [input]="secondStartDate"
          [inputId]="'secondStartDate'"
          [field]="'secondStartDate'"
          (updatedDate)="onBlurDateInput($event)">
        </app-date-input>
      </div>
      <div *ngIf="!editable?.secondStartDate" class="col-8 col-sm-4 text-left m-0 mb-4">
        <app-display-text-field id="plan-summary-second-start-date" [textField]="plan?.secondStartDate | dateWeek">
        </app-display-text-field>
      </div>
    </ng-container>

    <ng-container *ngIf="plan?.scheduleType == ScheduleType.OVERHAUL || plan?.scheduleType == ScheduleType.NORMAL">
      <div class="col-4 col-sm-2 text-sm-right gray-title m-0 mb-4 px-0 pl-1">
        <span class="mandatory-mark">*</span>
        {{ "component.pm-plan-summary.frequency" | translate }}
      </div>
      <div *ngIf="editable?.frequency" class="col-sm-4 col-8 text-left m-0 mb-4">
        <ng-select id="edit-plan-frequency" class="px-0" [class.input-error]="errorFields?.frequency"
          [items]="frequencyOptions" bindLabel="label" bindValue="value" (change)="onChangeFrequency()"
          [(ngModel)]="plan.frequency" >
        </ng-select>
      </div>
      <div *ngIf="!editable?.frequency" class="col-8 col-sm-4 text-left m-0 mb-4 overflow-auto">
        <app-display-text-field id="plan-summary-frequency" [textField]="frequency">
        </app-display-text-field>
      </div>
    </ng-container>

  </div>
</ng-template>