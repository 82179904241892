<div class="wrapper">
  <app-nav [breadcrumbs]="breadcrumbs"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="content pm-task-create-content action-btn-padding">
    <div class="component-wrap row">
      <div class="col-12 col-xl-6">
        <app-pm-job-description
          #pmJobDescriptionElem
          class="d-block mb-5"
          [job]="pmJob"
          [pageMode]="pageMode"
        ></app-pm-job-description>
        <app-pm-job-client-info
          #pmJobClientInfoElem
          class="d-block mb-5"
          [job]="pmJob"
          [pageMode]="pageMode" 
        ></app-pm-job-client-info>
      </div>

      <div class="col-12 col-xl-6">
        <app-pm-job-progress
          #pmJobProgressElem
          [job]="pmJob"
          [pageMode]="pageMode" 
          class="d-block mb-5" 
        ></app-pm-job-progress>
        <app-pm-job-equipment-info
          #pmJobEquipmentInfoElem 
          class="d-block mb-5"
          [pageMode]="pageMode" 
          [periodId]="pmJob?.period?._id" 
          [pmJobNumber]="pmJob?.pmJobNumber"
          [selectedEquipmentsNumber]="pmJob?.equipmentList?.length" 
        ></app-pm-job-equipment-info>
        <app-pm-job-remarks
          class="d-block mb-5"
          [job]="pmJob"
        ></app-pm-job-remarks>
        <app-pm-job-ccs-info
          #pmJobCcsInfoElem
          class="d-block mb-5"
          [pageMode]="pageMode"
          [job]='pmJob'
          (onEquipmentRemove)="initPage()"
        ></app-pm-job-ccs-info>
        <app-attachment-info
          *ngIf="pmJob.attachments && pmJob.attachments.length > 0"
          [attachmentList]="pmJob.attachments"
          [currentPageMode]="pageMode"
        ></app-attachment-info>
      </div>
    </div>

    <!-- Action Sidebar -->
    <ng-container *ngIf="actionSidebar.dataList && actionSidebar.dataList.length > 0">
      <app-action-sidebar-v2
        [actionButtonList]="actionSidebar.dataList"
        [isDisabled]="actionSidebar.isDisabled"
        [isLoading]="actionSidebar.isLoading"
        (clickedButton)="onClickedActionButton($event)">
      </app-action-sidebar-v2>
    </ng-container>

    <!--CM task refer -->
    <app-custom-slider-panel #pm_job_cancel_form_panel [widthPx]=600
      [customClass]="'pm_job_cancel_form_panel'">
      <pm-job-cancel-form [job]="pmJob" (cancelledPmJob)="onCancelledPmJob($event)">
      </pm-job-cancel-form>
    </app-custom-slider-panel>
  </div>

</div>
