<div id="pmPeriodListComponent" class="d-flex">
  <app-nav [navbarTitle]="'sidebar.subitem.pm-period-list' | translate"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="d-flex flex-wrap" [class.isSearching]="tablexParam.isLoadingTable">
    <div class="col-12 col-xl-9 order-1">
      <!-- <div class="d-flex justify-content-between">
        <span class="font-size-xl">
          {{ 'sidebar.subitem.pm-period-list' | translate }}
        </span>
      </div>
      <hr class="underline" /> -->
      <!-- <button
        class="btn standard brand-blue ml-auto d-block"
        [disabled]="!highlightedRow"
        (click)="onClickCreatePMTask()"
      >
        {{ 'component.pm-period-list.create-pm-task' | translate }}
      </button> -->

      <app-tablex id="pm-period-list-table" [tablexParam]="tablexParam"></app-tablex>
    </div>
    <app-pm-period-search-criteria
      class="col-12 col-xl-3 order-0 order-xl-1"
      (onSearch)="onAdvancedSearch($event)"
      (onReset)="resetSorting()"
    >
    </app-pm-period-search-criteria>
  </div>
</div>
