<div class="add-team-form-wrap">
  <div class="d-flex justify-content-between font-size-xl">
    {{ "pages.settings-team.add-member-for-team" | translate }}
    <button class="btn standard brand-amber" (click)="onClickedSaveButton()" [disabled]="!displayMemberList || displayMemberList.length <= 0">
      <div *ngIf="isLoading" class="d-flex align-items-center loading-spinner spinner-small"></div>
      <ng-container *ngIf="!isLoading">
        <i class="fa fa-save mr-2"></i>{{ "global.submit" | translate }}
      </ng-container>
    </button>
  </div>

  <div id="add-member-form" class="mt-4">
    <div class="font-size-l bold">{{ vendorTeam?.name }}</div>
    <ng-container *ngIf="displayMemberList && displayMemberList.length > 0">
      <div class="member-list-wrap">
        <div class="row mx-0 mb-2 align-items-center">
          <input id="select-all-member" type="checkbox" class="col-auto" [(ngModel)]="selectAllMember" (change)="onClickedSelectAllCheckBox()" />
          <label class="col mb-0 row mx-0" for="select-all-member">
            <span *ngIf="!selectAllMember" class="col-12">{{ "global.select-all" | translate }}</span>
            <span *ngIf="selectAllMember" class="col-12">{{ "global.unselect-all" | translate }}</span>
          </label>
        </div>
        
        <div class="row mx-0 mb-2 align-items-center" *ngFor="let member of displayMemberList">
          <input id="member-{{member.postName}}" type="checkbox" class="col-auto" value="all" 
            [(ngModel)]="member.selected" (change)="onChangedMember()" />
          <label class="col row mx-0 mb-0" for="member-{{member.postName}}">
            <span class="col-6">{{ member.postName }}</span>
            <span class="col-6">{{ member.staffName }}</span>
          </label>
        </div>
      </div>
    </ng-container>
      
    <ng-container *ngIf="!displayMemberList || displayMemberList.length <= 0">
      <div id="no-members-available" class="mt-3 red">
        {{ "pages.settings-team.no-members-available" | translate }}
      </div>
    </ng-container>
  </div>

</div>