import { Injectable } from '@angular/core';
import { AppDelegate } from '../core/AppDelegate';
import { ActionButtonI } from './action-sidebar-v2/action-sidebar-v2.model';
import { PopUpDialogI } from './pop-up-dialog/pop-up-dialog.model';

@Injectable({
  providedIn: 'root',
})
export class ActionSidebarV2Service {
  constructor() {}

  popUpConfirmationDialog(actionButton: ActionButtonI, buttonHandler: () => void) {
    const dialog: PopUpDialogI = {
      msg: {
        content: actionButton.popUpTitle,
      },
      buttons: [
        {
          name:
            actionButton.popUpButtonList && actionButton.popUpButtonList.length >= 1
              ? actionButton.popUpButtonList[0].name
              : 'global.yes',
          handler: buttonHandler,
        },
        {
          name:
            actionButton.popUpButtonList && actionButton.popUpButtonList.length >= 2
              ? actionButton.popUpButtonList[1].name
              : 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(dialog);
  }
}
