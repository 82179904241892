<div class="wrapper">
  <app-nav [navbarTitle]="'pages.settings-team.title' | translate"></app-nav>
  <app-sidebar [contentType]="'settings'"></app-sidebar>

  <div id="content" class="content">
    <div class="team-page-wrap row">
      <div class="col-12 font-size-x mb-3">
         <ng-select 
            id="setting-team.vendor-dropdown" 
            class="col-xl-6 p-0" 
            bindValue="vendorNumber"
            bindLabel="vendorName"
            [(ngModel)]="selectedVendorNumber" 
            [items]="vendorList"
            [clearable]="false"
            [loading]="isLoadingVendorList"
            (scrollToEnd)="onVendorScrollToEnd()"
            (search)="onSearchVendor($event)"
          ></ng-select>
      </div>

      <ng-container ngif="selectedVendorNumber">
        <div class="col-12 col-xl-6">
          <app-team-list-table #team_list [vendorNumber]="selectedVendorNumber" [hasUpdatePermission]="hasUpdatePermission" (assignedVendorTeam)="onSelectedTeam($event)" ></app-team-list-table>
        </div>
        
        <div class="col-12 col-xl-6">
          <app-team-member-table #member_list [hasUpdatePermission]="hasUpdatePermission" [vendorTeam]="selectedTeam"></app-team-member-table>
          <app-contract-list #contract_list class="d-block mt-5" *ngIf="false"
            [parameters]="contractListParam" [hasUpdatePermission]="hasUpdatePermission">
          </app-contract-list>
        </div>
      </ng-container>
      
    </div>
  </div>
</div>