/*
  version 20210506:
   - Reminder Recipients handle (layout and API)
   - Max Time to Acknowledge change to input type number
   - update general API
   - Permission control
   - add comment and tidy up region of method
  
  version 20210428:
   - create this component
*/

import { validateVerticalPosition } from '@angular/cdk/overlay';
import { Injector, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { UserHelper } from 'src/app/core/user-helper';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { BasePage } from 'src/app/core/base';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';

@Component({
  selector: 'app-contract-general',
  templateUrl: './contract-general.component.html',
  styleUrls: ['./contract-general.component.scss'],
})
export class ContractGeneralComponent extends BasePage implements OnInit {
  @Input() currentContract?: JMOBJ.MaintenanceTermContract; // TODO: remove. request contract detail by contract number
  @Input() currentContractNumber?: string;
  @Output() onUpdateContractGeneral = new EventEmitter<any>();

  contract?: JMOBJ.MaintenanceTermContract;

  //mode control variable
  editmode = false;

  //basic data
  contractNumber = undefined;
  defaultTeamName = undefined;
  liftContract = undefined;
  cmEquipmentFindBy = undefined;
  pmEquipmentFindBy = undefined;
  reminderRecipients = undefined;
  nonUrgentMaxAckTime = undefined;
  urgentMaxAckTime = undefined;
  emergencyMaxAckTime = undefined;
  pmMaxAckTime = undefined;

  //Permission Control variable
  hasEditPermission = true;
  hasRoutePermission = true;

  //Enum
  equipmentFindBy = JMENUM.EquipmentFindBy;

  //Reminder Recipients drop down variable
  postList: any = [];
  postListCount = 0;
  postListSize = 50;
  postListPageNumber = 1;

  //vaildation variable
  errorFields = {
    nonUrgentMaxAckTime: false,
    urgentMaxAckTime: false,
    emergencyMaxAckTime: false,
  };

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    if (!UserHelper.hasEitherOnePermission([JMENUM.VpPermission.CONTRACT_UPDATE])) {
      this.hasEditPermission = false;
    }
    if (!UserHelper.hasEitherOnePermission([JMENUM.VpPermission.CONTRACT_VIEW, JMENUM.VpPermission.CONTRACT_UPDATE])) {
      this.hasRoutePermission = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentContractNumber) {
      if (this.currentContractNumber) {
        this.requestGetContractDetail(this.currentContractNumber);
      }
      // this.postListPageNumber = 1;
      // this.requestGetContactPersonsByPost();
    }
  }

  //#region Data control Function
  private resetContractGeneral() {
    if (this.currentContract) {
      this.contractNumber = this.currentContract.contractNumber;
      this.defaultTeamName = this.currentContract.defaultTeam;
      this.liftContract = this.currentContract.contractItemType == 'LIFT';
      this.cmEquipmentFindBy = this.equipmentFindBy[this.currentContract.cmEquipmentFindBy];
      this.pmEquipmentFindBy = this.equipmentFindBy[this.currentContract.pmEquipmentFindBy];
      this.reminderRecipients = this.currentContract.reminderRecipients;
      this.nonUrgentMaxAckTime = this.currentContract.cmResponseTime
        ? this.currentContract.cmResponseTime[JMENUM.JMPriority.NonUrgent]
        : undefined;
      this.urgentMaxAckTime = this.currentContract.cmResponseTime
        ? this.currentContract.cmResponseTime[JMENUM.JMPriority.Urgent]
        : undefined;
      this.emergencyMaxAckTime = this.currentContract.cmResponseTime
        ? this.currentContract.cmResponseTime[JMENUM.JMPriority.Emergency]
        : undefined;
      this.pmMaxAckTime = this.currentContract.pmResponseTime;
    } else {
      this.contractNumber = undefined;
      this.defaultTeamName = undefined;
      this.liftContract = undefined;
      this.cmEquipmentFindBy = undefined;
      this.pmEquipmentFindBy = undefined;
      this.reminderRecipients = undefined;
      this.nonUrgentMaxAckTime = undefined;
      this.urgentMaxAckTime = undefined;
      this.emergencyMaxAckTime = undefined;
      this.pmMaxAckTime = undefined;
    }
    this.resetErrorField();
  }

  public resetErrorField() {
    this.errorFields = {
      nonUrgentMaxAckTime: false,
      urgentMaxAckTime: false,
      emergencyMaxAckTime: false,
    };
  }
  //#endregion

  //#region Reaction Control Function
  public onClickDefaultTeam() {
    let path = '/settings/team/' + this.defaultTeamName;
    if (!this.editmode) {
      this.router.navigate([path]);
    } else if (this.editmode) {
      window.open(path);
    }
  }

  public onSubmitGeneral() {
    if (!this.validation()) {
      return;
    }
    if (!this.cmEquipmentFindBy || !this.pmEquipmentFindBy) {
      let data = {
        msg: {
          content: 'component.contract-general.message.pop-up-disable-create-task',
        },
        buttons: [
          {
            name: 'global.yes',
            handler: () => {
              this.requestUpdateContract();
            },
          },
          {
            name: 'global.no',
            handler: null,
          },
        ],
      };
      AppDelegate.popUpDialog().open(data);
    } else {
      let data = {
        msg: {
          content: 'popup.confirm-to-save',
        },
        buttons: [
          {
            name: 'global.yes',
            handler: () => {
              this.requestUpdateContract();
            },
          },
          {
            name: 'global.no',
            handler: null,
          },
        ],
      };
      AppDelegate.popUpDialog().open(data);
    }
  }

  public onReminderRecipientsScrollToEnd() {
    if (this.postListSize * this.postListPageNumber < this.postListCount) {
      this.postListPageNumber += 1;
    }
    this.requestGetContactPersonsByPost();
  }

  public onEdit() {
    this.editmode = !this.editmode;
  }

  public onRollBack() {
    let data = {
      msg: {
        content: 'popup.confirm-to-clear',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.resetContractGeneral();
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(data);
  }
  //#endregion

  //#region API Call Function
  async requestGetContractDetail(contractNumber: string) {
    let request = new JM.JMRequestContractsGetContract();
    request.contractNumber = contractNumber;

    const response: JM.JMResponseContractsGetContract = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.contract = response.payload;
    this.resetContractGeneral();
  }

  public async requestGetContactPersonsByPost() {
    let request: JM.JMRequestPostsGetContractInHouseHandlingPost =
      new JM.JMRequestPostsGetContractInHouseHandlingPost();
    request.contractNumber = this.currentContractNumber;
    request.systemName = Constants.SYSTEM_NAME;
    request.pageNumber = this.postListPageNumber;
    request.pageSize = this.postListSize;

    let response: JM.JMResponsePostsGetContractInHouseHandlingPost = await AppDelegate.sendJMRequest(request);
    if (response && response.code == 200) {
      this.postList = response.payload.records;
      this.postListCount = response.payload.totalCount;
    } else {
      AppDelegate.toastMsg().showResponseMsg(response);
    }
  }

  public async requestUpdateContract() {
    let request: JM.JMRequestContractsUpdateContractGeneralInfo = new JM.JMRequestContractsUpdateContractGeneralInfo();
    let cmEquipmentFindByObjKey = Object.keys(this.equipmentFindBy).find(
      (key) => this.equipmentFindBy[key] === this.cmEquipmentFindBy
    );
    let pmEquipmentFindByObjKey = Object.keys(this.equipmentFindBy).find(
      (key) => this.equipmentFindBy[key] === this.pmEquipmentFindBy
    );

    request.cmEquipmentFindBy = cmEquipmentFindByObjKey ? cmEquipmentFindByObjKey : null;
    request.pmEquipmentFindBy = pmEquipmentFindByObjKey ? pmEquipmentFindByObjKey : null;
    request.pmResponseTime = this.pmMaxAckTime ? this.pmMaxAckTime : undefined;
    request.cmResponseTime = { 1: this.emergencyMaxAckTime, 2: this.urgentMaxAckTime, 3: this.nonUrgentMaxAckTime };
    request.reminderRecipients = this.reminderRecipients;
    request.contractItemType = this.liftContract ? 'LIFT' : null;
    request.contractNumber = this.contractNumber;

    let response = await AppDelegate.sendJMRequest(request);
    if (response && response.code == 200) {
      this.editmode = false;
      this.currentContract = response.payload;
      this.onUpdateContractGeneral.emit();
    } else {
      AppDelegate.toastMsg().showResponseMsg(response);
    }
  }
  //#endregion

  //#region validation related Function
  public validation(): boolean {
    let vaild = true;

    if (!this.emergencyMaxAckTime || this.emergencyMaxAckTime < 0) {
      this.errorFields.emergencyMaxAckTime = true;
      vaild = false;
    }
    if (!this.urgentMaxAckTime || this.urgentMaxAckTime < 0) {
      this.errorFields.urgentMaxAckTime = true;
      vaild = false;
    }
    if (!this.nonUrgentMaxAckTime || this.nonUrgentMaxAckTime < 0) {
      this.errorFields.nonUrgentMaxAckTime = true;
      vaild = false;
    }
    if (vaild) {
      this.resetErrorField();
    } else {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('component.contract-general.message.error.cm-response-time'));
    }
    return vaild;
  }
  //#endregion
}
