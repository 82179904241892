import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JM, JMENUM, JMOBJ, JMREASON } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/core/session';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { StringValidator } from 'src/app/core/string-validator';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';

@Component({
  selector: 'sn-cancel-editor',
  templateUrl: './sn-cancel-editor.component.html',
  styleUrls: ['./sn-cancel-editor.component.scss'],
})
export class SnCancelEditorComponent implements OnInit {
  @Input() sn: JMOBJ.ServiceNotification;
  @Output() updatedSn = new EventEmitter<{
    sn: JMOBJ.ServiceNotification,
    actionList: Array<JMENUM.VpSnAction>
  }>();

  // data
  cancelForm = this.fb.group({
    reason: [undefined, [Validators.required]],
    remarks: ['', [Validators.required, StringValidator.noWhiteSpaceBothEnd]],
  });

  reasonList: {
    code: string;
    label: string;
  }[] = [];

  submitBtn = {
    isLoading: false,
  };

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.getReasonList();
    this.resetForm();
  }

  private resetForm() {
    this.cancelForm.reset({
      reason: undefined,
      remarks: '',
    });
  }

  private getReasonList() {
    const cancelReasons = JMREASON.SN_CANCEL_REASON_LIST.filterReason([
      JMREASON.Attribute.VP,
    ])

    this.reasonList = cancelReasons.map(reason => {
      return {
        code: reason.code.toString(),
        label: Session.selectedLanguage === JMENUM.Language.ZH ? reason.description.zh : reason.description.en
      };
    });
  }

  //------------------------
  // API
  private async requestCancelSn() {
    const request = new JM.JMRequestSnVpCancelSn();
    
    request.snNumber = this.sn.snNumber;
    request.version = this.sn.version;
    request.cancellationCode = this.reason.value;
    request.cancelRemarks = this.remarks.value;

    const response: JM.JMResponseSnVpCancelSn = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.cancelled'));
    
    this.updatedSn.emit({
      sn: response.payload.sn,
      actionList: response.payload.actionList
    });

    this.resetForm();
  }

  //------------------------
  // button callback
  onSubmitBtnClicked() {
    const data = {
      msg: {
        content: 'component.sn-cancel-editor.pop-up.confirm-to-cancel',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestCancelSn();
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(data);
  }

  //------------------------
  // get property
  get reason() {
    return this.cancelForm.get('reason');
  }
  get remarks() {
    return this.cancelForm.get('remarks');
  }

  get inputBoxMaxLength() {
    return Constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }
}
