<div class="d-flex justify-content-between" >
  <div class="font-size-xl">
    <div class="section-title d-inline">{{ "component.notification-info.title" | translate }}</div>
    <div class="contract-number-tag d-inline ml-3">{{ sn?.team?.contractNumber }}</div>
  </div>
  <div class="font-size-xl">{{ snStatus }}</div>
</div>
<hr class="underline" />

<div class="row mb-3 mx-0 align-items-center">
  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.notification-info.label.client" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="notification-info-client" [textField]="client">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label ">
    {{ "component.notification-info.label.contact-number" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="notification-info-contact-number" [textField]="sn?.contactNumber">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label ">
    {{ "component.notification-info.label.contact-person" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="notification-info-contact-person" [textField]="sn?.contactPerson">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.notification-info.label.email" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4 text-break">
    <app-display-text-field id="notification-info-email" [textField]="sn?.email">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.notification-info.label.location" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="notification-info-location" [textField]="location">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.notification-info.label.location-detail" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="notification-info-location-detail" [textField]="sn?.locationDetail">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.notification-info.label.ccs-sn-no" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="notification-info-ccs-sn-no" [textField]="sn?.ccsSnNumber">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.notification-info.label.priority" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="notification-info-priority" [textField]="priority">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.notification-info.label.fault-details-by-client" | translate }}
  </div>
  <div class="col-9 col-md-10 mt-4">
    <app-display-text-field id="notification-info-fault-detail" [textField]="sn?.faultDetail">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.notification-info.label.internal-remarks" | translate }}
  </div>
  <div class="col-9 col-md-10 mt-4">
    <app-display-text-field id="internal-remarks" [textField]="sn?.internalRemarks">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.notification-info.label.equipment-category" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="notification-info-equipment-category" [textField]="equipmentCategory">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.notification-info.label.equipment-type" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="notification-info-equipment-type" [textField]="equipmentType">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.notification-info.label.create-time" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="notification-info-create-time" [textField]="sn?.createdAt | dateTimeWeek">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.notification-info.label.create-by" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="notification-info-create-by" [textField]="sn?.createdBy">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.notification-info.label.handled-by" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="notification-info-handled-by" [textField]="sn?.vendorTeam?.name">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.notification-info.label.initial-system" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <!-- TODO: JMObj sn no sourceSystem -->
    <app-display-text-field id="notification-info-initial-system" [textField]="sn['sourceSystem']">
    </app-display-text-field>
  </div>

  <ng-container *ngIf="false">
    <div class="col-2 mt-4 p-0 text-right font-size-m gray-label">
      {{ "component.notification-info.label.cancel-reason" | translate }}
    </div>
    <div class="col-10 mt-4">
      <!-- TODO: wait SN schema ready -->
      <app-display-text-field id="notification-info-cancel-reason" [textField]="cancelReason">
      </app-display-text-field>
    </div>
  </ng-container>
  
  <ng-container *ngIf="sn.rejectCode">
    <div class="col-2 mt-4 p-0 text-right font-size-m gray-label">
      {{ "component.notification-info.label.reject-reason" | translate }}
    </div>
    <div class="col-10 mt-4">
      <!-- TODO: wait SN schema ready -->
      <app-display-text-field id="notification-info-reject-reason" [textField]="rejectReason">
      </app-display-text-field>
    </div>
  </ng-container>
  
</div>
