<div class="d-flex justify-content-between">
  <div class="font-size-xl">{{ "component.settings-team.office-hour" | translate}}</div>

  <div class="d-flex align-items-center">
    <div class="position-relative">
      <button id="copy-timetable-button" class="" (click)="onClickCopy()">
        <i class="fas fa-copy blue font-size-xl"></i>
      </button>
      <app-chat-bubble-container #copy_hours_panel>
        <app-form-copy-office-hour #copy_hours_form [parameters]="copyHoursParam"></app-form-copy-office-hour>
      </app-chat-bubble-container>
    </div>
    
    <button id="clear-timetable-button" class="" (click)="onClickClear()">
      <i class="fas fa-trash red font-size-xl"></i>
    </button>

    <button class="font-size-xl" id="submit-timetable-button" (click)="onClickSubmitTimetable()">
      <i class="fa fa-check amber "></i>
    </button>

    <div *ngIf="parameters.team" class="font-size-xl">{{ parameters.team['contract']}}</div>
  </div>
</div>
<hr class="underline" />

<div class="timetable-wrap px-5 pt-4">
  <div class="row time-header justify-content-around mx-0 mb-3">
    <div class="col-3 font-size-m" >{{"global.day-of-week" | translate }}</div>
    <div class="col-3 font-size-m px-0" >{{"global.start" | translate }}</div>
    <div class="col-3 font-size-m px-0" >{{"global.end" | translate }}</div>
  </div>
  <div class="row time-input-row justify-content-around mx-0 mb-2">
    <div class="col-3" >{{"global.monday" | translate }}</div>
    <app-time-input class="col-3 px-0" [isError]="errorFields.mondayStartTime" [customClass]="'form-control'"
      [input]="timeObj['mondayStartTime']" [inputId]="'mondayStartTimeInput'" [field]="'mondayStartTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
    <app-time-input class="col-3 px-0" [isError]="errorFields.mondayEndTime" [customClass]="'form-control'"
      [input]="timeObj['mondayEndTime']" [inputId]="'mondayEndTimeInput'" [field]="'mondayEndTime'" 
       [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
  </div>

  <div class="row time-input-row justify-content-around mx-0 mb-2">
    <div class="col-3" >{{"global.tuesday" | translate }}</div>
    <app-time-input class="col-3 px-0" [isError]="errorFields.tuesdayStartTime" [customClass]="'form-control'"
      [input]="timeObj['tuesdayStartTime']" [inputId]="'tuesdayStartTimeInput'" [field]="'tuesdayStartTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
    <app-time-input class="col-3 px-0" [isError]="errorFields.tuesdayEndTime" [customClass]="'form-control'"
      [input]="timeObj['tuesdayEndTime']" [inputId]="'tuesdayEndTimeInput'" [field]="'tuesdayEndTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
  </div>

  <div class="row time-input-row justify-content-around mx-0 mb-2">
    <div class="col-3" >{{"global.wednesday" | translate }}</div>
    <app-time-input class="col-3 px-0" [isError]="errorFields.wednesdayStartTime" [customClass]="'form-control'"
      [input]="timeObj['wednesdayStartTime']" [inputId]="'wednesdayStartTimeInput'" [field]="'wednesdayStartTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
    <app-time-input class="col-3 px-0" [isError]="errorFields.wednesdayEndTime" [customClass]="'form-control'"
      [input]="timeObj['wednesdayEndTime']" [inputId]="'wednesdayEndTimeInput'" [field]="'wednesdayEndTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
  </div>

  <div class="row time-input-row justify-content-around mx-0 mb-2">
    <div class="col-3" >{{"global.thursday" | translate }}</div>
    <app-time-input class="col-3 px-0" [isError]="errorFields.thursdayStartTime" [customClass]="'form-control'"
      [input]="timeObj['thursdayStartTime']" [inputId]="'thursdayStartTimeInput'" [field]="'thursdayStartTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
    <app-time-input class="col-3 px-0" [isError]="errorFields.thursdayEndTime" [customClass]="'form-control'"
      [input]="timeObj['thursdayEndTime']" [inputId]="'thursdayEndTimeInput'" [field]="'thursdayEndTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
  </div>

  <div class="row time-input-row justify-content-around mx-0 mb-2">
    <div class="col-3" >{{"global.friday" | translate }}</div>
    <app-time-input class="col-3 px-0" [isError]="errorFields.fridayStartTime" [customClass]="'form-control'"
      [input]="timeObj['fridayStartTime']" [inputId]="'fridayStartTimeInput'" [field]="'fridayStartTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
    <app-time-input class="col-3 px-0" [isError]="errorFields.fridayEndTime" [customClass]="'form-control'"
      [input]="timeObj['fridayEndTime']" [inputId]="'fridayEndTimeInput'" [field]="'fridayEndTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
  </div>

  <div class="row time-input-row justify-content-around mx-0 mb-2">
    <div class="col-3" >{{"global.saturday" | translate }}</div>
    <app-time-input class="col-3 px-0" [isError]="errorFields.saturdayStartTime" [customClass]="'form-control'"
      [input]="timeObj['saturdayStartTime']" [inputId]="'saturdayStartTimeInput'" [field]="'saturdayStartTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
    <app-time-input class="col-3 px-0" [isError]="errorFields.saturdayEndTime" [customClass]="'form-control'"
      [input]="timeObj['saturdayEndTime']" [inputId]="'saturdayEndTimeInput'" [field]="'saturdayEndTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
  </div>

  <div class="row time-input-row justify-content-around mx-0 mb-2">
    <div class="col-3" >{{"global.sunday" | translate }}</div>
    <app-time-input class="col-3 px-0" [isError]="errorFields.sundayStartTime" [customClass]="'form-control'"
      [input]="timeObj['sundayStartTime']" [inputId]="'sundayStartTimeInput'" [field]="'sundayStartTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
    <app-time-input class="col-3 px-0" [isError]="errorFields.sundayEndTime" [customClass]="'form-control'"
      [input]="timeObj['sundayEndTime']" [inputId]="'sundayEndTimeInput'" [field]="'sundayEndTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
  </div>

  <div class="row time-input-row justify-content-around mx-0 mb-2">
    <div class="col-3" >{{"global.public-holidays" | translate }}</div>
    <app-time-input class="col-3 px-0" [isError]="errorFields.holidayStartTime" [customClass]="'form-control'"
      [input]="timeObj['holidayStartTime']" [inputId]="'holidayStartTimeInput'" [field]="'holidayStartTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
    <app-time-input class="col-3 px-0" [isError]="errorFields.holidayEndTime" [customClass]="'form-control'"
      [input]="timeObj['holidayEndTime']" [inputId]="'holidayEndTimeInput'" [field]="'holidayEndTime'" 
      [alertMsg]="timeAlertMsg" (onBlur)="onBlurTimeInput($event)">
    </app-time-input>
  </div>
</div>