'use strict';

import { Router } from '@angular/router';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/core/session';
import { UserHelper } from 'src/app/core/user-helper';
import { Constants } from 'src/constants';
import { JMLanguage, Language } from 'src/app/core/JMLanguage/JMLanguage';
import { PopUpDialogComponent } from 'src/app/shared/pop-up-dialog/pop-up-dialog.component';
import { ToastMsgComponent } from 'src/app/shared/toast-msg/toast-msg.component';
import { SpecialResponseCode } from '@enum/response-code.enum';
import { Util } from './util';

class AppDelegateClass {
  appInjector = undefined;

  jwt: string = undefined;

  constructor() { }

  getRoute(): string {
    return this.appInjector.get(Router).url;
  }

  routeToPage(page: string, param?: any, extras?: { [x: string]: any }) {
    const commands = param ? [page, param] : [page];
    if (extras) {
      (this.appInjector.get(Router) as Router).navigate(commands, extras);
    } else {
      (this.appInjector.get(Router) as Router).navigate(commands);
    }
  }

  //-----------------------------------------------------------------
  logout() {
    this.jwt = null;
    Session.clear();
    this.routeToPage('/login');
  }

  //-----------------------------------------------------------------
  async login(username: string, password: string, callback: Function) {
    Session.clear();

    let request = new JM.JMRequestAuthLoginV2();
    request.password = password;
    request.postName = username;
    request.systemName = Constants.SYSTEM_NAME;
    const response: JM.JMResponseAuthLoginV2 = await JM.JMConnector.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {

      switch (response.code) {
        case SpecialResponseCode.LOGIN_OTP:
          Session.setOtpDetail(username, response.payload);
          callback(response);
          this.routeToPage('/login-otp');
          return;
        case SpecialResponseCode.VP_URL:
          const redirUrl = response['vp-url'];
          window.location = redirUrl;
          callback(response);
          return;
        case SpecialResponseCode.SUSPENDED_ACCOUNT:
          if (isNaN(response.payload.accountLockSeconds) || response.payload.accountLockSeconds <= 0) {
            AppDelegate.toastMsg().showResponseMsg(response);
          } else {
            let errorMsg = JMLanguage.translate('pages.login.error.suspend', [Util.getMinute(response.payload.accountLockSeconds) + (Util.getMinute(response.payload.accountLockSeconds) != ''
              && Util.getExtraSecond(response.payload.accountLockSeconds) != '' && JMLanguage.getCurrentLanguage() === Language.EN ? ' ' : '') + Util.getExtraSecond(response.payload.accountLockSeconds)])
            AppDelegate.toastMsg().showMsg(errorMsg);   
          }
          callback(response);
          return;
        case SpecialResponseCode.EXPIRED_PASSWORD:
          if (response.payload.post && response.payload.token) {
            const { payload } = response;
            JM.JMConnector.cacheClear();
            JM.JMConnector.setPost(payload.post);
            JM.JMConnector.setToken(payload.token);
            Session.setUserToken(payload.token);
            Session.setUserInfo(payload.post);
            this.routeToPage('/reset/password');
          }
          return null;
        default:
          JM.JMConnector.cacheClear();
          JM.JMConnector.setPost(null);
          JM.JMConnector.setToken(null);
          AppDelegate.toastMsg().showResponseMsg(response);
          callback(response);
          return;
      }
    }
    JM.JMConnector.cacheClear();
    let payload = response.payload;
    if (payload.post && payload.post.authorizations) {
      JM.JMConnector.setToken(payload.token);
      JM.JMConnector.setPost(payload.post);

      Session.setUserInfo(payload.post);
      Session.setUserToken(payload.token);

      Session.clearOtpDetail();
      JM.JMConnector.cacheEquipmentCategory().then((category) => {
        Session.setEquipmentCategory(category);
      });
      JM.JMConnector.cacheEquipmentType().then((type) => {
        Session.setEquipmentType(type);
      });
      JM.JMConnector.cacheAllClient().then((allClient) => {
        Session.setClient(allClient);
      });
      this.requestUserWorkCentres();
      // get feature list
      const requestSystemFeatureList = new JM.JMRequestSystemConfigsGetSystemFeatureList();
      requestSystemFeatureList.systemName = JMENUM.SourceSystem.CCEPVP;
      AppDelegate.sendJMRequest(requestSystemFeatureList).then(res => {
        Session.setFeatureList(res.payload.records);
      });

      this.routeToPage('/home', null, {
        replaceUrl: true,
      });
    } else {
      AppDelegate.toastMsg().showMsg(response);
    }
    callback(response);
  }

  public async requestUserWorkCentres() {
    let request: JM.JMRequestAuthGetPostWorkCentres = new JM.JMRequestAuthGetPostWorkCentres();
    let response = await this.sendJMRequest(request);
    if (response && response.code == 200) {
      Session.setUserWorkCentres(response.payload);
    } else {
      this.toastMsg().showResponseMsg(response);
    }
  }

  //------------------------
  handleJMError(error: JM.JMNetworkError) {
    console.error(error);
    if (error.code === 401 || error.code === 498) {
      this.logout();
    }

    let message = JMLanguage.translate('api.error.' + error.code);
    if (!message) {
      message = JMLanguage.translate('api.error.99999');
    }

    AppDelegate.toastMsg().showMsg(message);
  }

  checkPagePermissions(route?: string): boolean {
    if (!route) {
      route = this.getRoute();
    }

    if (!UserHelper.hasPagePermission(route)) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.permission-denied'));
      this.routeToPage('/home');
      return false;
    }

    return true;
  }

  async sendJMRequest(request: JM.JMRequest) {
    try {
      let response = await JM.JMConnector.sendJMRequest(request);
      if (response.code === 99503) {
        const redirUrl = response['vp-url'];
        window.location = redirUrl;
        return null;
      }
      return response;
    } catch (error) {
      if (error.code === 498 || error.code === 401) {
        this.logout();
      }
      return error;
    }
  }

  async sendJMRequestWithFileHost(request: JM.JMRequest) {
    try {
      let response = await JM.JMConnector.sendJMRequest(request, null, JM.JMConnector.getFileHost());
      return {
        payload: response
      };
    } catch (error) {
      if (error.code === 498 || error.code === 401) {
        this.logout();
      }
      return error;
    }
  }

  async sendJMFileRequest(request, stream) {
    try {
      let response = await JM.JMConnector.sendJMFileRequest(request, stream);
      return response;
    } catch (error) {
      if (error.code === 498 || error.code === 401) {
        this.logout();
      }
      return error;
    }
  }

  async sendJMFileRequestWithFileHost(request, stream) {
    try {
      let response = await JM.JMConnector.sendJMFileRequest(request, stream, null, JM.JMConnector.getFileHost());
      return {
        payload: response
      };
    } catch (error) {
      if (error.code === 498 || error.code === 401) {
        this.logout();
      }
      return error;
    }
  }

  //------------------------
  // toast msg
  toastMsg(): ToastMsgComponent {
    return this.appInjector.get(ToastMsgComponent);
  }

  popUpDialog(): PopUpDialogComponent {
    return this.appInjector.get(PopUpDialogComponent);
  }

  getEquipmentCategory() {
    return Session.getEquipmentCategory();
  }

  getEquipmentType() {
    return Session.getEquipmentType();
  }
}

let AppDelegate = new AppDelegateClass();
export { AppDelegate };
