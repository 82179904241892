/**
 ****************** Deprecated ******************
 ************************************************
 * Key by Posts.systems.CCEPVP.userSettings
 * TODO: Save custom columns field in local.
 ************************************************
 ************************************************
 */
export enum VPUserSettings {
  VP_CONTRACT_LIST_COLUMNS = 'VpContractListColumns', //if no specific page
  VP_TEAM_SETTING_LIST_COLUMNS = 'VpTeamSettingListColumns',
  VP_TEAM_SETTING_MEMBER_LIST_COLUMNS = 'VpTeamSettingMemberListColumns',
  VP_TEAM_SETTING_CONTRACT_LIST_COLUMNS = 'VpTeamSettingContractListColumns',
  VP_CONTRACTOR_SETTING_CONTRACTOR_LIST_COLUMNS = 'VpContractorSettingContractorListColumns',
  VP_CONTRACTOR_SETTING_CONTRACT_LIST_COLUMNS = 'VpContractorSettingContractListColumns',
  VP_CONTRACT_SETTING_CONTRACT_LIST_COLUMNS = 'VpContractSettingContractListColumns',
  VP_CONTRACT_SETTING_CONTRACTOR_LIST_COLUMNS = 'VpContractSettingContractorListColumns',
  VP_CM_TASK_LIST_COLUMNS = 'VpCMTaskListColumns',
  VP_SELECT_EQUIPMENT_LIST_COLUMNS = 'VpSelectEquipmentListColumns',
}
