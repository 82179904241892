import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from 'src/app/shared/tablex/tablexColumnType';
import * as moment from 'moment';
import { saveAs } from "file-saver";
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Constants } from 'src/constants';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { PmJobService } from 'src/app/pm/shared/pm-job.service';

@Component({
  selector: 'app-pm-job-attachment',
  templateUrl: './pm-job-attachment.component.html',
  styleUrls: ['./pm-job-attachment.component.scss']
})
export class PmJobAttachmentComponent implements OnInit {
  @ViewChild('attachment_preview', { static: true }) attachmentPreview;
  @ViewChild('upload_previewer', { static: true }) uploadPreviewer;
  
  pmJobNumber: string;
  pmJob: JMOBJ.PmJob;

  file: any;
  fileList: any = {};
  fileDetails: any = {};

  attachment: JMOBJ.Attachment;
  selectedAttachment: any;
  attachmentList: any = [];

  uploadPreviewParam: any = {};

  navbarTitle: string;
  breadcrumbs: any = [];
  tablexParam: any = {};

  isDisabledAddAttachment: boolean = false;
  isPreviewerLoading: boolean = false;
  hasViewPermission: boolean = false;
  hasEditPermission: boolean = true;
  isShowUploadPreview: boolean = false;

  constructor(private route: ActivatedRoute, private pmJobService: PmJobService) { }

  ngOnInit() {
    const route = AppDelegate.getRoute();
    const trimRoute = route.replace(/^(\/pm\/jobs)(\/.*)(\/attachment)$/g, '$1$3');
    if (!AppDelegate.checkPagePermissions(trimRoute)) {
      return;
    }
    
    this.pmJobNumber = this.route.snapshot.paramMap.get('jobNumber');

    this.initBreadCrumbs();
    this.initTable();
    this.fetchJobData();
  }

  initBreadCrumbs() {
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-pm-job',
        name: JMLanguage.translate('pages.pm-job-view.breadcrumbs.task-list'),
        route: '/pm/jobs',
      },
      {
        id: 'breadcrumbs-pm-job-number',
        name: this.pmJobNumber,
        route: `/pm/jobs/${this.pmJobNumber}`
      },
      {
        id: 'breadcrumbs-attchment',
        name: JMLanguage.translate('action.button.attachment'),
        route: null,
        currentPage: true
      }
    ];
  }

  initPermission(actions:JMENUM.VpPmJobAction[]) {
    if(!(Array.isArray(actions) && actions.includes(JMENUM.VpPmJobAction.ATTACHMENT))) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.permission-denied'));
      AppDelegate.routeToPage('/home');
      return;
    }
  }

  // ----------- API ----------- //
  private async fetchJobData(){
    this.tablexParam.isLoadingTable = true;
    const response: JM.JMResponseVpGetPmJob = await this.pmJobService.getPmJob(this.pmJobNumber);
    this.tablexParam.isLoadingTable = false;
    this.pmJob = response.payload?.pmJob;

    if (response.payload) {
      this.initPermission(response.payload.actionList);
      this.updateJobCard(response.payload.pmJob);
      this.initUploadPreviewer();
    }
  }

  private async requestRemoveAttachment(attachmentId, button?) {
    if (button) button.isLoading = true;
    let response = await this.pmJobService.removeAttachment(this.pmJob, attachmentId);
    if (button) button.isLoading = false;

    this.updateJobCard(response.payload.pmJob);
    this.setPreviewContent(undefined);
    AppDelegate.toastMsg().showMsg(JMLanguage.translate("global.removed"));
  }

  private async requestUploadAttachment(files: Array<File>) {
    if (!(Array.isArray(files) && files.length > 0)) return;

    this.uploadPreviewParam.isLoadingAddAttachment = true;
    
    // do not use parallel call method as will face job version problem
    // const requestPromises = [];
    // for (let file of files) {
    //   requestPromises.push(this.pmJobService.uploadAttachment(this.pmJob, file, file.name));
    // }
    // let results = await Promise.all(requestPromises);

    let updatedJobCard;
    for (let file of files) {
      try {
        let result = await this.pmJobService.uploadAttachment(this.pmJob, file, file.name);
        if (!result) {
          this.uploadPreviewParam.isLoadingAddAttachment = false;
          this.resetUploadPreviewer();
          return
        }
        updatedJobCard = result.payload.pmJob;
        this.pmJob.version = result.payload.pmJob.version;
      } catch (err) { console.error(err); }
    }

    this.uploadPreviewParam.isLoadingAddAttachment = false;
    AppDelegate.toastMsg().showMsg(JMLanguage.translate("global.saved"));
    
    updatedJobCard && this.updateJobCard(updatedJobCard);

    // do not use parallel call method as will face job version problem
    // if(results.length == 1) {
    //   this.updateJobCard(results[0].payload.pmJob);
    // } else {
    //   this.fetchJobData();
    // }
    
    this.resetUploadPreviewer();
  }

  private resetUploadPreviewer(){
    this.uploadPreviewParam.userInput = {};
    this.uploadPreviewer.clearUploadTable();
    this.uploadPreviewer.closeUploadPreview();
  }

  private async requestGetFile(attachmentId, download?, button?) {
    const request = new JM.JMRequestFilesGetFile(attachmentId);

    this.isPreviewerLoading = download ? false : true;
    if (button) button.isLoading = true;
    const response: JM.JMResponseFilesGetFile = await AppDelegate.sendJMRequestWithFileHost(request);
    if (button) button.isLoading = false;
    this.isPreviewerLoading = false;

    if (!response || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (download) {
      saveAs(response.payload, attachmentId);
    } else {
      this.setPreviewContent(response.payload);
    }
  }

  // ----------- UI function ----------- //
  updateJobCard(jobCard) {
    this.pmJob = jobCard;
    
    if (this.pmJob.attachments && this.pmJob.attachments.length > 0) {
      this.pmJob.attachments = this.pmJob.attachments.reverse();
      this.attachmentList = this.pmJob.attachments;
      this.requestGetFile(this.attachmentList[0].attachmentId);
      this.selectedAttachment = this.attachmentList[0];
    }else{
      this.attachmentList = [];
    }
    this.renderTable();
  }

  setPreviewContent(file) {
    if(file){
      this.file = file;
      this.fileDetails = {
        fileName: this.selectedAttachment.attachmentId,
        description: this.selectedAttachment.description,
        fileSize: '',
        uploadedBy: this.selectedAttachment.uploadedBy,
        uploadDate: moment(this.selectedAttachment.uploadedAt).format(Constants.DATETIME_FORMAT_2),
      };

      this.fileDetails.fileSize = this.attachmentPreview.getFileSize(this.file.size);
    }else{
      this.file = undefined;
      this.fileDetails = {};
    }
    
  }

  // ----------- Button function ----------- //
  onClickSubmitAttachment(files: Array<File>) {
    this.requestUploadAttachment(files);
  }

  onDownloadButtonClicked = (button) => {
    this.requestGetFile(button.attachment.attachmentId, true, button);
  }

  onDeleteButtonClicked = (button) => {
    let data = {
      msg: {
        content: 'component.attachment.remove-msg',
      },
      buttons: [
        {
          name: "global.yes",
          handler: () => {
            this.requestRemoveAttachment(button.attachment.attachmentId, button);
          }
        },
        { name: "global.no" }
      ]
    }
    AppDelegate.popUpDialog().open(data);
  }

  // ----------- Init Component ----------- //
  initUploadPreviewer() {
    this.uploadPreviewParam = {
      isDisabledAddAttachment: !this.hasEditPermission,
      isLoadingAddAttachment: false,
      hasEditPermission: this.hasEditPermission,
    }
  }

  // ----------- Tablex UI function ----------- //
  onRowClicked = (index: number, row: any) => {
    this.selectedAttachment = this.attachmentList[index];
    this.requestGetFile(this.selectedAttachment.attachmentId);
  }

  renderTable() {
    this.tablexParam.content = this.attachmentList.map(attachment => {
      let buttons = [
        { "id": "download-button_" + attachment._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": attachment },
      ];
      if (this.hasEditPermission) {
        buttons = [
          { "id": "download-button_" + attachment._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": attachment },
        ];
        this.pmJob.status !== JMENUM.PMJobStatus.COMPLETED && buttons.push({
          "id": "delete-button_" + attachment._id,
          "name": "",
          "class": "delete-button btn p-1",
          "icon": "fas fa-times font-size-l",
          "onClicked": this.onDeleteButtonClicked,
          "attachment": attachment
        });
      }
      return [
        attachment._id,
        attachment.description,
        buttons
      ];
    });
  }

  // ------ Tablex ------ //
  initTable() {
    this.tablexParam = {
      isLoadingTable: true,
      tableRow: "row",
      tableClass: "attachment-table-wrapper",
      tableWrapperClass: "",
      enableSetPageSize: false,
      enablePagination: false,
      onRowClicked: this.onRowClicked,
      headers: [
        {
          id: '_id',
          name: 'id',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "d-none"
        },
        {
          id: 'filename',
          name: 'component.attachment.fileName',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col link-style"
        },
        {
          id: 'action',
          name: 'component.attachment.action',
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-3 justify-content-around"
        }
      ],
      content: []
    };
  }
  // ------ Tablex End ------ //
}
