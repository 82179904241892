<div class="d-flex align-items-center" >
  <div class="font-size-xl">
    {{ 'component.contract-dropdown.contract-label' | translate }}
  </div>
  <div class="font-size-x contract-dropdown-wrapper ml-3">
    <ng-select
      id="contract-dropdown" 
      class="p-0" 
      bindLabel="contractNumber"
      [(ngModel)]="selectedContract" 
      [items]="contractList"
      [clearable]="true"
      [loading]="isLoadingContractList"
      (scrollToEnd)="onContractScrollToEnd()"
      (search)="onSearchContract($event)"
      (change)="onChangedContract($event)"
      (clear)="onClearContract()"
    ></ng-select>
  </div>
</div>