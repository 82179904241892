<div class="d-flex justify-content-between" >
  <div class="font-size-xl">
    {{ "component.cm-task-progress.title" | translate }}
  </div>
</div>
<hr class="underline" />

<div class="row mx-2 gray-bg mt-3 p-3 align-items-center justify-content-between">
  <div class="d-inline-block col-10 p-0">
    <div class="d-inline-block bold mr-2">
      <span class="red" *ngIf="responseToClientTimeInput.isMandatory">#</span>
      {{ "component.cm-task-progress.response-to-client-time" | translate }}
    </div>
    <app-display-text-field *ngIf="isViewMode" class="d-inline-block mx-3" id="task-progress-response-to-client-time" 
      [textField]="jobCard?.responseToClientTime | dateTimeWeek " [emptyHandleDisplay]="emptyHandleNa"
      [ngClass]="{'input-error':responseToClientTimeInput.isError}">
    </app-display-text-field>

    <div *ngIf="!isViewMode" class="d-inline-block row mx-0">
      <app-date-input class="d-inline-block mr-2" [isError]="responseToClientTimeInput.isError" [customClass]="'px-2'"
        [input]="responseToClientTimeInput.dateInput" [inputId]="'response-client-date-input'" 
        (updatedDate)="onUpdatedDate($event, responseToClientTimeInput, 'responseClientTime')">
      </app-date-input>
      <app-time-input class="d-inline-block p-0 col-5 col-sm-3" [isError]="responseToClientTimeInput.isError" [customClass]="'px-1 form-control'"
        [input]="responseToClientTimeInput.timeInput" [inputId]="'response-client-time-input'"
        (updatedTime)="onUpdatedTime($event, responseToClientTimeInput, 'responseClientTime')">
      </app-time-input>
    </div>
  </div>
  
  <div id="task-progress-response-client-status"
    *ngIf="needComplianceStatus"
    [ngClass]="{
      'bold': true,
      'blue': validationResult.isPassResponseClient == complianceStatus.pass,
      'red': validationResult.isPassResponseClient == complianceStatus.fail
    }">{{ "component.cm-task-progress.compliance."+validationResult.isPassResponseClient | translate }}
  </div>
</div>


<div class="row mx-0 justify-content-between align-items-stretch">

  <div class="col-12 col-md-4 mt-3 task-item-container-col px-2">
    <div class="task-item-container row mx-0 py-2">
      <div class="col-12 mb-3">
        <div class="d-inline-block bold">{{ "component.cm-task-progress.response" | translate }}</div>
        <div id="task-progress-response-status"
          *ngIf="needComplianceStatus"
          [ngClass]="{
            'float-right': true,
            'bold': true,
            'blue': validationResult.isPassResponse == complianceStatus.pass,
            'red': validationResult.isPassResponse == complianceStatus.fail
          }">{{ "component.cm-task-progress.compliance."+validationResult.isPassResponse | translate }}
        </div>
      </div>
      <div class="col-12 align-self-end">
        <div class="d-block">
          <span class="red" *ngIf="receivedTimeInput.isMandatory">#</span>
          {{ "component.cm-task-progress.received-time" | translate }}
        </div>
        <app-display-text-field *ngIf="isViewMode" class="d-block mt-1 mx-3 mb-3" id="task-progress-received-time" 
          [textField]="jobCard?.receivedTime | dateTimeWeek " [emptyHandleDisplay]="emptyHandleNa"
          [ngClass]="{'input-error':receivedTimeInput.isError}">
        </app-display-text-field>
        <div *ngIf="!isViewMode" class="row mx-0 align-items-center justify-content-between">
          <app-date-input class="col-12 col-md-8 m-0 p-0" [isError]="receivedTimeInput.isError" [customClass]="'px-2'"
            [input]="receivedTimeInput.dateInput" [inputId]="'received-date-input'" 
            (updatedDate)="onUpdatedDate($event, receivedTimeInput, 'receivedTime')">
          </app-date-input>
          <app-time-input class="col-5 col-md-4 col-xl-3 p-0 mt-2 mt-md-0" [isError]="receivedTimeInput.isError" [customClass]="'px-1 form-control'"
            [input]="receivedTimeInput.timeInput" [inputId]="'received-time-input'" 
            (updatedTime)="onUpdatedTime($event, receivedTimeInput, 'receivedTime')">
          </app-time-input>
        </div>

        <div class="d-block">
          <span class="red" *ngIf="appointmentTimeInput.isMandatory">#</span>
          {{ "component.cm-task-progress.appointed-time" | translate }}
        </div>
        <app-display-text-field *ngIf="isViewMode" class="d-block mt-1 mx-3 mb-3" id="task-progress-appointment-time" 
          [textField]="jobCard?.appointmentTime | dateTimeWeek " [emptyHandleDisplay]="emptyHandleNa"
          [ngClass]="{'input-error':appointmentTimeInput.isError}">
        </app-display-text-field>
        <div *ngIf="!isViewMode" class="row mx-0 align-items-center justify-content-between">
          <app-date-input class="col-12 col-md-8 m-0 p-0" [isError]="appointmentTimeInput.isError" [customClass]="'px-2'"
            [input]="appointmentTimeInput.dateInput" [inputId]="'appointmentDateInput'" 
            (updatedDate)="onUpdatedDate($event, appointmentTimeInput, 'appointmentTime')">
          </app-date-input>
          <app-time-input class="col-5 col-md-4 col-xl-3 p-0 mt-2 mt-md-0" [isError]="appointmentTimeInput.isError" [customClass]="'px-1 form-control'"
            [input]="appointmentTimeInput.timeInput" [inputId]="'appointmentTimeInput'" 
            (updatedTime)="onUpdatedTime($event, appointmentTimeInput, 'appointmentTime')">
          </app-time-input>
        </div>

        <div class="d-block">
          <span class="red" *ngIf="startTimeInput.isMandatory">#</span>
          {{ "component.cm-task-progress.arrival-start-time" | translate }}
          <ng-container *ngIf="isViewMode" >
            <app-arrival-gps [arrivalGpsParam]='arrivalGpsParam'></app-arrival-gps>
          </ng-container>
        </div>
        <app-display-text-field *ngIf="isViewMode" class="d-block mt-1 mx-3 mb-3" id="task-progress-start-time" 
          [textField]="jobCard?.startTime | dateTimeWeek " [emptyHandleDisplay]="emptyHandleNa"
          [ngClass]="{'input-error':startTimeInput.isError}">
        </app-display-text-field>
        <div *ngIf="!isViewMode" class="row mx-0 align-items-center justify-content-between">
          <app-date-input class="col-12 col-md-8 m-0 p-0" [isError]="startTimeInput.isError" [customClass]="'px-2'"
            [input]="startTimeInput.dateInput" [inputId]="'startDateInput'" 
            (updatedDate)="onUpdatedDate($event, startTimeInput, 'startTime')">
          </app-date-input>
          <app-time-input class="col-5 col-md-4 col-xl-3 p-0 mt-2 mt-md-0" [isError]="startTimeInput.isError" [customClass]="'px-1 form-control'"
            [input]="startTimeInput.timeInput" [inputId]="'startTimeInput'" 
            (updatedTime)="onUpdatedTime($event, startTimeInput, 'startTime')">
          </app-time-input>
        </div>
      </div>

    </div>
  </div>
  
  <div class="col-12 col-md-4 mt-3 task-item-container-col px-2">
    <div class="task-item-container row mx-0 py-2">
      <div class="col-12 mb-3">
        <div class="d-inline-block bold">{{ "component.cm-task-progress.rectification" | translate }}</div>
        <div id="task-progress-rectification-status"
          *ngIf="needComplianceStatus"
          [ngClass]="{
            'float-right': true,
            'bold': true,
            'blue': validationResult.isPassRectification == complianceStatus.pass,
            'red': validationResult.isPassRectification == complianceStatus.fail
          }">{{ "component.cm-task-progress.compliance."+validationResult.isPassRectification | translate }}
        </div>
      </div>

      <div class="col-12 align-self-end" >
        <div class="d-block">
          {{ "component.cm-task-progress.planned-completion-time" | translate }}
        </div>
        <app-display-text-field *ngIf="isViewMode" class="d-block mt-1 mx-3 mb-3" id="task-progress-planned-completion-time" 
          [textField]="jobCard?.plannedCompletionTime | dateTimeWeek " [emptyHandleDisplay]="emptyHandleNa"
          [ngClass]="{'input-error':plannedCompletionTimeInput.isError}">
        </app-display-text-field>
        <div *ngIf="!isViewMode" class="row mx-0 align-items-center justify-content-between">
          <app-date-input class="col-12 col-md-8 m-0 p-0" [isError]="plannedCompletionTimeInput.isError" [customClass]="'px-2'"
            [input]="plannedCompletionTimeInput.dateInput" [inputId]="'plannedCompletionDateInput'" 
            (updatedDate)="onUpdatedDate($event, plannedCompletionTimeInput, 'plannedCompletionTime')">
          </app-date-input>
          <app-time-input class="col-5 col-md-4 col-xl-3 p-0 mt-2 mt-md-0" [isError]="plannedCompletionTimeInput.isError" [customClass]="'px-1 form-control'"
            [input]="plannedCompletionTimeInput.timeInput" [inputId]="'plannedCompletionTimeInput'" 
            (updatedTime)="onUpdatedTime($event, plannedCompletionTimeInput, 'plannedCompletionTime')">
          </app-time-input>
        </div>

        <div class="d-block">
          <span class="red" *ngIf="completionTimeInput.isMandatory">#</span>
          {{ "component.cm-task-progress.completion-time" | translate }}
        </div>
        <app-display-text-field *ngIf="isViewMode" class="d-block mt-1 mx-3 mb-3" id="task-progress-completion-time" 
          [textField]="jobCard?.completionTime | dateTimeWeek " [emptyHandleDisplay]="emptyHandleNa"
          [ngClass]="{'input-error':completionTimeInput.isError}">
        </app-display-text-field>
        <div *ngIf="!isViewMode" class="row mx-0 align-items-center justify-content-between">
          <app-date-input class="col-12 col-md-8 m-0 p-0" [isError]="completionTimeInput.isError" [customClass]="'px-2'"
            [input]="completionTimeInput.dateInput" [inputId]="'completionDateInput'" 
            (updatedDate)="onUpdatedDate($event, completionTimeInput, 'completionTime')">
          </app-date-input>
          <app-time-input class="col-5 col-md-4 col-xl-3 p-0 mt-2 mt-md-0" [isError]="completionTimeInput.isError" [customClass]="'px-1 form-control'"
            [input]="completionTimeInput.timeInput" [inputId]="'completionTimeInput'" 
            (updatedTime)="onUpdatedTime($event, completionTimeInput, 'completionTime')">
          </app-time-input>
        </div>
      </div>

    </div>
  </div>

  <div class="col-12 col-md-4 mt-3 task-item-container-col px-2">
    <div class="task-item-container row mx-0 py-2">
      <div class="col-12 mb-3">
        <div class="d-inline-block bold">{{ "component.cm-task-progress.malfunction" | translate }}</div>
        <div class="float-right bold" >
          <div class="custom-checkbox position-relative custom-control d-inline-block mr-2">
            <input type="checkbox" id="malfunction-breakdown-checkbox" class="checkbox custom-control-input p-0 " name="malfunction-breakdown"
              [(ngModel)]="breakdownCheckbox.value" [disabled]="breakdownCheckbox.isDisabled" (change)="onChangedBreakdownCheckbox()" 
            />
            <label for="malfunction-breakdown-checkbox" class="custom-control-label mb-0">
              {{ "component.cm-task-progress.breakdown" | translate }}
            </label>
          </div>
        </div>
      </div>

      <div class="col-12 align-self-end" >
        <div class="d-block">
          <span class="red" *ngIf="malfunctionStartTimeInput.isMandatory">#</span>
          {{ "component.cm-task-progress.malfunction-start-time" | translate }}
        </div>
        <app-display-text-field *ngIf="isViewMode" class="d-block mt-1 mx-3 mb-3" id="task-progress-malfunction-start-time" 
          [textField]="jobCard?.malfunctionStartTime | dateTimeWeek " [emptyHandleDisplay]="emptyHandleNa"
          [ngClass]="{'input-error':malfunctionStartTimeInput.isError}">
        </app-display-text-field>
        <div *ngIf="!isViewMode" class="row mx-0 align-items-center justify-content-between">
          <app-date-input class="col-12 col-md-8 m-0 p-0" [isError]="malfunctionStartTimeInput.isError" [customClass]="'px-2'"
            [input]="malfunctionStartTimeInput.dateInput" [inputId]="'malfunctionStartDateInput'" 
            (updatedDate)="onUpdatedDate($event, malfunctionStartTimeInput, 'malfunctionStartTime')">
          </app-date-input>
          <app-time-input class="col-5 col-md-4 col-xl-3 p-0 mt-2 mt-md-0" [isError]="malfunctionStartTimeInput.isError" [customClass]="'px-1 form-control'"
            [input]="malfunctionStartTimeInput.timeInput" [inputId]="'malfunctionStartTimeInput'" 
            (updatedTime)="onUpdatedTime($event, malfunctionStartTimeInput, 'malfunctionStartTime')">
          </app-time-input>
        </div>

        <div class="d-block">
          <span class="red" *ngIf="malfunctionEndTimeInput.isMandatory">#</span>
          {{ "component.cm-task-progress.malfunction-end-time" | translate }}
        </div>
        <app-display-text-field *ngIf="isViewMode" class="d-block mt-1 mx-3 mb-3" id="task-progress-malfunction-end-time" 
          [textField]="jobCard?.malfunctionEndTime | dateTimeWeek " [emptyHandleDisplay]="emptyHandleNa"
          [ngClass]="{'input-error':malfunctionEndTimeInput.isError}">
        </app-display-text-field>
        <div *ngIf="!isViewMode" class="row mx-0 align-items-center justify-content-between">
          <app-date-input class="col-12 col-md-8 m-0 p-0" [isError]="malfunctionEndTimeInput.isError" [customClass]="'px-2'"
            [input]="malfunctionEndTimeInput.dateInput" [inputId]="'malfunctionEndDateInput'" 
            (updatedDate)="onUpdatedDate($event, malfunctionEndTimeInput, 'malfunctionEndTime')">
          </app-date-input>
          <app-time-input class="col-5 col-md-4 col-xl-3 p-0 mt-2 mt-md-0" [isError]="malfunctionEndTimeInput.isError" [customClass]="'px-1 form-control'" 
            [input]="malfunctionEndTimeInput.timeInput" [inputId]="'malfunctionEndTimeInput'" 
            (updatedTime)="onUpdatedTime($event, malfunctionEndTimeInput, 'malfunctionEndTime')">
          </app-time-input>
        </div>
      </div>

    </div>
  </div>

</div>
