/*

version 20210210:
 - added data type in function open

*/

import { Component, Injectable, Input } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PopUpDialogI, PopUpDialogButtonI, PopUpDetailDialogI, DialogContentType } from './pop-up-dialog.model';

@Component({
  selector: 'app-pop-up-dialog',
  templateUrl: './pop-up-dialog.component.html',
  styleUrls: ['./pop-up-dialog.component.scss'],
})
@Injectable({
  providedIn: 'root',
})
export class PopUpDialogComponent {
  @Input() msg;
  @Input() buttons: PopUpDialogButtonI[];
  contentType: DialogContentType = DialogContentType.normal;

  constructor(private modalService: NgbModal) {}

  open(data?: PopUpDialogI) {
    this.contentType = DialogContentType.normal;
    const modalRef = this.modalService.open(PopUpDialogComponent, { backdrop: true, keyboard: false, centered: true });
    modalRef.componentInstance.msg = data.msg;
    modalRef.componentInstance.buttons = data.buttons;
  }

  openDetailModal(data?: PopUpDetailDialogI, config?: NgbModalOptions): NgbModalRef {
    this.contentType = DialogContentType.detail;
    const modelConfig: NgbModalOptions = { backdrop: true, keyboard: false, centered: true };
    Object.assign(modelConfig, config);
    const modalRef = this.modalService.open(PopUpDialogComponent, modelConfig);
    modalRef.componentInstance.contentType = DialogContentType.detail;
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.buttons = data.buttons;
    return modalRef;
  }

  close(result: any) {
    this.modalService.dismissAll(result);
  }

  buttonClicked(button) {
    this.close(button?.name);
    if (button.handler != null) {
      button.handler();
    }
  }
}
