<div id="settingUserEmsdComponent" class="wrapper">
  <app-nav [navbarTitle]="'pages.setting-user-emsd.title' | translate"></app-nav>
  <app-sidebar [contentType]="'settings'"></app-sidebar>

  <div id="content" class="content">
    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column flex-grow-1 pr-2">
        <div class="d-flex justify-content-between mx-0">
          <div class="title">{{ 'pages.setting-user-emsd.user-list' | translate }}</div>

          <app-icon-button
            *ngIf="!isComponentUEEModeCreate && hasEmsdUserCreatePermission"
            [id]="'user-manage-add-user-button'"
            [color]="'blue'"
            [icon]="'fa-plus'"
            (onClick)="onClickedCreateMode()"
          >
          </app-icon-button>
        </div>

        <hr class="underline" />

        <app-tablex id="user-emsd-table" [tablexParam]="tablexParam"></app-tablex>
      </div>

      <div class="col-3 pr-0 pl-4">
        <app-user-emsd-edit
          *ngIf="hasEmsdUserUpdatePermission || hasEmsdUserCreatePermission"
          [componentMode]="componentUEEMode"
          [ngClass]="{ hidden: !shouldComponentUEEShow }"
          [user]="selectedUser"
          (submitSuccess)="onSubmitSuccess($event)"
        >
        </app-user-emsd-edit>
      </div>
    </div>
  </div>
</div>
