import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { inputNumber } from 'src/app/core/Formatter';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Util } from 'src/app/core/util';

@Component({
  selector: 'app-form-add-team',
  templateUrl: './form-add-team.component.html',
  styleUrls: ['./form-add-team.component.scss'],
})
export class FormAddTeamComponent implements OnInit {
  @Input() isEditMode: boolean = false;
  @Input() vendorNumber?: string;
  @Output() updatedVendorTeam = new EventEmitter<JMOBJ.VendorTeam>();

  inputNumber = inputNumber;

  // UI
  mode: 'edit' | 'add';
  userInput = {
    _id: '',
    name: '',
    contractList: [],
    email: '',
    phone: '',
    fax: '',
  };
  errorFields: any = {};
  contractListOptions: string[] = [];
  showNoDataText: boolean = false;
  isSavingTeam: boolean = false;
  isLoadingContract: boolean = false;

  contractDict: any = {};

  constructor() {}

  ngOnInit(): void {
    this.mode = this.isEditMode ? 'edit' : 'add';
  }

  // ----------- paret component init function ----------- //
  initTeamCreationForm() {
    this.userInput = { ...new JMOBJ.VendorTeam() };
    this.resetForm();
  }

  async initTeamEditionForm(vendorTeamId: string) {
    // get vendor team
    let request = new JM.JMRequestContractsGetVendorTeam();
    request.id = vendorTeamId;

    const response: JM.JMResponseContractsGetVendorTeam = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      this.showNoDataText = true;
      return;
    }

    this.userInput = { ...response.payload };
    this.resetForm();
  }

  private resetForm() {
    this.errorFields = {};

    if (this.vendorNumber) {
      this.requestContractNumbersByVendor(this.vendorNumber);
    }
  }

  // ----------- API ----------- //
  private async requestContractNumbersByVendor(vendorNumber: string) {
    let request = new JM.JMRequestContractsGetContractNumbersByVendor();
    request.vendorNumber = vendorNumber;

    this.isLoadingContract = true;
    const response: JM.JMResponseContractsGetContractNumbersByVendor = await AppDelegate.sendJMRequest(request);
    this.isLoadingContract = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.contractList) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.contractListOptions = response.payload.contractList;
  }

  private async requestAddTeam() {
    let request = new JM.JMRequestContractsAddVendorTeam();
    request.vendorNumber = this.vendorNumber;
    request.name = this.userInput.name.trim();
    request.contractList = this.userInput.contractList;
    request.email = this.userInput.email.trim();
    request.phone = this.userInput.phone.trim();
    request.fax = !!this.userInput.fax && this.userInput.fax.trim().length ? this.userInput.fax.trim() : undefined;

    this.isSavingTeam = true;
    const response: JM.JMResponseContractsAddVendorTeam = await AppDelegate.sendJMRequest(request);
    this.isSavingTeam = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.updatedVendorTeam.emit(response.payload);
    AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.settings-team.added-team'));
  }

  private async requestUpdateTeam() {
    let request = new JM.JMRequestContractsUpdateVendorTeam();
    request.id = this.userInput._id;
    request.vendorNumber = this.vendorNumber;
    request.name = this.userInput.name.trim();
    request.contractList = this.userInput.contractList;
    request.email = this.userInput.email.trim();
    request.phone = this.userInput.phone.trim();
    request.fax = !!this.userInput.fax && this.userInput.fax.trim().length ? this.userInput.fax.trim() : null;

    this.isSavingTeam = true;
    const response: JM.JMResponseContractsUpdateVendorTeam = await AppDelegate.sendJMRequest(request);
    this.isSavingTeam = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.settings-team.updated-team'));
    this.updatedVendorTeam.emit(response.payload);
  }

  // ----------- UI function ----------- //
  onClickedSaveButton() {
    this.validateForm();
  }

  onChangeEmail() {
    if (!Util.isValidEmail(this.userInput.email)) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.invalid-email-format'));
    }
  }

  private popupSaveComfirmationBox() {
    let button = {
      msg: {
        content: 'popup.confirm-to-save',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            if (this.isEditMode) {
              this.requestUpdateTeam();
            } else {
              this.requestAddTeam();
            }
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(button);
  }

  private validateForm() {
    const isValidName = !!this.userInput.name && this.userInput.name.trim().length > 0;
    const isValidContractList = this.userInput.contractList && this.userInput.contractList.length > 0;
    const isValidEmail = !!this.userInput.email && Util.isValidEmail(this.userInput.email);
    const isValidPhone = !!this.userInput.phone && this.userInput.phone.trim().length > 0;

    const isValidForm = isValidName && isValidContractList && isValidEmail && isValidPhone;

    if (isValidForm) {
      this.popupSaveComfirmationBox();
    } else {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.missing-mandatory-fields'));
      this.errorFields = {
        name: !isValidName,
        contractList: !isValidContractList,
        email: !isValidEmail,
        phone: !isValidPhone,
      };
    }
  }
}
