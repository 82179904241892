<div class="contractor-list-table-container"> 
  <div class="d-flex justify-content-between" >
    <div class="font-size-xl">
      {{ "component.settings-contractor.contractor-list" | translate}}
    </div>
  
    <div class="d-flex">
      <button class="mr-2 font-size-xl" (click)="onClickDownload()">
        <div *ngIf="isDownloading" class="d-flex align-items-center loading-spinner spinner-small blue"></div>
        <i *ngIf="!isDownloading" class="fa fa-download blue"></i>
      </button>
      <!-- <div class="search-box mb-1">
        <input type="text" class="form-control" />
          <i class="fas fa-search blue" (click)="onClickSearch()"></i>
      </div> -->
    </div>
  </div>
  <hr class="underline" />
  
  <app-tablex class="custom-tablex" [tablexParam]="tablexParam"></app-tablex>
</div>
