import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { UserHelper } from 'src/app/core/user-helper';
import { ActionButtonI } from 'src/app/shared/action-sidebar-v2/action-sidebar-v2.model';
import { ActionSidebarV2Service } from '../../shared/action-sidebar-v2.service';
import { PmJobService } from 'src/app/pm/shared/pm-job.service'
import { PmJobEditActionButtonList } from './pm-job-edit.model';

import { PmJobDescriptionComponent } from '../shared/pm-job-description/pm-job-description.component';
import { PmJobClientInfoComponent } from '../shared/pm-job-client-info/pm-job-client-info.component';
import { PmJobProgressComponent } from '../shared/pm-job-progress/pm-job-progress.component';
import { PmJobEquipmentInfoComponent } from '../shared/pm-job-equipment-info/pm-job-equipment-info.component';

@Component({
  selector: 'app-pm-job-edit',
  templateUrl: './pm-job-edit.component.html',
  styleUrls: ['./pm-job-edit.component.scss']
})
export class PmJobEditComponent implements OnInit {
  @ViewChild('pmJobDescriptionElem', { static: false }) pmJobDescriptionElem: PmJobDescriptionComponent;
  @ViewChild('pmJobClientInfoElem', { static: false }) pmJobClientInfoElem: PmJobClientInfoComponent;
  @ViewChild('pmJobProgressElem', { static: false }) pmJobProgressElem: PmJobProgressComponent;
  @ViewChild('pmJobEquipmentInfoElem', { static: false }) pmJobEquipmentInfoElem: PmJobEquipmentInfoComponent;

  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.EDIT;
  breadcrumbs: any = [];
  jobNumber: string;
  pmJob: JMOBJ.PmJob = new JMOBJ.PmJob();

  // action buttons
  actionSidebar: {
    dataList: ActionButtonI[];
    isDisabled: boolean;
    isLoading: boolean;
  } = {
    dataList: [],
    isDisabled: false,
    isLoading: false,
  };

  constructor(private route: ActivatedRoute, private actionSidebarV2Service: ActionSidebarV2Service, private pmJobService: PmJobService) { }

  ngOnInit(): void {
    const route = AppDelegate.getRoute();
    const trimRoute = route.replace(/^(\/pm\/jobs)(\/.*)(\/edit)$/g, '$1$3');

    if (!AppDelegate.checkPagePermissions(trimRoute)) {
      return;
    }
    this.initPage();
  }

  async initPage() {
    this.jobNumber = this.route.snapshot.paramMap.get('jobNumber');

    if (this.jobNumber) {
      this.initBreadCrumbs();
      this.fetchJobData();
    }
  };

  initBreadCrumbs() {
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-pm-job',
        name: JMLanguage.translate('pages.pm-job-view.breadcrumbs.task-list'),
        route: '/pm/jobs',
      },
      {
        id: 'breadcrumbs-pm-job-number',
        name: this.jobNumber,
        route: `/pm/jobs/${this.jobNumber}`,
      },
      {
        id: 'breadcrumbs-pm-job-edit',
        name: JMLanguage.translate('action.button.edit'),
        route: null,
        currentPage: true,
      },
    ];
  }

  triggerSubmitButtonAction() {
    if(this.pmJobDescriptionElem.validate() && this.pmJobClientInfoElem.validate()
    && this.pmJobProgressElem.validate()) {
      const data = {
        msg: {
          content: 'action.button.popup.confirm-submit',
        },
        buttons: [
          {
            name: 'global.yes',
            handler: async () => {
              const response = await this.pmJobService.submitPmJob(this.pmJob);
              if (response?.payload) {
                AppDelegate.routeToPage(`/pm/jobs/${response.payload.pmJob.pmJobNumber}`);
              }
            },
          },
          {
            name: 'global.no',
            handler: null,
          },
        ],
      };
      AppDelegate.popUpDialog().open(data);
    }
  }

  // API
  async fetchJobData() {
    const response: JM.JMResponseVpGetPmJob = await this.pmJobService.getPmJob(this.jobNumber);
    this.pmJob = response?.payload?.pmJob;
    if (Array.isArray(response.payload?.actionList) && response.payload.actionList.length > 0) {
      this.resetActionBar(response.payload.actionList);
    }
  }

  async requestSavePmJob() {
    let response = await this.pmJobService.savePmJob(this.pmJob);
    return response;
  }

  // ----------- action button function -----------
  onClickedActionButton(actionButton: ActionButtonI) {
    actionButton.handler();
  }

  private resetActionBar(apiActionList): void {
    this.actionSidebar.dataList = [];
    const constActionList = [
      'clear',
      'close',
    ];

    const actionList = Array.isArray(apiActionList) && apiActionList.length ? [
      ...apiActionList,
      ...constActionList,
    ] : constActionList;

    this.filterActionButton(actionList);
  }

  private filterActionButton(actionList: string[]) {
    const sortedButtonList = [
      JMENUM.VpPmJobAction.UPDATE,
      'clear',
      JMENUM.VpPmJobAction.SUBMIT,
      JMENUM.VpPmJobAction.EQUIPMENT,
      'close',
    ];

    for (const sortedButton of sortedButtonList) {
      const showActionButton = actionList.includes(sortedButton);

      if (showActionButton) {
        const button: ActionButtonI = PmJobEditActionButtonList[sortedButton];
        const hasPermissionList = button && button.permissionList && button.permissionList.length;

        if (hasPermissionList) {
          const allowAction = UserHelper.hasEitherOnePermission(button.permissionList);
          if (allowAction) {
            this.addActionButton(button);
          }
        } else if (button) {
          this.addActionButton(button);
        }
      }
    }
  }

  private addActionButton(actionButton: ActionButtonI) {
    let handler = () => {};

    switch (actionButton.action) {
      case JMENUM.VpPmJobAction.UPDATE:
        handler = async () => {
          if(this.validationFieldsOnAllComponents()) {
            let response = await this.requestSavePmJob();
            if (response.payload?.pmJob?.pmJobNumber) {
              AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.saved'));
              AppDelegate.routeToPage(`/pm/jobs/${response.payload.pmJob.pmJobNumber}`);
            }
          };
        };
        break;
      case 'clear':
        handler = () => {
          const buttonHandler = () => {
            this.pmJobDescriptionElem.clearAll();
            this.pmJobClientInfoElem.clearAll();
            this.pmJobProgressElem.clearAll();
          };
          this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, buttonHandler);
        };
        break;
      case JMENUM.VpPmJobAction.SUBMIT:
        handler = async () => {
          if(Array.isArray(this.pmJob.equipmentList) && this.pmJob.equipmentList.length > 0) {
            this.triggerSubmitButtonAction();
          } else {
            AppDelegate.toastMsg().showMsg(JMLanguage.translate("pages.pm-job.popup.message.mandatory-equipment"));
          }
        };
        break;
      case 'close':
        handler = () => {
          const buttonHandler = () => {
            AppDelegate.routeToPage(`/pm/jobs/${this.jobNumber}`);
          }
          this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, buttonHandler);
        };
        break;
      case JMENUM.VpPmJobAction.EQUIPMENT:
        handler = async () => {
          if(this.validationFieldsOnAllComponents()) {
            const response = await this.requestSavePmJob();
            if (response && response.payload && response.payload.pmJob.pmJobNumber) {
              AppDelegate.routeToPage(`/pm/jobs/${this.jobNumber}/equipment-list`);
            }
          }
        };
        break;
      default:
        break;
    }

    actionButton.handler = handler;
    this.actionSidebar.dataList.push(actionButton);
  }

  validationFieldsOnAllComponents() {
    const validDescription = this.pmJobDescriptionElem.validate();
    const validClient = this.pmJobClientInfoElem.validate();
    const validProgress = this.pmJobProgressElem.validate();

    return validDescription && validClient && validProgress;
  }
}
