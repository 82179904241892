import { Component, OnInit, ViewChild } from '@angular/core';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMOBJ } from '@ccep/CCEPConnector-ts';
import { VPUserSettings } from '@enum/vp-user-settings.enum';
import { ContractListByContractComponent } from '../contract-list-by-contract/contract-list-by-contract.component';

@Component({
  selector: 'app-setting-contract',
  templateUrl: './setting-contract.component.html',
  styleUrls: ['./setting-contract.component.scss'],
})
export class SettingContractComponent implements OnInit {
  @ViewChild('contract_list_table', { static: false }) contractListTable: ContractListByContractComponent;
  @ViewChild('contract_setting_general', { static: false }) contractSettingGeneral;
  @ViewChild('contract_setting_team_list', { static: false }) contractSettingTeamList;

  currentContract?: JMOBJ.MaintenanceTermContract;
  // disabledContractList = false;
  // contractListParam = {};

  constructor() {}

  ngOnInit(): void {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }
    // this.initTable();
  }

  // initTable() {
  //   // this.contractListParam['defaultRequestSummary'] = true;
  //   this.contractListParam['enableSearch'] = false;
  //   this.contractListParam['enableDownload'] = false;
  //   // this.contractListParam['userSettingListName'] = VPUserSettings.VP_CONTRACT_SETTING_CONTRACT_LIST_COLUMNS;
  // }

  // UI event
  onAssignedContract(contract?: JMOBJ.MaintenanceTermContract) {
    this.currentContract = contract;
  }

  onUpdatedContract() {
    this.contractListTable.requestContractList();
  }
}
