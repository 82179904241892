import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import * as moment from 'moment';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { formatDate, ngbDateToString, stringToNgbDate } from 'src/app/core/Formatter';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Session } from 'src/app/core/session';
import { PmJobSearchCriteriaComponent } from '../../shared/pm-job-search-criteria/pm-job-search-criteria.component'
import { Util } from 'src/app/core/util';
@Component({
  selector: 'app-pm-plan-periods',
  templateUrl: './pm-plan-periods.component.html',
  styleUrls: ['./pm-plan-periods.component.scss'],
})
export class PmPlanPeriodsComponent implements OnInit {
  @Input() plan;
  @Input() pageMode: JMENUM.JMPageMode;
  @Output() onRefreshPeriod: EventEmitter<any> = new EventEmitter();

  FormatDate = formatDate;
  periodPagination = {
    pageNumber: 1,
    totalPageCount: 1,
    pageSize: 1000,
  }

  searchSelections : PmJobSearchCriteriaComponent['searchSelections'] = {
    contractNumber: [],
    pmPlanNumber: null,
    pmPeriod: null,
    pmJobNumber: null,
    jobDescription: null,
    equipmentNumber: null,
    status: {},
    scheduleType: {},
    startRange: null,
    startDate: null,
    endRange: null,
    endDate: null,
    frequency: [],
    handlingTeam: [],
    createTimeFrom: null,
    createTimeTo: null,
    createdBy: null
  }
  
  //For HTML
  PMPlanFrequency = JMENUM.PMPlanFrequency;
  PlanCoverage = JMENUM.PMPlanCoverage;
  ScheduleType = JMENUM.PMScheduleType;
  PMStatus = JMENUM.PMStatus;
  JMPageMode = JMENUM.JMPageMode;

  isDisabledReset: boolean = true;

  // Validation
  valid: boolean;
  errorFields: any = {};
  editable: any = {};
  mandatory: any = {};

  // Input Fields
  overhaulDateNgb: any = [];

  //Period Status dropdown
  statusOptions: any = [];
  selectedStatus: string;

  // Cache
  periodEquipmentCountMap = new Map<string, number>(); // key: periodId, value: periodEquipmentCount

  constructor() {}

  ngOnInit() {
    this.statusOptions = [
      { label: JMLanguage.translate('pm-period.status.completed'), value: JMENUM.PMStatus.COMPLETED },
      { label: JMLanguage.translate('pm-period.status.in_progress'), value: JMENUM.PMStatus.IN_PROGRESS },
      { label: JMLanguage.translate('pm-period.status.outstanding'), value: JMENUM.PMStatus.OUTSTANDING },
    ];
  }

  ngOnChanges() {
    this.requestPmPeriodList(this.periodPagination.pageNumber).then(async (records) => {
      this.plan.periods = records;

      while (this.periodPagination.pageNumber < this.periodPagination.totalPageCount) {
        const additionalPeriods = await this.requestPmPeriodList(++this.periodPagination.pageNumber);
        this.plan.periods = [
          ...this.plan.periods,
          ...additionalPeriods,
        ];
      }
      this.initOverhaulDateInput();
    });
    // this.requestPmPeriodList().then(_ => {
    //   this.requestPmPeriodEquipmentCount();
    // });
  }

  // ----------- API ----------- //
  private async requestPmPeriodList(pageNumber: number){
    if (!this.plan['pmPlanNumber']) return;
    const request = new JM.JMRequestVpGetPmPeriodList();
    request.pmPlanNumber = [this.plan.pmPlanNumber];
    request.pageSize = this.periodPagination.pageSize;
    pageNumber && (request.pageNumber = pageNumber);
    request.includeEquipmentCount = true;

    const response: JM.JMResponseVpGetPmPeriodList = await AppDelegate.sendJMRequest(request);
    if (response?.code != 200 || response.error || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    pageNumber == 1 && (this.periodPagination.totalPageCount = Math.ceil(response.payload.totalCount / this.periodPagination.pageSize));
    return response.payload.records;
  }

  private async requestPmPeriodEquipmentCount(){
    if(!this.plan['pmPlanNumber']) return;
    let request: JM.JMRequestVpGetPmPeriodEquipmentCount = new JM.JMRequestVpGetPmPeriodEquipmentCount();
    request.pmPlanNumber = this.plan.pmPlanNumber;

    const response: JM.JMResponseVpGetPmPeriodEquipmentCount = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    for (const record of response.payload.records) {
      this.periodEquipmentCountMap.set(record.periodId, record.equipmentCount);
    }
    
    this.plan.periods.forEach(period => {
      const periodId = period.periodId;
      period.equipmentCount = this.periodEquipmentCountMap.has(periodId) ? this.periodEquipmentCountMap.get(periodId) : 0;
    });
  }

  public requestPmPeriodCalculate() {
    if (this.plan.status && this.plan.status != this.PMStatus.DRAFT) return;
    let request: JM.JMRequestPmPlanPmPeriodCalculate = new JM.JMRequestPmPlanPmPeriodCalculate();
    request.frequency = this.plan.frequency;
    request.scheduleType = this.plan.scheduleType;
    request.startDate = this.plan.startDate;
    request.endDate = this.plan.endDate;
    if (this.plan.pmPlanNumber) {
      request.pmPlanNumber = this.plan.pmPlanNumber;
    }
    if (
      (this.plan.scheduleType == this.ScheduleType.OVERHAUL || this.plan.scheduleType == this.ScheduleType.NORMAL) &&
      this.plan.secondStartDate
    ) {
      request.secondStartDate = this.plan.secondStartDate;
    }

    JM.JMConnector.sendPmPlanPmPeriodCalculate(
      request,
      (error: JM.JMNetworkError, response: JM.JMResponsePmPlanPmPeriodCalculate) => {
        if (error) {
          AppDelegate.handleJMError(error);
          return;
        }
        if (!response || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.toastMsg().showResponseMsg(response);
          return;
        }

        this.plan.periods = response.payload;
        this.initOverhaulDateInput();
      }
    );
  }

  // ----------- UI function ----------- //
  public toEquipmentListPage(periodId) {
    periodId && AppDelegate.routeToPage(`/pm/periods/${this.plan.pmPlanNumber}/${periodId}/equipment-list`);
  }

  public initOverhaulDateInput() {
    if (this.plan.scheduleType == this.ScheduleType.OVERHAUL) {
      this.overhaulDateNgb = [];
      this.plan.periods.forEach((record, r) => {
        if (record.index == 1) {
          if (record.status == this.PMStatus.IN_PROGRESS) {
            this.isDisabledReset = true;
          } else {
            this.isDisabledReset = false;
          }
        }
        this.overhaulDateNgb.push({
          index: record.index,
          startDate: stringToNgbDate(record.overhaulStartDate, 'YYYYMMDD'),
          endDate: stringToNgbDate(record.overhaulEndDate, 'YYYYMMDD'),
          isErrorStartDate: false,
          isErrorEndDate: false,
        });
      });
    }
  }

  public onBlurStartDateInput(event) {
    this.plan.periods.find((period) => {
      if (period.index == event.fieldName) {
        period['overhaulStartDate'] = ngbDateToString(event.date);
        if (this.validateOverhaulStarEnd(period, true) || this.validateOverhaulStartToPeriod(period)) {
          this.overhaulDateNgb.find((field) => field.index)['isErrorStartDate'] = true;
        } else {
          this.overhaulDateNgb.find((field) => field.index)['isErrorStartDate'] = false;
        }
      }
    });
  }

  public onBlurEndDateInput(event) {
    this.plan.periods.find((period) => {
      if (period.index == event.fieldName) {
        period['overhaulEndDate'] = ngbDateToString(event.date);
        if (this.validateOverhaulStarEnd(period) || this.validateOverhaulEndToPeriod(period)) {
          this.overhaulDateNgb.find((field) => field.index)['isErrorEndDate'] = true;
        } else {
          this.overhaulDateNgb.find((field) => field.index)['isErrorEndDate'] = false;
        }
      }
    });
  }

  public onClickRefresh() {
    if (
      ((this.plan.frequency && this.plan.scheduleType) || this.plan.scheduleType == this.ScheduleType.ONCE) &&
      this.plan.startDate &&
      this.plan.endDate
    ) {
      this.onRefreshPeriod.emit();
    } else {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.pm-plan.toast.please-fill-in-mandatory-fields'));
    }
  }

  public clearAll() {
    this.plan.periods = undefined;
  }

  public validateOverhaulStarEnd(period, isStartDate?) {
    // check if overhaulStartDate < overhaulEndDate
    if (period['overhaulStartDate'] && period['overhaulEndDate']) {
      let differ = moment(period['overhaulEndDate'], 'YYYYMMDD').diff(
        moment(period['overhaulStartDate'], 'YYYYMMDD'),
        'days'
      );
      if (differ <= 0) {
        // TODO: check error
        AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.pm-plan.alert-invalid-overhaul'));
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  public validateOverhaulStartToPeriod(period) {
    // check if periodStartDate < overhaulStartDate
    if (period['overhaulStartDate'] && period['periodStartDate']) {
      let differ = moment(period['overhaulStartDate'], 'YYYYMMDD').diff(moment(period['periodStartDate']), 'days');
      if (differ < 0) {
        AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.pm-plan.alert-invalid-overhaul'));
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  public validateOverhaulEndToPeriod(period) {
    // check if periodEndDate < overhaulEndDate
    if (period['overhaulEndDate'] && period['periodEndDate']) {
      let differ = moment(period['periodEndDate']).diff(moment(period['overhaulEndDate'], 'YYYYMMDD'), 'days');
      if (differ < 0) {
        AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.pm-plan.alert-invalid-overhaul'));
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  onNumberOfJobsClick(data){
    this.clearPmJobListSettingsFiltersSession();
    this.setPmJobListSettingsFilters(data);
    this.saveFilterOptionsToLocal();
    this.toPmJobList();
  }
  
  private clearPmJobListSettingsFiltersSession(){
    Session.setPmJobSettingsSearchFilters(null);
  }


  private setPmJobListSettingsFilters(data){
    this.searchSelections = {
      ...this.searchSelections,
      pmPeriod: data._id? data._id:null,
      pmPlanNumber:data.pmPlanNumber? data.pmPlanNumber:null,
      contractNumber:data.pmPlanContract?.contractNumber? [data.pmPlanContract.contractNumber]:[],
    }
  }

  private saveFilterOptionsToLocal = (): void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.searchSelections));
      delete cache.frequencyOptions;
      Session.setPmJobSettingsSearchFilters(cache);
    } catch (e) {
      console.warn(e);
    }
  };

  toPmJobList() {
    AppDelegate.routeToPage(`pm/jobs`);
  }

  private isNormalOncePeroidExpired(period): boolean {
    if (this.plan.scheduleType == JMENUM.PMScheduleType.NORMAL || this.plan.scheduleType == JMENUM.PMScheduleType.ONCE) {
      let currentDate = Util.getToday();
      if (period.status === "outstanding" && moment(period.periodEndDate).isBefore(currentDate)) {
        return true;
      }
    }
  }

  private isOverhaulPeroidExpired(period): boolean {
    if (this.plan.scheduleType == JMENUM.PMScheduleType.OVERHAUL) {
      let currentDate = Util.getToday();
      if (period.status === "outstanding" && moment(period.overhaulEndDate).isBefore(currentDate)) {
        return true;
      }
    }
  }

}
