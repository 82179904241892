import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-bubble-container',
  templateUrl: './chat-bubble-container.component.html',
  styleUrls: ['./chat-bubble-container.component.scss'],
})
export class ChatBubbleContainerComponent implements OnInit {
  @Input() isClickBgToClose: boolean = true;

  active: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  dismiss(event) {
    this.close(event);
  }

  toggle(event?) {
    if (this.active) this.close(event);
    else this.show(event);
  }

  show(event?) {
    if (event) event.stopPropagation();
    this.active = true;
  }

  close(event?) {
    if (event) event.stopPropagation();
    this.active = false;
  }
}
