import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'slider-panel',
  templateUrl: './slider-panel.component.html',
  styleUrls: ['./slider-panel.component.scss'],
})
export class SliderPanelComponent implements OnInit {
  @Input() widthPx: number; // width px
  @Input() customClass: String;
  active: boolean = false;

  defaultWidthPx: number = 400; // default width px

  ngOnInit() {
    if (!this.widthPx) this.widthPx = this.defaultWidthPx;
  }

  ngOnDestroy() {}

  ngOnChanges() {}

  dismiss(event) {
    this.close(event);
  }

  //===================================================================================================================
  //
  toggle(event?) {
    if (this.active) this.close(event);
    else this.show(event);
  }

  show(event?) {
    if (event) event.stopPropagation();
    this.active = true;
  }

  close(event?) {
    if (event) event.stopPropagation();
    this.active = false;
  }
}
