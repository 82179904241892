import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pm-period-search-criteria',
  templateUrl: './pm-period-search-criteria.component.html',
  styleUrls: ['./pm-period-search-criteria.component.scss'],
})
export class PmPeriodSearchCriteriaComponent implements OnInit {
  @Output() onSearch: EventEmitter<any> = new EventEmitter();
  @Output() onReset: EventEmitter<any> = new EventEmitter();

  searchFilters: PmPeriodSearchCriteriaComponentSearchFiltersI;

  constructor() {}

  ngOnInit(): void {
    this.initSearchFilters();
    this.onSearch.emit({ ...this.searchFilters });
  }

  initSearchFilters = () => {
    this.searchFilters = {
      planNumber: undefined,
      periodStatus: {
        [JMENUM.PMPeriodStatus.COMPLETED]: false,
        [JMENUM.PMPeriodStatus.IN_PROGRESS]: true,
        [JMENUM.PMPeriodStatus.OUTSTANDING]: true,
      },
      periodSchedule: {
        [JMENUM.PMPlanPeriod.ON_SCHEDULE]: false,
        [JMENUM.PMPlanPeriod.OVERDUE]: true,
      },
      startRange: 'after',
      startDate: {
        year: 2010,
        month: 1,
        day: 1,
      },
      endRange: 'after',
      endDate: {
        year: 2010,
        month: 1,
        day: 1,
      },
    };
  };

  onClickClear = () => {
    this.initSearchFilters();
    this.onReset.emit();
    this.onSearch.emit({ ...this.searchFilters });
  };

  onClickSearch = () => {
    this.onSearch.emit({ ...this.searchFilters });
  };

  onUpdatedDateStart = (event) => {
    this.searchFilters.startDate = event.date;
  };

  onUpdatedDateEnd = (event) => {
    this.searchFilters.endDate = event.date;
  };

  ngModelChangeCheckIfNoCheck(filerKey: string) {
    let combinedStatus = false;
    for (let key in this.searchFilters[filerKey]) {
      combinedStatus = combinedStatus || this.searchFilters[filerKey][key];
    }
    if (!combinedStatus) {
      for (let key in this.searchFilters[filerKey]) {
        this.searchFilters[filerKey][key] = true;
      }
    }
  }
}

export interface PmPeriodSearchCriteriaComponentSearchFiltersI {
  planNumber?: string;
  periodStatus?: { [key: string]: boolean };
  periodSchedule?: { [key: string]: boolean };
  startRange?: 'on-or-before' | 'after';
  startDate?: NgbDateStruct;
  endRange?: 'on-or-before' | 'after';
  endDate?: NgbDateStruct;
}
