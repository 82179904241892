<div class="previewer-container">
    <div *ngIf="isLoading" class="previewer middle-item">
        <span class="spinner-border" role="status" aria-hidden="true"></span>
    </div> 

    <div *ngIf="!isLoading && fileDetails" class="row mx-0 mb-3">
        <div class="col-12 col-xl-6 row mb-3" *ngFor="let details of fileDetails | keyvalue">
            <span class="col-4 gray-title">{{ "component.attachment."+details.key | translate }}</span>
            <app-display-text-field class="col" [id]="'preview-detail-'+details.key" [textField]="details.value"></app-display-text-field>
        </div>
    </div>

    <div *ngIf="!isLoading && file && fileType && previewContent" class="previewer-container">
        <div *ngIf="fileType == attachmentFileType.Image" class="previewer">
            <div><img [src]="previewContent" /></div>
        </div>
        <div *ngIf="fileType == attachmentFileType.Pdf" class="previewer-container pdf-container">
            <pdf-viewer  
                [src]="previewContent" 
                [render-text]="true"
                [stick-to-page]="false"
                [autoresize]="true"
                [original-size]="false"
            ></pdf-viewer>
        </div>  
        <div *ngIf="fileType == attachmentFileType.Text" class="text-previewer-container">
            <div class="text-previewer">{{ previewContent }}</div>
        </div> 
        <div *ngIf="fileType == attachmentFileType.Other" class="previewer middle-item text-center">
            <div id="not-support-msg">{{ "component.attachment.not-support-file-format" | translate }}</div>
        </div> 
    </div>
</div>