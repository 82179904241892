<div class="d-flex justify-content-between">
  <div class="font-size-xl">{{ "component.settings-team.team-member" | translate}}</div>
  <div class="d-flex align-items-center">
    <div class="position-relative">
      <button id="copy-button" class="btn py-0 px-2"
        *ngIf="false"
        [disabled]="!selectedTeam || !hasUpdatePermission" 
        [title]="'component.settings-team.copy' | translate"
        (click)="onClickCopyButton()" >
        <i class="fas fa-copy blue font-size-xl"></i>
      </button>
      <app-chat-bubble-container #copy_member_panel>
        <app-form-copy-team-member #copy_member_form [parameters]="copyMemberParam"></app-form-copy-team-member>
      </app-chat-bubble-container>
    </div>
    
    <button class="btn py-0 px-2 font-size-xl" id="add-team-member-button" (click)="onClickAddMemberButton()" [disabled]="!selectedTeam || !hasUpdatePermission">
      <i class="fa fa-plus blue "></i>
    </button>
  </div>
</div>
<hr class="underline" />
<app-tablex class="" [tablexParam]="tablexParam"></app-tablex>

<slider-panel #add_member_panel >
  <app-form-add-team-member #add_member_form (addedMembers)="onAddedMembers()"></app-form-add-team-member>
</slider-panel>

<slider-panel #edit_member_info_panel [widthPx]=700>
  <team-member-info-editor [vendorTeam]="vendorTeam" [post]="selectedPost" (updatedInfo)="onUpdatedTeamMemberInfo($event)"></team-member-info-editor>
</slider-panel>