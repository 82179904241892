import { Subject } from 'rxjs';
import { Constants } from 'src/constants';

export class InputControl {
  isDisabled: boolean = false;
  isError: boolean = false;
  isMandatory: boolean = false;

  constructor() {}
}

export class DropdownControl extends InputControl {
  isLoading: boolean = false;

  constructor() {
    super();
  }
}

export class DropdownSearch {
  keywords: string = '';
  search$: Subject<any[]> = new Subject<any[]>();
  totalPageNumber: number = 0;
  pageNumber: number = 1;
  pageSize: number = 100;

  constructor() {}
}

export class NgSelectWithPaginationHelper {
  /*
    Please map all these functions/varialbles into ng select component for pagination functions
    please note searchFn must precede search to disable frontend filter properly

    (NgSelect - DropdownSearch2)
    items - list
    loading - loading
    searchFn - searchFn
    search - search
    close - close
    clear - clear
    scrollToEnd - scrollToEnd

    example : 
    <ng-select
        bindValue="roleId"
        bindLabel="name"
        [loading]="{xxxx}.loading"
        [items]="{xxxx}.list"
        [searchFn]="{xxxx}.searchFn"
        (search)="{xxxx}.search($event)"
        (scrollToEnd)="{xxxx}.scrollToEnd()"
        (close)="{xxxx}.close()"
        (clear)="{xxxx}.clear()"
      >
    </ng-select>

  */
  loading: boolean = false;
  list: { [key: string]: any }[] = [];

  private listBackUp: { [key: string]: any }[] = [];
  private listLastSearchKeyword: string;
  private listPageNumber: number = 0;
  private listPageNumberKeyword: number = 0;
  private listPageSize: number;
  private listTotalCount: number;
  private listTotalCountKeyword: number;
  private searchDebouncer;
  private requestDropDownList: ngSelectWithPaginationHelperFetchFunction;

  constructor(reqestListFunction: ngSelectWithPaginationHelperFetchFunction, pageSize = 100) {
    this.listPageSize = pageSize;
    this.requestDropDownList = reqestListFunction;
  }

  searchFn = (e): boolean => {
    return true;
  };

  search = (e) => {
    if (this.loading) return;
    clearTimeout(this.searchDebouncer);
    this.listLastSearchKeyword = null;
    this.listTotalCountKeyword = null;
    this.listPageNumberKeyword = 0;
    if (e.term) {
      this.searchDebouncer = setTimeout(() => {
        this.list = [];
        this.fetchDropdownList(e.term);
      }, Constants.DEBOUNCE_TIME);
    } else {
      this.list = this.listBackUp;
    }
  };

  scrollToEnd = () => {
    this.fetchDropdownList(this.listLastSearchKeyword);
  };

  close = () => {
    this.list = this.listBackUp;
    this.listLastSearchKeyword = null;
  };

  clear = () => {
    this.list = this.listBackUp;
    this.listLastSearchKeyword = null;
  };

  async fetchDropdownList(keyword?: string) {
    if (!keyword) {
      await this.requestList();
    } else {
      await this.requestListKeyword(keyword);
    }
  }

  private async requestList() {
    let pageToFetch = this.listPageNumber + 1;
    if (this.listTotalCount && this.listTotalCount <= this.listPageNumber * this.listPageSize) {
      return;
    }

    this.loading = true;
    let result = await this.requestDropDownList(null, pageToFetch, this.listPageSize);
    this.loading = false;

    if (result) {
      this.listPageNumber = pageToFetch;
      this.listTotalCount = result.totalCount;
      if (pageToFetch === 1) {
        this.list = result.data;
      } else {
        this.list = this.list.concat(result.data);
      }
      this.listBackUp = this.list;
    }
  }
  private async requestListKeyword(keyword: string) {
    let pageToFetch = this.listPageNumberKeyword + 1;
    if (this.listTotalCountKeyword && this.listTotalCountKeyword <= this.listPageNumberKeyword * this.listPageSize) {
      return;
    }

    this.loading = true;
    let result = await this.requestDropDownList(keyword, pageToFetch, this.listPageSize);
    this.loading = false;

    if (result) {
      this.listPageNumberKeyword = pageToFetch;
      this.listTotalCountKeyword = result.totalCount;
      this.listLastSearchKeyword = keyword;
      if (pageToFetch === 1) {
        this.list = result.data;
      } else {
        this.list = this.list.concat(result.data);
      }
    }
  }
}

export type ngSelectWithPaginationHelperFetchFunction = (
  keywordSearch: string,
  pageToFetch: number,
  pageSize: number
) => Promise<{ data: { [key: string]: any }[]; totalCount: number }>;
