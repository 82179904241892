<div class="otp-page-wrap">
  <div class="page-container text-center px-md-5 px-3">
    <form>
      <div class="otp-wrap text-left d-flex flex-column justify-content-between mx-auto">
        <div class="otp-header mb-4 text-center ">{{ 'page.login-otp.title' | translate }}</div>
        <div class="otp-text text-center mb-4">{{ 'page.login-otp.authentication-sent-to' | translate }}{{ maskedMobile }}</div>

        <div class="container mb-4">
          <div class="row justify-content-around mx-1">
            <div class="otp-header text-right col-2 p-0">{{ otpPrefix }}-</div>
            <input *ngFor="let item of otpInputItems; let i=index" 
              id="{{ otpInputIdHeader }}{{ i }}" 
              class="form-control text-center col-1 p-2" 
              type="text" maxlength="1" 
              [ngModelOptions]="{standalone: true}"
              (ngModelChange)="onInputOtp($event, i)" 
              (input)="checkValidToSubmit($event, i)" 
              oninput="this.value = this.value.replace(/[^0-9.]/g, '');"
              (keyup.backspace)="onBackspaceOtpInput(i)" 
              [(ngModel)]="otpInputValues[i]"
            />
          </div>
        </div>
        
        <div class="row px-0 mx-4 align-items-center justify-content-between">
          <div>
            <div class="container p-0">
              <div class="otp-text">{{ 'page.login-otp.cannot-receive' | translate }}</div>

              <div *ngIf="uiSelectedLanguage === 'en' && isResendTimerCouting" class="otp-text">
                <span class="otp-resend-locked">{{ 'page.login-otp.resend' | translate }}</span>
                {{ 'page.login-otp.resend-text1' | translate }}
                <countdown #resend_timer [config]="resendTimerConfig" (event)="onResendTimerUpdated($event)"></countdown>
                {{ 'page.login-otp.resend-text2' | translate }}
              </div>

              <div *ngIf="uiSelectedLanguage === 'zh' && isResendTimerCouting" class="otp-text">
                {{ 'page.login-otp.resend-text1' | translate }}
                <countdown #resend_timer [config]="resendTimerConfig" (event)="onResendTimerUpdated($event)"></countdown>
                {{ 'page.login-otp.resend-text2' | translate }}
                <span class="otp-resend-locked">{{ 'page.login-otp.resend' | translate }}</span>
              </div>

              <div *ngIf="!isResendTimerCouting">
                <a *ngIf="!isLoadingResend" role="button" id="otp-resend-button" class="otp-text" (click)="onResendClicked()">
                  {{ 'page.login-otp.resend' | translate }}
                </a>
                <div *ngIf="isLoadingResend" class="d-inline-block loading-spinner spinner-small otp-text pl-3"></div>
              </div>

            </div>
          </div>
          <div>
            <button id="otp-submit-button" class="standard brand-blue" [disabled]="isDisabledSubmit || isLoadingSubmit" (click)="onSubmitClicked()">
              <div *ngIf="isLoadingSubmit" class="d-inline-block loading-spinner spinner-small">
                {{ 'global.loading' | translate }}
              </div>
              <ng-container *ngIf="!isLoadingSubmit">
                <i class="fa fa-check mr-2"></i>{{ 'global.submit' | translate }}
              </ng-container>
            </button>
          </div>
        </div>

      </div>
    </form>
  </div>

</div>