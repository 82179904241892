import { Component, Injector, OnInit, Input } from '@angular/core';
import { JMENUM } from '@ccep/CCEPConnector-ts';
import { Constants } from 'src/constants';
import { UserHelper } from 'src/app/core/user-helper';
import { SidebarItems } from 'src/app/shared/sidebar/sidebar-items';
import { AppDelegate } from 'src/app/core/AppDelegate';

import { ReleaseNotePlatform } from 'src/app/setting-release-note/release-note/release-note.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() contentType: string;

  routeUrl: string = '';

  items = SidebarItems;
  showSidebar: boolean = false;

  systemVersion: string;
  supportInfo: any;

  constructor(injector: Injector) {}

  ngOnInit() {
    this.routeUrl = AppDelegate.getRoute();
    this.systemVersion = Constants.SYSTEM_VERSION;
    this.supportInfo = Constants.SUPPORT_INFO;

    // default content type is 'main'
    if (!this.contentType) {
      this.contentType = 'main';
    }
  }

  isEnabled(item) {
    if (!item) {
      return false;
    }
    return UserHelper.hasPagePermission(item.route);
  }

  onClickedRealseNoteLink() {
    const hasReleaseNoteUpdatingPermission = UserHelper.hasPermission(JMENUM.Permission.RELEASENOTE_UPDATE);
    if (hasReleaseNoteUpdatingPermission) {
      AppDelegate.routeToPage('/settings/release-note/' + ReleaseNotePlatform.web);
    }
  }
}
