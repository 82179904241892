import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';
import { UserHelper } from 'src/app/core/user-helper';
import { ActionSidebarV2Service } from '../../shared/action-sidebar-v2.service';
import { ActionButtonI } from '../../shared/action-sidebar-v2/action-sidebar-v2.model';
import { CmAdditionalInfoComponent } from '../shared/cm-additional-info/cm-additional-info.component';
import { CmClientInfoComponent } from '../shared/cm-client-info/cm-client-info.component';
import { CmProgressDetailsComponent } from '../shared/cm-progress-details/cm-progress-details.component';
import { CmTaskProgressComponent } from '../shared/cm-task-progress/cm-task-progress.component';
import { CmTaskDescriptionComponent } from './../shared/cm-task-description/cm-task-description.component';
import { CmTaskCreateActionButtonList } from './cm-task-create.model';

@Component({
  selector: 'app-cm-task-create',
  templateUrl: './cm-task-create.component.html',
  styleUrls: ['./cm-task-create.component.scss'],
})
export class CmTaskCreateComponent implements OnInit {
  @ViewChild(CmTaskDescriptionComponent, { static: false }) taskDescriptionElem: CmTaskDescriptionComponent;
  @ViewChild(CmClientInfoComponent, { static: false }) clientInfoElem: CmClientInfoComponent;
  @ViewChild(CmTaskProgressComponent, { static: false }) taskProgressElem: CmTaskProgressComponent;
  @ViewChild(CmProgressDetailsComponent, { static: false }) progressDetailsElem: CmProgressDetailsComponent;
  @ViewChild(CmAdditionalInfoComponent, { static: false }) additionalInfoElem: CmAdditionalInfoComponent;

  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.CREATE;

  sn: JMOBJ.ServiceNotification = new JMOBJ.ServiceNotification();
  jobCard: JMOBJ.JobCard = new JMOBJ.JobCard();
  jobCardNumber: string;
  contract?: JMOBJ.MaintenanceTermContract;
  equipmentClient?: {
    clientNumber: string;
    clientShortName: string;
  };
  contractSeverity?: JMOBJ.ContractSeverity;

  // action buttons
  actionSidebar: {
    dataList: ActionButtonI[];
    isDisabled: boolean;
    isLoading: boolean;
  } = {
    dataList: [],
    isDisabled: false,
    isLoading: false,
  };

  constructor(private _location: Location, private actionSidebarV2Service: ActionSidebarV2Service) {}

  ngOnInit(): void {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }

    this.resetActionBar();
  }

  //------------------------
  // API
  private async requestCreateStandaloneCmTask() {
    let request = new JM.JMRequestSnCreateStandaloneCmTask();
    this.taskDescriptionElem.setCreateStandaloneCmTaskData(request);
    this.clientInfoElem.setCreateStandaloneCmTaskData(request);
    this.taskProgressElem.setCreateStandaloneCmTaskData(request);
    this.progressDetailsElem.setCreateStandaloneCmTaskData(request);

    request.sourcePlatform = JMENUM.SourcePlatform.WEB;

    this.actionSidebar.isLoading = true;
    const response: JM.JMResponseSnCreateStandaloneCmTask = await AppDelegate.sendJMRequest(request);
    this.actionSidebar.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.created'));

    const jobCard = response.payload.jobCard;
    if (jobCard) {
      AppDelegate.routeToPage(`/cm-task/${jobCard.snNumber}/${jobCard.jobCardNumber}`);
      return;
    }

    const sn = response.payload.sn;
    if (sn) {
      AppDelegate.routeToPage(`/cm-task/${sn.snNumber}`);
    }
  }

  //------------------------
  // UI function
  onUpdatedContract(contract?: JMOBJ.MaintenanceTermContract) {
    // this.contract = contract;
  }

  async onUpdatedEquipment(equipment?: JMOBJ.Equipment) {
    this.sn.client = equipment ? equipment.clientShortName : undefined;
    this.equipmentClient = equipment ? {
      clientNumber: equipment.clientNumber,
      clientShortName: equipment.clientShortName,
    } : undefined;
  }

  onUpdatedContractSeverity(contractSeverity?: JMOBJ.ContractSeverity) {
    this.contractSeverity = contractSeverity;
  }

  //------------------------
  // action button function
  onClickedActionButton(actionButton: ActionButtonI) {
    actionButton.handler();
  }

  private resetActionBar(): void {
    const actionList = [JMENUM.VpSnAction.CREATE_TASK, 'clear', 'close'];

    this.filterActionButton(actionList);
  }

  private filterActionButton(actionList: string[]) {
    for (const action of actionList) {
      const button: ActionButtonI = CmTaskCreateActionButtonList[action];
      const hasPermissionList = button && button.permissionList && button.permissionList.length;

      if (hasPermissionList) {
        const allowAction = UserHelper.hasEitherOnePermission(button.permissionList);
        if (allowAction) {
          this.addActionButton(button);
        }
      } else {
        this.addActionButton(button);
      }
    }
  }

  private addActionButton(actionButton: ActionButtonI) {
    let handler = () => {};

    switch (actionButton.action) {
      case JMENUM.VpSnAction.CREATE_TASK:
        handler = () => {
          const buttonHandler = () => {
            this.clickedCreateCmTaskButton();
          };

          if(this.taskProgressElem.isCompliacneStatusFail()) {
            let popupDetail = {
              msg: { content: 'action.button.popup.confirm-complete.compliance-fail' },
              buttons: [
                {
                  name: 'global.yes',
                  handler: () => { this.clickedCreateCmTaskButton(); },
                },
                { name: 'global.no', handler: null },
              ],
            };
            AppDelegate.popUpDialog().open(popupDetail);
          } else {
            this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, buttonHandler);
          }
        };
        break;
      case 'clear':
        handler = () => {
          const buttonHandler = () => {
            this.taskDescriptionElem.resetFields();
            this.clientInfoElem.resetFields();
            this.taskProgressElem.resetFields();
            this.progressDetailsElem.resetFields();
          };
          this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, buttonHandler);
        };
        break;
      case 'close':
        handler = () => {
          const buttonHandler = () => {
            this._location.back();
          };
          this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, buttonHandler);
        };
        break;
      default:
        break;
    }

    actionButton.handler = handler;
    this.actionSidebar.dataList.push(actionButton);
  }

  private clickedCreateCmTaskButton() {
    if (!this.taskProgressElem.validateFieldsFormat()) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.invalid-format'));
      return;
    }

    if (!this.isInputtedMandatoryFields()) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.missing-mandatory-fields'));
      return;
    }

    const isValid = this.isValidFields();
    if (isValid) {
      this.requestCreateStandaloneCmTask();
    }
  }

  private isInputtedMandatoryFields(): boolean {
    const isValidTaskDescriptionElem = this.taskDescriptionElem.validateMandatoryFields();
    const isValidClientInfoElem = this.clientInfoElem.validateMandatoryFields();
    // const isValidTaskProgressElem = this.taskProgressElem.validateMandatoryFields();
    const isValidProgressDetailsElem = this.progressDetailsElem.validateMandatoryFields();

    const isValid =
      isValidTaskDescriptionElem &&
      isValidClientInfoElem &&
      // isValidTaskProgressElem &&
      isValidProgressDetailsElem;

    return isValid;
  }

  private isValidFields(): boolean {
    const isValidTaskDescriptionElem = this.taskDescriptionElem.validateData();
    const isValidClientInfoElem = this.clientInfoElem.validateData();
    const isValidTaskProgressElem = this.taskProgressElem.validateData();
    const isValidProgressDetailsElem = this.progressDetailsElem.validateData();

    const isValid =
      isValidTaskDescriptionElem &&
      isValidClientInfoElem &&
      isValidTaskProgressElem &&
      isValidProgressDetailsElem;

    return isValid;
  }

  //------------------------
  // get property
  get hasUpdatePermission(): boolean {
    return UserHelper.hasPermission(JMENUM.VpPermission.CMTASK_CREATE);
  }
}
