<div class="copy-team-member-form row mx-0 align-items-center">
  <div class="mr-2">{{ "pages.settings-team.copy-members-from" | translate }}</div>
  <ng-select class="col px-0 mr-2"
    [items]="copyTeamOptions" bindLabel="label" bindValue="value" [loading]="isLoadingTeam"
    [(ngModel)]="copyTeamFrom" (scrollToEnd)="onTeamScrollToEnd()" (search)="onSearchTeam($event)">
  </ng-select>
  <button class="btn standard brand-blue" (click)="onCopyMember()">
    <div *ngIf="parameters.isLoading" class="d-flex align-items-center loading-spinner spinner-small"></div>
    <ng-container *ngIf="!parameters.isLoading">
      {{ "global.submit" | translate }}
    </ng-container>
  </button>
</div>