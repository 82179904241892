import { Injector } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CountdownModule } from 'ngx-countdown';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { MaterialModule } from 'src/app/material-module';
import { JMLanguagePipe } from 'src/app/core/JMLanguage/JMLanguage.pip';
import { FormatDatePipe } from 'src/app/core/format-date.pipe';
import { FormatDateTimePipe } from 'src/app/core/format-date-time.pipe';
import { SafePipe } from './safe.pipe';

import { AppDelegate } from './core/AppDelegate';

import { ActionSidebarComponent } from './shared/action-sidebar/action-sidebar.component';
import { PaginationComponent } from './shared/pagination/pagination.component';
import { PopUpDialogComponent } from './shared/pop-up-dialog/pop-up-dialog.component';
import { NavComponent } from './shared/nav/nav.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { TabMenuComponent } from './shared/tab-menu/tab-menu.component';
import { SliderPanelComponent } from './shared/slider-panel/slider-panel.component';
import { SubmitButtonComponent } from './shared/submit-button/submit-button.component';
import { IconButtonComponent } from './shared/icon-button/icon-button.component';
import { TablexComponent } from './shared/tablex/tablex.component';

import { ToastMsgComponent } from './shared/toast-msg/toast-msg.component';
import { GraphStackedBarChartComponent } from './shared/graph-stacked-bar-chart/graph-stacked-bar-chart.component';

import { LoginComponent } from './logins/login/login.component';
import { LoginOtpComponent } from './logins/login-otp/login-otp.component';

import { SettingsComponent } from './pages/settings/settings.component';
import { SettingsSubpageComponent } from './pages/settings-subpage/settings-subpage.component';
import { SettingsVendorInfoComponent } from './pages/settings-vendor-info/settings-vendor-info.component';
import { SettingsUserProfileComponent } from './pages/settings-user-profile/settings-user-profile.component';
import { SettingsUserManagementComponent } from './setting-users/settings-user-management/settings-user-management.component';
import { SettingsRoleManagementComponent } from './pages/settings-role-management/settings-role-management.component';

import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';

import { BreadcrumbsComponent } from './shared/breadcrumbs/breadcrumbs.component';
import { DisplayTextFieldComponent } from './shared/display-text-field/display-text-field.component';
import { AdvancedSearchComponent } from './shared/advanced-search/advanced-search.component';
import { SettingContractComponent } from './setting-contracts/setting-contract/setting-contract.component';
import { ContractListComponent } from './pages/components/contract-list/contract-list.component';
import { ContractGeneralComponent } from './setting-contracts/contract-general/contract-general.component';
import { ContractorTeamListComponent } from './setting-contracts/contractor-team-list/contractor-team-list.component';
import { SettingsTeamComponent } from './setting-teams/settings-team/settings-team.component';
import { TeamListTableComponent } from './setting-teams/shared/team-list-table/team-list-table.component';
import { OfficeHourTableComponent } from './setting-contracts/office-hour-table/office-hour-table.component';
import { TeamMemberTableComponent } from './setting-teams/shared/team-member-table/team-member-table.component';
import { FormAddTeamComponent } from './setting-teams/shared/form-add-team/form-add-team.component';
import { FormAddTeamMemberComponent } from './setting-teams/shared/form-add-team-member/form-add-team-member.component';
import { ChatBubbleContainerComponent } from './shared/chat-bubble-container/chat-bubble-container.component';
import { FormCopyOfficeHourComponent } from './pages/components/form-copy-office-hour/form-copy-office-hour.component';
import { FormCopyTeamMemberComponent } from './setting-teams/shared/form-copy-team-member/form-copy-team-member.component';
import { SettingsContractorComponent } from './pages/settings-contractor/settings-contractor.component';
import { ContractorListTableComponent } from './pages/components/contractor-list-table/contractor-list-table.component';
import { ContractorGeneralSettingComponent } from './pages/components/contractor-general-setting/contractor-general-setting.component';
import { TimeInputComponent } from './shared/time-input/time-input.component';
import { DateInputComponent } from './shared/date-input/date-input.component';
import { AttachmentInfoComponent } from './shared/attachment-info/attachment-info.component';
import { AttachmentPreviewerComponent } from './shared/attachment-previewer/attachment-previewer.component';
import { UploadPreviewerComponent } from './shared/upload-previewer/upload-previewer.component';
import { UploadAttachmentTooltipComponent } from './shared/upload-attachment-tooltip/upload-attachment-tooltip.component';

import { CmTaskCreateComponent } from './cm-tasks/cm-task-create/cm-task-create.component';
import { CmTaskViewComponent } from './cm-tasks/cm-task-view/cm-task-view.component';
import { CmTaskListComponent } from './cm-tasks/cm-task-list/cm-task-list.component';
import { CmTaskAttachmentComponent } from './cm-tasks/cm-task-attachment/cm-task-attachment.component';
import { CmTaskDescriptionComponent } from './cm-tasks/shared/cm-task-description/cm-task-description.component';
import { CmProgressDetailsComponent } from './cm-tasks/shared/cm-progress-details/cm-progress-details.component';
import { CmTaskProgressComponent } from './cm-tasks/shared/cm-task-progress/cm-task-progress.component';
import { CmEquipmentInfoComponent } from './cm-tasks/shared/cm-equipment-info/cm-equipment-info.component';
import { CmClientInfoComponent } from './cm-tasks/shared/cm-client-info/cm-client-info.component';
import { CmAdditionalInfoComponent } from './cm-tasks/shared/cm-additional-info/cm-additional-info.component';

import { DateWeekPipe } from './core/date-week.pipe';
import { DateTimeWeekPipe } from './core/date-time-week.pipe';
import { SelectEquipmentComponent } from './service-notifications/select-equipment/select-equipment.component';
import { NotificationListComponent } from './service-notifications/notification-list/notification-list.component';
import { SnViewComponent } from './service-notifications/sn-view/sn-view.component';
import { NotificationInfoComponent } from './service-notifications/notification-info/notification-info.component';
import { NotificationProgressComponent } from './service-notifications/notification-progress/notification-progress.component';
import { SnRemarksComponent } from './service-notifications/shared/sn-remarks/sn-remarks.component';
import { SnCancelRemarksComponent } from './service-notifications/shared/sn-cancel-remarks/sn-cancel-remarks.component';
import { RemarkHistoryComponent } from './service-notifications/shared/remark-history/remark-history.component';
import { SnCancelEditorComponent } from './service-notifications/sn-cancel-editor/sn-cancel-editor.component';
import { SnRejectEditorComponent } from './service-notifications/sn-reject-editor/sn-reject-editor.component';
import { CustomSliderPanelComponent } from './shared/custom-slider-panel/custom-slider-panel.component';
import { LoadingButtonComponent } from './shared/loading-button/loading-button.component';
import { SnReferFormComponent } from './service-notifications/sn-refer-form/sn-refer-form.component';
import { ContractListByContractComponent } from './setting-contracts/contract-list-by-contract/contract-list-by-contract.component';
import { ActionSidebarV2Component } from './shared/action-sidebar-v2/action-sidebar-v2.component';
import { SettingContractSeverityListComponent } from './setting-contracts/setting-contract-severity-list/setting-contract-severity-list.component';
import { ContractSeverityListTableComponent } from './setting-contracts/shared/contract-severity-list-table/contract-severity-list-table.component';
import { ContractSeverityEditorComponent } from './setting-contracts/shared/contract-severity-editor/contract-severity-editor.component';
import { SettingContractSeverityDetailComponent } from './setting-contracts/setting-contract-severity-detail/setting-contract-severity-detail.component';
import { SeverityCriteriaListTableComponent } from './setting-contracts/shared/severity-criteria-list-table/severity-criteria-list-table.component';
import { SeverityCriteriaEditorComponent } from './setting-contracts/shared/severity-criteria-editor/severity-criteria-editor.component';
import { CmEquipmentSelectorComponent } from './cm-tasks/shared/cm-equipment-selector/cm-equipment-selector.component';
import { CmTaskEditComponent } from './cm-tasks/cm-task-edit/cm-task-edit.component';
import { CmTaskCompleteEditorComponent } from './cm-tasks/shared/cm-task-complete-editor/cm-task-complete-editor.component';
import { CmTaskReferEditorComponent } from './cm-tasks/shared/cm-task-refer-editor/cm-task-refer-editor.component';
import { CmTaskSearchCriteriaComponent } from './cm-tasks/shared/cm-task-search-criteria/cm-task-search-criteria.component';
import { PmPlanListComponent } from './pm/pm-plan-list/pm-plan-list.component';
import { PmPlanCreateComponent } from './pm/pm-plan-create/pm-plan-create.component';
import { PmPlanViewComponent } from './pm/pm-plan-view/pm-plan-view.component';
import { PmPlanEditComponent } from './pm/pm-plan-edit/pm-plan-edit.component';
import { PmPlanSummaryComponent } from './pm/shared/pm-plan-summary/pm-plan-summary.component';
import { PmPlanParticularsComponent } from './pm/shared/pm-plan-particulars/pm-plan-particulars.component';
import { PmPlanRemindersComponent } from './pm/shared/pm-plan-reminders/pm-plan-reminders.component';
import { PmPlanPeriodsComponent } from './pm/shared/pm-plan-periods/pm-plan-periods.component';
import { PmPlanReferEditorComponent } from './pm/shared/pm-plan-refer-editor/pm-plan-refer-editor.component';
import { PmPlanRemarksComponent } from './pm/shared/pm-plan-remarks/pm-plan-remarks.component';
import { PmPlanSearchCriteriaComponent } from './pm/shared/pm-plan-search-criteria/pm-plan-search-criteria.component';
import { PmJobCreateComponent } from './pm/pm-job-create/pm-job-create.component';
import { PmJobViewComponent } from './pm/pm-job-view/pm-job-view.component';
import { PmJobEditComponent } from './pm/pm-job-edit/pm-job-edit.component';
import { PmJobListComponent } from './pm/pm-job-list/pm-job-list.component';
import { PmJobAttachmentComponent } from './pm/pm-job-attachment/pm-job-attachment.component';
import { PmJobEquipmentListComponent } from './pm/pm-job-equipment-list/pm-job-equipment-list.component';
import { PmJobSearchCriteriaComponent } from './pm/shared/pm-job-search-criteria/pm-job-search-criteria.component';
import { PmJobDescriptionComponent } from './pm/shared/pm-job-description/pm-job-description.component';
import { PmJobClientInfoComponent } from './pm/shared/pm-job-client-info/pm-job-client-info.component';
import { PmJobProgressComponent } from './pm/shared/pm-job-progress/pm-job-progress.component';
import { PmJobEquipmentInfoComponent } from './pm/shared/pm-job-equipment-info/pm-job-equipment-info.component';
import { SettingUserEmsdComponent } from './setting-users/setting-user-emsd/setting-user-emsd.component';
import { UserEmsdEditComponent } from './setting-users/shared/user-emsd-edit/user-emsd-edit.component';
import { PmPlanEquipmentListComponent } from './pm/pm-plan-equipment-list/pm-plan-equipment-list.component';
import { PmPeriodListComponent } from './pm/pm-period-list/pm-period-list.component';
import { PmPeriodSearchCriteriaComponent } from './pm/shared/pm-period-search-criteria/pm-period-search-criteria.component';
import { PmPeriodEquipmentListComponent } from './pm/pm-period-equipment-list/pm-period-equipment-list.component';
import { PmJobCcsInfoComponent } from './pm/shared/pm-job-ccs-info/pm-job-ccs-info.component';
import { CmCreateFollowUpJobEditorComponent } from './cm-tasks/shared/cm-create-follow-up-job-editor/cm-create-follow-up-job-editor.component';
import { PmJobCancelFormComponent } from './pm/shared/pm-job-cancel-form/pm-job-cancel-form.component';
import { PmJobRemarksComponent } from './pm/shared/pm-job-remarks/pm-job-remarks.component';
import { ContractEquipmentListComponent } from './setting-contracts/shared/contract-equipment-list/contract-equipment-list.component';
import { EquipmentTableByContractComponent } from './shared/equipment-table-by-contract/equipment-table-by-contract.component';
import { SettingContractEmsdContactComponent } from './setting-contracts/setting-contract-emsd-contact/setting-contract-emsd-contact.component';
import { ContractDropdownComponent } from './setting-contracts/shared/contract-dropdown/contract-dropdown.component';
import { ContractRoutingCoverageListTableComponent } from './setting-contracts/shared/contract-routing-coverage-list-table/contract-routing-coverage-list-table.component';
import { ContractEmsdContactListComponent } from './setting-contracts/shared/contract-emsd-contact-list/contract-emsd-contact-list.component';
import { TeamMemberInfoEditorComponent } from './setting-teams/shared/team-member-info-editor/team-member-info-editor.component';
import { ReleaseNoteComponent } from './setting-release-note/release-note/release-note.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PmOutstandingEquipmentListComponent } from './pm/pm-outstanding-equipment-list/pm-outstanding-equipment-list.component';
import { PmEquipmentListSearchCriteriaComponent } from './pm/shared/pm-equipment-list-search-criteria/pm-equipment-list-search-criteria.component';
import { EnvWarningComponent } from './general-component/env-warning/env-warning.component';
import { PasswordErrorListComponent } from './general-component/password-error-list/password-error-list.component';
import { ResetPasswordComponent } from './logins/reset-password/reset-password.component';
import { GpsLocationSubmittedTableComponent } from './shared/gps-location-submitted-table/gps-location-submitted-table.component';
import { ArrivalGpsComponent } from './shared/arrival-gps/arrival-gps.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ComingSoonComponent,

    LoginComponent,
    LoginOtpComponent,
    SettingsComponent,
    SettingsSubpageComponent,
    SettingsVendorInfoComponent,
    SettingsUserProfileComponent,
    SettingsUserManagementComponent,
    SettingsRoleManagementComponent,
    SafePipe,
    SliderPanelComponent,
    SidebarComponent,
    PaginationComponent,
    TablexComponent,
    ActionSidebarComponent,
    NavComponent,
    TabMenuComponent,
    ToastMsgComponent,
    SubmitButtonComponent,
    IconButtonComponent,
    TimeInputComponent,
    // pip
    JMLanguagePipe,
    FormatDatePipe,
    FormatDateTimePipe,
    DateWeekPipe,
    DateTimeWeekPipe,
    PopUpDialogComponent,

    GraphStackedBarChartComponent,

    BreadcrumbsComponent,

    SettingContractComponent,

    ContractListComponent,

    ContractGeneralComponent,

    ContractorTeamListComponent,

    DisplayTextFieldComponent,
    SettingsTeamComponent,
    TeamListTableComponent,
    OfficeHourTableComponent,
    TeamMemberTableComponent,
    FormAddTeamComponent,
    FormAddTeamMemberComponent,
    ChatBubbleContainerComponent,
    FormCopyOfficeHourComponent,
    FormCopyTeamMemberComponent,
    SettingsContractorComponent,
    ContractorListTableComponent,
    ContractorGeneralSettingComponent,
    DateInputComponent,
    AttachmentInfoComponent,
    AttachmentPreviewerComponent,
    UploadPreviewerComponent,
    UploadAttachmentTooltipComponent,
    CmTaskCreateComponent,
    CmTaskViewComponent,
    CmTaskListComponent,
    CmTaskAttachmentComponent,
    CmTaskDescriptionComponent,
    CmClientInfoComponent,
    CmProgressDetailsComponent,
    CmTaskProgressComponent,
    CmEquipmentInfoComponent,
    CmAdditionalInfoComponent,
    SelectEquipmentComponent,
    AdvancedSearchComponent,
    NotificationListComponent,
    SnViewComponent,
    NotificationInfoComponent,
    NotificationProgressComponent,
    SnRemarksComponent,
    SnCancelRemarksComponent,
    RemarkHistoryComponent,
    SnCancelEditorComponent,
    SnRejectEditorComponent,
    CustomSliderPanelComponent,
    LoadingButtonComponent,
    SnReferFormComponent,
    ContractListByContractComponent,
    ActionSidebarV2Component,
    SettingContractSeverityListComponent,
    ContractSeverityListTableComponent,
    ContractSeverityEditorComponent,
    SettingContractSeverityDetailComponent,
    SeverityCriteriaListTableComponent,
    SeverityCriteriaEditorComponent,
    CmEquipmentSelectorComponent,
    CmTaskEditComponent,
    CmTaskCompleteEditorComponent,
    CmTaskReferEditorComponent,
    CmTaskSearchCriteriaComponent,
    PmPlanListComponent,
    PmPlanCreateComponent,
    PmPlanViewComponent,
    PmPlanEditComponent,
    PmPlanSummaryComponent,
    PmPlanParticularsComponent,
    PmPlanRemindersComponent,
    PmPlanPeriodsComponent,
    PmPlanReferEditorComponent,
    PmPlanRemarksComponent,
    PmPlanSearchCriteriaComponent,
    PmJobCreateComponent,
    PmJobViewComponent,
    PmJobEditComponent,
    PmJobListComponent,
    PmJobAttachmentComponent,
    PmJobEquipmentListComponent,
    PmJobSearchCriteriaComponent,
    PmJobDescriptionComponent,
    PmJobClientInfoComponent,
    PmJobProgressComponent,
    PmJobEquipmentInfoComponent,
    SettingUserEmsdComponent,
    UserEmsdEditComponent,
    PmPlanEquipmentListComponent,
    PmPeriodListComponent,
    PmPeriodSearchCriteriaComponent,
    PmPeriodEquipmentListComponent,
    PmJobCcsInfoComponent,
    CmCreateFollowUpJobEditorComponent,
    PmJobCancelFormComponent,
    PmJobRemarksComponent,
    ContractEquipmentListComponent,
    EquipmentTableByContractComponent,
    SettingContractEmsdContactComponent,
    ContractDropdownComponent,
    ContractRoutingCoverageListTableComponent,
    ContractEmsdContactListComponent,
    TeamMemberInfoEditorComponent,
    ReleaseNoteComponent,
    DashboardComponent,
    PmOutstandingEquipmentListComponent,
    PmEquipmentListSearchCriteriaComponent,
    EnvWarningComponent,
    PasswordErrorListComponent,
    ResetPasswordComponent,
    GpsLocationSubmittedTableComponent,
    ArrivalGpsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    PdfViewerModule,
    ReactiveFormsModule,
    MaterialModule,
    NgSelectModule,
    NgbModule,
    CountdownModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [CookieService, FormBuilder],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    AppDelegate.appInjector = this.injector;
  }
}
