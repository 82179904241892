import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JM, JMCONSTANT, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { ActionButtonI } from '../../shared/action-sidebar-v2/action-sidebar-v2.model';
import { CustomSliderPanelComponent } from '../../shared/custom-slider-panel/custom-slider-panel.component';
import { PmPlanViewActionButtonList } from './pm-plan-view.model';

import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { UserHelper } from 'src/app/core/user-helper';
import { Session } from 'src/app/core/session';

@Component({
  selector: 'app-pm-plan-view',
  templateUrl: './pm-plan-view.component.html',
  styleUrls: ['./pm-plan-view.component.scss'],
})
export class PmPlanViewComponent implements OnInit {
  @ViewChild('pm_plan_refer_editor_panel', { static: false }) pmPlanReferEditorPanel: CustomSliderPanelComponent;

  @ViewChild('pmPlanSummaryElem', { static: false }) pmPlanSummaryElem;
  @ViewChild('pmPlanParticularsElem', { static: false }) pmPlanParticularsElem;
  @ViewChild('pmPlanRemindersElem', { static: false }) pmPlanRemindersElem;
  @ViewChild('pmPlanPeriodsElem', { static: false }) pmPlanPeriodsElem;
  @ViewChild('pmPlanRemarksElem', { static: false }) pmPlanRemarksElem;

  breadcrumbs: any = [];
  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.VIEW;
  pmPlanNumber = undefined;
  plan: JMOBJ.PmPlan = new JMOBJ.PmPlan();
  // periods: JMOBJ.PmPeriod = new JMOBJ.PmPeriod;

  // action buttons
  actionSidebar: {
    dataList: ActionButtonI[];
    isDisabled: boolean;
    isLoading: boolean;
  } = {
    dataList: [],
    isDisabled: false,
    isLoading: false,
  };
  
  hasPMReminderFeatureToggle = false;

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  async ngOnInit(): Promise<void> {
    const route = AppDelegate.getRoute();
    const trimRoute = route.replace(/^(\/pm\/plans).*$/g, '$1');

    if (!AppDelegate.checkPagePermissions(trimRoute)) {
      return;
    }

    this.pmPlanNumber = this.route.snapshot.paramMap.get('planNumber');

    if (this.pmPlanNumber) {
      this.setBreadcrumbs();
      await this.requestPmPlan();
    }

    const pmReminderFeature = Session.featureList.find(feature => feature.key == JMCONSTANT.JMFeature.VP_PM_PLAN_REMINDER);
    if(pmReminderFeature) {
      this.hasPMReminderFeatureToggle = pmReminderFeature.enabled;
    }
  }

  // ---------- API ----------
  private async requestPmPlan(): Promise<JM.JMResponseVpGetPmPlan> {
    if (!this.pmPlanNumber) return;

    let request: JM.JMRequestVpGetPmPlan = new JM.JMRequestVpGetPmPlan();
    request.pmPlanNumber = this.pmPlanNumber;

    let response: JM.JMResponseVpGetPmPlan = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (response.payload.pmPlan) {
      this.plan = response.payload.pmPlan;
    }
    if (Array.isArray(response.payload.actionList) && response.payload.actionList.length > 0) {
      this.resetActionBar(response.payload.actionList);
    }
  }

  private async requestSubmitPmPlan() {
    let request: JM.JMRequestVpPmPlanSubmit = new JM.JMRequestVpPmPlanSubmit();
    request.pmPlanNumber = this.plan.pmPlanNumber;
    request.periods = this.plan['periods'];
    request.vendorTeamId = this.plan.vendorTeam.id;
    if (this.plan.frequency) {
      request.frequency = this.plan.frequency as JMENUM.PMPlanFrequency;
    }
    request.scheduleType = this.plan.scheduleType as JMENUM.PMScheduleType;
    request.startDate = this.plan.startDate;
    request.secondStartDate = (this.plan.scheduleType == JMENUM.PMScheduleType.OVERHAUL || this.plan.scheduleType == JMENUM.PMScheduleType.NORMAL) && this.plan.secondStartDate ? this.plan.secondStartDate : undefined;
    request.endDate = this.plan.endDate;
    request.planDescription = this.plan.planDescription;
    request.vpReminderRecipients = this.plan.vpReminderRecipients ? this.plan.vpReminderRecipients : undefined;
    request.remarks = this.plan.remarks ? this.plan.remarks : undefined;
    request.vpToBeOverdueReminder = this.plan.vpToBeOverdueReminder? this.plan.vpToBeOverdueReminder : undefined;
    request.vpOverduePeriodReminder = this.plan.vpOverduePeriodReminder? this.plan.vpOverduePeriodReminder : undefined;
    // request.attachments = undefined;
    request.version = this.plan.version;
    
    let response: JM.JMResponseVpPmPlanSubmit = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }
    AppDelegate.toastMsg().showMsg(JMLanguage.translate("global.submitted"));
    return response;
  }

  // ----------- UI function -----------

  private setBreadcrumbs() {
    const breadcrumbName = JMLanguage.translate('pages.pm-plan-view.breadcrumbs.plan-list');
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-pm-plan-list',
        name: breadcrumbName,
        route: '/pm/plans'
      },
      {
        id: 'breadcrumbs-pm-plan-number',
        name: this.pmPlanNumber,
        route: null,
        currentPage: true,
      },
    ];
  }

  onRefreshPeriod() {
    let validateSecondStartDate = true;
    if (this.plan.secondStartDate) {
      validateSecondStartDate = this.pmPlanSummaryElem.validateSecondStartDate();
    }
    if (this.pmPlanSummaryElem.validateStartEndDate() && validateSecondStartDate) {
      this.pmPlanPeriodsElem.requestPmPeriodCalculate();
    }
  }

   triggerSubmitButtonAction() {
    if(this.pmPlanSummaryElem.validate() && this.pmPlanParticularsElem.validate() && (this.hasPMReminderFeatureToggle ? this.pmPlanRemindersElem.validate() : true)) {
      const data = {
        msg: {
          content: 'action.button.popup.confirm-submit',
        },
        buttons: [
          {
            name: 'global.yes',
            handler: async () => {
              const response = await this.requestSubmitPmPlan();
              if (response?.payload) {
                this.plan = response.payload.pmPlan;
                this.resetActionBar(response.payload.actionList);
              }
            },
          },
          {
            name: 'global.no',
            handler: null,
          },
        ],
      };
      AppDelegate.popUpDialog().open(data);
    }
  }

  onChangeContractSelection() {
    this.pmPlanParticularsElem.clearTeam();
    this.pmPlanParticularsElem.toggleTeamInput();
    this.pmPlanParticularsElem.fetchNewTeamList()
    if (this.hasPMReminderFeatureToggle) {
      this.pmPlanRemindersElem.clearRecipients();
      this.pmPlanRemindersElem.toggleRecipientsInput();
      this.pmPlanRemindersElem.fetchNewPostList();
    }
  }

  // ----------- action button function -----------
  onClickedActionButton(actionButton: ActionButtonI) {
    actionButton.handler();
  }

  private resetActionBar(apiActionList): void {
    this.actionSidebar.dataList = [];

    let constActionList;
    if (Array.isArray(apiActionList) && apiActionList.includes(JMENUM.VpPmPlanAction.EQUIPMENT)) {
      constActionList = [];
    } else {
      constActionList = ['equipmentview'];
    }

    const actionList = Array.isArray(apiActionList) && apiActionList.length ? [
      ...apiActionList,
      ...constActionList,
    ] : constActionList;

    this.filterActionButton(actionList);
  }

  private filterActionButton(actionList: string[]) {
    let sortedButtonList;
    if (this.plan.status == JMENUM.PMStatus.IN_PROGRESS || this.plan.status == JMENUM.PMStatus.OUTSTANDING) {
      sortedButtonList = [
        JMENUM.VpPmPlanAction.SUBMIT,
        // JMENUM.VpPmPlanAction.CANCEL,
        JMENUM.VpPmPlanAction.UPDATE,
        JMENUM.VpPmPlanAction.REFER,
        JMENUM.VpPmPlanAction.EQUIPMENT,
        'equipmentview',
        JMENUM.VpPmPlanAction.ATTACHMENT,
      ];
    } else {
      sortedButtonList = [
        JMENUM.VpPmPlanAction.SUBMIT,
        // JMENUM.VpPmPlanAction.CANCEL,
        JMENUM.VpPmPlanAction.UPDATE,
        JMENUM.VpPmPlanAction.EQUIPMENT,
        'equipmentview',
        JMENUM.VpPmPlanAction.ATTACHMENT,
      ];
    }

    for (const sortedButton of sortedButtonList) {
      const showActionButton = actionList.includes(sortedButton);
 
      if (showActionButton) {
        const button: ActionButtonI = PmPlanViewActionButtonList[sortedButton];
        this.addActionButton(button);

        // const hasPermissionList = button && button.permissionList && button.permissionList.length; // use backed action list to determine permissions

        // if (hasPermissionList) {
        //   const allowAction = UserHelper.hasEitherOnePermission(button.permissionList);
        //   if (allowAction) {
        //     this.addActionButton(button);
        //   }
        // } else {
        //   this.addActionButton(button);
        // }
      }
    }
  }

  private addActionButton(actionButton: ActionButtonI) {
    let handler = () => {};

    switch (actionButton.action) {
      case JMENUM.VpPmPlanAction.SUBMIT:
        handler = () => {
          this.triggerSubmitButtonAction();
        };
        break;
      case JMENUM.VpPmPlanAction.CANCEL:
        handler = () => {};
        break;
      case JMENUM.VpPmPlanAction.UPDATE:
        handler = () => {
          this.router.navigate([`/pm/plans/${this.pmPlanNumber}/edit`]);
        };
        break;
      case JMENUM.VpPmPlanAction.REFER:
        handler = () => {
          this.pmPlanReferEditorPanel.toggle();
        };
        break;
      case JMENUM.VpPmPlanAction.EQUIPMENT:
        handler = () => {
          this.router.navigate([`/pm/plans/${this.pmPlanNumber}/equipment-list`]);
        };
        break;
      case 'equipmentview': 
        handler = () => {
          this.router.navigate([`/pm/plans/${this.pmPlanNumber}/equipment-list-view`]);
        };
        break;
      case JMENUM.VpPmPlanAction.ATTACHMENT:
        handler = () => {};
        break;
      default:
        break;
    }

    actionButton.handler = handler;
    this.actionSidebar.dataList.push(actionButton);
  }
  
  //------------------------
  // button callback
  onReferPmPlan(pmPlan: JMOBJ.PmPlan) {
    this.requestPmPlan();
    this.pmPlanReferEditorPanel.close();
  }

  //------------------------
  get isEquipmentApprovalPending(): boolean {
    return this.plan?.approvalObject?.type == JMENUM.ApprovalType.PMPERIOD_EQUIPMENT_EDIT;
  }
}
