export const TabMenuItems = {
  'settings-general': [
    {
      id: 'tab-menu-item-vendor',
      title: 'tab-menu.item.vendor',
      route: '/settings/general/vendor-info',
    },
    {
      id: 'tab-menu-item-user-profile',
      title: 'tab-menu.item.user-profile',
      route: '/settings/general/user-profile',
    },
  ],
  'settings-contract': [
    {
      id: 'tab-menu-item-contract',
      title: 'tab-menu.item.contract',
      route: '/settings/contract/contract',
    },
    {
      id: 'tab-menu-item-contract-severity',
      title: 'tab-menu.item.contract-severity',
      route: '/settings/contract/contract-severity-list',
    },
    {
      id: 'tab-menu-item-emsd-contact',
      title: 'tab-menu.item.emsd-contact',
      route: '/settings/contract/emsd-contact',
    },
    // hide tab in phase 1
    // {
    //   id: 'tab-menu-item-severity-view',
    //   title: 'tab-menu.item.severity-view',
    //   route: '/settings/contract/severity-view',
    // },
    // {
    //   id: 'tab-menu-item-pm-plan',
    //   title: 'tab-menu.item.pm-plan',
    //   route: '/settings/contract/pm-plan',
    // }
  ],
};
