import { formatDate } from '@angular/common';
import { createTokenForExternalReference } from '@angular/compiler/src/identifiers';
import * as moment from 'moment';
import { JMLanguage, Language } from './JMLanguage/JMLanguage';

class UtilClass {
  readonly DEFAULT_DATE_FORMAT: string = 'dd/MM/yyy';
  readonly DEFAULT_DATETIME_FORMAT: string = 'dd/MM/yyyy HH:mm';
  readonly DEFAULT_DATE_STRING_FORMAT: string = 'yyyyMMdd';

  constructor() {}

  // format function
  formatDate(isoDate, format = this.DEFAULT_DATETIME_FORMAT) {
    if (!isoDate) {
      return null;
    }
    return formatDate(isoDate, format, 'en-US');
  }

  stringToDate(dateString, format = this.DEFAULT_DATE_STRING_FORMAT) {
    if (!dateString) {
      return null;
    }

    format = this.replaceAll(format, 'd', 'D');
    format = this.replaceAll(format, 'y', 'Y');

    let momentObj = moment(dateString, format);
    if (!momentObj) {
      return null;
    }

    return momentObj.toDate();
  }

  formatDateString(dateString, from = this.DEFAULT_DATE_STRING_FORMAT, to = this.DEFAULT_DATE_FORMAT) {
    return this.formatDate(this.stringToDate(dateString, from), to);
  }

  replaceAll(text, from, to) {
    while (text.includes(from)) {
      text = text.replace(from, to);
    }

    return text;
  }

  toString(object) {
    return '' + object ? object : '';
  }
  
  getToday(){
    return moment().set(
      {hour:0,minute:0,second:0,millisecond:0}
    );
  }

  isValidEmail(email) {
    let validCharacters = '0123456789abcdefghijklmnopqrstuvwxyz-_';
    email = email.toLowerCase();

    if (!email.includes('@')) {
      return false;
    }
    if (email.indexOf('@') != email.lastIndexOf('@')) {
      return false;
    }
    if (email.lastIndexOf('.') <= email.indexOf('@') + 1) {
      return false;
    }
    if (!validCharacters.includes(email.substr(email.length - 1))) {
      return false;
    }
    if (!validCharacters.includes(email.substr(0, 1))) {
      return false;
    }
    if (email.includes(' ')) {
      return false;
    }
    return true;
  }

  pad(num) {
    var s = '0' + num;
    return s.substr(s.length - 2);
  }

  getMinute(second: number): string {

    if (Math.floor(second / 60) === 0) {
      return ''
    }

    if (Math.floor(second / 60) > 1 && JMLanguage.getCurrentLanguage() === Language.EN) {
      return Math.floor(second / 60) + JMLanguage.translate("global.minute") + "s"
    } else {
      return Math.floor(second / 60) + JMLanguage.translate("global.minute")
    }

  }

  getExtraSecond(second: number): string {

    if (Math.floor(second % 60) === 0) {
      return ''
    }

    if (Math.floor(second % 60) > 1 && JMLanguage.getCurrentLanguage() === Language.EN) {
      return Math.floor(second % 60) + JMLanguage.translate("global.second") + "s"
    } else {
      return Math.floor(second % 60) + JMLanguage.translate("global.second")
    }

  }
}

let Util = new UtilClass();
export { Util };
