<nav class="navbar theme fixed-top d-flex justify-content-md-end justify-content-between align-items-end py-0">
  <div class="nav-title d-flex font-size-xl align-self-center mr-auto" id="id_navbar_title" *ngIf="navbarTitle">
    {{ navbarTitle }}
  </div>
  <app-breadcrumbs class="nav-title d-flex align-self-center mr-auto ml-2" [breadcrumbs]="breadcrumbs" *ngIf="breadcrumbs.length > 0 && !navbarTitle"></app-breadcrumbs> 
  
  <div class="nav-item-wrap d-flex"> 
    <div *ngFor="let item of navItems" class="nav-item mr-4">
      <a class="nav-link px-0" id="{{ item.id }}" routerLink="{{ item.route }}"  routerLinkActive="active">
        {{ item.title  | translate }}
      </a>
    </div>

    <div class="nav-item mr-4">
      <button *ngIf="currentLanguage != languageList.ZH " class="nav-link px-0" id="lang-zh-btn" (click)="onLanguageButtonClicked(languageList.ZH)">中</button>
      <button *ngIf="currentLanguage != languageList.EN " class="nav-link px-0" id="lang-en-btn" (click)="onLanguageButtonClicked(languageList.EN)">En</button>
    </div>
    <div class="nav-item mr-4 dropdown">
      <a class="nav-link clickable dropdown-toggle px-0" id="user-link" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fas fa-user-circle mr-1"></i> {{ username }}
      </a>
      <div class="dropdown-menu" aria-labelledby="navbarDropdown" id="user-dropdown">
        <div id="navbar-logout-btn" class="dropdown-item clickable px-1" (click)="logout()">{{ "global.logout" | translate }}</div>
      </div>
    </div>

    <ng-container *ngIf="showActionSidebarIcon">
      <div class="nav-item">
        <div class="nav-link navbar-nav mr-auto px-0">
          <button type="button" id="" class="actionSidebar" (click)="$event.stopPropagation();toggleActionSidebar()">
            <i class="fas fa-bars"></i>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</nav>
