<!-- <div class="modal-header">
    <h4 class="modal-title">{{msg.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">

        <span aria-hidden="true">&times;</span>
    </button>
</div> -->
<div class="modal-body" *ngIf="contentType === 'normal'">
    <ng-container *ngIf="msg.content">
        <div class="row col-12 mt-3 mb-4 mx-0">{{msg.content | translate}}</div>
    </ng-container>
    <!-- for input field use -->
    <!-- <ng-container *ngIf="msg"></ng-container> -->
    <div class="row justify-content-end m-0">
        <ng-container *ngFor="let button of buttons; let i = index">
            <div class="col-auto ml-2 px-0">
                <button class="btn standard" [ngClass]="{ 
                'brand-blue': buttons.length == 1 || (buttons.length == 2 && i == 0),
                'brand-red': buttons.length == 2 && i == 1 
                }" (click)="buttonClicked(button)">{{button.name | translate}}</button>
            </div>
        </ng-container>
    </div>
</div>

<ng-container *ngIf="contentType === 'detail'">
    <div class="modal-container">
        <div class="modal-header">
            <h4 class="modal-title">
                <i [class]="data.header?.iconCss" *ngIf="data.header?.iconCss"></i>
                {{data.header?.title}}
            </h4>
        </div>
        <div class="modal-body">
            <ng-container *ngFor="let contentDetail of data.content">
                <p>{{contentDetail.msg | translate}}</p>
            </ng-container>
        </div>
        <div class="modal-footer" *ngIf="buttons.length">
            <ng-container *ngFor="let button of buttons; let i = index">
                <div class="col-auto ml-2 px-0">
                    <button (click)="buttonClicked(button)" class="{{(button.cssClass ||
                    (buttons.length == 2 && i == 1  ? 'brand-red' : 'brand-blue')) + ' popup-btn'}}">
                        {{ button.name | translate }} 
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
