<form [formGroup]="rejectForm">
  <div class="container-fluid mb-3">
    <div class="d-flex align-items-center justify-content-between my-3">
      <h3>
        {{ 'component.sn-reject-editor.title' | translate }}
      </h3>
      <app-loading-button  
        [id]="'reject_sn_button'"
        [text]="'global.submit'"
        [buttonClass]="'w-2 standard brand-amber'"
        [iconClass]="'fa-check'"
        [isDisabled]="rejectForm.invalid || submitBtn.isLoading"
        [isLoading]="submitBtn.isLoading"
        (clickedButton)="onSubmitBtnClicked()"
      ></app-loading-button>
    </div>

    <div class="row align-items-center my-3">
      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'component.sn-reject-editor.reject-reason' | translate}}
      </div>
      <div class="col-12">
          <ng-select
            id="reject_reason_input"
            formControlName="reason"
            bindLabel="label"
            bindValue="code"
            [items]="reasonList"
          ></ng-select>
      </div>
      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'component.sn-reject-editor.reject-remarks' | translate}}
      </div>
      <div class="col-12">
        <textarea
          id="reject_remarks_input"
          class="form-control"
          rows="5"
          formControlName="remarks"
          [maxlength]="inputBoxMaxLength">
        </textarea>
        <span 
          *ngIf="remarks?.value?.length >= inputBoxMaxLength" 
          class="red small"
        >
          {{ "slider.textarea.max-length" | translate:[inputBoxMaxLength]}}
        </span>
      </div>
    </div>

    <div class="row align-items-center my-3">
      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'global.mandatory-fields' | translate}}
      </div>
    </div>
  </div>
</form>
