import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { CustomSliderPanelComponent } from 'src/app/shared/custom-slider-panel/custom-slider-panel.component';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';
import { Constants } from 'src/constants';
import { FormAddTeamMemberComponent } from '../form-add-team-member/form-add-team-member.component';

@Component({
  selector: 'app-team-member-table',
  templateUrl: './team-member-table.component.html',
  styleUrls: ['./team-member-table.component.scss'],
})
export class TeamMemberTableComponent implements OnInit, OnChanges {
  @ViewChild('copy_member_panel', { static: false }) copyMemberPanel;
  @ViewChild('copy_member_form', { static: false }) copyMemberForm;
  @ViewChild('add_member_panel', { static: false }) addMemberPanel: CustomSliderPanelComponent;
  @ViewChild('add_member_form', { static: false }) addMemberForm: FormAddTeamMemberComponent;
  @ViewChild('edit_member_info_panel', { static: false }) editMemberInfoPanel: CustomSliderPanelComponent;

  @Input() hasUpdatePermission: boolean;
  @Input() vendorTeam?: JMOBJ.VendorTeam;

  copyMemberParam: {
    isLoading: boolean;
    team: JMOBJ.VendorTeam;
    onCopiedMember: any;
  } = {
    isLoading: false,
    team: new JMOBJ.VendorTeam(),
    onCopiedMember: () => {},
  };

  memberList: JMOBJ.Post[] = [];
  selectedTeam: JMOBJ.VendorTeam;
  selectedPost: JMOBJ.Post;

  //Team Member Table
  tablexParam: {} = {};
  tablexFilter: {} = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize: number = 10;
  currentPage: number = 1;
  pageCount: number = 1;
  sortBy = undefined;
  sortOrder = undefined;
  allColHeaders: any = [];
  selectedRows: any = [];
  selectedTableRows: any = [];

  constructor() {}

  ngOnInit(): void {
    this.initTableHeader();
    this.initTablex();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reloadMemberListTable(changes.vendorTeam.currentValue);
  }

  // ----------- relaod ----------- //
  private reloadMemberListTable(vendorTeam?: JMOBJ.VendorTeam) {
    this.memberList = [];
    this.selectedTeam = vendorTeam;

    if (vendorTeam) {
      this.currentPage = 1;
      this.requestVendorTeamMemberList();

      // TODO: refactor copyMember
      // this.copyMemberForm.initForm();
      // this.copyMemberParam.team = this.selectedTeam;
      // this.copyMemberForm.requestTeamList();
    } else {
      this.renderTable();
    }
  }

  // ----------- API ----------- //
  private async requestVendorTeamMemberList() {
    let request = new JM.JMRequestPostsGetVendorTeamMemberList();
    request.vendorTeamId = this.vendorTeam._id;
    request.pageSize = this.currentPageSize;
    request.pageNumber = this.currentPage;
    request.filter = this.tablexFilter;

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponsePostsGetVendorTeamMemberList = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.memberList = response.payload.records;
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);

    this.renderTable();
  }

  private async requestDeleteMember(post: JMOBJ.Post) {
    let request = new JM.JMRequestPostsRemoveVendorTeamMember();
    request.vendorTeamId = this.vendorTeam._id;
    request.postName = post.name;
    request.includeVendorTeamMemberList = false;

    const response: JM.JMResponsePostsRemoveVendorTeamMember = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.settings-team.member-deleted'));
    this.requestVendorTeamMemberList();
  }

  // ----------- UI function ----------- //
  onAddedMembers = () => {
    this.addMemberPanel.close();
    this.requestVendorTeamMemberList();
  };

  onCopiedMember = () => {
    this.copyMemberPanel.close();
    this.requestVendorTeamMemberList();
  };

  onClickAddMemberButton() {
    // manual trigger get member list api
    this.addMemberForm.initMemberListForm(this.vendorTeam);
    this.addMemberPanel.toggle();
  }

  onClickCopyButton() {
    this.copyMemberParam.onCopiedMember = this.onCopiedMember;
    this.copyMemberPanel.toggle();
  }

  onClickDeleteMemberButton(post: JMOBJ.Post) {
    let button = {
      msg: {
        content: 'popup.confirm-to-delete',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestDeleteMember(post);
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(button);
  }

  onEditTeamMemberButton(post: JMOBJ.Post) {
    this.selectedPost = post;
    this.editMemberInfoPanel.toggle();
  }

  onUpdatedTeamMemberInfo(post: JMOBJ.Post) {
    this.editMemberInfoPanel.toggle();
    this.requestVendorTeamMemberList();
  }

  // ----------- Tablex UI function ----------- //
  onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.requestVendorTeamMemberList();
  };

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.requestVendorTeamMemberList();
  };

  renderTable() {
    this.tablexParam['content'] = this.memberList.map((data, index) => {
      const vendorTeamId = this.vendorTeam?._id;
      const remarks = data.vendorTeamList.find(vendroTeam => vendroTeam?._id == vendorTeamId)?.remarks;

      let roles = [];
      if (data.systems && data.systems[Constants.SYSTEM_NAME]) {
        const systemProfile = data.systems[Constants.SYSTEM_NAME];

        if (systemProfile.roleIds && systemProfile.roleIds.length) {
          roles = systemProfile.roleIds.map((roleId) => {
            return JMLanguage.translate('role.' + roleId);
          });
        }
      }

      let button = [];
      if (this.hasUpdatePermission) {
        button = [
          {
            id: 'edit-team-member-' + data._id,
            name: '',
            disable: false,
            title: 'Edit',
            class: 'btn',
            icon: 'fa-pencil-alt fas amber font-size-xl',
            onClicked: () => {
              this.onEditTeamMemberButton(data);
            },
          },
          {
            id: 'delete-member-' + data._id,
            name: '',
            disable: false,
            title: 'Delete',
            class: 'btn',
            icon: 'fas fa-times red font-size-xl',
            onClicked: () => {
              this.onClickDeleteMemberButton(data);
            },
          },
        ];
      }

      const row = [
        data._id,
        data.name,
        data.employeeName,
        remarks,
        data.phone,
        roles,
        data.systems[Constants.SYSTEM_NAME].isActive
          ? JMLanguage.translate('global.active')
          : JMLanguage.translate('global.inactive'),
        button,
      ];
      return row;
    });
  }

  initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableClearFilter: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      enableSort: false,
      minifyButton: true,
      tableRow: 'row',
      tableClass: 'team-member-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };

    this.initTableHeader();
  }

  initTableHeader() {
    let enableAction = this.hasUpdatePermission ? 'col' : 'd-none';
    this.allColHeaders = [
      {
        id: 'objId',
        name: '',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'd-none ',
      },
      {
        id: 'account',
        name: 'pages.settings-team.table-column.account',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col col-2',
      },
      {
        id: 'staffName',
        name: 'pages.settings-team.table-column.staff-name',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'remarks',
        name: 'pages.settings-team.table-column.remarks',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col col-2',
      },
      {
        id: 'telNumber',
        name: 'pages.settings-team.table-column.tel-number',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'role',
        name: 'pages.settings-team.table-column.role',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col col-2',
      },
      {
        id: 'status',
        name: 'pages.settings-team.table-column.status',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'action',
        name: 'pages.settings-team.table-column.action',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Buttons,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: enableAction,
      },
    ];
  }
}
