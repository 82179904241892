import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DateTimeInput } from './cm-task-progress/cm-task-progress.model';

@Injectable({
  providedIn: 'root',
})
export class CmTaskProgressService {
  constructor() {}

  isMissingMandatoryField(dateTimeInputList: DateTimeInput[]): boolean {
    let hasError = false;

    for (const dateTimeInput of dateTimeInputList) {
      const hasDateTime = dateTimeInput.dateInput && dateTimeInput.timeInput;

      if (dateTimeInput.isMandatory && !hasDateTime) {
        hasError = true;
        dateTimeInput.isError = true;
      }
    }
    return hasError;
  }

  // assume correct if it has date and time
  isInvalidFormat(dateTimeInputList: DateTimeInput[]): boolean {
    let hasError = false;

    for (const dateTimeInput of dateTimeInputList) {
      const isMissingDate = !dateTimeInput.dateInput && dateTimeInput.timeInput;
      const isMissingTime = dateTimeInput.dateInput && !dateTimeInput.timeInput;

      if (isMissingDate || isMissingTime) {
        hasError = true;
        dateTimeInput.isError = true;
      }
    }
    return hasError;
  }

  isFutureDate(dateTimeInputList: DateTimeInput[]): boolean {
    const currentDate = Date.now();
    let hasError = false;

    for (const dateTimeInput of dateTimeInputList) {
      if (dateTimeInput.value && dateTimeInput.value.valueOf() > currentDate.valueOf()) {
        hasError = true;
        dateTimeInput.isError = true;
      }
    }
    return hasError;
  }

  isOverVendorEndDate(dateTimeInputList: DateTimeInput[], vendorEndDate: moment.Moment): boolean {
    let hasError = false;
    let endDate = vendorEndDate.toDate();
    endDate.setHours(23);
    endDate.setMinutes(59);

    for (const dateTimeInput of dateTimeInputList) {
      if (dateTimeInput.value && dateTimeInput.value.valueOf() > endDate.valueOf()) {
        hasError = true;
        dateTimeInput.isError = true;
      }
    }
    return hasError;
  }

  isTime1EqualOrLaterThanTime2(dateTimeInput1: DateTimeInput, dateTimeInput2: DateTimeInput): boolean {
    const isTime1LaterThanTime2 = dateTimeInput1.value.valueOf() >= dateTimeInput2.value.valueOf();
    let hasError = false;

    if (isTime1LaterThanTime2) {
      dateTimeInput1.isError = true;
      dateTimeInput2.isError = true;

      hasError = true;
    }

    return hasError;
  }

  isTime1LaterThanTime2(dateTimeInput1: DateTimeInput, dateTimeInput2: DateTimeInput): boolean {
    const isTime1LaterThanTime2 = dateTimeInput1.value.valueOf() > dateTimeInput2.value.valueOf();
    let hasError = false;

    if (isTime1LaterThanTime2) {
      dateTimeInput1.isError = true;
      dateTimeInput2.isError = true;

      hasError = true;
    }

    return hasError;
  }

  isTime1Time2DiffMeetsInterval(dateTimeInput1: DateTimeInput, dateTimeInput2: DateTimeInput, timeInterval: number): boolean {
    const timeDiff = dateTimeInput2.value.valueOf() - dateTimeInput1.value.valueOf();
    let pass = true;
    if (timeDiff < timeInterval) {
      dateTimeInput1.isError = true;
      dateTimeInput2.isError = true;
      pass = false;
    }
    return pass;
  }
}
