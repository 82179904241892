import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';
import { Constants } from 'src/constants';
import { EquipmentService } from '../../../core/equipment.service';

@Component({
  selector: 'app-severity-criteria-editor',
  templateUrl: './severity-criteria-editor.component.html',
  styleUrls: ['./severity-criteria-editor.component.scss'],
})
export class SeverityCriteriaEditorComponent implements OnInit {
  @Input() contractSeverity?: JMOBJ.ContractSeverity;

  @Output() addedCriteria: EventEmitter<JMOBJ.ContractSeverityCriteria> = new EventEmitter();

  isEditMode: boolean = false;
  timeReferenceUnitList: {
    label: string;
    value: string;
  }[] = [];
  slaPriorityList: {
    label: string;
    value: JMENUM.JMPriority;
  }[] = [];

  // data
  criteriaForm?: FormGroup;
  clientList: {
    label: string;
    value: string;
  }[] = [];
  districtList: {
    label: string;
    value: number;
  }[] = [];
  locationList: JMOBJ.Location[] = [];
  equipmentCategoryList: {
    label: string;
    value: string;
  }[] = [];
  equipmentTypeList: {
    label: string;
    value: string;
  }[] = [];

  // UI
  inputBoxMaxLength = Constants.TEXTAREA_MAX_LENGTH;
  submitBtn = {
    isShow: true,
    isDisabled: true,
    isLoading: false,
  };

  // location dropdown
  isLoadingLocationList = false;
  private locationSearch: {
    keywords: string;
    search$: Subject<any[]>;
    totalPageNumber: number;
    pageNumber: number;
    pageSize: number;
  } = {
    search$: new Subject<any[]>(),
    keywords: '',
    totalPageNumber: 0,
    pageNumber: 1,
    pageSize: 100,
  };

  constructor(private fb: FormBuilder, private equipmentService: EquipmentService) {}

  ngOnInit(): void {
    this.initForm();
    this.resetForm();
    this.resetClientList();
    this.resetDistrictList();
    this.resetEquipmentCategoryOption();
    this.resetEquipmentTypeOption();

    this.initLocationSearchObserver();
    this.initEquipmentCategoryObserver();
    this.initEquipmentTypeObserver();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  //------------------------
  // Parent component call function
  refreshData() {
    this.resetForm();
    this.requestLocationSummary();
  }

  //------------------------
  // init or reset
  private initEquipmentCategoryObserver() {
    this.equipmentCategory.valueChanges.subscribe((value) => {
      this.equipmentType.reset(null);
      this.resetEquipmentTypeOption(value);
    });
  }

  private initEquipmentTypeObserver() {
    this.equipmentType.valueChanges.subscribe((value) => {
      this.resetEquipmentCategoryOption(value);
    });
  }

  private resetClientList() {
    let options = [];
    const clientDict = Session.getClient();

    for (const key in clientDict) {
      if (Object.prototype.hasOwnProperty.call(clientDict, key)) {
        const client = clientDict[key];
        options.push({
          label: client.clientShortName,
          value: client.clientShortName,
        });
      }
    }
    this.clientList = options;
  }

  private resetDistrictList() {
    let options = [];
    JM.JMConnector.getAllDistrictCode().map((code) => {
      this.districtList.push(code);
      options.push({
        label: JMLanguage.translate(`enum.district.${code}`),
        value: code,
      });
    });
    this.districtList = options;
  }

  private resetEquipmentCategoryOption(type?: string) {
    this.equipmentCategoryList = [];
    let typeCodeList = [];

    typeCodeList = type
      ? this.equipmentService.getEquipmentCategoryCodeFromType(type)
      : this.equipmentService.getAllEquipmentCategoryCode();
    typeCodeList = typeCodeList.sort();

    for (const code of typeCodeList) {
      const obj = this.equipmentService.getEquipmentCategory(code);
      if (obj) {
        this.equipmentCategoryList.push({
          label: `${code} - ${obj.description[Session.selectedLanguage]}`,
          value: code,
        });
      }
    }
  }

  private resetEquipmentTypeOption(category?: string) {
    this.equipmentTypeList = [];
    let categoryCodeList = [];

    categoryCodeList = category
      ? this.equipmentService.getEquipmentTypeCodeFromCategory(category)
      : this.equipmentService.getAllEquipmentTypeCode();
    categoryCodeList = categoryCodeList.sort();

    for (const code of categoryCodeList) {
      const obj = this.equipmentService.getEquipmentType(code);
      if (obj) {
        this.equipmentTypeList.push({
          label: `${code} - ${obj.description[Session.selectedLanguage]}`,
          value: code,
        });
      }
    }
  }

  private initForm() {
    this.criteriaForm = this.fb.group({
      client: [null],
      district: [null],
      location: [null],
      equipmentCategory: [null],
      equipmentType: [null],
    });
  }

  private resetForm() {
    this.criteriaForm.reset({
      client: null,
      district: null,
      location: null,
      equipmentCategory: null,
      equipmentType: null,
    });
  }

  //------------------------
  // api
  private async requestAddCriteria() {
    let request = new JM.JMRequestContractsAddContractSeverityCriteria();
    request.contractSeverityId = this.contractSeverity._id;
    request.client = !!this.client.value ? this.client.value : undefined;
    request.district = !!this.district.value ? this.district.value : undefined;
    request.location = !!this.location.value ? this.location.value : undefined;
    request.equipmentCategory = !!this.equipmentCategory.value ? this.equipmentCategory.value : undefined;
    request.equipmentType = !!this.equipmentType.value ? this.equipmentType.value : undefined;

    this.submitBtn.isLoading = true;
    const response: JM.JMResponseContractsAddContractSeverityCriteria = await AppDelegate.sendJMRequest(request);
    this.submitBtn.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.addedCriteria.emit(response.payload);
    this.resetForm();
  }

  private async requestLocationSummary(isAppend: boolean = false) {
    let request = new JM.JMRequestLocationsLocationSummary();
    request.parameters = ['code', 'districtCode'];
    request.pageSize = this.locationSearch.pageSize;
    request.pageNumber = this.locationSearch.pageNumber;
    request.locationOnly = true;

    if (!!this.locationSearch.keywords) {
      request.locationDescription = this.locationSearch.keywords;
    }

    this.isLoadingLocationList = true;
    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingLocationList = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.locationSearch.totalPageNumber = Math.ceil(response.payload.totalCount / request.pageSize);
    if (isAppend) {
      this.locationList = this.locationList.concat(response.payload.records);
    } else {
      this.locationList = response.payload.records;
    }
  }

  //------------------------
  // location dropdown event
  onClearLocation() {
    this.clearLocationOption();
    this.locationSearch.keywords = null;
    this.requestLocationSummary(false);
  }

  onLocationScrollToEnd() {
    if (this.locationSearch.pageNumber < this.locationSearch.totalPageNumber) {
      this.locationSearch.pageNumber++;
      this.requestLocationSummary(true);
    }
  }

  onSearchLocation(event) {
    this.locationSearch.keywords = event.term;
    this.locationSearch.search$.next();
  }

  private clearLocationOption() {
    this.locationList = [];
    this.locationSearch.pageNumber = 1;
    this.locationSearch.totalPageNumber = 0;
  }

  private initLocationSearchObserver() {
    this.locationSearch.search$.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.clearLocationOption();
      this.requestLocationSummary();
    });
  }
  //------------------------
  // ui

  //------------------------
  // button callback
  onSubmitBtnClicked() {
    const data = {
      msg: {
        content: 'popup.confirm-to-save',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestAddCriteria();
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(data);
  }

  //------------------------
  // get form property
  get client() {
    return this.criteriaForm.get('client');
  }
  get district() {
    return this.criteriaForm.get('district');
  }
  get location() {
    return this.criteriaForm.get('location');
  }
  get equipmentCategory() {
    return this.criteriaForm.get('equipmentCategory');
  }
  get equipmentType() {
    return this.criteriaForm.get('equipmentType');
  }

  //------------------------
  // get property
  get formTitleText(): string {
    if (this.isEditMode) {
      return JMLanguage.translate('component.severity-criteria-editor.title.edit');
    } else {
      return JMLanguage.translate('component.severity-criteria-editor.title.add');
    }
  }
}
