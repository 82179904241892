import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { UserHelper } from 'src/app/core/user-helper';
import { CustomSliderPanelComponent } from 'src/app/shared/custom-slider-panel/custom-slider-panel.component';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';
import { Constants } from 'src/constants';
import { PopUpDialogI } from '../../../shared/pop-up-dialog/pop-up-dialog.model';
import { ContractSeverityEditorComponent } from '../contract-severity-editor/contract-severity-editor.component';

@Component({
  selector: 'app-contract-severity-list-table',
  templateUrl: './contract-severity-list-table.component.html',
  styleUrls: ['./contract-severity-list-table.component.scss'],
})
export class ContractSeverityListTableComponent implements OnInit {
  @ViewChild('add_contract_severity_panel', { static: false }) addContractSeverityPanel: CustomSliderPanelComponent;
  @ViewChild('add_contract_severity_form', { static: false })
  addContractSeverityEditor: ContractSeverityEditorComponent;

  @Input() hasUpdatePermission: boolean = false;
  @Input() contract?: JMOBJ.MaintenanceTermContract;

  contractSeverityList: JMOBJ.ContractSeverity[] = [];
  selectedContractSeverity?: JMOBJ.ContractSeverity;

  // Table
  tablexParam: {} = {};
  tablexFilter: {} = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize: number = 10;
  currentPage: number = 1;
  pageCount: number = 1;
  allColHeaders: any = [];
  selectedRows: any = [];
  selectedTableRows: any = [];

  constructor() {}

  ngOnInit(): void {
    this.initTableHeader();
    this.initTablex();
  }

  // ----------- relaod ----------- //
  ngOnChanges(changes: SimpleChanges): void {
    this.reloadContractSeverityListTable();
  }

  private reloadContractSeverityListTable() {
    this.initTableHeader();
    this.initTablex();
    if (this.contract) {
      this.requestContractSeverityList();
    } else {
      this.contractSeverityList = [];
      this.resetTable();
      this.renderTable();
    }
  }

  /// ----------- API ----------- //
  private async requestContractSeverityList() {
    if (!UserHelper.hasPermission(JMENUM.VpPermission.SEVERITY_VIEW)) {
      return;
    }

    let request = new JM.JMRequestContractsGetContractSeverityList();
    request.contractNumber = this.contract.contractNumber;
    request.pageSize = this.currentPageSize;
    request.pageNumber = this.currentPage;
    request.filter = this.tablexFilter;

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseContractsGetContractSeverityList = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.contractSeverityList = response.payload.records;
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
    this.renderTable();
  }

  private async requestRemoveContractSeverity(contractSeverityId: string) {
    let request = new JM.JMRequestContractsRemoveContractSeverity();
    request.contractSeverityId = contractSeverityId;

    const response: JM.JMResponseContractsRemoveContractSeverity = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.deleted'));
    this.resetTable();
    this.requestContractSeverityList();
  }

  // ----------- UI function ----------- //
  onClickedDeleteButton() {
    const button: PopUpDialogI = {
      msg: {
        content: 'popup.confirm-to-delete',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            const contractSeverityId = this.selectedContractSeverity._id;
            this.requestRemoveContractSeverity(contractSeverityId);
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(button);
  }

  onClickedAddButton() {
    this.addContractSeverityPanel.toggle();
  }

  onAddedContractSeverity(contractSeverity: JMOBJ.ContractSeverity) {
    this.requestContractSeverityList();
    this.addContractSeverityPanel.close();
  }

  // onUpdatedContractSeverity(contractSeverity: JMOBJ.ContractSeverity) {
  //   this.requestContractSeverityList();
  //   this.editContractSeverityPanel.close();
  // }

  // ----------- Tablex UI function ----------- //
  onRowSelected = (index, row) => {
    if (!this.contractSeverityList) {
      return;
    }
    this.selectedContractSeverity = this.contractSeverityList.find((team) => team._id == row[0]);
  };

  onRowUnselected = (index, row) => {
    this.selectedContractSeverity = undefined;
  };

  onFilterChanged = (event, index, header, filter) => {
    for (let key in filter) {
      if (!filter[key]) {
        delete this.tablexFilter[key];
      } else {
        this.tablexFilter[key] = filter[key];
      }
    }
    this.currentPage = 1;
    this.resetTable();
    this.requestContractSeverityList();
  };

  onFilterClear = () => {
    this.tablexFilter = {};
    this.tablexParam['filter'] = {};
    this.resetTable();
    this.requestContractSeverityList();
  };

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.resetTable();
    this.requestContractSeverityList();
  };

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.resetTable();
    this.requestContractSeverityList();
  };

  private resetTable() {
    this.selectedTableRows = [];
    this.tablexParam['selectedRowIndex'] = undefined;
    this.tablexParam['highlightedRows'] = [];
    this.selectedContractSeverity = undefined;
  }

  private renderTable() {
    this.tablexParam['content'] = this.contractSeverityList.map((severity, index) => {
      const severityHtml = this.getSeverityHtml(severity);
      const liftTrapped =
        severity && severity.liftTrapped ? JMLanguage.translate('global.yes') : JMLanguage.translate('global.no');
      const timeReferenceHtml = this.getTimeReferenceHtml(severity);
      const numberOfRecords = severity.criteriaList.length;
      const priority = JMLanguage.translate(`sla.priority.${severity.priority}`);

      const row = [severity._id, [severityHtml], liftTrapped, [timeReferenceHtml], numberOfRecords, priority];

      return row;
    });
  }

  private getSeverityHtml(severity: JMOBJ.ContractSeverity): string {
    const descriptionHtml = !!severity.description ? `<div>${severity.description}</div>` : null;
    const criteriaPageUrl = `/settings/contract/contract-severity-list/contract-severity/${severity._id}`;
    let html = `<a href="${criteriaPageUrl}">${severity.name}</a>`;

    if (descriptionHtml) {
      html += descriptionHtml;
    }

    return html;
  }

  private getTimeReferenceHtml(severity: JMOBJ.ContractSeverity): string {
    const responseToClientTitle = JMLanguage.translate('component.contract-severity-list-table.cell.respond-to-client');
    const responseToClientValue = this.formatTimeReference(severity.responseToClient, severity.responseToClientUnit);
    const responseToClientHtml = `<div>${responseToClientTitle}: ${responseToClientValue}</div>`;

    const responseTimeTitle = JMLanguage.translate('component.contract-severity-list-table.cell.respond-time');
    const responseTimeValue = this.formatTimeReference(severity.responseTime, severity.responseTimeUnit);
    const responseTimeHtml = `<div>${responseTimeTitle}: ${responseTimeValue}</div>`;

    const rectificationTimeTitle = JMLanguage.translate(
      'component.contract-severity-list-table.cell.rectification-time'
    );
    const rectificationTimeValue = this.formatTimeReference(severity.rectificationTime, severity.rectificationTimeUnit);
    const rectificationTimeHtml = `<div>${rectificationTimeTitle}: ${rectificationTimeValue}</div>`;

    const type = JMLanguage.translate(`enum.vp-time-reference-type.${severity.timeReferenceType}`);
    const typeHtml = `<div>(${type})</div>`;

    const html = responseToClientHtml + responseTimeHtml + rectificationTimeHtml + typeHtml;
    return html;
  }

  private formatTimeReference(value: number, unit: JMENUM.VPTimeReferenceUnit): string {
    if (!value) {
      return 'N/A';
    }

    const unitText = JMLanguage.translate(`enum.vp-time-reference-unit.${unit}`);
    return `${value} ${unitText}`;
  }

  private initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableClearFilter: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      enableSort: false,
      minifyButton: true,
      tableRow: 'row',
      tableClass: 'contract-severity-list-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowSelected: this.onRowSelected,
      onRowUnselected: this.onRowUnselected,
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };

    this.initTableHeader();
  }

  private initTableHeader() {
    const trapColumnClass = this.isEnableContractLiftTrapped ? 'col-1' : 'd-none';

    this.allColHeaders = [
      {
        id: 'objId',
        name: '',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'd-none ',
      },
      {
        id: 'severity',
        name: 'component.contract-severity-list-table.table-column.severity',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'trap',
        name: 'component.contract-severity-list-table.table-column.trap',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: trapColumnClass,
      },
      {
        id: 'time-reference',
        name: 'component.contract-severity-list-table.table-column.time-reference',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'number-of-reconrds',
        name: 'component.contract-severity-list-table.table-column.number-of-reconrds',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col-1 ',
      },
      {
        id: 'sla-priority',
        name: 'component.contract-severity-list-table.table-column.sla-priority',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col-2 ',
      },
    ];
  }

  // ----------- get property function ----------- //
  get isEnableContractLiftTrapped(): boolean {
    return this.contract && this.contract.liftTrapped;
  }
}
