import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { UserHelper } from 'src/app/core/user-helper';
import { ActionSidebarV2Service } from '../../shared/action-sidebar-v2.service';
import { ActionButtonI } from '../../shared/action-sidebar-v2/action-sidebar-v2.model';
import { CustomSliderPanelComponent } from '../../shared/custom-slider-panel/custom-slider-panel.component';
import { SnViewActionButtonList } from './sn-view.model';

@Component({
  selector: 'app-sn-view',
  templateUrl: './sn-view.component.html',
  styleUrls: ['./sn-view.component.scss'],
})
export class SnViewComponent implements OnInit {
  @ViewChild('sn_cancel_editor_panel', { static: false }) snCancelEditorPanel: CustomSliderPanelComponent;
  @ViewChild('sn_reject_editor_panel', { static: false }) snRejectEditorPanel: CustomSliderPanelComponent;
  @ViewChild('sn_refer_form_panel', { static: false }) snReferFormPanel: CustomSliderPanelComponent;
  @ViewChild('create_follow_up_job_panel', { static: false }) createFollowUpJobPanel: CustomSliderPanelComponent;

  breadcrumbs: any = [];
  snNumber: string;
  sn: JMOBJ.ServiceNotification = new JMOBJ.ServiceNotification();
  currentPageMode = JMENUM.JMPageMode.VIEW;

  // action buttons
  actionSidebar: {
    dataList: ActionButtonI[];
    isDisabled: boolean;
    isLoading: boolean;
  } = {
    dataList: [],
    isDisabled: false,
    isLoading: false,
  };

  constructor(private route: ActivatedRoute, private actionSidebarV2Service: ActionSidebarV2Service) {}

  ngOnInit(): void {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }

    this.snNumber = this.route.snapshot.paramMap.get('snNumber');
    if (this.snNumber) {
      this.requestVpSn();
      this.setBreadcrumbs();
    }
  }

  //------------------------
  // API
  private async requestVpSn() {
    let request = new JM.JMRequestSnGetVpSn();
    request.snNumber = this.snNumber;

    this.actionSidebar.isLoading = true;
    const response: JM.JMResponseSnGetVpSn = await AppDelegate.sendJMRequest(request);
    this.actionSidebar.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.sn) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.sn = response.payload.sn;
    this.resetActionBar(response.payload.actionList);
  }

  //------------------------
  // UI function
  private setBreadcrumbs() {
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-sn-number',
        name: this.snNumber,
        route: null,
        currentPage: true,
      },
    ];
  }

  //------------------------
  // action button function
  onClickedActionButton(actionButton: ActionButtonI) {
    actionButton.handler();
  }

  private resetActionBar(actionList: JMENUM.VpSnAction[]): void {
    this.actionSidebar.dataList = [];

    if (actionList.length) {
      this.filterActionButton(actionList);
    }
  }

  private filterActionButton(actionList: JMENUM.VpSnAction[]) {
    const sortedButtonList = [
      JMENUM.VpSnAction.ACKNOWLEDGE,
      JMENUM.VpSnAction.CREATE_TASK,
      JMENUM.VpSnAction.CREATE_FOLLOW_UP,
      JMENUM.VpSnAction.REJECT,
      JMENUM.VpSnAction.CANCEL,
      JMENUM.VpSnAction.REFER,
    ];

    for (const sortedButton of sortedButtonList) {
      const showActionButton = actionList.includes(sortedButton);

      if (showActionButton) {
        const button: ActionButtonI = SnViewActionButtonList[sortedButton];
        const hasPermissionList = button && button.permissionList && button.permissionList.length;

        if (hasPermissionList) {
          const allowAction = UserHelper.hasEitherOnePermission(button.permissionList);
          if (allowAction) {
            this.addActionButton(button);
          }
        } else {
          this.addActionButton(button);
        }
      }
    }
  }

  private addActionButton(actionButton: ActionButtonI) {
    let handler = () => {};

    switch (actionButton.action) {
      case JMENUM.VpSnAction.ACKNOWLEDGE:
        handler = () => {
          this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, this.onAckowledge);
        };
        break;
      case JMENUM.VpSnAction.CREATE_TASK:
        handler = () => {
          this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, this.onCreateTask.bind(this));
        };
        break;
      case JMENUM.VpSnAction.CREATE_FOLLOW_UP:
        handler = () => {
          this.createFollowUpJobPanel.toggle();
        };
        break;
      case JMENUM.VpSnAction.CANCEL:
        handler = () => {
          this.snCancelEditorPanel.toggle();
        };
        break;
      case JMENUM.VpSnAction.REJECT:
        handler = () => {
          this.snRejectEditorPanel.toggle();
        };
        break;
      case JMENUM.VpSnAction.REFER:
        handler = () => {
          this.snReferFormPanel.toggle();
        };
        break;
      default:
        break;
    }

    actionButton.handler = handler;
    this.actionSidebar.dataList.push(actionButton);
  }

  //------------------------
  // button callback
  onCancelledSn(parameters: {
    sn: JMOBJ.ServiceNotification, 
    actionList: JMENUM.VpSnAction[]
  }) {
    this.sn = parameters?.sn;
    if (parameters?.actionList) {
      this.resetActionBar(parameters.actionList);
    }

    this.snCancelEditorPanel.close();
  }

  onCreatedFollowUpJob(jobCard: JMOBJ.JobCard) {
    this.createFollowUpJobPanel.close();
    AppDelegate.routeToPage(`/cm-task/${jobCard.snNumber}/${jobCard.jobCardNumber}`);
  }

  onRejectedSn(parameters: {
    sn: JMOBJ.ServiceNotification, 
    actionList: JMENUM.VpSnAction[]
  }) {
    this.sn = parameters?.sn;
    if (parameters?.actionList) {
      this.resetActionBar(parameters.actionList);
    }
    this.snRejectEditorPanel.close();
  }

  onReferredSn(sn: JMOBJ.ServiceNotification) {
    this.sn = sn;
    this.snReferFormPanel.close();
  }

  onAckowledge = async () => {
    let request = new JM.JMRequestSnAcknowledgeVpSn();
    request.snNumber = this.snNumber;
    request.version = this.sn.version;

    this.actionSidebar.isLoading = true;
    const response: JM.JMResponseSnAcknowledgeVpSn = await AppDelegate.sendJMRequest(request);
    this.actionSidebar.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.sn = response.payload.sn;
    AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.acknowledged'));
    this.resetActionBar(response.payload.actionList);
  }
  
  async onCreateTask() {
    const request = new JM.JMRequestJobCardsCreateCmTask();
    request.snNumber = this.snNumber;
    request.snVersion = this.sn.version;

    const response: JM.JMResponseJobCardsCreateCmTask = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.created'));

    let { snNumber, jobCardNumber } = response.payload.jobCard;
    AppDelegate.routeToPage(`/cm-task/${snNumber}/${jobCardNumber}`);
  }

  //------------------------
  // get property
  get hasSnViewPermission(): boolean {
    return UserHelper.hasPagePermission(JMENUM.VpPermission.SN_VIEW);
  }
}
