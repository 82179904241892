import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-slider-panel',
  templateUrl: './custom-slider-panel.component.html',
  styleUrls: ['./custom-slider-panel.component.scss'],
})
export class CustomSliderPanelComponent implements OnInit {
  @Input() widthPx: number; // width px
  @Input() customClass: String;
  @Input() fixedPanel: boolean = false;
  @Output() closedPanel = new EventEmitter<any>();

  active: boolean = false;

  defaultWidthPx: number = 400; // default width px

  ngOnInit() {
    if (!this.widthPx) this.widthPx = this.defaultWidthPx;
  }

  dismiss(event: any) {
    this.close(event);
  }

  //===================================================================================================================
  //
  toggle(event?: any) {
    if (this.active) this.close(event);
    else this.show(event);
  }

  show(event?: any) {
    if (event) event.stopPropagation();
    this.active = true;
  }

  close(event?: any) {
    if (event) event.stopPropagation();
    this.active = false;
    this.closedPanel.emit();
  }
}
