import { JMENUM } from '@ccep/CCEPConnector-ts';
import { ActionButtonI } from 'src/app/shared/action-sidebar-v2/action-sidebar-v2.model';

export const PmPlanEditActionButtonList: { [x: string]: ActionButtonI } = {
  [JMENUM.VpPmPlanAction.UPDATE]: {
    action: JMENUM.VpPmPlanAction.UPDATE,
    permissionList: [JMENUM.VpPermission.PMPLAN_UPDATE, JMENUM.VpPermission.PMREMINDER_UPDATE],
    actionDescription: 'action.button.save',
    buttonId: 'right-tool-bar_save_button',
    imageUrl: '/assets/svg/btn_action_save.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  ['clear']: {
    action: 'clear',
    permissionList: [],
    actionDescription: 'action.button.clear',
    buttonId: 'right-tool-bar_clear_button',
    imageUrl: '/assets/svg/btn_action_clear.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: 'action.button.popup.confirm-clear',
    popUpButtonList: [],
  },
  [JMENUM.VpPmPlanAction.SUBMIT]: {
    action: JMENUM.VpPmPlanAction.SUBMIT,
    permissionList: [JMENUM.VpPermission.PMPLAN_SUBMIT],
    actionDescription: 'action.button.submit',
    buttonId: 'right-tool-bar_submit_button',
    imageUrl: '/assets/svg/btn_action_submit.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  ['close']: {
    action: 'close',
    permissionList: [],
    actionDescription: 'action.button.close',
    buttonId: 'right-tool-bar_close_button',
    imageUrl: '/assets/svg/btn_action_reject.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: 'action.button.popup.confirm-close',
    popUpButtonList: [],
  },
  [JMENUM.VpPmPlanAction.EQUIPMENT]: {
    action: JMENUM.VpPmPlanAction.EQUIPMENT,
    permissionList: [JMENUM.VpPermission.PMPLAN_UPDATE],
    actionDescription: 'action.button.equipment',
    buttonId: 'right-tool-bar_update-equipment_button',
    imageUrl: '/assets/svg/btn_action_equipment.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
};
