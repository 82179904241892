import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import {
  JMResponseAddEquipmentToPmPlan,
  JMResponseRemoveEquipmentFromPmPlan,
} from '@ccep/CCEPConnector-ts/lib/JMConnector/JMConnector';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { ActionSidebarV2Service } from 'src/app/shared/action-sidebar-v2.service';
import { ActionButtonI } from 'src/app/shared/action-sidebar-v2/action-sidebar-v2.model';
import { breadcrumbsComponentI } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { TablexColumnFilterOption, TablexColumnFilterType } from 'src/app/shared/tablex/tablexColumnType';
import { Session } from 'src/app/core/session';
import { Constants } from 'src/constants';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';

@Component({
  selector: 'app-pm-plan-equipment-list',
  templateUrl: './pm-plan-equipment-list.component.html',
  styleUrls: ['./pm-plan-equipment-list.component.scss'],
})
export class PmPlanEquipmentListComponent {
  //Service
  // route: ActivatedRoute;
  location: Location;
  actionSidebarV2Service: ActionSidebarV2Service;
  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.EDIT;
  JMPageMode = JMENUM.JMPageMode;

  //Tabs
  tabOptions = {
    assigned: JMLanguage.translate('component.pm-plan-equipment-list.assigned'),
    unassigned: JMLanguage.translate('component.pm-plan-equipment-list.unassigned'),
  };
  activeTab = this.tabOptions.unassigned;

  //TableX
  tablexParam: { [key: string]: any } = {};
  tablexFilter: any = {};
  locationOptions: TablexColumnFilterOption = new TablexColumnFilterOption();

  //Action buttons
  actionSidebar: {
    dataList: ActionButtonI[];
    isDisabled: boolean;
    isLoading: boolean;
  } = {
    dataList: [],
    isDisabled: false,
    isLoading: false,
  };

  // location options
  locationTotalCount: number;
  locationPageNumber: number = 0;
  locationPageSize: number = 100;
  private searchLocationObserver = new Subject<any[]>();
  searchLocationKeywords: string = undefined;

  //Others
  pmPlanNumber: string;
  breadcrumbs: breadcrumbsComponentI[];
  equipmentList: JM.JMOBJ.Equipment[] = [];
  equipmentListVersion: number;
  isSubmitting: boolean;
  generalSearchWords: string;

  constructor(private route: ActivatedRoute, location: Location, actionSidebarV2Service: ActionSidebarV2Service) {
    route.data.subscribe((data) => {
      if (data.mode) {
        this.pageMode = data.mode;
      }
    });
    this.location = location;
    this.actionSidebarV2Service = actionSidebarV2Service;
  }

  async ngOnInit() {
    const route = AppDelegate.getRoute();
    const trimRoute = route.replace(/^(\/pm\/plans)(\/.*)(\/.*)$/g, '$1$3');

    if (!AppDelegate.checkPagePermissions(trimRoute)) {
      return;
    }

    this.pmPlanNumber = this.route.snapshot.paramMap.get('planNumber');
    this.requestLocationSummary();
    this.setBreadcrumbs();
    this.initTablex();
    this.initFilterOptions();
    await this.requestTableData();
    this.renderTable();
    this.initActionBtns();
    this.searchLocationObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      ).subscribe(() => {
        this.searchLocation();
      }
    );
  }

  setBreadcrumbs = () => {
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-plan-list',
        name: JMLanguage.translate('breadcrumbs.pm-plan'),
        route: `/pm/plans`,
      },
      {
        id: 'breadcrumbs-pm-plan-number',
        name: this.pmPlanNumber,
        route: `/pm/plans/${this.pmPlanNumber}`,
      },
      {
        id: 'breadcrumbs-sn-number',
        name: JMLanguage.translate('breadcrumbs.equipment'),
        route: ``,
        currentPage: true,
      },
    ];
  };

  initActionBtns = () => {
    this.actionSidebar.dataList = [];

    const actionList = ['close'];

    actionList.forEach((e) => this.addActionButton(PmPlanEquipmentListButtonList[e]));
  };

  private addActionButton(actionButton: ActionButtonI) {
    let handler = () => {};

    switch (actionButton.action) {
      case 'close':
        handler = () => {
          if (this.pmPlanNumber) {
            AppDelegate.routeToPage(`/pm/plans/${this.pmPlanNumber}`);
          }
        };
        break;
      default:
        break;
    }

    actionButton.handler = handler;
    this.actionSidebar.dataList.push(actionButton);
  }

  onClickedActionButton(actionButton: ActionButtonI) {
    actionButton.handler();
  }

  initTablex() {
    this.tablexParam = {
      pageSizeOptions: [10, 25, 100],
      currentPageSize: 10,
      currentPage: 1,
      pageCount: 0,
      enableSetPageSize: true,
      enablePagination: true,
      enableSort: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      isLoadingTable: false,
      // tableRow: 'd-flex',
      // tableClass: 'user-table',
      // tableWrapperClass: 'table-min-width',
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowClicked: this.onRowClicked,
      // onRowUnselected: this.onRowUnselected,
      content: [],
      highlightedRows: [],
    };
    this.initTableHeader();
  }

  initTableHeader() {
    this.tablexParam.headers = [
      {
        id: GetPmPlanAssignedEquipmentListParams.equipmentNumber,
        name: 'component.pm-plan-equipment-list.table-column.equipment-id',
        enableSort: false,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: GetPmPlanAssignedEquipmentListParams.description,
        name: 'component.pm-plan-equipment-list.table-column.description',
        enableSort: false,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: GetPmPlanAssignedEquipmentListParams.location,
        name: 'component.pm-plan-equipment-list.table-column.location',
        enableSort: false,
        enableFilter: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.locationOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: GetPmPlanAssignedEquipmentListParams.clientShortName,
        name: 'component.pm-plan-equipment-list.table-column.client',
        enableSort: false,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: GetPmPlanAssignedEquipmentListParams.equipmentType,
        name: 'component.pm-plan-equipment-list.table-column.equipment-type',
        enableSort: false,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
    ];
  }

  renderTable() {
    this.tablexParam.content = this.equipmentList.map((eq) => {
      let equipmentNumber = eq[GetPmPlanAssignedEquipmentListParams.equipmentNumber];
      let description = eq[GetPmPlanAssignedEquipmentListParams.description];
      let location = eq[GetPmPlanAssignedEquipmentListParams.location] ? `(${eq[GetPmPlanAssignedEquipmentListParams.location]})` : [];
      let locationDescription = eq[GetPmPlanAssignedEquipmentListParams.locationDescription];
      let clientShortName = eq[GetPmPlanAssignedEquipmentListParams.clientShortName];
      let equipmentType = eq[GetPmPlanAssignedEquipmentListParams.equipmentType];
      return [
        equipmentNumber,
        description,
        `${(locationDescription? locationDescription[JMLanguage.getCurrentLanguage()] : '')} ${location}`,
        clientShortName,
        equipmentType,
      ];
    });
  }

  async requestTableData(pageNumber?: number, pageSize?: number): Promise<boolean> {
    this.selectRow(null);
    let request: JM.JMRequestGetPmPlanAssignedEquipmentList | JM.JMRequestGetPmPlanUnassignedEquipmentList;
    switch (this.activeTab) {
      case this.tabOptions.assigned:
        request = new JM.JMRequestGetPmPlanAssignedEquipmentList();
        break;
      case this.tabOptions.unassigned:
        request = new JM.JMRequestGetPmPlanUnassignedEquipmentList();
        break;
    }
    this.tablexParam.isLoadingTable = true;
    request.pmPlanNumber = this.pmPlanNumber;
    request.sortBy = GetPmPlanAssignedEquipmentListParams.equipmentNumber;
    request.sortOrder = JMENUM.SortOrder.ASC;
    request.pageNumber = pageNumber ? pageNumber : 1;
    request.pageSize = pageSize ? pageSize : this.tablexParam.currentPageSize;
    request.parameters = Object.values(GetPmPlanAssignedEquipmentListParams);
    request = this.renderRequestFilter(request);

    this.equipmentList = [];
    const response: JM.JMResponseGetPmPlanAssignedEquipmentList | JM.JMResponseGetPmPlanUnassignedEquipmentList =
    await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return false;
    }
    this.equipmentList = response.payload.records;
    this.equipmentListVersion = response.payload.planVersion;
    this.tablexParam.totalCount = response.payload.totalCount;
    this.tablexParam.pageCount = Math.ceil(response.payload.totalCount / request.pageSize);
    this.tablexParam.currentPage = request.pageNumber;
    this.tablexParam.currentPageSize = request.pageSize;
    return true;
  }

  private async requestLocationSummary(){
    if (this.locationTotalCount !== null && 
      this.locationTotalCount <= this.locationPageNumber * this.locationPageSize) {
      return;
    }
    this.locationOptions.isLoading = true;
    this.locationPageNumber++;

    let request: JM.JMRequestLocationsLocationSummary = new JM.JMRequestLocationsLocationSummary();
    request.includeSummary = true;
    request.pageSize = this.locationPageSize;
    request.pageNumber = this.locationPageNumber;
    request.locationOnly = true;
    if (this.searchLocationKeywords) {
      request.locationDescription = this.searchLocationKeywords;
    }

    let response = await AppDelegate.sendJMRequest(request);
    this.locationOptions.isLoading = false;
    if (response.error) {
      AppDelegate.toastMsg().showResponseMsg(response.error);
      return;
    }
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    let options = response.payload.records.map(location => {
      let description = location.description[Session.selectedLanguage] ? location.description[Session.selectedLanguage] : location.description["en"];
      let name = description + ' (' + location.code + ')';
      let obj = { 
        value: location.code, 
        label: name,
        descriptionEn: location.description["en"] ? location.description["en"] : "",
        descriptionZh: location.description["zh"] ? location.description["zh"] : ""
      };
      return obj;
    });
    this.locationTotalCount = response.payload.totalCount;
    this.locationOptions.items = this.locationOptions.items.concat(options);
  }
  
  selectRow(index: number) {
    this.tablexParam.selectedRowIndex = null;
    if (index == null) {
      this.tablexParam.highlightedRows = [];
    } else {
      let prevState = this.tablexParam.highlightedRows[index];
      this.tablexParam.highlightedRows[index] = !prevState;
    }
  }

  async submitEquipment(equipmentlist: string[]) {
    this.isSubmitting = true;
    let submitResult: boolean;
    switch (this.activeTab) {
      case this.tabOptions.assigned:
        submitResult = await this.removeEquipmentFromPmPlan(equipmentlist);
        break;
      case this.tabOptions.unassigned:
        submitResult = await this.addEquipmentToPmPlan(equipmentlist);
        break;
    }
    this.isSubmitting = false;

    submitResult && (await this.requestTableData(1)) && this.renderTable();
  }

  private async addEquipmentToPmPlan(equipmentlist: string[]): Promise<boolean> {
    if (!this.pmPlanNumber) return false;

    let request = new JM.JMRequestAddEquipmentToPmPlan();
    request.pmPlanNumber = this.pmPlanNumber;
    request.equipmentNumbers = equipmentlist;
    request.version = this.equipmentListVersion;

    const response: JMResponseAddEquipmentToPmPlan = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return false;
    }
    return true;
  }

  private async removeEquipmentFromPmPlan(equipmentlist: string[]): Promise<boolean> {
    if (!this.pmPlanNumber) return false;

    let request = new JM.JMRequestRemoveEquipmentFromPmPlan();
    request.pmPlanNumber = this.pmPlanNumber;
    request.equipmentNumbers = equipmentlist;
    request.version = this.equipmentListVersion;

    const response: JMResponseRemoveEquipmentFromPmPlan = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return false;
    }
    return true;
  }

  
  renderRequestFilter(request) {
    request.filter = {};
    for (let key in this.tablexFilter) {
      if (this.tablexFilter[key]) {
        request.filter[key] = this.tablexFilter[key];
      }
    }

    if (!!this.generalSearchWords) {
      const wordList = this.generalSearchWords.split(' ').filter(word => !!word);
      if (wordList.length >= 1) {
        request.equipmentNumberList = wordList;
      }
    }
    return request;
  }

  searchLocation() {
    this.locationOptions.items = [];
    this.locationPageNumber = 0;
    this.locationTotalCount = null;
    this.requestLocationSummary();
  }

  onFilterChanged = async (event, index, header, filter) => {
    for (let key in filter) {
      if(!filter[key]){
        delete this.tablexFilter[key];
      }else{
        this.tablexFilter[key] = filter[key];
      }
    }

    await this.requestTableData(1);
    this.renderTable();
  }

  onOptionFilterChanged = async (event, i, header) => {
    if (header.filterDropdownOption.selectedValue) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }

    await this.requestTableData(1);
    this.renderTable();
  }

  onFilterClear = async () => {
    this.tablexFilter = {};
    this.tablexParam['filter'] = {};
    await this.requestTableData(1);
    this.renderTable();
  }

  onLocationKeywordsClear = () => {
    this.searchLocationKeywords = undefined;
    this.searchLocationObserver.next();
  }

  onSubmitClick = () => {
    let selectedEquipmentList = [];
    this.tablexParam.highlightedRows.forEach((el, index) => {
      if (el) {
        selectedEquipmentList.push(this.tablexParam.content[index][0]);
      }
    });
    if (selectedEquipmentList.length > 0) {
      this.submitEquipment(selectedEquipmentList);
    }
  };

  onRowClicked = (index, row) => {
    this.selectRow(index);
  };

  onPageSizeClicked = async (pageSize: number) => {
    await this.requestTableData(1, pageSize);
    this.renderTable();
  };

  onPageNumberClicked = async (pageNumber: number) => {
    await this.requestTableData(pageNumber);
    this.renderTable();
  };

  onClickSetActiveTab = async (tab) => {
    // this.setActiveTab(tab);
    this.activeTab = tab;
    this.generalSearchWords = null;
    await this.requestTableData(1);
    this.renderTable();
  };

  onSelectAll = () => {
    this.tablexParam.highlightedRows = this.tablexParam.content.map((e) => true);
  };

  onUnselectAll = () => {
    this.tablexParam.highlightedRows = [];
  };

  onGeneralSearchKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.onGeneralSearchClicked();
    }
  }

  async onGeneralSearchClicked() {
    await this.requestTableData(1);
    this.renderTable();
  }

  onSearchLocationOptions = (event) => {
    this.searchLocationKeywords = event.term;
    this.searchLocationObserver.next();
  }

  onLocationScrollToEnd = () =>{
    this.requestLocationSummary();
  }

  onFilterLocationOptions = (term: string, item: any) => {
    return item.value.toLowerCase().includes(term.toLowerCase()) ||
      item.descriptionEn.toLowerCase().includes(term.toLowerCase()) ||
      item.descriptionZh.toLowerCase().includes(term.toLowerCase());
  }

  initFilterOptions() {
    //Location
    this.locationOptions.items = [];
    this.locationOptions.bindLabel = "label";
    this.locationOptions.bindValue = "value";
    this.locationOptions.change = this.onOptionFilterChanged;
    this.locationOptions.onScrollToEnd = this.onLocationScrollToEnd;
    this.locationOptions.onClear = this.onLocationKeywordsClear;
    this.locationOptions.onSearch = this.onSearchLocationOptions;
    this.locationOptions.searchFn = this.onFilterLocationOptions;
  }

  get submitBtnName() {
    switch (this.activeTab) {
      case this.tabOptions.unassigned:
        return JMLanguage.translate('component.pm-plan-equipment-list.assign');
      case this.tabOptions.assigned:
        return JMLanguage.translate('component.pm-plan-equipment-list.unassign');
      default:
        return undefined;
    }
  }

  get isActionBarEnabled() {
    return this.actionSidebar.dataList && this.actionSidebar.dataList.length > 0;
  }

  get numberOfRowsSelected(): number {
    if (this.tablexParam && this.tablexParam.highlightedRows) {
      return this.tablexParam.highlightedRows.filter((e) => e).length;
    }
    return 0;
  }
}

enum GetPmPlanAssignedEquipmentListParams {
  _id = '_id',
  equipmentNumber = 'equipmentNumber',
  description = 'description',
  location = 'location',
  locationDescription = 'locationDescription',
  clientShortName = 'clientShortName',
  equipmentType = 'equipmentType',
}

const PmPlanEquipmentListButtonList: { [x: string]: ActionButtonI } = {
  close: {
    action: 'close',
    permissionList: [],
    actionDescription: 'action.button.close',
    buttonId: 'right-tool-bar_close_button',
    imageUrl: '/assets/svg/btn_action_reject.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: 'action.button.popup.confirm-close',
    popUpButtonList: [],
  },
};
