<div class="wrapper">
  <app-nav [breadcrumbs]="breadcrumbs"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="content pm-task-create-content action-btn-padding">
    <div class="component-wrap row">
      <div class="col-12 col-xl-6">
        <app-pm-job-description
          #pmJobDescriptionElem
          class="d-block mb-5"
          [job]="pmJob"
          [pageMode]="pageMode"
        ></app-pm-job-description>
        <app-pm-job-client-info
          #pmJobClientInfoElem
          class="d-block mb-5"
          [job]="pmJob"
          [pageMode]="pageMode" 
        ></app-pm-job-client-info>
      </div>

      <div class="col-12 col-xl-6">
        <app-pm-job-progress
          #pmJobProgressElem
          [job]="pmJob"
          [pageMode]="pageMode" 
          class="d-block mb-5" 
        ></app-pm-job-progress>
        <app-pm-job-equipment-info
          #pmJobEquipmentInfoElem 
          class="d-block mb-5"
          [pageMode]="pageMode" 
          [periodId]="pmJob?.period?._id" 
          [pmJobNumber]="pmJob?.pmJobNumber"
          [selectedEquipmentsNumber]="pmJob?.equipmentList?.length" 
        ></app-pm-job-equipment-info>
      </div>
    </div>

    <!-- Action Sidebar -->
    <ng-container *ngIf="actionSidebar.dataList && actionSidebar.dataList.length > 0">
      <app-action-sidebar-v2
        [actionButtonList]="actionSidebar.dataList"
        [isDisabled]="actionSidebar.isDisabled"
        [isLoading]="actionSidebar.isLoading"
        (clickedButton)="onClickedActionButton($event)">
      </app-action-sidebar-v2>
    </ng-container>
  </div>

</div>
