<nav id="action-sidebar">
  <ul class="list-unstyled components">
    <li>
      <ng-container *ngFor="let item of buttons;let i = index">
        <button class="btn mx-auto" id="{{item.buttonId}}" (click)="click(item)" [disabled]="disabled || !item.isEnable"
          [class]="{'d-none': !item.isShow, 'd-block': item.isShow }">
          <img *ngIf="item.imageUrl" src="{{item.imageUrl}}" alt="{{item.actionDescription | translate}}">
          <i *ngIf="item.iconClass" class="action-btn-icon {{item.iconClass}}"></i>
          <div>{{item.actionDescription | translate}}</div>
        </button>
      </ng-container>
    </li>
  </ul>
</nav>