import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Util } from 'src/app/core/util';
import { TimeInputI } from './time-input.model';

@Component({
  selector: 'app-time-input',
  templateUrl: './time-input.component.html',
  styleUrls: ['./time-input.component.scss'],
})
export class TimeInputComponent implements OnInit, OnChanges {
  @Input() customClass?: string;
  @Input() alertMsg?: string;
  @Input() field?: string; // field name
  @Input() input: string; // ngModel
  @Input() inputId: string; // id for HTML use
  @Input() isError: boolean = false;
  @Input() disabled? = undefined;
  @Input() isShowAlertMsg: boolean = false;

  @Output() updatedTime: EventEmitter<TimeInputI> = new EventEmitter();

  isErrorField: any = {};

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.isErrorField = this.isError;
  }

  onChangeTime(time?: string) {
    const regex = '^([01]?[0-9]|2[0-3])([0-5][0-9]|[0-9])$';
    let timeString = time.replace(':', '');

    if (!timeString || timeString.length <= 0) {
      this.input = undefined;
      this.updatedTime.emit({ fieldName: this.field, time: this.input });
      return;
    }

    if (timeString.length <= 2 && Number(timeString) <= 24) {
      timeString = Util.pad(timeString.substring(0, 2)) + '00';
    } else if (timeString.length <= 2 && Number(timeString) > 24) {
      this.isErrorField = true;
      this.showAlertToastMessage();
      return;
    }
    if (timeString.length == 3) {
      timeString = Util.pad(timeString.substring(0, 1)) + Util.pad(timeString.substring(1, 3));
    }
    if (timeString.length == 5) {
      timeString = timeString.substring(0, 4);
    }

    if (timeString == '2400' || timeString.match(regex)) {
      this.isErrorField = false;
      this.input = timeString.substring(0, 2) + ':' + timeString.substring(2, 4);
      this.updatedTime.emit({ fieldName: this.field, time: this.input });
    } else {
      this.isErrorField = true;
      this.showAlertToastMessage();
    }
  }

  private showAlertToastMessage() {
    if (this.isShowAlertMsg) {
      const msg = this.alertMsg ? this.alertMsg : JMLanguage.translate('global.invalid-time');
      AppDelegate.toastMsg().showMsg(msg);
    }
  }
}
