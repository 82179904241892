import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JM, JMENUM, JMOBJ, JMREASON } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { UserHelper } from 'src/app/core/user-helper';
import { ActionButtonI } from '../../shared/action-sidebar-v2/action-sidebar-v2.model';
import { CustomSliderPanelComponent } from '../../shared/custom-slider-panel/custom-slider-panel.component';
import { CmTaskViewActionButtonList } from './cm-task-view.model';
import { CmAdditionalInfoComponent } from '../shared/cm-additional-info/cm-additional-info.component';
import { CmClientInfoComponent } from '../shared/cm-client-info/cm-client-info.component';
import { CmProgressDetailsComponent } from '../shared/cm-progress-details/cm-progress-details.component';
import { CmTaskDescriptionComponent } from '../shared/cm-task-description/cm-task-description.component';
import { CmTaskProgressComponent } from '../shared/cm-task-progress/cm-task-progress.component';


@Component({
  selector: 'app-cm-task-view',
  templateUrl: './cm-task-view.component.html',
  styleUrls: ['./cm-task-view.component.scss'],
})
export class CmTaskViewComponent implements OnInit {
  @ViewChild('cm_task_complete_editor_panel', { static: false }) cmTaskCompleteEditorPanel: CustomSliderPanelComponent;
  @ViewChild('cm_task_refer_editor_panel', { static: false }) cmTaskReferEditorPanel: CustomSliderPanelComponent;

  @ViewChild(CmTaskDescriptionComponent, { static: false }) taskDescriptionElem: CmTaskDescriptionComponent;
  @ViewChild(CmClientInfoComponent, { static: false }) clientInfoElem: CmClientInfoComponent;
  @ViewChild(CmTaskProgressComponent, { static: false }) taskProgressElem: CmTaskProgressComponent;
  @ViewChild(CmProgressDetailsComponent, { static: false }) progressDetailsElem: CmProgressDetailsComponent;
  @ViewChild(CmAdditionalInfoComponent, { static: false }) additionalInfoElem: CmAdditionalInfoComponent;

  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.VIEW;
  JMPage = JMENUM.JMPage;

  breadcrumbs: any = [];
  jobCard: JMOBJ.JobCard = new JMOBJ.JobCard();
  jobCardNumber: string;
  sn: JMOBJ.ServiceNotification = new JMOBJ.ServiceNotification();
  contract?: JMOBJ.MaintenanceTermContract;
  contractSeverity?: JMOBJ.ContractSeverity;

  // action buttons
  actionSidebar: {
    dataList: ActionButtonI[];
    isDisabled: boolean;
    isLoading: boolean;
  } = {
    dataList: [],
    isDisabled: false,
    isLoading: false,
  };

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }

    this.jobCardNumber = this.route.snapshot.paramMap.get('jobCardNumber');
    if (this.jobCardNumber) {
      this.setBreadcrumbs();
      this.requestCMTask();
    }
  }

  //------------------------
  // API
  private async requestCMTask(enablePermissionErrorToast = true) {
    let request = new JM.JMRequestJobCardsGetCmTask();
    request.jobCardNumber = this.jobCardNumber;

    this.actionSidebar.isLoading = true;
    const response: JM.JMResponseJobCardsGetCmTask = await AppDelegate.sendJMRequest(request);
    this.actionSidebar.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.jobCard) {
      if (response.code == 401 && !enablePermissionErrorToast) return response;
      AppDelegate.toastMsg().showResponseMsg(response);
      return response;
    }

    this.jobCard = response.payload.jobCard;

    if (this.jobCard.contract) {
      let contract = new JMOBJ.MaintenanceTermContract();
      contract.contractNumber = this.jobCard.contract.contractNumber;
      contract.title = this.jobCard.contract.title;
      contract.liftTrapped = this.jobCard.contract.liftTrapped;
      this.contract = contract;
    }
    if (this.jobCard.contractSeverity) {
      let contractSeverity = new JMOBJ.ContractSeverity();
      contractSeverity._id = this.jobCard.contractSeverity.id;
      contractSeverity.description = this.jobCard.contractSeverity.description;
      contractSeverity.name = this.jobCard.contractSeverity.name;
      contractSeverity.liftTrapped = this.jobCard.contractSeverity.liftTrapped;
      contractSeverity.responseToClient = this.jobCard.contractSeverity.responseToClient;
      this.contractSeverity = contractSeverity;
    }

    this.resetActionBar(response.payload.actionList);
    this.requestVpSn();
  }

  private async requestVpSn() {
    let request = new JM.JMRequestSnGetVpSn();
    request.snNumber = this.jobCard.snNumber;

    const response: JM.JMResponseSnGetVpSn = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.sn) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.sn = response.payload.sn;
    this.setChildComponentValues();
  }

  private setChildComponentValues() {
    this.taskDescriptionElem.setValuesInEditMode(this.jobCard, this.contract, this.contractSeverity);
    this.clientInfoElem.setValuesInEditMode(this.jobCard);
    this.taskProgressElem.setValuesInEditMode(this.jobCard);
    this.progressDetailsElem.setValuesInEditMode(this.jobCard);
  }

  //------------------------
  // UI function
  private setBreadcrumbs() {
    const jobCardNumberArr = this.jobCardNumber.split('-');
    const snNumber = jobCardNumberArr[0];
    const taskNumber = JMLanguage.translate('pages.cm-task-view.breadcrumbs.task-number', [jobCardNumberArr[1]]);
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-sn-number',
        name: snNumber,
        route: `/cm-task/${snNumber}`,
      },
      {
        id: 'breadcrumbs-task-number',
        name: taskNumber,
        route: null,
        currentPage: true,
      },
    ];
  }

  //------------------------
  // action button function
  onClickedActionButton(actionButton: ActionButtonI) {
    actionButton.handler();
  }

  private resetActionBar(actionList: JMENUM.VpCmTaskAction[]): void {
    this.actionSidebar.dataList = [];

    if (actionList.length) {
      this.filterActionButton(actionList);
    }
  }

  private filterActionButton(actionList: string[]) {
    const sortedButtonList = [
      JMENUM.VpCmTaskAction.COMPLETE,
      JMENUM.VpCmTaskAction.UPDATE,
      JMENUM.VpCmTaskAction.REFER,
      JMENUM.VpCmTaskAction.ADD_ATTACHMENT,
      'exportPdf',
    ];

    for (const sortedButton of sortedButtonList) {
      const showActionButton = actionList.includes(sortedButton);

      if (showActionButton) {
        const button: ActionButtonI = CmTaskViewActionButtonList[sortedButton];
        const hasPermissionList = button && button.permissionList && button.permissionList.length;

        if (hasPermissionList) {
          const allowAction = UserHelper.hasEitherOnePermission(button.permissionList);
          if (allowAction) {
            this.addActionButton(button);
          }
        } else {
          this.addActionButton(button);
        }
      }
    }
  }

  private addActionButton(actionButton: ActionButtonI) {
    let handler = () => {};

    switch (actionButton.action) {
      case JMENUM.VpCmTaskAction.COMPLETE:
        handler = () => {
          if (!this.taskProgressElem.validateFieldsFormat()) {
            AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.invalid-format'));
            return;
          }
      
          if (!this.isInputtedMandatoryFields()) {
            AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.missing-mandatory-fields'));
            return;
          }
          
          const isValid = this.isValidFields();
          if (isValid) {
            if(this.taskProgressElem.isCompliacneStatusFail()) {
              let popupDetail = {
                msg: { content: 'action.button.popup.confirm-complete.compliance-fail' },
                buttons: [
                  {
                    name: 'global.yes',
                    handler: () => { this.cmTaskCompleteEditorPanel.toggle() },
                  },
                  { name: 'global.no', handler: null },
                ],
              };
              AppDelegate.popUpDialog().open(popupDetail);
            } else {
              this.cmTaskCompleteEditorPanel.toggle();
            }
          }
        };
        break;
      case JMENUM.VpCmTaskAction.UPDATE:
        handler = () => {
          const updateCmTaskLink = `/cm-task/${this.jobCard.snNumber}/${this.jobCardNumber}/edit`;
          AppDelegate.routeToPage(updateCmTaskLink);
        };
        break;
      case JMENUM.VpCmTaskAction.REFER:
        handler = () => {
          this.cmTaskReferEditorPanel.toggle();
        };
        break;
      case JMENUM.VpCmTaskAction.ADD_ATTACHMENT:
        handler = () => {
          const attachmentPageUrl = `/cm-task/${this.jobCard.snNumber}/${this.jobCardNumber}/attachment`;
          AppDelegate.routeToPage(attachmentPageUrl);
        };
        break;
      case 'exportPdf':
        handler = () => {
          // TODO:
        };
        break;

      default:
        break;
    }

    actionButton.handler = handler;
    this.actionSidebar.dataList.push(actionButton);
  }

  private isInputtedMandatoryFields(): boolean {
    const isValidTaskDescriptionElem = this.taskDescriptionElem.validateMandatoryFields();
    const isValidClientInfoElem = this.clientInfoElem.validateMandatoryFields();
    const isValidTaskProgressElem = this.taskProgressElem.validateMandatoryFields();
    const isValidProgressDetailsElem = this.progressDetailsElem.validateMandatoryFields();

    const isValid =
      isValidTaskDescriptionElem &&
      isValidClientInfoElem &&
      isValidTaskProgressElem &&
      isValidProgressDetailsElem;

    return isValid;
  }

  private isValidFields(): boolean {
    const isValidTaskDescriptionElem = this.taskDescriptionElem.validateData();
    const isValidClientInfoElem = this.clientInfoElem.validateData();
    const isValidTaskProgressElem = this.taskProgressElem.validateData();
    const isValidProgressDetailsElem = this.progressDetailsElem.validateData();

    const isValid =
      isValidTaskDescriptionElem &&
      isValidClientInfoElem &&
      isValidTaskProgressElem &&
      isValidProgressDetailsElem;

    return isValid;
  }

  //------------------------
  // button callback
  onCompletedJobCard(jobCard: JMOBJ.JobCard) {
    this.requestCMTask();
    this.cmTaskCompleteEditorPanel.close();
  }

  onReferJobCard(jobCard: JMOBJ.JobCard) {
    this.cmTaskReferEditorPanel.close();
    const enablePermissionErrorToast = false;
    this.requestCMTask(enablePermissionErrorToast).then(res => {
      if (res?.code === 401) {
        // #21174 Special handling if jobcard has refered to Team which current User has no access
        AppDelegate.routeToPage('');
      }
    });
  }

  //------------------------
  // get property
  get hasUpdatePermission(): boolean {
    return UserHelper.hasPermission(JMENUM.VpPermission.CMTASK_UPDATE);
  }
}
