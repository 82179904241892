<div class="wrapper">
  <app-nav [navbarTitle]="'pages.release-note.title' | translate"></app-nav>

  <app-sidebar></app-sidebar>

  <div id="content" class="action-btn-padding">
    <div class="row m-0 border-bottom">
      <ng-container *ngFor="let item of tabBar | keyvalue">
        <div class="col-3 py-2 border border-bottom-0 rounded-top text-center"
          [ngClass]="{'bg-light' : item.value.selected, 'cursor-pointer' : !item.value.selected}"
          (click)="switchTab(item.value.platformType)">
          {{ item.value.label }}
        </div>
      </ng-container>
    </div>

    <div class="row mx-0">
      <div class="col-md-3 mb-md-0 col-12 mb-4">

        <div>
          <span>{{ 'pages.release-note.version-list.title' | translate }}</span>
          <button class="btn col-auto font-size-xl outline-none"
            type="button"
            (click)="onClickAddReleaseNote($event)"
          >
            <i class="fa fa-plus blue"></i>
          </button>
        </div>

        <div class="release-note-list">
          <div *ngFor="let item of releaseNoteList; let i = index"
            class="release-note-item"
            [class.selected]="item.version == selectedNoteVersion"
            (click)="onClickReleaseNote($event, item)">
            {{ item.version }}
          </div>
        </div>
      </div>
        
      <div *ngIf="selectedNoteVersion"
        class="d-flex flex-column col-md-9 col-12">
        <span class="mt-3">{{ 'pages.release-note.version' | translate }}</span>

        <span *ngIf="selectedNoteVersion !== 'new'">{{ selectedNoteVersion }}</span>
        <input *ngIf="selectedNoteVersion === 'new'"
          class="form-control mt-1"
          [(ngModel)]="releaseNoteFormInput.version"
          [class.is-invalid]="releaseNoteFormInputError.version"
        >

        <span class="mt-3">{{ 'pages.release-note.message.en' | translate }}</span>
        <textarea class="form-control mt-1"
          [(ngModel)]="releaseNoteFormInput.enMessage"
          [class.is-invalid]="releaseNoteFormInputError.enMessage"
        ></textarea>
        
        <span class="mt-3">{{ 'pages.release-note.message.zh' | translate }}</span>
        <textarea class="form-control mt-1"
          [(ngModel)]="releaseNoteFormInput.zhMessage"
          [class.is-invalid]="releaseNoteFormInputError.zhMessage"
        ></textarea>
        
        <ng-container *ngIf="platform != 'web'" >
          <span class="mt-3">{{ 'pages.release-note.force-update' | translate }}</span>
          <input class="form-control mt-1"
            [(ngModel)]="releaseNoteFormInput.minVersion"
            [class.is-invalid]="releaseNoteFormInputError.minVersion"
          >

          <span class="mt-3">{{ 'pages.release-note.soft-update' | translate }}</span>
          <input class="form-control mt-1"
            [(ngModel)]="releaseNoteFormInput.latestVersion"
            [class.is-invalid]="releaseNoteFormInputError.latestVersion"
          >
        </ng-container>

        <!-- <button class="mt-3" (click)="onClickSubmit()">Submit</button> -->
      </div>
    </div>

    <!-- Action Sidebar -->
    <ng-container *ngIf="actionSidebar.dataList && actionSidebar.dataList.length > 0">
      <app-action-sidebar-v2
        [actionButtonList]="actionSidebar.dataList"
        [isDisabled]="actionSidebar.isDisabled"
        [isLoading]="actionSidebar.isLoading"
        (clickedButton)="onClickedActionButton($event)">
      </app-action-sidebar-v2>
    </ng-container>
  </div>
</div>
