import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { JM, JMENUM, JMOBJ} from '@ccep/CCEPConnector-ts';
import { formatDate, ngbDateToString, stringToNgbDate } from 'src/app/core/Formatter';

import * as moment from 'moment';

@Component({
  selector: 'app-pm-job-progress',
  templateUrl: './pm-job-progress.component.html',
  styleUrls: ['./pm-job-progress.component.scss']
})
export class PmJobProgressComponent implements OnInit {
    @Input() set job( job ) {
        if (job) {
          this.setValueFromPmJob(job);
          this.arrivalGpsParam = job.arrival != undefined && {
            startTime : job.startTime,
            completionTime : job.completionTime,
            gpsLocations: job.arrival
            }
        }
        this._job = job;
    };
    @Input() pageMode: JMENUM.JMPageMode;

    JMPageMode = JMENUM.JMPageMode;

    errorFields: any = {};
    timeAlertMsg: string = undefined;
    dateAlertMsg: string = undefined;
    mandatory: any = {};
    editable: any = {};
    _job : any = {};

    dateInputSet : any = {};
    timeInputSet : any = {};

    inputFieldSettings : {
        [key: string] : {
            editable?: boolean
            mandatory?: boolean
            failed?: boolean
        }
    };
    
    completeTimeInterval = 15;
    arrivalGpsParam: any = undefined;

    constructor() {}

    ngOnInit() {
        this.timeAlertMsg = JMLanguage.translate("global.invalid-time");
        this.dateAlertMsg = JMLanguage.translate("global.invalid-date");
        this.initDateTimeSet();
        this.fieldsControl();
    }

    initDateTimeSet() {
        this.dateInputSet = {
            appointmentTime: undefined,
            startTime: undefined,
            completionTime: undefined,
            malfunctionStartTime: undefined,
            malfunctionEndTime: undefined,
        };
        this.timeInputSet = {
            appointmentTime: undefined,
            startTime: undefined,
            completionTime: undefined,
            malfunctionStartTime: undefined,
            malfunctionEndTime: undefined,
        };
    }

    private fieldsControl() {
        this.inputFieldSettings = {
            startTime : {
                editable: true,
                mandatory: true,
            },
            completionTime: {
                editable: true,
                mandatory: true,
            },
        }
        // this.setValueFromPmJob();

    }

    public clearAll(){
        for (let field in this.inputFieldSettings) {
            this._job[field] = undefined;
            this.inputFieldSettings[field].failed = false;
            this.dateInputSet[field] = undefined;
            this.timeInputSet[field] = undefined;
        }
    }

    private setValueFromPmJob(job) {
        let startTime: any = job.startTime ? job.startTime : undefined;
        if (startTime) {
            let time = moment(startTime);
            this.dateInputSet['startTime'] = stringToNgbDate(startTime);
            this.timeInputSet['startTime'] = time.format('HH:mm');
        }

        let completionTime: any = job.completionTime ? job.completionTime : undefined;
        if (completionTime) {
            let time = moment(completionTime);
            this.dateInputSet['completionTime'] = stringToNgbDate(completionTime);
            this.timeInputSet['completionTime'] = time.format('HH:mm');
        }
    }

    public onUpdatedDateInput(event) {
        this.dateInputSet[event.fieldName] = event.date;
        this.setDateTime(event.fieldName);
    }

    public onUpdatedTimeInput(event) {
        this.timeInputSet[event.fieldName] = event.time;
        this.setDateTime(event.fieldName);
    }

    public setDateTime(field) {
        this.resetErrorField(field);
        if (this.dateInputSet[field] && this.timeInputSet[field]) {
            let date = this.dateInputSet[field];
            let timeArr = this.timeInputSet[field].split(':');
            let formattedDate = new Date(date.year, date.month - 1, date.day);
            if (timeArr && timeArr.length > 0) {
                formattedDate.setHours(timeArr[0]);
                formattedDate.setMinutes(timeArr[1]);
                this._job[field] = new Date(formattedDate);
            }
        }

        if (!this.timeInputSet[field] || !this.dateInputSet[field]) {
            this._job[field] = undefined;
        }

        if (this._job['startTime'] && this._job.period) {
          if(!this.validateStartTime()) return;
        }

        if (this._job['completionTime'] && this._job.period) {
          if(!this.validateCompletionTime()) return;
        }

        if (this._job['startTime'] && this._job['completionTime']) {
          if(!this.validateJobDuration()) return;
        }
    }

    public validateJobDuration() {
      // reset
      this.inputFieldSettings.completionTime.failed = false;
      this.inputFieldSettings.startTime.failed = false;

      if (this._job['startTime'] && this._job['completionTime']) {
        let completionTime = new Date(this._job.completionTime);
        let startTime = new Date(this._job.startTime);
        let differTime = completionTime.valueOf() - startTime.valueOf();

        // check if complete time > start time
        if (differTime <= 0) {
          this.inputFieldSettings.completionTime.failed = true;
          this.inputFieldSettings.startTime.failed = true;
          AppDelegate.toastMsg().showMsg(JMLanguage.translate("component.pm-job-progress.error.arrival-time-after-completion-time"));
          return false;
        }
        if (differTime < this.completeTimeInterval * 60 * 1000) {
          this.inputFieldSettings.completionTime.failed = true;
          this.inputFieldSettings.startTime.failed = true;
          const textArray = [
            JMLanguage.translate('component.cm-task-progress.arrival-start-time'),
            JMLanguage.translate('component.cm-task-progress.completion-time'),
            this.completeTimeInterval,
            JMLanguage.translate('enum.vp-time-reference-unit.mins'),
          ];
          AppDelegate.toastMsg().showMsg(
            JMLanguage.translate(
              "component.cm-task-progress.toast.date-time.time-interval",
              textArray
            )
          );
          return false;
        }
        return true;
      } else {
        return false;
      }
    }

    public validateStartTime() {
      // checking - must not be a future time
      if (moment(this._job.startTime).isAfter(moment.now())) {
        this.inputFieldSettings.startTime.failed = true;
        AppDelegate.toastMsg().showMsg(
          JMLanguage.translate('component.cm-task-progress.toast.date-time.cannot-future-time')
        );
        return false;
      }

      // show error if startTime < periodStartDate
      if (this._job.startTime && this._job.period.periodStartDate) {
        let differTime = moment(this._job.startTime).diff(moment(this._job.period.periodStartDate), 'days', true);
        if (differTime < 0) {
          this.inputFieldSettings.startTime.failed = true;
          AppDelegate.toastMsg().showMsg(JMLanguage.translate("component.pm-job-progress.error.pm-job-exceed-pm-period"));
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    public validateCompletionTime() {
      // checking - must not be a future time
      if (moment(this._job.completionTime).isAfter(moment.now())) {
        this.inputFieldSettings.completionTime.failed = true;
        AppDelegate.toastMsg().showMsg(
          JMLanguage.translate('component.cm-task-progress.toast.date-time.cannot-future-time')
        );
        return false;
      }

      // show error if completionTime > periodEndDate
      if (this._job.completionTime && this._job.period.periodEndDate) {
        let differTime = moment(this._job.completionTime).diff(moment(this._job.period.periodEndDate), 'days', false);
        if (differTime > 0) {
          this.inputFieldSettings.completionTime.failed = true;
          AppDelegate.toastMsg().showMsg(JMLanguage.translate("component.pm-job-progress.error.pm-job-exceed-pm-period"));
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    public validate() {
      let validationStatus = true;

      for (let field in this.inputFieldSettings) {
        let target = this.inputFieldSettings[field];
        if (target.editable && target.mandatory) {
          let isFieldFailed = this._job[field] ? false : true;
          target.failed = isFieldFailed;
          if (isFieldFailed) {
            validationStatus = false;
          }
        }
      }

      if (!validationStatus) {
        AppDelegate.toastMsg().showMsg(JMLanguage.translate("toast.missing-mandatory-fields"));
        return validationStatus;
      }

      if (this._job['startTime'] && this._job.period && !this.validateStartTime()) {
        validationStatus = false;
      }

      if (this._job['completionTime'] && this._job.period && !this.validateCompletionTime()) {
        validationStatus = false;
      }

      if (!this.validateJobDuration()) {
        return validationStatus = false;
      }

      return validationStatus;
    }

    public resetErrorField(key) {
        if (this.inputFieldSettings[key] != undefined) {
            this.inputFieldSettings[key].failed = false;
        }
    }
}