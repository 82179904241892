import { JMENUM } from '@ccep/CCEPConnector-ts';

export const RouteDetails = {
  '/settings': {},
  '/settings/general': {
    permissions: [
      JMENUM.VpPermission.COMPANY_VIEW,
      JMENUM.VpPermission.COMPANY_UPDATE,
      JMENUM.VpPermission.PROFILE_VIEW,
      JMENUM.VpPermission.PROFILE_UPDATE,
    ],
  },
  '/settings/general/vendor-info': {
    permissions: [JMENUM.VpPermission.COMPANY_VIEW, JMENUM.VpPermission.COMPANY_UPDATE],
  },
  '/settings/general/user-profile': {
    permissions: [JMENUM.VpPermission.PROFILE_VIEW, JMENUM.VpPermission.PROFILE_UPDATE],
  },
  '/settings/contract': {
    permissions: [JMENUM.VpPermission.CONTRACT_VIEW],
  },
  '/settings/contract/contract': {
    permissions: [JMENUM.VpPermission.CONTRACT_VIEW],
  },
  '/settings/contract/contract-severity-list': {
    permissions: [JMENUM.VpPermission.SEVERITY_VIEW],
  },
  '/settings/contract/contract-severity-list/contract-severity': {
    permissions: [JMENUM.VpPermission.SEVERITY_VIEW],
  },
  '/settings/contractor-management': {
    permissions: [JMENUM.VpPermission.CONTRACTOR_VIEW],
  },
  '/settings/user': {
    permissions: [JMENUM.VpPermission.USER_VIEW],
  },
  '/settings/emsd-user': {
    permissions: [JMENUM.VpPermission.EMSDUSER_VIEW],
  },
  '/settings/team': {
    permissions: [JMENUM.VpPermission.TEAM_VIEW],
  },
  '/settings/role': {
    permissions: [JMENUM.VpPermission.ROLE_VIEW],
  },
  '/cm-task': {
    permissions: [JMENUM.VpPermission.CMTASK_VIEW],
  },
  '/cm-task/create': {
    permissions: [JMENUM.VpPermission.CMTASK_CREATE],
  },
  '/cm-task/edit': {
    permissions: [JMENUM.VpPermission.CMTASK_UPDATE],
  },
  '/cm-task/attachment': {
    permissions: [JMENUM.VpPermission.CMTASK_VIEW],
  },
  '/pm/plans': {
    permissions: [JMENUM.VpPermission.PMPLAN_VIEW],
  },
  '/pm/plans/create': {
    permissions: [JMENUM.VpPermission.PMPLAN_CREATE],
  },
  '/pm/plans/edit': {
    permissions: [JMENUM.VpPermission.PMPLAN_UPDATE, JMENUM.VpPermission.PMREMINDER_UPDATE],
  },
  '/pm/plans/equipment-list': {
    permissions: [JMENUM.VpPermission.PMPLAN_UPDATE],
  },
  '/pm/plans/equipment-list-view': {
    permissions: [JMENUM.VpPermission.PMPLAN_VIEW],
  },
  '/pm/periods': {
    permissions: [JMENUM.VpPermission.PMPLAN_VIEW],
  },
  '/pm/periods/equipment-list': {
    permissions: [JMENUM.VpPermission.PMPLAN_VIEW],
  },
  '/pm/jobs': {
    permissions: [JMENUM.VpPermission.PMJOB_VIEW],
  },
  '/pm/jobs/create': {
    permissions: [JMENUM.VpPermission.PMJOB_CREATE],
  },
  '/pm/jobs/edit': {
    permissions: [JMENUM.VpPermission.PMJOB_UPDATE],
  },
  '/pm/jobs/attachment': {
    permissions: [JMENUM.VpPermission.PMJOB_VIEW],
  },
  '/pm/jobs/equipment-list': {
    permissions: [JMENUM.VpPermission.PMJOB_UPDATE],
  },
  '/pm/jobs/equipment-list-view': {
    permissions: [JMENUM.VpPermission.PMJOB_VIEW],
  },
  '/pending-notification': {
    permissions: [JMENUM.VpPermission.SN_VIEW],
  },
};
