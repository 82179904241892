<form [formGroup]="severityForm">
  <div class="container-fluid mb-3">
    <div class="d-flex align-items-center justify-content-between my-3">
      <h3>
        {{ formTitleText }}
      </h3>
      <app-loading-button  
        [id]="'save_contract_severity_button'"
        [text]="'global.save'"
        [buttonClass]="'w-2 standard brand-amber'"
        [iconClass]="'fa-check'"
        [isDisabled]="severityForm.invalid || submitBtn.isLoading"
        [isLoading]="submitBtn.isLoading"
        (clickedButton)="onSubmitBtnClicked()"
      ></app-loading-button>
    </div>

    <div class="row align-items-center my-3">
      <!-- severity -->
      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'component.contract-severity-editor.severity' | translate}}
      </div>
      <div class="col-12">
        <input type="input" class="form-control" id="name_input" formControlName="name" [maxlength]="inputBoxMaxLength"
          [class.input-error]="severityName.invalid && (severityName.dirty || severityName.touched)">
      </div>

      <!-- description -->
      <div class="col-12 mt-3">
        {{ 'component.contract-severity-editor.description' | translate}}
      </div>
      <div class="col-12">
        <textarea id="description_input" class="form-control" rows="5" formControlName="description" [maxlength]="inputBoxMaxLength">
        </textarea>
      </div>
      
      <!-- lift trapped -->
      <div class="col-12 mt-3">
        {{ 'component.contract-severity-editor.lift-trapped' | translate}}
      </div>
      <div class="col-12">
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="lift_trapped_yes" formControlName="liftTrapped"
            [value]="liftTrappedOption.yes" >
          <label class="custom-control-label" for="lift_trapped_yes">{{ 'global.yes' | translate }}</label>
        </div>
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="lift_trapped_no" formControlName="liftTrapped"
            [value]="liftTrappedOption.no" >
          <label class="custom-control-label" for="lift_trapped_no">{{ 'global.no' | translate }}</label>
        </div>
      </div>

      <!-- response to client time -->
      <div class="col-12 mt-3">
        {{ 'component.contract-severity-editor.response-to-client-time' | translate}}
      </div>
      <div class="col-6">
        <input type="input" class="form-control" id="response_to_client_input" formControlName="responseToClient" [maxlength]="inputBoxMaxLength"
          [class.input-error]="severityResponseToClient.invalid && (severityResponseToClient.dirty || severityResponseToClient.touched)">
      </div>
      <div class="col-6">
        <ng-select
          id="response_to_client_unit_dropdown"
          formControlName="responseToClientUnit"
          bindLabel="label"
          bindValue="value"
          [items]="timeReferenceUnitList"
          [clearable]="false"
        ></ng-select>
      </div>

      <!-- response time -->
      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'component.contract-severity-editor.response-time' | translate}}
      </div>
      <div class="col-6">
        <input type="input" class="form-control" id="response_time_input" formControlName="responseTime" [maxlength]="inputBoxMaxLength"
          [class.input-error]="severityResponseTime.invalid && (severityResponseTime.dirty || severityResponseTime.touched)">
      </div>
      <div class="col-6">
        <ng-select
          id="response_time_dropdown"
          formControlName="responseTimeUnit"
          bindLabel="label"
          bindValue="value"
          [items]="timeReferenceUnitList"
          [clearable]="false"
        ></ng-select>
      </div>

      <!-- rectification time -->
      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'component.contract-severity-editor.rectification-time' | translate}}
      </div>
      <div class="col-6">
        <input type="input" class="form-control" id="rectification_time_input" formControlName="rectificationTime" [maxlength]="inputBoxMaxLength"
          [class.input-error]="severityRectificationTime.invalid && (severityRectificationTime.dirty || severityRectificationTime.touched)">
      </div>
      <div class="col-6">
        <ng-select
          id="rectification_time_dropdown"
          formControlName="rectificationTimeUnit"
          bindLabel="label"
          bindValue="value"
          [items]="timeReferenceUnitList"
          [clearable]="false"
        ></ng-select>
      </div>

      <!-- time reference type -->
      <div class="col-12 mt-3">
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="time_reference_type_by_arrival" formControlName="timeReferenceType"
            [value]="timeReferenceTypeEnum.BY_ARRIVAL" >
          <label class="custom-control-label" for="time_reference_type_by_arrival">{{ 'enum.vp-time-reference-type.by_arrival' | translate }}</label>
        </div>
        <div class="custom-control custom-radio">
          <input type="radio" class="custom-control-input" id="time_reference_type_by_appointment" formControlName="timeReferenceType"
            [value]="timeReferenceTypeEnum.BY_APPOINTMENT" >
          <label class="custom-control-label" for="time_reference_type_by_appointment">{{ 'enum.vp-time-reference-type.by_appointment' | translate }}</label>
        </div>
      </div>

      <!-- SLA priority -->
      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'component.contract-severity-editor.sla-priority' | translate}}
      </div>
      <div class="col-6">
        <ng-select
          id="sla_priority_dropdown"
          formControlName="priority"
          bindLabel="label"
          bindValue="value"
          [items]="slaPriorityList"
          [clearable]="false"
        ></ng-select>
      </div>
    </div>

    <div class="row align-items-center my-3">
      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'global.mandatory-fields' | translate}}
      </div>
    </div>
  </div>
</form>
