<ng-container *ngIf="textField">
    <span>{{textField}}</span>
</ng-container>
<ng-container *ngIf="textFieldArray && textFieldArray.length > 0">
    <ng-container *ngFor="let item of textFieldArray; last as isLast">
        <span class="seperate-item">
            {{ item + (isLast ? '' : ', ') }}
        </span>
    </ng-container>
</ng-container> 
<ng-container *ngIf="translateKey">
    <span>{{ translateKey | translate }}</span>
</ng-container>
<ng-container *ngIf="!textField && !translateKey && (!textFieldArray || textFieldArray.length < 1)">
    <span class="empty-display font-size-s">
        <ng-container *ngIf="emptyHandleDisplay">
            {{emptyHandleDisplay | translate}}
        </ng-container>
        <ng-container *ngIf="!emptyHandleDisplay">
            {{'component.display-text-field.not-defined' | translate}}
        </ng-container>
    </span>
</ng-container>