import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { formatDate } from '@angular/common';
import { saveAs } from 'file-saver';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from 'src/app/shared/tablex/tablexColumnType';
import { Constants } from 'src/constants';
import { RoutingCoverageI } from '../contract-routing-coverage-list-table/contract-routing-coverage-list-table.component';

@Component({
  selector: 'contract-emsd-contact-list',
  templateUrl: './contract-emsd-contact-list.component.html',
  styleUrls: ['./contract-emsd-contact-list.component.scss']
})
export class ContractEmsdContactListComponent implements OnInit, OnChanges {

  @Input() contract?: JMOBJ.MaintenanceTermContract;
  @Input() routingCoverage?: RoutingCoverageI;

  // data
  memberPostList: string[] = [];
  roPostList: string[] = [];

  // cache
  postObjByPostMap = new Map<string, JMOBJ.Post>(); // key: post, value: Post Object

  //Status
  isDownloading: boolean = false;

  // TableX
  memberTablexParam: { [key: string]: any } = {};
  roTablexParam: { [key: string]: any } = {};

  constructor() { }

  ngOnInit(): void {
    this.initTablex();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.routingCoverage && !changes.routingCoverage.firstChange) {
      this.getEmsdContact();
    }
  }

  //=================================================
  // API
  private getEmsdContact() {

    this.memberPostList = [];
    this.roPostList = [];
    this.setLoadingTable(true);

    this.requestContractCoverageContact().then(_ => {
      return this.getUniquePostList();
    }).then(postList => {
      return this.requestPostSummary(postList);
    }).finally(() => {
      this.renderTable();
      this.setLoadingTable(false);
    });
  }

  private async requestContractCoverageContact() {
    const request = new JM.JMRequestRoutingRulesGetContractCoverageContact();
    request.contractNumber = this.contract.contractNumber;
    request.coverage = {
      client: this.routingCoverage.client,
      district: this.routingCoverage.district,
      locations: this.routingCoverage.locations,
      equipmentCategory: this.routingCoverage.equipmentCategory,
      equipmentTypes: this.routingCoverage.equipmentTypes,
    }

    const response: JM.JMResponseRoutingRulesGetContractCoverageContact = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.memberPostList = response.payload.memberList ? response.payload.memberList.sort() : [];
    this.roPostList = response.payload.roList ? response.payload.roList.sort() : [];

    return;
  }

  private async requestPostSummary(postList: string[]) {
    if (!postList.length) { return; }

    const request = new JM.JMRequestPostsPostSummary();
    request.name = postList;
    request.systemName = 'CCEPJM';
    request.pageSize = 1000;
    request.pageNumber = 1;
    request.parameters = ['name', 'roles', 'employeeName', 'email', 'phone'];

    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    for (const post of response.payload.records) {
      this.postObjByPostMap.set(post.name, post);
    }
  }

  private async getUniquePostList() {
    const postList = this.memberPostList.concat(this.roPostList).filter(post => !this.postObjByPostMap.has(post));

    if (postList.length > 0) {
      return Array.from(new Set(postList));
    }

    return [];
  }

  private async requestContractContactListExcel() {
    const request = new JM.JMRequestRoutingRulesExportContractContactList();
    request.contractNumber = this.contract.contractNumber;

    this.isDownloading = true;
    const response: JM.JMResponseRoutingRulesExportContractContactList = await AppDelegate.sendJMRequest(request);
    this.isDownloading = false;

    if (!response) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }
    const dateTime = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    const fileName = `Contract_Contact_List_${dateTime}.xlsx`;
    saveAs(response, fileName);
  }

  async onClickDownload() {
    this.isDownloading = true;
    await this.requestContractContactListExcel();
  }

  //=================================================
  // tablex
  private initTablex() {
    this.memberTablexParam = {
      pageSizeOptions: [10, 25, 100],
      currentPageSize: 1000,
      currentPage: 1,
      pageCount: 0,
      enableSetPageSize: false,
      enablePagination: false,
      enableSort: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      isLoadingTable: false,
      // tableRow: 'd-flex',
      tableClass: 'member-table',
      tableWrapperClass: 'table-min-width',
      // onPageNumberClicked: this.onPageNumberClicked,
      // onPageSizeClicked: this.onPageSizeClicked,
      // onRowSelected: this.onRowSelected,
      // onRowUnselected: this.onRowUnselected,
      // onFilterChanged: this.onFilterChanged,
      // onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      content: [],
    };
    this.roTablexParam = {
      pageSizeOptions: [10, 25, 100],
      currentPageSize: 1000,
      currentPage: 1,
      pageCount: 0,
      enableSetPageSize: false,
      enablePagination: false,
      enableSort: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      isLoadingTable: false,
      // tableRow: 'd-flex',
      tableClass: 'ro-table',
      tableWrapperClass: 'table-min-width',
      // onPageNumberClicked: this.onPageNumberClicked,
      // onPageSizeClicked: this.onPageSizeClicked,
      // onRowSelected: this.onRowSelected,
      // onRowUnselected: this.onRowUnselected,
      // onFilterChanged: this.onFilterChanged,
      // onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      content: [],
    };

    this.memberTablexParam.headers = this.getTableHeader();
    this.roTablexParam.headers = this.getTableHeader();
  }

  private getTableHeader() {
    return [
      {
        id: 'employeeName',
        name: 'component.contract-emsd-contact-list.table-column.employee-name',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Top,
        class: 'w-20',
      },
      {
        id: 'name',
        name: 'component.contract-emsd-contact-list.table-column.name',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Top,
        class: 'w-20',
      },
      {
        id: 'roles',
        name: 'component.contract-emsd-contact-list.table-column.roles',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.MultiLine,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Top,
        class: 'w-20',
      },
      {
        id: 'phone',
        name: 'component.contract-emsd-contact-list.table-column.phone',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Top,
        class: 'w-20',
      },
      {
        id: 'email',
        name: 'component.contract-emsd-contact-list.table-column.email',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Top,
        class: 'w-20',
      },
    ];
  }

  private renderTable() {
    const getRowContent = (post: JMOBJ.Post) => {
      return [
        post.employeeName ? post.employeeName : '',
        post.name ? post.name : '',
        post.roles ? post.roles.map((role: any) => role.name) : [],
        post.phone ? post.phone : '',
        post.email ? post.email : ''
      ];
    }

    const getTableContent = (postList: string[]) => {
      let content = [];

      for (const post of postList) {
        const postObj = this.postObjByPostMap.get(post);
        if (postObj) {
          content.push(getRowContent(postObj));
        }
      }
      return content;
    }

    this.memberTablexParam.content = getTableContent(this.memberPostList);
    this.roTablexParam.content = getTableContent(this.roPostList);
  }

  private setLoadingTable(loading: boolean) {
    this.roTablexParam.isLoadingTable = loading;
    this.memberTablexParam.isLoadingTable = loading;
  }

}
