import { Component, Input, OnInit } from '@angular/core';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { UserHelper } from 'src/app/core/user-helper';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Constants } from 'src/constants';

@Component({
  selector: 'app-form-copy-team-member',
  templateUrl: './form-copy-team-member.component.html',
  styleUrls: ['./form-copy-team-member.component.scss'],
})
export class FormCopyTeamMemberComponent implements OnInit {
  @Input() parameters: {
    isLoading: boolean;
    team: JMOBJ.VendorTeam;
    onFinishCopyMember: any;
  } = {
    isLoading: false,
    team: undefined,
    onFinishCopyMember: () => {},
  };

  // Team Dropdown
  private searchTeamObserver = new Subject<any[]>();
  searchTeamKeywords: string = null;
  teamPageSize: number = 100;
  teamPageNumber: number = 0;
  teamTotalCount: number;
  isLoadingTeam: boolean = false;

  copyTeamOptions: any;
  copyTeamFrom: string;

  constructor() {}

  ngOnInit(): void {
    this.searchTeamObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.searchTeams();
    });
  }

  // ----------- API ----------- //
  public async requestTeamList() {
    if (this.teamTotalCount !== null && this.teamTotalCount <= this.teamPageNumber * this.teamPageSize) {
      return;
    }
    this.isLoadingTeam = true;
    this.teamPageNumber++;

    let request: JM.JMRequestContractVendorTeamSummary = new JM.JMRequestContractVendorTeamSummary();

    if (this.parameters && this.parameters.team && this.parameters.team.vendorNumber) {
      request.vendorNumber = [this.parameters.team.vendorNumber];
    }

    request.parameters = ['_id', 'name'];
    request.pageNumber = this.teamPageNumber;
    request.pageSize = this.teamPageSize;
    if (this.searchTeamKeywords) {
      request.filter = { name: this.searchTeamKeywords };
    }

    let response: JM.JMResponseContractVendorTeamSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingTeam = false;
    if (response && response.code == 200) {
      let options = response.payload.records
        .filter((record) => record._id != this.parameters.team._id)
        .map((team) => {
          let obj = { value: team._id, label: team.name };
          return obj;
        });
      this.teamTotalCount = response.payload.totalCount;
      this.copyTeamOptions = this.copyTeamOptions.concat(options);
    } else {
      AppDelegate.toastMsg().showResponseMsg(response);
    }
  }

  private async requestCopyVendorTeamMember() {
    if (!this.copyTeamFrom) {
      return;
    }
    let request: JM.JMRequestPostsCopyVendorTeamMember = new JM.JMRequestPostsCopyVendorTeamMember();
    request.fromVendorTeamId = this.copyTeamFrom;
    request.toVendorTeamId = this.parameters.team._id;

    let response: JM.JMResponsePostsCopyVendorTeamMember = await AppDelegate.sendJMRequest(request);
    if (response && response.code == 200) {
      this.parameters.onFinishCopyMember();
    } else {
      AppDelegate.toastMsg().showResponseMsg(response);
    }
  }

  // ----------- UI function ----------- //
  public initForm() {
    this.copyTeamFrom = undefined;
    this.copyTeamOptions = [];
    this.teamPageNumber = 0;
    this.teamTotalCount = null;
  }

  public onTeamScrollToEnd() {
    this.requestTeamList();
  }

  public onSearchTeam(event) {
    this.searchTeamKeywords = event.term;
    this.searchTeamObserver.next();
  }

  public searchTeams() {
    this.copyTeamOptions = [];
    this.teamPageNumber = 0;
    this.teamTotalCount = null;
    this.requestTeamList();
  }

  public onCopyMember() {
    let button = {
      msg: {
        content: 'popup.confirm-to-copy',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestCopyVendorTeamMember();
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(button);
  }
}
