<div class="font-size-xl m-0">
  {{ "component.pm-plan-remarks.title" | translate}}
</div>

<hr class="underline" class="mt-1 mb-3" />
<div *ngIf="!editable?.remarks" class="">
  {{ plan?.remarks }}
</div>
<textarea *ngIf="editable?.remarks" id="edit-pm-plan-remarks"class="form-control" rows="3" [maxlength]="remarksMaxLength"
  [(ngModel)]="plan.remarks" (change)="onChangeRemarks()" [class.input-error]="errorFields.remarks"
></textarea>

<div *ngIf="rejectRemarks" class="reject-remarks">
  {{ "component.pm-plan-remarks.reject-remarks" | translate}}: {{ rejectRemarks }}
</div>
<div *ngIf="referRemarks" class="refer-remarks">
  {{ "component.pm-plan-remarks.refer-remarks" | translate}}: {{ referRemarks }}
</div>