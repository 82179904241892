import { Component, Input, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Constants } from 'src/constants';
import { NgSelectWithPaginationHelper } from '../../../shared/form.model';

@Component({
  selector: 'app-user-emsd-edit',
  templateUrl: './user-emsd-edit.component.html',
  styleUrls: ['./user-emsd-edit.component.scss'],
})
export class UserEmsdEditComponent implements OnInit {
  @Input() user: JMOBJ.Post;
  @Input() componentMode: JMENUM.JMPageMode;
  @Output() submitSuccess = new EventEmitter();

  //fields
  post: string;
  name: string;
  roles: number[];
  code: string;
  isActive: boolean;

  fieldsError: { [key: string]: boolean } = {};
  fieldsMandatory: { [key: string]: boolean } = {};
  fieldsEditable: { [key: string]: boolean } = {};

  isSubmitting: boolean;

  userListDropdownSearch = new NgSelectWithPaginationHelper(
    async (keywordSearch: string, pageToFetch: number, pageSize: number) => {
      let request = new JM.JMRequestPostsGetVpInhouseUserOptions();
      request.pageNumber = pageToFetch;
      request.pageSize = pageSize;
      request.parameters = ['code', 'name', 'employeeName'];
      if (keywordSearch) {
        request.filter = {
          name: keywordSearch,
        };
      }
      const response: JM.JMResponsePostsGetVpInhouseUserOptions = await AppDelegate.sendJMRequest(request);
      if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
        AppDelegate.toastMsg().showResponseMsg(response);
        return;
      }
      return {
        data: response.payload.records,
        totalCount: response.payload.totalCount,
      };
    }
  );

  roleListDropdownSearch = new NgSelectWithPaginationHelper(
    async (keywordSearch: string, pageToFetch: number, pageSize: number) => {
      let request = new JM.JMRequestAuthRoleSummary();
      request.systemName = [Constants.SYSTEM_NAME];
      request.targetType = [JMENUM.RoleTargetType.EMSD];
      request.pageSize = pageSize;
      request.pageNumber = pageToFetch;
      request.parameters = ['roleId', 'name'];

      const response: JM.JMResponseAuthRoleSummary = await AppDelegate.sendJMRequest(request);

      if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
        AppDelegate.toastMsg().showResponseMsg(response);
        return;
      }

      return {
        data: response.payload.records,
        totalCount: response.payload.totalCount,
      };
    }
  );

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user) {
      this.isComponentModeEdit && this.assignUserInfo(changes.user.currentValue);
    }
    if (changes.componentMode) {
      this.initFlow();
      this.isComponentModeCreate && this.assignUserInfo(null);
      this.isComponentModeEdit && this.assignUserInfo(this.user);
    }
    this.fieldsError = {};
  }

  ngOnInit(): void {}

  initFlow = () => {
    this.fieldsMandatory = {};
    this.fieldsEditable = {};
    switch (this.componentMode) {
      case JMENUM.JMPageMode.CREATE:
        this.fieldsMandatory = {
          post: true,
          roles: true,
        };
        this.fieldsEditable = {
          post: true,
          roles: true,
          isActive: true,
        };
        return;
      case JMENUM.JMPageMode.EDIT:
        this.fieldsMandatory = {
          roles: true,
        };
        this.fieldsEditable = {
          roles: true,
          isActive: true,
        };
        return;
    }
    this.roleListDropdownSearch.fetchDropdownList();
    this.userListDropdownSearch.fetchDropdownList();
  };

  postCreateVpInHouseUser = async () => {
    let request = new JM.JMRequestPostsCreateVpInhouseUser();
    request.isActive = this.isActive;
    request.name = this.post;
    request.roles = this.roles;

    const response: JM.JMResponsePostsCreateVpInhouseUser = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }
    this.submitSuccess.emit();
  };

  postEditUser = async () => {
    let request = new JM.JMRequestPostsUpdate();
    request.systemName = Constants.SYSTEM_NAME;
    request.name = this.post;
    request.roles = this.roles;
    request.isActive = this.isActive;

    this.isSubmitting = true;
    const response: JM.JMResponsePostsUpdate = await AppDelegate.sendJMRequest(request);
    this.isSubmitting = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }
    this.submitSuccess.emit();
  };

  assignUserInfo = (user: JMOBJ.Post) => {
    if (user) {
      this.post = user.name;
      this.name = user.employeeName;
      this.code = user.code;
      this.roles = this.parseRolesForNgSelect(user.roles);
      this.isActive = user.isActive;
    } else {
      this.post = undefined;
      this.name = undefined;
      this.code = undefined;
      this.roles = [];
      this.isActive = true;
    }
  };

  parseRolesForNgSelect = (roles: JMOBJ.Role[]): number[] => {
    let rolesIdArray = [];
    let rolesToPushToOriginalList = [];
    if (roles) {
      roles.forEach((role) => {
        let notInList = this.roleListDropdownSearch.list.every((el) => el.roleId !== role.roleId);
        if (notInList) {
          rolesToPushToOriginalList.push(role);
        }
        rolesIdArray.push(role.roleId);
      });
    }
    this.roleListDropdownSearch.list = this.roleListDropdownSearch.list.concat(rolesToPushToOriginalList);
    return rolesIdArray;
  };

  rolesNgModelChange = (event: string) => {
    let user = this.userListDropdownSearch.list.find((e) => e.name === event);
    this.code = user && user.code;
    this.name = user && user.employeeName;
  };

  onClickedSubmit = () => {
    if (!this.validateFields()) return;
    AppDelegate.popUpDialog().open({
      msg: { content: 'popup.confirm-to-save' },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            switch (this.componentMode) {
              case JMENUM.JMPageMode.CREATE:
                this.postCreateVpInHouseUser();
                return;
              case JMENUM.JMPageMode.EDIT:
                this.postEditUser();
                return;
            }
          },
        },
        { name: 'global.no', handler: null },
      ],
    });
  };

  validateFields = (): boolean => {
    this.fieldsError = {};
    let pass = true;
    for (let key in this.fieldsMandatory) {
      if (!this[key] || this[key].length === 0) {
        this.fieldsError[key] = true;
        pass = false;
      }
    }
    return pass;
  };

  get isComponentModeCreate(): boolean {
    return this.componentMode === JMENUM.JMPageMode.CREATE;
  }

  get isComponentModeEdit(): boolean {
    return this.componentMode === JMENUM.JMPageMode.EDIT;
  }
}
