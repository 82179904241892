import { JMENUM } from '@ccep/CCEPConnector-ts';
import { ActionButtonI } from 'src/app/shared/action-sidebar-v2/action-sidebar-v2.model';

export const SnViewActionButtonList: { [x: string]: ActionButtonI } = {
  [JMENUM.VpSnAction.ACKNOWLEDGE]: {
    action: JMENUM.VpSnAction.ACKNOWLEDGE,
    permissionList: [JMENUM.VpPermission.SN_ACKNOWLEDGE],
    actionDescription: 'action.button.acknowledge',
    buttonId: 'right-tool-bar_acknowledge_button',
    imageUrl: '/assets/svg/btn_action_acknowledge.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: 'action.button.popup.acknowledge',
    popUpButtonList: [],
  },
  [JMENUM.VpSnAction.CREATE_TASK]: {
    action: JMENUM.VpSnAction.CREATE_TASK,
    permissionList: [JMENUM.VpPermission.CMTASK_CREATE],
    actionDescription: 'action.button.create.cm-task',
    buttonId: 'right-tool-bar_create_cm_task_button',
    imageUrl: '/assets/svg/btn_action_create_cm_task.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: 'action.button.popup.confirm-create-cm-task',
    popUpButtonList: [],
  },
  [JMENUM.VpSnAction.CREATE_FOLLOW_UP]: {
    action: JMENUM.VpSnAction.CREATE_FOLLOW_UP,
    permissionList: [JMENUM.VpPermission.CMTASK_CREATE],
    actionDescription: 'action.button.create.follow-up',
    buttonId: 'right-tool-bar_create_follow_up_button',
    imageUrl: '/assets/svg/btn_action_job_create_follow_up.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  [JMENUM.VpSnAction.CANCEL]: {
    action: JMENUM.VpSnAction.CANCEL,
    permissionList: [JMENUM.VpPermission.SN_CANCEL],
    actionDescription: 'action.button.cancel',
    buttonId: 'right-tool-bar_cancel_button',
    imageUrl: '/assets/svg/btn_action_cancel.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  [JMENUM.VpSnAction.REJECT]: {
    action: JMENUM.VpSnAction.REJECT,
    permissionList: [JMENUM.VpPermission.SN_REJECT],
    actionDescription: 'action.button.reject',
    buttonId: 'right-tool-bar_reject_button',
    imageUrl: '/assets/svg/btn_action_reject.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  [JMENUM.VpSnAction.REFER]: {
    action: JMENUM.VpSnAction.REFER,
    permissionList: [JMENUM.VpPermission.SN_REFER],
    actionDescription: 'action.button.refer',
    buttonId: 'right-tool-bar_refer_button',
    imageUrl: '/assets/svg/btn_action_reassign.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
};
