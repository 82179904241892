'use strict';

import ENFile from 'src/assets/i18n/en.json';
import ZHFile from 'src/assets/i18n/zh.json';

enum Language {
  EN = 'en',
  ZH = 'zh',
}

class JMLanguageClass {
  private currLang = Language.EN;
  private langFile: { [key: string]: {} } = {};

  constructor() {
    this.langFile[Language.EN] = ENFile;
    this.langFile[Language.ZH] = ZHFile;
  }

  translate(key: string, args?: any, lang?: Language): string {
    let translated;
    if (lang) {
      translated = this.langFile[lang][key];
    } else {
      translated = this.langFile[this.currLang][key];
    }
    if (!translated) {
      // console.warn(`${lang} wording not found: ${key}`)
      translated = key;
    }
    if (args) {
      for (let i = 0; i < args.length; i++) {
        translated = translated.replace('${' + i + '}', args[i]);
      }
    }
    return translated;
  }

  getCurrentLanguage(): Language {
    return this.currLang;
  }

  changeLanguage(newLang: Language): void {
    this.currLang = newLang;
  }
}

let JMLanguage = new JMLanguageClass();

export { Language, JMLanguage };
