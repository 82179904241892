import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { inputNumber } from 'src/app/core/Formatter';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';
import { Util } from 'src/app/core/util';
import { Constants } from 'src/constants';
import { trimString, filterNumber } from '../../../core/Formatter';
import { DropdownControl, DropdownSearch, InputControl } from '../../../shared/form.model';

@Component({
  selector: 'app-cm-client-info',
  templateUrl: './cm-client-info.component.html',
  styleUrls: ['./cm-client-info.component.scss'],
})
export class CmClientInfoComponent implements OnInit, OnChanges {
  @Input() jobCard: JMOBJ.JobCard;
  @Input() sn: JMOBJ.ServiceNotification;
  @Input() pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.VIEW;
  @Input() equipmentClient?: {};

  inputNumber = inputNumber;

  clientInfo?: {
    label: string;
    value: string;
  };

  // Input
  clientInput = new CmTaskClientInput();
  contactNumberInput = new CmTaskContactNumberInput();
  contactPersonInput = new CmTaskContactPersonInput();
  emailInput = new CmTaskEmailInput();

  private clientSearch = new DropdownSearch();

  constructor() {}

  ngOnInit(): void {
    this.clientSearch.pageSize = 1000;

    if (this.isCreateMode || this.isEditMode) {
      this.setClientSearchObserver();

      this.resetInput();
      this.requestClientList(false);
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (this.isViewMode) {
      if (this.jobCard && this.jobCard.client) {
        await this.requestClientInfo(this.jobCard.client);
      }
      return;
    }
    
    if (this.isCreateMode || this.isEditMode) {
      if (changes.equipmentClient && !changes.equipmentClient.isFirstChange()) {
        this.clientInput.value = changes.equipmentClient.currentValue?.clientShortName;
      }
    }
  }

  //------------------------
  // parent component trigger function
  public setCreateStandaloneCmTaskData(request: JM.JMRequestSnCreateStandaloneCmTask) {
    request.client = this.clientInput.value ? this.clientInput.value : undefined;
    request.contactNumber = filterNumber(this.contactNumberInput.value) ?? undefined;
    request.contactPerson = !!this.contactPersonInput.value.trim() ? this.contactPersonInput.value.trim() : undefined;
    request.email = !!this.emailInput.value.trim() ? this.emailInput.value : undefined;
  }

  public setUpdateCmTaskData(request: JM.JMRequestJobCardsUpdateCmTask) {
    request.client = this.clientInput.value ? this.clientInput.value : null;
  }

  public async setValuesInEditMode(jobCard: JMOBJ.JobCard) {
    this.clientInput.isMandatory = true;
    this.contactNumberInput.isMandatory = false;
    this.contactPersonInput.isMandatory = false;
    this.emailInput.isMandatory = false;

    if (jobCard.client) {
      await this.requestClientInfo(jobCard.client);

      if (this.clientInfo) {
        this.clientInput.value = this.clientInfo.value;
        this.clientInput.optionList = [this.clientInfo];
        this.clientSearch.totalPageNumber = 0;
      }
    }
  }

  public resetFields() {
    this.resetInput();
  }

  public validateMandatoryFields(): boolean {
    this.setAllFieldsNoError();
    return !this.isMissingMandatoryField();
  }

  public validateData(): boolean {
    this.setAllFieldsNoError();

    // checking - mandatory fields
    if (this.isMissingMandatoryField()) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.missing-mandatory-fields'));
      return false;
    }

    // checking - email format
    if (this.isInvalidEmail()) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('component.cm-client-info.toast.incorrect-email-format'));
      return false;
    }

    return true;
  }

  //------------------------
  // checking function
  private isMissingMandatoryField(): boolean {
    let hasError = false;

    if (this.clientInput.isMandatory && !this.clientInput.value) {
      this.clientInput.isError = true;
      hasError = true;
    }
    if (this.contactNumberInput.isMandatory && !trimString(this.contactNumberInput.value)) {
      this.contactNumberInput.isError = true;
      hasError = true;
    }
    if (this.contactPersonInput.isMandatory && !trimString(this.contactPersonInput.value)) {
      this.contactPersonInput.isError = true;
      hasError = true;
    }
    if (this.emailInput.isMandatory && !trimString(this.emailInput.value)) {
      this.emailInput.isError = true;
      hasError = true;
    }

    return hasError;
  }

  private isInvalidEmail(): boolean {
    let hasError = false;
    this.emailInput.isError = false;

    if (this.emailInput.value && !Util.isValidEmail(this.emailInput.value)) {
      this.emailInput.isError = true;
      hasError = true;
    }
    return hasError;
  }

  //------------------------
  // reset function
  private resetInput() {
    this.resetClientInput();
    this.resetContractNumberInput();
    this.resetContractPersonInput();
    this.resetEmailInputInput();
  }

  private resetClientInput() {
    this.clientInput.isDisabled = false;
    this.clientInput.isError = false;
    this.clientInput.isLoading = false;
    this.clientInput.isMandatory = true;
    this.clientInput.value = undefined;
  }

  private resetContractNumberInput() {
    this.contactNumberInput.isDisabled = false;
    this.contactNumberInput.isError = false;
    this.contactNumberInput.isMandatory = true;
    this.contactNumberInput.value = '';
  }

  private resetContractPersonInput() {
    this.contactPersonInput.isDisabled = false;
    this.contactPersonInput.isError = false;
    this.contactPersonInput.isMandatory = true;
    this.contactPersonInput.value = '';
  }

  private resetEmailInputInput() {
    this.emailInput.isDisabled = false;
    this.emailInput.isError = false;
    this.emailInput.isMandatory = false;
    this.emailInput.value = '';
  }

  private setAllFieldsNoError() {
    this.clientInput.isError = false;
    this.contactNumberInput.isError = false;
    this.contactPersonInput.isError = false;
    this.emailInput.isError = false;
  }

  //------------------------
  // API
  private async requestClientList(isAppend: boolean = false) {
    const request = new JM.JMRequestClientsClientSummary();
    request.pageSize = this.clientSearch.pageSize;
    request.pageNumber = this.clientSearch.pageNumber;
    request.parameters = ['clientShortName', 'name'];

    if (!!this.clientSearch.keywords) {
      request.filter = {
        clientShortName: this.clientSearch.keywords,
      };
    }

    this.clientInput.isLoading = true;
    const response: JM.JMResponseClientsClientSummary = await AppDelegate.sendJMRequest(request);
    this.clientInput.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.clientSearch.totalPageNumber = Math.ceil(response.payload.totalCount / request.pageSize);
    const optionList = response.payload.records.map((client) => this.getClientInfoObj(client));

    if (isAppend) {
      this.clientInput.optionList = this.clientInput.optionList.concat(optionList);
    } else {
      this.clientInput.optionList = optionList;
    }
  }

  private async requestClientInfo(clientShortName: string) {
    let request = new JM.JMRequestClientsClientSummary();
    request.clientShortName = [clientShortName];

    const response: JM.JMResponseClientsClientSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (response.payload.records.length) {
      this.clientInfo = this.getClientInfoObj(response.payload.records[0]);
    }
  }

  private getClientInfoObj(client: JM.JMOBJ.Client): { value: string; label: string } {
    return {
      value: client.clientShortName,
      label: `${client.clientShortName} - ${client.name[Session.selectedLanguage]}`,
    };
  }

  //------------------------
  // Client dropdown event
  onClearClient() {
    this.resetClientOption();
  }

  onClientScrollToEnd() {
    if (this.clientSearch.pageNumber < this.clientSearch.totalPageNumber) {
      this.clientSearch.pageNumber++;

      this.requestClientList(true);
    }
  }

  onSearchClient(event) {
    this.clientSearch.keywords = event.term;
    this.clientSearch.search$.next();
  }

  onChangedClient() {}

  private clearClientOption() {
    this.clientInput.optionList = [];
    this.clientSearch.pageNumber = 1;
    this.clientSearch.totalPageNumber = 0;
  }

  private resetClientOption() {
    this.clientSearch.keywords = null;
    this.clientSearch.search$.next();
  }

  private setClientSearchObserver() {
    this.clientSearch.search$.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.clearClientOption();
      this.requestClientList(false);
    });
  }

  //------------------------
  // UI event
  onChangedEmail() {
    if (this.isInvalidEmail()) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('component.cm-client-info.toast.incorrect-email-format'));
      return;
    }
  }

  //------------------------
  // get property
  get isCreateMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.CREATE;
  }
  get isEditMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.EDIT;
  }
  get isViewMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.VIEW;
  }

  get textAreaMaxLength(): number {
    return Constants.TEXTAREA_MAX_LENGTH;
  }
}

class CmTaskClientInput extends DropdownControl {
  value?: string; // client short name
  optionList: {
    label: string;
    value: string;
  }[] = [];

  constructor() {
    super();
  }
}

class CmTaskContactNumberInput extends InputControl {
  value: string;

  constructor() {
    super();
  }
}

class CmTaskContactPersonInput extends InputControl {
  value: string;

  constructor() {
    super();
  }
}

class CmTaskEmailInput extends InputControl {
  value: string;

  constructor() {
    super();
  }
}
