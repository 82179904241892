<form [formGroup]="criteriaForm">
  <div class="container-fluid mb-3">
    <div class="d-flex align-items-center justify-content-between my-3">
      <h3>
        {{ formTitleText }} 
      </h3>
      <app-loading-button  
        [id]="'save_criteria_button'"
        [text]="'global.save'"
        [buttonClass]="'w-2 standard brand-amber'"
        [iconClass]="'fa-check'"
        [isDisabled]="criteriaForm.invalid || submitBtn.isLoading"
        [isLoading]="submitBtn.isLoading"
        (clickedButton)="onSubmitBtnClicked()"
      ></app-loading-button>
    </div>
    <div class="fs-3 mb-3 font-weight-bold">
      {{ contractSeverity?.name }}
    </div>

    <div class="row align-items-center my-3">
      <!-- client -->
      <div class="col-12 mt-3">
        {{ 'component.severity-criteria-editor.client' | translate}}
      </div>
      <div class="col-12">
        <ng-select
          id="client_dropdown"
          formControlName="client"
          bindLabel="label"
          bindValue="value"
          [items]="clientList"
          [clearable]="true"
          [multiple]="false"
        ></ng-select>
      </div>

      <!-- district -->
      <div class="col-12 mt-3">
        {{ 'component.severity-criteria-editor.district' | translate}}
      </div>
      <div class="col-12">
        <ng-select
          id="district_dropdown"
          formControlName="district"
          bindLabel="label"
          bindValue="value"
          [items]="districtList"
          [clearable]="true"
          [multiple]="false"
        ></ng-select>
      </div>

      <!-- location -->
      <div class="col-12 mt-3">
        {{ 'component.severity-criteria-editor.location' | translate}}
      </div>
      <div class="col-12">
        <ng-select
          id="location_dropdown"
          formControlName="location"
          bindLabel="code"
          bindValue="code"
          [items]="locationList"
          [clearable]="true"
          [multiple]="false"
          [loading]="isLoadingLocationList"
          (scrollToEnd)="onLocationScrollToEnd()"
          (search)="onSearchLocation($event)"
        ></ng-select>
      </div>

      <!-- equipment category -->
      <div class="col-12 mt-3">
        {{ 'component.severity-criteria-editor.equipment-category' | translate}}
      </div>
      <div class="col-12">
        <ng-select
          id="equipment_category_dropdown"
          formControlName="equipmentCategory"
          bindLabel="label"
          bindValue="value"
          [items]="equipmentCategoryList"
          [clearable]="true"
          [multiple]="false"
        ></ng-select>
      </div>

      <!-- equipment type -->
      <div class="col-12 mt-3">
        {{ 'component.severity-criteria-editor.equipment-type' | translate}}
      </div>
      <div class="col-12">
        <ng-select
          id="equipment_type_dropdown"
          formControlName="equipmentType"
          bindLabel="label"
          bindValue="value"
          [items]="equipmentTypeList"
          [clearable]="true"
          [multiple]="false"
        ></ng-select>
      </div>
    </div>

  </div>
</form>
