import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';

@Component({
  selector: 'app-cm-equipment-selector',
  templateUrl: './cm-equipment-selector.component.html',
  styleUrls: ['./cm-equipment-selector.component.scss'],
})
export class CmEquipmentSelectorComponent implements OnInit {
  @Input() jobCard?: JMOBJ.JobCard;
  @Input() sn?: JMOBJ.ServiceNotification;
  @Input() contract?: JMOBJ.MaintenanceTermContract;
  @Input() contractSeverity?: JMOBJ.ContractSeverity;
  @Input() pageMode?: JMENUM.JMPageMode;

  @Output() updatedEquipment = new EventEmitter<JM.JMOBJ.Equipment>();

  // data
  isSeverityCriteriaEquipment: boolean = true; // false = contract' equipments
  selectedEquipment?: JMOBJ.Equipment;


  constructor() {}

  ngOnInit(): void {

  }

  //------------------------
  // event function
  onClickedSelectButton() {
    if (!this.selectedEquipment) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('component.cm-equipment-selector.message.select-equipment'));
      return;
    }

    if (this.isEditMode) {
      const matchSnWorkCentre = this.sn && this.sn.workCentre === this.selectedEquipment.workCentre;
      const matchJobWorkCentre = this.jobCard && this.jobCard.workCentre === this.selectedEquipment.workCentre;
      if (!matchSnWorkCentre || !matchJobWorkCentre) {
        AppDelegate.toastMsg().showMsg(
          JMLanguage.translate('component.cm-equipment-selector.message.workcentre-not-match')
        );
      }
    }

    this.updatedEquipment.emit(this.selectedEquipment);
  }

  onEquipmentChanged(equipment?: JMOBJ.Equipment) {
    this.selectedEquipment = equipment;
  }

  onChangedSeverityCriteriaEquipmentCheckbox() {
    
  }

  //------------------------
  // get property
  get isEditMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.EDIT;
  }
}
