<div class="wrapper">
  <app-nav [navbarTitle]="'pages.cm-task-create.title' | translate"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="content cm-task-create-content action-btn-padding">
    <div class="component-wrap row">
      <div class="col-12 col-xl-6">
        <app-cm-task-description
          class="d-block mb-5"
          [jobCard]="jobCard"
          [sn]="sn"
          [pageMode]="pageMode"
          (updatedContract)="onUpdatedContract($event)"
          (updatedEquipment)="onUpdatedEquipment($event)"
          (updatedContractSeverity)="onUpdatedContractSeverity($event)">
        </app-cm-task-description>
        <app-cm-client-info
          class="d-block mb-5"
          [jobCard]="jobCard"
          [sn]="sn"
          [pageMode]="pageMode"
          [equipmentClient]="equipmentClient"
        ></app-cm-client-info>
        <!-- <app-cm-equipment-info class="d-block mb-5" [jobCard]="jobCard" [sn]="sn" [pageMode]="pageMode"
          [contract]="contract" [contractSeverity]="contractSeverity">
        </app-cm-equipment-info> -->
      </div>

      <div class="col-12 col-xl-6">
        <app-cm-task-progress class="d-block mb-4" [jobCard]="jobCard" [sn]="sn"
          [pageMode]="pageMode" [contract]="contract" [contractSeverity]="contractSeverity">
        </app-cm-task-progress>
        <app-cm-progress-details class="d-block mb-5" [jobCard]="jobCard" [sn]="sn" [pageMode]="pageMode">
        </app-cm-progress-details>
        <!-- Hidden additional information in Phrase 1 -->
        <app-cm-additional-info *ngIf="false" class="d-block mb-5" [jobCard]="jobCard" [sn]="sn" [pageMode]="pageMode"
          [contract]="contract">
        </app-cm-additional-info>
      </div>
    </div>


    <!-- Action Sidebar -->
    <ng-container *ngIf="actionSidebar.dataList && actionSidebar.dataList.length > 0">
      <app-action-sidebar-v2 [actionButtonList]="actionSidebar.dataList"
        [isDisabled]="actionSidebar.isDisabled" [isLoading]="actionSidebar.isLoading"
        (clickedButton)="onClickedActionButton($event)">
      </app-action-sidebar-v2>
    </ng-container>
  </div>

</div>