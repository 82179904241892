<nav id="action-sidebar">
  <ul class="list-unstyled components">
    <li>
      <ng-container *ngIf="isLoading; else actionSidebar">
        <div class="d-flex justify-content-center mt-5">
          <div class="spinner-border" role="status">
            <span class="sr-only">{{'global.loading' | translate}}</span>
          </div>
        </div>
      </ng-container>
      <ng-template #actionSidebar>
        <ng-container *ngFor="let item of actionButtonList; let i = index">
          <button class="btn mx-auto" id="{{item.buttonId}}" [class]="{'d-none': !item.isShow, 'd-block': item.isShow }"
            [disabled]="isDisabled || !item.isEnable" (click)="onClickedButton(item)">
            <img *ngIf="item.imageUrl" src="{{item.imageUrl}}" alt="{{item.actionDescription | translate}}">
            <i *ngIf="item.iconClass" class="action-btn-icon {{item.iconClass}}"></i>
            <div>{{item.actionDescription | translate}}</div>
          </button>
        </ng-container>
      </ng-template>
    </li>
  </ul>
</nav>