import { Pipe, PipeTransform } from '@angular/core';
import { formatDateWithWeek } from './Formatter';

@Pipe({
  name: 'dateWeek',
})
export class DateWeekPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let result = value ? formatDateWithWeek(value) : value;
    return result;
  }
}
