import { Injectable, Injector, OnInit } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { Router, ActivatedRoute } from '@angular/router';
import { PopUpDialogComponent } from '../shared/pop-up-dialog/pop-up-dialog.component';
import { ToastMsgComponent } from '../shared/toast-msg/toast-msg.component';
import { Session } from 'src/app/core/session';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { AuthorizationService } from '../services/authorization.service';
/****************************************************************************************
 !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! DEPRECATED !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 TODO: API migrate AppDelegate.sendJMRequest to handle token expired in Related Page
 ***************************************************************************************/
@Injectable()
export class BasePage implements OnInit {
  protected authorizationService: AuthorizationService;
  protected route: ActivatedRoute;
  protected router: Router;
  public toastMsg: ToastMsgComponent;
  public popUpDialog: PopUpDialogComponent;

  showSideBar = false;
  token: string = null;
  // constants = Constants; // for html use

  constructor(injector: Injector, requiredLogin = true) {
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.toastMsg = injector.get(ToastMsgComponent);
    this.popUpDialog = injector.get(PopUpDialogComponent);
    this.authorizationService = injector.get(AuthorizationService);

    this.decodeToken(); // decode possible token
    // check if the user logged in
    if (requiredLogin) {
      let user = Session.userInfo;
      let token = Session.userToken;
      if (token) {
        this.authorizationService.setUserInfo(user, token);  
        Session.setUserToken(token);
        Session.setUserInfo(user);
        if (window.location.pathname == '/') {
          AppDelegate.routeToPage('/home');
        }
      } else {
        AppDelegate.routeToPage('/login');
      }
    }
  }

  ngOnInit() {}

  //----------------------------------------------------------------
  inputNumber(event: any) {
    event.target.value = event.target.value.replace(/[^\d]/g, '');
  }

  getParameterByName(name: any) {
    let url = window.location.href;
    name = name.replace(/[[]]/g, '$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace('/+/g', ' '));
  }

  decodeToken() {
    this.token = this.getParameterByName('token');
    if (this.token != undefined && this.token != null) {
      let user = jwt_decode(this.token);
      Session.setUserToken(this.token);
      Session.setUserInfo(user);
      this.authorizationService.setUserInfo(user, this.token);
    }
  }
  
}
