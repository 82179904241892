import { Injectable } from '@angular/core';
import { Session } from 'src/app/core/session';
import { RouteHelper } from './route-util/route-helper';

@Injectable({
  providedIn: 'root',
})
class UserHelperClass {
  constructor() {}

  getUserName() {
    if (!Session.userInfo) {
      return null;
    }
    return Session.userInfo.name;
  }

  getRoles() {
    if (!Session.userInfo || !Session.userInfo.roles) {
      return [];
    }

    return Session.userInfo.roles;
  }

  getVendorNumber() {
    if (!Session.userInfo) {
      return null;
    }

    return Session.userInfo.vendorNumber;
  }

  getVendorTeams() {
    if (!Session.userInfo || !Session.userInfo.vendorTeams) {
      return [];
    }

    return Session.userInfo.vendorTeams;
  }

  getUserPermission(): string[] {
    if (!Session.userInfo || !Session.userInfo.permissions) {
      return [];
    }

    return Session.userInfo.permissions.sort((a, b) => a.localeCompare(b));
  }

  hasPermission(permission: string): boolean {
    if (!permission || !Session.userInfo || !Session.userInfo.permissions) {
      return false;
    }

    return Session.userInfo.permissions.includes(permission);
  }

  hasEitherOnePermission(permissions: string[]): boolean {
    if (!permissions || !Session.userInfo || !Session.userInfo.permissions) {
      return false;
    }
    for (let permission of permissions) {
      if (Session.userInfo.permissions.includes(permission)) {
        return true;
      }
    }

    return false;
  }

  hasPagePermission(route): boolean {
    if (!route) {
      return false;
    }

    let permissions = RouteHelper.getPermissions(route);
    return !permissions || UserHelper.hasEitherOnePermission(permissions);
  }
}

let UserHelper = new UserHelperClass();
export { UserHelper };
