import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { StringValidator } from 'src/app/core/string-validator';
import { Constants } from 'src/constants';

@Component({
  selector: 'app-contract-severity-editor',
  templateUrl: './contract-severity-editor.component.html',
  styleUrls: ['./contract-severity-editor.component.scss'],
})
export class ContractSeverityEditorComponent implements OnInit, OnChanges {
  @Input() contract?: JMOBJ.MaintenanceTermContract;
  @Input() contractSeverity?: JMOBJ.ContractSeverity;

  @Output() addedContractSeverity: EventEmitter<JMOBJ.ContractSeverity> = new EventEmitter();

  isEditMode: boolean = false;
  timeReferenceUnitList: {
    label: string;
    value: string;
  }[] = [];
  slaPriorityList: {
    label: string;
    value: JMENUM.JMPriority;
  }[] = [];

  // data
  severityForm?: FormGroup;

  // UI
  inputBoxMaxLength = Constants.TEXTAREA_MAX_LENGTH;
  liftTrappedOption = {
    // only accept string in value of radio input
    yes: 'true',
    no: 'false',
  };
  timeReferenceTypeEnum = JMENUM.VPTimeReferenceType;
  submitBtn = {
    isShow: true,
    isDisabled: true,
    isLoading: false,
  };

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initTimeReferenceUnitList();
    this.initSlaPriorityList();
    this.initForm();
    this.resetForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contract && !changes.contract.firstChange) {
      this.resetForm();
    }
  }

  // TODO: use reload mechanism. for update contract severity

  //------------------------
  // ui
  private initTimeReferenceUnitList() {
    const unitList = [
      JMENUM.VPTimeReferenceUnit.DAYS,
      JMENUM.VPTimeReferenceUnit.HOURS,
      JMENUM.VPTimeReferenceUnit.MINS,
    ];

    this.timeReferenceUnitList = unitList.map((unit) => {
      return {
        label: JMLanguage.translate(`enum.vp-time-reference-unit.${unit}`),
        value: unit,
      };
    });
  }

  private initSlaPriorityList() {
    const priorityList = [JMENUM.JMPriority.NonUrgent, JMENUM.JMPriority.Urgent, JMENUM.JMPriority.Emergency];

    this.slaPriorityList = priorityList.map((priority) => {
      return {
        label: JMLanguage.translate(`sla.priority.${priority}`),
        value: priority,
      };
    });
  }

  private initForm() {
    const timePattern = '^[1-9][0-9]*(\\.[1-9])?$|^0\\.[1-9]$';

    this.severityForm = this.fb.group({
      name: ['', [Validators.required, StringValidator.noWhiteSpaceBothEnd]],
      description: [''],
      liftTrapped: [''],
      responseToClient: ['', [Validators.pattern(timePattern), Validators.min(0.1)]],
      responseToClientUnit: [''],
      responseTime: ['', [Validators.required, Validators.pattern(timePattern), Validators.min(0.1)]],
      responseTimeUnit: [''],
      rectificationTime: ['', [Validators.required, Validators.pattern(timePattern), Validators.min(0.1)]],
      rectificationTimeUnit: [''],
      timeReferenceType: [''],
      priority: [''],
    });
  }

  private resetForm() {
    const enableLiftTrapped = this.hasContractLiftTrapped ? true : false;

    this.severityForm.reset({
      name: '',
      description: '',
      liftTrapped: {
        value: this.liftTrappedOption.no,
        disabled: !enableLiftTrapped,
      },
      responseToClient: '',
      responseToClientUnit: JMENUM.VPTimeReferenceUnit.DAYS,
      responseTime: '',
      responseTimeUnit: JMENUM.VPTimeReferenceUnit.DAYS,
      rectificationTime: '',
      rectificationTimeUnit: JMENUM.VPTimeReferenceUnit.DAYS,
      timeReferenceType: JMENUM.VPTimeReferenceType.BY_ARRIVAL,
      priority: JMENUM.JMPriority.NonUrgent,
    });
  }

  //------------------------
  // api
  private async requestAddContractSeverity() {
    let request = new JM.JMRequestContractsAddContractSeverity();
    request.contractNumber = this.contract.contractNumber;
    request.name = !!this.severityName.value ? this.severityName.value.trim() : '';
    request.description = !!this.severityDescription.value ? this.severityDescription.value.trim() : '';
    request.responseTime = !!this.severityResponseTime.value ? Number(this.severityResponseTime.value) : 0;
    request.responseTimeUnit = this.severityResponseTimeUnit.value;
    request.rectificationTime = !!this.severityRectificationTime.value
      ? Number(this.severityRectificationTime.value.trim())
      : 0;
    request.rectificationTimeUnit = this.severityRectificationTimeUnit.value;
    request.timeReferenceType = this.severityTimeReferenceType.value;
    request.priority = this.severityPriority.value;

    if (!!this.severityResponseToClient.value) {
      request.responseToClient = Number(this.severityResponseToClient.value);
      request.responseToClientUnit = this.severityResponseToClientUnit.value;
    }

    if (this.hasContractLiftTrapped) {
      request.liftTrapped = this.severityLiftTrapped.value === this.liftTrappedOption.yes;
    }

    this.submitBtn.isLoading = true;
    const response: JM.JMResponseContractsAddContractSeverity = await AppDelegate.sendJMRequest(request);
    this.submitBtn.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.addedContractSeverity.emit(response.payload);
    this.resetForm();
  }

  //------------------------
  // button callback
  onSubmitBtnClicked() {
    const data = {
      msg: {
        content: 'popup.confirm-to-save',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestAddContractSeverity();
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(data);
  }

  //------------------------
  // get form property
  get severityName() {
    return this.severityForm.get('name');
  }
  get severityDescription() {
    return this.severityForm.get('description');
  }
  get severityLiftTrapped() {
    return this.severityForm.get('liftTrapped');
  }
  get severityResponseToClient() {
    return this.severityForm.get('responseToClient');
  }
  get severityResponseToClientUnit() {
    return this.severityForm.get('responseToClientUnit');
  }
  get severityResponseTime() {
    return this.severityForm.get('responseTime');
  }
  get severityResponseTimeUnit() {
    return this.severityForm.get('responseTimeUnit');
  }
  get severityRectificationTime() {
    return this.severityForm.get('rectificationTime');
  }
  get severityRectificationTimeUnit() {
    return this.severityForm.get('rectificationTimeUnit');
  }
  get severityTimeReferenceType() {
    return this.severityForm.get('timeReferenceType');
  }
  get severityPriority() {
    return this.severityForm.get('priority');
  }

  //------------------------
  // get property
  get formTitleText(): string {
    if (this.isEditMode) {
      return JMLanguage.translate('component.contract-severity-editor.title.edit-severity');
    } else {
      return JMLanguage.translate('component.contract-severity-editor.title.add-severity');
    }
  }

  get hasContractLiftTrapped(): boolean {
    return this.contract && this.contract.liftTrapped;
  }
}
