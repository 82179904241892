<ng-container *ngIf="routingCoverage;else selectRoutingCoverageMessage">
  <div class="d-flex justify-content-between mx-0">
    <div class="title">{{ 'component.contract-emsd-contact-list.title.responsible-officer' | translate }}</div>
    <div class="d-flex justify-content-end mt-3">
      <app-icon-button 
        [id]="'contract-contact-list-download-button'"
        [color]="'blue'"
        [icon]="'fa-download'"
        [isDisabled]="isDownloading || isDisabled"
        [isLoading]="isDownloading"
        (onClick)="onClickDownload()" 
      ></app-icon-button>
    </div>
    <!-- <app-icon-button
      *ngIf="!isComponentUEEModeCreate && hasEmsdUserCreatePermission"
      [id]="'user-manage-add-user-button'"
      [color]="'blue'"
      [icon]="'fa-plus'"
      (onClick)="onClickedCreateMode()"
    > 
    </app-icon-button>-->
  </div>
  <hr class="underline mt-1" />
  <div class="tablexWrapper">
    <app-tablex id="ro-contact-table" [tablexParam]="roTablexParam"></app-tablex>
  </div>

  <div class="d-flex justify-content-between mx-0">
    <div class="title">{{ 'component.contract-emsd-contact-list.title.member' | translate }}</div>
  </div>
  <hr class="underline mt-1" />
  <div class="tablexWrapper">
    <app-tablex id="member-contact-table" [tablexParam]="memberTablexParam"></app-tablex>
  </div>
</ng-container>


<ng-template #selectRoutingCoverageMessage>
  <!-- Please select Routing Coverage -->
</ng-template>