import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import {
  TablexColumnFilterOption,
  TablexColumnFilterType,
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';
import { Session } from 'src/app/core/session';

@Component({
  selector: 'app-pm-plan-list',
  templateUrl: './pm-plan-list.component.html',
  styleUrls: ['./pm-plan-list.component.scss'],
})
export class PmPlanListComponent implements OnInit {
  // Table
  tablexFilter: any = {};
  tablexParam: {} = {};
  pageSizeOptions: any = [10, 25, 100];
  pageCount = 1;
  allColHeaders: any = [];
  selectedColId: any = [];
  selectedCol: any = [];
  selectedRow: JMOBJ.PmPlan = null;
  tablexDefaultSortBy = 'startDate';
  tablexDefaultSortOrder: JMENUM.SortOrder = JMENUM.SortOrder.DESC;
  // selectedTableRows: any = [];
  statusOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  scheduleOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  coverageOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  frequencyOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  teamOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  workCentreOptions: TablexColumnFilterOption = new TablexColumnFilterOption();

  enableCreateJob: boolean = false;

  pmPlanList: any = [];
  currentPageSize: number = 10;
  currentPageNumber: number = 1;

  searchFilters: any = null;
  searchStatus: string[] = null;
  searchScheduleType: string[] = null;
  searchFrequency: string[] = null;
  searchHandlingTeam: string[] = null;
  searchDateRange: any = null;

  constructor() {}

  ngOnInit(): void {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }

    this.initAllTableHeader();
    this.initSelectedCol();
    this.initTablex();

    // 1st search on search criteria
    // this.requestPmPlanList();
  }

  private async requestPmPlanList() {
    // TODO: API intergration
    let request: JM.JMRequestVpGetPmPlanList = new JM.JMRequestVpGetPmPlanList();

    if (this.searchFilters) {
      request.filter = this.searchFilters;
    }
    if (this.searchStatus) {
      request.status = this.searchStatus;
    }
    if (this.searchScheduleType) {
      request.scheduleType = this.searchScheduleType;
    }
    if (this.searchFrequency) {
      request.frequency = this.searchFrequency;
    }
    if (this.searchHandlingTeam) {
      request.vendorTeamId = this.searchHandlingTeam;
    }
    if (this.searchDateRange) {
      for (const key in this.searchDateRange) {
        request[key] = this.searchDateRange[key];
      }
    }

    request.sortBy = this.tablexParam['sortBy'];
    request.sortOrder = this.tablexParam['sortOrder'];
    request.pageNumber = this.currentPageNumber;
    request.pageSize = this.currentPageSize;

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseVpGetPmPlanList = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (Array.isArray(response.payload.records) && response.payload.records.length > 0) {
      this.pmPlanList = response.payload.records;
      this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
      this.tablexParam['currentPage'] = request.pageNumber;
      this.renderTable();
    } else {
      this.pmPlanList = [];
      this.renderTable();
      this.tablexParam['pageCount'] = 0;
      this.tablexParam['currentPage'] = 0;
    }
  }

  translateFilters(searchCriteria) {
    let filters: { [x: string]: any } = {};
    let scheduleType = [],
      status = [];

    searchCriteria.pmPlanNumber && (filters.pmPlanNumber = searchCriteria.pmPlanNumber);
    searchCriteria.contractNumber && (filters['contract.contractNumber'] = searchCriteria.contractNumber);
    searchCriteria.planDescription && (filters.planDescription = searchCriteria.planDescription);

    for (const key in searchCriteria.planStatus) {
      searchCriteria.planStatus[key] && status.push(key);
    }

    for (const key in searchCriteria.scheduleType) {
      searchCriteria.scheduleType[key] && scheduleType.push(key);
    }

    this.searchDateRange = null;
    if (searchCriteria.startRange) {
      let field;
      switch (searchCriteria.startRange) {
        case 'on-or-before':
          field = 'startDateTo';
          break;
        case 'after':
          field = 'startDateFrom';
          break;
      }
      if (field && searchCriteria.startDate) {
        this.searchDateRange = {
          [field]: searchCriteria.startDate,
          ...this.searchDateRange,
        };
      }
    }

    if (searchCriteria.startRange) {
      let field;
      switch (searchCriteria.endRange) {
        case 'on-or-before':
          field = 'endDateTo';
          break;
        case 'after':
          field = 'endDateFrom';
          break;
      }
      if (field && searchCriteria.endDate) {
        this.searchDateRange = {
          [field]: searchCriteria.endDate,
          ...this.searchDateRange,
        };
      }
    }

    let size = Object.keys(filters).length;
    this.searchFilters = size > 0 ? filters : null;
    this.searchStatus = status.length > 0 ? status : null;
    this.searchScheduleType = scheduleType.length > 0 ? scheduleType : null;
    this.searchFrequency = searchCriteria.frequency.length > 0 ? searchCriteria.frequency : null;
    this.searchHandlingTeam = searchCriteria.handlingTeam.length > 0 ? searchCriteria.handlingTeam : null;
  }

  onAdvancedSearch({ searchCriteria, pageNumber, pageSize }) {
    if (searchCriteria) {
      this.translateFilters(searchCriteria);
    }
    if (typeof pageNumber === 'number') {
      this.currentPageNumber = pageNumber;
    }
    if (typeof pageSize === 'number') {
      this.currentPageSize = pageSize;
    }
    this.requestPmPlanList();
  }

  initAllTableHeader() {
    this.allColHeaders = [
      {
        id: 'objId',
        name: 'pages.job-list.table-column.id',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col d-none',
      },
      {
        id: 'pmPlanNumber',
        name: 'pages.pm-plan-list.table-column.pm-plan-no',
        // enableFilter: true,
        enableSort: true,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'planDescription',
        name: 'pages.pm-plan-list.table-column.plan-desc',
        // enableFilter: true,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'status',
        name: 'pages.pm-plan-list.table-column.status',
        // enableFilter: true,
        // enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.statusOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'scheduleType',
        name: 'pages.pm-plan-list.table-column.schedule-type',
        // enableFilter: true,
        // enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.scheduleOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      // {
      //   id: 'planCoverage',
      //   name: 'pages.pm-plan-list.table-column.plan-coverage',
      //   // enableFilter: true,
      //   // enableSort: true,
      //   filterType: TablexColumnFilterType.Dropdown,
      //   filterDropdownOption: this.coverageOptions,
      //   showOptionTitle: true,
      //   type: TablexColumnType.Text,
      //   horizontalAlign: TablexColumnHorizontalAlign.Center,
      //   verticalAlign: TablexColumnVerticalAlign.Bottom,
      //   class: 'col th-align-self-start appraisal-status',
      // },
      {
        id: 'frequency',
        name: 'pages.pm-plan-list.table-column.frequency',
        // enableFilter: true,
        // enableSort: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.frequencyOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'startDate',
        name: 'pages.pm-plan-list.table-column.start-date',
        // enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'endDate',
        name: 'pages.pm-plan-list.table-column.end-date',
        // enableFilter: false,
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
      {
        id: 'teamId',
        name: 'pages.pm-plan-list.table-column.handling-team',
        // enableFilter: true,
        // enableSort: true,
        type: TablexColumnType.Text,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.teamOptions,
        showOptionTitle: true,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col th-align-self-start',
      },
    ];
    this.selectedCol = this.allColHeaders;
  }

  initSelectedCol() {
    this.selectedColId = [
      // 'objId',
      'pmPlanNumber',
      'planDescription',
      'status',
      'scheduleType',
      // 'planCoverage',
      'frequency',
      'startDate',
      'endDate',
      'teamId',
    ];

    this.loadColumnSettingsFromLocal();

    this.onColFiltered(this.selectedColId);
  }

  initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      // enableClearFilter: true,
      enableColFilter: true,
      // enableSelectedRowCount: true,
      enableSort: true,
      // sortOrder: -1,
      // sortBy: 'updatedAt',
      minifyButton: true,
      tableRow: 'row',
      tableClass: 'pm-plan-list-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPageNumber,
      pageCount: this.pageCount,
      // selectedRowCount: 0,
      // totalRowCount: 0,
      selectedColId: this.selectedColId,
      fullColNameList: this.allColHeaders.map((col) => {
        return { id: col.id, name: col.name };
      }),
      onPageNumberClicked: this.onPageNumberClicked.bind(this),
      onPageSizeClicked: this.onPageSizeClicked.bind(this),
      // onFilterChanged: this.onFilterChanged,
      // onFilterClear: this.onFilterClear,
      onRowClicked: this.onRowClicked.bind(this),
      onColFiltered: this.onColFiltered.bind(this),
      onSortOrderChanged: this.onSortOrderChanged.bind(this),
      // enableStickyHeader: false,
      // filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
      customClass: 'expiry',
      sortBy: this.tablexDefaultSortBy,
      sortOrder: this.tablexDefaultSortOrder,
    };
  }

  renderTable() {
    this.tablexParam['headers'] = this.allColHeaders.filter((col) => {
      return this.selectedColId.includes(col.id);
    });

    this.tablexParam['content'] = this.pmPlanList.map((data) => {
      let originRow = [
        data._id,
        data.pmPlanNumber ? { url: `/pm/plans/${data.pmPlanNumber}`, value: data.pmPlanNumber } : '',
        data.planDescription ? data.planDescription : '',
        data.status ? JMLanguage.translate('pm-plan.status.' + data.status) : '',
        data.scheduleType ? JMLanguage.translate('pm-plan.schedule-type.' + data.scheduleType) : '',
        // data.planCoverage ? JMLanguage.translate('pm.plan-coverage.' + data.planCoverage) : '',
        data.frequency ? JMLanguage.translate('pm-plan.frequency.' + data.frequency) : '',
        data.startDate ? moment(data['startDate']).format(Constants.DATE_FORMAT) : '',
        data.endDate ? moment(data['endDate']).format(Constants.DATE_FORMAT) : '',
        data.vendorTeam ? data.vendorTeam.name : '',
      ];
      let row = [];
      originRow.forEach((value, i) => {
        if (this.selectedColId.includes(this.allColHeaders[i].id)) {
          row.push(originRow[i]);
        }
      });
      return row;
    });
    this.tablexParam['isLoadingTable'] = false;
  }

  onRowClicked(index, row) {
    const pmPlanId = row[0];
    const matchedPmPlan = this.pmPlanList.find((plan) => plan._id === pmPlanId);

    if (matchedPmPlan) {
      const isInSelectedList = this.selectedRow && this.selectedRow._id === matchedPmPlan._id;
      if (isInSelectedList) {
        // click selected row
        this.clearRowSelect();
      } else {
        // click new row
        this.clearRowSelect();
        this.tablexParam['highlightedRows'][index] = true;
        this.selectedRow = matchedPmPlan;
        this.enableCreateJob = true;
      }
    }
    // this.tablexParam['selectedRowCount'] = this.selectedRow ? 1 : 0;
  }

  setRowSelect() {}

  clearRowSelect() {
    this.selectedRow = null;
    this.tablexParam['highlightedRows'] = [];
    // this.tablexParam['selectedRowCount'] = 0;
    this.enableCreateJob = false;
  }

  onColFiltered(selectedColId) {
    this.selectedColId = [...selectedColId];
    this.renderTable();
    this.saveColumnSettingsToLocal();
  }

  onPageSizeClicked(pageSize: number) {
    this.currentPageSize = pageSize;
    this.currentPageNumber = 1;
    this.requestPmPlanList();
  }

  onPageNumberClicked(pageIndex: number) {
    this.currentPageNumber = pageIndex;
    this.requestPmPlanList();
  }

  onSortOrderChanged(header, sortOrder) {
    this.tablexParam['sortBy'] = header ? header : 'startDate'; // TODO: default sorting
    this.tablexParam['sortOrder'] = sortOrder;
    this.requestPmPlanList();
  }

  // onFilterChanged = (event, index, header, filter) => {
  //   for (let key in filter) {
  //     if(!filter[key]){
  //       delete this.tablexFilter[key];
  //     }else{
  //       this.tablexFilter[key] = filter[key];
  //     }
  //   }

  //   this.currentPageNumber = 1;
  //   this.requestPmPlanList();
  // }

  // onOptionFilterChanged = (event, i, header) => {
  //   if (header.filterDropdownOption.selectedValue && header.filterDropdownOption.selectedValue.length > 0) {
  //     this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
  //   } else {
  //     delete this.tablexFilter[header.id];
  //   }

  //   this.currentPageNumber = 1;
  //   this.requestPmPlanList();
  // }

  // onFilterClear = () => {
  //   this.tablexFilter = {};
  //   this.tablexParam['filter'] = {};

  //   this.currentPageNumber = 1;
  //   this.requestPmPlanList();
  // }

  private saveColumnSettingsToLocal() {
    try {
      let cache = JSON.parse(JSON.stringify(this.selectedColId));
      Session.setPmPlanSettingsColumns(cache);
    } catch (e) {
      console.warn(e);
    }
  }

  private loadColumnSettingsFromLocal() {
    try {
      let cache = Session.getPmPlanSettingsColumns();
      if (cache) {
        this.selectedColId = JSON.parse(JSON.stringify(cache));
      }
    } catch (e) {
      console.warn(e);
    }
  }

  // TODO:
  onClickCreatePMTask() {
    let pmPlanNumber = this.selectedRow.pmPlanNumber;
    console.log(`go to Create PM task: ${pmPlanNumber}`);
  }
}
