import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';

@Component({
  selector: 'team-member-info-editor',
  templateUrl: './team-member-info-editor.component.html',
  styleUrls: ['./team-member-info-editor.component.scss']
})
export class TeamMemberInfoEditorComponent implements OnInit, OnChanges {
  @Input() post: JMOBJ.Post;
  @Input() vendorTeam: JMOBJ.VendorTeam;

  @Output() updatedInfo = new EventEmitter<JMOBJ.Post>();

  // data
  infoForm = this.fb.group({
    remarks: [undefined],
  });

  saveBtn = {
    isLoading: false,
  };

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.resetForm();
  }

  
  ngOnChanges(changes: SimpleChanges): void {
    this.resetForm();
  }

  private resetForm() {
    const remarks = this.post?.vendorTeamList?.find(vendorTeam => vendorTeam._id == this.vendorTeam?._id)?.remarks;
    
    this.infoForm.reset({
      remarks: remarks,
    });
  }


  //------------------------
  // API
  private async requestUpdateTeamMemberInfo() {
    const request = new JM.JMRequestPostsUpdateVendorTeamMemberInfo();
    request.postName = this.post.name;
    request.vendorTeamId = this.vendorTeam._id;
    request.remarks = this.remarks.value.trim() || '';

    this.saveBtn.isLoading = true;
    const response: JM.JMResponsePostsUpdateVendorTeamMemberInfo = await AppDelegate.sendJMRequest(request);
    this.saveBtn.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.saved'));
    this.updatedInfo.emit(response.payload);
    this.resetForm();
  }

  //------------------------
  // button callback
  onSaveBtnClicked() {
    const data = {
      msg: {
        content: 'component.team-member-info-editor.pop-up.confirm-to-save',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestUpdateTeamMemberInfo();
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(data);
  }

  //------------------------
  // get property
  get remarks() {
    return this.infoForm.get('remarks');
  }

  get inputBoxMaxLength() {
    return Constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }


}
