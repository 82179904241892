import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { SpecialResponseCode } from '@enum/response-code.enum';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';
import { PasswordErrorListComponent } from 'src/app/general-component/password-error-list/password-error-list.component';
import { AuthorizationService } from 'src/app/services/authorization.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('passwordErrorList', { static: false }) passwordErrorList: PasswordErrorListComponent;
  resetPasswordForm: FormGroup;
  isResettingPassword = false;
  isLocalAccount = false;
  showPasswordError = false;
  invalidControlObj = {
    existingPassword: false,
    newPassword: false,
    confirmPassword: false
  }
  constructor(private formBuilder: FormBuilder, private authSerice: AuthorizationService) {
  }

  ngOnInit(): void {
    if (Session.userToken == null) {
      AppDelegate.routeToPage('/login');
    }
    this.isLocalAccount = (Session.userInfo.authenticationMode == JMENUM.AuthenticationMode.LOCAL);
    this.resetPasswordForm = this.formBuilder.group({
      existingPassword: null,
      newPassword: null,
      confirmPassword: null
    });
    if (!this.isLocalAccount) {
      this.resetPasswordForm.controls['existingPassword'].disable();
      this.resetPasswordForm.controls['newPassword'].disable();
      this.resetPasswordForm.controls['confirmPassword'].disable();
    }
    this.resetPasswordForm.valueChanges.subscribe(data => {
      this.invalidControlObj = {
        existingPassword: false,
        newPassword: false,
        confirmPassword: false
      }
    });
  }

  clickResetPasswordBtn() {
    this.showPasswordError = false;
    this.invalidControlObj = {
      existingPassword: false,
      newPassword: false,
      confirmPassword: false
    };
    this.passwordErrorList.resetPasswordErrors();
    if (!this.isLocalAccount) {
      return;
    }

    if (this.resetPasswordForm.value.existingPassword == null || this.resetPasswordForm.value.existingPassword.length == 0) {
      this.invalidControlObj.existingPassword = true;
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('page.login.reset-password.error.existing-password-cannot-be-empty'));
      return;
    }

    if (this.resetPasswordForm.value.newPassword == null || this.resetPasswordForm.value.newPassword.length == 0) {
      this.invalidControlObj.newPassword = true;
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('page.login.reset-password.error.new-password-cannot-be-empty'));
      return;
    }

    if (this.resetPasswordForm.value.newPassword === this.resetPasswordForm.value.existingPassword) {
      this.invalidControlObj.existingPassword = true;
      this.invalidControlObj.newPassword = true;
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('page.login.reset-password.error.new-password-as-same-as-current-password'));
      return;
    }
    
    if (!this.passwordErrorList.isPasswordVaildate(this.resetPasswordForm.value.newPassword)) {
      this.invalidControlObj.newPassword = true;
      this.showPasswordError = true;
      return
    }

    if (this.resetPasswordForm.value.newPassword !== this.resetPasswordForm.value.confirmPassword) {
      this.invalidControlObj.newPassword = true;
      this.invalidControlObj.confirmPassword = true;
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('page.login.reset-password.error.password-not-match'));
      return;
    }


    this.requestUpdatePassword();
  }

  async requestUpdatePassword() {
    const request = new JM.JMRequestPostsUpdatePasswordV2();
    request.oldPassword = this.resetPasswordForm.value.existingPassword;
    request.newPassword = this.resetPasswordForm.value.newPassword;

    this.isResettingPassword = true;
    this.resetPasswordForm.disable();
    const response: JM.JMResponsePostsUpdatePasswordV2 = await AppDelegate.sendJMRequest(request);

    this.isResettingPassword = false;
    this.resetPasswordForm.enable();
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      if(response.code === SpecialResponseCode.WRONG_PASSWORD) {
        this.invalidControlObj.existingPassword = true;
      }
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }
    if (response.payload.post && response.payload.post.authorizations) {
      JM.JMConnector.setToken(response.payload.token);
      JM.JMConnector.setPost(response.payload.post);

      Session.setUserInfo(response.payload.post);
      Session.setUserToken(response.payload.token);

      Session.clearOtpDetail();
      JM.JMConnector.cacheEquipmentCategory().then((category) => {
        Session.setEquipmentCategory(category);
      });
      JM.JMConnector.cacheEquipmentType().then((type) => {
        Session.setEquipmentType(type);
      });
      JM.JMConnector.cacheAllClient().then((allClient) => {
        Session.setClient(allClient);
      });

      AppDelegate.requestUserWorkCentres();

      // get feature list
      const requestSystemFeatureList = new JM.JMRequestSystemConfigsGetSystemFeatureList();
      requestSystemFeatureList.systemName = JMENUM.SourceSystem.CCEPVP;
      AppDelegate.sendJMRequest(requestSystemFeatureList).then(res => {
        Session.setFeatureList(res.payload.records);
      });

      AppDelegate.routeToPage('/home', null, {
        replaceUrl: true,
      });
    } else {
      AppDelegate.toastMsg().showResponseMsg(response)
    }
  }
}

