<ng-container *ngIf="contractNumber">
  <div class="row justify-content-between col-12 font-size-xl m-0 mb-2 p-0">
    <div class="d-flex justify-content-start">
      {{ 'component.contract-equipment-list.title' | translate}}
    </div>

    <div class="d-flex justify-content-end">
      <app-icon-button 
        [id]="'comtract-equipment-download-button'"
        [color]="'blue'"
        [icon]="'fa-download'"
        [isDisabled]="isDownloading"
        [isLoading]="isDownloading"
        (onClick)="onClickDownload()" 
      ></app-icon-button>
    </div>
  </div>

  <hr class="underline" />

  <equipment-table-by-contract [contractNumber]="contractNumber"></equipment-table-by-contract>
</ng-container>