export interface PopUpDialogI {
  msg: {
    content: string;
  };
  buttons: PopUpDialogButtonI[];
}
export interface PopUpDetailDialogI {
  header?: {
    title?: string;
    iconCss?: string;
  },
  content: {
    msg: string;
  }[],
  buttons: PopUpDialogButtonI[];
}
export interface PopUpDialogButtonI {
  name: string;
  handler?: Function;
  cssClass?: string;
}

export enum DialogContentType {
  normal = "normal",
  detail = "detail"
}
