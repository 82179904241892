<div class="contractor-general-setting-container">
  <div class="d-flex justify-content-between" style="min-height: 35px;">
    <div class="font-size-xl">
      {{ "component.settings-contractor.general" | translate}}
    </div>
  
    <div *ngIf="hasUpdatePermission" >
      <button *ngIf="!isEditmode" id="contractor-general-edit-btn" class="btn icon-btn mr-2" 
        (click)="onEdit()" [disabled]="!selectedContractor || !selectedContractor['_id']">
        <i class="fa fa-pencil amber"></i>
      </button>
      <!-- <button class="btn icon-btn mr-2" *ngIf="isEditmode" id="contractor-general-discard-btn" (click)="onRollBack()">
        <i class="fas fa-eraser red"></i>
      </button> -->
      <button class="btn icon-btn mr-2" *ngIf="isEditmode" id="contractor-general-submit-btn" (click)="onSubmit()">
        <i class="fa fa-check amber"></i>
      </button>
    </div>
  </div>
  <hr class="underline" />

  <div class="general-setting-fields-container">
    <div class="field-row">
      <div class="label label-text col-5 mb-0 px-4 text-right">
        {{ "component.contractor-general.label.account-prefix" | translate }}
      </div>
      <div class="value px-3 col-7">
        <ng-container *ngIf="!isEditmode">
          <app-display-text-field id="contractor-general-account-prefix" [textField]="accountPrefix">
          </app-display-text-field>
        </ng-container>
        <ng-container *ngIf="isEditmode">
          <input id="edit-contractor-general-account-prefix" class="form-control" type="text" [(ngModel)]="accountPrefix" 
          [disabled]="selectedContractor && selectedContractor.postNamePrefix" />
        </ng-container>
      </div>
    </div>
    <div class="field-row">
      <div class="label label-text col-5 mb-0 px-4 text-right">
      </div>
      <div class="value px-3 col-7">
        <ng-container *ngIf="!isEditmode">
          <div class="checkbox">
            <input id="contractor-general-active" type="checkbox" [(ngModel)]="active" disabled />
          </div>
          <div class="checkbox-label">
            {{ "component.contractor-general.label.active" | translate }}
          </div>
        </ng-container>
        <ng-container *ngIf="isEditmode">
          <div class="checkbox ">
            <input id="edit-contractor-general-active" type="checkbox" [(ngModel)]="active" />
          </div>
          <div class="checkbox-label">
            <label for="edit-contractor-general-active" style="margin-bottom: 0">{{ "component.contractor-general.label.active" | translate }}</label>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<!-- <div class="row mb-3 mx-0" *ngIf="!isEditmode">
  <div class="col-6 row mx-0 align-items-center">
    <div class="col-5 mt-3 p-0 pl-4 text-right font-size-m gray-label">
      {{ "component.contractor-general.label.account-prefix" | translate }}
    </div>
    <div class="col-7 mt-3">
      <app-display-text-field id="contractor-general-account-prefix" [textField]="accountPrefix">
      </app-display-text-field>
    </div>

    <div class="col-5 mt-3 p-0 pl-4 text-right font-size-m gray-label ">
      {{ "component.contractor-general.label.hotline" | translate }}
    </div>
    <div class="col-7 mt-3">
      <app-display-text-field id="contractor-general-hotline" [textField]="selectedContractor?.telephone">
      </app-display-text-field>
    </div>

    <div class="col-5 mt-3 p-0 pl-4 text-right font-size-m gray-label ">
      {{ "component.contractor-general.label.fax" | translate }}
    </div>
    <div class="col-7 mt-3">
      <app-display-text-field id="contractor-general-fax" [textField]="selectedContractor?.fax">
      </app-display-text-field>
    </div>

    <div class="col-5 mt-3 p-0 pl-4 text-right font-size-m gray-label ">
      {{ "component.contractor-general.label.email" | translate }}
    </div>
    <div class="col-7 mt-3">
      <app-display-text-field id="contractor-general-email" [textField]="selectedContractor?.email">
      </app-display-text-field>
    </div>

    <div class="col-5 mt-3 p-0 pl-4 text-right font-size-m gray-label ">
      {{ "component.contractor-general.label.responsible-officer" | translate }}
    </div>
    <div class="col-7 mt-3">
      <app-display-text-field id="contractor-general-reponsible-officer" [textField]="responsibleOfficer">
      </app-display-text-field>
    </div>

    <div class="col-5 mt-3 p-0 pl-4 text-right font-size-m gray-label ">
      {{ "component.contractor-general.label.contractor-default-team" | translate }}
    </div>
    <div class="col-7 mt-3">
      <app-display-text-field id="contractor-general-contractor-default-team" [textField]="contractorDefaultTeam">
      </app-display-text-field>
    </div>

  </div>
  

  <div class="col-6 row mx-0 align-content-start">
    <div class="col-2 mt-3 p-0 pl-4 text-right ">
      <input id="contractor-general-require-tfa" type="checkbox" [(ngModel)]="requireTFA" disabled />
    </div>
    <div class="col-10 mt-3 font-size-m gray-label">
      {{ "component.contractor-general.label.require-two-factor-authentication" | translate }}
    </div>

    <div class="col-2 mt-3 p-0 pl-4 text-right">
      <input id="contractor-general-active" type="checkbox" [(ngModel)]="active" disabled />
    </div>
    <div class="col-10 mt-3 font-size-m gray-label">
      {{ "component.contractor-general.label.active" | translate }}
    </div>
  </div>

</div>

<div class="row mb-3 mx-0" *ngIf="isEditmode">
  <div class="col-6 row mx-0 align-items-center">
    <div class="col-5 mt-3 p-0 pl-4 text-right font-size-m gray-label">
      {{ "component.contractor-general.label.account-prefix" | translate }}
    </div>
    <div class="col-7 mt-3">
      <input id="edit-contractor-general-account-prefix" class="form-control" type="text" [(ngModel)]="accountPrefix" />
    </div>

    <div class="col-5 mt-3 p-0 pl-4 text-right font-size-m gray-label ">
      {{ "component.contractor-general.label.hotline" | translate }}
    </div>
    <div class="col-7 mt-3">
      <app-display-text-field id="edit-contractor-general-hotline" [textField]="selectedContractor?.telephone">
      </app-display-text-field>
    </div>

    <div class="col-5 mt-3 p-0 pl-4 text-right font-size-m gray-label ">
      {{ "component.contractor-general.label.fax" | translate }}
    </div>
    <div class="col-7 mt-3">
      <app-display-text-field id="edit-contractor-general-fax" [textField]="selectedContractor?.fax">
      </app-display-text-field>
    </div>

    <div class="col-5 mt-3 p-0 pl-4 text-right font-size-m gray-label ">
      {{ "component.contractor-general.label.email" | translate }}
    </div>
    <div class="col-7 mt-3">
      <input id="edit-contractor-general-email" class="form-control" type="text" [(ngModel)]="email" (change)="onChangeEmail(email)" />
    </div>

    <div class="col-5 mt-3 p-0 pl-4 text-right font-size-m gray-label ">
      {{ "component.contractor-general.label.responsible-officer" | translate }}
    </div>
    <div class="col-7 mt-3">
      <ng-select id="edit-contractor-general-reponsible-officer" 
        [items]="responsibleOfficerOptions" bindLabel="label label-text col-5 mb-0 px-4 text-right" bindValue="value px-3 col-7" [loading]="isLoadingPost"
        (scrollToEnd)="onPostScrollToEnd()" (search)="onSearchPost($event)" [(ngModel)]="responsibleOfficer" >
      </ng-select>
    </div>

    <div class="col-5 mt-3 p-0 pl-4 text-right font-size-m gray-label ">
      {{ "component.contractor-general.label.contractor-default-team" | translate }}
    </div>
    <div class="col-7 mt-3">
      <ng-select id="edit-contractor-general-contractor-default-team" 
        [items]="contractorDefaultTeamOptions" bindLabel="label label-text col-5 mb-0 px-4 text-right" bindValue="value px-3 col-7" [loading]="isLoadingTeam"
        (scrollToEnd)="onTeamScrollToEnd()" (search)="onSearchTeam($event)" [(ngModel)]="contractorDefaultTeamInput">
      </ng-select>
    </div>

  </div>

  <div class="col-6 row mx-0 align-content-start">
    <div class="col-2 mt-3 p-0 pl-4 text-right font-size-m gray-label">
      <input id="edit-contractor-general-require-tfa" type="checkbox" [(ngModel)]="requireTFA" />
    </div>
    <div class="col-10 mt-3">
      <label for="edit-contractor-general-require-tfa">{{ "component.contractor-general.label.require-two-factor-authentication" | translate }}</label> 
    </div>

    <div class="col-2 mt-3 p-0 pl-4 text-right font-size-m gray-label">
      <input id="edit-contractor-general-active" type="checkbox" [(ngModel)]="active" />
    </div>
    <div class="col-10 mt-3">
      <label for="edit-contractor-general-active">{{ "component.contractor-general.label.active" | translate }}</label>
    </div>
  </div>
</div> -->
