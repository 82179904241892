import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Constants } from 'src/constants';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from 'src/app/shared/tablex/tablexColumnType';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { TablexComponentParams } from 'src/app/shared/tablex/tablex.component';

@Component({
  selector: 'app-upload-attachment-tooltip',
  templateUrl: './upload-attachment-tooltip.component.html',
  styleUrls: ['./upload-attachment-tooltip.component.scss']
})
export class UploadAttachmentTooltipComponent implements OnInit {
  @ViewChild('upload_attachment_preview', { static: false }) uploadAttachmentPreview;
  @ViewChild('fileUpload', { static: false }) fileInputRef: ElementRef<HTMLInputElement>;

  @Input() parameters = {
    isDisabledAddAttachment: false,
    isLoadingAddAttachment: false,
    hasEditPermission: false,
  }

  @Output() onSubmitAttachment = new EventEmitter<File[]>();

  files: Array<File> = [];
  tablexParam: TablexComponentParams;

  isShowUploadTooltip: boolean = false;
  isPreviewerLoading: boolean = false;
  isFileOverSize: boolean = false;

  constructor() { }

  ngOnInit() {
    this.tablexParam = {
      enableSetPageSize: false,
      enablePagination: false,
      enableStickyHeader: false,
      enableClearFilter: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      enableSelectedAll: false,
      enableSort: false,
      enableSelectCol:  false,
      headers: [
        // {
        //   id: '_id',
        //   name: 'id',
        //   type: TablexColumnType.Text,
        //   horizontalAlign: TablexColumnHorizontalAlign.Center,
        //   verticalAlign: TablexColumnVerticalAlign.Middle,
        //   class: "d-none"
        // },
        {
          id: 'fileName',
          name: 'component.attachment.fileName',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-6"
        },
        {
          id: 'fileSize',
          name: 'component.attachment.fileSize',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-4"
        },
        {
          id: 'action',
          name: 'component.attachment.action',
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-2 justify-content-around"
        }
      ],
      content: []
    }
  }

  ngOnChanges(){
  }

  onClickAddAttachmentTooltip(){
    this.isShowUploadTooltip = !this.isShowUploadTooltip;
  }

  onClickSubmitAttachment() {
    this.onSubmitAttachment.emit(this.files);
  }

  public closeUploadPreview(){
    this.isShowUploadTooltip = false;
  }

  public clearUploadTable() {
    this.resetFile();
  }

  public renderPreviewTable() {
    let isOversize = false;
    this.tablexParam.content = this.files.map((file, index) => {
      // get filesize
      let fileSizeStr = this.getFileSize(file.size);
      // oversize handling
      !isOversize && (isOversize = file.size > Constants.ATTACHMENT_SIZE);

      let buttons = [{
        "id": "delete-button",
        "name": "",
        "class": "delete-button btn p-1",
        "icon": "fas fa-times font-size-l",
        "onClicked": this.onFileDelete.bind(this),
        "index": index,
      }];

      return [
        file.name,
        fileSizeStr,
        buttons
      ]
    });
    !this.isFileOverSize && isOversize && AppDelegate.toastMsg().showMsg(JMLanguage.translate("component.attachment.oversize"));
    this.isFileOverSize = isOversize;
  }

  public getFileSize(fileSize) {
    let size = fileSize / 1024;
    let unit = "KB";
    
    if (size > 1024) {
      size = size / 1024;
      unit = "MB";
    }

    return size.toFixed(2) + " " + unit;
  }

  // drag and drop handler
  onDraggedOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
  }

  onFilesDropped(event: any) {
    event.preventDefault();
    event.stopPropagation();

    const uploadFiles: FileList = event.dataTransfer.files;
    if (uploadFiles.length > 0) {
      this.setFile(uploadFiles);
    }
    this.renderPreviewTable();
  }

  // file input handler
  onChangeFileInput(event: Event){
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const uploadFiles: FileList = target.files;
    if (uploadFiles.length > 0) {
      this.setFile(uploadFiles);
    }
    this.renderPreviewTable();
  }

  onFileDelete(button) {
    this.files.splice(button.index, 1);
    this.fileInputRef.nativeElement.value = null;
  }

  private resetFile() {
    this.files = [];
    this.fileInputRef.nativeElement.value = null;
  }

  private setFile(files?: FileList) {
    this.files = Array.from(files);
  }

  private addFiles(files?: FileList) {
    if (Array.isArray(this.files)) {
      this.files = this.files.concat(Array.from(files))
    } else {
      this.files = Array.from(files);
    }
  }
}
