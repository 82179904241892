<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [class]="toast.classname"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hide)="toastService.remove(toast)"
  [header]="toast.textOrTpl"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
  </ng-template>
  <!-- <ng-template #text>{{ toast.textOrTpl }}</ng-template> -->
</ngb-toast>