import { Component, Input, OnInit } from '@angular/core';
import { JM, JMENUM, JMOBJ, JMREASON } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/core/session';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';

@Component({
  selector: 'app-notification-info',
  templateUrl: './notification-info.component.html',
  styleUrls: ['./notification-info.component.scss'],
})
export class NotificationInfoComponent implements OnInit {
  @Input() sn: JMOBJ.ServiceNotification;

  priorityEnum = JMENUM.JMPriority;
  statusEnum = JMENUM.SnStatus;
  language: string;

  //input fields
  snStatus: string;
  contractNumber: string;
  client: string;
  contactNumber: string;
  contactPerson: string;
  email: string;
  location: string;
  priority: string;
  faultDetail: string;
  equipmentCategory: string;
  equipmentType: string;
  createdAt: Date;
  createdBy: string;
  handledBy: string;
  initialSystem: string;

  constructor() {}

  ngOnInit(): void {
    this.language = Session.selectedLanguage;
  }

  ngOnChanges() {
    if (this.sn.client) {
      this.requestClientSummary();
    }
    if (this.sn.location) {
      this.requestLocationSummary();
    }

    this.updateText();
  }

  // ----------- API ----------- //
  private async requestClientSummary() {
    let request = new JM.JMRequestClientsClientSummary();
    request.parameters = ['clientShortName', 'name'];
    request.clientShortName = [this.sn.client];
    request.pageNumber = 1;
    request.pageSize = 1;

    let response: JM.JMResponseClientsClientSummary = await AppDelegate.sendJMRequest(request);

    if (response.error) {
      AppDelegate.toastMsg().showMsg(response.error);
      return;
    }
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showMsg(response);
      return;
    }

    if (response.payload.records.length) {
      const client = response.payload.records[0];
      const description = client.name[this.language] ? client.name[this.language] : client.name.en;

      this.client = client ? `${client.clientShortName} - ${description}` : `${client.clientShortName}`;
    }
  }

  private async requestLocationSummary() {
    let request = new JM.JMRequestLocationsLocationSummary();
    request.parameters = ['code', 'description'];
    request.location = [this.sn.location];

    let response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);

    if (response.error) {
      AppDelegate.toastMsg().showMsg(response.error);
      return;
    }
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showMsg(response);
      return;
    }

    if (response.payload.records.length) {
      const location = response.payload.records[0];
      const description = location.description[this.language]
        ? location.description[this.language]
        : location.description.en;

      this.location = location ? `${description} (${location.code})` : `${location.code}`;
    }
  }

  // ----------- UI function ----------- //
  updateText() {
    this.snStatus = this.sn.status ? JMLanguage.translate(`sn.status.${this.sn.status}`) : '';
    this.priority = this.sn.priority ? JMLanguage.translate(`sn.priority.${this.sn.priority}`) : '';

    if (this.sn.equipmentCategory) {
      const equipmentCategoryObj = AppDelegate.getEquipmentCategory();
      if (equipmentCategoryObj && equipmentCategoryObj[this.sn.equipmentCategory]) {
        this.equipmentCategory = `${equipmentCategoryObj[this.sn.equipmentCategory].description[this.language]}`;
      }
    }

    if (this.sn.equipmentType) {
      const equipmentTypeObj = AppDelegate.getEquipmentType();
      if (equipmentTypeObj && equipmentTypeObj[this.sn.equipmentType]) {
        this.equipmentType = `(${this.sn.equipmentType}) ${
          equipmentTypeObj[this.sn.equipmentType].description[this.language]
        }`;
      }
    }
  }

  //------------------------
  // get property
  get cancelReason() {
    const code = this.sn.cancellationCode;
    const remarks = this.sn.cancelRemarks;
    const reason = code && remarks ? `[${code}] ${remarks}` : null;

    return reason;
  }

  get rejectReason() {
    const code = this.sn?.rejectCode;
    const remarks = this.sn?.rejectRemarks ? this.sn?.rejectRemarks : '';
    const description = code ? JMREASON.SN_REJECT_REASON_LIST.getDescription(code) : null;
    const reason = description && description[Session.selectedLanguage] ? `[${description[Session.selectedLanguage]}] ` : '';

    return `${reason}${remarks}`;
  }
}
