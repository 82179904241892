<!-- temp permission of currentPageMode==pageMode.EDIT -->
<!-- Please remove when sn-edit handle draft and reroute case -->
<ng-container *ngIf="pageMode == JMPageMode.VIEW">
  <div class="font-size-xl m-0">
      {{ "component.sn-remarks.title" | translate }}
  </div>
  <hr class="underline" />
  <div class="m-0 mb-4">
    <div class="row m-0 p-0 align-items-center"
      *ngIf="sn.status == snStatus.DRAFT && sn.remarks && sn.remarks.length">
      <div class="col-2 text-right gray-title m-0 mb-4 px-0 pl-1">
          {{"component.sn-remarks.remark" | translate }}
      </div>
      <div class="col-10 text-left font-size-s m-0 mb-4 overflow-auto"id="sn-remarks-remark">
        <app-display-text-field id="remark-detail-remark" [textField]="sn.remarks[0].remark">
        </app-display-text-field>
      </div>
    </div>
    
    <div *ngIf="sn.status != snStatus.DRAFT">
      <!-- remark field for edit? -->
      <div class="mb-4 text-right" *ngIf="pageMode == JMPageMode.EDIT">
        <div class="remark-prioity m-0 p-0 mx-3"
          [ngClass]="{'selected-visibility': additionalRemark.visibilityType  == RemarkVisibility.INVISIBLE}"
          *ngIf="invisibleVisibilityPermission"
          (click)="onRemarkVisibilityChanged(RemarkVisibility.INVISIBLE)">
          {{'pages.sn.remark-visibility-invisible' | translate}}
        </div>
        <div class="remark-prioity m-0 p-0 mx-3" *ngIf="privateVisibilityPermission"
          [ngClass]="{'selected-visibility': additionalRemark.visibilityType  == RemarkVisibility.PRIVATE}"
          (click)="onRemarkVisibilityChanged(RemarkVisibility.PRIVATE)">
          {{'pages.sn.remark-visibility-private' | translate}}
        </div>
        <div class="remark-prioity m-0 p-0 mx-3" *ngIf="publicVisibilityPermission"
          [ngClass]="{'selected-visibility': additionalRemark.visibilityType  == RemarkVisibility.PUBLIC}"
          (click)="onRemarkVisibilityChanged(RemarkVisibility.PUBLIC)">
          {{'pages.sn.remark-visibility-public' | translate}}
        </div>

        <textarea maxlength="{{ remarksMaxLength }}" id="create-sn_remarks_inputbox" type="text"
          class="form-control"
          [(ngModel)]="additionalRemark.remark"
          [ngModelOptions]="{standalone: true}"
          rows="3">
        </textarea>
      </div>

      <remark-history
        [remarks]="sn.remarks"
        [editMode]="pageMode == JMPageMode.EDIT"
      ></remark-history>
    </div>
  </div>
</ng-container>