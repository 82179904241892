import { JMENUM } from '@ccep/CCEPConnector-ts';
import { ActionButtonI } from 'src/app/shared/action-sidebar-v2/action-sidebar-v2.model';

export const PmJobViewActionButtonList: { [x: string]: ActionButtonI } = {
  [JMENUM.VpPmJobAction.SUBMIT]: {
    action: JMENUM.VpPmJobAction.SUBMIT,
    permissionList: [JMENUM.VpPermission.PMJOB_SUBMIT],
    actionDescription: 'action.button.submit',
    buttonId: 'right-tool-bar_submit_button',
    imageUrl: '/assets/svg/btn_action_submit.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  [JMENUM.VpPmJobAction.CANCEL]: {
    action: JMENUM.VpPmJobAction.CANCEL,
    permissionList: [JMENUM.VpPermission.PMJOB_CANCEL],
    actionDescription: 'action.button.cancel',
    buttonId: 'right-tool-bar_cancel_button',
    imageUrl: '/assets/svg/btn_action_cancel.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  [JMENUM.VpPmJobAction.UPDATE]: {
    action: JMENUM.VpPmJobAction.UPDATE,
    permissionList: [JMENUM.VpPermission.PMJOB_UPDATE],
    actionDescription: 'action.button.edit',
    buttonId: 'right-tool-bar_edit_button',
    imageUrl: '/assets/svg/btn_action_edit.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  [JMENUM.VpPmJobAction.EQUIPMENT]: {
    action: JMENUM.VpPmJobAction.EQUIPMENT,
    permissionList: [JMENUM.VpPermission.PMJOB_UPDATE],
    actionDescription: 'action.button.equipment',
    buttonId: 'right-tool-bar_update-equipment_button',
    imageUrl: '/assets/svg/btn_action_equipment.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  [JMENUM.VpPmJobAction.ATTACHMENT]: {
    action: JMENUM.VpPmJobAction.ATTACHMENT,
    permissionList: [JMENUM.VpPermission.PMJOB_UPDATE],
    actionDescription: 'action.button.attachment',
    buttonId: 'right-tool-bar_attachment_button',
    imageUrl: '/assets/svg/btn_action_attach.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
};
