import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';

import { Constants } from 'src/constants';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { UserHelper } from 'src/app/core/user-helper';
import { AppDelegate } from 'src/app/core/AppDelegate';

@Component({
  selector: 'app-pm-plan-particulars',
  templateUrl: './pm-plan-particulars.component.html',
  styleUrls: ['./pm-plan-particulars.component.scss'],
})
export class PmPlanParticularsComponent implements OnInit {
  @Input() plan: JMOBJ.PmPlan;
  @Input() pageMode: JMENUM.JMPageMode;

  PMStatus = JMENUM.PMStatus;
  PlanCoverage = JMENUM.PMPlanCoverage;
  JMPageMode = JMENUM.JMPageMode;

  // Validation
  valid: boolean;
  errorFields: any = {};

  editable: any = {};
  mandatory: any = {};

  // Input Fields
  equipmentCount: number;
  handlingTeam: string;
  teamName: string;

  // Team Dropdown
  teamOptions: any = [];
  private searchTeamObserver = new Subject<any[]>();
  searchTeamKeywords: string = null;
  teamPageSize: number = 100;
  teamPageNumber: number = 0;
  teamTotalPage: number;
  isLoadingTeam: boolean = false;
  isDisabledTeam: boolean = true;

  constructor() {}

  ngOnInit() {
    if (this.pageMode !== JMENUM.JMPageMode.VIEW) {
      this.fieldsControl();

      this.searchTeamObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
        this.searchTeams();
      });
    }
  }

  ngOnChanges() {
    let isDraftEdit: boolean = this.pageMode === JMENUM.JMPageMode.EDIT && this.plan.status === JMENUM.PMStatus.DRAFT;
    if ((this.pageMode === JMENUM.JMPageMode.VIEW && this.plan.pmPlanNumber) || !isDraftEdit) {
      // this.requestTeamName();
    }
    if (this.pageMode === JMENUM.JMPageMode.CREATE || isDraftEdit) {
      // this.toggleTeamInput();
      // this.fetchNewTeamList();
    }
    if (this.plan.vendorTeam) {
      this.handlingTeam = this.plan.vendorTeam.id;
    }

    this.fieldsControl();
  }

  async fetchNewTeamList() {
    if (this.plan.contract) {
      this.teamPageNumber = 0;
      this.teamTotalPage = null;
      this.teamOptions = await this.requestTeamList(this.teamPageNumber);
    }
  }

  // ----------- API ----------- //
  private async requestTeamList(pageNumber?: number) {
    let request: JM.JMRequestContractsGetVendorTeamListByContract =
      new JM.JMRequestContractsGetVendorTeamListByContract();
    request.parameters = ['_id', 'name'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.teamPageSize;
    request.contractNumber = this.plan.contract.contractNumber;

    if (this.searchTeamKeywords) {
      request.filter = { name: this.searchTeamKeywords };
    }

    this.isLoadingTeam = true;
    let response: JM.JMResponseContractsGetVendorTeamListByContract = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }
    this.isLoadingTeam = false;

    if (pageNumber === 1) {
      let totalTeamCount = response.payload.totalCount;
      this.teamTotalPage = Math.ceil(totalTeamCount / request.pageSize);
    }
    if (response.payload && Array.isArray(response.payload.records)) {
      return response.payload.records.map((team) => {
        return { value: team._id, label: team.name };
      });
    }
  }

  // ----------- UI function ----------- //
  private fieldsControl() {
    if (this.pageMode === JMENUM.JMPageMode.CREATE) {
      this.editable = {
        equipmentCount: false,
        vendorTeam: true,
      };
    } else if (this.pageMode === JMENUM.JMPageMode.EDIT) {
      if (this.plan.status === JMENUM.PMStatus.DRAFT) {
        this.editable = {
          equipmentCount: false,
          vendorTeam: true,
        };
      } else {
        this.editable = {
          equipmentCount: false,
          vendorTeam: false,
        };
      }
    }
    this.mandatory = { ...this.editable };
  }

  public onChangeTeam(event: any) {
    if (event) {
      this.plan.vendorTeam = {
        id: event.value,
        name: event.label,
      };
    } else {
      this.plan.vendorTeam = undefined;
    }
  }

  public async onTeamScrollToEnd() {
    if (this.teamPageNumber < this.teamTotalPage) {
      this.teamPageNumber += 1;
      let teams = await this.requestTeamList(this.teamPageNumber);

      // just append will not trigger ng-select change detection, need to update array referencef
      this.teamOptions = [...this.teamOptions, ...teams];
    }
  }

  public onClearTeam() {
    this.searchTeamKeywords = null;
    this.searchTeamObserver.next();
  }

  public onSearchTeam(event) {
    this.searchTeamKeywords = event.term;
    this.searchTeamObserver.next();
  }

  public async searchTeams() {
    this.teamOptions = [];
    this.teamPageNumber = 0;
    this.teamTotalPage = null;
    this.teamOptions = await this.requestTeamList(this.teamPageNumber);
  }

  public clearAll() {
    for (let field in this.editable) {
      this.plan[field] = undefined;
    }
    this.clearTeam();
  }

  public clearTeam() {
    this.teamOptions = [];
    this.teamPageNumber = 0;
    this.teamTotalPage = null;

    this.handlingTeam = undefined;
    this.plan.vendorTeam = undefined;
    this.searchTeamKeywords = undefined;
  }

  public toggleTeamInput() {
    if (this.plan.contract) {
      this.isDisabledTeam = false;
    } else {
      this.isDisabledTeam = true;
    }
  }

  public validate() {
    let hasErrorField = false;

    // Check fields have data
    for (let field in this.editable) {
      if (field && this.mandatory[field]) {
        this.errorFields[field] = this.plan[field] ? false : true;
      }
    }
    for (let field in this.editable) {
      if (field && this.errorFields[field]) {
        hasErrorField = true;
      }
    }

    if (hasErrorField) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.pm-plan.toast.please-fill-in-mandatory-fields'));
    }

    this.valid = !hasErrorField;
    return this.valid;
  }

  //------------------------
  // get property
  get hasUpdatePermission(): boolean {
    return UserHelper.hasPermission(JMENUM.VpPermission.PMPLAN_UPDATE);
  }
}
