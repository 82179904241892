import { Component, Injector, OnInit, Input } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Session } from 'src/app/core/session';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { JM, JMENUM, JMOBJ} from '@ccep/CCEPConnector-ts';
import { DropdownControl, DropdownSearch, InputControl } from '../../../shared/form.model';

@Component({
  selector: 'app-pm-job-client-info',
  templateUrl: './pm-job-client-info.component.html',
  styleUrls: ['./pm-job-client-info.component.scss']
})
export class PmJobClientInfoComponent implements OnInit {
  @Input() set job( job ) {
    if (this.pageMode === JMENUM.JMPageMode.VIEW && job) {
      if (!this._job || (job.client && job.client != this._job.client)) {
        this.requestClientInformationSummarySingle(job.client);
      }
    }
    this._job = job;
  }
  @Input() pageMode : JMENUM.JMPageMode;

  clientsOptions: any = [];
  inputFieldSettings : {
    [key: string] : {
      editable?: boolean
      mandatory?: boolean
      failed?: boolean
      validation? : (value)=>boolean
    }
  };
  JMPageMode = JMENUM.JMPageMode;
  _job : JMOBJ.PmJob;
  jobClientNameParsed :string ;

  private clientSearch = new DropdownSearch();

  constructor() { }

  ngOnInit() {
      this.fieldsControl();
      if (this.pageMode !== JMENUM.JMPageMode.VIEW) {
        this.setClientSearchObserver();
        this.requestClientInformationSummary();
      }
  }

  private fieldsControl(){
    this.inputFieldSettings = {
      client: {
        editable: true,
        mandatory: true,
      },
      contactNumber: {
        editable: true,
        validation: (val) => {
          let pass = !val || /^\d{0,8}$/.test(val);
          if (!pass) {
            AppDelegate.toastMsg().showMsg(JMLanguage.translate('component.pm-job-client-info.error.msg.contact-number'));
          }
          return pass;
        },
      },
      contactPerson: {
        editable: true,
      },
      email: {
        editable: true,
        validation: (val: string) => {
          let pass = !val || (val.includes('@') && !val.includes(' '));
          if (!pass) {
            AppDelegate.toastMsg().showMsg(JMLanguage.translate('component.pm-job-client-info.error.msg.email'));
          }
          return pass;
        },
      },
    };
  }

  private async requestClientInformationSummary(isAppend: boolean = false) {
      const request: JM.JMRequestClientsClientSummary = new JM.JMRequestClientsClientSummary();
      request.pageSize = this.clientSearch.pageSize;
      request.pageNumber = this.clientSearch.pageNumber;
      // if( JMUTILITY.hasPermission(Session.userInfo, JMENUM.Permission.WORKCENTRE_ALL) ) {
      //   request.workCentre = undefined;
      // } else {
      //   request.workCentre = Session.userInfo.authorizations.workCenters;
      // }
      if (!!this.clientSearch.keywords) {
        request.filter = {
          clientShortName: this.clientSearch.keywords,
        };
      }

      const response: JM.JMResponseClientsClientSummary = await AppDelegate.sendJMRequest(request);
  
      if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.toastMsg().showResponseMsg(response);
        return;
      }

      this.clientSearch.totalPageNumber = Math.ceil(response.payload.totalCount / request.pageSize);
      const optionList = response.payload.records.map(client => { 
        return {
            value: client.clientShortName, 
            label: client.clientShortName+ ' - ' + (
              client.name[Session.selectedLanguage] ? client.name[Session.selectedLanguage] : client.name[JMENUM.Language.EN]
            )
        };
      });
      
      if (isAppend) {
        this.clientsOptions = this.clientsOptions.concat(optionList);
      } else {
        this.clientsOptions = optionList;
      }
  }

  //------------------------
  // Client dropdown event
  onClearClient() {
    this.resetClientOption();
  }

  onClientScrollToEnd() {
    if (this.clientSearch.pageNumber < this.clientSearch.totalPageNumber) {
      this.clientSearch.pageNumber++;

      this.requestClientInformationSummary(true);
    }
  }

  onSearchClient(event) {
    this.clientSearch.keywords = event.term;
    this.clientSearch.search$.next();
  }
  
  private clearClientOption() {
    this.clientsOptions = [];
    this.clientSearch.pageNumber = 1;
    this.clientSearch.totalPageNumber = 0;
  }

  private resetClientOption() {
    this.clientSearch.keywords = null;
    this.clientSearch.search$.next();
  }

  private setClientSearchObserver() {
    this.clientSearch.search$.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.clearClientOption();
      this.requestClientInformationSummary(false);
    });
  }

  public clearAll(){
      for (let field in this.inputFieldSettings) {
        if(this.inputFieldSettings[field].editable){
          this._job[field] = undefined;
          this.inputFieldSettings[field].failed = false;
        }
      }
  }

  public validate() {
    let hasErrorField = false;
    
    for (let field in this.inputFieldSettings) {
      let target = this.inputFieldSettings[field];
      if (target.editable) {

        if(target.mandatory) {
          let isFieldFailed = this._job[field] ? false : true;
          target.failed = isFieldFailed;
          if (isFieldFailed) {
            hasErrorField = true;
            AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.missing-mandatory-fields'));
            continue;
          }
        }

        if(target.validation){
          let isFieldFailed = !target.validation(this._job[field]);
          target.failed = isFieldFailed;
          if (isFieldFailed) {
            hasErrorField = true;
            continue;
          }
        }

      }
    }
    return !hasErrorField;
  }

  private async requestClientInformationSummarySingle(clientName : string) {
      let request = new JM.JMRequestClientsClientSummary();
      request.clientShortName = [clientName];

      const response: JM.JMResponseClientsClientSummary = await AppDelegate.sendJMRequest(request);

      if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
        AppDelegate.toastMsg().showResponseMsg(response);
        return;
      }

      if (response.payload.records && response.payload.records.length > 0) {
        let content = response.payload.records[0];
        this.jobClientNameParsed =
          content.clientShortName +
          ' - ' +
          (content.name[Session.selectedLanguage] ? content.name[Session.selectedLanguage] : content.name[JMENUM.Language.EN]);
      } else {
        this.jobClientNameParsed = clientName;
      }
  }
}
