import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { ngbDateToString, stringToMoment } from 'src/app/core/Formatter';
import { Session } from 'src/app/core/session';
import { Constants } from 'src/constants';

@Component({
  selector: 'app-cm-task-search-criteria',
  templateUrl: './cm-task-search-criteria.component.html',
  styleUrls: ['./cm-task-search-criteria.component.scss']
})
export class CmTaskSearchCriteriaComponent implements OnInit {
  @Input() isDownloading: boolean = false;
  @Output() onSearch: EventEmitter<CmTaskSearchCriteriaI> = new EventEmitter();
  @Output() onDownload: EventEmitter<CmTaskSearchCriteriaI> = new EventEmitter();

  isSearching: boolean = false;
  isCollapse: boolean = false;
  isDisabled: boolean = false; //For Not Ready Function

  showCreationTimeErrorMsg: boolean = false;
  isCreationPeriodError: boolean = false;
  startDateNgb: NgbDateStruct;
  endDateNgb: NgbDateStruct;

  searchSelections: SearchSelectionI;

  // Client Dropdown
  private searchClientObserver = new Subject<any[]>();
  clientOptions: any = [];
  searchClientKeyword: string = null;
  clientPageSize: number = 100;
  clientPageNumber: number = 1;
  clientTotalPage: number;
  isLoadingClient: boolean = false;

  // Contract Dropdown
  private searchContractObserver = new Subject<any[]>();
  contractOptions: any = [];
  searchContractKeyword: string = null;
  contractPageSize: number = 100;
  contractPageNumber: number = 1;
  contractTotalPage: number;
  isLoadingContract: boolean = false;

  // Team Dropdown
  private searchTeamObserver = new Subject<any[]>();
  teamOptions: any = [];
  searchTeamKeyword: string = null;
  teamPageSize: number = 100;
  teamPageNumber: number = 1;
  teamTotalPage: number;
  isLoadingTeam: boolean = false;

  // Equipment Dropdown
  private searchEquipmentObserver = new Subject<any[]>();
  equipmentOptions: any = [];
  searchEquipmentKeyword: string = null;
  equipmentPageSize: number = 100;
  equipmentPageNumber: number = 1;
  equipmentTotalPage: number;
  isLoadingEquipment: boolean = false;

  // Location Dropdown
  private searchLocationObserver = new Subject<any[]>();
  locationOptions: any = [];
  searchLocationKeyword: string = null;
  locationPageSize: number = 100;
  locationPageNumber: number = 1;
  locationTotalPage: number;
  isLoadingLocation: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.initFilters();
    this.loadFilterOptionsFromLocal();
    this.onClickSearch();
  }

  async initFilters() {
    this.searchSelections = {
      snNumber: null,
      jobCardNumber: null,
      status: {},
      startDate: null,
      endDate: null,

      client: null,
      contracts: null,
      handlingTeam: null,
      equipment: null,
      location: null,
    };
    this.startDateNgb = null;
    this.endDateNgb = null;

    const statusList = [
      JMENUM.JobCardStatus.IN_PROGRESS,
      JMENUM.JobCardStatus.REWORKING,
      JMENUM.JobCardStatus.CANCELLING,
      JMENUM.JobCardStatus.CANCELLED,
      JMENUM.JobCardStatus.COMPLETED,
      JMENUM.JobCardStatus.SUBMITTED_FOR_COMPLETION,
    ];
    for (const status of statusList) {
      this.searchSelections.status[status] = false;
    }

    this.searchClientObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchClients.bind(this));
    this.searchContractObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchContracts.bind(this));
    this.searchTeamObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchTeams.bind(this));
    this.searchEquipmentObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchEquipments.bind(this));
    this.searchLocationObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchLocations.bind(this));

    // Promise.all([
    //   this.requestClientList(this.clientPageNumber),
    //   this.requestContractList(this.contractPageNumber),
    //   this.requestTeamList(this.teamPageNumber),
    //   this.requestEquipmentList(this.equipmentPageNumber),
    //   this.requestLocationList(this.locationPageNumber),
    // ]).then(values => {
    //   this.clientOptions = values[0];
    //   this.contractOptions = values[1];
    //   this.teamOptions = values[2];
    //   this.equipmentOptions = values[3];
    //   this.locationOptions = values[4];
    // });
  }

  resetFilters() {
    this.searchSelections.snNumber = null;
    this.searchSelections.jobCardNumber = null;
    this.searchSelections.startDate = null;
    this.searchSelections.endDate = null;

    this.searchSelections.client = null;
    this.searchSelections.contracts = null;
    this.searchSelections.handlingTeam = null;
    this.searchSelections.equipment = null;
    this.searchSelections.location = null;

    for (const key in this.searchSelections.status) {
      this.searchSelections.status[key] = false;
    }

    this.startDateNgb = null;
    this.endDateNgb = null;
  }

  //------------------------
  // client ng select handler
  onSearchClient(event) {
    this.searchClientKeyword = event.term;
    this.searchClientObserver.next();
  }

  clearClientFilter() {
    this.searchClientKeyword = null;
    this.searchClients();
  }

  async searchClients() {
    this.clientOptions = [];
    this.clientPageNumber = 1;
    this.clientTotalPage = null;
    this.clientOptions = await this.requestClientList(this.clientPageNumber);
  }

  async onClientScrollToEnd() {
    if (this.clientPageNumber < this.clientTotalPage) {
      this.clientPageNumber++;

      let clients = await this.requestClientList(this.clientPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.clientOptions = [...this.clientOptions, ...clients];
    }
  }

  private async requestClientList(pageNumber?: number) {
    const request = new JM.JMRequestClientsClientSummary();

    request.parameters = ['clientShortName', 'name'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.clientPageSize;

    if (this.searchClientKeyword) {
      request.filter = { clientShortName: this.searchClientKeyword };
    }

    this.isLoadingClient = true;
    const response: JM.JMResponseClientsClientSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingClient = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.clientTotalPage = Math.ceil(totalCount / request.pageSize);
    }

    return response.payload.records.map((client) => {
      const lang = Session.selectedLanguage;
      const description = client.name[lang] ? client.name[lang] : client.name.en;
      return {
        value: client.clientShortName,
        label: `${client.clientShortName} - ${description}`
      };
    });
  }

  //------------------------
  // contract ng select handler
  onSearchContract(event) {
    this.searchContractKeyword = event.term;
    this.searchContractObserver.next();
  }

  clearContractFilter() {
    this.searchContractKeyword = null;
    this.searchContracts();
  }

  async searchContracts() {
    this.contractOptions = [];
    this.contractPageNumber = 1;
    this.contractTotalPage = null;
    this.contractOptions = await this.requestContractList(this.contractPageNumber);
  }

  async onContractScrollToEnd() {
    if (this.contractPageNumber < this.contractTotalPage) {
      this.contractPageNumber++;

      let contracts = await this.requestContractList(this.contractPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.contractOptions = [...this.contractOptions, ...contracts];
    }
  }

  private async requestContractList(pageNumber?: number) {
    const request = new JM.JMRequestContractsGetContractList();

    request.parameters = ['contractNumber'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.contractPageSize;

    if (this.searchContractKeyword) {
      request.filter = { contractNumber: this.searchContractKeyword };
    }

    this.isLoadingContract = true;
    const response: JM.JMResponseContractsGetContractList = await AppDelegate.sendJMRequest(request);
    this.isLoadingContract = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.contractTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    return response.payload.records.map((contract) => {
      return contract.contractNumber;
    });
  }

  //------------------------
  // team ng select handler
  onSearchTeam(event) {
    this.searchTeamKeyword = event.term;
    this.searchTeamObserver.next();
  }

  clearTeamFilter() {
    this.searchTeamKeyword = null;
    this.searchTeams();
  }

  async searchTeams() {
    this.teamOptions = [];
    this.teamPageNumber = 1;
    this.teamTotalPage = null;
    this.teamOptions = await this.requestTeamList(this.teamPageNumber);
  }

  async onTeamScrollToEnd() {
    if (this.teamPageNumber < this.teamTotalPage) {
      this.teamPageNumber++;

      let teams = await this.requestTeamList(this.teamPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.teamOptions = [...this.teamOptions, ...teams];
    }
  }

  private async requestTeamList(pageNumber?: number) {
    const request = new JM.JMRequestContractsGetVendorTeamList();

    request.parameters = ['_id', 'name'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.teamPageSize;

    if (this.searchTeamKeyword) {
      request.filter = { name: this.searchTeamKeyword };
    }

    this.isLoadingTeam = true;
    const response: JM.JMResponseContractsGetVendorTeamList = await AppDelegate.sendJMRequest(request);
    this.isLoadingTeam = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.teamTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    return response.payload.records.map((team) => {
      return { value: team._id, label: team.name };
    });
  }


  //------------------------
  // equipment ng select handler
  onSearchEquipment(event) {
    this.searchEquipmentKeyword = event.term;
    this.searchEquipmentObserver.next();
  }

  clearEquipmentFilter() {
    this.searchEquipmentKeyword = null;
    this.searchEquipments();
  }

  async searchEquipments() {
    this.equipmentOptions = [];
    this.equipmentPageNumber = 1;
    this.equipmentTotalPage = null;
    this.equipmentOptions = await this.requestEquipmentList(this.equipmentPageNumber);
  }

  async onEquipmentScrollToEnd() {
    if (this.equipmentPageNumber < this.equipmentTotalPage) {
      this.equipmentPageNumber++;

      let equipments = await this.requestEquipmentList(this.equipmentPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.equipmentOptions = [...this.equipmentOptions, ...equipments];
    }
  }

  private async requestEquipmentList(pageNumber?: number) {
    const request = new JM.JMRequestEquipmentsEquipmentSummary();

    request.parameters = ['equipmentNumber', 'description'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.equipmentPageSize;

    if (this.searchEquipmentKeyword) {
      request.filter = { equipmentNumber: this.searchEquipmentKeyword };
    }

    this.isLoadingEquipment = true;
    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingEquipment = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.equipmentTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    return response.payload.records.map((equipment) => {
      return {
        value: equipment.equipmentNumber,
        label: `${equipment.equipmentNumber} ${equipment.description}`,
      };
    });
  }

  //------------------------
  // location ng select handler
  onSearchLocation(event) {
    this.searchLocationKeyword = event.term;
    this.searchLocationObserver.next();
  }

  clearLocationFilter() {
    this.searchLocationKeyword = null;
    this.searchLocations();
  }

  async searchLocations() {
    this.locationOptions = [];
    this.locationPageNumber = 1;
    this.locationTotalPage = null;
    this.locationOptions = await this.requestLocationList(this.locationPageNumber);
  }

  async onLocationScrollToEnd() {
    if (this.locationPageNumber < this.locationTotalPage) {
      this.locationPageNumber++;

      let locations = await this.requestLocationList(this.locationPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.locationOptions = [...this.locationOptions, ...locations];
    }
  }

  private async requestLocationList(pageNumber?: number) {
    const request = new JM.JMRequestLocationsLocationSummary();

    request.locationOnly = true;
    request.parameters = ['code', 'description'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.locationPageSize;

    if (this.searchLocationKeyword) {
      request.locationDescription = this.searchLocationKeyword;
    }

    this.isLoadingLocation = true;
    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingLocation = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.locationTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    return response.payload.records.map((location) => {
      const lang = Session.selectedLanguage;
      const description = location.description[lang] ? location.description[lang] : location.description.en;
      return {
        value: location.code,
        label: `${description} (${location.code})`,
      };
    });
  }

  //------------------------
  // event
  onClickDownload() {
    const searchCriteria = this.getSearchCriteria(this.searchSelections);
    this.isCreationPeriodError = false;

    if (!searchCriteria || !searchCriteria.jobCreationTimeTo || !searchCriteria.jobCreationTimeFrom) {
      this.popUpDonwloadErrorDialog('component.cm-task-search-criteria.error-msg.fill-creation-time');
      this.isCreationPeriodError = true;
      return;
    }

    const fromDate = searchCriteria.jobCreationTimeFrom;
    const toDate = searchCriteria.jobCreationTimeTo;

    if (toDate.getTime() < fromDate.getTime()) {
      this.popUpDonwloadErrorDialog('component.cm-task-search-criteria.error-msg.wrong-creation-time');
      this.isCreationPeriodError = true;
      return;
    }

    const diffMilliSeconds = Math.round(toDate.getTime() - fromDate.getTime());
    const diffDays = diffMilliSeconds / (1000 * 60 * 60 * 24);
    
    if (diffDays >= 31) {
      this.popUpDonwloadErrorDialog('component.cm-task-search-criteria.error-msg.creation-time-over-period');
      this.isCreationPeriodError = true;
      return;
    }

    this.onDownload.emit(searchCriteria);
    // this.saveFilterOptionsToLocal();
  }

  onClickSearch() {
    this.emitSearchEvent();
    // this.saveFilterOptionsToLocal();
  }

  onClickReset() {
    this.resetFilters();
    this.emitSearchEvent();
    // this.saveFilterOptionsToLocal();
  }

  // date picker handler
  onBlurDateInput(event) {
    this.isCreationPeriodError = false;
    
    if (event.fieldName == 'startDate') {
      this.startDateNgb = event.date;
      this.searchSelections.startDate = ngbDateToString(event.date);
    } else if (event.fieldName == 'endDate') {
      this.endDateNgb = event.date;
      this.searchSelections.endDate = ngbDateToString(event.date);
    }

    const valid = this.validateTimePeriod(this.searchSelections.startDate, this.searchSelections.endDate);
    this.showCreationTimeErrorMsg = !valid;
  }

  private getSearchCriteria(searchSelections: SearchSelectionI) {
    let criteria: CmTaskSearchCriteriaI = {};

    if (!!searchSelections.snNumber || !!searchSelections.jobCardNumber) {
      criteria.filter = {
        snNumber: !!searchSelections.snNumber ? searchSelections.snNumber : undefined,
        jobCardNumber: !!searchSelections.jobCardNumber ? searchSelections.jobCardNumber : undefined,
      }
    }

    criteria.clientList = !!searchSelections.client ? [searchSelections.client] : undefined;
    criteria.contractNumberList = !!searchSelections.contracts ? searchSelections.contracts : undefined;
    criteria.vendorTeamIdList = !!searchSelections.handlingTeam ? [searchSelections.handlingTeam] : undefined;
    criteria.equipmentNumberList = !!searchSelections.equipment ? [searchSelections.equipment] : undefined;
    criteria.locationCodeList = !!searchSelections.location ? [searchSelections.location] : undefined;
    
    const timeFrom = stringToMoment(searchSelections.startDate, 'YYYYMMDD');
    const timeTo = stringToMoment(searchSelections.endDate, 'YYYYMMDD');
    criteria.jobCreationTimeFrom = timeFrom ? timeFrom.toDate() : undefined;
    criteria.jobCreationTimeTo = timeTo ? timeTo.add(1, 'd').subtract(1, 'ms').toDate() : undefined;

    const status = [];
    for (const key in searchSelections.status) {
      searchSelections.status[key] && status.push(key);
    }
    criteria.statusList = status.length > 0 ? status : undefined;

    return criteria;
  }

  private validateTimePeriod(fromNgbDate?: String, toNgbDateDate?: String) {
    if (fromNgbDate && toNgbDateDate) {
      return toNgbDateDate >= fromNgbDate ? true : false;
    }

    return true;
  }

  private popUpDonwloadErrorDialog(errorMsgKey: string) {
    const data = {
      msg: {
        content: errorMsgKey,
      },
      buttons: [
        {
          name: 'global.ok',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(data);
  }

  private emitSearchEvent() {
    this.isCreationPeriodError = false;
    const searchCriteria = this.getSearchCriteria(this.searchSelections);
    this.onSearch.emit(searchCriteria);
  }

  //------------------------
  // cache function
  private saveFilterOptionsToLocal = (): void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.searchSelections));
      Session.setCmTaskSettingsSearchFilters(cache);
    } catch (e) {
      console.warn(e);
    }
  };

  private loadFilterOptionsFromLocal = (): void => {
    try {
      let cache = Session.getCmTaskSettingsSearchFilters();
      if (cache) {
        let cacheParsed = JSON.parse(JSON.stringify(cache));
        this.searchSelections = {
          ...this.searchSelections,
          ...cacheParsed,
        };
        Session.setCmTaskSettingsSearchFilters(null);
      }
    } catch (e) {
      console.warn(e);
    }
  };
}


interface SearchSelectionI {
  snNumber: string;
  jobCardNumber: string;
  status: { [key: number]: boolean };
  startDate: string; // job creation time from
  endDate: string; // job creation time to
  client: string; // clientShortName
  contracts: string[]; // contract
  handlingTeam: string; // vendorTeam
  equipment: string; // equipmentNumber
  location: string; // locationCode
}

export interface CmTaskSearchCriteriaI {
  filter?: {
    snNumber?: string,
    jobCardNumber?: string,
  },
  clientList?: string[],
  contractNumberList?: string[],
  vendorTeamIdList?: string[],
  equipmentNumberList?: string[],
  locationCodeList?: string[],
  statusList?: number[],
  jobCreationTimeFrom?: Date,
  jobCreationTimeTo?: Date,
}