<div class="font-size-xl m-0 mb-2">
  <div class="overflow-hidden">
    <div class="d-inline-block">
      {{ 'component.pm-job-description.title' | translate }}
      <span *ngIf="job?.pmJobNumber && job?.status" id="pm-job-status" class="font-size-l px">
        ({{ 'pm-job.status.' + job.status | translate }})
      </span>
    </div>

    <div class="overflow-hidden d-inline-block float-right">
      <span id="job-summary-job-number" *ngIf="job?.pmJobNumber">
        {{ job.pmJobNumber }}
      </span>
      <span id="empty-job-number" *ngIf="!job?.pmJobNumber">
        {{ 'component.pm-job-description.job-number' | translate }}
      </span>
    </div>
  </div>
</div>

<hr class="underline mb-0" />

<ng-container *ngIf="pageMode == JMPageMode.VIEW">
  <div class="row mx-0 align-items-center position-relative">
    <div class="mandatory-label font-size-m">
      <span class="mandatory-mark">*</span>{{ 'global.mandatory-fields' | translate }}
    </div>

    <div class="col-2 text-sm-right gray-title mt-3 mb-3">
      <span class="mandatory-mark">*</span>
      {{ 'component.pm-job-description.pm-plan' | translate }}
    </div>
    <div class="col-10 text-left overflow-auto">
      <app-display-text-field [textField]="pmPlanNumberStatusString"> </app-display-text-field>
    </div>

    <div class="col-2 text-sm-right gray-title mt-3 mb-3">
      {{ 'component.pm-plan-summary.schedule-type' | translate }}
    </div>
    <div class="col-10 text-left overflow-auto">
      <app-display-text-field [textField]="parseScheduleType(job?.pmPlan?.scheduleType)"> </app-display-text-field>
    </div>

    <div class="col-2 text-sm-right gray-title mt-3 mb-3">
      <span class="mandatory-mark">*</span>
      {{ 'component.pm-job-description.pm-period' | translate }}
    </div>
    <div class="col-10 text-left overflow-auto">
      <app-display-text-field [textField]="parsePeriodToString()"> </app-display-text-field>
    </div>

    <div class="col-2 text-sm-right gray-title mt-3 mb-3">
      <span class="mandatory-mark">*</span>
      {{ 'component.pm-job-description.title' | translate }}
    </div>
    <div class="col-10 text-left overflow-auto">
      <app-display-text-field [textField]="job?.jobDescription"> </app-display-text-field>
    </div>

    <div class="col-2 text-sm-right gray-title mt-3 mb-3">
      {{ 'component.pm-job-description.handling-team' | translate }}
    </div>
    <div class="col-10 col-md-4 text-left overflow-auto">
      <app-display-text-field [textField]="job?.originVendorTeam?.name"> </app-display-text-field>
    </div>

    <div class="col-2 text-sm-right gray-title mt-3 mb-3">
      {{ 'component.pm-job-description.created-by' | translate }}
    </div>
    <div class="col-10 col-md-4 text-left overflow-auto">
      <app-display-text-field [textField]="job?.createdBy"> </app-display-text-field>
    </div>

    <div class="col-2 text-sm-right gray-title mt-3 mb-3">
      {{ 'component.pm-job-description.created-time' | translate }}
    </div>
    <div class="col-10 col-md-4 text-left overflow-auto">
      <app-display-text-field [textField]="parseDateToString(job?.createdAt)"> </app-display-text-field>
    </div>

    <div class="col-2 text-sm-right gray-title mt-3 mb-3">
      {{ 'component.pm-job-description.last-updated-time' | translate }}
    </div>
    <div class="col-10 col-md-4 text-left overflow-auto">
      <app-display-text-field [textField]="parseDateToString(job?.updatedAt)"> </app-display-text-field>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="pageMode == JMPageMode.CREATE || pageMode == JMPageMode.EDIT">
  <div class="row mx-0 align-items-center position-relative">
    <div class="mandatory-label font-size-m">
      <span class="mandatory-mark">*</span>{{ 'global.mandatory-fields' | translate }}
    </div>
    <div class="col-12 row mx-0 px-0 align-items-center">
      <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 mb-4 px-0 pl-1">
        <span class="mandatory-mark">*</span>
        {{ 'component.pm-job-description.pm-plan' | translate }}
      </div>

      <div *ngIf="inputFieldSettings?.pmPlan?.editable" class="col-6 col-sm-6 text-left m-0 mt-4 mb-4">
        <ng-select
          #pmPlanNgSelect
          id="edit-job-pm-plan"
          class="px-0"
          bindLabel="pmPlanNumber"
          [class.input-error]="inputFieldSettings?.pmPlan?.failed"
          [items]="pmPlanOptions"
          [clearable]="pageMode != JMPageMode.EDIT"
          [loading]="pmPlanOptionIsLoading"
          [(ngModel)]="job.pmPlan"
          (change)="onChangePmPlan($event)"
          (clear)="onClearPmPlan($event)"
          (search)="onSearchPmPlanOptions($event)"
          (scrollToEnd)="onScrollToEndPmPlan()"
          (open)="onOpenDropDown()"
        >
          <ng-template ng-label-tmp let-item="item">
            <span>{{ item.pmPlanNumber }}</span>
            <span> ({{ 'pm-plan.status.' + item.status | translate }})</span>
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            <span>{{ item.pmPlanNumber }}</span>
            <span> ({{ 'pm-plan.status.' + item.status | translate }})</span>
          </ng-template>
        </ng-select>
      </div>
      <div
        *ngIf="!inputFieldSettings?.pmPlan?.editable"
        class="col-6 col-sm-6 col-xl-4 text-left m-0 mt-4 mb-4 overflow-auto"
      >
        <app-display-text-field
          [textField]="job?.pmPlan?.pmPlanNumber"
          [ngClass]="{ 'input-error': inputFieldSettings?.pmPlan?.failed }"
        >
        </app-display-text-field>
      </div>
    </div>

    <ng-container *ngIf="inputFieldSettings?.pmPlan?.editable">
      <div class="col-12 row">
        <div class="col-4 col-sm-2 col-xl-2 m-0 px-0"></div>
        <div class="col-8 col-sm-10 text-left m-0 pl-4 overflow-auto font-italic">
          {{ job?.pmPlan?.planDescription }}
        </div>
      </div>
    </ng-container>

    <div class="col-12 row mx-0 px-0 align-items-center">
      <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 mb-4 px-0 pl-1">
        {{ 'component.pm-plan-summary.schedule-type' | translate }}
      </div>
      <div class="col-6 col-sm-6 col-xl-4 text-left m-0 mt-4 mb-4 overflow-auto">
        <app-display-text-field
          [textField]="parseScheduleType(job?.pmPlan?.scheduleType)"
        >
        </app-display-text-field>
      </div>
    </div>

    <div class="col-12 row mx-0 px-0 align-items-center">
      <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 mb-4 px-0 pl-1">
        {{ 'component.pm-plan-summary.frequency' | translate }}
      </div>
      <div class="col-6 col-sm-6 col-xl-4 text-left m-0 mt-4 mb-4 overflow-auto">
        <app-display-text-field [textField]="job?.pmPlan?.frequency ? parseFrequency(job?.pmPlan?.frequency) : ''">
        </app-display-text-field>
      </div>
    </div>

    <div class="col-12 row mx-0 px-0 align-items-center">
      <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 mb-4 px-0 pl-1">
        {{ 'component.pm-plan-particulars.handling-team' | translate }}
      </div>
      <div class="col-6 col-sm-6 col-xl-4 text-left m-0 mt-4 mb-4 overflow-auto">
        <app-display-text-field [textField]="job?.originVendorTeam?.name"> </app-display-text-field>
      </div>
    </div>

    <div class="col-12 row mx-0 px-0 align-items-center">
      <div class="col-4 col-sm-2 col-xl-2 text-sm-right gray-title m-0 mt-4 mb-4 px-0 pl-1">
        <span class="mandatory-mark">*</span>
        {{ 'component.pm-job-description.pm-period' | translate }}
      </div>
      <div *ngIf="inputFieldSettings?.period?.editable" class="col-6 col-sm-6 text-left m-0 mt-4 mb-4">
        <ng-select
          #pmPeriodNgSelect
          id="edit-job-pm-period"
          class="px-0"
          [class.input-error]="inputFieldSettings?.period?.failed"
          [disabled]="!job.pmPlan"
          [items]="pmPeriodOptions"
          [clearable]="false"
          [searchable]="false"
          [loading]="pmPeriodOptionIsLoading"
          [(ngModel)]="job.period"
          (change)="onChangePmPeriod($event)"
          (focus)="onOpenDropDown()"
        >
          <ng-template ng-label-tmp let-item="item">
            <span>{{ item.periodStartDate + ' - ' + item.periodEndDate }}</span>
            <span> ({{ 'pm-period.status.' + item.status | translate }})</span>
          </ng-template>

          <ng-template ng-option-tmp let-item="item">
            <span>{{ item.periodStartDate + ' - ' + item.periodEndDate }}</span>
            <span> ({{ 'pm-period.status.' + item.status | translate }})</span>
          </ng-template>
        </ng-select>
      </div>
      <div
        *ngIf="!inputFieldSettings?.period?.editable"
        class="col-6 col-sm-6 col-xl-10 text-left m-0 mt-4 mb-4 overflow-auto"
      >
        <app-display-text-field
          [textField]="parsePeriodToString()"
          [ngClass]="{ 'input-error': inputFieldSettings?.pmPlan?.failed }"
        >
        </app-display-text-field>
      </div>
    </div>

    <div class="col-12 row mx-0 px-0 align-items-center">
      <div class="col-4 col-sm-2 text-sm-right gray-title m-0 mb-4 px-0 pl-1">
        <span class="mandatory-mark">*</span>
        {{ 'component.pm-job-description.title' | translate }}
      </div>
      <div class="col-8 col-sm-10 text-left font-size-s m-0 mb-4">
        <textarea
          id="edit-pm-job-description"
          class="form-control"
          rows="2"
          [maxlength]="descriptionMaxLength"
          [(ngModel)]="job.jobDescription"
          (change)="onChangeDescription()"
          [class.input-error]="inputFieldSettings?.jobDescription?.failed"
        ></textarea>
      </div>
    </div>
  </div>
</ng-container>
