import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Util } from 'src/app/core/util';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';

@Component({
  selector: 'app-office-hour-table',
  templateUrl: './office-hour-table.component.html',
  styleUrls: ['./office-hour-table.component.scss'],
})
export class OfficeHourTableComponent implements OnInit {
  @ViewChild('copy_hours_panel', { static: false }) copyHoursPanel;
  @ViewChild('copy_hours_form', { static: false }) copyHoursForm;

  @Input() parameters: {
    team: [];
  } = {
    team: [],
  };

  copyHoursParam: {
    isLoading: boolean;
  } = {
    isLoading: false,
  };

  timeObj: any = [];
  errorFields: any = {};
  timeAlertMsg: string;

  constructor() {}

  ngOnInit(): void {
    this.timeAlertMsg = JMLanguage.translate('toast.invalid-time');
  }

  // ----------- API ----------- //

  // ----------- UI function ----------- //
  onClickCopy() {
    this.copyHoursPanel.toggle();
  }

  onClickClear() {}

  onBlurTimeInput(event) {
    this.timeObj[event.field] = event.data;
  }

  onClickSubmitTimetable() {}
}
