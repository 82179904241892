import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-display-text-field',
  templateUrl: './display-text-field.component.html',
  styleUrls: ['./display-text-field.component.scss'],
})
export class DisplayTextFieldComponent implements OnInit {
  @Input() textField: string = undefined;
  @Input() textFieldArray: string[] = [];
  @Input() translateKey: string = undefined;
  @Input() emptyHandleDisplay: string = undefined;

  constructor() {}

  ngOnInit() {}
}
