<div class="wrapper">
  <app-nav [navbarTitle]="'component.setting-contract-emsd-contact.title' | translate"></app-nav>
  <app-sidebar [contentType]="'settings'"></app-sidebar>
  
  <div id="content" class="content">
    <app-tab-menu [contentType]="'settings-contract'"></app-tab-menu>

    <div class="row">
      <div class="col-12 col-xl-6">
        <contract-dropdown (assignedContract)="onAssignedContract($event)"></contract-dropdown>
        <hr class="underline mt-2" />
        <contract-routing-coverage-list-table [contract]="selectedContract" (assignedRoutingCoverage)="onAssignedRoutingCoverage($event)"></contract-routing-coverage-list-table>
      </div>
      <div class="col-12 col-xl-6">
        <contract-emsd-contact-list [contract]="selectedContract" [routingCoverage]="selectedRoutingCriteria"></contract-emsd-contact-list>
      </div>
    </div>
      
  </div>
</div>