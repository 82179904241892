import { Component, Input, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { formatDate } from '@angular/common';
import { JM } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';

@Component({
  selector: 'contract-equipment-list',
  templateUrl: './contract-equipment-list.component.html',
  styleUrls: ['./contract-equipment-list.component.scss']
})
export class ContractEquipmentListComponent implements OnInit {

  @Input() contractNumber?: string;

  //Status
  isDownloading: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  onClickDownload() {
    this.requestContractEquipmentListExcel();
  }

  private async requestContractEquipmentListExcel() {
    let request = new JM.JMRequestEquipmentsExportContractEquipmentList();
    request.contractNumber = this.contractNumber;

    this.isDownloading = true;
    const response: JM.JMResponseJobCardsExportCmTaskSummary = await AppDelegate.sendJMRequest(request);
    this.isDownloading = false;

    if (!response) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    const dateTime = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    const fileName = `Equipments-${this.contractNumber} ${dateTime}.xlsx`;
    saveAs(response, fileName);
  }

}
