import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { NgSelectComponent } from '@ng-select/ng-select';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';
import { Constants } from 'src/constants';

@Component({
  selector: 'app-pm-job-description',
  templateUrl: './pm-job-description.component.html',
  styleUrls: ['./pm-job-description.component.scss']
})
export class PmJobDescriptionComponent implements OnInit, OnChanges {
  @Input() job;
  @Input() pageMode: JMENUM.JMPageMode;

  @ViewChild('pmPlanNgSelect', { static: false }) pmPlanNgSelect: NgSelectComponent;
  @ViewChild('pmPeriodNgSelect', { static: false }) pmPeriodNgSelect: NgSelectComponent;

  pmPlanOptions: any = [];
  pmPeriodOptions: any = [];
  errorFields: any = {};
  descriptionMaxLength: number = 1000;
  inputFieldSettings: {
    [key: string]: {
      editable?: boolean;
      mandatory?: boolean;
      failed?: boolean;
    };
  };
  JMPageMode = JMENUM.JMPageMode;
  dropDownAlertShown: boolean = false;

  // PM Plan options fields
  private searchPmPlanObserver = new Subject<any[]>();
  searchPmPlanKeyword: string;
  pmPlanOptionPageNumber: number = 1;
  pmPlanOptionPageSize: number = 100;
  pmPlanOptionMaxPageSize: number = 1;
  pmPlanOptionIsLoading: boolean = false;

  pmPeriodOptionIsLoading: boolean = false;

  constructor() {}

  async ngOnChanges(changes: SimpleChanges) {
    this.fieldsControl();
    if(this.pageMode !== JMENUM.JMPageMode.CREATE && this.pageMode !== JMENUM.JMPageMode.VIEW) {
      if (changes.job && !changes.job.isFirstChange()) {
        this.pmPlanOptions = await this.requestPMPlanOptions();
        this.autoFillFlow();
      }
    }
  }

  async ngOnInit() {
    this.fieldsControl();
    if (this.pageMode === JMENUM.JMPageMode.CREATE) {
      this.pmPlanOptions = await this.requestPMPlanOptions();
      this.autoFillFlow();
    }
    this.initSearchPmPlanObserver();
  }

  private async autoFillFlow() {
    const pmPlanNumber = this.job?.pmPlan?.pmPlanNumber;
    const periodId = this.job?.period?._id;

    if (pmPlanNumber) {
      const pmPlanResult = this.pmPlanOptions.find((el) => el.pmPlanNumber === pmPlanNumber);
      if (pmPlanResult) {
        this.job.pmPlan = pmPlanResult; // apply full plan obj to 
        await this.requestAllPmPeriodList();
        const pmPeriodResult = this.pmPeriodOptions.find((el) => el._id === periodId);
        if (pmPeriodResult) {
          this.job.period = pmPeriodResult;
        }
      }
    }
  }

  private async requestPMPlanOptions(pageNumber?: number) {    
    const request = new JM.JMRequestVpGetPmJobPmPlanOptions();
    request.pageSize = this.pmPlanOptionPageSize;
    request.pageNumber = pageNumber || 1;
    request.sortBy = 'pmPlanNumber';
    request.sortOrder = JMENUM.SortOrder.ASC;
    
    if (this.searchPmPlanKeyword) {
      request.filter = { pmPlanNumber: this.searchPmPlanKeyword };
    }

    this.pmPlanOptionIsLoading = true;
    const response: JM.JMResponseVpGetPmJobPmPlanOptions = await AppDelegate.sendJMRequest(request);
    this.pmPlanOptionIsLoading = false;

    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.pmPlanOptionMaxPageSize = Math.ceil(response.payload.totalCount / this.pmPlanOptionPageSize);
    return response.payload.records;
  }

  private async requestTeamName() {
    if (!this.job.pmPlan || !this.job.pmPlan.teamId) return;
    const request: JM.JMRequestTeamsTeamSummary = new JM.JMRequestTeamsTeamSummary();
    request.parameters = ['_id', 'name'];
    request.idList = [this.job.pmPlan.teamId];

    const response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);

    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (response.payload.records && response.payload.records.length > 0) {
      this.job.team = response.payload.records[0];
    }
  }
  
  private async requestAllPmPeriodList() {
    if (!this.job.pmPlan || !this.job.pmPlan.pmPlanNumber) {return};

    this.pmPeriodOptionIsLoading = true;

    const totalCount = await this.requestPmPeriodTotalCount();
    if (totalCount) {
      const pageSize = 100;
      const maxPageSize = Math.ceil(totalCount / pageSize);
      const promises = [];

      for (let page = 1; page <= maxPageSize; page++) {
        promises.push(this.requestPmPeriodList(page, pageSize));
      }

      await Promise.all(promises).then((results: any) => {
        this.pmPeriodOptions = results.flat();
      });
    }

    this.pmPeriodOptionIsLoading = false;
  }

  private async requestPmPeriodList(pageNumber?: number, pageSize?: number) {
    if (!this.job.pmPlan || !this.job.pmPlan.pmPlanNumber) {return};

    let request = new JM.JMRequestVpGetPmPeriodList();
    request.pmPlanNumber = [this.job.pmPlan.pmPlanNumber];
    request.pageSize = pageSize || 100;
    request.pageNumber = pageNumber || 1;

    const response: JM.JMResponseVpGetPmPeriodList = await AppDelegate.sendJMRequest(request);

    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    return response.payload.records;
  }

  private async requestPmPeriodTotalCount() {
    if (!this.job.pmPlan || !this.job.pmPlan.pmPlanNumber) {return};

    let request = new JM.JMRequestVpGetPmPeriodList();
    request.pmPlanNumber = [this.job.pmPlan.pmPlanNumber];
    request.pageSize = 1;
    request.pageNumber = 1;

    const response: JM.JMResponseVpGetPmPeriodList = await AppDelegate.sendJMRequest(request);

    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    return response.payload.totalCount;
  }

  private fieldsControl() {
    let settings = {
      pmPlan: {
        editable: true,
        mandatory: true,
      },
      period: {
        editable: true,
        mandatory: true,
      },
      jobDescription: {
        editable: true,
        mandatory: true,
      },
      team: {
        editable: false,
        mandatory: false,
      },
    };

    if (this.job.status === JMENUM.PMJobStatus.REWORKING) {
      settings.pmPlan.editable = false;
      settings.period.editable = false;
    }

    this.inputFieldSettings = settings;
  }

  public async onScrollToEndPmPlan() {
    if (this.pmPlanOptionPageNumber < this.pmPlanOptionMaxPageSize) {
      this.pmPlanOptionPageNumber++;
      const newList = await this.requestPMPlanOptions(this.pmPlanOptionPageNumber);
      if (Array.isArray(newList)) {
        this.pmPlanOptions = this.pmPlanOptions.concat(newList);
      }
    }
  }

  public onChangePmPlan(event: Event) {
    if (this.pageMode === JMENUM.JMPageMode.EDIT) {
      this.resetEquipmentList();
    }

    this.resetPeriodField();
    this.resetTeamName();
    this.requestTeamName();
    this.requestAllPmPeriodList();
  }

  // PM plan search function
  public initSearchPmPlanObserver() {
    // for search PM Plan use
    this.searchPmPlanObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(async () => {
      this.pmPlanOptions = [];
      this.pmPlanOptionPageNumber = 1;
      this.pmPlanOptionMaxPageSize = null;
      this.pmPlanOptions = await this.requestPMPlanOptions(this.pmPlanOptionPageNumber);
    });
  }

  public onSearchPmPlanOptions(event) {
    this.searchPmPlanKeyword = event.term;
    this.searchPmPlanObserver.next();
  }

  public onClearPmPlan(event) {
    this.searchPmPlanKeyword = undefined;
    this.searchPmPlanObserver.next();
  }

  public onChangePmPeriod(event) {
    if (this.pageMode === JMENUM.JMPageMode.EDIT) {
      this.resetEquipmentList();
    }
  }

  onOpenDropDown() {
    if (this.pageMode === JMENUM.JMPageMode.EDIT && !this.dropDownAlertShown) {
      this.pmPlanNgSelect.close();
      this.pmPeriodNgSelect.close();

      let popupDetail = {
        msg: { content: 'component.pm-job-description.popup.title' },
        buttons: [
          {
            name: 'global.yes',
            handler: null,
          },
          { name: 'global.no', handler: null },
        ],
      };
      AppDelegate.popUpDialog().open(popupDetail);
      this.dropDownAlertShown = true;
    }
  }

  public onChangeDescription() {}

  resetEquipmentList() {
    this.job.equipmentList = [];
  }

  resetPeriodField() {
    this.job.period = undefined;
    this.pmPeriodOptions = [];
  }

  resetTeamName() {
    this.job.team = undefined;
  }

  public clearAll() {
    for (let field in this.inputFieldSettings) {
      if (this.inputFieldSettings[field].editable) {
        this.job[field] = undefined;
        this.inputFieldSettings[field].failed = false;
      }
    }
  }

  public validate() {
    let hasErrorField = false;

    for (let field in this.inputFieldSettings) {
      let target = this.inputFieldSettings[field];
      if (target.editable && target.mandatory) {
        if (typeof this.job[field] === 'string') {
          this.job[field] = this.job[field].trim();
        }
        let isFieldFailed = this.job[field] ? false : true;
        target.failed = isFieldFailed;
        if (isFieldFailed) {
          hasErrorField = true;
        }
      }
    }

    if (hasErrorField) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.missing-mandatory-fields'));
    }

    return !hasErrorField;
  }

  parseDateToString(dateString): string {
    try {
      let momentObj = moment(dateString);
      if (Session.selectedLanguage === 'zh') {
        momentObj.locale('zh-hk');
        return momentObj.format('YYYY-MM-DD (dd)');
      } else {
        return momentObj.format('YYYY-MM-DD (ddd)');
      }
    } catch (e) {
      return null;
    }
  }

  get pmPlanNumberStatusString() {
    try {
      let pmPlanNumber = this.job.pmPlan.pmPlanNumber;
      let parsedStatus = this.job.pmPlan.status;
      if (parsedStatus) {
        parsedStatus = '(' + this.parsePmStatus(parsedStatus) + ')';
      }
      return pmPlanNumber + ' ' + parsedStatus;
    } catch (e) {
      return null;
    }
  }

  parsePmStatus(status) {
    return JMLanguage.translate(`pm-plan.status.${status}`);
  }

  parsePeriodStatus(status) {
    return JMLanguage.translate(`pm-period.status.${status}`);
  }

  parseScheduleType(type) {
    if (!type) return '';
    return JMLanguage.translate(`pm-plan.schedule-type.${type}`);
  }

  parseFrequency(frequency) {
    return JMLanguage.translate(`pm-plan.frequency.${frequency}`);
  }

  parsePeriodToString() {
    if (!this.job || !this.job.period) return null;
    return `${this.job.period.periodStartDate} - ${this.job.period.periodEndDate} (${this.parsePeriodStatus(
      this.job.period.status
    )})`;
  }
}
