import { Component, OnInit } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import * as moment from 'moment';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { ngbDateToString } from 'src/app/core/Formatter';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';
import { PmPeriodSearchCriteriaComponentSearchFiltersI } from '../shared/pm-period-search-criteria/pm-period-search-criteria.component';
import { Session } from 'src/app/core/session';
import { PmJobSearchCriteriaComponent } from '../shared/pm-job-search-criteria/pm-job-search-criteria.component'
@Component({
  selector: 'app-pm-period-list',
  templateUrl: './pm-period-list.component.html',
  styleUrls: ['./pm-period-list.component.scss'],
})
export class PmPeriodListComponent implements OnInit {
  //TableX
  tablexParam: { [key: string]: any } = {};
  tablexAllHeaders: { [key: string]: any }[];
  tablexSelectedColId: string[];
  tablexDefaultSortBy: tableXCols = tableXCols.periodStartDate;
  tablexDefaultSortOrder: JMENUM.SortOrder = JMENUM.SortOrder.DESC;

  periods: JMOBJ.PmPeriodListResponseObj[] = [];
  searchCritera: PmPeriodSearchCriteriaComponentSearchFiltersI;
  searchSelections : PmJobSearchCriteriaComponent['searchSelections'] = {
    contractNumber: [],
    pmPlanNumber: null,
    pmPeriod: null,
    pmJobNumber: null,
    jobDescription: null,
    equipmentNumber: null,
    status: {},
    scheduleType: {},
    startRange: null,
    startDate: null,
    endRange: null,
    endDate: null,
    frequency: [],
    handlingTeam: [],
    createTimeFrom: null,
    createTimeTo: null,
    createdBy: null
  }
  constructor() {}

  ngOnInit() {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }
    
    this.initTablex();
  }

  initTablex() {
    this.initAllHeaders();
    this.tablexParam = {
      pageSizeOptions: [10, 25, 100],
      currentPageSize: 10,
      currentPage: 1,
      pageCount: 0,
      enableSetPageSize: true,
      enablePagination: true,
      enableSort: true,
      sortBy: this.tablexDefaultSortBy,
      sortOrder: this.tablexDefaultSortOrder,
      enableColFilter: true,
      enableSelectedRowCount: false,
      isLoadingTable: false,
      minifyButton: true,
      // tableRow: 'd-flex',
      // tableClass: 'user-table',
      // tableWrapperClass: 'table-min-width',
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowClicked: this.onRowClicked,
      // onRowUnselected: this.onRowUnselected,
      onColFiltered: this.onColFiltered,
      onSortOrderChanged: this.onSortOrderChanged,
      content: [],
      highlightedRows: [],
      fullColNameList: this.tablexAllHeaders.map((col) => {
        return { id: col.id, name: col.name };
      }),
      headers: this.tablexAllHeaders,
      selectedColId: Object.values(tableXCols),
    };
    this.tablexSelectedColId = this.tablexParam.selectedColId;
  }

  initAllHeaders() {
    this.tablexAllHeaders = [
      {
        id: 'objId',
        name: 'pages.job-list.table-column.id',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'd-none',
      },
      {
        id: tableXCols.planNumber,
        name: 'component.pm-period-list.table-column.pm-plan-number',
        enableSort: true,
        enableFilter: false,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: tableXCols.periodIndex,
        name: 'component.pm-period-list.table-column.period-index',
        enableSort: true,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-1 px-0 text-break',
      },
      {
        id: tableXCols.periodStatus,
        name: 'component.pm-period-list.table-column.period-status',
        enableSort: true,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: tableXCols.periodStartDate,
        name: 'component.pm-period-list.table-column.period-start-date',
        enableSort: true,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: tableXCols.periodEndDate,
        name: 'component.pm-period-list.table-column.period-end-date',
        enableSort: true,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: tableXCols.periodEquipmentsNumber,
        name: 'component.pm-period-list.table-column.number-of-equipments',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-1 px-0 text-break',
      },
      {
        id: tableXCols.periodEquipmentCompleted,
        name: 'component.pm-period-list.table-column.equipment-completed',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-1 px-0 text-break',
      },
      {
        id: tableXCols.periodSchedule,
        name: 'component.pm-period-list.table-column.period-schedule',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col-1 px-0 text-break',
      },
      {
        id: tableXCols.numberOfJobs,
        name: 'component.pm-period-list.table-column.number-of-job',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Buttons,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-1 px-0 text-break',
      },
    ];
  }

  onNumberOfJobsClicked(data){
    this.clearPmJobListSettingsFiltersSession();
    this.setPmJobListSettingsFilters(data);
    this.saveFilterOptionsToLocal();
    this.toPmJobList();
  }

  private clearPmJobListSettingsFiltersSession(){
    Session.setPmJobSettingsSearchFilters(null);
  }

  private setPmJobListSettingsFilters(data){
    this.searchSelections = {
      ...this.searchSelections,
      pmPeriod: data._id? data._id:null,
      pmPlanNumber:data.pmPlanNumber? data.pmPlanNumber:null,
      contractNumber:data.pmPlanContract?.contractNumber? [data.pmPlanContract.contractNumber]:[],
    }
  }

  private saveFilterOptionsToLocal = (): void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.searchSelections));
      delete cache.frequencyOptions;
      Session.setPmJobSettingsSearchFilters(cache);
    } catch (e) {
      console.warn(e);
    }
  };

  toPmJobList() {
    AppDelegate.routeToPage(`pm/jobs`);
  }

  renderTable() {
    this.tablexParam.headers = this.tablexAllHeaders.filter((col) => {
      return this.tablexSelectedColId.includes(col.id);
    });
    this.tablexParam.content = this.periods.map((e) => {
      let differOverdue = moment(e.periodEndDate).diff(moment(), 'days'); //Logic copied from JM
      let yes = JMLanguage.translate('global.yes');
      let no = JMLanguage.translate('global.no');
      let equipmentLength =  e.equipmentCount ? e.equipmentCount : 0;
      let equipmentCompleted = e.completedEquipmentCount ? e.completedEquipmentCount : 0;
      let buttons:any = [
        { "id": "button_" + e._id, "name": 0, "class": "custom-noneOfJobButton"}
      ]
      
      if(e.pmJobList != undefined && e.pmJobList.length != 0){
        buttons = [
          { "id": "button_" + e._id, "name": e.pmJobList.length, "class": "custom-numberOfJobsButton", "onClicked": ()=>this.onNumberOfJobsClicked(e)},
        ];
      }

      let oriRow = [
        e._id,
        e.pmPlanNumber ? { url: `/pm/plans/${e.pmPlanNumber}`, value: e.pmPlanNumber } : '',
        e.index,
        JMLanguage.translate(`pm-plan.status.${e.status}`),
        e.periodStartDate,
        e.periodEndDate,
        equipmentLength,
        equipmentCompleted,
        e.periodScheduleStatus ? this.getOverdueStatusText(e.periodScheduleStatus) : '',
        buttons
      ];

      let row = [];
      oriRow.forEach((value, i) => {
        if (this.tablexSelectedColId.includes(this.tablexAllHeaders[i].id)) {
          row.push(value);
        }
      });
      return row;
    });
  }

  getOverdueStatusText(code: string) {
    switch(code) {
      case JMENUM.PMPlanPeriod.ON_SCHEDULE:
        return JMLanguage.translate('pm-period.schedule.on_schedule');
      case JMENUM.PMPlanPeriod.OVERDUE:
        return JMLanguage.translate('pm-period.schedule.overdue');
      default:
        return '';
    }
  }

  async requestTableData(pageNumber?: number, pageSize?: number): Promise<boolean> {
    this.selectRow(null);
    let request: JM.JMRequestVpGetPmPeriodList = new JM.JMRequestVpGetPmPeriodList();
    request.filter = {};
    this.tablexParam.isLoadingTable = true;
    request.sortBy = this.tablexParam.sortBy;
    request.sortOrder = this.tablexParam.sortOrder;
    request.pageNumber = pageNumber ? pageNumber : 1;
    request.pageSize = pageSize ? pageSize : this.tablexParam.currentPageSize;
    request.includeEquipmentCount = true;
    // request.parameters = Object.values(GetPmPeriodsParams);
    this.assignSearchCritera(request);

    this.periods = [];
    const response: JM.JMResponseVpGetPmPeriodList = await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return false;
    }
    this.periods = response.payload.records;
    this.tablexParam.totalCount = response.payload.totalCount;
    this.tablexParam.pageCount = Math.ceil(response.payload.totalCount / request.pageSize);
    this.tablexParam.currentPage = request.pageNumber;
    this.tablexParam.currentPageSize = request.pageSize;
    return true;
  }

  selectRow(index: number) {
    this.tablexParam.selectedRowIndex = null;
    let prevState = this.tablexParam.highlightedRows[index];
    this.tablexParam.highlightedRows = [];
    this.tablexParam.highlightedRows[index] = !prevState;
  }

  assignSearchCritera = (req: JM.JMRequestVpGetPmPeriodList) => {
    let { planNumber, periodStatus, startDate, startRange, endDate, endRange, periodSchedule } = this.searchCritera;

    //plan number
    if (planNumber && planNumber !== '') {
      req.filter.pmPlanNumber = planNumber.trim();
    }

    //period status
    req.status = [];
    Object.keys(periodStatus).forEach((e) => {
      if (periodStatus[e]) {
        req.status.push(e);
      }
    });
    if (req.status.length === 0) {
      delete req.status;
    }

    //period start date
    if (startDate) {
      if (startRange === 'on-or-before') {
        req.periodStartDateTo = ngbDateToString(startDate);
      } else if (startRange === 'after') {
        req.periodStartDateFrom = ngbDateToString(startDate);
      }
    }

    //period end date
    if (endDate) {
      if (endRange === 'on-or-before') {
        req.periodEndDateTo = ngbDateToString(endDate);
      } else if (endRange === 'after') {
        req.periodEndDateFrom = ngbDateToString(endDate);
      }
    }

    //period schedlue
    req.periodScheduleStatus = [];
    if (periodSchedule[JMENUM.PMPlanPeriod.ON_SCHEDULE]) {
      req.periodScheduleStatus.push(JMENUM.PMPlanPeriod.ON_SCHEDULE);
    }
    if (periodSchedule[JMENUM.PMPlanPeriod.OVERDUE]) {
      req.periodScheduleStatus.push(JMENUM.PMPlanPeriod.OVERDUE);
    }
  };

  resetSorting = () => {
    console.log(this.tablexParam.sortBy)
    console.log(this.tablexParam.sortOrder)
    this.tablexParam.sortBy = this.tablexDefaultSortBy;
    this.tablexParam.sortOrder = this.tablexDefaultSortOrder;
    console.log(this.tablexParam.sortBy)
    console.log(this.tablexParam.sortOrder)
  }

  onRowClicked = (index, row) => {
    this.selectRow(index);
  };

  onPageSizeClicked = async (pageSize: number) => {
    await this.requestTableData(1, pageSize);
    this.renderTable();
  };

  onPageNumberClicked = async (pageNumber: number) => {
    await this.requestTableData(pageNumber);
    this.renderTable();
  };

  onColFiltered = (selectedColId) => {
    this.tablexSelectedColId = selectedColId;
    this.renderTable();
  };

  onAdvancedSearch = async (searchCritera) => {
    this.searchCritera = searchCritera;
    await this.requestTableData(1);
    this.renderTable();
  };

  onSortOrderChanged = async (header, sortOrder) => {
    this.tablexParam.sortBy = header ? header : this.tablexDefaultSortBy;
    this.tablexParam.sortOrder = sortOrder;
    await this.requestTableData(1);
    this.renderTable();
  };

  onClickCreatePMTask = () => {
    alert('coming soon');
  };

  get highlightedRow() {
    let highlightedIndex = this.tablexParam.highlightedRows.indexOf(true);
    if (highlightedIndex === -1) {
      return null;
    } else {
      return this.tablexParam.content[highlightedIndex];
    }
  }
}

enum tableXCols {
  planNumber = 'pmPlanNumber',
  periodIndex = 'index',
  periodStatus = 'status',
  periodStartDate = 'periodStartDate',
  periodEndDate = 'periodEndDate',
  periodEquipmentsNumber = 'periodEquipmentsNumber',
  periodEquipmentCompleted = 'periodEquipmentCompleted',
  periodSchedule = 'periodSchedule',
  numberOfJobs = 'numberOfJobs',
}
