import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Constants } from 'src/constants';

@Component({
  selector: 'contract-dropdown',
  templateUrl: './contract-dropdown.component.html',
  styleUrls: ['./contract-dropdown.component.scss']
})
export class ContractDropdownComponent implements OnInit {
  @Input() contractParameterList: string[] = ['contractNumber'];

  @Output() assignedContract = new EventEmitter<JMOBJ.MaintenanceTermContract>();
  
  selectedContract?: JMOBJ.MaintenanceTermContract;
  contractList: JMOBJ.MaintenanceTermContract[] = [];
  isLoadingContractList = false;

  private contractSearch: {
    keywords: string;
    search$: Subject<any[]>;
    totalPageNumber: number;
    pageNumber: number;
    pageSize: number;
  } = {
    search$: new Subject<any[]>(),
    keywords: '',
    totalPageNumber: 0,
    pageNumber: 1,
    pageSize: 100,
  };

  constructor() { }

  ngOnInit(): void {
    this.setContractSearchObserver();
    this.requestContractList(false).then(() => {
      if (this.contractList.length) {
        this.selectedContract = this.contractList[0];
        this.emitContract(this.selectedContract);
      }
    });
  }

  // ----------- API ----------- //
  private async requestContractList(isAppend: boolean = false) {
    let request = new JM.JMRequestContractsGetContractList();
    request.parameters = this.contractParameterList;
    request.pageSize = this.contractSearch.pageSize;
    request.pageNumber = this.contractSearch.pageNumber;

    if (!!this.contractSearch.keywords) {
      request.filter = {
        contractNumber: this.contractSearch.keywords,
      };
    }

    this.isLoadingContractList = true;
    const response: JM.JMResponseContractsGetContractList = await AppDelegate.sendJMRequest(request);
    this.isLoadingContractList = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.contractSearch.totalPageNumber = Math.ceil(response.payload.totalCount / request.pageSize);
    if (isAppend) {
      this.contractList = this.contractList.concat(response.payload.records);
    } else {
      this.contractList = response.payload.records;
    }
  }

  // ----------- Contract dropdown event ----------- //
  onClearContract() {
    this.clearContractOption();
    this.contractSearch.keywords = null;
    this.requestContractList(false);
  }

  onChangedContract(contract: JMOBJ.MaintenanceTermContract) {
    this.emitContract(contract);
  }

  onContractScrollToEnd() {
    if (this.contractSearch.pageNumber < this.contractSearch.totalPageNumber) {
      this.contractSearch.pageNumber++;
      this.requestContractList(true);
    }
  }

  onSearchContract(event) {
    this.contractSearch.keywords = event.term;
    this.contractSearch.search$.next();
  }

  private emitContract(contract: JMOBJ.MaintenanceTermContract) {
    this.assignedContract.emit(contract);
  }

  private clearContractOption() {
    this.contractList = [];
    this.contractSearch.pageNumber = 1;
    this.contractSearch.totalPageNumber = 0;
  }

  private setContractSearchObserver() {
    this.contractSearch.search$.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.clearContractOption();
      this.requestContractList();
    });
  }

}
