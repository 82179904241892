import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from 'src/app/core/base';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { TabMenuItems } from 'src/app/shared/tab-menu/tab-menu-items';
import { UserHelper } from 'src/app/core/user-helper';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';

@Component({
  selector: 'setting-subpage',
  templateUrl: 'settings-subpage.component.html',
  styleUrls: ['settings-subpage.component.scss'],
})
export class SettingsSubpageComponent extends BasePage implements OnInit {
  tabMenuMap = {
    '/settings/general': 'settings-general',
    '/settings/contract': 'settings-contract',
  };

  constructor(private injector: Injector) {
    super(injector, true);
  }

  ngOnInit() {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }

    /* 
      ****************************
      Redirect to first tab
      ****************************
    */

    let route = AppDelegate.getRoute();
    let tabMenu = this.tabMenuMap[route];

    if (!tabMenu) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.permission-denied'));
      AppDelegate.routeToPage('/home');
      return;
    }

    let subPages = TabMenuItems[this.tabMenuMap[route]].map((item) => item.route);

    for (let route of subPages) {
      if (UserHelper.hasPagePermission(route)) {
        AppDelegate.routeToPage(route);
        return;
      }
    }
  }
  //-------------------------------
}
