import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { JMENUM, JMOBJ, JMREASON } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';


@Component({
  selector: 'app-pm-job-remarks',
  templateUrl: './pm-job-remarks.component.html',
  styleUrls: ['./pm-job-remarks.component.scss']
})
export class PmJobRemarksComponent implements OnInit {
  @Input() job: JMOBJ.PmJob;

  messages: {
    title: string;
    details: string;
  }[] = [];

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.job) {
      this.parseMessages();
    }
  }

  parseMessages() {
    this.messages = [];

    let cancelCode = this.job && this.job.cancelReason;
    let cancelRemarks = this.job && this.job.cancelRemarks;
    if (cancelCode) {
      let cancelDescription = JMREASON.PM_JOB_CANCEL_REASON_LIST.getDescription(cancelCode);
      let cancelDescriptionWithLang = '';
      if (cancelDescription) {
        cancelDescriptionWithLang =
          Session.selectedLanguage === JMENUM.Language.ZH ? cancelDescription.zh : cancelDescription.en;
      }
      let message = {
        title: JMLanguage.translate('component.pm-job-remarks.message.cancel-reason'),
        details: `[${cancelDescriptionWithLang}] ${cancelRemarks}`,
      };

      this.messages.push(message);
    }

    let rejectCode = this.job && this.job.rejectCode;
    let rejectRemarks = this.job && this.job.rejectRemarks;
    if (rejectCode) {
      let rejectDescription = JMREASON.PM_PLAN_REJECT_APPROVAL_REASON_LIST.getDescription(rejectCode);
      let rejectDescriptionWithLang = '';
      if (rejectDescription) {
        rejectDescriptionWithLang =
          Session.selectedLanguage === JMENUM.Language.ZH ? rejectDescription.zh : rejectDescription.en;
      }
      let message = {
        title: JMLanguage.translate('component.pm-job-remarks.message.reject-reason'),
        details: `[${rejectDescriptionWithLang}] ${rejectRemarks}`,
      };

      this.messages.push(message);
    }
  }
}
