import { Component, OnInit, Input } from '@angular/core';
import { JMENUM, JMREASON } from '@ccep/CCEPConnector-ts';
import { Constants } from 'src/constants';
import { Session } from 'src/app/core/session';

@Component({
  selector: 'app-pm-plan-remarks',
  templateUrl: './pm-plan-remarks.component.html',
  styleUrls: ['./pm-plan-remarks.component.scss'],
})
export class PmPlanRemarksComponent implements OnInit {
  @Input() plan;
  @Input() pageMode: JMENUM.JMPageMode;

  // Validation
  valid: boolean;
  errorFields: any = {};
  editable: any = {};
  mandatory: any = {};

  // Input Fields
  remarks: string;
  remarksMaxLength: number = Constants.TEXTAREA_MAX_LENGTH;

  // model
  rejectRemarks: string;
  referRemarks: string;

  constructor() {}

  ngOnInit() {}

  ngOnChanges() {
    if (this.pageMode !== JMENUM.JMPageMode.VIEW) {
      this.fieldsControl();
    }

    if (this.plan.rejectCode) {
      const { rejectCode, rejectRemarks } = this.plan;
      const description = JMREASON.PM_PLAN_REJECT_APPROVAL_REASON_LIST.getDescription(rejectCode);
      this.rejectRemarks = `[${description[Session.selectedLanguage]}] ${rejectRemarks}`;
    }
    if (this.plan.referRemarks) {
      this.referRemarks = this.plan.referRemarks;
    }
  }

  // ----------- API ----------- //

  // ----------- UI function ----------- //
  private fieldsControl() {
    if (this.pageMode == JMENUM.JMPageMode.CREATE) {
      this.editable = {
        remarks: true,
      };
    } else if (this.pageMode == JMENUM.JMPageMode.EDIT) {
      if (this.plan.status === JMENUM.PMStatus.DRAFT) {
        this.editable = {
          remarks: true,
        };
      } else {
        this.editable = {
          remarks: false,
        };
      }
    }
    this.mandatory = { ...this.editable };
  }

  onChangeRemarks() {
    this.plan['remarks'] = this.plan['remarks'].trim();
  }

  public clearAll() {
    this.plan['remarks'] = undefined;
  }
}
