import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from 'src/app/shared/tablex/tablexColumnType';
import * as moment from 'moment';
import { saveAs } from "file-saver";
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Constants } from 'src/constants';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';

@Component({
  selector: 'app-cm-task-attachment',
  templateUrl: './cm-task-attachment.component.html',
  styleUrls: ['./cm-task-attachment.component.scss']
})
export class CmTaskAttachmentComponent implements OnInit {
  @ViewChild('attachment_preview', { static: true }) attachmentPreview;
  @ViewChild('upload_previewer', { static: true }) uploadPreviewer;
  
  snNumber: string;
  jobCardNumber: string;
  jobCard: JMOBJ.JobCard;

  file: any;
  fileList: any = {};
  fileDetails: any = {};

  attachment: JMOBJ.Attachment;
  selectedAttachment: any;
  attachmentList: any = [];

  uploadPreviewParam: any = {};

  navbarTitle: string;
  breadcrumbs: any = [];
  tablexParam: any = {};

  isDisabledAddAttachment: boolean = false;
  enableRemoveAttachment: boolean = true;
  isPreviewerLoading: boolean = false;
  hasViewPermission: boolean = false;
  hasEditPermission: boolean = true;
  isShowUploadPreview: boolean = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    const route = AppDelegate.getRoute();
    const trimRoute = route.replace(/^(\/cm-task)(\/.*)(\/.*)(\/attachment)$/g, '$1$4');
    if (!AppDelegate.checkPagePermissions(trimRoute)) {
      return;
    }
    
    this.snNumber = this.route.snapshot.paramMap.get('snNumber');
    this.jobCardNumber = this.route.snapshot.paramMap.get('jobCardNumber');

    this.initBreadCrumbs();
    this.initTable();
    this.fetchJobData();
  }

  initBreadCrumbs() {
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-sn-number',
        name: this.snNumber,
        route: `/cm-task/${this.snNumber}`
      },
      {
        id: 'breadcrumbs-job-card-number',
        name: this.jobCardNumber,
        route: `/cm-task/${this.snNumber}/${this.jobCardNumber}`
      },
      {
        id: 'breadcrumbs-attchment',
        name: JMLanguage.translate('action.button.attachment'),
        route: null,
        currentPage: true
      }
    ];
  }

  initPermission(actions:JMENUM.VpCmTaskAction[]) {
    if(!(Array.isArray(actions) && actions.includes(JMENUM.VpCmTaskAction.ADD_ATTACHMENT))) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.permission-denied'));
      AppDelegate.routeToPage('/home');
      return;
    }

    if(!(Array.isArray(actions) && actions.includes(JMENUM.VpCmTaskAction.UPDATE))) {
      this.enableRemoveAttachment = false;
    }
  }

  // ----------- API ----------- //
  private async fetchJobData(){
    const request = new JM.JMRequestJobCardsGetCmTask();
    request.jobCardNumber = this.jobCardNumber;

    this.tablexParam.isLoadingTable = true;
    const response: JM.JMResponseJobCardsGetCmTask = await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (response.payload) {
      this.initPermission(response.payload.actionList);
      this.updateJobCard(response.payload.jobCard);
      this.initUploadPreviewer();
    }
  }

  private async requestRemoveAttachment(attachmentId, button?) {
    let request = new JM.JMRequestJobCardsVpCmTaskRemoveAttachment();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    request.attachmentIds = [attachmentId];

    if (button) button.isLoading = true;
    let response: JM.JMResponseJobCardsVpCmTaskRemoveAttachment = await AppDelegate.sendJMRequest(request);
    if (button) button.isLoading = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.updateJobCard(response.payload);
    this.setPreviewContent(undefined);
    AppDelegate.toastMsg().showMsg(JMLanguage.translate("global.removed"));
  }

  private async requestUploadAttachment(files: Array<File>) {
    if (!(Array.isArray(files) && files.length > 0)) return;

    this.uploadPreviewParam.isLoadingAddAttachment = true;
    
    // do not use parallel call method as will face job version problem
    // const requestPromises = [];
    // for (let file of files) {
    //   requestPromises.push(this.pmJobService.uploadAttachment(this.pmJob, file, file.name));
    // }
    // let results = await Promise.all(requestPromises);

    let updatedJobCard;
    for (let file of files) {
      try {
        const request = new JM.JMRequestJobCardsVpCmTaskUploadAttachment();
        const formData = new FormData();
        formData.append('uploadData', file, file.name);
        formData.append('jobCardNumber', this.jobCard.jobCardNumber);
        formData.append('version', this.jobCard.version.toString());
        formData.append('description', file.name);
    
        this.uploadPreviewParam.isLoadingAddAttachment = true;
        let response: JM.JMResponseJobCardsVpCmTaskUploadAttachment = await AppDelegate.sendJMFileRequest(request, formData);
        this.uploadPreviewParam.isLoadingAddAttachment = false;
    
        if (!response || !response.code || response.code != 200 || !response.payload) {
          AppDelegate.toastMsg().showResponseMsg(response);
        }
        updatedJobCard = response.payload;
        this.jobCard.version = response.payload.version;
        
      } catch (err) { console.error(err); }
    }

    this.uploadPreviewParam.isLoadingAddAttachment = false;
    AppDelegate.toastMsg().showMsg(JMLanguage.translate("global.saved"));
    
    updatedJobCard && this.updateJobCard(updatedJobCard);

    // do not use parallel call method as will face job version problem
    // if(results.length == 1) {
    //   this.updateJobCard(results[0].payload.pmJob);
    // } else {
    //   this.fetchJobData();
    // }
    this.uploadPreviewer.clearUploadTable();
    this.uploadPreviewer.closeUploadPreview();
  }

  private async requestGetFile(attachmentId, download?, button?) {
    const request = new JM.JMRequestFilesGetFile(attachmentId);

    this.isPreviewerLoading = download ? false : true;
    if (button) button.isLoading = true;
    const response: JM.JMResponseFilesGetFile = await AppDelegate.sendJMRequestWithFileHost(request);
    if (button) button.isLoading = false;
    this.isPreviewerLoading = false;

    if (!response || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (download) {
      saveAs(response.payload, attachmentId);
    } else {
      this.setPreviewContent(response.payload);
    }
  }

  // ----------- UI function ----------- //
  updateJobCard(jobCard) {
    this.jobCard = jobCard;
    
    if (this.jobCard.attachments && this.jobCard.attachments.length > 0) {
      this.jobCard.attachments = this.jobCard.attachments.reverse();
      this.attachmentList = this.jobCard.attachments;
      this.requestGetFile(this.attachmentList[0].attachmentId);
      this.selectedAttachment = this.attachmentList[0];
    }else{
      this.attachmentList = [];
    }
    this.renderTable();
  }

  setPreviewContent(file) {
    if(file){
      this.file = file;
      this.fileDetails = {
        fileName: this.selectedAttachment.attachmentId,
        description: this.selectedAttachment.description,
        fileSize: '',
        uploadedBy: this.selectedAttachment.uploadedBy,
        uploadDate: moment(this.selectedAttachment.uploadedTime).format(Constants.DATETIME_FORMAT_2),
      };

      this.fileDetails.fileSize = this.attachmentPreview.getFileSize(this.file.size);
    }else{
      this.file = undefined;
      this.fileDetails = {};
    }
    
  }

  // ----------- Button function ----------- //
  onClickSubmitAttachment(files: Array<File>) {
    this.requestUploadAttachment(files);
  }

  onDownloadButtonClicked = (button) => {
    this.requestGetFile(button.attachment.attachmentId, true, button)
  }

  onDeleteButtonClicked = (button) => {
    let data = {
      msg: {
        content: 'component.attachment.remove-msg',
      },
      buttons: [
        {
          name: "global.yes",
          handler: () => {
            this.requestRemoveAttachment(button.attachment.attachmentId, button);
          }
        },
        { name: "global.no" }
      ]
    }
    AppDelegate.popUpDialog().open(data);
  }

  // ----------- Init Component ----------- //
  initUploadPreviewer() {
    this.uploadPreviewParam = {
      isDisabledAddAttachment: !this.hasEditPermission,
      isLoadingAddAttachment: false,
      hasEditPermission: this.hasEditPermission,
    }
  }

  // ----------- Tablex UI function ----------- //
  onRowClicked = (index: number, row: any) => {
    this.selectedAttachment = this.attachmentList[index];
    this.requestGetFile(this.selectedAttachment.attachmentId);
  }

  renderTable() {
    this.tablexParam.content = this.attachmentList.map(attachment => {
      let buttons = [
        { "id": "download-button_" + attachment._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": attachment },
      ];
      if (this.hasEditPermission) {
        buttons = [
          { "id": "download-button_" + attachment._id, "name": "", "class": "download-button btn p-1", "icon": "fas fa-download font-size-xl", "onClicked": this.onDownloadButtonClicked, "attachment": attachment },
        ];
        this.enableRemoveAttachment && buttons.push({
          "id": "delete-button_" + attachment._id,
          "name": "",
          "class": "delete-button btn p-1",
          "icon": "fas fa-times font-size-l",
          "onClicked": this.onDeleteButtonClicked,
          "attachment": attachment
        });
      }
      return [
        attachment._id,
        attachment.description,
        buttons
      ];
    });
  }

  // ------ Tablex ------ //
  initTable() {
    this.tablexParam = {
      isLoadingTable: true,
      tableRow: "row",
      tableClass: "attachment-table-wrapper",
      tableWrapperClass: "",
      enableSetPageSize: false,
      enablePagination: false,
      onRowClicked: this.onRowClicked,
      headers: [
        {
          id: '_id',
          name: 'id',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "d-none"
        },
        {
          id: 'filename',
          name: 'component.attachment.fileName',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col link-style"
        },
        {
          id: 'action',
          name: 'component.attachment.action',
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col-3 justify-content-around"
        }
      ],
      content: []
    };
  }
  // ------ Tablex End ------ //
}
