import { Component, OnInit, Injector, Input } from '@angular/core';
import { Router } from '@angular/router';
import { JMLanguage, Language } from 'src/app/core/JMLanguage/JMLanguage';
import { UserHelper } from 'src/app/core/user-helper';
import { Session } from 'src/app/core/session';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { breadcrumbsComponentI } from '../breadcrumbs/breadcrumbs.component';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Input() breadcrumbs: breadcrumbsComponentI[] = [];
  @Input() navbarTitle: string;
  username: string = '';
  languageList = Language;
  currentLanguage = JMLanguage.getCurrentLanguage();
  showActionSidebarIcon : boolean = false;
  eventActionSidebar    : any;

  navItems: any[];

  constructor(injector: Injector, private router: Router) {}

  ngOnInit() {
    this.username = UserHelper.getUserName();

    this.navItems = [
      {
        id: 'nav-item-home',
        route: '/home',
        title: 'navbar.home',
      },
      {
        id: 'nav-item-settings',
        route: '/settings',
        title: 'navbar.settings',
      },
    ];

    // window.addEventListener('resize', this.changeSizeHandling);
    if (this.router.url.includes('/cm-task/') ||
      this.router.url.includes('/pm/plans/') ||
      this.router.url.includes('/pm/jobs/') ||
      this.router.url.includes('/settings/release-note/')) {
      this.showActionSidebarIcon = true;
    }
    // this.changeSizeHandling();
  }

  logout() {
    Session.clear();
    AppDelegate.routeToPage('/login');
  }

  onLanguageButtonClicked(language: Language) {
    if (this.currentLanguage != language) {
      this.currentLanguage = language;
      JMLanguage.changeLanguage(this.currentLanguage);
      Session.setSelectedLanguage(this.currentLanguage);
      window.location.href = window.location.href;
    }
  }

  changeSizeHandling = () => {
    console.log(window.innerWidth);
    if (window.innerWidth <= 1000) {
      if (!this.eventActionSidebar) {
        document.addEventListener('click', this.dismissActionSideBar);
        this.eventActionSidebar = this.dismissActionSideBar;
      }
    } else {
      if (this.eventActionSidebar) {
        document.removeEventListener('click', this.dismissActionSideBar);
        this.eventActionSidebar = null;
      }
    }
  }

  dismissActionSideBar = (e) => {
    let actionSideBar = document.getElementById("action-sidebar");

    if (actionSideBar != null
      && window.innerWidth < 1000 && e.pageX < (window.innerWidth - 96) && actionSideBar.classList.contains("active")) {
      actionSideBar.classList.remove("active");
    }
  }

  //Layout Event Function
  toggleActionSidebar() {
    let sideBar = document.getElementById("action-sidebar");
    if (sideBar.classList.contains("active")) {
      sideBar.classList.remove("active");
    } else {
      sideBar.classList.add("active");
    }
    // this.sidebarClicked.emit(true);
  }
}
