import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePage } from 'src/app/core/base';
import {
  TablexColumnType,
  TablexColumnVerticalAlign,
  TablexColumnHorizontalAlign,
} from 'src/app/shared/tablex/tablexColumnType';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Util } from 'src/app/core/util';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';
import { UserHelper } from 'src/app/core/user-helper';
import { Session } from 'src/app/core/session';
import { AuthenticationMode } from '@ccep/CCEPConnector-ts/lib/JMConnector/JMEnum';
import { PasswordErrorListComponent } from 'src/app/general-component/password-error-list/password-error-list.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'settings-user-profile',
  templateUrl: 'settings-user-profile.component.html',
  styleUrls: ['settings-user-profile.component.scss'],
})
export class SettingsUserProfileComponent extends BasePage implements OnInit {
  @ViewChild('passwordErrorList', { static: false }) passwordErrorList: PasswordErrorListComponent;
  readonly ActionType: any = {
    VIEW: 'VIEW',
    EDIT: 'EDIT',
  };

  actionMode: string = this.ActionType.VIEW;
  hasUpdatePermission: boolean = false;

  // tablex parameters
  tablexParam: any = {};

  // ng model
  userInfo: any = {};
  originUserInfo: any = {};
  teamList: any[] = [];
  contractDetails: any = {};

  errorFields: string[] = [];

  userName: string = '';
  roleText: string = '';

  mandatoryFields: string[] = ['employeeName', 'phone', 'mobile'];

  isLoadingSubmit: boolean = false;
  isLocalAccount = true;
  showPasswordError = false;


  constructor(private injector: Injector, private _activatedRoute: ActivatedRoute) {
    super(injector, true);

    this.actionMode = _activatedRoute.snapshot.queryParamMap.get("actionMode") || this.ActionType.VIEW;
  }

  ngOnInit() {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }

    this.hasUpdatePermission = UserHelper.hasPermission(JMENUM.VpPermission.PROFILE_UPDATE);

    this.userName = UserHelper.getUserName();
    let roles = UserHelper.getRoles();
    this.roleText = roles.map((role) => role.name).join(', ');

    this.initTablex();

    this.requestUserInfo();
    this.requestTeamList();

    this.isLocalAccount = (Session.userInfo.authenticationMode == AuthenticationMode.LOCAL);
  }

  // -------- Request funtion -------
  requestUpdateUser() {
    if (this.isLoadingSubmit) {
      return;
    }

    let request = new JM.JMRequestPostsUpdateInfo();
    request.name = UserHelper.getUserName();

    request.employeeName = this.userInfo.employeeName;
    request.email = this.userInfo.email;
    request.phone = this.userInfo.phone;
    request.mobile = this.userInfo.mobile;
    request.oldPassword = this.userInfo.oldPassword;
    request.newPassword = this.userInfo.newPassword;
    request.reminderLanguage = this.userInfo.reminderLanguage;

    this.isLoadingSubmit = true;

    JM.JMConnector.sendPostsUpdateInfo(request, (error: JM.JMNetworkError, response: JM.JMResponsePostsUpdateInfo) => {
      this.isLoadingSubmit = false;

      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }

      if (!response || !response.code || response.code !== 200 || !response.payload) {
        this.toastMsg.showResponseMsg(response);
        return;
      }

      this.originUserInfo = response.payload;
      this.resetUserInfo();
      this.actionMode = this.ActionType.VIEW;

      AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.settings-user-profile.toast.udpated'));
    });
  }

  requestUserInfo() {
    let request = new JM.JMRequestPostsPostSummary();
    request.systemName = Constants.SYSTEM_NAME;
    request.name = [UserHelper.getUserName()];

    JM.JMConnector.sendPostsPostSummary(
      request,
      (error: JM.JMNetworkError, response: JM.JMResponsePostsPostSummary) => {
        if (error) {
          AppDelegate.handleJMError(error);
          return;
        }

        if (
          !response ||
          !response.code ||
          response.code !== 200 ||
          !response.payload ||
          !response.payload.records ||
          !response.payload.records.length
        ) {
          this.toastMsg.showResponseMsg(response);
          return;
        }

        this.originUserInfo = response.payload.records[0];
        this.resetUserInfo();
      }
    );
  }

  requestTeamList() {
    let request = new JM.JMRequestContractVendorTeamSummary();
    request.parameters = ['name', 'phone', 'email', 'activeStatus'];
    request.id = UserHelper.getVendorTeams();

    JM.JMConnector.sendContractsVendorTeamSummary(
      request,
      (error: JM.JMNetworkError, response: JM.JMResponseContractVendorTeamSummary) => {
        if (error) {
          AppDelegate.handleJMError(error);
          return;
        }

        if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
          this.toastMsg.showResponseMsg(response);
          return;
        }
        // !team.activeStatus for no active status in team (old data)
        this.teamList = response.payload.records.filter(team => !team.activeStatus || team.activeStatus === JMENUM.ActiveStatus.ACTIVE);

        this.renderTable();
      }
    );
  }
  // ------- Custom funtion ---------
  resetUserInfo() {
    if (!this.originUserInfo) {
      return;
    }

    this.userInfo = {
      name: this.originUserInfo.name,
      employeeName: this.originUserInfo.employeeName,
      email: this.originUserInfo.email,
      phone: this.originUserInfo.phone,
      mobile: this.originUserInfo.mobile,
      reminderLanguage: this.originUserInfo.reminderLanguage ? this.originUserInfo.reminderLanguage : 'en',
    };
  }

  trimData() {
    let numericFields = ['phone', 'mobile'];
    for (let field of numericFields) {
      if (this.userInfo[field]) {
        this.userInfo[field] = this.userInfo[field].toString().replace(/[^\d]/g, '');
      }
    }

    let textFields = ['employeeName', 'email', 'phone', 'mobile', 'oldPassword', 'newPassword'];

    for (let field of textFields) {
      if (this.userInfo[field]) {
        this.userInfo[field] = this.userInfo[field].trim();
      }
    }
  }

  isValidToSubmit() {
    this.errorFields = [];
    this.resetPasswordErrors();

    // trim data
    this.trimData();

    if (!this.isLocalAccount) {
      return true;
    }

    // check mandatory fields
    for (let field of this.mandatoryFields) {
      if (!this.userInfo[field]) {
        this.errorFields.push(field);
        continue;
      }
    }

    if (this.errorFields.length) {
      AppDelegate.toastMsg().showMsg(
        JMLanguage.translate('pages.settings-user-profile.toast.missing-mandatory-fields')
      );
      return false;
    }


    if ((this.userInfo.newPassword || this.userInfo.newPassword?.length > 0)
      && (this.userInfo.oldPassword == null || this.userInfo.oldPassword?.length == 0)) {
      this.errorFields.push('oldPassword');
      AppDelegate.toastMsg().showMsg(JMLanguage.translate("pages.settings-user-profile.toast.missing-old-password"));
      return;
    }

    if ((this.userInfo.oldPassword || this.userInfo.oldPassword?.length > 0)
      && (this.userInfo.newPassword == null || this.userInfo.newPassword?.length == 0)) {
      this.errorFields.push('newPassword');
      AppDelegate.toastMsg().showMsg(JMLanguage.translate("pages.user-profile.error.new-password-cannot-be-empty"));
      return;
    }


    if (this.userInfo.oldPassword
      && this.userInfo.newPassword
      && this.userInfo.newPassword === this.userInfo.oldPassword) {
        this.errorFields.push('oldPassword', 'newPassword');
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.user-profile.error.new-password-as-same-as-current-password'));
      return;
    }

    if (this.userInfo.newPassword != null && this.userInfo.newPassword != "" && this.userInfo.newPassword.length > 0) {
      if (!this.passwordErrorList.isPasswordVaildate(this.userInfo.newPassword)) {
        this.errorFields.push('newPassword');
        this.showPasswordError = true;
        return
      }
    }

    if (this.userInfo.newPassword
      && this.userInfo.newPassword !== this.userInfo.confirmationPassword) {
      this.errorFields.push('newPassword', 'confirmationPassword');
      AppDelegate.toastMsg().showMsg(JMLanguage.translate("pages.settings-user-profile.toast.different-password"));
      return;
    }

    // check email
    if (this.userInfo.email && !Util.isValidEmail(this.userInfo.email)) {
      this.errorFields.push('email');
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.settings-user-profile.toast.wrong-email-format'));
      return false;
    }

    return true;
  }

  // ------- Event handling function ---------
  onClickedEdit() {
    this.actionMode = this.ActionType.EDIT;
  }

  onClickedSubmit() {
    if (!this.isValidToSubmit()) {
      return;
    }
    let popupDetail = {
      msg: { content: 'popup.confirm-to-save' },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestUpdateUser();
          },
        },
        { name: 'global.no', handler: null },
      ],
    };
    AppDelegate.popUpDialog().open(popupDetail);
  }

  onClickedDiscard() {
    this.errorFields = [];
    this.resetPasswordErrors();

    this.resetUserInfo();
    this.actionMode = this.ActionType.VIEW;
  }

  // ------- Tablex function -------
  renderTable() {
    let index = 1;
    this.tablexParam['content'] = this.teamList.map((data) => {
      let contractDetail = this.contractDetails[data.contractNumber];
      return ['' + index++, Util.toString(data.name), Util.toString(data.phone), Util.toString(data.email)];
    });
  }

  initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: false,
      enablePagination: false,
      enableSort: true,
      enableColFilter: false,
      enableSelectedRowCount: false,
      tableRow: 'row',
      tableClass: 'user-table',
      tableWrapperClass: 'table-min-width',
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      content: [],
      highlightedRows: [],
      displayColId: [],
      customClassRows: [],
    };

    this.initTableHeader();
  }

  initTableHeader() {
    this.tablexParam['headers'] = [
      {
        id: 'team-name',
        name: 'pages.settings-user-profile.table-column.order',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'contract-number',
        name: 'pages.settings-user-profile.table-column.team-name',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'start-date',
        name: 'pages.settings-user-profile.table-column.phone',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'end-date',
        name: 'pages.settings-user-profile.table-column.email',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
    ];
  }

  resetPasswordErrors() {
    this.showPasswordError = false
    this.passwordErrorList.resetPasswordErrors();
  }

}
