<div class="wrapper">
  <app-nav></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="content cm-task-list-content">
    <div class="component-wrap row">
      <div class="col-12 col-xl-9 ">
        <div class="d-flex justify-content-between mb-2 align-items-center">
          <div class="font-size-xl align-self-end">
            {{ "pages.cm-task-list.title" | translate}}
          </div>
        
          <!-- general search bar-->
          <div class="d-flex px-0 col-6 justify-content-end">
            <div class="search-box col-11 px-0">
              <input type="search" class="form-control search-input"
                placeholder="{{'pages.cm-task-list.search.placeHolder' | translate}}"
                [(ngModel)]="generalSearchWords"
                (keyup)="onGeneralSearchKeyup($event, 'generalSearch')"
                (ngModelChange)="checkSearchEmpty()"
              />
              <i class="fas fa-search blue" (click)="onGeneralSearchClicked()"></i>
            </div>
          </div>
        </div>
        <hr class="underline" />

        <!-- TODO: download button-->
        <div class="col-12 px-0" *ngIf="false">
          <div class="d-flex justify-content-end mt-3">
            <app-icon-button 
              [id]="'cm-task-download-button'"
              [color]="'blue'"
              [icon]="'fa-download'"
              [isDisabled]="isDownloading || isDisabled"
              [isLoading]="isDownloading"
              (onClick)="onClickDownload()" 
            ></app-icon-button>
          </div>
        </div>
        
        <app-tablex id="cm-task-list-table" class="" [tablexParam]='tablexParam'></app-tablex>
      </div>

      <div class="col-12 col-xl-3">
        <app-cm-task-search-criteria
          [isDownloading]="isDownloading"
          (onDownload)="onDownload($event)"
          (onSearch)="onAdvancedSearch($event)"
        ></app-cm-task-search-criteria>
      </div>
    </div>
  </div>

</div>
