import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JM, JMOBJ, JMREASON } from '@ccep/CCEPConnector-ts';
import { JMReason } from '@ccep/CCEPConnector-ts/lib/JMConnector/class/Reason';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';
import { StringValidator } from 'src/app/core/string-validator';
import { Constants } from 'src/constants';

@Component({
  selector: 'pm-job-cancel-form',
  templateUrl: './pm-job-cancel-form.component.html',
  styleUrls: ['./pm-job-cancel-form.component.scss']
})
export class PmJobCancelFormComponent implements OnInit {
  @Input() job: JMOBJ.PmJob;
  @Output() cancelledPmJob = new EventEmitter<JMOBJ.PmJob>();

  // data
  cancelForm = this.fb.group({
    cancelReason: [undefined, [Validators.required]],
    cancelRemarks: ['', [Validators.required, StringValidator.noWhiteSpaceBothEnd]],
  });

  cancelReasonList: {
    code: number;
    label: string;
  }[] = [];

  submitBtn = {
    isLoading: false,
  };

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.resetCancelReasonList();
    this.resetForm();
  }

  private resetForm() {
    this.cancelForm.reset({
      cancelReason: undefined,
      cancelRemarks: '',
    });
  }

  private resetCancelReasonList() {
    const filterCriteria = [JMREASON.Attribute.VP, JMREASON.Attribute.PM];
    const filterReasonList = JMREASON.PM_JOB_CANCEL_REASON_LIST.filterReason(filterCriteria);

    this.cancelReasonList = this.getReasonList(filterReasonList);
  }

  private getReasonList(reasonList: JMReason[]) {
    return reasonList.map((reason) => {
      const description = reason.description;
      let label = '';
      if (description) {
        label = description[Session.selectedLanguage] ? description[Session.selectedLanguage] : description.en;
      }
      return {
        code: reason.code,
        label: label,
      };
    });
  }
  //------------------------
  // API
  private async requestCancelPmJob() {
    let request = new JM.JMRequestVpPmJobCancel();
    request.pmJobNumber = this.job.pmJobNumber;
    request.version = this.job.version;
    request.cancelReason = this.cancelReason.value;
    request.cancelRemarks = this.cancelRemarks.value.trim() || undefined;

    this.submitBtn.isLoading = true;
    const response: JM.JMResponseVpPmJobCancel = await AppDelegate.sendJMRequest(request);
    this.submitBtn.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.cancelled'));
    this.cancelledPmJob.emit(response.payload.pmJob);
    this.resetForm();
  }

  //------------------------
  // button callback
  onSubmitBtnClicked() {
    const data = {
      msg: {
        content: 'component.pm-job-cancel-form.pop-up.confirm-to-cancel',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestCancelPmJob();
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(data);
  }

  //------------------------
  // get property
  get cancelReason() {
    return this.cancelForm.get('cancelReason');
  }
  get cancelRemarks() {
    return this.cancelForm.get('cancelRemarks');
  }

  get inputBoxMaxLength() {
    return Constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }


}
