import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { ngbDateToString, stringToNgbDate, stringToMoment, formatDate } from 'src/app/core/Formatter';
import { AppDelegate } from 'src/app/core/AppDelegate';

@Component({
  selector: 'app-pm-plan-summary',
  templateUrl: './pm-plan-summary.component.html',
  styleUrls: ['./pm-plan-summary.component.scss'],
})
export class PmPlanSummaryComponent implements OnInit {
  @ViewChild('startDateElem', { static: false }) startDateElem;
  @ViewChild('endDateElem', { static: false }) endDateElem;
  @ViewChild('secondStartDateElem', { static: false }) secondStartDateElem;
  @Input() plan: JMOBJ.PmPlan;
  @Input() pageMode: JMENUM.JMPageMode;
  @Output() refreshActionButton: EventEmitter<any> = new EventEmitter();
  @Output() onChangeContractSelection: EventEmitter<any> = new EventEmitter<Event>();

  @Input() startDateStr: string;
  @Input() endDateStr: string;
  @Input() secondStartDateStr: string;
  @Output() startDateStrChange = new EventEmitter<string>();
  @Output() endDateStrChange = new EventEmitter<string>();
  @Output() secondStartDateStrChange = new EventEmitter<string>();
  @Output() onChangeSchTypeAndFreq: EventEmitter<any> = new EventEmitter<Event>();

  //ENUM for HTML
  // PMPlanType = JMENUM.PMPlanType;
  PMStatus = JMENUM.PMStatus;
  // PlanCoverage = JMENUM.PMPlanCoverage;
  ScheduleType = JMENUM.PMScheduleType;
  PlanPeriod = JMENUM.PMPlanPeriod;
  PMPlanFrequency = JMENUM.PMPlanFrequency;
  JMPageMode = JMENUM.JMPageMode;

  //boolean
  isDisabledSecondDate: boolean = false;
  isDisabledSLA: boolean = true;

  isLoadingContractOptions: boolean = false;

  // Validation
  valid: boolean;
  errorFields: any = {};
  editable: any = {};
  mandatory: any = {};

  // Input Fields
  contractNumber: string;
  planDescription: string;
  scheduleType: string;
  startDate: any;
  endDate: any;
  secondStartDate: any;
  frequency: string;
  // planCoverage: string;

  descriptionMaxLength: number = 1000;
  contractStartDate: any;
  contractEndDate: any;

  // Dropdown Options
  contractOptions: any = [];
  // slaOptions: any = [];
  frequencyOptions: any = [];
  frequencyUnitList: any;

  // contract options fields
  private searchTeamObserver = new Subject<any[]>();
  searchContractKeyword: string;
  contractOptionsPage: number;
  contractOptionsTotalPage: number;

  // User info
  post: any = {};

  constructor() {}

  ngOnInit() {
    this.frequencyUnitList = {
      weekly: { desc: 'weekly', timeUnit: 'w', frequencyUnit: 1 },
      bi_weekly: { desc: 'bi_weekly', timeUnit: 'w', frequencyUnit: 2 },
      monthly: { desc: 'monthly', timeUnit: 'M', frequencyUnit: 1 },
      bi_monthly: { desc: 'bi_monthly', timeUnit: 'M', frequencyUnit: 2 },
      tetra_monthly: { desc: 'tetra_monthly', timeUnit: 'M', frequencyUnit: 4 },
      half_yearly: { desc: 'half_yearly', timeUnit: 'M', frequencyUnit: 6 },
      quarterly: { desc: 'quarterly', timeUnit: 'M', frequencyUnit: 3 },
      yearly: { desc: 'yearly', timeUnit: 'y', frequencyUnit: 1 },
      bi_yearly: { desc: 'bi_yearly', timeUnit: 'y', frequencyUnit: 2 },
    };

    this.initSearchContractObserver();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.plan) {
      // wait for plan obj from API for != create page mode 
      if (this.pageMode === JMENUM.JMPageMode.CREATE || !changes.plan.firstChange) {
        if (this.pageMode != JMENUM.JMPageMode.VIEW) {
          this.fieldsControl();
          this.initOptions();
    
          if (!this.plan.planCoverage) {
            this.plan.planCoverage = JMENUM.PMPlanCoverage.EQUIPMENT;
          }
        }
        this.plan && this.initPlan();
      }
    }
    
    if (changes.startDateStr) {
      this.startDate = stringToNgbDate(this.startDateStr);
    }
    if (changes.endDateStr) {
      this.endDate = stringToNgbDate(this.endDateStr);
    }
    if (changes.secondStartDateStr) {
      this.secondStartDate = stringToNgbDate(this.secondStartDateStr);
    }
  }

  // ----------- API ----------- //
  private async requestContractList(pageNumber?: number) {
    let request: JM.JMRequestContractsGetContractList = new JM.JMRequestContractsGetContractList();
    request.parameters = [
      'contractNumber',
      'vendorStartDate',
      'vendorEndDate',
      'liftTrapped',
      'vendorNumber',
      'vendorName',
    ];
    request.pageNumber = pageNumber || 1;
    request.pageSize = 10;
    this.pageMode == this.JMPageMode.CREATE && (request.expired = 'unexpired');
    if (this.searchContractKeyword) {
      request.filter = { contractNumber: this.searchContractKeyword };
    }

    this.isLoadingContractOptions = true;
    let response: JM.JMResponseContractsGetContractList = await AppDelegate.sendJMRequest(request);
    this.isLoadingContractOptions = false;
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (pageNumber === 1) {
      let totalContractCount = response.payload.totalCount;
      this.contractOptionsTotalPage = Math.ceil(totalContractCount / request.pageSize);
    }
    if (response.payload && Array.isArray(response.payload.records)) {
      return response.payload.records.map((contract: JMOBJ.MaintenanceTermContract) => {
        return {
          label: contract.contractNumber,
          value: contract.contractNumber,
          contractStartDate: contract.vendorStartDate,
          contractEndDate: contract.vendorEndDate,
          isLiftContract: contract.liftTrapped,
          vendorNumber: contract.vendorNumber,
          vendorName: contract.vendorName,
        };
      });
    }
  }

  // ----------- UI function ----------- //
  private initPlan() {
    this.planDescription = this.plan['planDescription'];
    this.scheduleType = this.plan.scheduleType
      ? JMLanguage.translate(`pm-plan.schedule-type.${this.plan.scheduleType}`)
      : undefined;

    this.frequency = this.plan.frequency ? JMLanguage.translate('pm-plan.frequency.' + this.plan.frequency) : undefined;
  }

  private fieldsControl() {
    this.mandatory = {
      contract: true,
      planDescription: true,
      scheduleType: true,
      startDate: true,
      endDate: true,
      secondStartDate: false,
      frequency: true,
    };
    this.editable = { ...this.mandatory };

    if (!this.plan['scheduleType']) this.plan['scheduleType'] = this.ScheduleType.NORMAL;
    this.updateSecondStartDateAndFrequency();

    if (this.pageMode === JMENUM.JMPageMode.EDIT) {
      // i.e. editing existing pm plan
      if (this.plan.status === JMENUM.PMStatus.DRAFT) {
        this.editable.contract = false;
      } else {
        this.editable.contract = false;
        this.editable.scheduleType = false;
        this.editable.startDate = false;
        this.editable.endDate = false;
        this.editable.secondStartDate = false;
        this.editable.frequency = false;
        this.editable.planDescription = false;
      }
    }
  }

  private async initOptions() {
    if (this.pageMode === JMENUM.JMPageMode.CREATE) {
      // i.e. creating new pm plan
      this.contractOptionsPage = 1;
      this.contractOptions = await this.requestContractList(this.contractOptionsPage);
    } else if (this.pageMode === JMENUM.JMPageMode.EDIT) {
      this.contractStartDate = this.plan.vendor.startDate;
      this.contractEndDate = this.plan.vendor.endDate;
      this.onChangeContractSelection.emit();
    }

    let frequencyEnum = Object.values(this.PMPlanFrequency);
    this.frequencyOptions = [];
    for (let i = 0; i < frequencyEnum.length; i++) {
      this.frequencyOptions.push({
        value: frequencyEnum[i],
        label: JMLanguage.translate('pm-plan.frequency.' + frequencyEnum[i]),
      });
    }
  }

  private updateSecondStartDateAndFrequency() {
    if (this.plan.scheduleType == this.ScheduleType.OVERHAUL || this.plan.scheduleType == this.ScheduleType.NORMAL) {
      this.editable.secondStartDate = true;
      this.editable.frequency = true;
      this.mandatory.frequency = true;
    } else {
      this.editable.secondStartDate = false;
      this.editable.frequency = false;
      this.mandatory.frequency = false;
      this.plan.secondStartDate = undefined;
      this.secondStartDate = undefined;
      this.plan.frequency = undefined;
    }
  }

  public onChangeContract(event: any) {
    if (event) {
      this.plan.contract = {
        contractNumber: event.value,
        liftTrapped: event.isLiftContract,
        _id: null,
        workCentres: null,
      };
      this.plan.vendor = {
        name: event.vendorName,
        vendorNumber: event.vendorNumber,
        startDate: null,
        endDate: null,
      };

      this.contractStartDate = event.contractStartDate;
      this.contractEndDate = event.contractEndDate;

      this.endDateStrChange.emit(event.contractEndDate);
    } else {
      this.plan.contract = undefined;
      this.plan.vendor = undefined;

      this.contractStartDate = undefined;
      this.contractEndDate = undefined;
    }
    this.onChangeContractSelection.emit();
  }

  public initSearchContractObserver() {
    // for search team use
    this.searchTeamObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(async () => {
      this.contractOptions = [];
      this.contractOptionsPage = 1;
      this.contractOptionsTotalPage = null;
      this.contractOptions = await this.requestContractList(this.contractOptionsPage);
    });
  }

  public async onContractScrollToEnd() {
    if (this.contractOptionsPage < this.contractOptionsTotalPage) {
      this.contractOptionsPage += 1;
      let contracts = await this.requestContractList(this.contractOptionsPage);

      // just append will not trigger ng-select change detection, need to update array reference
      this.contractOptions = [...this.contractOptions, ...contracts];
    }
  }

  public onClearContract() {
    this.searchContractKeyword = null;
    this.searchTeamObserver.next();
  }

  public onSearchContractOptions(event) {
    this.searchContractKeyword = event.term;
    this.searchTeamObserver.next();
  }

  public onFilterTeamOptions(term: string, item: any) {
    return item.label.toLowerCase().includes(term.toLowerCase());
  }

  public onChangeDescription() {
    this.planDescription = this.planDescription.trim();
    this.plan.planDescription = this.planDescription;
  }

  public onChangeScheduleType() {
    this.updateSecondStartDateAndFrequency();
    this.onChangeSchTypeAndFreq.emit({
      scheduleType: this.plan.scheduleType,
      frequency: this.plan.frequency
    });
  }

  public onChangeFrequency() {
    this.onChangeSchTypeAndFreq.emit({
      scheduleType: this.plan.scheduleType,
      frequency: this.plan.frequency
    });
  } 

  public onBlurDateInput(event) {
    switch (event.fieldName) {
      case 'startDate':
        this.startDateStrChange.emit(ngbDateToString(event.date));
        break;
      case 'endDate':
        this.endDateStrChange.emit(ngbDateToString(event.date));
        break;
      case 'secondStartDate':
        this.secondStartDateStrChange.emit(ngbDateToString(event.date));
        break;
    }
  }

  public onChangeCoverage() {
    this.refreshActionButton.emit();
  }

  public clearAll() {
    for (let field in this.editable) {
      if (this.editable[field]) {
        switch (field) {
          case 'scheduleType':
            this.plan['scheduleType'] = this.ScheduleType.NORMAL;
            break;
          default:
            this.plan[field] = undefined;
            break;
        }
      }
    }
    this.plan.vendor = undefined;
    this.contractNumber = undefined;
    this.planDescription = undefined;
  }

  public validateStartEndDate() {
    // TODO: should not update plan field in this layer
    // show error if startDate > endDate
    if (this.startDateStr && this.endDateStr) {
      let differTime = moment(this.endDateStr).diff(moment(this.startDateStr), 'days');
      if (differTime < 0) {
        this.errorFields['startDate'] = true;
        this.errorFields['endDate'] = true;
        AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.pm-plan.alert-end-earlier-than-start'));
        return false;
      }
    }

    // show error if startDate < contractStartDate
    if (this.startDateStr) {
      let differTime = moment(this.startDateStr).diff(moment(this.contractStartDate), 'days');
      if (differTime < 0) {
        this.errorFields['startDate'] = true;
        AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.pm-plan.alert-start-earlier-than-contract-start'));
        return false;
      }
    }

    // show error if contractEndDate < endDate
    if (this.endDateStr) {
      let differTime = moment(this.contractEndDate).diff(moment(this.endDateStr), 'days', true);
      if (differTime < 0) {
        this.errorFields['endDate'] = true;
        AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.pm-plan.alert-end-later-than-contract-end'));
        return false;
      }
    }

    // show error if endDate - startDate < frequency for normal schedule type
    if (
      this.startDateStr &&
      this.endDateStr &&
      this.plan.frequency &&
      this.plan.scheduleType === this.ScheduleType.NORMAL
    ) {
      let differPeriod: any = moment(this.endDateStr).diff(
        moment(this.startDateStr),
        this.frequencyUnitList[this.plan.frequency]['timeUnit'],
        true
      );
      // e.g.: if unit is bi-weekly, then differPeriod cannot <= 2 (weeks)
      if (differPeriod <= this.frequencyUnitList[this.plan.frequency]['frequencyUnit']) {
        this.errorFields['startDate'] = true;
        this.errorFields['endDate'] = true;
        AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.pm-plan.alert-overall-period-too-short'));
        return false;
      } else {
        this.errorFields['startDate'] = false;
        this.errorFields['endDate'] = false;
      }
    }
    return true;
  }

  public validateSecondStartDate() {
    // show error if startDate > secondStartDate
    if (this.startDateStr && this.secondStartDateStr) {
      let differStartTime = moment(this.secondStartDateStr).diff(moment(this.startDateStr), 'days');
      if (differStartTime <= 0) {
        this.errorFields['startDate'] = true;
        this.errorFields['secondStartDate'] = true;
        AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.pm-plan.alert-second-start-earlier-than-start'));
        return false;
      } else {
        this.errorFields['startDate'] = false;
        this.errorFields['secondStartDate'] = false;
      }
    }

    // show error if endDate < secondStartDate
    if (this.endDateStr && this.secondStartDateStr) {
      let differEndTime = moment(this.endDateStr).diff(moment(this.secondStartDateStr), 'days');
      if (differEndTime <= 0) {
        this.errorFields['endDate'] = true;
        this.errorFields['secondStartDate'] = true;
        AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.pm-plan.alert-second-start-earlier-than-end'));
        return false;
      } else {
        this.errorFields['endDate'] = false;
        this.errorFields['secondStartDate'] = false;
      }
    }

    // show error if secondStartDate - startDate > frequency
    if (this.startDateStr && this.secondStartDateStr) {
      let differPeriod: any = moment(this.secondStartDateStr).diff(
        moment(this.startDateStr),
        this.frequencyUnitList[this.plan.frequency]['timeUnit'],
        true
      );
      // e.g.: if unit is bi-weekly, then differPeriod cannot > 2 (weeks)
      if (differPeriod > this.frequencyUnitList[this.plan.frequency]['frequencyUnit']) {
        this.errorFields['startDate'] = true;
        this.errorFields['secondStartDate'] = true;
        AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.pm-plan.alert-first-period-too-long'));
        return false;
      } else {
        this.errorFields['startDate'] = false;
        this.errorFields['secondStartDate'] = false;
      }
    }
    return true;
  }

  public validate() {
    let hasErrorField = false;
    this.errorFields = {};

    // Check fields have data
    for (let field in this.editable) {
      if (field && this.mandatory[field]) {
        this.errorFields[field] = this.plan[field] ? false : true;
      }
    }
    for (let field in this.editable) {
      if (field && this.errorFields[field]) {
        hasErrorField = true;
      }
    }

    if (hasErrorField) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.pm-plan.toast.please-fill-in-mandatory-fields'));
    }

    if (this.plan.secondStartDate) {
      hasErrorField = !this.validateStartEndDate() || !this.validateSecondStartDate() || hasErrorField ? true : false;
    } else {
      hasErrorField = !this.validateStartEndDate() || hasErrorField ? true : false;
    }

    this.valid = !hasErrorField;
    return this.valid;
  }
}
