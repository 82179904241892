import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { VPUserSettings } from '@enum/vp-user-settings.enum';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { stringToMoment } from 'src/app/core/Formatter';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';
import { Util } from 'src/app/core/util';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign
} from 'src/app/shared/tablex/tablexColumnType';
import { Constants } from 'src/constants';
import { CmTaskSearchCriteriaI } from '../shared/cm-task-search-criteria/cm-task-search-criteria.component';

@Component({
  selector: 'app-cm-task-list',
  templateUrl: './cm-task-list.component.html',
  styleUrls: ['./cm-task-list.component.scss'],
})
export class CmTaskListComponent implements OnInit {
  language: string;

  isDownloading: boolean = false;
  isDisabled: boolean = false; //For Not Ready Function

  // data
  isGeneralSearch: boolean = false;
  generalSearchWords: string;
  jobList: JMOBJ.JobCard[] = [];
  locationMap = new Map<string, JMOBJ.Location>(); // key: locationCode
  equipmentMap = new Map<string, JMOBJ.Equipment>(); // key: equipmentNumber

  // tablex parameters
  tablexParam: {} = {};
  tablexFilter: {} = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize: number = 10;
  currentPage: number = 1;
  pageCount: number = 1;
  sortBy = undefined;
  sortOrder = undefined;
  selectedColId: any = [];
  allColHeaders: any = [];
  selectedCol: any = [];
  selectedRows: any = [];
  selectedTableRows: any = [];

  // advance search data
  searchData: CmTaskSearchCriteriaI = {};

  constructor() { }

  ngOnInit(): void {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }

    this.generalSearchWords = Session.cmTaskListGeneralSearchKeyword;

    this.language = Session.selectedLanguage;
    this.initTableHeader();
    this.initSelectedCol();
    this.initTablex();

    if (this.generalSearchWords && this.generalSearchWords.length > 0) {
      this.isGeneralSearch = true;
      this.generalSearch();
    } else {
      this.isGeneralSearch = false;
    }
  }

  ngOnDestroy() {
    Session.cmTaskListGeneralSearchKeyword = null;
  }

  // ----------- API ----------- //
  requestUpdateUserSettings() {
    // TODO: get from local storage
    let userSettings = Session.userInfo['userSettings'] ? Session.userInfo['userSettings'] : {};
    let request = new JM.JMRequestPostsUpdateUserSettings();
    request.name = Session.userInfo.name;
    request.systemName = Constants.SYSTEM_NAME;
    userSettings[VPUserSettings.VP_CM_TASK_LIST_COLUMNS] = this.selectedColId;
    request.userSettings = userSettings;

    JM.JMConnector.sendPostsUpdateUserSettings(request, (error: JM.JMNetworkError, response: Object) => {
      if (error) {
        AppDelegate.toastMsg().showResponseMsg(error);
        return;
      }
    });
  }

  private async advancedSearch() {
    let request = new JM.JMRequestJobCardsGetCmTaskList();
    request.pageSize = this.currentPageSize;
    request.pageNumber = this.currentPage;
    request.sortBy = this.tablexParam['sortBy'];
    request.sortOrder = this.tablexParam['sortOrder'];
    this.renderRequestFilter(request);

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseJobCardsGetCmTaskList = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }
    this.tablexParam['isLoadingTable'] = false;

    this.jobList = response.payload.records;
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);

    this.requestAllDescription();
  }

  private async generalSearch() {
    const request = new JM.JMRequestJobCardVpCmTaskGeneralSearch();
    request.expression = this.generalSearchWords;
    request.pageNumber = this.currentPage;
    request.pageSize = this.currentPageSize;
    // request.parameters = this.snParameterList;

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseJobCardVpCmTaskGeneralSearch = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    // Array.isArray(response.payload.records) && (this.jobList = response.payload.records.map(record => record['sn']));
    this.jobList = response.payload.records;
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);

    this.requestAllDescription();
  }

  private async requestAllDescription() {
    if (this.jobList.length) {
      let equipmentNumberList: string[] = [];
      let locationCodeList: string[] = [];

      for (const jobCard of this.jobList) {
        if (jobCard.equipment && !this.equipmentMap.has(jobCard.equipment)) {
          equipmentNumberList.push(jobCard.equipment);
        }
        if (jobCard.location && !this.locationMap.has(jobCard.location)) {
          locationCodeList.push(jobCard.location);
        }
      }

      if (equipmentNumberList.length) {
        await this.requestEquipmentDescription(equipmentNumberList);
      }
      if (locationCodeList.length) {
        await this.requestLocationDescription(locationCodeList);
      }
    }

    this.renderTable();
  }

  private async requestLocationDescription(locationList: string[]) {
    let request = new JM.JMRequestLocationsLocationSummary();
    request.location = [...new Set(locationList)];
    request.parameters = ['code', 'description'];

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    for (const location of response.payload.records) {
      if (!this.locationMap.has(location.code)) {
        this.locationMap.set(location.code, location);
      }
    }
  }

  private async requestEquipmentDescription(equipmentList: string[]) {
    let request = new JM.JMRequestEquipmentsEquipmentSummary();
    request.equipmentNumber = [...new Set(equipmentList)];
    request.parameters = ['equipmentNumber', 'description'];

    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    for (const equipment of response.payload.records) {
      if (!this.equipmentMap.has(equipment.equipmentNumber)) {
        this.equipmentMap.set(equipment.equipmentNumber, equipment);
      }
    }
  }

  private async requestCMTaskListExcel() {
    let request = new JM.JMRequestJobCardsExportCmTaskSummary();
    this.renderRequestFilter(request);

    this.isDownloading = true;
    const response: JM.JMResponseJobCardsExportCmTaskSummary = await AppDelegate.sendJMRequest(request);
    this.isDownloading = false;

    if (!response) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    const dateTime = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    const fileName = `CM-task ${dateTime}.xlsx`;
    saveAs(response, fileName);
  }

  private renderRequestFilter(request: JM.JMRequestJobCardsGetCmTaskList | JM.JMRequestJobCardsExportCmTaskSummary) {
    if (!this.searchData) {
      return;
    }

    if (this.searchData.filter && Object.entries(this.searchData.filter).length > 0) {
      request.filter = this.searchData.filter;
    }

    request.clientList = this.searchData.clientList;
    request.contractNumberList = this.searchData.contractNumberList;
    request.vendorTeamIdList = this.searchData.vendorTeamIdList;
    request.equipmentNumberList = this.searchData.equipmentNumberList;
    request.locationCodeList = this.searchData.locationCodeList;
    request.jobCreationTimeFrom = this.searchData.jobCreationTimeFrom;
    request.jobCreationTimeTo = this.searchData.jobCreationTimeTo;
    request.statusList = this.searchData.statusList;
  }

  // ----------- UI function ----------- //
  initSelectedCol() {
    // TODO: get form local storage
    this.selectedColId = [
      'jobCardNumber',
      'jobDescription',
      'status',
      'severity',
      'client',
      'contract',
      'vendorTeam',
      'location',
    ];
    let cachedUserSettings = Session.userInfo.userSettings;
    if (cachedUserSettings && cachedUserSettings[VPUserSettings.VP_CM_TASK_LIST_COLUMNS]) {
      Object.assign(this.selectedColId, cachedUserSettings[VPUserSettings.VP_CM_TASK_LIST_COLUMNS]);
    }

    this.onColFiltered(this.selectedColId);
  }

  onGeneralSearchKeyup(event, searchType) {
    if (event.key === 'Enter') {
      this.onGeneralSearchClicked();
    }
  }

  checkSearchEmpty() { }

  onGeneralSearchClicked() {
    this.currentPage = 1;
    this.resetTableAction();
    if (this.generalSearchWords?.trim().length > 0) {
      this.isGeneralSearch = true;
      this.generalSearch();
    } else {
      this.isGeneralSearch = false;
      this.advancedSearch();
    }
  }

  onClickDownload() { // TODO: remove

  }

  // ----------- Tablex UI function ----------- //
  public resetTableAction() {
    this.selectedTableRows = [];
    this.tablexParam['selectedRowIndex'] = undefined;
    this.tablexParam['highlightedRows'] = [];
  }

  onColFiltered = (selectedColId) => {
    this.selectedColId = [...selectedColId];
    this.renderTable();

    // TODO: save in local storage
    if (
      !Session.userInfo['userSettings'] ||
      (Session.userInfo['userSettings'] &&
        Session.userInfo.userSettings[VPUserSettings.VP_CM_TASK_LIST_COLUMNS] != this.selectedColId)
    ) {
      this.requestUpdateUserSettings();
      let userSettings = Session.userInfo['userSettings'] ? Session.userInfo['userSettings'] : {};
      userSettings[VPUserSettings.VP_CM_TASK_LIST_COLUMNS] = this.selectedColId;
      Session.userInfo.userSettings = userSettings;
      Session.setUserInfo(Session.userInfo);
    }
  };

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.resetTableAction();
    this.isGeneralSearch ? this.generalSearch() : this.advancedSearch();
  };

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.resetTableAction();
    this.isGeneralSearch ? this.generalSearch() : this.advancedSearch();
  };

  onSortOrderChanged = (header, sortOrder) => {
    this.tablexParam['sortBy'] = header ? header : 'updatedAt';
    this.tablexParam['sortOrder'] = sortOrder;
    this.isGeneralSearch ? this.generalSearch() : this.advancedSearch();
  };

  renderTable() {
    this.tablexParam['headers'] = this.allColHeaders.filter((col) => {
      return this.selectedColId.includes(col.id);
    });
    this.tablexParam['content'] = this.jobList.map((job) => {
      const jobCardNumberLink = {
        url: `/cm-task/${job.snNumber}/${Util.toString(job.jobCardNumber)}`,
        value: Util.toString(job.jobCardNumber),
      };
      const jobDescription = job.jobDescription ? job.jobDescription : '';
      const jobStatus = job.status ? JMLanguage.translate(`jobcard.status.${job.status}`) : '';
      const severityName = job.contractSeverity && job.contractSeverity.name ? job.contractSeverity.name : '';
      const client = job.client ? job.client : '';
      const contractNumber = job.contract && job.contract.contractNumber ? job.contract.contractNumber : '';
      const vendorTeamName = job.vendorTeam && job.vendorTeam.name ? job.vendorTeam.name : '';
      const equipmentDescription = this.getEquipmentDescription(job.equipment);
      const locationDescription = this.getLocationDescription(job.location);
      const contractorRemarks = job.contractorRemarks ? job.contractorRemarks : '';
      const liftTrapped = job.liftTrapped ? JMLanguage.translate('global.yes') : JMLanguage.translate('global.no');
      const createdTime = moment(job.createdAt).format(Constants.DATETIME_FORMAT_2);
      const updatedTime = moment(job.updatedAt).format(Constants.DATETIME_FORMAT_2);

      const originRow = [
        job._id,
        jobCardNumberLink,
        jobDescription,
        jobStatus,
        severityName,
        client,
        contractNumber,
        vendorTeamName,
        equipmentDescription,
        locationDescription,
        contractorRemarks,
        liftTrapped,
        createdTime,
        updatedTime,
      ];

      let row = [];
      originRow.forEach((value, i) => {
        if (this.selectedColId.includes(this.allColHeaders[i].id)) {
          row.push(originRow[i]);
        }
      });
      return row;
    });
    this.resetTableAction();
    this.tablexParam['isLoadingTable'] = false;
    this.tablexParam['totalRowCount'] = this.tablexParam['content'].length;
  }

  private getEquipmentDescription(equipmentNumber?: string): string {
    if (!equipmentNumber) {
      return '';
    }

    if (this.equipmentMap.has(equipmentNumber)) {
      const equipment = this.equipmentMap.get(equipmentNumber);
      return `${equipment.description} (${equipmentNumber})`;
    } else {
      return `(${equipmentNumber})`;
    }
  }

  private getLocationDescription(locationCode?: string): string {
    if (!locationCode) {
      return '';
    }

    if (this.locationMap.has(locationCode)) {
      const location = this.locationMap.get(locationCode);
      const description = location.description[Session.selectedLanguage]
        ? location.description[Session.selectedLanguage]
        : location.description.en;
      return `${description} (${locationCode})`;
    } else {
      return `(${locationCode})`;
    }
  }

  initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableColFilter: true,
      enableSelectedRowCount: false,
      enableSort: true,
      minifyButton: true,
      tableRow: 'row',
      tableClass: 'cm-task-list-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      selectedColId: this.selectedColId,
      fullColNameList: this.allColHeaders.map((col) => {
        return { id: col.id, name: col.name };
      }),
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onColFiltered: this.onColFiltered,
      onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };

    this.initTableHeader();
  }

  initTableHeader() {
    // TODO: update fields
    this.allColHeaders = [
      {
        id: 'objId',
        name: '',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'd-none ',
      },
      {
        id: 'jobCardNumber',
        name: 'pages.cm-task-list.table-column.cm-task-number',
        enableSort: true,
        enableFilter: false,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'jobDescription',
        name: 'pages.cm-task-list.table-column.task-description',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'status',
        name: 'pages.cm-task-list.table-column.status',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'severity',
        name: 'pages.cm-task-list.table-column.severity',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'client',
        name: 'pages.cm-task-list.table-column.client',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'contract',
        name: 'pages.cm-task-list.table-column.contract',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'vendorTeam',
        name: 'pages.cm-task-list.table-column.team',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'equipment',
        name: 'pages.cm-task-list.table-column.equipment',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'functionalLocation',
        name: 'pages.cm-task-list.table-column.location',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'contractorRemarks',
        name: 'pages.cm-task-list.table-column.progress-report',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'liftTrapped',
        name: 'pages.cm-task-list.table-column.lift-trapped',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'createdAt',
        name: 'pages.cm-task-list.table-column.created-date',
        enableSort: true,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'updatedAt',
        name: 'pages.cm-task-list.table-column.last-updated-date',
        enableSort: true,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
    ];
    this.selectedCol = this.allColHeaders;
  }


  // ----------- advance search event ----------- //
  async onDownload(searchCriteria: CmTaskSearchCriteriaI) {
    if (searchCriteria) {
      this.searchData = searchCriteria;
    }

    this.requestCMTaskListExcel();
  };

  async onAdvancedSearch(searchCriteria: CmTaskSearchCriteriaI) {
    if (searchCriteria) {
      this.searchData = searchCriteria;
    }
    this.currentPage = 1;
    this.resetTableAction();
    
    this.isGeneralSearch = false;
    this.advancedSearch();

  };
}
