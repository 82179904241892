import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserHelper } from 'src/app/core/user-helper';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Util } from 'src/app/core/util';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Constants } from 'src/constants';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-contractor-general-setting',
  templateUrl: './contractor-general-setting.component.html',
  styleUrls: ['./contractor-general-setting.component.scss'],
})
export class ContractorGeneralSettingComponent implements OnInit {
  @Input() selectedContractor: JMOBJ.Vendor = null;
  @Output() onFinishUpdateInfo = new EventEmitter<any>();

  isEditmode: boolean = false;
  hasUpdatePermission: boolean = false;

  //input fields
  accountPrefix: string;
  // telephone: string;
  // fax: string;
  // email: string;
  // responsibleOfficer: string;
  // contractorDefaultTeam: string;
  // contractorDefaultTeamInput: string;
  // requireTFA: boolean;
  active: boolean;

  vendorNumber: string;

  // responsibleOfficer Dropdown
  // responsibleOfficerOptions: any = [];
  // private searchPostObserver = new Subject<any[]>();
  // searchPostKeywords: string = null;
  // postPageSize: number = 100;
  // postPageNumber: number = 0;
  // postTotalCount: number;
  // isLoadingPost: boolean = false;

  // contractorDefaultTeam Dropdown
  // contractorDefaultTeamOptions: any = [];
  // private searchTeamObserver = new Subject<any[]>();
  // searchTeamKeywords: string = null;
  // teamPageSize: number = 100;
  // teamPageNumber: number = 0;
  // teamTotalCount: number;
  // isLoadingTeam: boolean = false;

  errorFields = {};

  constructor() {}

  ngOnInit(): void {
    this.hasUpdatePermission = UserHelper.hasPermission(JMENUM.VpPermission.CONTRACTOR_UPDATE);
  }

  ngOnChanges() {
    this.setForm();
    this.isEditmode = false;
  }

  // ----------- API ----------- //
  // private async requestContractVendorTeamSummary() {
  //   if (this.teamTotalCount !== null && this.teamTotalCount <= this.teamPageNumber * this.teamPageSize) {
  //     return;
  //   }
  //   this.isLoadingTeam = true;
  //   this.teamPageNumber++;

  //   let request: JM.JMRequestContractVendorTeamSummary = new JM.JMRequestContractVendorTeamSummary();
  //   request.vendorNumber = [this.vendorNumber];
  //   request.parameters = ['_id', 'name'];
  //   request.pageNumber = this.teamPageNumber;
  //   request.pageSize = this.teamPageSize;
  //   if (this.searchTeamKeywords) {
  //     request.filter = { name: this.searchTeamKeywords };
  //   }

  //   let response: JM.JMResponseContractVendorTeamSummary = await AppDelegate.sendJMRequest(request);
  //   this.isLoadingTeam = false;
  //   if (response && response.code == 200) {
  //     let options = response.payload.records.map((record) => {
  //       return { label: record.name, value: record._id };
  //     });
  //     this.teamTotalCount = response.payload.totalCount;
  //     this.contractorDefaultTeamOptions = this.contractorDefaultTeamOptions.concat(options);
  //   } else {
  //     AppDelegate.toastMsg().showResponseMsg(response);
  //   }
  // }

  // private async requestPostSummary() {
  //   if (this.postTotalCount !== null && this.postTotalCount <= this.postPageNumber * this.postPageSize) {
  //     return;
  //   }
  //   this.isLoadingPost = true;
  //   this.postPageNumber++;

  //   let request: JM.JMRequestPostsPostSummary = new JM.JMRequestPostsPostSummary();
  //   request.systemName = Constants.SYSTEM_NAME;
  //   request.vendorNumber = [this.vendorNumber];
  //   request.pageNumber = this.postPageNumber;
  //   request.pageSize = this.postPageSize;
  //   if (this.searchPostKeywords) {
  //     request.filter = { contractNumber: this.searchPostKeywords };
  //   }

  //   let response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
  //   this.isLoadingPost = false;
  //   if (response && response.code == 200) {
  //     let options = response.payload.records.map((record) => {
  //       return { label: record.name, value: record.name };
  //     });
  //     this.postTotalCount = response.payload.totalCount;
  //     this.responsibleOfficerOptions = this.responsibleOfficerOptions.concat(options);
  //   } else {
  //     AppDelegate.toastMsg().showResponseMsg(response);
  //   }
  // }

  private async requestUpdateContractGeneralInfo() {
    let request: JM.JMRequestContractsUpdateVendorGeneralInfo = new JM.JMRequestContractsUpdateVendorGeneralInfo();
    request.vendorNumber = this.selectedContractor.vendorNumber;
    request.postNamePrefix = this.accountPrefix ? this.accountPrefix : null;
    // request.email = this.email ? this.email : null;
    // request.responsibleOfficer = this.responsibleOfficer ? this.responsibleOfficer : null;
    // request.defaultVendorTeam = this.contractorDefaultTeamInput ? this.contractorDefaultTeamInput : null;
    // request.loginVerificationMode = this.requireTFA ? '2FA' : null;
    request.activeStatus = this.active
      ? JMENUM.ActiveStatus.ACTIVE
      : JMENUM.ActiveStatus.INACTIVE;

    let response: JM.JMResponseContractsUpdateVendorGeneralInfo = await AppDelegate.sendJMRequest(request);
    if (response && response.code == 200) {
      this.isEditmode = false;
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.updated'));
      this.onFinishUpdateInfo.emit();
    } else if (response && response.code == 2037) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate("api.error.2037"));
    } else {
      AppDelegate.toastMsg().showResponseMsg(response);
    }
  }

  // ----------- UI function ----------- //
  private setForm() {
    this.accountPrefix = this.selectedContractor ? this.selectedContractor['postNamePrefix'] : null;
    // this.email = this.selectedContractor.email;
    // this.responsibleOfficer = this.selectedContractor.responsibleOfficer;
    // let defaultVendorTeam = this.contractorDefaultTeamOptions.find(
    //   (team) => team.value == this.selectedContractor['defaultVendorTeam']
    // );
    // this.contractorDefaultTeam = defaultVendorTeam ? defaultVendorTeam['label'] : undefined;
    // this.contractorDefaultTeamInput = defaultVendorTeam ? defaultVendorTeam['value'] : undefined;
    // this.requireTFA = false;
    this.active = this.selectedContractor ? 
    this.selectedContractor['activeStatus'] === JMENUM.ActiveStatus.ACTIVE ? true : false : false;
    // this.requestContractVendorTeamSummary();
    // this.requestPostSummary();
  }

  public onEdit() {
    this.isEditmode = true;
  }

  public onSubmit() {
    if(!this.accountPrefix && this.active) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('component.contractor-general.msg.missing-account-prefix'));
      return;
    }
    let data = {
      msg: {
        content: 'global.title.confirm-save',
      },
      buttons: [
        {
          name: "global.yes",
          handler: () => {
            this.requestUpdateContractGeneralInfo();
          }
        },
        { name: "global.no" }
      ]
    }
    AppDelegate.popUpDialog().open(data);
  }

  // public onChangeEmail(value) {
  //   if (this.email && !Util.isValidEmail(value)) {
  //     AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.invalid-email-format'));
  //     return false;
  //   }
  //   return true;
  // }

  // public onPostScrollToEnd() {
  //   this.requestPostSummary();
  // }

  // public onSearchPost(event) {
  //   this.searchPostKeywords = event.term;
  //   this.searchPostObserver.next();
  // }

  // public onTeamScrollToEnd() {
  //   this.requestContractVendorTeamSummary();
  // }

  // public onSearchTeam(event) {
  //   this.searchTeamKeywords = event.term;
  //   this.searchTeamObserver.next();
  // }

  public onRollBack() {
    this.resetContractorGeneral();
  }

  private resetContractorGeneral() {
    if (this.selectedContractor) {
      this.accountPrefix = this.selectedContractor['postNamePrefix'];
      // this.telephone = this.selectedContractor['telephone'];
      // this.fax = this.selectedContractor['fax'];
      // this.email = this.selectedContractor['email'];
      // this.responsibleOfficer = this.selectedContractor['responsibleOfficer'];
      // this.contractorDefaultTeamInput = this.selectedContractor['defaultVendorTeam'];
      // this.requireTFA = this.selectedContractor['requireTFA'];
      this.active = this.selectedContractor['activeStatus'] === JMENUM.ActiveStatus.ACTIVE;
    }
    this.resetErrorField();
  }

  public resetErrorField() {
    this.errorFields = {
      accountPrefix: false,
      email: false,
      responsibleOfficer: false,
      contractorDefaultTeam: false,
    };
  }
}
