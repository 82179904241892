/*
  version 20210504:
   - render table data handle after Team hyperlink is ready and field name maybe change 
   - Permission handle
   - Real Link of team 
   - add comment and tidy up region of method

  version 20210428:
   - create this component
*/

import { Component, Input, OnInit } from '@angular/core';
import {
  TablexColumnType,
  TablexColumnHorizontalAlign,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';
import { JM, JMOBJ, JMENUM } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Util } from 'src/app/core/util';
import { UserHelper } from 'src/app/core/user-helper';

@Component({
  selector: 'app-contractor-team-list',
  templateUrl: './contractor-team-list.component.html',
  styleUrls: ['./contractor-team-list.component.scss'],
})
export class ContractorTeamListComponent implements OnInit {
  @Input() selectContract: JMOBJ.MaintenanceTermContract = new JMOBJ.MaintenanceTermContract();

  // tablex parameters
  tablexParam: {} = {};
  allColHeaders: any = [];
  contractorTeamList: any[] = [];

  hasPermission = true;

  constructor() {}

  ngOnInit(): void {
    if (!UserHelper.hasEitherOnePermission([JMENUM.VpPermission.TEAM_VIEW, JMENUM.VpPermission.TEAM_UPDATE])) {
      this.hasPermission = false;
    }
  }

  ngOnChanges(): void {
    this.initTableHeader();
    this.initTablex();
    if (this.selectContract['contractNumber']) {
      this.requestContractVendorTeamSummary();
    }
  }

  //#region Tablex Setup Function
  private initTableHeader() {
    this.allColHeaders = [
      {
        id: 'order',
        name: 'component.contractor-team-list.table.title.order',
        enableSort: true,
        enableFilter: true,
        filterAttribute: 'order',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'team-name',
        name: 'component.contractor-team-list.table.title.team-name',
        enableSort: true,
        enableFilter: true,
        filterAttribute: 'team-name',
        type: this.hasPermission ? TablexColumnType.Hyperlink : TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'hotline',
        name: 'component.contractor-team-list.table.title.hotline',
        enableSort: true,
        enableFilter: true,
        filterAttribute: 'hotline',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'default-team',
        name: 'component.contractor-team-list.table.title.default-team',
        enableSort: true,
        enableFilter: true,
        filterAttribute: 'default-team',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
    ];
  }

  private initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      tableRow: 'row',
      tableClass: 'contractor-team-list-table',
      tableWrapperClass: 'table-min-width',
      fullColNameList: this.allColHeaders.map((col) => {
        return { id: col.id, name: col.name };
      }),
      enableStickyHeader: false,
      headers: this.allColHeaders,
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };
  }

  private renderTable() {
    if (this.contractorTeamList == null) {
      return;
    }

    if (this.contractorTeamList.length > 0) {
      this.tablexParam['content'] = this.contractorTeamList.map((data, index) => {
        return [
          index + 1,
          this.hasPermission
            ? {
                url: '/settings/team/' + Util.toString(data._id) + '/' + Util.toString(data.name),
                value: Util.toString(data.name),
              }
            : data.name,
          Util.toString(data.phone),
          this.selectContract.defaultTeam == data.name ? 'Y' : '',
        ];
      });
    } else {
      this.tablexParam['content'] = undefined;
    }
  }
  //#endregion

  //#region API Call Function
  private async requestContractVendorTeamSummary() {
    if (!UserHelper.hasPermission(JMENUM.VpPermission.TEAM_VIEW)) {
      return;
    }
    this.tablexParam['isLoadingTable'] = true;

    let request: JM.JMRequestContractVendorTeamSummary = new JM.JMRequestContractVendorTeamSummary();
    request.contractNumber = [this.selectContract['contractNumber']];

    let response: JM.JMResponseContractVendorTeamSummary = await AppDelegate.sendJMRequest(request);

    this.tablexParam['isLoadingTable'] = false;
    if (response && response.code == 200) {
      this.contractorTeamList = response.payload.records;
      this.renderTable();
    } else {
      AppDelegate.toastMsg().showResponseMsg(response);
    }
  }
  //#endregion
}
