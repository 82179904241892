<div class="d-flex justify-content-between mb-2 align-items-center">
  <div class="font-size-xl align-self-end">
    {{ "component.contract-list-by-contract.title" | translate}}
  </div>

  <div>
    <div class="text-right">
      <span class="red">*</span>{{ "component.contract-list-by-contract.note.expired-contract" | translate}}
    </div>
    <div class="d-flex justify-content-end">
      <button class="mr-2 font-size-xl" (click)="onClickDownload()" *ngIf="enableDownload">
        <div *ngIf="isDownloading" class="d-flex align-items-center loading-spinner spinner-small blue"></div>
        <i *ngIf="!isDownloading" class="fa fa-download blue"></i>
      </button>
      <div class="search-box" *ngIf="enableSearch">
        <input type="text" class="form-control search-input" />
        <i class="fas fa-search blue" (click)="onClickSearch()"></i>
      </div>
    </div>
  </div>
  
</div>
<hr class="underline" />

<app-tablex id="contract-list-by-contract-table" class="" [tablexParam]='tablexParam'></app-tablex>