import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import * as moment from 'moment';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { UserHelper } from 'src/app/core/user-helper';
import { Util } from 'src/app/core/util';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';
import { Constants } from 'src/constants';

@Component({
  selector: 'app-contract-list-by-contract',
  templateUrl: './contract-list-by-contract.component.html',
  styleUrls: ['./contract-list-by-contract.component.scss'],
})
export class ContractListByContractComponent implements OnInit {
  @Input() enableSearch: boolean = false;
  @Input() enableDownload: boolean = false;

  @Output() assignedContract = new EventEmitter<JMOBJ.MaintenanceTermContract>();

  contractList: JMOBJ.MaintenanceTermContract[] = [];
  selectedContract?: JMOBJ.MaintenanceTermContract;
  isDownloading: boolean = false;

  // tablex parameters
  tablexParam: {} = {};
  tablexFilter = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize = 10;
  currentPage = 1;
  pageCount = 1;
  sortBy = undefined;
  sortOrder = undefined;
  selectedIndex = undefined;
  allColHeaders: any = [];
  selectedRows: any = undefined;

  constructor() {}

  ngOnInit(): void {
    this.initTableHeader();
    this.initTablex();
    this.requestContractList();
  }

  //------------------------
  // Tablex Config Function
  private initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableSort: true,
      enableColFilter: false,
      enableSelectedRowCount: false,
      minifyButton: true,
      tableRow: 'row',
      tableClass: 'contract-list-table',
      tableWrapperClass: 'table-min-width',
      enableClearFilter: true,
      sortOrder: 1,
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      onRowSelected: this.onRowSelected,
      onRowUnselected: this.onRowUnselected,
      onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };
  }

  private initTableHeader() {
    this.allColHeaders = [
      {
        id: 'objId',
        name: 'component.contract-list-by-contract.table.title.contract-no',
        enableSort: false,
        enableFilter: false,
        filterAttribute: '_id',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'd-none ',
      },
      {
        id: 'contractNumber',
        name: 'component.contract-list-by-contract.table.title.contract-no',
        enableSort: true,
        enableFilter: true,
        filterAttribute: 'contractNumber',
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'title',
        name: 'component.contract-list-by-contract.table.title.contract-title',
        enableSort: true,
        enableFilter: true,
        filterAttribute: 'title',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'responsibleDivision',
        name: 'component.contract-list-by-contract.table.title.sbu',
        enableSort: true,
        enableFilter: true,
        filterAttribute: 'responsibleDivision',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'responsibleSeniorEngineer',
        name: 'component.contract-list-by-contract.table.title.snr-reviewer',
        enableSort: true,
        enableFilter: true,
        filterAttribute: 'responsibleSeniorEngineer',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'responsibleEngineer',
        name: 'component.contract-list-by-contract.table.title.reviewer',
        enableSort: true,
        enableFilter: true,
        filterAttribute: 'responsibleEngineer',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'vendorStartDate',
        name: 'component.contract-list-by-contract.table.title.start-date',
        enableSort: true,
        enableFilter: false,
        filterAttribute: 'vendorStartDate',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'vendorEndDate',
        name: 'component.contract-list-by-contract.table.title.end-date',
        enableSort: true,
        enableFilter: false,
        filterAttribute: 'vendorEndDate',
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
    ];
  }

  //------------------------
  // Tablex Reaction Function
  onFilterChanged = (event, index, header, filter) => {
    this.resetTable();

    for (let key in filter) {
      if (!filter[key]) {
        delete this.tablexFilter[key];
      } else {
        if (key != 'vendorStartDate' && key != 'vendorEndDate') {
          this.tablexFilter[key] = filter[key];
        }
      }
    }

    this.currentPage = 1;
    this.requestContractList();
  };

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.resetTable();
    this.requestContractList();
  };

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.resetTable();
    this.requestContractList();
  };

  onRowSelected = (index, row) => {
    if (!this.contractList) {
      return;
    }
    this.selectedContract = this.contractList.find((record) => record._id == row[0]);
    this.assignedContract.emit(this.selectedContract);
  };

  onRowUnselected = (index, row) => {
    this.selectedContract = undefined;
    this.assignedContract.emit(this.selectedContract);
  };

  onSortOrderChanged = (sortBy, sortOrder) => {
    this.tablexParam['sortBy'] = sortBy;
    this.tablexParam['sortOrder'] = sortOrder;
    this.requestContractList();
  };

  onFilterClear = () => {
    this.tablexFilter = {};
    this.tablexParam['filter'] = {};
    this.requestContractList();
  };

  resetTable() {
    this.tablexParam['selectedRowIndex'] = undefined;
    this.tablexParam['highlightedRows'] = [];
    this.selectedContract = undefined;
    this.assignedContract.emit(this.selectedContract);
  }

  private renderTable() {
    if (!this.contractList) {
      return;
    }
    if (this.contractList.length) {
      this.tablexParam['content'] = undefined;
    }

    this.tablexParam['content'] = this.contractList.map((data) => {
      const redTextClass = 'class="red"';
      const today = moment().startOf('day');
      const vendorEndDate = moment(data.vendorEndDate, 'YYYYMMDD');
      const isExpried = !today.isSameOrBefore(vendorEndDate);

      const contractNumber = isExpried
        ? `${Util.toString(data.contractNumber)}`
        : Util.toString(data.contractNumber);
      const startDate = Util.toString(moment(data.vendorStartDate, 'YYYYMMDD').format('DD/MM/YYYY'));
      const endDate = `<span ${isExpried ? redTextClass : ''}>${Util.toString(vendorEndDate.format('DD/MM/YYYY'))}</span>`;

      const row = [
        data._id,
        [contractNumber],
        Util.toString(data.title),
        Util.toString(data.responsibleDivision),
        Util.toString(data.responsibleSeniorEngineer),
        Util.toString(data.responsibleEngineer),
        startDate,
        [endDate],
      ];

      return row;
    });
  }

  //------------------------
  // API
  async requestContractList() {
    if (!UserHelper.hasPermission(JMENUM.VpPermission.CONTRACT_VIEW)) {
      return;
    }

    let request = new JM.JMRequestContractsGetContractList();
    request.pageSize = this.currentPageSize;
    request.pageNumber = this.currentPage;
    request.filter = this.tablexFilter;
    request.sortBy = this.tablexParam['sortBy'];
    request.sortOrder = this.tablexParam['sortOrder'];

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseContractsGetContractList = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.contractList = response.payload.records;
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
    this.renderTable();
  }
}
