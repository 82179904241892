<div id class="wrapper">
  <app-nav [navbarTitle]="'pages.settings-contractor.title' | translate"></app-nav>
  <app-sidebar [contentType]="'settings'"></app-sidebar>

  <div id="content" class="content setting-contractor-content">

    <div class="contractor-page-wrap individual-frame-wrap row">
      <div class="col-12 col-xl-6 left-frame mb-4">
        <app-contractor-list-table #contractor_list class="contractor-list-table-app" (onSelectContractor)="assignContractor($event)"></app-contractor-list-table>
      </div>
      
      <div class="col-12 col-xl-6 right-frame">
        <app-contractor-general-setting  #contractor_general class="d-flex w-100" [selectedContractor]="selectedContractor" (onFinishUpdateInfo)="onFinishUpdateInfo()"></app-contractor-general-setting>
        <app-contract-list #contract_list class="d-flex mt-2 w-100" style="flex: 1; overflow: auto;" [parameters]="contractListParam"></app-contract-list>
      </div>
    </div>
    
  </div>
</div>