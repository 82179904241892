import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { JM, JMCONSTANT, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { ActionSidebarV2Service } from '../../shared/action-sidebar-v2.service';
import { ActionButtonI } from '../../shared/action-sidebar-v2/action-sidebar-v2.model';
import { PmPlanCreateActionButtonList } from './pm-plan-create.model';

import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { UserHelper } from 'src/app/core/user-helper';
import { Session } from 'src/app/core/session';

@Component({
  selector: 'app-pm-plan-create',
  templateUrl: './pm-plan-create.component.html',
  styleUrls: ['./pm-plan-create.component.scss'],
})
export class PmPlanCreateComponent implements OnInit {
  @ViewChild('pmPlanSummaryElem', { static: false }) pmPlanSummaryElem;
  @ViewChild('pmPlanParticularsElem', { static: false }) pmPlanParticularsElem;
  @ViewChild('pmPlanRemindersElem', { static: false }) pmPlanRemindersElem;
  @ViewChild('pmPlanPeriodsElem', { static: false }) pmPlanPeriodsElem;
  @ViewChild('pmPlanRemarksElem', { static: false }) pmPlanRemarksElem;

  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.CREATE;
  pmPlanNumber = undefined;
  plan: JMOBJ.PmPlan = new JMOBJ.PmPlan();
  // periods: JMOBJ.PmPeriod = new JMOBJ.PmPeriod;

  hasPMReminderFeatureToggle = false;

  // action buttons
  actionSidebar: {
    dataList: ActionButtonI[];
    isDisabled: boolean;
    isLoading: boolean;
  } = {
      dataList: [],
      isDisabled: false,
      isLoading: false,
    };

  constructor(private _location: Location, private actionSidebarV2Service: ActionSidebarV2Service) { }

  ngOnInit(): void {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }

    this.resetActionBar();
    const pmReminderFeature = Session.featureList.find(feature => feature.key == JMCONSTANT.JMFeature.VP_PM_PLAN_REMINDER);
    if (pmReminderFeature) {
      this.hasPMReminderFeatureToggle = pmReminderFeature.enabled;
    }
  }

  // ----------- API ----------- //
  private async requestSavePmPlan() {
    let request: JM.JMRequestVpPmPlanDraft = new JM.JMRequestVpPmPlanDraft();
    request.pmPlanNumber = this.plan.pmPlanNumber;
    request.contractNumber = this.plan.contract.contractNumber;
    request.periods = this.plan['periods'];
    request.vendorTeamId = this.plan.vendorTeam.id;
    request.frequency = this.plan.frequency;
    request.scheduleType = this.plan.scheduleType;
    request.startDate = this.plan.startDate;
    request.secondStartDate =
      (this.plan.scheduleType == JMENUM.PMScheduleType.OVERHAUL ||
        this.plan.scheduleType == JMENUM.PMScheduleType.NORMAL) &&
        this.plan.secondStartDate
        ? this.plan.secondStartDate
        : undefined;
    request.endDate = this.plan.endDate;
    request.planDescription = this.plan.planDescription;
    request.vpReminderRecipients = this.plan.vpReminderRecipients ? this.plan.vpReminderRecipients : undefined;
    request.remarks = this.plan.remarks ? this.plan.remarks : undefined;
    request.vpToBeOverdueReminder = this.plan.vpToBeOverdueReminder ? this.plan.vpToBeOverdueReminder : undefined;
    request.vpOverduePeriodReminder = this.plan.vpOverduePeriodReminder ? this.plan.vpOverduePeriodReminder : undefined;

    let response: JM.JMResponseVpPmPlanDraft = await AppDelegate.sendJMRequest(request);
    if (!response || response.error || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }
    AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.saved'));
    return response.payload.pmPlan.pmPlanNumber;
  }

  // ----------- UI function -----------
  onRefreshPeriod() {
    let validateSecondStartDate = true;
    if (this.plan.secondStartDate) {
      validateSecondStartDate = this.pmPlanSummaryElem.validateSecondStartDate();
    }
    if (this.pmPlanSummaryElem.validateStartEndDate() && validateSecondStartDate) {
      this.pmPlanPeriodsElem.requestPmPeriodCalculate();
    }
  }

  async savePmPlan(): Promise<string> {
    let pmPlanNumber;
    if (this.pmPlanSummaryElem.validate() && this.pmPlanParticularsElem.validate() && (this.hasPMReminderFeatureToggle ? this.pmPlanRemindersElem.validate() : true)) {
      pmPlanNumber = await this.requestSavePmPlan();
    }
    return pmPlanNumber;
  }

  clearPlanFields() {
    this.plan.startDate = undefined;
    this.plan.endDate = undefined;
    this.plan.secondStartDate = undefined;
  }

  onChangeContractSelection() {
    this.pmPlanParticularsElem.clearTeam();
    this.pmPlanParticularsElem.toggleTeamInput();
    this.pmPlanParticularsElem.fetchNewTeamList();
    if (this.hasPMReminderFeatureToggle) {
      this.pmPlanRemindersElem.clearRecipients();
      this.pmPlanRemindersElem.toggleRecipientsInput();
      this.pmPlanRemindersElem.fetchNewPostList();
    }
  }

  // ----------- action button function -----------
  onClickedActionButton(actionButton: ActionButtonI) {
    actionButton.handler();
  }

  private resetActionBar(): void {
    this.actionSidebar.dataList = [];

    const actionList = [JMENUM.VpPmPlanAction.DRAFT, 'clear', 'close', JMENUM.VpPmPlanAction.EQUIPMENT];

    this.filterActionButton(actionList);
  }

  private filterActionButton(actionList: string[]) {
    for (const action of actionList) {
      const button: ActionButtonI = PmPlanCreateActionButtonList[action];
      const hasPermissionList = button && button.permissionList && button.permissionList.length;

      if (hasPermissionList) {
        const allowAction = UserHelper.hasEitherOnePermission(button.permissionList);
        if (allowAction) {
          this.addActionButton(button);
        }
      } else {
        this.addActionButton(button);
      }
    }
  }

  private addActionButton(actionButton: ActionButtonI) {
    let handler = () => { };

    switch (actionButton.action) {
      case JMENUM.VpPmPlanAction.DRAFT:
        handler = async () => {
          let pmPlanNumber = await this.savePmPlan();
          if (pmPlanNumber) {
            AppDelegate.routeToPage(`/pm/plans/${pmPlanNumber}`);
          }
        };
        break;
      case 'clear':
        handler = () => {
          const buttonHandler = () => {
            this.clearPlanFields();
            this.pmPlanSummaryElem.clearAll();
            this.pmPlanParticularsElem.toggleTeamInput();
            this.pmPlanParticularsElem.clearAll();
            if (this.hasPMReminderFeatureToggle) {
              this.pmPlanRemindersElem.clearAll();
              this.pmPlanRemindersElem.toggleRecipientsInput();
            }
            this.pmPlanPeriodsElem.clearAll();
            this.pmPlanRemarksElem.clearAll();
          };
          this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, buttonHandler);
        };
        break;
      case 'close':
        handler = () => {
          const buttonHandler = () => {
            this._location.back();
          };
          this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, buttonHandler);
        };
        break;
      case JMENUM.VpPmPlanAction.EQUIPMENT:
        handler = async () => {
          let pmPlanNumber = await this.savePmPlan();
          if (pmPlanNumber) {
            AppDelegate.routeToPage(`/pm/plans/${pmPlanNumber}/equipment-list`);
          }
        };
        break;
      default:
        break;
    }

    actionButton.handler = handler;
    this.actionSidebar.dataList.push(actionButton);
  }

  onChangeSchTypeAndFreq(args: any) {
    if (!this.hasPMReminderFeatureToggle) {
      return;
    }
    this.plan['toBeOverdueReminder'] = {
      toBeDueDay: ReminderDefaultValueDict[args.scheduleType][args.frequency] ? ReminderDefaultValueDict[args.scheduleType][args.frequency]['toBeDueDay'] : ReminderDefaultValueDict[args.scheduleType]['toBeDueDay'],
      interval: ReminderDefaultValueDict[args.scheduleType][args.frequency] ? ReminderDefaultValueDict[args.scheduleType][args.frequency]['interval'] : ReminderDefaultValueDict[args.scheduleType]['interval'],
      activeStatus: JMENUM.ActiveStatus.ACTIVE
    };
    this.plan['overduePeriodReminder'] = {
      interval: ReminderDefaultValueDict[args.scheduleType][args.frequency] ? ReminderDefaultValueDict[args.scheduleType][args.frequency]['overdueInterval'] : ReminderDefaultValueDict[args.scheduleType]['overdueInterval'],
      activeStatus: JMENUM.ActiveStatus.ACTIVE
    };
    if(this.hasPMReminderFeatureToggle) {
      this.pmPlanRemindersElem.updateReminderDefaultValues();
    }
  }
}

export const ReminderDefaultValueDict = {
  normal: {
    weekly: {
      toBeDueDay: 6, interval: 3, overdueInterval: 2
    },
    bi_weekly: {
      toBeDueDay: 6, interval: 3, overdueInterval: 2
    },
    monthly: {
      toBeDueDay: 14, interval: 7, overdueInterval: 7
    },
    bi_monthly: {
      toBeDueDay: 14, interval: 7, overdueInterval: 14
    },
    quarterly: {
      toBeDueDay: 14, interval: 7, overdueInterval: 21
    },
    tetra_monthly: {
      toBeDueDay: 14, interval: 7, overdueInterval: 28
    },
    half_yearly: {
      toBeDueDay: 14, interval: 7, overdueInterval: 90
    },
    yearly: {
      toBeDueDay: 14, interval: 7, overdueInterval: 90
    },
    bi_yearly: {
      toBeDueDay: 14, interval: 7, overdueInterval: 180
    },
  },
  once: {
    toBeDueDay: 6, interval: 3, overdueInterval: 2
  },
  overhaul: {
    weekly: {
      toBeDueDay: 6, interval: 3, overdueInterval: 2
    },
    bi_weekly: {
      toBeDueDay: 6, interval: 3, overdueInterval: 2
    },
    monthly: {
      toBeDueDay: 14, interval: 7, overdueInterval: 7
    },
    bi_monthly: {
      toBeDueDay: 14, interval: 7, overdueInterval: 14
    },
    quarterly: {
      toBeDueDay: 14, interval: 7, overdueInterval: 21
    },
    tetra_monthly: {
      toBeDueDay: 14, interval: 7, overdueInterval: 28
    },
    half_yearly: {
      toBeDueDay: 14, interval: 7, overdueInterval: 90
    },
    yearly: {
      toBeDueDay: 14, interval: 7, overdueInterval: 90
    },
    bi_yearly: {
      toBeDueDay: 14, interval: 7, overdueInterval: 180
    },
  },
}