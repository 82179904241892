import { Component, OnInit } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { stringToMoment } from 'src/app/core/Formatter';
import { Session } from 'src/app/core/session';
import { TablexComponentParams } from 'src/app/shared/tablex/tablex.component';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';

@Component({
  selector: 'app-pm-job-list',
  templateUrl: './pm-job-list.component.html',
  styleUrls: ['./pm-job-list.component.scss']
})
export class PmJobListComponent implements OnInit {
  //TableX
  tablexParam: TablexComponentParams
  tablexHeaders: { [key: string]: any }[];
  tablexSelectedColId: string[] = [
    'pmJobNumber',
    'pmPlan.pmPlanNumber',
    'jobDescription',
    'period.periodStartDate',
    'period.periodEndDate',
    'status',
    'scheduleType',
    'frequency',
    'handlingTeam',
    'updatedAt',
  ];
  tablexPageSizeOptions = [10, 25, 100];
  tablexDefaultPageSize: number = 10;
  tablexDefaultPageNumber: number = 1;
  tablexDefaultPageCount = 0;
  tablexDefaultSortBy = 'period.periodStartDate';
  tablexDefaultSortOrder: JMENUM.SortOrder = JMENUM.SortOrder.DESC;

  pmJobs: Array<JMOBJ.PmJob>;

  searchFilters: any = null;
  searchStatus: string[] = null;
  searchHandlingTeam: string[] = null;
  searchParams: any = null;

  constructor() { }

  ngOnInit(): void {
    this.initTablex();
  }

  // API
  async requestTableData(pageNumber?: number, pageSize?: number): Promise<boolean> {
    // this.selectRow(null);
    const request: JM.JMRequestVpGetPmJobList = new JM.JMRequestVpGetPmJobList();

    request.sortBy = this.tablexParam.sortBy;
    request.sortOrder = this.tablexParam.sortOrder;
    request.pageNumber = pageNumber ? pageNumber : 1;
    request.pageSize = pageSize ? pageSize : this.tablexParam.currentPageSize;
    if (this.searchFilters) {
      request.filter = this.searchFilters;
    }
    if (this.searchStatus) {
      request.status = this.searchStatus;
    }
    if (this.searchHandlingTeam) {
      request.pmPlanVendorTeamIds = this.searchHandlingTeam;
    }
    if (this.searchParams) {
      for (const key in this.searchParams) {
        request[key] = this.searchParams[key];
      }
    }

    this.tablexParam.isLoadingTable = true;
    const response: JM.JMResponseVpGetPmJobList = await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return false;
    }
    this.pmJobs = response.payload.records;
    this.tablexParam.pageCount = Math.ceil(response.payload.totalCount / request.pageSize);
    this.tablexParam.currentPage = request.pageNumber;
    this.tablexParam.currentPageSize = request.pageSize;

    this.renderTable();
    return true;
  }

  initTablex() {
    this.initAllHeaders();
    const sessionColId = this.loadColumnSettingsFromLocal();
    
    this.tablexParam = {
      isLoadingTable: false,
      enableStickyHeader: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableColFilter: true,
      enableSort: true,
      minifyButton: true,
      pageSizeOptions: this.tablexPageSizeOptions,
      currentPageSize: this.tablexDefaultPageSize,
      currentPage: this.tablexDefaultPageNumber,
      pageCount: this.tablexDefaultPageCount,
      fullColNameList: this.tablexHeaders.map((col) => {
        return { id: col.id, name: col.name };
      }),
      selectedColId: sessionColId || this.tablexSelectedColId,
      sortOrder: this.tablexDefaultSortOrder,
      sortBy: this.tablexDefaultSortBy,
      filter: {},
      tableRow: 'row',
      onPageNumberClicked: this.onPageNumberClicked.bind(this),
      onPageSizeClicked: this.onPageSizeClicked.bind(this),
      onColFiltered: this.onColFiltered.bind(this),
      onSortOrderChanged: this.onSortOrderChanged.bind(this),
      headers: this.tablexHeaders,
    };
  }

  initAllHeaders() {
    this.tablexHeaders = [
      {
        id: 'objId',
        name: 'pages.job-list.table-column.id',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'd-none',
      },
      {
        id: 'pmJobNumber',
        name: 'pages.pm-job-list.table-column.pm-job-no',
        enableSort: true,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'pmPlan.pmPlanNumber',
        name: 'pages.pm-job-list.table-column.pm-plan-no',
        enableSort: true,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'jobDescription',
        name: 'pages.pm-job-list.table-column.description',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'period.periodStartDate',
        name: 'pages.pm-job-list.table-column.start-date',
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'period.periodEndDate',
        name: 'pages.pm-job-list.table-column.end-date',
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'status',
        name: 'pages.pm-job-list.table-column.status',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'pmPlan.scheduleType',
        name: 'pages.pm-job-list.table-column.schedule-type',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'pmPlan.frequency',
        name: 'pages.pm-job-list.table-column.frequency',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'pmPlan.vendorTeamName',
        name: 'pages.pm-job-list.table-column.handling-team',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'updatedAt',
        name: 'pages.pm-job-list.table-column.updated-date',
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
      {
        id: 'createdBy',
        name: 'pages.pm-job-list.table-column.created-by',
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col justify-content-center align-items-center d-flex',
      },
    ];
  }

  renderTable() {
    this.tablexParam.headers = this.tablexHeaders.filter((col) => {
      return this.tablexParam.selectedColId.includes(col.id);
    });
    this.tablexParam.content = this.pmJobs.map((element, index)=>{
      let {
        _id,
        pmJobNumber,
        pmPlan = {},
        jobDescription,
        period = {},
        status,
        updatedAt,
        createdBy
      } = element;
      
      let fullRow =  [
        _id,
        pmJobNumber ? {url: `/pm/jobs/${pmJobNumber}`, value: pmJobNumber} : '',
        pmPlan['pmPlanNumber'] ?  {url: `/pm/plans/${pmPlan['pmPlanNumber']}`, value: pmPlan['pmPlanNumber']} : '',
        jobDescription,
        period['periodStartDate'],
        period['periodEndDate'],
        status ? JMLanguage.translate(`pm-job.status.${status}`) : '',
        pmPlan['scheduleType'] ? JMLanguage.translate(`pm-plan.schedule-type.${pmPlan['scheduleType']}`) : '',
        pmPlan['frequency'] ? JMLanguage.translate(`pm-plan.frequency.${pmPlan['frequency']}`) : '',
        pmPlan['vendorTeamName'] ? pmPlan['vendorTeamName'] : '',
        updatedAt ? moment(updatedAt).format(Constants.DATETIME_FORMAT_2) : '',
        createdBy
      ];

      let rowFiltered = []
      fullRow.forEach((value, i) => {
        if (this.tablexParam.selectedColId.includes(this.tablexHeaders[i].id)) {
          rowFiltered.push(fullRow[i]);
        }
      });

      return rowFiltered;
    })
  }

  async onAdvancedSearch(searchCriteria) {
    if (searchCriteria) {
      this.translateFilters(searchCriteria);
    }
    await this.requestTableData(1);
    this.renderTable();
  };

  async onPageNumberClicked(pageNumber: number) {
    await this.requestTableData(pageNumber);
    this.renderTable();
  }

  async onPageSizeClicked(pageSize: number) {
    await this.requestTableData(1, pageSize);
    this.renderTable();
  }

  onColFiltered(selectedColId) {
    this.tablexParam.selectedColId = selectedColId;
    this.renderTable();
    this.saveColumnSettingsToLocal(this.tablexParam.selectedColId);
  }

  async onSortOrderChanged(header, sortOrder) {
    this.tablexParam.sortBy = header ? header : this.tablexDefaultSortBy;
    this.tablexParam.sortOrder = sortOrder;
    await this.requestTableData(1);
    this.renderTable();
  }

  translateFilters(searchCriteria) {
    const filters: { [x: string]: any } = {};
    const requestParams: {[x:string]:any} = {};
    const status = [];

    searchCriteria.contractNumber && searchCriteria.contractNumber.length > 0 && (requestParams.pmPlanContractNumbers = searchCriteria.contractNumber);
    searchCriteria.pmPlanNumber && (requestParams.pmPlanNumbers = [searchCriteria.pmPlanNumber]);
    searchCriteria.pmPeriod && (requestParams.periodIds = [searchCriteria.pmPeriod]);

    searchCriteria.pmJobNumber && (filters.pmJobNumber = searchCriteria.pmJobNumber);
    searchCriteria.jobDescription && (filters.jobDescription = searchCriteria.jobDescription);
    searchCriteria.equipmentNumber && (requestParams.equipmentNumberList = [searchCriteria.equipmentNumber]);
    searchCriteria.createdBy && (filters.createdBy = searchCriteria.createdBy);

    for (const key in searchCriteria.status) {
      searchCriteria.status[key] && status.push(key);
    }

    if (searchCriteria.startRange) {
      let field;
      switch (searchCriteria.startRange) {
        case 'on-or-before':
          field = 'toPeriodStartDate';
          break;
        case 'after':
          field = 'fromPeriodStartDate';
          break;
      }
      if (field && searchCriteria.startDate) {
        requestParams[field] = searchCriteria.startDate;
      }
    }

    if (searchCriteria.endRange) {
      let field;
      switch (searchCriteria.endRange) {
        case 'on-or-before':
          field = 'toPeriodEndDate';
          break;
        case 'after':
          field = 'fromPeriodEndDate';
          break;
      }
      if (field && searchCriteria.endDate) {
        requestParams[field] = searchCriteria.endDate;
      }
    }

    if (searchCriteria.createTimeFrom) {
      requestParams.fromCreateTime = stringToMoment(searchCriteria.createTimeFrom, 'YYYYMMDD').toISOString();
    }
    if (searchCriteria.createTimeTo) {
      requestParams.toCreateTime = stringToMoment(searchCriteria.createTimeTo, 'YYYYMMDD').toISOString();
    }

    let size = Object.keys(filters).length;
    this.searchFilters = size > 0 ? filters : null;
    this.searchParams = Object.keys(requestParams).length > 0 ? requestParams : null;
    this.searchStatus = status.length > 0 ? status : null;
    this.searchHandlingTeam = searchCriteria.handlingTeam.length > 0 ? searchCriteria.handlingTeam : null;
  }

  private saveColumnSettingsToLocal(columnSetting) {
    try {
      let cache = JSON.parse(JSON.stringify(columnSetting));
      Session.setPmJobSettingsColumns(cache);
    } catch (e) {
      console.warn(e);
    }
  }

  private loadColumnSettingsFromLocal() {
    try {
      let cache = Session.getPmJobSettingsColumns();
      if (cache) {
        return JSON.parse(JSON.stringify(cache));
      }
    } catch (e) {
      console.warn(e);
    }
  }
}
