<div class="wrapper">
  <app-nav [navbarTitle]="'pages.settings-vendor-info.title' | translate"></app-nav>
  <app-sidebar [contentType]="'settings'"></app-sidebar>

  <div id="content" class="content">
    
    <app-tab-menu [contentType]="'settings-general'"></app-tab-menu>

    <div class="row">
      <div class="col-xl-5">
        <div class="row justify-content-between mx-0">
          <div class="title">{{ 'pages.settings-vendor-info.title.summary' | translate }}</div>
          <div class="row justify-content-right mx-0">
            <div *ngIf="actionMode === ActionType.EDIT" class="pt-2 pr-2"><span class="red pr-1">*</span>{{ 'global.mandatory-fields' | translate }}</div>
            <app-icon-button *ngIf="actionMode === ActionType.VIEW && hasUpdatePermission"
              [id]="'vendor-info-edit-button'"
              [color]="'blue'"
              [icon]="'fa-edit'"
              (onClick)="onClickedEdit()" 
            >
            </app-icon-button>
            <app-icon-button *ngIf="actionMode === ActionType.EDIT"
              [id]="'vendor-info-dicard-button'"
              [color]="'red'"
              [icon]="'fa-times'"
              (onClick)="onClickedDiscard()" 
            >
            </app-icon-button>
            <app-icon-button *ngIf="actionMode === ActionType.EDIT"
              [id]="'vendor-info-submit-button'"
              [color]="'amber'"
              [icon]="'fa-check'"
              [isLoading]="isLoadingSubmit"
              (onClick)="onClickedSubmit()" 
            >
            </app-icon-button>
          </div>
        </div>
        <hr class="underline" />
        <div class="my-3">
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="vendor-info-vendor-name-text">
              {{ 'pages.settings-vendor-info.vendor-name' | translate }}
            </label>
            <div id="vendor-info-name-text" class="px-3 col-7">{{ vendorDetail.vendorName }}</div>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="vendor-info-vendor-number-text">
              {{ 'pages.settings-vendor-info.vendor-number' | translate }}
            </label>
            <div id="vendor-info-number-text" class="px-3 col-7">{{ vendorDetail.vendorNumber }}</div>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="vendor-info-post-name-prefix-text">
              {{ 'pages.settings-vendor-info.post-name-prefix' | translate }}
            </label>
            <div id="vendor-info-post-name-prefix-text" class="px-3 col-7">{{ vendorDetail.postNamePrefix }}</div>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="vendor-info-telephone-text">
              {{ 'pages.settings-vendor-info.telephone' | translate }}
            </label>
            <div id="vendor-info-telephone-text" class="px-3 col-7">{{ vendorDetail.telephone }}</div>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="vendor-info-fax-text">
              {{ 'pages.settings-vendor-info.fax' | translate }}
            </label>
            <div id="vendor-info-fax-text" class="px-3 col-7">{{ vendorDetail.fax }}</div>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="vendor-info-email-input">
              <span *ngIf="mandatoryFields.includes('email') && actionMode === ActionType.EDIT" class="red">*</span>
              {{ 'pages.settings-vendor-info.email' | translate }}
            </label>
            <div class="px-3 col-7">
              <input *ngIf="actionMode === ActionType.EDIT" id="vendor-info-email-input" type="text" class="form-control" [(ngModel)]="vendorDetail.email" [ngClass]="{'input-error': errorFields.includes('email')}"/>
              <div *ngIf="actionMode === ActionType.VIEW">{{ vendorDetail.email }}</div>
            </div>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="vendor-info-responsible-officer-dropdown">
              <span *ngIf="mandatoryFields.includes('responsibleOfficer') && actionMode === ActionType.EDIT" class="red">*</span>
              {{ 'pages.settings-vendor-info.responsible-officer' | translate }}
            </label>
            <div class="px-3 col-7">
              <ng-select 
                *ngIf="actionMode === ActionType.EDIT"
                id="vendor-info-responsible-officer-dropdown" 
                [items]="postList"
                bindValue="name"
                bindLabel="name"
                [(ngModel)]="vendorDetail.responsibleOfficer" 
                [ngClass]="{'input-error': errorFields.includes('responsibleOfficer')}"
              >
              </ng-select>
              <div *ngIf="actionMode === ActionType.VIEW">{{ vendorDetail.responsibleOfficer }}</div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

</div>
