import { Injectable } from '@angular/core';
import { RouteDetails } from 'src/app/core/route-util/route-details';

@Injectable({
  providedIn: 'root',
})
class RouteHelperClass {
  constructor() {}

  getPermissions(route) {
    if (!RouteDetails[route] || !RouteDetails[route].permissions) {
      return null;
    }
    return RouteDetails[route].permissions;
  }
}

let RouteHelper = new RouteHelperClass();
export { RouteHelper };
