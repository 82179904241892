<div class="wrapper">
  <app-nav [breadcrumbs]="breadcrumbs"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="content sn-view-content action-btn-padding">
    <div class="component-wrap row">
      <div class="col-12 col-xl-6">
        <app-notification-info [sn]="sn" class="d-block mb-5"></app-notification-info>
      </div>

      <div class="col-12 col-xl-6">
        <app-notification-progress [sn]="sn" class="d-block mb-4"></app-notification-progress>

        <!-- Remark Component -->
        <app-sn-remarks #remark_detail
          class="d-block mb-4"
          [sn]="sn"
          [pageMode]="currentPageMode"
          *ngIf="sn.remarks && sn.remarks.length > 0">
        </app-sn-remarks>

        <app-sn-cancel-remarks
          class="d-block mb-5"
          [sn]="sn"
        ></app-sn-cancel-remarks>
      </div>
    </div>
    
    <!-- Action Sidebar -->
    <ng-container *ngIf="actionSidebar.dataList && actionSidebar.dataList.length > 0">
      <app-action-sidebar-v2 [actionButtonList]="actionSidebar.dataList" [isDisabled]="actionSidebar.isDisabled"
        [isLoading]="actionSidebar.isLoading" (clickedButton)="onClickedActionButton($event)">
      </app-action-sidebar-v2>
    </ng-container>

    <!-- SN cancel editor -->
    <app-custom-slider-panel #sn_cancel_editor_panel [widthPx]=600 [customClass]="'sn_cancel_editor_panel'">
      <sn-cancel-editor #sn_cancel_editor [sn]="sn" (updatedSn)="onCancelledSn($event)"></sn-cancel-editor>
    </app-custom-slider-panel>

    <!-- SN reject editor -->
    <app-custom-slider-panel #sn_reject_editor_panel [widthPx]=600 [customClass]="'sn_reject_editor_panel'">
      <sn-reject-editor #sn_reject_editor [sn]="sn" (updatedSn)="onRejectedSn($event)"></sn-reject-editor>
    </app-custom-slider-panel>

    <!-- SN refer editor -->
    <app-custom-slider-panel #sn_refer_form_panel [widthPx]=600 [customClass]="'sn_refer_form_panel'">
      <sn-refer-form #sn_refer_form [sn]="sn" (updatedSn)="onReferredSn($event)"></sn-refer-form>
    </app-custom-slider-panel>

    <!-- Create follow up job editor -->
    <app-custom-slider-panel #create_follow_up_job_panel [widthPx]=600 [customClass]="'create_follow_up_job_panel'">
      <cm-create-follow-up-job-editor #create_follow_up_job [sn]="sn" (createdFollowUpJob)="onCreatedFollowUpJob($event)">
      </cm-create-follow-up-job-editor>
    </app-custom-slider-panel>
  </div>

</div>
