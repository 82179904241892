<form [formGroup]="infoForm">
  <div class="container-fluid mb-3">
    <div class="d-flex align-items-center justify-content-between my-3">
      <h3>
        {{ 'component.team-member-info-editor.title' | translate }}
      </h3>
      <app-loading-button  
        [id]="'save_button'"
        [text]="'global.save'"
        [buttonClass]="'w-2 standard brand-amber'"
        [iconClass]="'fa-check'"
        [isDisabled]="saveBtn.isLoading"
        [isLoading]="saveBtn.isLoading"
        (clickedButton)="onSaveBtnClicked()"
      ></app-loading-button>
    </div>

    <div class="row align-items-center my-3">
      <div class="col-12 mt-3 font-size-l bold">
        {{ 'component.team-member-info-editor.team' | translate}}: {{vendorTeam?.name}}
      </div>
      <div class="col-12 mt-3 font-size-l bold">
        {{ 'component.team-member-info-editor.account' | translate}}: {{post?.name}}
      </div>
      <div class="col-12 mt-3 font-size-l bold">
        {{ 'component.team-member-info-editor.staff-name' | translate}}: {{post?.employeeName}}
      </div>
      
      <div class="col-12 mt-5">
        {{ 'component.team-member-info-editor.remarks' | translate}}
      </div>
      <div class="col-12">
        <textarea
          id="remarks_input"
          class="form-control"
          rows="5"
          formControlName="remarks"
          [maxlength]="inputBoxMaxLength">
        </textarea>
        <span 
          *ngIf="remarks?.value?.length >= inputBoxMaxLength" 
          class="red small"
        >
          {{ 'slider.textarea.max-length' | translate:[inputBoxMaxLength]}}
        </span>
      </div>
    </div>

    <!-- <div class="row align-items-center my-3">
      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'global.mandatory-fields' | translate}}
      </div>
    </div> -->
  </div>
</form>
