import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class DateTimeInput {
  dateInput?: NgbDateStruct; // date input value
  timeInput?: string; // time input value
  value?: Date; // combine date input and time input value
  text: string = ''; // display name
  isError: boolean = false;
  isDisabled: boolean = false;
  isMandatory: boolean = false;

  constructor() {}
}
