import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-copy-office-hour',
  templateUrl: './form-copy-office-hour.component.html',
  styleUrls: ['./form-copy-office-hour.component.scss'],
})
export class FormCopyOfficeHourComponent implements OnInit {
  @Input() parameters: {
    isLoading: boolean;
  } = {
    isLoading: false,
  };

  constructor() {}

  ngOnInit(): void {}
}
