import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { JMENUM } from '@ccep/CCEPConnector-ts';

import { environment } from 'src/environments/environment';
import { LoginComponent } from './logins/login/login.component';
import { LoginOtpComponent } from './logins/login-otp/login-otp.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SettingsSubpageComponent } from './pages/settings-subpage/settings-subpage.component';
import { SettingsVendorInfoComponent } from './pages/settings-vendor-info/settings-vendor-info.component';
import { SettingsUserProfileComponent } from './pages/settings-user-profile/settings-user-profile.component';
import { SettingsUserManagementComponent } from './setting-users/settings-user-management/settings-user-management.component';
import { SettingsRoleManagementComponent } from './pages/settings-role-management/settings-role-management.component';

import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SettingContractComponent } from './setting-contracts/setting-contract/setting-contract.component';
import { SettingContractSeverityListComponent } from './setting-contracts/setting-contract-severity-list/setting-contract-severity-list.component';
import { SettingsTeamComponent } from './setting-teams/settings-team/settings-team.component';
import { SettingsContractorComponent } from './pages/settings-contractor/settings-contractor.component';
import { CmTaskCreateComponent } from './cm-tasks/cm-task-create/cm-task-create.component';
import { CmTaskViewComponent } from './cm-tasks/cm-task-view/cm-task-view.component';
import { CmTaskEditComponent } from './cm-tasks/cm-task-edit/cm-task-edit.component';
import { CmTaskAttachmentComponent } from './cm-tasks/cm-task-attachment/cm-task-attachment.component';
import { CmTaskListComponent } from './cm-tasks/cm-task-list/cm-task-list.component';
import { SelectEquipmentComponent } from './service-notifications/select-equipment/select-equipment.component';
import { NotificationListComponent } from './service-notifications/notification-list/notification-list.component';
import { SnViewComponent } from './service-notifications/sn-view/sn-view.component';
import { SettingContractSeverityDetailComponent } from './setting-contracts/setting-contract-severity-detail/setting-contract-severity-detail.component';
import { PmPlanListComponent } from './pm/pm-plan-list/pm-plan-list.component';
import { PmPlanCreateComponent } from './pm/pm-plan-create/pm-plan-create.component';
import { PmPlanViewComponent } from './pm/pm-plan-view/pm-plan-view.component';
import { PmPlanEditComponent } from './pm/pm-plan-edit/pm-plan-edit.component';
import { PmJobCreateComponent } from './pm/pm-job-create/pm-job-create.component';
import { PmJobViewComponent } from './pm/pm-job-view/pm-job-view.component';
import { PmJobEditComponent } from './pm/pm-job-edit/pm-job-edit.component';
import { PmJobListComponent } from './pm/pm-job-list/pm-job-list.component';
import { PmOutstandingEquipmentListComponent } from './pm/pm-outstanding-equipment-list/pm-outstanding-equipment-list.component';
import { PmJobAttachmentComponent } from './pm/pm-job-attachment/pm-job-attachment.component';
import { PmJobEquipmentListComponent } from './pm/pm-job-equipment-list/pm-job-equipment-list.component';
import { SettingUserEmsdComponent } from './setting-users/setting-user-emsd/setting-user-emsd.component';
import { PmPlanEquipmentListComponent } from './pm/pm-plan-equipment-list/pm-plan-equipment-list.component';
import { PmPeriodListComponent } from './pm/pm-period-list/pm-period-list.component';
import { PmPeriodEquipmentListComponent } from './pm/pm-period-equipment-list/pm-period-equipment-list.component';
import { SettingContractEmsdContactComponent } from './setting-contracts/setting-contract-emsd-contact/setting-contract-emsd-contact.component';
import { ReleaseNoteComponent } from './setting-release-note/release-note/release-note.component';
import { ResetPasswordComponent } from './logins/reset-password/reset-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login-otp', component: LoginOtpComponent },
  { path: 'reset/password', component: ResetPasswordComponent },
  { path: 'home', redirectTo: '/dashboard' },
  { path: 'dashboard', component: DashboardComponent }, //DashboardComponent
  {
    path: 'settings',
    children: [
      { path: '', component: SettingsComponent },
      {
        path: 'general',
        children: [
          { path: '', component: SettingsSubpageComponent },
          { path: 'vendor-info', component: SettingsVendorInfoComponent },
          { path: 'user-profile', component: SettingsUserProfileComponent },
        ],
      },
      {
        path: 'contract',
        children: [
          { path: '', component: SettingsSubpageComponent },
          { path: 'contract', component: SettingContractComponent },
          {
            path: 'contract-severity-list',
            children: [
              { path: '', component: SettingContractSeverityListComponent },
              { path: ':contractNumber', component: SettingContractSeverityListComponent }, // TODO: pre-select in contract dropdown
              {
                path: 'contract-severity',
                children: [{ path: ':contractSeverityId', component: SettingContractSeverityDetailComponent }],
              },
            ],
          },
          { path: 'emsd-contact', component: SettingContractEmsdContactComponent },

          { path: 'severity-view', component: ComingSoonComponent },
          { path: 'pm-plan', component: ComingSoonComponent },
        ],
      },
      { path: 'contractor-management', component: SettingsContractorComponent },
      { path: 'user', component: SettingsUserManagementComponent },
      { path: 'emsd-user', component: SettingUserEmsdComponent },
      {
        path: 'team',
        children: [
          { path: '', component: SettingsTeamComponent },
          {
            path: ':teamId',
            children: [
              { path: '', component: SettingsTeamComponent },
              { path: ':teamName', component: SettingsTeamComponent },
            ],
          },
        ],
      },
      { path: 'role', component: SettingsRoleManagementComponent },
      { path: 'release-note/:platform', component: ReleaseNoteComponent },
    ],
  },
  { path: 'pending-notification', component: NotificationListComponent }, //sn-list
  {
    path: 'cm-task',
    children: [
      { path: '', component: CmTaskListComponent },
      { path: 'create', component: CmTaskCreateComponent },
      {
        path: ':snNumber',
        children: [
          { path: '', component: SnViewComponent }, // TODO: TBC - update to sn/:snNumber for sn view
          {
            path: ':jobCardNumber',
            children: [
              { path: 'edit', component: CmTaskEditComponent },
              { path: 'attachment', component: CmTaskAttachmentComponent },
              { path: '', component: CmTaskViewComponent },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'pm',
    children: [
      {
        path: 'plans',
        children: [
          { path: '', component: PmPlanListComponent },
          { path: 'create', component: PmPlanCreateComponent },
          {
            path: ':planNumber',
            children: [
              { path: '', component: PmPlanViewComponent },
              { path: 'edit', component: PmPlanEditComponent },
              { path: 'equipment-list', component: PmPlanEquipmentListComponent },
              { path: 'equipment-list-view', component: PmPlanEquipmentListComponent, data: { mode: JMENUM.JMPageMode.VIEW } },
            ],
          },
        ],
      },
      { path: 'periods', children: [
        { path: ':planNumber/:periodId',
          children: [
            { path: 'equipment-list', component: PmPeriodEquipmentListComponent },
          ]
        },
        { path: '', component: PmPeriodListComponent },
      ] },
      { path: 'equipments/outstanding', component: PmOutstandingEquipmentListComponent },
      { path: 'jobs', children: [
        { path: 'create', component: PmJobCreateComponent },
        { path: ':jobNumber',
          children: [
          { path: 'edit', component: PmJobEditComponent },
          { path: 'attachment', component: PmJobAttachmentComponent },
          { path: 'equipment-list/view', component: PmJobEquipmentListComponent, data: { mode: JMENUM.JMPageMode.VIEW } },
          { path: 'equipment-list', component: PmJobEquipmentListComponent },
          { path: '', component: PmJobViewComponent },
        ]},
        { path: '', component: PmJobListComponent }
      ]},
    ],
  },
  { path: 'select-equipment/:jobCardNumber', component: SelectEquipmentComponent }, // TODO: changed to slider panel
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: '/dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: environment.useHash, enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
