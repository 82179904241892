import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PmJobService } from 'src/app/pm/shared/pm-job.service'
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Session } from 'src/app/core/session';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';

@Component({
  selector: 'app-pm-job-equipment-info',
  templateUrl: './pm-job-equipment-info.component.html',
  styleUrls: ['./pm-job-equipment-info.component.scss']
})
export class PmJobEquipmentInfoComponent implements OnInit {
  @Input() periodId: string;
    @Input() pmJobNumber: string;
    @Input() pageMode: JMENUM.JMPageMode;
    @Input() selectedEquipmentsNumber: number;

    // pmJobService: PmJobService

    editable: any = {};

    totalEquipments : number = 0;
    JMPageMode = JMENUM.JMPageMode;

    constructor(private router: Router, private pmJobService: PmJobService) {
    }

    ngOnInit() {
        this.fieldsControl();
    }

    ngOnChanges(changes:SimpleChanges){
      if(changes.periodId){
        if(changes.periodId.currentValue) {
            this.requestEquipmentInformation(changes.periodId.currentValue);
        } else {
            this.totalEquipments = 0;
        }
      }
    }

    private fieldsControl(){
      this.editable = {
      }
      // this.mandatory = {...this.editable};
    }

    private async requestEquipmentInformation(periodId : string) {
      const response = await this.pmJobService.getPmJobUnassignedEquipmentList(periodId);
      if(response && response.payload){
        this.totalEquipments = response.payload.totalCount ? response.payload.totalCount : 0;
      }
    }

    equipmentText() {
        // (selectedEquipments ? selectedEquipments : 0) + ' of ' + totalEquipments + ' selected'
        let selected = this.selectedEquipmentsNumber ? this.selectedEquipmentsNumber : 0;
        return JMLanguage.translate('component.pm-job-equipment-info.number-of-equipments', [selected, this.totalEquipments]);
    }

    onDetailBtnClick = (e:MouseEvent) => {
      e.preventDefault();
      this.router.navigateByUrl(
        `/pm/jobs/${this.pmJobNumber}/equipment-list/view`
      );
    }

}
