import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';
import { UserHelper } from 'src/app/core/user-helper';
import { ActionSidebarV2Service } from '../../shared/action-sidebar-v2.service';
import { ActionButtonI } from '../../shared/action-sidebar-v2/action-sidebar-v2.model';
import { CmAdditionalInfoComponent } from '../shared/cm-additional-info/cm-additional-info.component';
import { CmClientInfoComponent } from '../shared/cm-client-info/cm-client-info.component';
import { CmProgressDetailsComponent } from '../shared/cm-progress-details/cm-progress-details.component';
import { CmTaskDescriptionComponent } from '../shared/cm-task-description/cm-task-description.component';
import { CmTaskProgressComponent } from '../shared/cm-task-progress/cm-task-progress.component';
import { CmTaskUpdateActionButtonList } from './cm-task-edit.model';

@Component({
  selector: 'app-cm-task-edit',
  templateUrl: './cm-task-edit.component.html',
  styleUrls: ['./cm-task-edit.component.scss'],
})
export class CmTaskEditComponent implements OnInit {
  @ViewChild(CmTaskDescriptionComponent, { static: false }) taskDescriptionElem: CmTaskDescriptionComponent;
  @ViewChild(CmClientInfoComponent, { static: false }) clientInfoElem: CmClientInfoComponent;
  @ViewChild(CmTaskProgressComponent, { static: false }) taskProgressElem: CmTaskProgressComponent;
  @ViewChild(CmProgressDetailsComponent, { static: false }) progressDetailsElem: CmProgressDetailsComponent;
  @ViewChild(CmAdditionalInfoComponent, { static: false }) additionalInfoElem: CmAdditionalInfoComponent;

  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.EDIT;

  breadcrumbs: any = [];
  sn: JMOBJ.ServiceNotification = new JMOBJ.ServiceNotification();
  jobCard: JMOBJ.JobCard = new JMOBJ.JobCard();
  actionList: Array<string>;
  jobCardNumber: string;
  contract?: JMOBJ.MaintenanceTermContract;
  equipmentClient?: {
    clientNumber: string;
    clientShortName: string;
  };
  contractSeverity?: JMOBJ.ContractSeverity;

  // action buttons
  actionSidebar: {
    dataList: ActionButtonI[];
    isDisabled: boolean;
    isLoading: boolean;
  } = {
    dataList: [],
    isDisabled: false,
    isLoading: false,
  };

  constructor(
    private _location: Location,
    private actionSidebarV2Service: ActionSidebarV2Service,
    private route: ActivatedRoute
  ) {}

  async ngOnInit(): Promise<void> {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }

    this.jobCardNumber = this.route.snapshot.paramMap.get('jobCardNumber');
    if (this.jobCardNumber) {
      this.setBreadcrumbs();
      await this.requestCMTask();

      if (!(Array.isArray(this.actionList) && this.actionList.includes(JMENUM.VpCmTaskAction.UPDATE))) {
        AppDelegate.routeToPage('/home');
        return;
      }

      if (this.jobCard.contract) {
        let contract = new JMOBJ.MaintenanceTermContract();
        contract.contractNumber = this.jobCard.contract.contractNumber;
        contract.title = this.jobCard.contract.title;
        contract.liftTrapped = this.jobCard.contract.liftTrapped;
        this.contract = contract;
      }
      if (this.jobCard.contractSeverity) {
        let contractSeverity = new JMOBJ.ContractSeverity();
        contractSeverity._id = this.jobCard.contractSeverity.id;
        contractSeverity.description = this.jobCard.contractSeverity.description;
        contractSeverity.name = this.jobCard.contractSeverity.name;
        contractSeverity.liftTrapped = this.jobCard.contractSeverity.liftTrapped;
        contractSeverity.responseToClient = this.jobCard.contractSeverity.responseToClient;
        this.contractSeverity = contractSeverity;
      }

      this.resetActionBar();
      this.requestVpSn();
    }
  }

  //------------------------
  // API
  private async requestCMTask() {
    let request = new JM.JMRequestJobCardsGetCmTask();
    request.jobCardNumber = this.jobCardNumber;

    this.actionSidebar.isLoading = true;
    const response: JM.JMResponseJobCardsGetCmTask = await AppDelegate.sendJMRequest(request);
    this.actionSidebar.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.jobCard) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.jobCard = response.payload.jobCard;
    this.actionList = response.payload.actionList;
  }

  private async requestVpSn() {
    let request = new JM.JMRequestSnGetVpSn();
    request.snNumber = this.jobCard.snNumber;

    const response: JM.JMResponseSnGetVpSn = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.sn) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.sn = response.payload.sn;
    this.setChildComponentValues();
  }

  private async requestUpdateCmTask() {
    let request = new JM.JMRequestJobCardsUpdateCmTask();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;

    this.taskDescriptionElem.setUpdateCmTaskData(request);
    this.clientInfoElem.setUpdateCmTaskData(request);
    this.taskProgressElem.setUpdateCmTaskData(request);
    this.progressDetailsElem.setUpdateCmTaskData(request);

    this.actionSidebar.isLoading = true;
    const response: JM.JMResponseJobCardsUpdateCmTask = await AppDelegate.sendJMRequest(request);
    this.actionSidebar.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.updated'));

    const jobCard = response.payload.jobCard;
    if (jobCard) {
      AppDelegate.routeToPage(`/cm-task/${jobCard.snNumber}/${jobCard.jobCardNumber}`);
      return;
    }
  }

  private setChildComponentValues() {
    this.taskDescriptionElem.setValuesInEditMode(this.jobCard, this.contract, this.contractSeverity);
    this.clientInfoElem.setValuesInEditMode(this.jobCard);
    this.taskProgressElem.setValuesInEditMode(this.jobCard);
    this.progressDetailsElem.setValuesInEditMode(this.jobCard);
  }

  //------------------------
  // UI function
  async onUpdatedEquipment(equipment?: JMOBJ.Equipment) {
    this.sn.client = equipment ? equipment.clientShortName : undefined;
    this.equipmentClient = equipment ? {
      clientNumber: equipment.clientNumber,
      clientShortName: equipment.clientShortName,
    } : undefined;
  }

  onUpdatedContractSeverity(contractSeverity?: JMOBJ.ContractSeverity) {
    this.contractSeverity = contractSeverity;
  }

  private setBreadcrumbs() {
    const jobCardNumberArr = this.jobCardNumber.split('-');
    const snNumber = jobCardNumberArr[0];
    const taskNumber = JMLanguage.translate('pages.cm-task-edit.breadcrumbs.task-number', [jobCardNumberArr[1]]);
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-sn-number',
        name: snNumber,
        route: `/cm-task/${snNumber}`,
      },
      {
        id: 'breadcrumbs-task-number',
        name: taskNumber,
        route: null,
        currentPage: true,
      },
    ];
  }

  //------------------------
  // action button function
  onClickedActionButton(actionButton: ActionButtonI) {
    actionButton.handler();
  }

  private resetActionBar(): void {
    const actionList = ['save', 'close'];

    this.filterActionButton(actionList);
  }

  private filterActionButton(actionList: string[]) {
    for (const action of actionList) {
      const button: ActionButtonI = CmTaskUpdateActionButtonList[action];
      const hasPermissionList = button && button.permissionList && button.permissionList.length;

      if (hasPermissionList) {
        const allowAction = UserHelper.hasEitherOnePermission(button.permissionList);
        if (allowAction) {
          this.addActionButton(button);
        }
      } else {
        this.addActionButton(button);
      }
    }
  }

  private addActionButton(actionButton: ActionButtonI) {
    let handler = () => {};

    switch (actionButton.action) {
      case 'save':
        handler = () => {
          const buttonHandler = () => {
            this.clickedUpdateCmTaskButton();
          };
          actionButton.popUpTitle = 'action.button.popup.confirm-save';
          if (this.taskDescriptionElem.equipmentInput.value) {
            const equipment = this.taskDescriptionElem.equipmentInput.value;
            if (this.sn.client != equipment.clientShortName) {
              actionButton.popUpTitle = 'action.button.popup.confirm-save.cm-task.diff-client';
            } else if (this.sn.location != equipment.location) {
              actionButton.popUpTitle = 'action.button.popup.confirm-save.cm-task.diff-location';
            }
          }
          this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, buttonHandler);
        };
        break;
      case 'close':
        handler = () => {
          const buttonHandler = () => {
            this._location.back();
          };
          this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, buttonHandler);
        };
        break;
      default:
        break;
    }

    actionButton.handler = handler;
    this.actionSidebar.dataList.push(actionButton);
  }

  private clickedUpdateCmTaskButton() {
    if (!this.taskProgressElem.validateFieldsFormat()) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.invalid-format'));
      return;
    }

    if (!this.isInputtedMandatoryFields()) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.missing-mandatory-fields'));
      return;
    }

    const isValid = this.isValidFields();
    if (isValid) {
      this.requestUpdateCmTask();
    }
  }

  private isInputtedMandatoryFields(): boolean {
    const isValidTaskDescriptionElem = this.taskDescriptionElem.validateMandatoryFields();
    const isValidClientInfoElem = this.clientInfoElem.validateMandatoryFields();
    // const isValidTaskProgressElem = this.taskProgressElem.validateMandatoryFields();
    const isValidProgressDetailsElem = this.progressDetailsElem.validateMandatoryFields();

    const isValid =
      isValidTaskDescriptionElem &&
      isValidClientInfoElem &&
      // isValidTaskProgressElem &&
      isValidProgressDetailsElem;

    return isValid;
  }

  private isValidFields(): boolean {
    const isValidTaskDescriptionElem = this.taskDescriptionElem.validateData();
    const isValidClientInfoElem = this.clientInfoElem.validateData();
    const isValidTaskProgressElem = this.taskProgressElem.validateData();
    const isValidProgressDetailsElem = this.progressDetailsElem.validateData();

    const isValid =
      isValidTaskDescriptionElem &&
      isValidClientInfoElem &&
      isValidTaskProgressElem &&
      isValidProgressDetailsElem;

    return isValid;
  }

  //------------------------
  // get property
}
