<div class="d-flex justify-content-between" >
  <div class="font-size-xl">
    {{ "component.cm-additional-info.title" | translate }}
  </div>
</div>
<hr class="underline" />
<div class="row mb-3 mx-0 align-items-center" *ngIf="isViewMode">
  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.cm-client-info.label.client" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="cm-additional-info-client" [textField]="client">
    </app-display-text-field>
  </div>
</div>

<div class="row mb-3 mx-0 align-items-center" *ngIf="isCreateMode || isEditMode">
  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    <span class="red">*</span>
    {{ "component.cm-additional-info.label.fault-type" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <ng-select class="px-0" id="edit-cm-additional-info-fault-type" [class.input-error]="errorFields.faultType"
      [disabled]="!contract"
      [items]="faultTypeOptions" bindLabel="label" bindValue="value" [(ngModel)]="faultType"
      (change)="onChangeFaultType()" >
      <ng-template ng-option-tmp let-item="item">
        <div [title]="item.label">{{item.label}}</div>
      </ng-template>
    </ng-select>
  </div>
</div>
