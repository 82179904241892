import { AbstractControl, ValidationErrors } from '@angular/forms';

export class StringValidator {
  static noWhiteSpaceBothEnd(control: AbstractControl): ValidationErrors | null {
    if (typeof control.value === 'string' && (control.value as string).trim().length === 0) {
      return { noWhiteSpaceBothEnd: true };
    }
    return null;
  }
}
