import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { GpsLocationSubmittedTableComponent } from 'src/app/shared/gps-location-submitted-table/gps-location-submitted-table.component'

@Component({
  selector: 'app-arrival-gps',
  templateUrl: './arrival-gps.component.html',
  styleUrls: ['./arrival-gps.component.scss']
})
export class ArrivalGpsComponent{
  @Input() arrivalGpsParam: any;

  constructor(public dialog: MatDialog) { }

  onClickGpsTableBtn = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'gps-location-submitted-table-container';
    dialogConfig.data = this.arrivalGpsParam
    dialogConfig.autoFocus = false;
    this.showPopUpGpsLocationSubmittedTable(dialogConfig);
  }

  showPopUpGpsLocationSubmittedTable(config: MatDialogConfig) {
    this.dialog.open(GpsLocationSubmittedTableComponent, config);
  }

}

