import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';

export function ngbDateToString(date: NgbDateStruct | undefined, delimiter?: string): string | undefined {
  if (date) {
    if (delimiter) {
      return (
        date.year.toString() +
        delimiter +
        date.month.toString().padStart(2, '0') +
        delimiter +
        date.day.toString().padStart(2, '0')
      );
    } else {
      return date.year.toString() + date.month.toString().padStart(2, '0') + date.day.toString().padStart(2, '0');
    }
  }
  return undefined;
}

export function stringToNgbDate(value: string | undefined, format?: any): NgbDateStruct | undefined {
  if (value) {
    const date = format ? moment(value, format) : moment(value);

    return {
      year: parseInt(date.year().toString()),
      month: parseInt((date.month() + 1).toString()),
      day: parseInt(date.date().toString()),
    };
  }
  return undefined;
}

export function stringToMoment(value: string | undefined, format?: string): moment.Moment | undefined {
  if (value) {
    const momentValue = format ? moment(value) : moment(value, format);
    if (momentValue.isValid()) {
      return momentValue;
    }
    return undefined;
  }
  return undefined;
}

export function formatDate(value: Date | undefined, style): string | undefined {
  if (value) {
    if (moment(value).isValid()) {
      return moment(value).format(style).toString();
    }
    return undefined;
  }
  return undefined;
}

export function formatDateTimeWithWeek(date: any) {
  // YYYY-MM-DD (ddd) HH:mm
  let result = undefined;
  if (moment(date).isValid()) {
    result =
      moment(date).format(Constants.DATE_FORMAT) +
      ' (' +
      JMLanguage.translate('global.weekday.abbr.' + moment(date).format('d')) +
      ') ' +
      moment(date).format('HH:mm');
  }
  return result;
}

export function formatDateWithWeek(date: any) {
  // YYYY-MM-DD (ddd)
  let result = undefined;
  if (moment(date).isValid()) {
    result =
      moment(date).format(Constants.DATE_FORMAT) +
      ' (' +
      JMLanguage.translate('global.weekday.abbr.' + moment(date).format('d')) +
      ') ';
  }
  return result;
}

//====================================================
/* file format */
export function base64ToBlob(base64: any, contentType: any) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}

//====================================================
/* string format */

// Deprecatet
export function inputNumber(event: any) {
  event.target.value = event.target.value.replace(/[^\d]/g, '');
}

export function trimString(value?: string): string | undefined {
  if (!value) {
    return undefined;
  }
  if (!value.trim().length) {
    return undefined;
  }

  return value.trim();
}

export function filterNumber(value?: string): string | undefined {
  if (!value) {
    return undefined;
  }
  const newValue = value.replace(/[^\d]/g, '');
  return newValue.length ? newValue : undefined;
}