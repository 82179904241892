import { Injectable } from '@angular/core';
import { JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';

@Injectable({
  providedIn: 'root'
})
export class CmTaskService {

  constructor() { }

  hasFollowUpJob(sn: JMOBJ.ServiceNotification): boolean {
    if (!sn || !sn.jobCards) {
      return undefined;
    }

    return sn.jobCards.length > 1;
  }

  isFirstAttendedJob(jobCard: JMOBJ.JobCard): boolean {
    if (!jobCard || !jobCard.jobCardNumber) {
      return undefined;
    }

    const tmpArr = jobCard.jobCardNumber.split('-');
    return tmpArr.length > 1 && tmpArr[1] == '01';
  }

  isSlaJob(jobCard: JMOBJ.JobCard): boolean {
    if (!jobCard || !jobCard.orderType) {
      return undefined;
    }

    return jobCard.orderType == JMENUM.OrderType.SLA_JOB;
  }
}
