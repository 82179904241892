import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { UserHelper } from 'src/app/core/user-helper';

@Component({
  selector: 'app-setting-contract-severity-detail',
  templateUrl: './setting-contract-severity-detail.component.html',
  styleUrls: ['./setting-contract-severity-detail.component.scss'],
})
export class SettingContractSeverityDetailComponent implements OnInit {
  contract?: JMOBJ.MaintenanceTermContract;
  contractSeverity?: JMOBJ.ContractSeverity;
  contractSeverityId?: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }

    // get route value
    const contractSeverityId = this.route.snapshot.paramMap.get('contractSeverityId');

    if (contractSeverityId) {
      this.requestContractSeverity(contractSeverityId);
    }
  }

  //------------------------
  // api
  private async requestContractSeverity(contractSeverityId: string) {
    let request = new JM.JMRequestContractsGetContractSeverity();
    request.id = contractSeverityId;

    const response: JM.JMResponseContractsGetContractSeverity = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.contractSeverity = response.payload;
  }

  // ----------- get property function ----------- //
  get hasUpdatePermission(): boolean {
    return UserHelper.hasPermission(JMENUM.VpPermission.SEVERITY_UPDATE);
  }
}
