<div class="wrapper">
  <app-nav [breadcrumbs]="breadcrumbs"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="content cm-task-list-content action-btn-padding">
    <div class="font-size-xl align-self-end">
      {{ "pages.select-equipment.title" | translate}}
    </div>
    <hr class="underline" />
    <app-tablex id="equipment-list-table" class="" [tablexParam]='tablexParam'></app-tablex>

    <!-- Action Sidebar -->
    <ng-container *ngIf="actionButtonData && actionButtonData.length > 0">
      <app-action-sidebar #select_equipment_action_bar [buttonData]="actionButtonData" [disabled]="disableActionSideBar"
        (clickEvent)="onActionButtonClicked($event)">
      </app-action-sidebar>
    </ng-container>
  </div>

</div>
