import { JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Language } from 'src/app/core/JMLanguage/JMLanguage';

/*
  This class store all the sessino variables of current logged in user
  including the
  
    1. CM related session: current user name, user object, user token,
    2. CM related cache
    3. input/selected value of each page
    4. user preference: selected language, locale etc
*/

class SessionClass {
  // settings
  public rememberUsername: string = null;
  public userInfo: JMOBJ.Post = null;
  public userToken: string = null;
  public userWorkCentres: [] = [];
  public selectedLanguage: JMENUM.Language = null;
  public dashboardSelectedContract: string[] = null;
  public featureList: {[x:string]:any}[] = null;
  // page variables, naming convention: <page name><variable name>
  public snListGeneralSearchKeyword: string = null;
  public cmTaskListGeneralSearchKeyword: string = null;
  public pmTaskListGeneralSearchCriteria: Map <string,any>=new Map();
  public pwReminderDisableList: {[x:string]: Array<string>} = null;


  // constant for local storage
  private readonly REMEMBER_USERNAME: string = 'REMEMBER_USERNAME';
  private readonly USER_INFO: string = 'USER_INFO';
  private readonly USER_TOKEN: string = 'USER_TOKEN';
  private readonly SELECTED_LANGUAGE: string = 'SELECTED_LANGUAGE';

  private readonly OTP_POST_NAME: string = 'OTP_POST_NAME';
  private readonly OTP_PREFIX: string = 'OTP_PREFIX';
  private readonly OTP_SECRET_KEY: string = 'OTP_SECRET_KEY';
  private readonly OTP_MOBILE: string = 'OTP_MOBILE';
  private readonly OTP_RESEND_TIMER_SECONDS: string = 'OTP_RESEND_TIMER_SECONDS';
  private readonly USER_WORKCENTRES: string = 'USER_WORKCENTRES';

  private readonly CLIENT_DICT: string = 'CLIENT_DICT';
  private readonly EQUIPMENT_CATEGORY: string = 'EQUIPMENT_CATEGORY';
  private readonly EQUIPMENT_TYPE: string = 'EQUIPMENT_TYPE';

  private readonly SETTING_SN_LIST_COLUMNS: string = 'SETTING_SN_LIST_COLUMNS';
  private readonly SETTING_SN_LIST_SEARCH_FILTERS: string = 'SETTING_SN_LIST_SEARCH_FILTERS';
  private readonly SETTING_CM_TASK_LIST_COLUMNS: string = 'SETTING_CM_TASK_LIST_COLUMNS';
  private readonly SETTING_CM_TASK_LIST_SEARCH_FILTERS: string = 'SETTING_CM_TASK_LIST_SEARCH_FILTERS';
  private readonly SETTING_PM_PLAN_LIST_COLUMNS: string = 'SETTING_PM_PLAN_LIST_COLUMNS';
  private readonly SETTING_PM_PLAN_LIST_SEARCH_FILTERS: string = 'SETTING_PM_PLAN_LIST_SEARCH_FILTERS';
  private readonly SETTING_PM_JOB_LIST_COLUMNS: string = 'SETTING_PM_JOB_LIST_COLUMNS';
  private readonly SETTING_PM_JOB_LIST_SEARCH_FILTERS: string = 'SETTING_PM_JOB_LIST_SEARCH_FILTERS';
  private readonly DASHBOARD_SELECTED_CONTRACT: string = "dashboard-selected-contract";
  private readonly SN_LIST_GENERAL_SEARCH_KEYWORD:string = "general-search-keywords";
  private readonly SETTING_PM_Equipment_LIST_SEARCH_FILTERS: string = 'SETTING_PM_EQUIPMENT_LIST_SEARCH_FILTERS';
  private readonly SETTING_PM_OUTSTANDING_EQUIPMENT_LIST_COLUMNS: string = 'SETTING_PM_OUTSTANDING_EQUIPMENT_LIST_COLUMNS';

  private readonly FEATURE_LIST:string = "feature-list";
  private readonly PW_REMINDER: string = "pw-reminder";

  constructor() {
    this.rememberUsername = this.get(this.REMEMBER_USERNAME);
    this.userInfo = this.get(this.USER_INFO);
    this.userToken = this.get(this.USER_TOKEN);
    this.userWorkCentres = this.get(this.USER_WORKCENTRES);

    this.selectedLanguage = this.get(this.SELECTED_LANGUAGE) ? this.get(this.SELECTED_LANGUAGE) : JMENUM.Language.EN;
    this.dashboardSelectedContract = this.get(this.DASHBOARD_SELECTED_CONTRACT);
    this.snListGeneralSearchKeyword = this.get(this.SN_LIST_GENERAL_SEARCH_KEYWORD);

    this.featureList = this.get(this.FEATURE_LIST);
    
    this.pwReminderDisableList = this.get(this.PW_REMINDER);
  }

  //--------------------------------------------------------------------
  private set(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  private get(key: string): any {
    let value = localStorage.getItem(key);

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  public clear(): void {
    localStorage.removeItem(this.USER_INFO);
    localStorage.removeItem(this.USER_TOKEN);
    localStorage.removeItem(this.USER_WORKCENTRES);
    localStorage.removeItem(this.DASHBOARD_SELECTED_CONTRACT);

    localStorage.removeItem(this.SN_LIST_GENERAL_SEARCH_KEYWORD);
    this.snListGeneralSearchKeyword = null;

    this.dashboardSelectedContract= null;
    this.userInfo = null;
    this.userToken = null;
    this.userWorkCentres = null;

    localStorage.removeItem(this.FEATURE_LIST);
    this.featureList = null;

    this.clearSnSettingsSearchFilters();
    this.clearCmTaskSettingsSearchFilters();

    this.clearPmPlanSettingsSearchFilters();
    this.clearPmPlanSettingsColumns();
    this.clearPmJobSettingsSearchFilters();
    this.clearPmJobSettingsColumns();
    this.clearPmEquipmentSearchFilters();
    this.clearPmOutstandingEquipmentListSettingsColumns();

    sessionStorage.clear();
  }

  //--------------------------------------------------------------------
  public setRememberUsername(username: string): void {
    this.set(this.REMEMBER_USERNAME, username);
    this.rememberUsername = username;
  }
  public setUserInfo(value: JMOBJ.Post): void {
    this.set(this.USER_INFO, value);
    this.userInfo = value;
  }
  public setUserToken(value: string): void {
    this.set(this.USER_TOKEN, value);
    this.userToken = value;
  }
  public setUserWorkCentres(value: []): void {
    this.set(this.USER_WORKCENTRES, value);
    this.userWorkCentres = value;
  }
  public setSelectedLanguage(value: Language) {
    this.set(this.SELECTED_LANGUAGE, value);
    this.selectedLanguage = value;
  }

  public setOtpDetail(postName, otpDetail) {
    if (!postName || !otpDetail) {
      return;
    }

    let otpPrefix = otpDetail.otpPrefix ? otpDetail.otpPrefix : '';
    let secretKey = otpDetail.secretKey ? otpDetail.secretKey : '';
    let mobile = otpDetail.mobile ? otpDetail.mobile : '';
    let resendTimerSeconds = otpDetail.resendTimerSeconds ? otpDetail.resendTimerSeconds : '';

    this.set(this.OTP_POST_NAME, postName);
    this.set(this.OTP_PREFIX, otpPrefix);
    this.set(this.OTP_SECRET_KEY, secretKey);
    this.set(this.OTP_MOBILE, mobile);
    this.set(this.OTP_RESEND_TIMER_SECONDS, '' + resendTimerSeconds);
  }

  public getOtpDetail() {
    let postName = this.get(this.OTP_POST_NAME);
    let otpPrefix = this.get(this.OTP_PREFIX);
    let secretKey = this.get(this.OTP_SECRET_KEY);
    let mobile = this.get(this.OTP_MOBILE);
    let resendTimerSeconds = this.get(this.OTP_RESEND_TIMER_SECONDS);

    if (!postName || !otpPrefix || !secretKey || !mobile || !resendTimerSeconds) {
      return null;
    }

    return {
      postName: postName,
      otpPrefix: otpPrefix,
      secretKey: secretKey,
      mobile: mobile,
      resendTimerSeconds: parseInt(resendTimerSeconds),
    };
  }

  public clearOtpDetail() {
    localStorage.removeItem(this.OTP_POST_NAME);
    localStorage.removeItem(this.OTP_PREFIX);
    localStorage.removeItem(this.OTP_SECRET_KEY);
    localStorage.removeItem(this.OTP_MOBILE);
    localStorage.removeItem(this.OTP_RESEND_TIMER_SECONDS);
  }

  public setClient(value: {}) {
    this.set(this.CLIENT_DICT, value);
  }

  public setEquipmentCategory(value: {}) {
    this.set(this.EQUIPMENT_CATEGORY, value);
  }

  public setEquipmentType(value: {}) {
    this.set(this.EQUIPMENT_TYPE, value);
  }

  public getClient() {
    return this.get(this.CLIENT_DICT);
  }

  public getEquipmentCategory() {
    return this.get(this.EQUIPMENT_CATEGORY);
  }

  public getEquipmentType() {
    return this.get(this.EQUIPMENT_TYPE);
  }

  public setFeatureList(list: {[x:string]:any}[]) {
    const featureList = list.filter(ele => ele.enabled).map(ele => {
      return {
        key: ele.key,
        enabled: ele.enabled,
      }
    });
    this.set(this.FEATURE_LIST, featureList);
    this.featureList = featureList;
  }


  //-------------------
  // page variable
  // SN
  public setSnListGeneralSearchKeyword(value:string) {
    this.set(this.SN_LIST_GENERAL_SEARCH_KEYWORD, value);
    this.snListGeneralSearchKeyword = value;
  }

  public setSnListSettingsColumns(value: any) {
    this.set(this.SETTING_SN_LIST_COLUMNS, value);
  }

  public setSnSettingsSearchFilters(value: any) {
    this.set(this.SETTING_SN_LIST_SEARCH_FILTERS, value);
  }

  public getSnListSettingsColumns() {
    return this.get(this.SETTING_SN_LIST_COLUMNS);
  }

  public getSnSettingsSearchFilters() {
    return this.get(this.SETTING_SN_LIST_SEARCH_FILTERS);
  }

  public clearSnListSettingsColumns() {
    localStorage.removeItem(this.SETTING_SN_LIST_COLUMNS);
  }

  public clearSnSettingsSearchFilters() {
    localStorage.removeItem(this.SETTING_SN_LIST_SEARCH_FILTERS);
  }

  // CM
  public setCmTaskSettingsSearchFilters(value: any) {
    this.set(this.SETTING_CM_TASK_LIST_SEARCH_FILTERS, value);
  }

  public setCmTaskSettingsColumns(value: any) {
    this.set(this.SETTING_CM_TASK_LIST_COLUMNS, value);
  }

  public getCmTaskSettingsSearchFilters() {
    return this.get(this.SETTING_CM_TASK_LIST_SEARCH_FILTERS);
  }

  public getCmTaskSettingsColumns() {
    return this.get(this.SETTING_CM_TASK_LIST_COLUMNS);
  }

  public clearCmTaskSettingsSearchFilters() {
    localStorage.removeItem(this.SETTING_CM_TASK_LIST_SEARCH_FILTERS);
  }

  public clearCmTaskSettingsColumns() {
    localStorage.removeItem(this.SETTING_CM_TASK_LIST_COLUMNS);
  }
  
  // PM Plan
  public setPmPlanSettingsSearchFilters(value: any) {
    this.set(this.SETTING_PM_PLAN_LIST_SEARCH_FILTERS, value);
  }

  public setPmPlanSettingsColumns(value: any) {
    this.set(this.SETTING_PM_PLAN_LIST_COLUMNS, value);
  }

  public getPmPlanSettingsSearchFilters() {
    return this.get(this.SETTING_PM_PLAN_LIST_SEARCH_FILTERS);
  }

  public setDashboardSelectedContract(value:string[]) {
    this.set(this.DASHBOARD_SELECTED_CONTRACT, value);
    this.dashboardSelectedContract = value;
  }

  public getPmPlanSettingsColumns() {
    return this.get(this.SETTING_PM_PLAN_LIST_COLUMNS);
  }

  public clearPmPlanSettingsSearchFilters() {
    localStorage.removeItem(this.SETTING_PM_PLAN_LIST_SEARCH_FILTERS);
  }

  public clearPmPlanSettingsColumns() {
    localStorage.removeItem(this.SETTING_PM_PLAN_LIST_COLUMNS);
  }

  // PM Job
  public setPmJobSettingsSearchFilters(value: any) {
    this.set(this.SETTING_PM_JOB_LIST_SEARCH_FILTERS, value);
  }

  public setPmJobSettingsColumns(value: any) {
    this.set(this.SETTING_PM_JOB_LIST_COLUMNS, value);
  }

  public getPmJobSettingsSearchFilters() {
    return this.get(this.SETTING_PM_JOB_LIST_SEARCH_FILTERS);
  }

  public getPmJobSettingsColumns() {
    return this.get(this.SETTING_PM_JOB_LIST_COLUMNS);
  }

  public clearPmJobSettingsSearchFilters() {
    localStorage.removeItem(this.SETTING_PM_JOB_LIST_SEARCH_FILTERS);
  }

  public clearPmJobSettingsColumns() {
    localStorage.removeItem(this.SETTING_PM_JOB_LIST_COLUMNS);
  }
  // PM Equipment
  public setPmEquipmentSearchFilters(value: any) {
    this.set(this.SETTING_PM_Equipment_LIST_SEARCH_FILTERS, value);
  }

  public getPmEquipmentSearchFilters() {
    return this.get(this.SETTING_PM_Equipment_LIST_SEARCH_FILTERS);
  }

  public clearPmEquipmentSearchFilters() {
    localStorage.removeItem(this.SETTING_PM_Equipment_LIST_SEARCH_FILTERS);
  }

  public setPmOutstandingEquipmentListSettingsColumns(value: any) {
    this.set(this.SETTING_PM_OUTSTANDING_EQUIPMENT_LIST_COLUMNS, value);
  }

  public getPmOutstandingEquipmentListSettingsColumns() {
    return this.get(this.SETTING_PM_OUTSTANDING_EQUIPMENT_LIST_COLUMNS);
  }

  public clearPmOutstandingEquipmentListSettingsColumns() {
    localStorage.removeItem(this.SETTING_PM_OUTSTANDING_EQUIPMENT_LIST_COLUMNS);
  }

  // pw reminder
  public setPwReminder(value: {[x:string]: Array<string>}) {
    this.set(this.PW_REMINDER, value);
    this.pwReminderDisableList = value;
  }

}

//----------------------------------------------------------------------
let Session = new SessionClass();

export { Session };
