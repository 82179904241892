<div class="wrapper">
  <app-nav [navbarTitle]="'component.setting-contract-severity-detail.title' | translate"></app-nav>
  <app-sidebar [contentType]="'settings'"></app-sidebar>

  <div id="content" class="content">
    <app-tab-menu [contentType]="'settings-contract'" [activeTabId]="'tab-menu-item-contract-severity'"></app-tab-menu>

    <div class="row">
      <div class="col-12">
        <div class="d-flex mb-2 ">
          <div>
            <a routerLink="/settings/contract/contract-severity-list" routerLinkActive="active">
              {{'tab-menu.item.contract-severity'| translate}}
            </a>
          </div>
          <ng-container *ngIf="contractSeverity">
            <div>&nbsp; > &nbsp;</div>
            <div>
              {{contractSeverity.contractNumber}}
            </div>
            <div>&nbsp; > &nbsp;</div>
            <div>
              {{contractSeverity.name}}
            </div>
          </ng-container>
        </div>
      </div>

      <div class="col-12">
        <app-severity-criteria-list-table #criteria_list_table [hasUpdatePermission]="hasUpdatePermission"
          [contractSeverity]="contractSeverity"></app-severity-criteria-list-table>
      </div>
    </div>

  </div>
</div>