import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss'],
})
export class LoadingButtonComponent {
  @Input() id: string = '';
  @Input() text: string = '';
  @Input() buttonClass: string = '';
  @Input() iconClass: string = '';
  @Input() isDisabled: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() isSubmitButton: boolean = false;

  @Output() clickedButton: EventEmitter<any> = new EventEmitter();

  constructor() {}

  onClicked() {
    this.clickedButton.emit();
  }
}
