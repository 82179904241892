<div class="d-flex justify-content-between mt-1 mb-2">
  <div class="font-size-xl">
    {{ "component.contract-severity-list-table.severity" | translate }}
  </div>

  <div class="d-flex align-items-center">
    <app-icon-button *ngIf="hasUpdatePermission" [id]="'delete-severity-button'" [color]="'red'" [icon]="'fa-times'"
      [isDisabled]="!selectedContractSeverity" (onClick)="onClickedDeleteButton()"></app-icon-button>
    <app-icon-button *ngIf="hasUpdatePermission" [id]="'add-severity-button'" [color]="'blue'" [icon]="'fa-plus'"
      [isDisabled]="!contract" (onClick)="onClickedAddButton()"></app-icon-button>
  </div>
</div>
<hr class="underline" />

<app-tablex class="" [tablexParam]="tablexParam"></app-tablex>

<slider-panel #add_contract_severity_panel [widthPx]=400>
  <app-contract-severity-editor #add_contract_severity_form [contract]="contract"
    (addedContractSeverity)="onAddedContractSeverity($event)"></app-contract-severity-editor>
</slider-panel>