import { Injectable } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  async requestContractList(option : { pageNumber: number, pageSize: number, parameters?: string[], filter? }) {
    const request = new JM.JMRequestContractsGetContractList();
    option?.pageSize && (request.pageSize = option?.pageSize);
    option?.pageNumber && (request.pageNumber = option?.pageNumber);
    if (option?.filter) {
      request.filter = {
        ...option?.filter
      };
    }
    if (option?.parameters) {
      request.parameters = option?.parameters
    }

    const response: JM.JMResponseContractsGetContractList = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }
    return response.payload;
  }
}
