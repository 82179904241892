import { Component, Injector, Input, OnInit } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { UserHelper } from 'src/app/core/user-helper';
import { Session }  from 'src/app/core/session';

@Component({
  selector: 'remark-history',
  templateUrl: './remark-history.component.html',
  styleUrls: ['./remark-history.component.scss']
})
export class RemarkHistoryComponent implements OnInit {
  @Input() remarks: any[];
  @Input() editMode: boolean;

  visibilityTypes = {
    PUBLIC: "public",
    PRIVATE: "private",
    INVISIBLE: "invisible"
  };
  
  lang: string;
  showVisibilityType: any = {};

  constructor() {
  }

  // sn remarks related permission
  // no those permission in VP, assume show public only

  // permissions = {
  //   view: {
  //     public: JMENUM.Permission.SN_REMARKS_PUBLIC_VIEW,
  //     private: JMENUM.Permission.SN_REMARKS_PRIVATE_VIEW,
  //     invisible: JMENUM.Permission.SN_REMARKS_INVISIBLE_VIEW,
  //   },
  //   update: {
  //     public: JMENUM.Permission.SN_REMARKS_PUBLIC_UPDATE,
  //     private: JMENUM.Permission.SN_REMARKS_PRIVATE_UPDATE,
  //     invisible: JMENUM.Permission.SN_REMARKS_INVISIBLE_UPDATE,
  //   }
  // }

  ngOnInit() {
    // visibilityTypes.forEach(type => {
    //   this.showVisibilityType[type] = UserHelper.hasPermission(this.permissions.update[type]);
    // });
  }

  ngOnDestroy() {
  }

  ngOnChanges() {
    if (!this.remarks) return;

    // lastest remarks will show first
    this.remarks.sort((row1, row2) => {
      return row2.createdAt.localeCompare(row1.createdAt);
    });
  }

  onVisibilityChanged(index, visibility) {
    if (!this.editMode) return;
    this.remarks[index].visibilityType = visibility;
  }
}
