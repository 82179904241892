import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-action-sidebar',
  templateUrl: './action-sidebar.component.html',
  styleUrls: ['./action-sidebar.component.scss'],
})
export class ActionSidebarComponent implements OnInit {
  @Input() buttonData: any;
  @Input() disabled: boolean;
  @Output() clickEvent = new EventEmitter<any>();

  buttons: any;
  constructor(injector: Injector) {}

  ngOnInit() {}

  ngOnChanges(): void {
    if (this.buttonData) {
      this.buttons = this.buttonData;
    }
  }

  click(button: any) {
    this.clickEvent.emit(button);
  }
}
