<div class="d-flex justify-content-between mb-2 align-items-center">
  <div class="font-size-xl align-self-end">
    {{ "pages.pm-plan-list.search-criteria" | translate }}
  </div>

  <div class="d-flex justify-content-end">
    <button class="btn icon-btn brand-amber mr-2 img-btn"
      [disabled]="isDisabled"
      (click)="onClickReset()"
    >
      <i class="fas fa-undo" ></i>
    </button>

    <button
      class="btn icon-btn brand-blue img-btn"
      (click)="onClickSearch()"
      [disabled]="isSearching || isDisabled"
    >
      <div *ngIf="isSearching" class="d-flex align-items-center loading-spinner spinner-small blue"></div>
      <i class="fas fa-search white font-size-l" ></i>
    </button>

    <button
      class="btn icon-btn ml-2 d-inline-block d-xl-none navbar-toggler"
      (click)="isCollapse = !isCollapse"
    >
      <i class="fas fa-chevron-up" [class.d-none]="isCollapse"></i>
      <i class="fas fa-chevron-down" [class.d-none]="!isCollapse"></i>
    </button>
  </div>
</div>
<hr class="underline" class="underline" />

<div class="navbar navbar-expand-xl px-0">
  <div id="search-field-wrap" class="row justify-content-between mx-0 collapse navbar-collapse align-items-start" [ngbCollapse]="isCollapse">
    <div class="col-12 col-md-6 col-xl-12  pl-0 pr-0 pr-md-3 pr-xl-0">
      <input
        id="search-plan-number"
        class="form-control mb-3"
        placeholder="{{'component.pm-plan-search-criteria.plan-number' | translate}}"
        [(ngModel)]="searchSelections.pmPlanNumber" 
      >
      <input
        id="search-contract-number"
        class="form-control mb-3"
        placeholder="{{'component.pm-plan-search-criteria.contract-number' | translate}}"
        [(ngModel)]="searchSelections.contractNumber" 
      >

      <hr class="d-block d-md-none d-xl-block" />

      <div class="mt-3">
        <span class="d-inline d-md-block d-xl-inline gray-40 mr-2">{{ "component.pm-plan-search-criteria.plan-status" | translate }}</span>
        <div class="d-inline-block" *ngIf="searchSelections.planStatus">
          <label [for]="'search-plan-status-'+status.key+'-checkbox'" *ngFor="let status of searchSelections.planStatus | keyvalue"
            class="checkbox-tag brand-blue" [class.checked]="searchSelections.planStatus[status.key]"
          >
            {{ "pm-plan.status."+status.key | translate }}
            <input type="checkbox" hidden="true" name="plan-status"
              [id]="'search-plan-status-'+status.key+'-checkbox'"
              [(ngModel)]="searchSelections.planStatus[status.key]"
            />
          </label>
        </div>
      </div>

      <hr class="d-block d-md-none d-xl-block" />

      <div class="mt-3">
        <span class="d-inline d-md-block d-xl-inline gray-40 mr-2">{{ "component.pm-plan-search-criteria.schedule-type" | translate }}</span>
        <div class="d-inline-block" *ngIf="searchSelections.scheduleType">
          <label [for]="'search-schedule-type-'+type.key+'-checkbox'" *ngFor="let type of searchSelections.scheduleType | keyvalue"
            class="checkbox-tag brand-blue" [class.checked]="searchSelections.scheduleType[type.key]"
          >
            {{ "pm-plan.schedule-type."+type.key | translate }}
            <input type="checkbox" hidden="true" name="schedule-type"
              [(ngModel)]="searchSelections.scheduleType[type.key]" [id]="'search-schedule-type-'+type.key+'-checkbox'" 
            />
          </label>
        </div>
      </div>

      <hr class="d-block d-md-none d-xl-block" />

      <div class="col-12 col-md-6 col-xl-12  pr-0 pl-0 pl-md-3 pl-xl-0">
        <div class="d-flex d-md-block d-xl-flex mb-2 align-items-start">
          <span class="d-inline d-md-block d-xl-inline gray-40 col-auto pl-0">{{ "component.pm-plan-search-criteria.start-date" | translate }}</span>
          <div class="d-inline-block">
            <div ngbRadioGroup
              name="start-range"
              class="mr-2 btn-group btn-group-toggle" 
              [(ngModel)]="searchSelections.startRange"
            >
              <label ngbButtonLabel class="radio-tag brand-blue">
                <input ngbButton type="radio" value="on-or-before" id="search-start-on-or-before">{{ "component.pm-plan-search-criteria.on-or-before" | translate }}
              </label>
              <label ngbButtonLabel class="radio-tag brand-blue">
                <input ngbButton type="radio" value="after" id="search-start-after">{{ "component.pm-plan-search-criteria.after" | translate }}
              </label>
            </div>
            <app-date-input #startDateElem
              class="mt-1 d-inline-block"
              [input]="startDateNgb"
              [inputId]="'startDateInput'"
              [field]="'startDate'" 
              (updatedDate)="onBlurDateInput($event)">
            </app-date-input>
          </div>
        </div>
        <div class="d-flex d-md-block d-xl-flex align-items-start mt-3">
          <span class="d-inline d-md-block d-xl-inline gray-40 col-auto pl-0">{{ "component.pm-plan-search-criteria.end-date" | translate }}</span>
          <div class="d-inline-block">
            <div class="mr-2 btn-group btn-group-toggle" ngbRadioGroup name="end-range" [(ngModel)]="searchSelections.endRange">
              <label ngbButtonLabel class="radio-tag brand-blue">
                <input ngbButton type="radio" value="on-or-before" id="search-end-on-or-before">{{ "component.pm-plan-search-criteria.on-or-before" | translate }}
              </label>
              <label ngbButtonLabel class="radio-tag brand-blue">
                <input ngbButton type="radio" value="after" id="search-end-after">{{ "component.pm-plan-search-criteria.after" | translate }}
              </label>
            </div>
            <app-date-input #endDateElem
              class="mt-1 d-inline-block"
              [input]="endDateNgb"
              [inputId]="'endDateInput'"
              [field]="'endDate'" 
              (updatedDate)="onBlurDateInput($event)">
            </app-date-input>
          </div>
        </div>
      </div>
      
      <hr class="d-block d-md-none d-xl-block" />

      <ng-select
        id="search-frequency"
        class="px-0 mb-2 mt-3"
        placeholder="{{'component.pm-plan-search-criteria.frequency' | translate}}"
        bindLabel="label"
        bindValue="value"
        [multiple]="true"
        [items]="frequencyOptions"
        [(ngModel)]="searchSelections.frequency"
      >
      </ng-select>

      <input
        id="search-plan-description"
        class="form-control mb-3"
        placeholder="{{'component.pm-plan-search-criteria.plan-description' | translate}}"
        [(ngModel)]="searchSelections.planDescription" 
      >

      <ng-select
        id="search-handling-team"
        class="px-0 mb-2 mt-3"
        placeholder="{{'component.pm-plan-search-criteria.handling-team' | translate}}"
        bindLabel="label"
        bindValue="value"
        [items]="teamOptions" 
        [loading]="isLoadingTeam"
        [multiple]="true"
        (clear)="clearTeamFilter()"
        (scrollToEnd)="onTeamScrollToEnd()"
        (search)="onSearchTeam($event)"
        (open)="clearTeamFilter()"
        [(ngModel)]="searchSelections.handlingTeam"
      >
      </ng-select>

    </div>
  </div>
</div>
