<div class="wrapper">
  <app-nav [navbarTitle]="'sidebar.subitem.pm-plans-list' | translate"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="content cm-task-create-content">
    <div class="component-wrap row">
      <div class="col-12 col-xl-9 ">
        <!-- <button
          class="btn standard brand-blue ml-auto d-block"
          [disabled]="!enableCreateJob"
          (click)="onClickCreatePMTask()"
        >
          {{ "pages.pm-plan-list.create-pm-job" | translate }}
        </button> -->
        <app-tablex 
          #pmPlanListTable
          id="pm-plan-list-table"
          [tablexParam]="tablexParam"
        ></app-tablex>
      </div>

      <div class="col-12 col-xl-3">
        <app-pm-plan-search-criteria
          (onSearch)="onAdvancedSearch($event)"
        ></app-pm-plan-search-criteria>
      </div>
    </div>
  </div>
</div>
