import { Component, OnInit } from '@angular/core';
import { JM } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/core/session';
import { environment } from 'src/environments/environment';
import { JMLanguage, Language } from 'src/app/core/JMLanguage/JMLanguage';
import { AppDelegate } from './core/AppDelegate';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    let lang = Session.selectedLanguage;
    if (!lang) {
      JMLanguage.changeLanguage(Language.EN);
      lang = Language.EN;
    } else {
      JMLanguage.changeLanguage(lang);
    }

    JM.JMConnector.setHost(environment.CCEP_API_HOST);
    JM.JMConnector.setFileHost(environment.CCEP_FILE_HOST);

    JM.JMConnector.setLanguage(Session.selectedLanguage);

    if (Session.userToken) {
      JM.JMConnector.setToken(Session.userToken);
    } else {
      AppDelegate.routeToPage('login');
    }
  }
}
