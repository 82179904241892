import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { JMENUM, JMOBJ, JMREASON } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';

@Component({
  selector: 'app-sn-cancel-remarks',
  templateUrl: './sn-cancel-remarks.component.html',
  styleUrls: ['./sn-cancel-remarks.component.scss']
})
export class SnCancelRemarksComponent implements OnInit {
  @Input() sn: JMOBJ.ServiceNotification;

  messages: {
    title: string;
    details: string;
  }[] = [];

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sn) {
      this.parseMessages();
    }
  }

  parseMessages() {
    this.messages = [];

    let cancelCode = this.sn?.cancellationCode;
    let cancelRemarks = this.sn?.cancelRemarks || '';
    if (cancelCode && cancelRemarks) {
      let cancelDescription = JMREASON.SN_CANCEL_REASON_LIST.getDescription(cancelCode);
      let cancelDescriptionWithLang = '';
      if (cancelDescription) {
        cancelDescriptionWithLang =
          Session.selectedLanguage === JMENUM.Language.ZH ? cancelDescription.zh : cancelDescription.en;
      }
      let message = {
        title: JMLanguage.translate('component.sn-cancel-remarks.message.cancel-reason'),
        details: `[${cancelDescriptionWithLang}] ${cancelRemarks}`,
      };

      this.messages.push(message);
    }
  }
}
