<div id="userEmsdEditComponent">
  <div class="d-flex justify-content-between">
    <div *ngIf="isComponentModeEdit" class="title">{{ 'component.user-emsd-edit.title.edit' | translate }}</div>
    <div *ngIf="isComponentModeCreate" class="title">{{ 'component.user-emsd-edit.title.add' | translate }}</div>

    <div class="d-flex">
      <div class="pt-2 pr-2"><span class="red pr-1">*</span>{{ 'global.mandatory-fields' | translate }}</div>

      <app-icon-button
        [id]="'user-emsd-edit-submit-button'"
        [color]="'amber'"
        [icon]="'fa-check'"
        [isLoading]="isSubmitting"
        (onClick)="onClickedSubmit()"
      >
      </app-icon-button>
    </div>
  </div>

  <hr class="underline" />

  <div class="d-flex flex-column my-3">
    <div class="fields d-flex align-items-start">
      <p class="label-text col-5 p-0 mb-4">
        {{ 'component.user-emsd-edit.post' | translate }} <span *ngIf="fieldsMandatory.post" class="red">*</span>
      </p>
      <p *ngIf="!fieldsEditable.post" class="col-7 p-0 m-0">{{ post }}</p>
      <ng-select
        *ngIf="fieldsEditable.post"
        class="flex-grow-1"
        bindLabel="name"
        bindValue="name"
        [items]="userListDropdownSearch.list"
        [ngClass]="{ 'input-error': fieldsError.post }"
        [(ngModel)]="post"
        (ngModelChange)="rolesNgModelChange($event)"
        (scrollToEnd)="userListDropdownSearch.scrollToEnd()"
        [searchFn]="userListDropdownSearch.searchFn"
        (search)="userListDropdownSearch.search($event)"
        (close)="userListDropdownSearch.close()"
        (clear)="userListDropdownSearch.clear()"
        [loading]="userListDropdownSearch.loading"
      >
      </ng-select>
    </div>

    <div class="fields d-flex align-items-start">
      <p class="label-text col-5 p-0 mb-4">
        {{ 'component.user-emsd-edit.name' | translate }} <span *ngIf="fieldsMandatory.name" class="red">*</span>
      </p>
      <p class="col-7 p-0 m-0">{{ name }}</p>
    </div>

    <div class="fields d-flex align-items-start">
      <p class="label-text col-5 p-0 mb-4">
        {{ 'component.user-emsd-edit.post-code' | translate }} <span *ngIf="fieldsMandatory.code" class="red">*</span>
      </p>
      <p class="col-7 p-0 m-0">{{ code }}</p>
    </div>

    <div class="fields d-flex align-items-start">
      <p class="label-text col-5 p-0 mb-4">
        {{ 'component.user-emsd-edit.roles' | translate }} <span *ngIf="fieldsMandatory.roles" class="red">*</span>
      </p>
      <ng-select
        bindValue="roleId"
        bindLabel="name"
        class="flex-grow-1"
        [multiple]="true"
        [ngClass]="{ 'input-error': fieldsError.roles }"
        [disabled]="!fieldsEditable.roles"
        [(ngModel)]="roles"
        [items]="roleListDropdownSearch.list"
        (scrollToEnd)="roleListDropdownSearch.scrollToEnd()"
        [searchFn]="roleListDropdownSearch.searchFn"
        (search)="roleListDropdownSearch.search($event)"
        (close)="roleListDropdownSearch.close()"
        (clear)="roleListDropdownSearch.clear()"
        [loading]="roleListDropdownSearch.loading"
      >
      </ng-select>
    </div>

    <div class="custom-checkbox my-4">
      <input
        id="active-checkbox"
        type="checkbox"
        class="custom-control-input"
        [disabled]="!fieldsEditable.isActive"
        [(ngModel)]="isActive"
      />
      <label for="active-checkbox" class="custom-control-label">Active</label>
    </div>
  </div>
</div>
