import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePage } from 'src/app/core/base';
import {
  TablexColumnType,
  TablexColumnVerticalAlign,
  TablexColumnHorizontalAlign,
} from 'src/app/shared/tablex/tablexColumnType';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Util } from 'src/app/core/util';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';
import { UserHelper } from 'src/app/core/user-helper';
import { PasswordErrorListComponent } from 'src/app/general-component/password-error-list/password-error-list.component';

@Component({
  selector: 'settings-user-management',
  templateUrl: 'settings-user-management.component.html',
  styleUrls: ['settings-user-management.component.scss'],
})
export class SettingsUserManagementComponent extends BasePage implements OnInit {
  @ViewChild('passwordErrorList', { static: false }) passwordErrorList: PasswordErrorListComponent;
  //constant
  readonly TWO_FACTOR_AUTHENTICATION: string = '2FA';
  readonly ActionType: any = {
    CREATE_USER: 'CREATE_USER',
    EDIT_USER: 'EDIT_USER',
  };

  // ---
  selectedIndex = undefined;
  actionMode: string = this.ActionType.CREATE_USER;
  mandatoryFields: string[] = [];
  errorFields: string[] = [];
  isLoadingSubmit: boolean = false;

  // ngModel
  userList: JMOBJ.Post[] = [];
  userInfo: any = { isActive: true };
  vendorList: any[] = [];
  usernamePrefix: string = '';
  roleList: JMOBJ.Role[] = [];
  vendorTeamList: JMOBJ.VendorTeam[] = [];
  confirmationPassword: string = '';

  // for team dorpdown use
  private searchVendorTeamsObserver = new Subject<any[]>();
  searchVendorTeamKeywords: string = null;
  vendorTeamsPageSize = 100;
  vendorTeamPageNumber = 0;
  vendorTeamTotalCount = null;
  isLoadingVendorTeams = false;

  // for vendor dorpdown use
  private searchVendorObserver = new Subject<any[]>();
  searchVendorKeywords: string = null;
  vendorPageSize = 100;
  vendorPageNumber = 0;
  vendorTotalCount = null;
  isLoadingVendor = false;

  // ----------
  vendorDetailMap: {} = {}; // key vendorNumber, value vendorDetail
  roleDetailMap: {} = {}; // key roleId, value roleDetail
  usernamePrefixSeparator: string = '_';
  vendorTeamDetailMap: {} = {}; // key teamId, value vendorDetail

  // tablex parameters
  tablexParam: {} = {};
  tablexFilter = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize = 10;
  currentPage = 1;
  pageCount = 1;
  sortBy = undefined;
  sortOrder = undefined;

  showPasswordError = false;

  constructor(private injector: Injector) {
    super(injector, true);
  }

  ngOnInit() {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }

    this.initTablex();

    this.setActionMode(this.ActionType.CREATE_USER);

    // for search vendorTeams use
    this.searchVendorTeamsObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.searchVendorTeams();
    });

    // for search vendor use
    this.searchVendorObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.searchVendor();
    });

    this.requestUserList();
    this.requestVendorList();
    this.requestRoleList();
  }

  //------ Request function -------
  async requestUserList() {
    if (!UserHelper.hasPermission(JMENUM.VpPermission.USER_VIEW)) {
      return;
    }

    let request = new JM.JMRequestPostsGetVpContractorUserList();
    request.pageSize = this.currentPageSize;
    request.pageNumber = this.currentPage;
    request.filter = this.tablexFilter;

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponsePostsGetVpContractorUserList = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      this.toastMsg.showResponseMsg(response);
      return;
    }

    this.userList = response.payload.records;
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
    this.renderTable();

    this.requestVendorOfUsers();
    this.requestVendorTeamOfUsers();
  }

  async requestVendorList() {
    if (!UserHelper.hasPermission(JMENUM.VpPermission.USER_UPDATE)) {
      return;
    }

    if (this.isLoadingVendor) {
      return;
    }

    if (this.vendorTotalCount !== null && this.vendorTotalCount <= this.vendorPageNumber * this.vendorPageSize) {
      return;
    }

    this.vendorPageNumber++;

    let request = new JM.JMRequestContractsGetVendorList();
    request.pageNumber = this.vendorPageNumber;
    request.pageSize = this.vendorPageSize;
    request.parameters = ['vendorNumber', 'vendorName', 'postNamePrefix'];

    if (this.searchVendorKeywords) {
      request.filter = { vendorName: this.searchVendorKeywords };
    }

    this.isLoadingVendor = true;
    const response: JM.JMResponseContractsGetVendorList = await AppDelegate.sendJMRequest(request);
    this.isLoadingVendor = false;

    if (!response || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.vendorTotalCount = response.payload.totalCount;
    this.vendorList = this.vendorList.concat(response.payload.records);

    // get all vendor detail
    for (const vendor of response.payload.records) {
      if (!this.vendorDetailMap[vendor.vendorNumber]) {
        this.vendorDetailMap[vendor.vendorNumber] = vendor;
      }
    }
  }

  requestRoleList() {
    let request = new JM.JMRequestAuthRoleSummary();
    request.systemName = [Constants.SYSTEM_NAME];
    request.targetType = [JMENUM.RoleTargetType.CONTRACTOR];
    request.parameters = ['roleId', 'name'];

    JM.JMConnector.sendAuthRoleSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseAuthRoleSummary) => {
      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }

      if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
        this.toastMsg.showResponseMsg(response);
        return;
      }

      this.roleList = response.payload.records;

      // get all vendor detail
      for (let role of this.roleList) {
        if (!this.roleDetailMap[role.roleId]) {
          this.roleDetailMap[role.roleId] = role;
        }
      }
    });
  }

  async requestVendorTeamList() {
    if (this.isLoadingVendorTeams) {
      return;
    }
    if (!this.userInfo.vendorNumber) {
      return;
    }

    if (
      this.vendorTeamTotalCount !== null &&
      this.vendorTeamTotalCount <= this.vendorTeamPageNumber * this.vendorTeamsPageSize
    ) {
      return;
    }

    this.vendorTeamPageNumber++;

    let request = new JM.JMRequestContractsGetVendorTeamListByVendor();
    request.vendorNumber = this.userInfo.vendorNumber;
    request.parameters = ['_id', 'name', 'activeStatus'];
    request.pageNumber = this.vendorTeamPageNumber;
    request.pageSize = this.vendorTeamsPageSize;

    if (this.searchVendorTeamKeywords) {
      request.filter = { name: this.searchVendorTeamKeywords };
    }

    this.isLoadingVendorTeams = true;
    const response: JM.JMResponseContractsGetVendorTeamListByVendor = await AppDelegate.sendJMRequest(request);
    this.isLoadingVendorTeams = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      this.toastMsg.showResponseMsg(response);
      return;
    }

    for (let vendorTeam of response.payload.records) {
      if (!this.vendorTeamDetailMap[vendorTeam._id]) {
        this.vendorTeamDetailMap[vendorTeam._id] = vendorTeam;
      }
    }

    this.vendorTeamTotalCount = response.payload.totalCount;
    this.vendorTeamList = this.vendorTeamList.concat(response.payload.records);
  }

  requestVendorOfUsers() {
    if (!this.userList || !this.userList.length) {
      return;
    }

    let vendors = [];
    for (let user of this.userList) {
      if (user.vendorNumber && !vendors.includes(user.vendorNumber) && !this.vendorDetailMap[user.vendorNumber]) {
        vendors.push(user.vendorNumber);
      }
    }

    if (!vendors.length) {
      return;
    }

    let request = new JM.JMRequestContractsVendorSummary();
    request.parameters = ['vendorNumber', 'vendorName', 'postNamePrefix'];

    request.vendorNumber = vendors;

    JM.JMConnector.sendContractsVendorSummary(
      request,
      (error: JM.JMNetworkError, response: JM.JMResponseContractsVendorSummary) => {
        if (error) {
          AppDelegate.handleJMError(error);
          return;
        }

        if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
          this.toastMsg.showResponseMsg(response);
          return;
        }

        // get all vendor detail
        for (let vendor of response.payload.records) {
          if (!this.vendorDetailMap[vendor.vendorNumber]) {
            this.vendorDetailMap[vendor.vendorNumber] = vendor;
          }
        }

        this.renderTable();
      }
    );
  }

  requestVendorTeamOfUsers() {
    if (!this.userList || !this.userList.length) {
      return;
    }

    let vendorTeams = [];
    for (let user of this.userList) {
      if (!user.vendorTeams) {
        continue;
      }
      for (let vendorTeam of user.vendorTeams) {
        if (!vendorTeams.includes(vendorTeam) && !this.vendorTeamDetailMap[vendorTeam]) {
          vendorTeams.push(vendorTeam);
        }
      }
    }
    if (!vendorTeams.length) {
      return;
    }

    let request = new JM.JMRequestContractVendorTeamSummary();
    request.id = vendorTeams;
    request.parameters = ['_id', 'name', 'activeStatus'];

    JM.JMConnector.sendContractsVendorTeamSummary(
      request,
      (error: JM.JMNetworkError, response: JM.JMResponseContractVendorTeamSummary) => {
        if (error) {
          AppDelegate.handleJMError(error);
          return;
        }

        if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
          this.toastMsg.showResponseMsg(response);
          return;
        }

        for (let vendorTeam of response.payload.records) {
          if (!this.vendorTeamDetailMap[vendorTeam._id]) {
            this.vendorTeamDetailMap[vendorTeam._id] = vendorTeam;
          }
        }
        this.renderTable();
      }
    );
  }

  requestCreateUser() {
    if (!this.userInfo) {
      return;
    }

    this.isLoadingSubmit = true;

    let request = new JM.JMRequestPostsCreate();
    request.systemName = Constants.SYSTEM_NAME;
    request.isActive = this.userInfo.isActive ? true : false;
    request.loginVerificationMode = this.userInfo.use2fa ? this.TWO_FACTOR_AUTHENTICATION : null;
    request.authenticationMode = JMENUM.AuthenticationMode.LOCAL;

    let username = this.getUsername();
    if (username) {
      request.name = username;
    }
    if (this.userInfo.employeeName) {
      request.employeeName = this.userInfo.employeeName;
    }
    if (this.userInfo.password) {
      request.password = this.userInfo.password;
    }
    if (this.userInfo.roles && this.userInfo.roles.length) {
      request.roles = this.userInfo.roles;
    }
    if (this.userInfo.phone) {
      request.phone = this.userInfo.phone;
    }
    if (this.userInfo.mobile) {
      request.mobile = this.userInfo.mobile;
    }
    if (this.userInfo.email) {
      request.email = this.userInfo.email;
    }
    if (this.userInfo.vendorNumber) {
      request.vendorNumber = this.userInfo.vendorNumber;
    }
    if (this.hasTeamUpdatePermission && this.userInfo.vendorTeams && this.userInfo.vendorTeams.length) {
      request.vendorTeams = this.userInfo.vendorTeams;
    }

    JM.JMConnector.sendPostsCreate(request, (error: JM.JMNetworkError, response: JM.JMResponsePostsCreate) => {
      this.isLoadingSubmit = false;

      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }

      if (!response || !response.code || response.code !== 200 || !response.payload) {
        this.toastMsg.showResponseMsg(response);
        return;
      }

      AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.settings-user-management.toast.created'));

      this.requestUserList();
    });
  }

  requestUpdateUser() {
    if (!this.userInfo) {
      return;
    }

    this.isLoadingSubmit = true;

    let request = new JM.JMRequestPostsUpdate();
    request.systemName = Constants.SYSTEM_NAME;
    request.isActive = this.userInfo.isActive ? true : false;
    request.loginVerificationMode = this.userInfo.use2fa ? this.TWO_FACTOR_AUTHENTICATION : null;
    request.authenticationMode = JMENUM.AuthenticationMode.LOCAL;

    let username = this.getUsername();
    if (username) {
      request.name = username;
    }
    if (this.userInfo.employeeName) {
      request.employeeName = this.userInfo.employeeName;
    }
    if (this.userInfo.password) {
      request.password = this.userInfo.password;
    }
    if (this.userInfo.roles && this.userInfo.roles.length) {
      request.roles = this.userInfo.roles;
    }
    if (this.userInfo.isActive) {
      request.isActive = this.userInfo.isActive;
    }
    if (this.userInfo.phone) {
      request.phone = this.userInfo.phone;
    }
    if (this.userInfo.mobile) {
      request.mobile = this.userInfo.mobile;
    }
    if (this.userInfo.email) {
      request.email = this.userInfo.email;
    }
    if (this.userInfo.vendorNumber) {
      request.vendorNumber = this.userInfo.vendorNumber;
    }
    if (this.hasTeamUpdatePermission) {
      request.vendorTeams = this.userInfo.vendorTeams ? this.userInfo.vendorTeams : [];
    }

    JM.JMConnector.sendPostsUpdate(request, (error: JM.JMNetworkError, response: JM.JMResponsePostsUpdate) => {
      this.isLoadingSubmit = false;

      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }

      if (!response || !response.code || response.code !== 200 || !response.payload) {
        this.toastMsg.showResponseMsg(response);
        return;
      }

      AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.settings-user-management.toast.udpated'));

      this.requestUserList();
    });
  }

  // ------ Custom function -------
  getUsername() {
    if (!this.usernamePrefix || !this.userInfo.name) {
      return null;
    }
    return this.usernamePrefix + this.userInfo.name;
  }

  setUsernamePrefix() {
    this.usernamePrefix = this.getUsernamePrefix(this.userInfo.vendorNumber);
  }

  getUsernamePrefix(vendorNumber) {
    if (vendorNumber) {
      return this.vendorDetailMap[vendorNumber]
        ? this.vendorDetailMap[vendorNumber].postNamePrefix + this.usernamePrefixSeparator
        : '';
    } else {
      return '';
    }
  }

  updateSelectedVendor() {
    this.setUsernamePrefix();

    this.vendorTeamList = [];
    this.vendorTeamPageNumber = 0;
    this.vendorTeamTotalCount = null;
    this.searchVendorTeamKeywords = null;

    if (this.actionMode === this.ActionType.CREATE_USER) {
      this.userInfo.vendorTeams = undefined;
    }

    this.requestVendorTeamList();
  }

  clearUserInfo() {
    this.setActionMode(this.ActionType.CREATE_USER);

    this.errorFields = [];
    this.resetPasswordErrors();

    this.tablexParam['selectedRowIndex'] = -1;
    this.selectedIndex = null;
    this.userInfo = { isActive: true };

    this.vendorList = [];
    this.vendorPageNumber = 0;
    this.vendorTotalCount = null;
    this.searchVendorKeywords = null;

    this.updateSelectedVendor();
    this.requestVendorList();
  }

  searchVendorTeams() {
    this.vendorTeamList = [];
    this.vendorTeamPageNumber = 0;
    this.vendorTeamTotalCount = null;
    this.requestVendorTeamList();
  }

  searchVendor() {
    this.vendorList = [];
    this.vendorPageNumber = 0;
    this.vendorTotalCount = null;
    this.requestVendorList();
  }

  selectUser() {
    this.setActionMode(this.ActionType.EDIT_USER);

    this.errorFields = [];
    this.resetPasswordErrors();

    let user = this.userList[this.selectedIndex];

    let usernamePrefix = this.getUsernamePrefix(user.vendorNumber);
    let username = user.name.substr(usernamePrefix.length, user.name.length - usernamePrefix.length);

    let use2fa = false;
    let roles = [];
    let isActive = false;
    if (user.systems && user.systems[Constants.SYSTEM_NAME]) {
      let systemProfile = user.systems[Constants.SYSTEM_NAME];
      use2fa = systemProfile.loginVerificationMode === this.TWO_FACTOR_AUTHENTICATION;
      isActive = systemProfile.isActive;

      if (systemProfile.roleIds && systemProfile.roleIds.length) {
        roles = systemProfile.roleIds;
      }
    }
    const activeVendorTeams: string[] = [];
    if (user.vendorTeams && user.vendorTeams.length) {
      for(const vendorTeam of user.vendorTeams) {
        if (vendorTeam && this.vendorTeamDetailMap[vendorTeam]) {
          if(this.vendorTeamDetailMap[vendorTeam].activeStatus === JMENUM.ActiveStatus.ACTIVE) {
            activeVendorTeams.push(vendorTeam);
          }
        }
      }
    }

    this.userInfo = {
      vendorNumber: user.vendorNumber,
      name: username,
      employeeName: user.employeeName,
      phone: user.phone,
      mobile: user.mobile,
      email: user.email,
      roles: roles,
      vendorTeams: activeVendorTeams,
      isActive: isActive,
      use2fa: use2fa,
    };

    this.vendorList = [];
    this.vendorPageNumber = 0;
    this.vendorTotalCount = null;
    this.searchVendorKeywords = null;

    this.requestVendorList();
    this.updateSelectedVendor();
  }

  setActionMode(actionMode) {
    this.actionMode = actionMode;

    if (this.actionMode === this.ActionType.EDIT_USER) {
      this.mandatoryFields = ['vendorNumber', 'name', 'employeeName', 'phone', 'mobile', 'email', 'roles'];
      return;
    }

    if (this.actionMode === this.ActionType.CREATE_USER) {
      this.mandatoryFields = [
        'vendorNumber',
        'name',
        'employeeName',
        'phone',
        'mobile',
        'email',
        'roles',
        'password',
        'confirmationPassword',
      ];
      return;
    }
  }

  trimData() {
    let numericFields = ['phone', 'mobile'];
    for (let field of numericFields) {
      if (this.userInfo[field]) {
        this.userInfo[field] = this.userInfo[field].toString().replace(/[^\d]/g, '');
      }
    }

    let textFields = ['name', 'employeeName', 'password', 'phone', 'mobile', 'email'];

    for (let field of textFields) {
      if (this.userInfo[field]) {
        this.userInfo[field] = this.userInfo[field].trim();
      }
    }
  }

  isValidToSubmit() {
    this.errorFields = [];
    this.resetPasswordErrors();

    // trim data
    this.trimData();

    // check mandatory fields
    for (let field of this.mandatoryFields) {
      if (field === 'roles') {
        if (!this.userInfo.roles || !this.userInfo.roles.length) {
          this.errorFields.push('roles');
        }
        continue;
      }

      if (!this.userInfo[field]) {
        this.errorFields.push(field);
        continue;
      }
    }

    if (this.errorFields.length) {
      AppDelegate.toastMsg().showMsg(
        JMLanguage.translate('pages.settings-user-management.toast.missing-mandatory-fields')
      );
      return false;
    }
    if (this.userInfo.password != null && this.userInfo.password != "" && this.userInfo.password.length > 0) {
      if (!this.passwordErrorList.isPasswordVaildate(this.userInfo.password, this.usernamePrefix + this.userInfo.name)) {
        this.errorFields.push('password');
        this.showPasswordError = true;
        return
      }
    }

    if (this.userInfo.password && this.userInfo.password !== this.userInfo.confirmationPassword) {
      this.errorFields.push('password', 'confirmationPassword');
      AppDelegate.toastMsg().showMsg(JMLanguage.translate("pages.settings-user-profile.toast.different-password"));
      return;
    }

    // check email
    if (this.userInfo.email && !Util.isValidEmail(this.userInfo.email)) {
      this.errorFields.push('email');
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.settings-user-management.toast.wrong-email-format'));
      return false;
    }

    return true;
  }

  // ------ Event callback -------
  onClickedSubmit() {
    if (!this.isValidToSubmit()) {
      return;
    }
    let popupDetail = {
      msg: { content: 'popup.confirm-to-save' },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            // actions

            if (this.actionMode === this.ActionType.EDIT_USER) {
              this.requestUpdateUser();
              return;
            }

            if (this.actionMode === this.ActionType.CREATE_USER) {
              this.requestCreateUser();
              return;
            }
          },
        },
        { name: 'global.no', handler: null },
      ],
    };
    AppDelegate.popUpDialog().open(popupDetail);
  }

  onClickedAddUser() {
    if (this.actionMode === this.ActionType.EDIT_USER) {
      this.clearUserInfo();
    }
  }

  onClickedClear() {
    let popupDetail = {
      msg: { content: 'popup.confirm-to-clear' },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            // actions
            if (this.actionMode === this.ActionType.EDIT_USER) {
              this.selectUser();
              return;
            }

            if (this.actionMode === this.ActionType.CREATE_USER) {
              this.clearUserInfo();
              return;
            }
          },
        },
        { name: 'global.no', handler: null },
      ],
    };
    AppDelegate.popUpDialog().open(popupDetail);
  }

  onSelectedVendorChanged() {
    this.updateSelectedVendor();
  }

  onClearVendor() {
    this.vendorList = [];
    this.vendorPageNumber = 0;
    this.vendorTotalCount = null;
    this.searchVendorKeywords = null;

    this.requestVendorList();
  }

  validateSelectedVendor() {
    // check vendor prefix
    if (this.userInfo.vendorNumber && this.vendorDetailMap[this.userInfo.vendorNumber]) {
      let vendor = this.vendorDetailMap[this.userInfo.vendorNumber];
      if (!vendor.postNamePrefix) {
        AppDelegate.toastMsg().showMsg(
          JMLanguage.translate('pages.settings-user-management.toast.missing-post-name-prefix')
        );
        this.userInfo.vendorNumber = null;
        this.updateSelectedVendor();
      }
    }
  }

  onClearVendorTeam() {
    this.vendorTeamList = [];
    this.vendorTeamPageNumber = 0;
    this.vendorTeamTotalCount = null;
    this.searchVendorTeamKeywords = null;

    this.requestVendorTeamList();
  }

  onVendorTeamScrollToEnd() {
    this.requestVendorTeamList();
  }

  onSearchVendorTeam(event) {
    this.searchVendorTeamKeywords = event.term;
    this.searchVendorTeamsObserver.next();
  }

  onVendorScrollToEnd() {
    this.requestVendorList();
  }

  onSearchVendor(event) {
    this.searchVendorKeywords = event.term;
    this.searchVendorObserver.next();
  }

  // ------ Tablex function -------
  renderTable() {
    if (this.userList == null) {
      return;
    }

    this.tablexParam['content'] = this.userList.map((data) => {
      let creationDetail = [];
      if (data.createdBy) {
        creationDetail.push(data.createdBy);
      }

      if (data.createdAt) {
        creationDetail.push(Util.formatDate(data.createdAt));
      }

      let vendorTeams = [];
      if (data.vendorTeams && data.vendorTeams.length) {
        for (let vendorTeam of data.vendorTeams) {
          if (vendorTeam && this.vendorTeamDetailMap[vendorTeam]) {
            if(this.vendorTeamDetailMap[vendorTeam]['activeStatus'] === JMENUM.ActiveStatus.ACTIVE) {
              vendorTeams.push(this.vendorTeamDetailMap[vendorTeam].name);
            }
          }
        }
      }

      let use2fa = false;
      if (data.systems && data.systems[Constants.SYSTEM_NAME]) {
        let systemProfile = data.systems[Constants.SYSTEM_NAME];
        use2fa = systemProfile.loginVerificationMode === this.TWO_FACTOR_AUTHENTICATION;
      }

      let roleList = [];
      let isActive = false;
      if (data.systems[Constants.SYSTEM_NAME]) {
        const systemProfile: JMOBJ.PostSystemAttribute = data.systems[Constants.SYSTEM_NAME];

        isActive = systemProfile.isActive;

        if (systemProfile.roleIds) {
          roleList = systemProfile.roleIds.map((roleId) => {
            if (!roleId || !this.roleDetailMap[roleId]) {
              return '';
            }
            return this.roleDetailMap[roleId].name;
          });
        }
      }

      return [
        Util.toString(data.name),
        data.vendorNumber && this.vendorDetailMap[data.vendorNumber]
          ? this.vendorDetailMap[data.vendorNumber].vendorName
          : '',
        Util.toString(data.employeeName),
        Util.toString(data.phone),
        Util.toString(data.mobile),
        Util.toString(data.email),
        Util.toString(data.fax),
        roleList,
        vendorTeams,
        isActive ? JMLanguage.translate('global.active') : JMLanguage.translate('global.inactive'),
        use2fa ? JMLanguage.translate('global.yes') : JMLanguage.translate('global.no'),
        creationDetail,
      ];
    });
  }

  initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableSort: true,
      enableColFilter: false,
      enableSelectedRowCount: false,
      tableRow: 'row',
      tableClass: 'user-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowSelected: this.onRowSelected,
      onRowUnselected: this.onRowUnselected,
      onFilterChanged: this.onFilterChanged,
      onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      content: [],
      highlightedRows: [],
      displayColId: [],
      customClassRows: [],
    };

    this.initTableHeader();
  }

  initTableHeader() {
    this.tablexParam['headers'] = [
      {
        id: 'account',
        name: 'pages.settings-user-management.table-column.account',
        enableSort: false,
        enableFilter: true,
        filterAttribute: 'name',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'contractor-name',
        name: 'pages.settings-user-management.table-column.contractor-name',
        enableSort: false,
        enableFilter: false,
        filterAttribute: 'vendorName',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'staff-name',
        name: 'pages.settings-user-management.table-column.staff-name',
        enableSort: false,
        enableFilter: true,
        filterAttribute: 'employeeName',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'phone',
        name: 'pages.settings-user-management.table-column.phone',
        enableSort: false,
        enableFilter: true,
        filterAttribute: 'phone',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col-1 ',
      },
      {
        id: 'mobile',
        name: 'pages.settings-user-management.table-column.mobile',
        enableSort: false,
        enableFilter: true,
        filterAttribute: 'mobile',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col-1 ',
      },
      {
        id: 'email',
        name: 'pages.settings-user-management.table-column.email',
        enableSort: false,
        enableFilter: true,
        filterAttribute: 'email',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'fax',
        name: 'pages.settings-user-management.table-column.fax',
        enableSort: false,
        enableFilter: true,
        filterAttribute: 'fax',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col-1 ',
      },
      {
        id: 'role',
        name: 'pages.settings-user-management.table-column.role',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.MultiLine,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col-1 ',
      },
      {
        id: 'team',
        name: 'pages.settings-user-management.table-column.team',
        enableSort: false,
        enableFilter: false,
        filterAttribute: 'team',
        type: TablexColumnType.MultiLine,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'status',
        name: 'pages.settings-user-management.table-column.status',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'use-2fa',
        name: 'pages.settings-user-management.table-column.use-2fa',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'creation-detail',
        name: 'pages.settings-user-management.table-column.creation-detail',
        enableSort: false,
        enableFilter: false,
        filterAttribute: 'createdAt',
        type: TablexColumnType.MultiLine,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },

    ];
  }

  onFilterChanged = (event, index, header, filter) => {
    if (filter[header.id] == null || filter[header.id] == '') {
      delete this.tablexFilter[header.filterAttribute];
    } else {
      this.tablexFilter[header.filterAttribute] = filter[header.id];
    }

    this.currentPage = 1;
    this.clearUserInfo();
    this.requestUserList();
  };

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.clearUserInfo();
    this.requestUserList();
  };

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.clearUserInfo();
    this.requestUserList();
  };

  onRowSelected = (index, row) => {
    if (!this.userList || this.userList.length <= index || !this.userList[index]) {
      return;
    }

    this.selectedIndex = index;

    this.selectUser();
  };

  onRowUnselected = (index, row) => {
    this.clearUserInfo();
  };

  onSortOrderChanged = (header, sortOrder) => {
    // if (this.sortBy == header.filterAttribute) {
    //   this.sortOrder *= -1
    // } else  {
    //   this.sortBy = header.filterAttribute;
    //   this.sortOrder = 1;
    // }

    this.tablexParam['sortBy'] = header;
    this.tablexParam['sortOrder'] = sortOrder;
    this.clearUserInfo();
    this.requestUserList();
  };

  //------------------------
  // permission getter
  get hasViewPermission(): boolean {
    return UserHelper.hasPermission(JMENUM.VpPermission.USER_VIEW);
  }
  get hasUpdatePermission(): boolean {
    return UserHelper.hasPermission(JMENUM.VpPermission.USER_UPDATE);
  }
  get hasTeamUpdatePermission(): boolean {
    return UserHelper.hasPermission(JMENUM.VpPermission.TEAM_UPDATE);
  }

  resetPasswordErrors() {
    this.showPasswordError = false
    this.passwordErrorList.resetPasswordErrors();
  }
  
}

