/**
 ******************************************************************
 !!!!!!!!!!!!!!!!!!!!!!!!!!! DEPRECATED !!!!!!!!!!!!!!!!!!!!!!!!!!!
 ******************************************************************
 */
export const ActionButtonDefinition: any = {
  // TODO: transalte
  selectEquipment: {
    save: {
      type: 'selectEquipment',
      action: 'save',
      buttonId: 'right-tool-bar_save_button',
      imageUrl: '../assets/svg/btn_action_save.svg',
      iconClass: '',
      actionDescription: 'action.button.save',
      showPopup: false,
      popupTitle: '',
      buttons: [],
      // permission: // permission.snDraft,
      isEnable: true,
      isShow: true,
    },
    clear: {
      type: 'selectEquipment',
      action: 'clear',
      buttonId: 'right-tool-bar_clear_button',
      imageUrl: '../assets/svg/btn_action_clear.svg',
      iconClass: '',
      actionDescription: 'action.button.clear',
      showPopup: true,
      popupTitle: 'action.button.popup.confirm-clear',
      buttons: [],
      // // permission: // permission.snDraft,
      isEnable: true,
      isShow: true,
    },
    close: {
      type: 'selectEquipment',
      action: 'close',
      buttonId: 'right-tool-bar_close_button',
      imageUrl: '../assets/svg/btn_action_reject.svg',
      iconClass: '',
      actionDescription: 'action.button.close',
      showPopup: true,
      popupTitle: 'action.button.popup.confirm-close',
      buttons: [],
      // // permission: // permission.snDraft,
      isEnable: true,
      isShow: true,
    },
  },
};

export enum ActionButtonType {
  cmTaskCreate = 'cmTaskCreate',
  cmTaskView = 'cmTaskView',
  snView = 'snView',
  selectEquipment = 'selectEquipment',
}

export enum ActionButtonSelectEquipment {
  save = 'save',
  clear = 'clear',
  close = 'close',
}
