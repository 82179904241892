import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import * as moment from 'moment';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { stringToMoment, stringToNgbDate } from 'src/app/core/Formatter';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { CmTaskService } from 'src/app/shared/cm-task.service';
import { DateInputI } from 'src/app/shared/date-input/date-input.model';
import { TimeInputI } from 'src/app/shared/time-input/time-input.model';
import { Constants } from 'src/constants';
import { CmTaskProgressService } from '../cm-task-progress.service';
import { DateTimeInput } from './cm-task-progress.model';

@Component({
  selector: 'app-cm-task-progress',
  templateUrl: './cm-task-progress.component.html',
  styleUrls: ['./cm-task-progress.component.scss'],
})
export class CmTaskProgressComponent implements OnInit, OnChanges {
  @Input() jobCard: JMOBJ.JobCard;
  @Input() sn: JMOBJ.ServiceNotification;
  @Input() pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.VIEW;
  @Input() contract?: JMOBJ.MaintenanceTermContract;
  @Input() contractSeverity?: JMOBJ.ContractSeverity;
  
  JMPageMode = JMENUM.JMPageMode;
  // input
  allDateTimeInputList: DateTimeInput[] = [];
  responseToClientTimeInput: DateTimeInput = new DateTimeInput();
  receivedTimeInput: DateTimeInput = new DateTimeInput();
  appointmentTimeInput: DateTimeInput = new DateTimeInput();
  startTimeInput: DateTimeInput = new DateTimeInput();
  plannedCompletionTimeInput: DateTimeInput = new DateTimeInput();
  completionTimeInput: DateTimeInput = new DateTimeInput();
  malfunctionStartTimeInput: DateTimeInput = new DateTimeInput();
  malfunctionEndTimeInput: DateTimeInput = new DateTimeInput();

  breakdownCheckbox = {
    value: false,
    isDisabled: false,
  };
  validationResult = {
    isPassResponseClient: CmTaskComplianceStatus.null,
    isPassResponse: CmTaskComplianceStatus.null,
    isPassRectification: CmTaskComplianceStatus.null,
  };
  complianceStatus = CmTaskComplianceStatus;
  validationCheckList = [
    "responseClientTime",
    "receivedTime",
    "appointmentTime",
    "startTime",
    "completionTime",
  ];
  completeTimeInterval = 15;
  arrivalGpsParam: any = undefined;

  constructor(
    private cmTaskProgressService: CmTaskProgressService,
    private cmTaskService: CmTaskService,
  ) { }

  ngOnInit(): void {
    this.resetInput();

    if (this.isViewMode) {
      this.breakdownCheckbox.isDisabled = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.isCreateMode) {
      if(changes.contractSeverity && !changes.contractSeverity.isFirstChange()) {
        this.recalComplianceStatus();
      }
    } else if(changes.contractSeverity && changes.contractSeverity.previousValue && changes.contractSeverity.currentValue) {
      this.recalComplianceStatus();
    }
    this.initArrivalGpsParam();
  }

  //------------------------
  // parent component trigger function
  public setCreateStandaloneCmTaskData(request: JM.JMRequestSnCreateStandaloneCmTask) {
    this.responseToClientTimeInput.value && (request.responseToClientTime = this.responseToClientTimeInput.value);
    this.receivedTimeInput.value && (request.receivedTime = this.receivedTimeInput.value);
    this.appointmentTimeInput.value && (request.appointmentTime = this.appointmentTimeInput.value);
    this.startTimeInput.value && (request.startTime = this.startTimeInput.value);
    this.plannedCompletionTimeInput.value && (request.plannedCompletionTime = this.plannedCompletionTimeInput.value);
    this.completionTimeInput.value && (request.completionTime = this.completionTimeInput.value);
    this.malfunctionStartTimeInput.value && (request.malfunctionStartTime = this.malfunctionStartTimeInput.value);
    this.malfunctionEndTimeInput.value && (request.malfunctionEndTime = this.malfunctionEndTimeInput.value);
    request.breakdown = this.breakdownCheckbox.value;
  }

  public setUpdateCmTaskData(request: JM.JMRequestJobCardsUpdateCmTask) {
    this.responseToClientTimeInput.value && (request.responseToClientTime = this.responseToClientTimeInput.value);
    this.receivedTimeInput.value && (request.receivedTime = this.receivedTimeInput.value);
    this.appointmentTimeInput.value && (request.appointmentTime = this.appointmentTimeInput.value);
    this.startTimeInput.value && (request.startTime = this.startTimeInput.value);
    this.plannedCompletionTimeInput.value && (request.plannedCompletionTime = this.plannedCompletionTimeInput.value);
    this.completionTimeInput.value && (request.completionTime = this.completionTimeInput.value);
    this.malfunctionStartTimeInput.value && (request.malfunctionStartTime = this.malfunctionStartTimeInput.value);
    this.malfunctionEndTimeInput.value && (request.malfunctionEndTime = this.malfunctionEndTimeInput.value);
    request.breakdown = this.breakdownCheckbox.value;
  }

  public setValuesInEditMode(jobCard: JMOBJ.JobCard) {
    const list = [
      {
        input: this.responseToClientTimeInput,
        date: jobCard.responseToClientTime,
      },
      {
        input: this.receivedTimeInput,
        date: jobCard.receivedTime,
      },
      {
        input: this.appointmentTimeInput,
        date: jobCard.appointmentTime,
      },
      {
        input: this.startTimeInput,
        date: jobCard.startTime,
      },
      {
        input: this.plannedCompletionTimeInput,
        date: jobCard.plannedCompletionTime,
      },
      {
        input: this.completionTimeInput,
        date: jobCard.completionTime,
      },
      {
        input: this.malfunctionStartTimeInput,
        date: jobCard.malfunctionStartTime,
      },
      {
        input: this.malfunctionEndTimeInput,
        date: jobCard.malfunctionEndTime,
      },
    ];

    this.breakdownCheckbox.value = jobCard.breakdown;
    this.onChangedBreakdownCheckbox();
    if (this.isViewMode) {
      this.breakdownCheckbox.isDisabled = true;
    }
    this.setDataTimeInputValue(list);

    this.jobCard.complianceStatus && this.updateComplianceStatus(this.jobCard.complianceStatus);
  }

  public resetFields() {
    this.resetInput();
  }

  public validateFieldsFormat(): boolean {
    this.setAllFieldsNoError(this.allDateTimeInputList);
    return !this.cmTaskProgressService.isInvalidFormat(this.allDateTimeInputList);
  }

  public validateMandatoryFields(): boolean {
    this.setAllFieldsNoError(this.allDateTimeInputList);
    return !this.cmTaskProgressService.isMissingMandatoryField(this.allDateTimeInputList);
  }

  public validateData(): boolean {
    this.setAllFieldsNoError(this.allDateTimeInputList);

    // checking - inputted fields format
    if (this.cmTaskProgressService.isInvalidFormat(this.allDateTimeInputList)) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('component.cm-task-progress.toast.date-time.invalid-format'));
      return false;
    }

    // checking - mandatory fields
    // if (this.cmTaskProgressService.isMissingMandatoryField(this.allDateTimeInputList)) {
    //   AppDelegate.toastMsg().showMsg(
    //     JMLanguage.translate('component.cm-task-progress.toast.date-time.missing-mandatory-fields')
    //   );
    //   return false;
    // }

    // checking - inputted fields are earlier than contract end date
    const hasVendorEndDate = this.contract && this.contract.vendorEndDate;
    if (hasVendorEndDate) {
      const vendorEndDateMoment = stringToMoment(this.contract.vendorEndDate, 'YYYYMMDD');
      if (
        vendorEndDateMoment &&
        this.cmTaskProgressService.isOverVendorEndDate(this.allDateTimeInputList, vendorEndDateMoment)
      ) {
        const textArray = [vendorEndDateMoment.format(Constants.DATE_FORMAT)];
        AppDelegate.toastMsg().showMsg(
          JMLanguage.translate('component.cm-task-progress.toast.date-time.earlier-than-contract-end-date', textArray)
        );
        return false;
      }
    }

    // checking - must not be a future time
    const futureDateCheckingList = this.getFutureDateCheckingList();
    if (this.cmTaskProgressService.isFutureDate(futureDateCheckingList)) {
      AppDelegate.toastMsg().showMsg(
        JMLanguage.translate('component.cm-task-progress.toast.date-time.cannot-future-time')
      );
      return false;
    }

    // checking - time 1 must be earlier than time 2
    const equalOrEarlierTimeCheckingList = this.getEarlierTimeCheckinglist();
    for (const inputArray of equalOrEarlierTimeCheckingList) {
      const hasBothValue = inputArray[0].value && inputArray[1].value;
      if (hasBothValue && this.cmTaskProgressService.isTime1EqualOrLaterThanTime2(inputArray[0], inputArray[1])) {
        const textArray = [inputArray[0].text, inputArray[1].text];
        AppDelegate.toastMsg().showMsg(
          JMLanguage.translate('component.cm-task-progress.toast.date-time.earlier-than-another-time', textArray)
        );
        return false;
      }
    }

    // checking - time 1 must be equal or earlier than time 2
    const earlierTimeCheckinglist = this.getEqualOrEarlierTimeCheckingList();
    for (const inputArray of earlierTimeCheckinglist) {
      const hasBothValue = inputArray[0].value && inputArray[1].value;
      if (hasBothValue && this.cmTaskProgressService.isTime1LaterThanTime2(inputArray[0], inputArray[1])) {
        const textArray = [inputArray[0].text, inputArray[1].text];
        AppDelegate.toastMsg().showMsg(
          JMLanguage.translate(
            'component.cm-task-progress.toast.date-time.equal-or-earlier-than-another-time',
            textArray
          )
        );
        return false;
      }
    }

    // check timeInterval
    const timeIntervalCheckinglist = this.getTimeIntervalCheckinglist();
    for (const inputArray of timeIntervalCheckinglist) {
      const hasBothValue = inputArray.dateInput[0].value && inputArray.dateInput[1].value;

      if (hasBothValue && !this.cmTaskProgressService.isTime1Time2DiffMeetsInterval(
        inputArray.dateInput[0],
        inputArray.dateInput[1],
        inputArray.number
      )) {
        const textArray = [
          inputArray.dateInput[0].text,
          inputArray.dateInput[1].text,
          this.completeTimeInterval,
          JMLanguage.translate('enum.vp-time-reference-unit.mins'),
        ];
        AppDelegate.toastMsg().showMsg(
          JMLanguage.translate(
            'component.cm-task-progress.toast.date-time.time-interval',
            textArray,
          )
        );
        return false;
      }
    }

    return true;
  }

  private getFutureDateCheckingList(): DateTimeInput[] {
    return [this.receivedTimeInput, this.startTimeInput, this.completionTimeInput];
  }

  private getEarlierTimeCheckinglist(): DateTimeInput[][] {
    let list: DateTimeInput[][] = [
      [this.startTimeInput, this.completionTimeInput],
      [this.malfunctionStartTimeInput, this.malfunctionEndTimeInput],
      [this.malfunctionStartTimeInput, this.receivedTimeInput],
    ];

    return list;
  }

  private getEqualOrEarlierTimeCheckingList(): DateTimeInput[][] {
    return [
      [this.receivedTimeInput, this.responseToClientTimeInput],
      [this.responseToClientTimeInput, this.startTimeInput],
      [this.receivedTimeInput, this.appointmentTimeInput],
      [this.malfunctionEndTimeInput, this.completionTimeInput],
    ];
  }

  private getTimeIntervalCheckinglist(): { dateInput: DateTimeInput[], number} [] {
    return [{
      dateInput:[this.startTimeInput, this.completionTimeInput],
      number: this.completeTimeInterval * 60 * 1000,
    }];
  }

  private setDataTimeInputValue(inputList: { input: DateTimeInput; date: Date }[]) {
    for (const obj of inputList) {
      if (obj.date) {
        const momentDate = moment(obj.date);

        obj.input.dateInput = stringToNgbDate(momentDate.toString());
        obj.input.timeInput = momentDate.format('HH:mm');
        obj.input.value = this.getDateByDateTimeInput(obj.input);
      }
    }
  }

  async requestComplianceStatus() {
    const request = new JM.JMRequestJobCardsGetComplianceStatus();
    request.contractSeverityId = this.contractSeverity._id;
    request.appointmentTime = this.appointmentTimeInput.value;
    request.completionTime = this.completionTimeInput.value;
    request.receivedTime = this.receivedTimeInput.value;
    request.responseToClientTime = this.responseToClientTimeInput.value;
    request.startTime = this.startTimeInput.value;

    const response: JM.JMResponseJobCardsGetComplianceStatus = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }
    return response;
  }

  recalComplianceStatus() {
    if (this.needComplianceStatus) {
      this.requestComplianceStatus().then((response) => response?.payload && this.updateComplianceStatus(response.payload));
    }
  }

  updateComplianceStatus(payload: {
      isResponseToClientTimePassed: Boolean,
      isResponseTimePassed: Boolean,
      isRectificationTimePassed: Boolean,
  }) {

    const validationResult = {
      isPassResponseClient: this.contractSeverity?.responseToClient ? translateComplianceStatus(payload.isResponseToClientTimePassed) : CmTaskComplianceStatus.na,
      isPassResponse: translateComplianceStatus(payload.isResponseTimePassed),
      isPassRectification: translateComplianceStatus(payload.isRectificationTimePassed),
    }

    this.validationResult = validationResult;
  }

  isCompliacneStatusFail() {
    return Object.values(this.validationResult).includes(CmTaskComplianceStatus.fail);
  }

  //------------------------
  // reset function
  private resetInput() {
    this.allDateTimeInputList = [
      this.responseToClientTimeInput,
      this.receivedTimeInput,
      this.appointmentTimeInput,
      this.startTimeInput,
      this.plannedCompletionTimeInput,
      this.completionTimeInput,
      this.malfunctionStartTimeInput,
      this.malfunctionEndTimeInput,
    ];

    this.breakdownCheckbox.value = false;
    this.resetResponseToClientTimeInput();
    this.resetReceivedTimeInput();
    this.resetAppointmentTimeInput();
    this.resetStartTimeInput();
    this.resetPlannedCompletionTimeInput();
    this.resetCompletionTimeInput();
    this.resetMalfunctionStartTimeInput();
    this.resetMalfunctionEndTimeInput();
  }

  private resetResponseToClientTimeInput() {
    this.responseToClientTimeInput.dateInput = undefined;
    this.responseToClientTimeInput.timeInput = undefined;
    this.responseToClientTimeInput.value = undefined;
    this.responseToClientTimeInput.text = JMLanguage.translate('component.cm-task-progress.response-to-client-time');
    this.responseToClientTimeInput.isError = false;
    this.responseToClientTimeInput.isDisabled = false;
    this.responseToClientTimeInput.isMandatory = true;
  }

  private resetReceivedTimeInput() {
    this.receivedTimeInput.dateInput = undefined;
    this.receivedTimeInput.timeInput = undefined;
    this.receivedTimeInput.value = undefined;
    this.receivedTimeInput.text = JMLanguage.translate('component.cm-task-progress.received-time');
    this.receivedTimeInput.isError = false;
    this.receivedTimeInput.isDisabled = false;
    this.receivedTimeInput.isMandatory = true;
  }

  private resetAppointmentTimeInput() {
    this.appointmentTimeInput.dateInput = undefined;
    this.appointmentTimeInput.timeInput = undefined;
    this.appointmentTimeInput.value = undefined;
    this.appointmentTimeInput.text = JMLanguage.translate('component.cm-task-progress.appointed-time');
    this.appointmentTimeInput.isError = false;
    this.appointmentTimeInput.isDisabled = false;
    this.appointmentTimeInput.isMandatory = true;
  }

  private resetStartTimeInput() {
    this.startTimeInput.dateInput = undefined;
    this.startTimeInput.timeInput = undefined;
    this.startTimeInput.value = undefined;
    this.startTimeInput.text = JMLanguage.translate('component.cm-task-progress.arrival-start-time');
    this.startTimeInput.isError = false;
    this.startTimeInput.isDisabled = false;
    this.startTimeInput.isMandatory = true;
  }

  private resetPlannedCompletionTimeInput() {
    this.plannedCompletionTimeInput.dateInput = undefined;
    this.plannedCompletionTimeInput.timeInput = undefined;
    this.plannedCompletionTimeInput.value = undefined;
    this.plannedCompletionTimeInput.text = JMLanguage.translate('component.cm-task-progress.planned-completion-time');
    this.plannedCompletionTimeInput.isError = false;
    this.plannedCompletionTimeInput.isDisabled = false;
    this.plannedCompletionTimeInput.isMandatory = false;
  }

  private resetCompletionTimeInput() {
    this.completionTimeInput.dateInput = undefined;
    this.completionTimeInput.timeInput = undefined;
    this.completionTimeInput.value = undefined;
    this.completionTimeInput.text = JMLanguage.translate('component.cm-task-progress.completion-time');
    this.completionTimeInput.isError = false;
    this.completionTimeInput.isDisabled = false;
    this.completionTimeInput.isMandatory = true;
  }

  private resetMalfunctionStartTimeInput() {
    this.malfunctionStartTimeInput.dateInput = undefined;
    this.malfunctionStartTimeInput.timeInput = undefined;
    this.malfunctionStartTimeInput.value = undefined;
    this.malfunctionStartTimeInput.text = JMLanguage.translate('component.cm-task-progress.malfunction-start-time');
    this.malfunctionStartTimeInput.isError = false;
    this.malfunctionStartTimeInput.isDisabled = false;
    this.malfunctionStartTimeInput.isMandatory = false;
  }

  private resetMalfunctionEndTimeInput() {
    this.malfunctionEndTimeInput.dateInput = undefined;
    this.malfunctionEndTimeInput.timeInput = undefined;
    this.malfunctionEndTimeInput.value = undefined;
    this.malfunctionEndTimeInput.text = JMLanguage.translate('component.cm-task-progress.malfunction-end-time');
    this.malfunctionEndTimeInput.isError = false;
    this.malfunctionEndTimeInput.isDisabled = false;
    this.malfunctionEndTimeInput.isMandatory = false;
  }

  private setAllFieldsNoError(dateTimeInputList: DateTimeInput[]) {
    for (const dateTimeInput of dateTimeInputList) {
      dateTimeInput.isError = false;
    }
  }

  //------------------------
  // event function
  onUpdatedDate(dateInput: DateInputI, dateTimeInput: DateTimeInput, jobCardFieldName: string) {
    dateTimeInput.isError = false;
    dateTimeInput.dateInput = dateInput.date;
    dateTimeInput.value = this.getDateByDateTimeInput(dateTimeInput);
    
    if (this.validationCheckList.includes(jobCardFieldName)) {
      this.recalComplianceStatus();
    }
  }

  onUpdatedTime(timeInput: TimeInputI, dateTimeInput: DateTimeInput, jobCardFieldName: string) {
    dateTimeInput.isError = false;
    dateTimeInput.timeInput = timeInput.time;
    dateTimeInput.value = this.getDateByDateTimeInput(dateTimeInput);

    if (this.validationCheckList.includes(jobCardFieldName)) {
      this.recalComplianceStatus();
    }
  }

  onChangedBreakdownCheckbox() {
    const hasBreakdown = this.breakdownCheckbox.value;

    this.malfunctionStartTimeInput.isMandatory = hasBreakdown;
    this.malfunctionEndTimeInput.isMandatory = hasBreakdown;
  }

  private getDateByDateTimeInput(dateTimeInput: DateTimeInput): Date {
    if (dateTimeInput.dateInput && dateTimeInput.timeInput) {
      const date = dateTimeInput.dateInput;
      const timeArr = dateTimeInput.timeInput.split(':');
      const formattedDate = new Date(date.year, date.month - 1, date.day);

      if (timeArr && timeArr.length > 0) {
        formattedDate.setHours(Number(timeArr[0]));
        formattedDate.setMinutes(Number(timeArr[1]));
        return formattedDate;
      }
    }

    return undefined;
  }

  initArrivalGpsParam() {
    if (this.jobCard.arrival == undefined) return
    this.arrivalGpsParam = {
      startTime : this.jobCard.startTime,
      completionTime : this.jobCard.completionTime,
      gpsLocations: this.jobCard.arrival,
    }
  }

  //------------------------
  // get property
  get isCreateMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.CREATE;
  }
  get isEditMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.EDIT;
  }
  get isViewMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.VIEW;
  }

  get needComplianceStatus(): boolean {
    const isFirstAttendedJob = this.cmTaskService.isFirstAttendedJob(this.jobCard);
    return isFirstAttendedJob == undefined || isFirstAttendedJob == true;
  }

  get emptyHandleNa(): string {
    return JMLanguage.translate('component.display-text-field.na');
  }
}

export enum CmTaskComplianceStatus {
  pass = 'pass',
  fail = 'fail',
  na = 'na',
  null = 'null',
};

export function translateComplianceStatus(status: Boolean) {
  return status ? CmTaskComplianceStatus.pass : status == false ? CmTaskComplianceStatus.fail : CmTaskComplianceStatus.null;
}
