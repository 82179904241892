import { Component, OnInit } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { UserHelper } from 'src/app/core/user-helper';
import { Util } from 'src/app/core/util';
import * as moment from 'moment';

import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';
import { Constants } from 'src/constants';

@Component({
  selector: 'app-setting-user-emsd',
  templateUrl: './setting-user-emsd.component.html',
  styleUrls: ['./setting-user-emsd.component.scss'],
})
export class SettingUserEmsdComponent implements OnInit {
  userList: JMOBJ.Post[] = [];
  selectedUser: JMOBJ.Post = null;
  componentUEEMode: JMENUM.JMPageMode;

  //TableX
  tablexParam: { [key: string]: any } = {};

  constructor() {}

  async ngOnInit() {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }
    
    this.initTablex();
    await this.requestUserList();
    this.renderTable();
  }

  initTablex() {
    this.tablexParam = {
      pageSizeOptions: [10, 25, 100],
      currentPageSize: 10,
      currentPage: 1,
      pageCount: 0,
      enableSetPageSize: true,
      enablePagination: true,
      enableSort: true,
      enableColFilter: false,
      enableSelectedRowCount: false,
      isLoadingTable: false,
      // tableRow: 'd-flex',
      tableClass: 'user-table',
      tableWrapperClass: 'table-min-width',
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowSelected: this.onRowSelected,
      onRowUnselected: this.onRowUnselected,
      onFilterChanged: this.onFilterChanged,
      // onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      content: [],
    };
    this.initTableHeader();
  }

  initTableHeader() {
    this.tablexParam.headers = [
      {
        id: JMRequestPostsPostSummaryParams.NAME,
        name: 'pages.setting-user-emsd.table-column.post',
        enableSort: false,
        enableFilter: true,
        filterAttribute: 'name',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-20',
      },
      {
        id: JMRequestPostsPostSummaryParams.CODE,
        name: 'pages.setting-user-emsd.table-column.post-code',
        enableSort: false,
        enableFilter: true,
        filterAttribute: 'vendorName',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-20',
      },
      {
        id: JMRequestPostsPostSummaryParams.ROLES,
        name: 'pages.setting-user-emsd.table-column.role',
        enableSort: false,
        enableFilter: false,
        filterAttribute: 'employeeName',
        type: TablexColumnType.MultiLine,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-20',
      },
      {
        id: JMRequestPostsPostSummaryParams.IS_ACTIVE,
        name: 'pages.setting-user-emsd.table-column.account-status',
        enableSort: false,
        enableFilter: false,
        filterAttribute: 'team',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'w-20',
      },
      // {
      //   id: JMRequestPostsPostSummaryParams.CREATED_BY,
      //   name: 'pages.setting-user-emsd.table-column.created-by',
      //   enableSort: false,
      //   enableFilter: false,
      //   filterAttribute: 'team',
      //   type: TablexColumnType.MultiLine,
      //   horizontalAlign: TablexColumnHorizontalAlign.Center,
      //   verticalAlign: TablexColumnVerticalAlign.Middle,
      //   class: 'w-20',
      // },
    ];
  }

  renderTable() {
    this.tablexParam.content = this.userList.map((post) => {
      let name = post[JMRequestPostsPostSummaryParams.NAME];
      let code = post[JMRequestPostsPostSummaryParams.CODE];
      let roles = post[JMRequestPostsPostSummaryParams.ROLES] || [];
      let isActive = post[JMRequestPostsPostSummaryParams.IS_ACTIVE];
      let createdBy = post[JMRequestPostsPostSummaryParams.CREATED_BY];
      let createdAt = post[JMRequestPostsPostSummaryParams.CREATED_AT];

      // let parsedCreatedAt = createdAt ? moment(createdAt).format(Constants.DATETIME_FORMAT_2) : null;

      return [
        name,
        code,
        roles.map((role) => role.name),
        isActive ? JMLanguage.translate('global.active') : JMLanguage.translate('global.inactive'),
        // [createdBy, parsedCreatedAt],
      ];
    });
  }

  async requestUserList(pageNumber?: number, pageSize?: number, filter?: { [key: string]: string }) {
    if (!this.hasEmsdUserViewPermission) {
      return;
    }

    let request = new JM.JMRequestPostsPostSummary();
    request.inhouseOnly = true;
    request.systemName = Constants.SYSTEM_NAME;
    request.parameters = Object.values(JMRequestPostsPostSummaryParams);
    request.pageSize = pageSize ? pageSize : this.tablexParam.currentPageSize;
    request.pageNumber = pageNumber ? pageNumber : this.tablexParam.currentPage;
    request.filter = filter;

    this.tablexParam.isLoadingTable = true;
    const response: JM.JMResponsePostsPostSummary = await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.tablexParam.currentPageSize = request.pageSize;
    this.tablexParam.currentPage = request.pageNumber;
    this.userList = response.payload.records;
    this.tablexParam.totalRowCount = response.payload.totalCount;
    this.tablexParam.pageCount = Math.ceil(response.payload.totalCount / request.pageSize);
  }

  onSubmitSuccess = async () => {
    await this.requestUserList(1);
    this.selectedUser = null;
    this.componentUEEMode = null;
    this.tablexParam.selectedRowIndex = -1;
    this.renderTable();
  };

  onPageSizeClicked = async (pageSize: number) => {
    await this.requestUserList(1, pageSize);
    this.renderTable();
  };

  onPageNumberClicked = async (pageNumber: number) => {
    await this.requestUserList(pageNumber);
    this.renderTable();
  };

  onFilterChanged = async (event, index, header, filter) => {
    if (filter[header.id] == null || filter[header.id] == '') {
      delete filter[header.id];
    }
    await this.requestUserList(1, null, filter);
    this.renderTable();
  };

  onRowSelected = async (index, row) => {
    this.selectedUser = this.userList[index];
    this.componentUEEMode = JMENUM.JMPageMode.EDIT;
  };
  onRowUnselected = async (index, row) => {
    this.selectedUser = null;
    this.componentUEEMode = null;
  };

  onClickedCreateMode = () => {
    this.componentUEEMode = JMENUM.JMPageMode.CREATE;
    this.tablexParam.selectedRowIndex = -1;
  };

  get hasEmsdUserViewPermission(): boolean {
    return UserHelper.hasPermission(JMENUM.VpPermission.EMSDUSER_VIEW);
  }

  get hasEmsdUserCreatePermission(): boolean {
    return UserHelper.hasPermission(JMENUM.VpPermission.EMSDUSER_CREATE);
  }

  get hasEmsdUserUpdatePermission(): boolean {
    return UserHelper.hasPermission(JMENUM.VpPermission.EMSDUSER_UPDATE);
  }

  get isComponentUEEModeCreate(): boolean {
    return this.componentUEEMode === JMENUM.JMPageMode.CREATE;
  }

  get isComponentUEEModeEdit(): boolean {
    return this.componentUEEMode === JMENUM.JMPageMode.EDIT;
  }

  get shouldComponentUEEShow(): boolean {
    return (
      (this.hasEmsdUserCreatePermission && this.isComponentUEEModeCreate) ||
      (this.hasEmsdUserUpdatePermission && this.isComponentUEEModeEdit)
    );
  }
}
enum JMRequestPostsPostSummaryParams {
  NAME = 'name',
  CODE = 'code',
  ROLES = 'roles',
  IS_ACTIVE = 'isActive',
  CREATED_BY = 'createdBy',
  CREATED_AT = 'createdAt',
  EMPLOYEE_NAME = 'employeeName',
}
