import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Session } from '../core/session';
import * as moment from 'moment';
@Injectable({
    providedIn: 'root'
  })

  export class AuthorizationService {
    private token: string;
    private user: any = null;
    private permissionSet: Set<string>;
    private workCenterSet: Set<string>;
    private DEFAULT_VAILD_PASSWORD_LENGTH:number = 6;
    private DEFAULT_SEQUENCE_LENGTH:number = 6;

    constructor(
        private http: HttpClient,
      ) { }

      hasPermission(permissionKey: string): boolean {
        return permissionKey
          ? (this.user ? this.permissionSet.has(permissionKey) : false)
          : true;
      }
      hasPermissions(permissionList: string[], requiredIncludeAll: boolean): boolean {
        if (!permissionList || permissionList.length === 0) {
          return true;
        }
        if (requiredIncludeAll){
          return permissionList.every( requiredPermission => this.hasPermission(requiredPermission));
        } else {
          return permissionList.some( requiredPermission => this.hasPermission(requiredPermission));
        }
      }
      getContracts(): string[] {
        return (this.user.authorizations && this.user.authorizations.contracts)? this.user.authorizations.contracts: [];
      }
      setUserInfo(userObject: any, token:string): void {
        try {
          this.token = token;
          this.user = userObject;
          this.permissionSet = new Set(this.user.permissions);
          this.workCenterSet = (this.user.authorizations && this.user.authorizations.workCenters)? new Set(this.user.authorizations.workCenters.sort()): new Set([]);
        } catch (e) {
          console.error(e);
        }
      }

      addUserRemindedPwChange(userName: string) {
        if (!userName) return;
    
        let userlist = Session.pwReminderDisableList;
        const dateKey = moment().format('YYMMDD');
    
        if (!userlist) userlist = {};
        if (!userlist[dateKey]) {
          userlist = {
            [dateKey]: []
          }
        }
    
        const tempSet = new Set<string>(userlist[dateKey]);
        tempSet.add(userName);
        userlist[dateKey] = Array.from(tempSet);
    
        Session.setPwReminder(userlist);
      }
      
      isUserRemindedPwChange(userName: string) {
        if (!userName) return null;
    
        let userlist = Session.pwReminderDisableList;
        const dateKey = moment().format('YYMMDD');
    
        if (!userlist) return false;
        if (!userlist[dateKey]) return false;
    
        return userlist[dateKey].includes(userName);
      }

      isPasswordLengthVaild(str:string, validLength:number = this.DEFAULT_VAILD_PASSWORD_LENGTH):boolean{
        return str.length >= validLength
      }
    
      isPasswordContainNumber(str:string):boolean{
        return /\d/.test(str);
      }
    
      isPasswordContainNonAlphanumericCharacters(str:string):boolean{
        return /([^a-zA-Z&\d])+([a-zA-Z\d])+|([a-zA-Z\d])+([^a-zA-Z&\d])+/.test(str)
      }
    
      isPasswordContainName(str:string,username:string):boolean{
        return str.toLowerCase().includes(username.toLowerCase())
      }
    
      isPasswordContainSequence(str:string,sequences:string[] = []):boolean{
        for(const sequence of sequences){
          if(str.toLowerCase().includes(sequence)){
            return true
          }
        }
        return false
      }

      getNumCharSequence(length:number = this.DEFAULT_SEQUENCE_LENGTH): string[] {
        let result: string[] = []
        let numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
        let chars = this.getLowerCaseChars();
        this.appendSequence(result, numbers, length);
        this.appendSequence(result, chars, length);
        return result
      }

      private appendSequence(output: string[], input: any[], length :any) {
        let startpointer = 0
        let endpointer = startpointer + length
        while (1) {
          if (endpointer > input.length) {
            break
          }
          output.push(input.slice(startpointer, endpointer).join(""));
          startpointer++
          endpointer = startpointer + length
        }
      }

      private getLowerCaseChars(): string[] {
        let result: string[] = []
        for (let i = 97; i <= 122; i++) {
          result.push(String.fromCharCode(i))
        }
        return result
      }
  }
  