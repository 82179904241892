import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Constants } from 'src/constants';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { ngbDateToString } from 'src/app/core/Formatter';
import { Session } from 'src/app/core/session';

@Component({
  selector: 'app-pm-plan-search-criteria',
  templateUrl: './pm-plan-search-criteria.component.html',
  styleUrls: ['./pm-plan-search-criteria.component.scss'],
})
export class PmPlanSearchCriteriaComponent implements OnInit {
  @Output() onSearch: EventEmitter<any> = new EventEmitter();

  isSearching: boolean = false;
  isCollapse: boolean = false;
  isDisabled: boolean = false; //For Not Ready Function

  startDateNgb: any;
  endDateNgb: any;
  frequencyOptions: any[];
  searchSelections: {
    pmPlanNumber: string;
    contractNumber: string;
    planStatus: { [key: string]: boolean };
    // planCoverage: { [ key : string ] : boolean };
    scheduleType: { [key: string]: boolean };
    startRange: 'on-or-before' | 'after';
    startDate: String;
    endRange: 'on-or-before' | 'after';
    endDate: String;
    frequency: string[];
    planDescription: string;
    handlingTeam: string[];
  };

  // Team Dropdown
  private searchTeamObserver = new Subject<any[]>();
  teamOptions: any = [];
  searchTeamKeyword: string = null;
  teamPageSize: number = 100;
  teamPageNumber: number = 1;
  teamTotalPage: number;
  isLoadingTeam: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.initFilters();
    this.loadFilterOptionsFromLocal();
    this.onSearch.emit({
      searchCriteria: this.searchSelections,
      pageNumber: 1,
      pageSize: null,
    });
  }

  async initFilters() {
    this.searchSelections = {
      pmPlanNumber: null,
      contractNumber: null,
      planStatus: {},
      // // planCoverage: {},
      scheduleType: {},
      startRange: 'after',
      startDate: '20100101',
      endRange: 'after',
      endDate: '20100101',
      frequency: [],
      planDescription: null,
      handlingTeam: [],
    };
    this.startDateNgb = {
      year: 2010,
      month: 1,
      day: 1,
    };
    this.endDateNgb = {
      year: 2010,
      month: 1,
      day: 1,
    };

    let statusEnum = Object.values(JMENUM.PMStatus);
    for (let i = 0; i < statusEnum.length; i++) {
      this.searchSelections.planStatus[statusEnum[i]] = false;
    }
    this.searchSelections.planStatus[JMENUM.PMStatus.IN_PROGRESS] = true;
    this.searchSelections.planStatus[JMENUM.PMStatus.OUTSTANDING] = true;

    let scheduleEnum = Object.values(JMENUM.PMScheduleType);
    for (let i = 0; i < scheduleEnum.length; i++) {
      this.searchSelections.scheduleType[scheduleEnum[i]] = false;
    }

    let frequencyEnum = Object.values(JMENUM.PMPlanFrequency);
    this.frequencyOptions = [];
    for (let i = 0; i < frequencyEnum.length; i++) {
      this.frequencyOptions.push({
        value: frequencyEnum[i],
        label: JMLanguage.translate(`pm-plan.frequency.${frequencyEnum[i]}`),
      });
    }

    // team list init
    this.searchTeamObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchTeams.bind(this));
    this.teamOptions = await this.requestTeamList(this.teamPageNumber);
  }

  async requestTeamList(pageNumber?: number) {
    let request: JM.JMRequestContractsGetVendorTeamList = new JM.JMRequestContractsGetVendorTeamList();

    request.parameters = ['_id', 'name'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.teamPageSize;

    if (this.searchTeamKeyword) {
      request.filter = { name: this.searchTeamKeyword };
    }

    this.isLoadingTeam = true;
    let response: JM.JMResponseContractsGetVendorTeamList = await AppDelegate.sendJMRequest(request);
    this.isLoadingTeam = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (pageNumber === 0) {
      let totalCount = response.payload.totalCount;
      this.teamTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    return response.payload.records.map((team) => {
      return { value: team._id, label: team.name };
    });
  }


  resetFilters() {
    this.searchSelections.pmPlanNumber = null;
    this.searchSelections.contractNumber = null;
    this.searchSelections.startRange = 'after';
    this.searchSelections.startDate = '20100101';
    this.searchSelections.endRange = 'after';
    this.searchSelections.endDate = '20100101';
    this.searchSelections.planDescription = null;
    this.searchSelections.frequency = [];
    this.searchSelections.handlingTeam = [];

    for (const key in this.searchSelections.planStatus) {
      this.searchSelections.planStatus[key] = false;
    }
    this.searchSelections.planStatus[JMENUM.PMStatus.IN_PROGRESS] = true;
    this.searchSelections.planStatus[JMENUM.PMStatus.OUTSTANDING] = true;

    for (const key in this.searchSelections.scheduleType) {
      this.searchSelections.scheduleType[key] = false;
    }
    this.startDateNgb = { year: 2010, month: 1, day: 1 };
    this.endDateNgb = { year: 2010, month: 1, day: 1 };
  }

  // date picker handler
  onBlurDateInput(event) {
    if (event.fieldName == 'startDate') {
      this.startDateNgb = event.date;
      this.searchSelections.startDate = ngbDateToString(event.date);
    } else if (event.fieldName == 'endDate') {
      this.endDateNgb = event.date;
      this.searchSelections.endDate = ngbDateToString(event.date);
    }
  }

  // Start: team ng select handler
  onSearchTeam(event) {
    this.searchTeamKeyword = event.term;
    this.searchTeamObserver.next();
  }

  clearTeamFilter() {
    this.searchTeamKeyword = null;
    this.searchTeams();
  }

  async searchTeams() {
    this.teamOptions = [];
    this.teamPageNumber = 1;
    this.teamTotalPage = null;
    this.teamOptions = await this.requestTeamList(this.teamPageNumber);
  }

  async onTeamScrollToEnd() {
    if (this.teamPageNumber < this.teamTotalPage) {
      this.teamPageNumber++;

      let teams = await this.requestTeamList(this.teamPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.teamOptions = [...this.teamOptions, ...teams];
    }
  }
  // End: team ng select handler

  // Start: search icon handler
  onClickSearch() {
    this.onSearch.emit({
      searchCriteria: this.searchSelections,
      pageNumber: 1,
      pageSize: null,
    });
    this.saveFilterOptionsToLocal();
  }

  onClickReset() {
    this.resetFilters();
    this.onSearch.emit({
      searchCriteria: this.searchSelections,
      pageNumber: 1,
      pageSize: null,
    });
    this.saveFilterOptionsToLocal();
  }
  // End: search icon handler

  private saveFilterOptionsToLocal = (): void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.searchSelections));
      delete cache.frequencyOptions;
      Session.setPmPlanSettingsSearchFilters(cache);
    } catch (e) {
      console.warn(e);
    }
  };

  private loadFilterOptionsFromLocal = (): void => {
    try {
      let cache = Session.getPmPlanSettingsSearchFilters();
      if (cache) {
        let cacheParsed = JSON.parse(JSON.stringify(cache));
        this.searchSelections = {
          ...this.searchSelections,
          ...cacheParsed,
        };
      }
    } catch (e) {
      console.warn(e);
    }
  };
}
