<div class="wrapper">
  <app-nav [breadcrumbs]="breadcrumbs"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="content cm-task-create-content action-btn-padding">
    <div *ngIf="isEquipmentApprovalPending"
      class="row notification-top"
      >{{ 'pages.pm-plan.equipment.pending-approval' | translate }}
    </div>
    <div class="component-wrap row">
      <div class="col-12 col-xl-6">
        <app-pm-plan-summary
          #pmPlanSummaryElem
          class="d-block mb-5"
          [plan]="plan"
          [pageMode]="pageMode"
          (onChangeContractSelection)="onChangeContractSelection()"
        ></app-pm-plan-summary>
        <app-pm-plan-particulars
          #pmPlanParticularsElem
          [plan]="plan"
          [pageMode]="pageMode"
        ></app-pm-plan-particulars>
        <ng-container *ngIf="hasPMReminderFeatureToggle">
          <app-pm-plan-reminders
            #pmPlanRemindersElem
            [plan]="plan"
            [pageMode]="pageMode"
          ></app-pm-plan-reminders>
        </ng-container>
      </div>

      <div class="col-12 col-xl-6">
        <app-pm-plan-periods
          #pmPlanPeriodsElem
          class="d-block mb-5"
          [plan]="plan"
          [pageMode]="pageMode"
          (onRefreshPeriod)="onRefreshPeriod()"
        ></app-pm-plan-periods>
        <app-pm-plan-remarks
          #pmPlanRemarksElem
          [plan]="plan"
          [pageMode]="pageMode"
        ></app-pm-plan-remarks>
      </div>
    </div>

    <!-- Action Sidebar -->
    <ng-container *ngIf="actionSidebar.dataList && actionSidebar.dataList.length > 0">
      <app-action-sidebar-v2
        [actionButtonList]="actionSidebar.dataList"
        [isDisabled]="actionSidebar.isDisabled"
        [isLoading]="actionSidebar.isLoading"
        (clickedButton)="onClickedActionButton($event)">
      </app-action-sidebar-v2>
    </ng-container>

    <!--PM Plan refer -->
    <app-custom-slider-panel #pm_plan_refer_editor_panel [widthPx]=600
      [customClass]="'pm_plan_refer_editor_panel'">
      <pm-plan-refer-editor [pmPlan]="plan" (updatedPmPlan)="onReferPmPlan($event)">
      </pm-plan-refer-editor>
    </app-custom-slider-panel>
  </div>
</div>
