import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from 'src/app/core/base';
import { AppDelegate } from 'src/app/core/AppDelegate';

@Component({
  selector: 'coming-soon',
  templateUrl: 'coming-soon.component.html',
  styleUrls: ['coming-soon.component.scss'],
})
export class ComingSoonComponent extends BasePage implements OnInit {
  routeUrl: string = '';

  sideBarContentType: string;
  tabMenuContentType: string;

  constructor(private injector: Injector) {
    super(injector, true);
  }

  ngOnInit() {
    this.routeUrl = AppDelegate.getRoute();

    if (this.routeUrl.includes('/settings')) {
      this.sideBarContentType = 'settings';
    }

    if (this.routeUrl.includes('/settings/general')) {
      this.tabMenuContentType = 'settings-general';
    }

    if (this.routeUrl.includes('/settings/contract')) {
      this.tabMenuContentType = 'settings-contract';
    }
  }
  //-------------------------------
}
