import { Component, OnInit } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { VPUserSettings } from '@enum/vp-user-settings.enum';
import { Session } from 'src/app/core/session';
import { UserHelper } from 'src/app/core/user-helper';
import { Util } from 'src/app/core/util';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Constants } from 'src/constants';
import { JMLanguage, Language } from 'src/app/core/JMLanguage/JMLanguage';
import {
  TablexColumnFilterOption,
  TablexColumnFilterType,
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
  language: string;

  //Status
  isDownloading: boolean = false;
  isDisabled: boolean = true; //For Not Ready Function

  // CM Task List
  isGeneralSearch: boolean = false;
  generalSearchWords: string;
  taskList: JM.JMOBJ.ServiceNotification[] = [];
  jobLocationList = [];

  // tablex parameters
  tablexParam: {} = {};
  tablexFilter: {} = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize: number = 10;
  currentPage: number = 1;
  pageCount: number = 1;
  sortBy = undefined;
  sortOrder = undefined;
  selectedColId: any = [];
  allColHeaders: any = [];
  selectedCol: any = [];
  selectedRows: any = [];
  selectedTableRows: any = [];
  statusOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  priorityOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  locationOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  teamOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  equipmentCategoryOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  equipmentTypeOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  initialSystemOptions: TablexColumnFilterOption = new TablexColumnFilterOption();

  // location options
  locationTotalCount: number;
  locationPageNumber: number = 0;
  locationPageSize: number = 100;
  private searchLocationObserver = new Subject<any[]>();
  searchLocationKeywords: string = undefined;

  // Team Options
  teamTotalCount: number;
  teamPageNumber: number = 0;
  teamPageSize: number = 100;
  private searchTeamObserver = new Subject<any[]>();
  searchTeamKeywords: string = undefined;

  // equipmentCategory Options
  // equipCateTotalCount: number;
  // equipCatePageNumber: number = 0;
  // equipCatePageSize: number = 100;
  // private searchEquipCateObserver = new Subject<any[]>();
  // searchEquipCateKeywords: string = undefined;

  // equipmentType Options
  // equipTypeTotalCount: number;
  // equipTypePageNumber: number = 0;
  // equipTypePageSize: number = 100;
  // private searchEquipTypeObserver = new Subject<any[]>();
  // searchEquipTypeKeywords: string = undefined;

  // cache data
  private locationDescriptionMap = new Map<string, string>();

  // search criteria
  searchCriteria: {[x:string]:any} = null;

  constructor() {}

  ngOnInit(): void {
    this.generalSearchWords = Session.snListGeneralSearchKeyword;
    
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }

    this.language = Session.selectedLanguage;
    this.requestLocationSummary();
    this.requestTeamSummary();
    // this.requestEquipmentCache();
    this.initTableHeader();
    this.initFilterOptions();
    this.initSelectedCol();
    this.initTablex();

    if (this.generalSearchWords && this.generalSearchWords.length > 0) {
      this.isGeneralSearch = true;
      this.generalSearch();
    } else {
      this.isGeneralSearch = false;
    }

    this.searchTeamObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.searchTeam();
    });

    this.searchLocationObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.searchLocation();
    });
  }

  // ----------- API ----------- //
  private async requestVpSnList() {
    let request = new JM.JMRequestSnGetVpSnList();
    request.pageSize = this.currentPageSize;
    request.pageNumber = this.currentPage;
    request.sortBy = this.tablexParam['sortBy'];
    request.sortOrder = this.tablexParam['sortOrder'];
    request = this.translateFilters(request);

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseSnGetVpSnList = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.taskList = response.payload.records;
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['currentPage'] = this.currentPage;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);

    this.renderTable();
    this.bindLocationDescription();
  }

  private async generalSearch() {
    let request = new JM.JMRequestSnVpSnGeneralSearch();
    request.expression = this.generalSearchWords;
    request.pageNumber = this.currentPage;
    request.pageSize = this.currentPageSize;
    // request.parameters = this.snParameterList;

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseSnVpSnGeneralSearch = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    Array.isArray(response.payload.records) && (this.taskList = response.payload.records.map(record => record['sn']));
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['currentPage'] = this.currentPage;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);

    this.renderTable();
    this.bindLocationDescription();
  }

  private async requestLocationDescription(locationCodeList: string[]) {
    let request = new JM.JMRequestLocationsLocationSummary();
    request.location = locationCodeList;
    request.includeSummary = true;
    request.pageSize = 100; // table max page size
    request.pageNumber = 1;
    request.parameters = ['code', 'description'];

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      return;
    }

    for (const location of response.payload.records) {
      if (location.description) {
        const lang = JMLanguage.getCurrentLanguage();
        const description = lang === Language.ZH ? location.description.zh : location.description.en;
        this.locationDescriptionMap.set(location.code, description);
      }
    }

    this.renderTable();
  }

  private async requestLocationSummary() {
    if (
      this.locationTotalCount !== null &&
      this.locationTotalCount <= this.locationPageNumber * this.locationPageSize
    ) {
      return;
    }
    this.locationOptions.isLoading = true;
    this.locationPageNumber++;

    let request: JM.JMRequestLocationsLocationSummary = new JM.JMRequestLocationsLocationSummary();
    request.includeSummary = true;
    request.pageSize = this.locationPageSize;
    request.pageNumber = this.locationPageNumber;
    request.locationOnly = true;
    if (this.searchLocationKeywords) {
      request.locationDescription = this.searchLocationKeywords;
    }

    let response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.locationOptions.isLoading = false;
    if (response.error) {
      AppDelegate.toastMsg().showMsg(response.error);
      return;
    }
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showMsg(response);
      return;
    }

    let options = response.payload.records.map((location) => {
      let description = location.description[Session.selectedLanguage]
        ? location.description[Session.selectedLanguage]
        : location.description['en'];
      let name = description + ' (' + location.code + ')';
      let obj = {
        value: location.code,
        label: name,
        descriptionEn: location.description['en'] ? location.description['en'] : '',
        descriptionZh: location.description['zh'] ? location.description['zh'] : '',
      };
      return obj;
    });
    this.locationTotalCount = response.payload.totalCount;
    this.locationOptions.items = this.locationOptions.items.concat(options);
  }

  private async requestTeamSummary() {
    if (this.teamTotalCount !== null && this.teamTotalCount <= this.teamPageNumber * this.teamPageSize) {
      return;
    }
    this.teamOptions.isLoading = true;
    this.teamPageNumber++;

    let request: JM.JMRequestTeamsTeamSummary = new JM.JMRequestTeamsTeamSummary();
    request.pageSize = this.teamPageSize;
    request.pageNumber = this.teamPageNumber;
    request.parameters = ['_id', 'name'];
    // request.contractNumberList = UserHelper.getUserContract; //TODO: UserHelper
    if (this.searchTeamKeywords) {
      // request.id = [this.searchTeamKeywords];
      request['name'] = [this.searchTeamKeywords];
    }

    let response: JM.JMResponseTeamsTeamSummary = await AppDelegate.sendJMRequest(request);
    this.teamOptions.isLoading = false;
    if (response.error) {
      AppDelegate.toastMsg().showMsg(response.error);
      return;
    }
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showMsg(response);
      return;
    }

    let options = response.payload.records.map((team) => {
      let obj = {
        value: team.name, //team._id,
        label: team.name,
      };
      return obj;
    });

    this.teamTotalCount = response.payload.totalCount;
    this.teamOptions.items = this.teamOptions.items.concat(options);
  }

  requestEquipmentCache() {
    let equipmentCategory = AppDelegate.getEquipmentCategory();
    let equipmentType = AppDelegate.getEquipmentType();
    this.equipmentCategoryOptions.items = [];
    this.equipmentTypeOptions.items = [];
    for (let cateCode in equipmentCategory) {
      let cate = equipmentCategory[cateCode];
      this.equipmentCategoryOptions.items.push({
        label: cate.code + ' - ' + cate.description[this.language],
        value: cate.code,
      });
    }
    for (let typeCode in equipmentType) {
      let type = equipmentType[typeCode];
      this.equipmentTypeOptions.items.push({
        label: type.code + ' - ' + type.description[this.language],
        value: type.code,
      });
    }
  }

  private bindLocationDescription() {
    const locationList = this.taskList
      .filter((sn) => sn.location && !this.locationDescriptionMap.has(sn.location))
      .map((sn) => sn.location);
    const uniqueLocaionList = [...new Set(locationList)];

    if (uniqueLocaionList.length) {
      this.requestLocationDescription(uniqueLocaionList);
    }
  }

  // ----------- UI function ----------- //
  initSelectedCol() {
    this.selectedColId = ['snNumber', 'faultDetail', 'status', 'priority', 'client', 'contractor', 'location', 'locationDetail', 'team'];
    this.loadColumnSettingsFromLocal();
    this.onColFiltered(this.selectedColId);
  }

  translateFilters(request: JM.JMRequestSnGetVpSnList) {
    request.filter = {};

    if (this.searchCriteria) {
      if (this.searchCriteria.filter) {
        request.filter = this.searchCriteria.filter;
      }
      request.clientList = this.searchCriteria.clientList;
      request.contractNumberList = this.searchCriteria.contractNumberList;
      request.vendorTeamIdList = this.searchCriteria.vendorTeamIdList;
      request.equipmentCategoryList = this.searchCriteria.equipmentCategoryList;
      request.equipmentTypeList = this.searchCriteria.equipmentTypeList;
      request.locationCodeList = this.searchCriteria.locationCodeList;
      request.faultDetail = this.searchCriteria.faultDetail;
      request.priorityList = this.searchCriteria.priorityList;
      request.statusList = this.searchCriteria.statusList;
      request.createTimeFrom = this.searchCriteria.createTimeFrom;
      request.createTimeTo = this.searchCriteria.createTimeTo;
    }
    return request;
    // TODO: orig code for reference
    // for (let key in this.tablexFilter) {
    //   if (this.tablexFilter[key]) {
    //     switch (key) {
    //       case 'status':
    //         request.statusList = this.tablexFilter[key];
    //         break;
    //       case 'priority':
    //         request.priorityList = this.tablexFilter[key];
    //         break;
    //       default:
    //         request.filter[key] = this.tablexFilter[key];
    //         break;
    //     }
    //   }
    // }
  }

  searchLocation() {
    this.locationOptions.items = [];
    this.locationPageNumber = 0;
    this.locationTotalCount = null;
    this.requestLocationSummary();
  }

  // searchEquipment() {
  //   this.equipmentOptions.items = [];
  //   this.equipmentPageNumber = 0;
  //   this.equipmentTotalCount = null;
  //   this.requestEquipmentSummary();
  // }

  searchTeam() {
    this.teamOptions.items = [];
    this.teamPageNumber = 0;
    this.teamTotalCount = null;
    this.requestTeamSummary();
  }

  onKeyup(event, searchType) {
    if (event.key === 'Enter') {
      this.onClickSearch();
    }
  }

  checkSearchEmpty() {}

  ngOnDestroy() {
    Session.setSnListGeneralSearchKeyword(null);
  }
  
  onClickSearch() {
    this.currentPage = 1;
    this.resetTableAction();
    if (this.generalSearchWords?.trim().length > 0) {
      this.isGeneralSearch = true;
      this.generalSearch();
    } else {
      this.isGeneralSearch = false;
      this.requestVpSnList();
    }
  }

  onClickDownload() {
    this.isDownloading = true;
  }

  onAdvancedSearch(searchCriteria) {
    if (searchCriteria) {
      this.searchCriteria = searchCriteria;
      
      this.isGeneralSearch = false;
      this.currentPage = 1;
      this.requestVpSnList();
    }
  };

  // ----------- Tablex UI function ----------- //
  public resetTableAction() {
    this.selectedTableRows = [];
    this.tablexParam['selectedRowIndex'] = undefined;
    this.tablexParam['highlightedRows'] = [];
  }

  onColFiltered = (selectedColId) => {
    this.selectedColId = [...selectedColId];
    this.renderTable();
    this.saveColumnSettingsToLocal();
  };

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.resetTableAction();

    this.isGeneralSearch ? this.generalSearch(): this.requestVpSnList();
  };

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.resetTableAction();

    this.isGeneralSearch ? this.generalSearch(): this.requestVpSnList();
  };

  onSortOrderChanged = (header, sortOrder) => {
    this.tablexParam['sortBy'] = header ? header : 'createdAt';
    this.tablexParam['sortOrder'] = sortOrder;

    this.isGeneralSearch ? this.generalSearch(): this.requestVpSnList();
  };

  onFilterChanged = (event, index, header, filter) => {
    for (let key in filter) {
      if (!filter[key]) {
        delete this.tablexFilter[key];
      } else {
        this.tablexFilter[key] = filter[key];
      }
    }
    this.currentPage = 1;
    
    this.isGeneralSearch ? this.generalSearch(): this.requestVpSnList();
  };

  onOptionFilterChanged = (event, i, header) => {
    if (header.filterDropdownOption.selectedValue) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }

    this.currentPage = 1;
    this.isGeneralSearch ? this.generalSearch(): this.requestVpSnList();
  };

  onFilterClear = () => {
    this.tablexFilter = {};
    this.tablexParam['filter'] = {};
    this.isGeneralSearch ? this.generalSearch(): this.requestVpSnList();
  };

  onSearchLocationOptions = (event) => {
    this.searchLocationKeywords = event.term;
    this.searchLocationObserver.next();
  };

  onClearLocationSearch = () => {
    this.searchLocationKeywords = undefined;
    this.searchLocationObserver.next();
  };

  onLocationScrollToEnd = () => {
    this.requestLocationSummary();
  };

  onFilterLocationOptions = (term: string, item: any) => {
    return (
      item.value.toLowerCase().includes(term.toLowerCase()) ||
      item.descriptionEn.toLowerCase().includes(term.toLowerCase()) ||
      item.descriptionZh.toLowerCase().includes(term.toLowerCase())
    );
  };

  onSearchTeamOptions = (event) => {
    this.searchTeamKeywords = event.term;
    this.searchTeamObserver.next();
  };

  onClearTeamSearch = () => {
    this.searchTeamKeywords = undefined;
    this.searchTeamObserver.next();
  };

  onTeamScrollToEnd = () => {
    this.requestTeamSummary();
  };

  onFilterTeamOptions = (term: string, item: any) => {
    return (
      item.value.toLowerCase().includes(term.toLowerCase()) ||
      item.label.toLowerCase().includes(term.toLowerCase()) ||
      item.label.toLowerCase().includes(term.toLowerCase())
    );
  };

  renderTable() {
    this.tablexParam['headers'] = this.allColHeaders.filter((col) => {
      return this.selectedColId.includes(col.id);
    });
    this.tablexParam['content'] = this.taskList.map((sn, index) => {
      const snNumber = {
        url: '/cm-task/' + sn.snNumber,
        value: Util.toString(sn.snNumber),
      };

      let location = '';
      if (sn.location) {
        let locationDescription = this.locationDescriptionMap.has(sn.location)
          ? `${this.locationDescriptionMap.get(sn.location)} `
          : '';
        location = `${locationDescription}(${sn.location})`;
      }

      let originRow = [
        sn._id,
        snNumber,
        sn.faultDetail ? sn.faultDetail : '',
        sn.status ? JMLanguage.translate('sn.status.' + sn.status) : '',
        sn.priority ? JMLanguage.translate('sn.priority.' + sn.priority) : '',
        sn.client ? sn.client : '',
        sn.vendor && sn.vendor.name ? sn.vendor.name : '',
        location,
        sn.locationDetail || '',
        sn.vendorTeam && sn.vendorTeam.name ? sn.vendorTeam.name : '',
        sn.contactNumber ? sn.contactNumber : '',
        sn.contactPerson ? sn.contactPerson : '',
        sn.equipmentCategory ? sn.equipmentCategory : '',
        sn.equipmentType ? sn.equipmentType : '',
        moment(sn.createdAt).format(Constants.DATETIME_FORMAT_2),
        sn.sourceSystem ? sn.sourceSystem : '',
      ];

      let row = [];
      originRow.forEach((value, i) => {
        if (this.selectedColId.includes(this.allColHeaders[i].id)) {
          row.push(originRow[i]);
        }
      });
      return row;
    });
    this.resetTableAction();
    this.tablexParam['isLoadingTable'] = false;
    this.tablexParam['totalRowCount'] = this.tablexParam['content'].length;
  }

  initFilterOptions() {
    //Status
    this.statusOptions.items = [];
    let statusEnum = Object.values(JMENUM.SnStatus);
    for (let i = statusEnum.length / 2; i < statusEnum.length; i++) {
      let value = statusEnum[i];
      this.statusOptions.items.push({ label: JMLanguage.translate('sn.status.' + value), value: value });
    }
    this.statusOptions.bindLabel = 'label';
    this.statusOptions.bindValue = 'value';
    this.statusOptions.multiple = true;
    this.statusOptions.change = this.onOptionFilterChanged;

    // Priority
    this.priorityOptions.items = [];
    let priorityEnum = Object.values(JMENUM.JMPriority);
    for (let i = priorityEnum.length / 2; i < priorityEnum.length; i++) {
      let value = priorityEnum[i];
      this.priorityOptions.items.push({ label: JMLanguage.translate('sn.priority.' + value), value: value });
    }
    this.priorityOptions.bindLabel = 'label';
    this.priorityOptions.bindValue = 'value';
    this.priorityOptions.multiple = true;
    this.priorityOptions.change = this.onOptionFilterChanged;

    //Location
    this.locationOptions.items = [];
    this.locationOptions.bindLabel = 'label';
    this.locationOptions.bindValue = 'value';
    this.locationOptions.change = this.onOptionFilterChanged;
    this.locationOptions.onScrollToEnd = this.onLocationScrollToEnd;
    this.locationOptions.onSearch = this.onSearchLocationOptions;
    this.locationOptions.searchFn = this.onFilterLocationOptions;

    //Team
    this.teamOptions.items = [];
    this.teamOptions.bindLabel = 'label';
    this.teamOptions.bindValue = 'value';
    this.teamOptions.change = this.onOptionFilterChanged;
    this.teamOptions.onScrollToEnd = this.onTeamScrollToEnd;
    this.teamOptions.onSearch = this.onSearchTeamOptions;
    this.teamOptions.searchFn = this.onFilterTeamOptions;
    this.teamOptions.onClear = this.onClearTeamSearch;

    //Equipment Category
    let equipmentCategory = AppDelegate.getEquipmentCategory();
    this.equipmentCategoryOptions.items = [];
    this.equipmentCategoryOptions.bindLabel = 'label';
    this.equipmentCategoryOptions.bindValue = 'value';
    this.equipmentCategoryOptions.change = this.onOptionFilterChanged;
    for (let cateCode in equipmentCategory) {
      let cate = equipmentCategory[cateCode];
      this.equipmentCategoryOptions.items.push({
        label: cate.code + ' - ' + cate.description[this.language],
        value: cate.code,
      });
    }

    //Equipment Type
    let equipmentType = AppDelegate.getEquipmentType();
    this.equipmentTypeOptions.items = [];
    this.equipmentTypeOptions.bindLabel = 'label';
    this.equipmentTypeOptions.bindValue = 'value';
    this.equipmentTypeOptions.change = this.onOptionFilterChanged;
    for (let typeCode in equipmentType) {
      let type = equipmentType[typeCode];
      this.equipmentTypeOptions.items.push({
        label: type.code + ' - ' + type.description[this.language],
        value: type.code,
      });
    }

    // InitialSystem
    this.initialSystemOptions.items = [
      { label: 'CCEPJM', value: 'CCEPJM' },
      { label: 'CCEPVP', value: 'CCEP-VP' }, //value should be CCEPVP
    ];
    this.initialSystemOptions.bindLabel = 'label';
    this.initialSystemOptions.bindValue = 'value';
    this.initialSystemOptions.change = this.onOptionFilterChanged;
  }

  initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableClearFilter: false,
      enableColFilter: true,
      enableSelectedRowCount: false,
      enableSort: true,
      minifyButton: true,
      tableRow: 'row',
      tableClass: 'notification-list-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      selectedColId: this.selectedColId,
      fullColNameList: this.allColHeaders.map((col) => {
        return { id: col.id, name: col.name };
      }),
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onColFiltered: this.onColFiltered,
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };

    this.initTableHeader();
  }

  initTableHeader() {
    this.allColHeaders = [
      {
        id: 'objId',
        name: '',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'd-none ',
      },
      {
        id: 'snNumber',
        name: 'pages.notification-list.table-column.sn-number',
        enableSort: true,
        enableFilter: false,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'faultDetail',
        name: 'pages.notification-list.table-column.fault-details',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'status',
        name: 'pages.notification-list.table-column.status',
        enableSort: false,
        enableFilter: false,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.statusOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'priority',
        name: 'pages.notification-list.table-column.priority',
        enableSort: false,
        enableFilter: false,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.priorityOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'client',
        name: 'pages.notification-list.table-column.client',
        enableSort: true,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'contractor',
        name: 'pages.notification-list.table-column.contractor',
        enableSort: true,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'location',
        name: 'pages.notification-list.table-column.location',
        enableSort: false,
        enableFilter: false,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.locationOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'locationDetail',
        name: 'pages.notification-list.table-column.location-detail',
        enableSort: false,
        enableFilter: false,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.locationOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'team',
        name: 'pages.notification-list.table-column.team',
        enableSort: false,
        enableFilter: false,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.teamOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'contactNumber',
        name: 'pages.notification-list.table-column.contact-number',
        enableSort: true,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'contactPerson',
        name: 'pages.notification-list.table-column.contact-person',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'equipmentCategory',
        name: 'pages.notification-list.table-column.equipment-category',
        enableSort: false,
        enableFilter: false,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.equipmentCategoryOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'equipmentType',
        name: 'pages.notification-list.table-column.equipment-type',
        enableSort: false,
        enableFilter: false,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.equipmentTypeOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'createAt',
        name: 'pages.notification-list.table-column.create-time',
        enableSort: true,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'sourceSystem',
        name: 'pages.notification-list.table-column.initial-system',
        enableSort: false,
        enableFilter: false,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.initialSystemOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
    ];
    this.selectedCol = this.allColHeaders;
  }

  private saveColumnSettingsToLocal() {
    try {
      let cache = JSON.parse(JSON.stringify(this.selectedColId));
      Session.setSnListSettingsColumns(cache);
    } catch (e) {
      console.warn(e);
    }
  }

  private loadColumnSettingsFromLocal() {
    try {
      let cache = Session.getSnListSettingsColumns();
      if (cache) {
        this.selectedColId = JSON.parse(JSON.stringify(cache));
      }
    } catch (e) {
      console.warn(e);
    }
  }
}
