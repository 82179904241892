<div class="add-team-form-wrap">
  <ng-container *ngIf="showNoDataText; else teamForm">
    <div class="d-flex justify-content-between">
      <div>{{ "global.no-record-found" | translate }}</div>
    </div>
  </ng-container>
  <ng-template #teamForm>
    <div class="d-flex justify-content-between font-size-xl">
      <div *ngIf="!isEditMode">{{ "pages.settings-team.add-team" | translate }}</div>
      <div *ngIf="isEditMode">{{ "pages.settings-team.edit-team" | translate }}</div>
      
      <button class="btn standard brand-amber" id="{{mode}}-save-team" [disabled]="isSavingTeam" (click)="onClickedSaveButton()">
        <div *ngIf="isSavingTeam" class="d-flex align-items-center loading-spinner spinner-small"></div>
        <ng-container *ngIf="!isSavingTeam">
          <i class="fas fa-check mr-2"></i>{{ "global.save" | translate }}
        </ng-container>
      </button>
    </div>
    
    <div id="add-team-form" class="mt-4">
      <div class="row mx-0 align-items-center mb-3">
        <div class="col-4 field-title"><span class="red">*</span>{{ "pages.settings-team.table-column.team-name" | translate }}</div>
        <input class="col-8 form-control" id="{{mode}}-team-name" type="text" [(ngModel)]="userInput.name" [class.input-error]="errorFields.name" />
      </div>
  
      <div class="row mx-0 align-items-center mb-3">
        <div class="col-4 field-title"><span class="red">*</span>{{ "pages.settings-team.table-column.contract" | translate }}</div>
        <ng-select class="col-8 px-0" id="{{mode}}-team-contract" [multiple]="true" [class.input-error]="errorFields.contractList"
          [items]="contractListOptions" [(ngModel)]="userInput.contractList" [loading]="isLoadingContract"
          >
        </ng-select>
      </div>
  
      <div class="row mx-0 align-items-center mb-3">
        <div class="col-4 field-title"><span class="red">*</span>{{ "pages.settings-team.table-column.email" | translate }}</div>
        <input class="col-8 form-control" id="{{mode}}-team-email" type="text" [(ngModel)]="userInput.email" (change)="onChangeEmail()" [class.input-error]="errorFields.email" />
      </div>
  
      <div class="row mx-0 align-items-center mb-3">
        <div class="col-4 field-title"><span class="red">*</span>{{ "pages.settings-team.table-column.hotline" | translate }}</div>
        <input class="col-8 form-control" id="{{mode}}-team-hotline" type="text" [(ngModel)]="userInput.phone" (keyup)="inputNumber($event)" [class.input-error]="errorFields.phone" />
      </div>
  
      <div class="row mx-0 align-items-center mb-3">
        <div class="col-4 field-title">{{ "pages.settings-team.table-column.fax" | translate }}</div>
        <input class="col-8 form-control" id="{{mode}}-team-fax" type="text" [(ngModel)]="userInput.fax" (keyup)="inputNumber($event)"  />
      </div>
  
      <div class="col-12 mt-5 font-size-m"><span class="red">*</span>{{ "global.mandatory-fields" | translate }}</div>
    </div>
  
  </ng-template>
  
</div>