import { Injectable } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';

@Injectable({
  providedIn: 'root',
})
export class PmPlanService {
  async requestPmPlanList(option : { pageNumber: number, pageSize: number, contractNumber?: string[], parameters?: string[], filter? }) {
    const request = new JM.JMRequestVpGetPmPlanList();
    option?.pageSize && (request.pageSize = option?.pageSize);
    option?.pageNumber && (request.pageNumber = option?.pageNumber);
    option?.contractNumber && (request.contractNumber = option?.contractNumber);
    if (option?.filter) {
      request.filter = {
        ...option.filter
      };
    }
    if (option?.parameters) {
      request.parameters = option?.parameters
    }

    const response: JM.JMResponseVpGetPmPlanList = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }
    return response.payload;
  }

  async requestPmPeriodList(option : { pageNumber: number, pageSize: number, pmPlanNumber: string[], parameters?: string[], filter? }) {
    const request = new JM.JMRequestVpGetPmPeriodList();
    request.pmPlanNumber = option?.pmPlanNumber;
    option?.pageSize && (request.pageSize = option?.pageSize);
    option?.pageNumber && (request.pageNumber = option?.pageNumber);
    if (option?.filter) {
      request.filter = {
        ...option.filter
      };
    }
    if (option?.parameters) {
      request.parameters = option?.parameters
    }

    const response: JM.JMResponseVpGetPmPeriodList = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }
    return response.payload;
  }
}
