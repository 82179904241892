<div id="pmPeriodEquipmentListComponent" class="d-flex">
  <app-nav [breadcrumbs]="breadcrumbs"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" [class.action-btn-padding]="isActionBarEnabled">
    <div class="d-flex justify-content-between">
      <span class="font-size-xl">
        {{ 'component.pm-period-equipment-list.title' | translate:[pmPeriod?.periodStartDate, pmPeriod?.periodEndDate] }}
      </span>
      <span class="font-size-xl">
        {{ "pages.notification-list.selected-text" | translate:[numberOfRowsSelected ,(equipmentTablexParam?.content && equipmentTablexParam?.content.length)]}}
      </span>
    </div>
    <hr class="underline" />

    <div id="multi-tab-content-container" class="">
      <ul class="nav nav-tabs">
        <li *ngFor="let tab of tabOptions | keyvalue" class="nav-item">
          <a class="nav-link" [class.active]="activeTab == tab.value" (click)="onClickSetActiveTab(tab.value)">{{
            tab.value
          }}</a>
        </li>
      </ul>

      <ng-container *ngIf="activeTab">
        <ng-container *ngIf="enableAssignButton">
          <div class="d-flex justify-content-end">
            <button
              class="btn standard brand-blue ml-2"
              [disabled]="numberOfRowsSelected <= 0 || isSubmitting"
              (click)="onSubmitClick()"
            >
              {{ submitBtnName }}
            </button>
            <button
              class="btn standard brand-blue ml-2"
              [disabled]="numberOfRowsSelected >= equipmentTablexParam?.content?.length"
              (click)="onSelectAll()"
            >
              {{ 'component.pm-plan-equipment-list.select-all' | translate }}
            </button>
            <button class="btn standard brand-blue ml-2" [disabled]="numberOfRowsSelected <= 0" (click)="onUnselectAll()">
              {{ 'component.pm-plan-equipment-list.unselect-all' | translate }}
            </button>
          </div>
        </ng-container>
        <app-tablex id="equipment-list-table" [tablexParam]="equipmentTablexParam"></app-tablex>
      </ng-container>
    </div>

    <div class="d-flex justify-content-between">
      <span class="font-size-xl">
        {{ 'component.pm-period-equipment-action-list.title' | translate }}
      </span>
    </div>
    <hr class="underline" />
    <app-tablex id="equipment-action-list-table" [tablexParam]="equipmentActionTablexParam"></app-tablex>

    <!-- Action Sidebar -->
    <ng-container *ngIf="isActionBarEnabled">
      <app-action-sidebar-v2
        [actionButtonList]="actionSidebar.dataList"
        [isDisabled]="actionSidebar.isDisabled"
        [isLoading]="actionSidebar.isLoading"
        (clickedButton)="onClickedActionButton($event)"
      >
      </app-action-sidebar-v2>
    </ng-container>
  </div>
</div>
