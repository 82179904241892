<div class="wrapper">
  <app-nav [navbarTitle]="'pages.settings-contract.title' | translate"></app-nav>
  <app-sidebar [contentType]="'settings'"></app-sidebar>
  
  <div id="content" class="content">
    <app-tab-menu [contentType]="'settings-contract'"></app-tab-menu>
  
    <div class="contract-page-wrap individual-frame-wrap row">
      <div class="col-12 col-xl-6 left-frame">
        <app-contract-list-by-contract #contract_list_table (assignedContract)="onAssignedContract($event)"></app-contract-list-by-contract>
      </div>
        
      <div class="col-12 col-xl-6 right-frame">
        <contract-equipment-list [contractNumber]="currentContract?.contractNumber"></contract-equipment-list>
        <app-contract-general #contract_setting_general *ngIf="false" [currentContractNumber]="currentContract?.contractNumber" (onUpdateContractGeneral)="onUpdatedContract()"></app-contract-general>
        <app-office-hour-table *ngIf="false"></app-office-hour-table>
        <app-contractor-team-list #contract_setting_team_list *ngIf="false" [selectContract]="currentContract"></app-contractor-team-list>
      </div>
    </div>
      
  </div>
</div>