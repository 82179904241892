<form [formGroup]="referForm">
  <div class="container-fluid mb-3">
    <div class="d-flex align-items-center justify-content-between my-3">
      <h3>
        {{ 'component.pm-plan-refer-editor.title' | translate }}
      </h3>
      <app-loading-button  
        [id]="'refer_pm_plan_button'"
        [text]="'global.submit'"
        [buttonClass]="'w-2 standard brand-amber'"
        [iconClass]="'fa-check'"
        [isDisabled]="referForm.invalid || submitBtn.isLoading"
        [isLoading]="submitBtn.isLoading"
        (clickedButton)="onSubmitBtnClicked()"
      ></app-loading-button>
    </div>
    
    <div class="row align-items-center my-3">
      <div class="col-12 mt-3">
        {{ 'component.pm-plan-refer-editor.assign-team' | translate }}
      </div>
      <div class="col-12 mt-3">
        {{ pmPlan?.vendorTeam?.name }}
      </div>

      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'component.pm-plan-refer-editor.refer-to' | translate }}
      </div>
      <div class="col-12 mt-3">
          <ng-select
            id="refer_team_input"
            formControlName="teamId"
            bindLabel="name"
            bindValue="_id"

            [loading]="vendorTeamInput.isLoading"
            [items]="vendorTeamInput.optionList"

            (scrollToEnd)="onVendorTeamScrollToEnd()"
            (search)="onSearchVendorTeam($event)" 
            (change)="onChangedVendorTeam()"
            (clear)="onClearVendorTeam()"

          ></ng-select>
      </div>
      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'component.pm-plan-refer-editor.refer-remarks' | translate }}
      </div>
      <div class="col-12">
        <textarea
          id="refer_remarks_input"
          class="form-control"
          rows="5"
          formControlName="remarks"
          [maxlength]="inputBoxMaxLength">
        </textarea>
        <span 
          *ngIf="remarks?.value?.length >= inputBoxMaxLength" 
          class="red small"
        >
          {{ "slider.textarea.max-length" | translate:[inputBoxMaxLength] }}
        </span>
      </div>
    </div>

    <div class="row align-items-center my-3">
      <div class="col-12 mt-3">
        <span class="red">*</span> {{ 'global.mandatory-fields' | translate }}
      </div>
    </div>
  </div>
</form>
