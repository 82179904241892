import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Constants } from 'src/constants';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { ngbDateToString, stringToNgbDate } from 'src/app/core/Formatter';
import { Session } from 'src/app/core/session';
import { DropdownControl, DropdownSearch, InputControl } from '../../../shared/form.model';
import { ContractService } from 'src/app/shared/contract.service';
import { PmPlanService } from 'src/app/pm/shared/pm-plan.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pm-equipment-list-search-criteria',
  templateUrl: './pm-equipment-list-search-criteria.component.html',
  styleUrls: ['./pm-equipment-list-search-criteria.component.scss'],
})
export class PmEquipmentListSearchCriteriaComponent implements OnInit, OnDestroy {
  @Output() onSearch: EventEmitter<any> = new EventEmitter();

  criteriaForm: FormGroup;

  currentErrorList = [];

  isSearching: boolean = false;

  isCollapse: boolean = true;
  // unSubObservable
  private unSubNotifier = new Subject();

  // Input
  contractInput = new PmPlanEquipSearchContractInput();
  pmPlanInput = new PmPlanEquipSearchPmPlanInput();
  private contractSearch = new DropdownSearch();
  private pmPlanSearch = new DropdownSearch();

  // Location Dropdown
  private searchLocationObserver = new Subject<any[]>();
  locationOptions: any = [];
  searchLocationKeyword: string = null;
  locationPageSize: number = 100;
  locationPageNumber: number = 1;
  locationTotalPage: number;
  isLoadingLocation: boolean = false;


  // Client Dropdown
  private searchClientObserver = new Subject<any[]>();
  clientOptions: any = [];
  searchClientKeyword: string = null;
  clientPageSize: number = 100;
  clientPageNumber: number = 1;
  clientTotalPage: number;
  isLoadingClient: boolean = false;

  // Equipment Type Dropdown
  equipmentTypeOptionsFull: any = [];



  constructor(
    private contractService: ContractService,
    private pmPlanService: PmPlanService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.initOptions();
    this.loadFilterOptionsFromLocal();
    this.onSearch.emit(this.criteriaForm.value);
  }

  ngOnDestroy(): void {
    this.unSubNotifier.next();
    this.unSubNotifier.complete();
  }

  initForm() {
    this.criteriaForm = this.fb.group({
      contractNumberList: null,
      pmPlanNumberList: null,
      isOutstanding: false,
      isCompleted: false,
      periodStartDateFrom: null,
      periodStartDateTo: null,
      periodEndDateFrom: null,
      periodEndDateTo: null,
      equipmentNumber: null,
      equipmentDesc: null,
      locationCodeList: null,
      clientList: null,
      equipmentType: null,
    });
  }

  initOptions() {
    // contract list init
    this.contractSearch.search$.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      takeUntil(this.unSubNotifier),
    ).subscribe(() => {
      this.resetContractDropDown();
      this.searchContracts();
    });
    this.contractSearch.search$.next();

    this.pmPlanSearch.search$.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      takeUntil(this.unSubNotifier),
    ).subscribe(() => {
      this.resetPmPlanDropDown();
      this.searchPmPlans();
    });
    this.pmPlanSearch.search$.next();

    this.searchClientObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchClients.bind(this));
    this.searchLocationObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchLocations.bind(this));
    this.equipmentTypeOptionsFull = Object.values(Session.getEquipmentType()).map(type => {
      const t: any = type;
      return {
        value: t.code,
        label: `${t.code} - ${t.description[JMLanguage.getCurrentLanguage()]}`,
      };
    });
  }
  // Start: Contract ng select handler
  onContractSearch(event) {
    this.contractSearch.keywords = event.term;
    this.contractSearch.search$.next();
  }

  onContractClear() {

    this.contractSearch.keywords = null;
    this.contractSearch.search$.next();
  }

  // date picker handler
  onBlurDateInput(event) {
    this.criteriaForm.get(event.fieldName).setValue(event.date);
    this.checkDateFormat();
  }

  checkDateFormat() {
    const startFrom = moment(this.getMomentFromNgbDate(this.criteriaForm.get('periodStartDateFrom').value));
    const startTo = moment(this.getMomentFromNgbDate(this.criteriaForm.get('periodStartDateTo').value));
    const endFrom = moment(this.getMomentFromNgbDate(this.criteriaForm.get('periodEndDateFrom').value));
    const endTo = moment(this.getMomentFromNgbDate(this.criteriaForm.get('periodEndDateTo').value));
    const wrongStartDateIndex = this.currentErrorList.indexOf('wrongStartDate');
    // check start date from to
    if (startFrom.isAfter(startTo)) {
      wrongStartDateIndex === -1 && this.currentErrorList.push('wrongStartDate');
    } else if (wrongStartDateIndex > -1) {
      this.currentErrorList.splice(wrongStartDateIndex, 1);
    }
    const wrongEndDateIndex = this.currentErrorList.indexOf('wrongEndDate');
    // check end date from to
    if (endFrom.isAfter(endTo)) {
      wrongEndDateIndex === -1 && this.currentErrorList.push('wrongEndDate');
    } else if (wrongEndDateIndex > -1) {
      this.currentErrorList.splice(wrongEndDateIndex, 1);
    }
    const wrongDateIndex = this.currentErrorList.indexOf('wrongDate');
    if (startFrom.isAfter(endFrom) || startTo.isAfter(endFrom) || startFrom.isAfter(endTo)
      || startTo.isAfter(endTo)) {
      wrongDateIndex === -1 && this.currentErrorList.push('wrongDate');
    } else if (wrongDateIndex > -1) {
      this.currentErrorList.splice(wrongDateIndex, 1);
    }
  }

  getMomentFromNgbDate(date: NgbDateStruct): moment.Moment {
    return date ? moment(date.year + '-' + date.month + '-' + date.day) : null;
  }

  async onContractScrollToEnd() {
    if (this.contractSearch.totalPageNumber && this.contractSearch.pageNumber < this.contractSearch.totalPageNumber) {
      let filter;
      this.contractSearch.pageNumber++;
      this.contractSearch.keywords && (filter = { contractNumber: this.contractSearch.keywords });

      const payload = await this.contractService.requestContractList({
        pageNumber: this.contractSearch.pageNumber,
        pageSize: this.contractSearch.pageSize,
        parameters: ['contractNumber'],
        filter,
      });

      if (payload) {
        this.contractSearch.totalPageNumber = Math.ceil(payload.totalCount / this.contractSearch.pageSize);
        this.contractInput.optionList = this.contractInput.optionList.concat(payload.records);
      }
    }
  }

  resetContractDropDown() {
    this.contractInput.optionList = [];
    this.contractSearch.totalPageNumber = null;
    this.contractSearch.pageNumber = 1;
  }

  async searchContracts() {
    let filter;
    this.contractSearch.keywords && (filter = { contractNumber: this.contractSearch.keywords });

    const payload = await this.contractService.requestContractList({
      pageNumber: this.contractSearch.pageNumber,
      pageSize: this.contractSearch.pageSize,
      parameters: ['contractNumber'],
      filter,
    });

    if (payload) {
      this.contractSearch.totalPageNumber = Math.ceil(payload.totalCount / this.contractSearch.pageSize);
      this.contractInput.optionList = payload.records;
    }
  }

  // Start: plan ng select handler
  onPmPlanSearch(event) {
    this.pmPlanSearch.keywords = event.term;
    this.pmPlanSearch.search$.next();
  }

  onPmPlanClear() {
    this.pmPlanSearch.keywords = null;
    this.pmPlanSearch.search$.next();
  }

  async onPmPlanScrollToEnd() {
    if (this.pmPlanSearch.totalPageNumber && this.pmPlanSearch.pageNumber < this.pmPlanSearch.totalPageNumber) {
      let filter;
      this.pmPlanSearch.pageNumber++;
      this.pmPlanSearch.keywords && (filter = { pmPlanNumber: this.pmPlanSearch.keywords });

      const payload = await this.pmPlanService.requestPmPlanList({
        pageNumber: this.pmPlanSearch.pageNumber,
        pageSize: this.pmPlanSearch.pageSize,
        parameters: ['pmPlanNumber'],
        filter,
      });

      if (payload) {
        this.pmPlanSearch.totalPageNumber = Math.ceil(payload.totalCount / this.pmPlanSearch.pageSize);
        this.pmPlanInput.optionList = this.pmPlanInput.optionList.concat(payload.records);
      }
    }
  }

  resetPmPlanDropDown() {
    this.pmPlanInput.optionList = [];
    this.pmPlanSearch.totalPageNumber = null;
    this.pmPlanSearch.pageNumber = 1;
  }

  async searchPmPlans() {
    let filter;
    this.pmPlanSearch.keywords && (filter = { pmPlanNumber: this.pmPlanSearch.keywords });

    const payload = await this.pmPlanService.requestPmPlanList({
      pageNumber: this.pmPlanSearch.pageNumber,
      pageSize: this.pmPlanSearch.pageSize,
      parameters: ['pmPlanNumber'],
      filter,
    });

    if (payload) {
      this.pmPlanSearch.totalPageNumber = Math.ceil(payload.totalCount / this.pmPlanSearch.pageSize);
      this.pmPlanInput.optionList = payload.records;
    }
  }

  // Start: search icon handler
  onClickSearch() {
    this.onSearch.emit(this.criteriaForm.value);
    this.saveFilterOptionsToLocal();
  }
  // End: search icon handler

  private saveFilterOptionsToLocal = (): void => {
    try {
      let cache = JSON.stringify(this.criteriaForm.value);
      Session.setPmEquipmentSearchFilters(cache);
    } catch (e) {
      console.warn(e);
    }
  };

  private loadFilterOptionsFromLocal = (): void => {
    try {
      let cache = Session.getPmEquipmentSearchFilters();
      if (cache) {
        let cacheParsed = JSON.parse(cache);
        this.criteriaForm.patchValue(cacheParsed);
        if (cacheParsed.locationCodeList) {
          this.searchLocationKeyword = cacheParsed.locationCodeList;
          this.searchLocations().then(() => {
            this.criteriaForm.get('locationCodeList').patchValue(this.searchLocationKeyword);
          })
        }
        if (cacheParsed.clientList) {
          this.searchClientKeyword = cacheParsed.clientList;
          this.searchClients().then(() => {
            this.criteriaForm.get('clientList').patchValue(this.searchClientKeyword);
          });
        }
      }
    } catch (e) {
      console.warn(e);
    }
  };

  // location ng select handler
  onSearchLocation(event) {
    this.searchLocationKeyword = event.term;
    this.searchLocationObserver.next();
  }

  clearLocationFilter() {
    this.searchLocationKeyword = null;
    this.searchLocations();
  }

  async searchLocations() {
    this.locationOptions = [];
    this.locationPageNumber = 1;
    this.locationTotalPage = null;
    this.locationOptions = await this.requestLocationList(this.locationPageNumber);
  }

  async onLocationScrollToEnd() {
    if (this.locationPageNumber < this.locationTotalPage) {
      this.locationPageNumber++;

      let locations = await this.requestLocationList(this.locationPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.locationOptions = [...this.locationOptions, ...locations];
    }
  }

  private async requestLocationList(pageNumber?: number) {
    const request = new JM.JMRequestLocationsLocationSummary();

    request.locationOnly = true;
    request.parameters = ['code', 'description'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.locationPageSize;

    if (this.searchLocationKeyword) {
      request.locationDescription = this.searchLocationKeyword;
    }

    this.isLoadingLocation = true;
    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingLocation = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.locationTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    return response.payload.records.map((location) => {
      const lang = Session.selectedLanguage;
      const description = location.description[lang] ? location.description[lang] : location.description.en;
      return {
        value: location.code,
        label: `${description} (${location.code})`,
      };
    });
  }

  // client ng select handler
  onSearchClient(event) {
    this.searchClientKeyword = event.term;
    this.searchClientObserver.next();
  }

  clearClientFilter() {
    this.searchClientKeyword = null;
    this.searchClients();
  }

  async searchClients() {
    this.clientOptions = [];
    this.clientPageNumber = 1;
    this.clientTotalPage = null;
    this.clientOptions = await this.requestClientList(this.clientPageNumber);
  }

  async onClientScrollToEnd() {
    if (this.clientPageNumber < this.clientTotalPage) {
      this.clientPageNumber++;

      let clients = await this.requestClientList(this.clientPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.clientOptions = [...this.clientOptions, ...clients];
    }
  }

  private async requestClientList(pageNumber?: number) {
    const request = new JM.JMRequestClientsClientSummary();

    request.parameters = ['clientShortName', 'name'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.clientPageSize;

    if (this.searchClientKeyword) {
      request.filter = { clientShortName: this.searchClientKeyword };
    }

    this.isLoadingClient = true;
    const response: JM.JMResponseClientsClientSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingClient = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.clientTotalPage = Math.ceil(totalCount / request.pageSize);
    }

    return response.payload.records.map((client) => {
      const lang = Session.selectedLanguage;
      const description = client.name[lang] ? client.name[lang] : client.name.en;
      return {
        value: client.clientShortName,
        label: `${client.clientShortName} - ${description}`
      };
    });
  }
}

class PmPlanEquipSearchContractInput extends DropdownControl {
  value?: string; // JMOBJ.MaintenanceTermContract;
  optionList: JMOBJ.MaintenanceTermContract[] = [];

  constructor() {
    super();
  }
}

class PmPlanEquipSearchPmPlanInput extends DropdownControl {
  value?: string; // JMOBJ.PmPlan;
  optionList: JMOBJ.PmPlan[] = [];

  constructor() {
    super();
  }
}