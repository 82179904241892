import { Component, OnInit, ViewChild } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { UserHelper } from 'src/app/core/user-helper';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { ActionButtonI } from 'src/app/shared/action-sidebar-v2/action-sidebar-v2.model';
import { ActionSidebarV2Service } from '../../shared/action-sidebar-v2.service';
import { PmJobService } from 'src/app/pm/shared/pm-job.service'
import { PmJobCreateActionButtonList } from './pm-job-create.model';

import { PmJobDescriptionComponent } from '../shared/pm-job-description/pm-job-description.component';
import { PmJobClientInfoComponent } from '../shared/pm-job-client-info/pm-job-client-info.component';
import { PmJobProgressComponent } from '../shared/pm-job-progress/pm-job-progress.component';
import { PmJobEquipmentInfoComponent } from '../shared/pm-job-equipment-info/pm-job-equipment-info.component';

@Component({
  selector: 'app-pm-job-create',
  templateUrl: './pm-job-create.component.html',
  styleUrls: ['./pm-job-create.component.scss']
})
export class PmJobCreateComponent implements OnInit {
  @ViewChild('pmJobDescriptionElem', { static: false }) pmJobDescriptionElem: PmJobDescriptionComponent;
  @ViewChild('pmJobClientInfoElem', { static: false }) pmJobClientInfoElem: PmJobClientInfoComponent;
  @ViewChild('pmJobProgressElem', { static: false }) pmJobProgressElem: PmJobProgressComponent;
  @ViewChild('pmJobEquipmentInfoElem', { static: false }) pmJobEquipmentInfoElem: PmJobEquipmentInfoComponent;

  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.CREATE;
  pmJob: JMOBJ.PmJob = new JMOBJ.PmJob();

  // action buttons
  actionSidebar: {
    dataList: ActionButtonI[];
    isDisabled: boolean;
    isLoading: boolean;
  } = {
    dataList: [],
    isDisabled: false,
    isLoading: false,
  };

  constructor(private actionSidebarV2Service: ActionSidebarV2Service, private pmJobService: PmJobService) { }

  ngOnInit(): void {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }

    this.resetActionBar();
  }

  // API
  async requestSavePmJob() {
    const response = await this.pmJobService.savePmJob(this.pmJob);
    return response;
  }

  // ----------- action button function -----------
  onClickedActionButton(actionButton: ActionButtonI) {
    actionButton.handler();
  }

  private resetActionBar(): void {
    this.actionSidebar.dataList = [];

    const actionList = [
      JMENUM.VpPmJobAction.DRAFT,
      'clear',
      'close'
    ];

    this.filterActionButton(actionList);
  }

  private filterActionButton(actionList: string[]) {
    for (const action of actionList) {
      const button: ActionButtonI = PmJobCreateActionButtonList[action];
      const hasPermissionList = button && button.permissionList && button.permissionList.length;

      if (hasPermissionList) {
        const allowAction = UserHelper.hasEitherOnePermission(button.permissionList);
        if (allowAction) {
          this.addActionButton(button);
        }
      } else {
        this.addActionButton(button);
      }
    }
  }

  private addActionButton(actionButton: ActionButtonI) {
    let handler = () => {};

    switch (actionButton.action) {
      case JMENUM.VpPmJobAction.DRAFT:
        handler = async () => {
          if(this.validationFieldsOnAllComponents()) {
            const response = await this.requestSavePmJob();
            if (response.payload?.pmJob?.pmJobNumber) {
              AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.saved'));
              AppDelegate.routeToPage(`/pm/jobs/${response.payload.pmJob.pmJobNumber}`);
            }
          };
        };
        break;
      case 'clear':
        handler = () => {
          const buttonHandler = () => {
            this.pmJobDescriptionElem.clearAll();
            this.pmJobClientInfoElem.clearAll();
            this.pmJobProgressElem.clearAll();
          };
          this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, buttonHandler);
        };
        break;
      case 'close':
        handler = () => {
          const buttonHandler = () => {
            AppDelegate.routeToPage('/dashboard');
          }
          this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, buttonHandler);
        };
        break;
      case JMENUM.VpPmJobAction.EQUIPMENT:
        handler = async () => {

        };
        break;
      default:
        break;
    }

    actionButton.handler = handler;
    this.actionSidebar.dataList.push(actionButton);
  }

  validationFieldsOnAllComponents() {
    const validDescription = this.pmJobDescriptionElem.validate();
    const validClient = this.pmJobClientInfoElem.validate();
    const validProgress = this.pmJobProgressElem.validate();

    return validDescription && validClient && validProgress;
  }
}
