import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';

@Component({
  selector: 'app-form-add-team-member',
  templateUrl: './form-add-team-member.component.html',
  styleUrls: ['./form-add-team-member.component.scss'],
})
export class FormAddTeamMemberComponent implements OnInit {
  @Input() isLoading: boolean = false;

  @Output() addedMembers = new EventEmitter<JMOBJ.Post[]>();

  vendorTeam?: JMOBJ.VendorTeam;
  selectAllMember: boolean = false;
  memberListPageCount: number = 1;

  // member list
  sourceMemberList: JMOBJ.Post[] = [];
  displayMemberList: {
    postName: string;
    staffName: string;
    selected: boolean;
  }[] = [];

  constructor() {}

  ngOnInit(): void {}

  // ----------- paret component init function ----------- //
  initMemberListForm(vendorTeam: JMOBJ.VendorTeam) {
    this.vendorTeam = vendorTeam;

    this.getAllAvailableMember();
  }

  private async getAllAvailableMember() {
    this.displayMemberList = [];
    this.sourceMemberList = [];
    this.memberListPageCount = 1;

    const currentPageSize: number = 100;
    let currentPage: number = 1;

    do {
      await this.requestMemberList(currentPage, currentPageSize);
      ++currentPage;
    } while (currentPage <= this.memberListPageCount);

    this.covertMemberListForDisplay();
  }

  private covertMemberListForDisplay() {
    this.displayMemberList = this.sourceMemberList.map((post) => {
      let obj = {
        postName: post.name,
        staffName: post.employeeName,
        selected: false,
      };
      return obj;
    });
  }

  // ----------- API ----------- //
  private async requestMemberList(pageNumber: number, pageSize: number) {
    let request = new JM.JMRequestPostsGetVendorTeamMemberOptions();
    request.vendorTeamId = this.vendorTeam._id;
    request.parameters = ['name', 'employeeName'];
    request.pageSize = pageSize;
    request.pageNumber = pageNumber;

    const response: JM.JMResponsePostsGetVendorTeamMemberOptions = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.sourceMemberList = this.sourceMemberList.concat(response.payload.records);
    this.memberListPageCount = Math.ceil(response.payload.totalCount / pageSize);
  }

  private async requestAddTeamMember() {
    const selectedMemberList = this.displayMemberList
      .filter((member) => member.selected)
      .map((member) => member.postName);

    let request: JM.JMRequestPostsAddVendorTeamMember = new JM.JMRequestPostsAddVendorTeamMember();
    request.includeVendorTeamMemberList = false;
    request.postNameList = selectedMemberList;
    request.vendorTeamId = this.vendorTeam._id;

    const response: JM.JMResponsePostsAddVendorTeamMember = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.settings-team.member-added'));
    this.addedMembers.emit();
  }

  // ----------- UI function ----------- //
  onClickedSelectAllCheckBox() {
    this.displayMemberList.forEach((member) => {
      member.selected = this.selectAllMember;
    });
  }

  onClickedSaveButton() {
    const button = {
      msg: {
        content: 'popup.confirm-to-save',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestAddTeamMember();
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(button);
  }

  onChangedMember() {
    this.updateSelectAllCheckBox();
  }

  private updateSelectAllCheckBox() {
    if (
      this.displayMemberList.length !== 0 &&
      this.displayMemberList.length === this.displayMemberList.filter((member) => member.selected).length
    ) {
      this.selectAllMember = true;
    } else {
      this.selectAllMember = false;
    }
  }
}
