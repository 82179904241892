import { Injectable } from '@angular/core';
import { JMOBJ } from '@ccep/CCEPConnector-ts';
import { Session } from './session';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  constructor() {}

  //============= Equipment Category =============
  getAllEquipmentCategoryCode(): string[] {
    const equipmentCategory = Session.getEquipmentCategory();
    return Object.keys(equipmentCategory);
  }

  getEquipmentCategory(catId: string): JMOBJ.EquipmentCategory {
    const equipmentCategory = Session.getEquipmentCategory();
    return equipmentCategory[catId];
  }

  getEquipmentCategoryCodeFromType(typeId: string): string[] {
    const equipmentCategory = Session.getEquipmentCategory();
    const equipmentType = Session.getEquipmentType();

    if (equipmentCategory && equipmentType) {
      let catArray = [];
      for (const catId in equipmentCategory) {
        const cat = equipmentCategory[catId];
        if (cat.equipmentType.includes(typeId) && !catArray.includes(catId)) {
          catArray.push(catId);
        }
      }
      return catArray;
    }

    return [];
  }

  //============= Equipment Type =============
  getAllEquipmentTypeCode(): string[] {
    const equipmentType = Session.getEquipmentType();
    return Object.keys(equipmentType);
  }

  getEquipmentType(typeId: string): JMOBJ.EquipmentType {
    const equipmentType = Session.getEquipmentType();
    return equipmentType[typeId];
  }

  getEquipmentTypeCodeFromCategory(catId: string): string[] {
    const cat = this.getEquipmentCategory(catId);
    return cat.equipmentType;
  }
}
