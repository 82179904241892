import { Component, Input, OnInit } from '@angular/core';
import { JM, JMOBJ, JMENUM } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';

import {
  translateComplianceStatus,
  CmTaskComplianceStatus
} from 'src/app/cm-tasks/shared/cm-task-progress/cm-task-progress.component';

@Component({
  selector: 'app-notification-progress',
  templateUrl: './notification-progress.component.html',
  styleUrls: ['./notification-progress.component.scss'],
})
export class NotificationProgressComponent implements OnInit {
  @Input() sn: JMOBJ.ServiceNotification;

  tablexParam: {} = {};
  jobCardNumber: string;
  jobCard: JMOBJ.JobCard = new JMOBJ.JobCard();
  jobCardSummary: JM.JMOBJ.JobCard[] = [];

  constructor() {}

  ngOnInit(): void {
    this.initTablex();
    this.initAllTableHeader();
  }

  ngOnChanges() {
    if (this.sn.jobCards && this.sn.jobCards.length > 0) {
      this.requestCMTaskSummary();
    } else {
      this.renderTable();
    }
  }

  // ----------- API ----------- //
  private async requestCMTaskSummary() {
    const request: JM.JMRequestJobCardsGetCmTaskList = new JM.JMRequestJobCardsGetCmTaskList();
    request.filter = {
      snNumber: this.sn.snNumber
    }
    request.parameters = ['snNumber', 'jobCardNumber', 'status', 'complianceStatus'];
    request.sortBy = 'jobCardNumber';
    request.sortOrder = JMENUM.SortOrder.ASC;

    this.tablexParam['isLoadingTable'] = true;
    let response: JM.JMResponseJobCardsCMTaskSummary = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (response && response.code == 200) {
      this.jobCardSummary = response.payload.records;
    } else {
      AppDelegate.toastMsg().showResponseMsg(response);
    }
    this.renderTable();
  }

  // ----------- UI function ----------- //
  public initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: false,
      enablePagination: false,
      enableColFilter: false,
      enableSelectedRowCount: true,
      tableRow: 'row',
      tableClass: 'jobcard-table',
      tableWrapperClass: 'table-min-width',
      selectedRowCount: 0,
      totalRowCount: 0,
      enableStickyHeader: false,
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };
  }

  public initAllTableHeader() {
    this.tablexParam['headers'] = [
      {
        id: 'jobCardNumber',
        name: 'component.notification-progress.task-no',
        enableFilter: false,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col',
      },
      {
        id: 'response',
        name: 'component.notification-progress.response',
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col',
      },
      {
        id: 'rectification',
        name: 'component.notification-progress.rectification',
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col',
      },
      {
        id: 'status',
        name: 'component.notification-progress.status',
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col',
      },
    ];
  }

  public renderTable() {
    this.tablexParam['content'] = this.jobCardSummary.map((jobCard) => {
      const jobCardLink = {
        url: '/cm-task/' + jobCard.snNumber + '/' + jobCard.jobCardNumber,
        value: jobCard.jobCardNumber,
      };
      const response = JMLanguage.translate(`component.cm-task-progress.compliance.${translateComplianceStatus(jobCard.complianceStatus?.isResponseTimePassed)}`);
      const rectification = JMLanguage.translate(`component.cm-task-progress.compliance.${translateComplianceStatus(jobCard.complianceStatus?.isRectificationTimePassed)}`);
      const status = jobCard.status ? JMLanguage.translate(`jobcard.status.${jobCard.status}`) : '';

      return [jobCardLink, response, rectification, status];
    });
  }
}
