import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';
import { DropdownControl, DropdownSearch } from 'src/app/shared/form.model';

@Component({
  selector: 'pm-plan-refer-editor',
  templateUrl: './pm-plan-refer-editor.component.html',
  styleUrls: ['./pm-plan-refer-editor.component.scss']
})
export class PmPlanReferEditorComponent implements OnInit {
  @Input() pmPlan: JMOBJ.PmPlan;
  @Output() updatedPmPlan = new EventEmitter<JMOBJ.PmPlan>();

  private vendorTeamSearch = new DropdownSearch();
  vendorTeamInput = new PmPlanVendorTeamInput();

  // data
  referForm = this.fb.group({
    teamId: [undefined, [Validators.required]],
    remarks: ['', [Validators.required]],
  });

  submitBtn = {
    isLoading: false,
  };

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.setVendorTeamSearchObserver();
    this.resetForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pmPlan && !changes.pmPlan.isFirstChange()) {
      this.requestVendorTeamList(false);
    }
  }

  ngOnDestory(): void {

  }

  private resetForm() {
    this.referForm.reset({
      team: undefined,
      remarks: '',
    });
  }

  private setVendorTeamSearchObserver() {
    this.vendorTeamSearch.search$.pipe(
      debounceTime(Constants.DEBOUNCE_TIME)
    ).subscribe(() => {
      this.clearVendorTeamOption();
      this.requestVendorTeamList(false);
    });
  }

  onVendorTeamScrollToEnd() {
    if (this.vendorTeamSearch.pageNumber < this.vendorTeamSearch.totalPageNumber) {
      this.vendorTeamSearch.pageNumber++;
      this.requestVendorTeamList(true);
    }
  }

  onSearchVendorTeam(event) {
    this.vendorTeamSearch.keywords = event.term;
    this.vendorTeamSearch.search$.next();
  }

  onChangedVendorTeam() {}

  onClearVendorTeam() {
    this.resetVendorTeamOption();
  }
  
  clearVendorTeamOption() {
    this.vendorTeamInput.optionList = [];
    this.vendorTeamSearch.pageNumber = 1;
    this.vendorTeamSearch.totalPageNumber = 0;
  }

  private resetVendorTeamOption() {
    this.vendorTeamSearch.keywords = null;
    this.vendorTeamSearch.search$.next();
  }

  //------------------------
  // API
  private async requestVendorTeamList(isAppend: boolean = false) {
    let request = new JM.JMRequestContractsGetVendorTeamListByContract();
    request.contractNumber = this.pmPlan.contract.contractNumber;
    request.pageSize = this.vendorTeamSearch.pageSize;
    request.pageNumber = this.vendorTeamSearch.pageNumber;
    request.parameters = ['_id', 'name'];

    if (!!this.vendorTeamSearch.keywords) {
      request.filter = {
        name: this.vendorTeamSearch.keywords,
      };
    }

    this.vendorTeamInput.isLoading = true;
    const response: JM.JMResponseContractsGetVendorTeamListByContract = await AppDelegate.sendJMRequest(request);
    this.vendorTeamInput.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.vendorTeamSearch.totalPageNumber = Math.ceil(response.payload.totalCount / request.pageSize);
    if (isAppend) {
      this.vendorTeamInput.optionList = this.vendorTeamInput.optionList.concat(response.payload.records);
    } else {
      this.vendorTeamInput.optionList = response.payload.records;
    }
  }

  private async requestReferPmPlan() {
    let request = new JM.JMRequestVpPmPlanRefer();
    request.pmPlanNumber = this.pmPlan.pmPlanNumber;
    request.version = this.pmPlan.version;
    request.vendorTeamId = this.teamId.value;
    request.referRemarks = this.remarks.value.trim() || undefined;

    this.submitBtn.isLoading = true;
    const response: JM.JMResponseVpPmPlanRefer = await AppDelegate.sendJMRequest(request);
    this.submitBtn.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.pmPlan) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.referred'));
    this.updatedPmPlan.emit(this.pmPlan);
    this.resetForm();
  }

  //------------------------
  // button callback
  onSubmitBtnClicked() {
    const data = {
      msg: {
        content: 'component.pm-plan-refer-editor.pop-up.confirm-to-refer',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestReferPmPlan();
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(data);
  }

  //------------------------
  // get property
  get teamId() {
    return this.referForm.get('teamId');
  }
  get remarks() {
    return this.referForm.get('remarks');
  }

  get inputBoxMaxLength() {
    return Constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }
}

class PmPlanVendorTeamInput extends DropdownControl {
  value?: JMOBJ.VendorTeam;
  optionList: JMOBJ.VendorTeam[] = [];

  constructor() {
    super();
  }
}
