import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/core/session';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { BasePage } from 'src/app/core/base';
import { Constants } from 'src/constants';
import { JMLanguage, Language } from 'src/app/core/JMLanguage/JMLanguage';

@Component({
  selector: 'login-otp.component',
  templateUrl: './login-otp.component.html',
  styleUrls: ['./login-otp.component.scss'],
})
export class LoginOtpComponent extends BasePage implements OnInit {
  @ViewChild('resend_timer', { static: true }) resendTimer: CountdownComponent;

  // config
  otpInputIdHeader: string = 'otp-input-';
  otpLength: number = 6;

  otpInputItems = new Array(this.otpLength); // for ng for use only

  resendTimerConfig: CountdownConfig = {};

  // otp detail
  postName: string;
  otpPrefix: string;
  secretKey: string;
  maskedMobile: string;
  resendTimerSeconds: number;

  // view
  otpInputValues: string[] = new Array(this.otpLength);
  isDisabledSubmit: boolean = true;

  isResendTimerCouting: boolean;
  isLoadingResend: boolean = false;
  isLoadingSubmit: boolean = false;

  uiSelectedLanguage: Language = undefined;

  constructor(private injector: Injector) {
    super(injector, false);
  }

  ngOnInit() {
    this.uiSelectedLanguage = JMLanguage.getCurrentLanguage();

    // init otpInputValues
    for (let i = 0; i < this.otpLength; i++) {
      this.otpInputValues[i] = '';
    }

    // get otp detail
    let otpDetail = Session.getOtpDetail();
    if (!otpDetail) {
      AppDelegate.routeToPage('/');
    }
    this.setOtpDetail(otpDetail);
  }

  // ---------- Request function ----------
  async requestResendOtp() {
    this.isLoadingResend = true;

    let request = new JM.JMRequestAuthResendOtp();
    request.postName = this.postName;
    request.secretKey = this.secretKey;
    request.systemName = Constants.SYSTEM_NAME;

    JM.JMConnector.sendAuthResendOtp(request, (error: JM.JMNetworkError, result: JM.JMResponseAuthResendOtp) => {
      this.isLoadingResend = false;

      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }

      if (!result || !result.code || result.code != 200 || !result.payload) {
        this.handleApiError(result);
        return;
      }

      this.setOtpDetail(result.payload);
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('page.login-otp.otp-resent'));
    });
  }

  async requestVerifyOtp() {
    this.isLoadingSubmit = true;

    let request = new JM.JMRequestAuthVerifyOtp();
    request.postName = this.postName;
    request.secretKey = this.secretKey;
    request.otp = this.getOtp();
    request.systemName = Constants.SYSTEM_NAME;

    JM.JMConnector.sendAuthVerifyOtp(request, (error: JM.JMNetworkError, result: JM.JMResponseAuthVerifyOtp) => {
      this.isLoadingSubmit = false;

      if (error) {
        AppDelegate.handleJMError(error);
        return;
      }

      if (!result || !result.code || result.code != 200 || !result.payload) {
        this.handleApiError(result);
        return;
      }

      let response = result.payload;
      if (response.post && response.post.authorizations) {
        JM.JMConnector.setToken(JM.JMConnector.getToken());

        Session.setUserInfo(response.post);
        Session.setUserToken(response.token);

        Session.clearOtpDetail();

         // get feature list
         const requestSystemFeatureList = new JM.JMRequestSystemConfigsGetSystemFeatureList();
         requestSystemFeatureList.systemName = JMENUM.SourceSystem.CCEPVP;
         AppDelegate.sendJMRequest(requestSystemFeatureList).then(res => {
           Session.setFeatureList(res.payload.records);
         });

        AppDelegate.routeToPage('/home');
      } else {
        AppDelegate.toastMsg().showMsg(result);
      }
    });
  }

  // ---------- Custom function ---------
  resetTimer(leftTime) {
    this.isResendTimerCouting = true;
    this.resendTimerConfig.format = 'mm:ss';
    this.resendTimerConfig.leftTime = leftTime;

    if (this.resendTimer) {
      this.resendTimer.begin();
    }
  }

  getOtp() {
    return this.otpInputValues.join('');
  }

  setOtpDetail(otpDetail) {
    // resend response would not return postName
    if (otpDetail.postName) {
      this.postName = otpDetail.postName;
    }

    this.otpPrefix = otpDetail.otpPrefix;
    this.secretKey = otpDetail.secretKey;
    this.resendTimerSeconds = otpDetail.resendTimerSeconds;

    let mobile = otpDetail.mobile;
    let maskedIndex = 4;

    this.maskedMobile = '';
    for (let i = 0; i < maskedIndex; i++) {
      this.maskedMobile += '*';
    }
    this.maskedMobile += mobile.length <= maskedIndex ? '' : mobile.substr(maskedIndex, mobile.length - maskedIndex);

    // reset timer
    this.resetTimer(this.resendTimerSeconds);

    // save to session
    Session.setOtpDetail(this.postName, otpDetail);
  }

  handleApiError(result) {
    AppDelegate.toastMsg().showResponseMsg(result);

    // OTP expired, redirect to login page
    if (result.code && result.code === 6034) {
      Session.clearOtpDetail();
      AppDelegate.routeToPage('/login');
    }
  }

  // ---------- Event callback ----------
  onSubmitClicked() {
    this.requestVerifyOtp();
  }

  onResendClicked() {
    this.requestResendOtp();
  }

  checkValidToSubmit() {
    this.isDisabledSubmit = this.getOtp().length !== this.otpLength;
  }

  onInputOtp(event, index) {
    // backspace case
    if (!event) {
      return;
    }

    // input value, move to next input
    if (index < this.otpLength - 1) {
      index++;
      document.getElementById(this.otpInputIdHeader + index).focus();
    }
  }

  onBackspaceOtpInput(index) {
    if (index > 0) {
      document.getElementById(this.otpInputIdHeader + --index).focus();
    }
    return;
  }

  onResendTimerUpdated(event) {
    if (event && event.action === 'done') {
      this.isResendTimerCouting = false;
    }
  }
}
