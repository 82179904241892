import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TablexColumnHorizontalAlign, TablexColumnType, TablexColumnVerticalAlign } from 'src/app/shared/tablex/tablexColumnType';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { saveAs } from "file-saver";

@Component({
  selector: 'app-attachment-info',
  templateUrl: './attachment-info.component.html',
  styleUrls: ['./attachment-info.component.scss']
})

export class AttachmentInfoComponent implements OnInit {
  @Input() attachmentList: any[];
  @Input() currentPageMode: JMENUM.JMPageMode = undefined;

  pageMode = JMENUM.JMPageMode;
  tablexParam: any = {};

  constructor() {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.attachmentList) {
      changes.attachmentList.isFirstChange() && this.initTable();

      Array.isArray(this.attachmentList) && this.renderTable();
    }
  }

  renderTable() {
    this.tablexParam.content = this.attachmentList.reverse().map(attachment => {
      return [
        attachment._id,
        [{ 
          "id": "attachment_" + attachment._id,
          "name": attachment.attachmentId,
          "class": "attachment-button",
          "icon": "",
          "onClicked": this.requestGetFile,
          "attachment": attachment,
        }],
        attachment.description
      ];
    });
  }

  initTable() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: false,
      enablePagination: false,
      enableColFilter: false,
      enableSelectedRowCount: true,
      tableRow: "row",
      tableClass: "attachment-table",
      tableWrapperClass: "table-min-width",
      selectedRowCount: 0,
      totalRowCount: 0,
      enableStickyHeader: false,
      content: [],
      highlightedRows: [],
      customClassRows: [],
      headers: [
        {
          id: '_id',
          name: 'id',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "d-none"
        },
        {
          id: 'filename',
          name: 'component.attachment-info.file-name',
          type: TablexColumnType.Buttons,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        {
          id: 'description',
          name: 'component.attachment-info.description',
          type: TablexColumnType.Text,
          horizontalAlign: TablexColumnHorizontalAlign.Center,
          verticalAlign: TablexColumnVerticalAlign.Middle,
          class: "col"
        },
        // {
        //   id:'action',
        //   name: 'component.attachment.action',
        //   type: TablexColumnType.Buttons, 
        //   horizontalAlign: TablexColumnHorizontalAlign.Center, 
        //   verticalAlign: TablexColumnVerticalAlign.Middle, 
        //   class: "col-1"
        // }
      ]
    };
  }


  private requestGetFile = async (button) => {
    if (button.attachment) {
      const attachment = button.attachment;
      const request = new JM.JMRequestFilesGetFile(attachment.attachmentId);

      const response: JM.JMResponseFilesGetFile = await AppDelegate.sendJMRequestWithFileHost(request);
    
      if (!response || !response.payload) {
        AppDelegate.toastMsg().showResponseMsg(response);
        return;
      }

      saveAs(response.payload, attachment.attachmentId);
    }
  }
}
