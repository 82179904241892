import { Component, Input, OnInit, Injector } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';
import { ignoreElements } from 'rxjs/operators';

@Component({
  selector: 'app-sn-remarks',
  templateUrl: './sn-remarks.component.html',
  styleUrls: ['./sn-remarks.component.scss']
})
export class SnRemarksComponent implements OnInit {
  @Input() sn: JMOBJ.ServiceNotification = new JMOBJ.ServiceNotification();
  @Input() pageMode: JMENUM.JMPageMode = undefined;
  @Input() additionalRemark: JMOBJ.Remark = new JMOBJ.Remark();

  JMPageMode = JMENUM.JMPageMode;
  remarksMaxLength = Constants.TEXTAREA_MAX_LENGTH;

  snStatus = JMENUM.SnStatus;

  RemarkVisibility = JMENUM.RemarkVisibility;

  publicVisibilityPermission = false;
  privateVisibilityPermission = false;
  invisibleVisibilityPermission = false;

  // additionalRemark: JMOBJ.Remark = new JMOBJ.Remark;
  defaultRemarkVisibility = JMENUM.RemarkVisibility.PUBLIC;

  constructor() {}

  ngOnInit() {
    this.additionalRemark.visibilityType = this.defaultRemarkVisibility;
  }

  ngOnChanges() {
    this.additionalRemark.snStatus = (this.sn.status) ? this.sn.status : undefined;
    this.additionalRemark.visibilityType = (this.additionalRemark.visibilityType)?this.additionalRemark.visibilityType:this.defaultRemarkVisibility;
    // if (this.currentPageMode == this.pageMode.EDIT) {
    //   // this.publicVisibilityPermission = this.authorizationService.hasPermission(JMENUM.Permission.SN_REMARKS_PUBLIC_UPDATE);
    //   // this.privateVisibilityPermission = this.authorizationService.hasPermission(JMENUM.Permission.SN_REMARKS_PRIVATE_UPDATE);
    //   // this.invisibleVisibilityPermission = this.authorizationService.hasPermission(JMENUM.Permission.SN_REMARKS_INVISIBLE_UPDATE);
    // }
    // else if(this.currentPageMode == this.pageMode.VIEW){
    //   this.publicVisibilityPermission = false;
    //   this.privateVisibilityPermission = false;
    //   this.invisibleVisibilityPermission = false;
    // }
  }

  public onRemarkVisibilityChanged(visibility: JMENUM.RemarkVisibility) {
    this.additionalRemark.visibilityType = visibility;
  }

}
