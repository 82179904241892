import { JMENUM } from '@ccep/CCEPConnector-ts';
import { ActionButtonI } from 'src/app/shared/action-sidebar-v2/action-sidebar-v2.model';

export const PmPlanViewActionButtonList: { [x: string]: ActionButtonI } = {
  [JMENUM.VpPmPlanAction.SUBMIT]: {
    action: JMENUM.VpPmPlanAction.SUBMIT,
    permissionList: [JMENUM.VpPermission.PMPLAN_SUBMIT],
    actionDescription: 'action.button.submit',
    buttonId: 'right-tool-bar_submit_button',
    imageUrl: '/assets/svg/btn_action_submit.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  [JMENUM.VpPmPlanAction.CANCEL]: {
    action: JMENUM.VpPmPlanAction.CANCEL,
    permissionList: [JMENUM.VpPermission.PMPLAN_CANCEL],
    actionDescription: 'action.button.cancel',
    buttonId: 'right-tool-bar_cancel_button',
    imageUrl: '/assets/svg/btn_action_cancel.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  [JMENUM.VpPmPlanAction.UPDATE]: {
    action: JMENUM.VpPmPlanAction.UPDATE,
    permissionList: [JMENUM.VpPermission.PMPLAN_UPDATE],
    actionDescription: 'action.button.edit',
    buttonId: 'right-tool-bar_edit_button',
    imageUrl: '/assets/svg/btn_action_edit.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  [JMENUM.VpPmPlanAction.REFER]: {
    action: JMENUM.VpPmPlanAction.REFER,
    permissionList: [JMENUM.VpPermission.PMPLAN_UPDATE],
    actionDescription: 'action.button.refer',
    buttonId: 'right-tool-bar_refer_button',
    imageUrl: '/assets/svg/btn_action_reassign.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  [JMENUM.VpPmPlanAction.EQUIPMENT]: {
    action: JMENUM.VpPmPlanAction.EQUIPMENT,
    permissionList: [JMENUM.VpPermission.PMPLAN_UPDATE],
    actionDescription: 'action.button.equipment',
    buttonId: 'right-tool-bar_update-equipment_button',
    imageUrl: '/assets/svg/btn_action_equipment.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  ['equipmentview']: {
    action: 'equipmentview',
    permissionList: [JMENUM.VpPermission.PMPLAN_VIEW],
    actionDescription: 'action.button.equipment',
    buttonId: 'right-tool-bar_update-equipment_button',
    imageUrl: '/assets/svg/btn_action_equipment.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
  [JMENUM.VpPmPlanAction.ATTACHMENT]: {
    action: JMENUM.VpPmPlanAction.ATTACHMENT,
    permissionList: [JMENUM.VpPermission.PMPLAN_UPDATE],
    actionDescription: 'action.button.attachment',
    buttonId: 'right-tool-bar_attachment_button',
    imageUrl: '/assets/svg/btn_action_attach.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: '',
    popUpButtonList: [],
  },
};
