import { Component, Injector, OnInit } from '@angular/core';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { BasePage } from 'src/app/core/base';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import {
  ActionButtonDefinition,
  ActionButtonSelectEquipment,
  ActionButtonType,
} from 'src/app/shared/action-sidebar/action-button-vp';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';

@Component({
  selector: 'app-select-equipment',
  templateUrl: './select-equipment.component.html',
  styleUrls: ['./select-equipment.component.scss'],
})
export class SelectEquipmentComponent extends BasePage implements OnInit {
  breadcrumbs: any = [];
  snNumber: string;
  jobCardNumber: string;
  jobCard: any;
  hasUpdatePermission: boolean = false;

  // action buttons
  actionButtonData = [];
  uiIsLoading: boolean = false;
  disableActionSideBar: boolean = false;

  // Equipment List
  equipmentList = [];

  // tablex parameters
  tablexParam: {} = {};
  tablexFilter: {} = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize: number = 10;
  currentPage: number = 1;
  pageCount: number = 1;
  sortBy = undefined;
  sortOrder = undefined;
  selectedColId: any = [];
  allColHeaders: any = [];
  selectedCol: any = [];
  selectedRows: any = [];
  selectedTableRows: any = [];

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    // if (!AppDelegate.checkPagePermissions()) { return; }
    // this.hasUpdatePermission = UserHelper.hasEitherOnePermission([Permission.VpSNView, Permission.VpSNViewAll]);

    this.jobCardNumber = this.route.snapshot.paramMap.get('jobCardNumber');
    // this.setBreadcrumbs();
    this.initActionButtons();
    this.buttonHandling();
    this.initTableHeader();
    this.initSelectedCol();
    this.initTablex();
    this.requestEquipmentSummary();
  }

  /**** TEST ****/
  mockData() {
    this.equipmentList = [
      {
        _id: '_id',
        equipmentId: 'equipmentId',
        description: 'description',
        locationCode: 'locationCode',
        locationDescription: 'locationDescription',
        client: 'client',
        equipmentType: 'equipmentType',
        equipmentModel: 'equipmentModel',
        equipmentSerialNumber: 'equipmentSerialNumber',
        matchSeverity: 'matchSeverity',
      },
    ];
  }
  /**** TEST END ****/

  // ----------- API ----------- //
  requestEquipmentSummary() {
    this.mockData();
    this.renderTable();
  }

  // ----------- UI function ----------- //
  setBreadcrumbs() {
    let jobCardNumberArr = this.jobCardNumber.split('-');
    let taskNumber = JMLanguage.translate('pages.cm-task-view.breadcrumbs.task-number', [jobCardNumberArr[1]]);
    this.snNumber = jobCardNumberArr[0];
    this.breadcrumbs = [
      { id: 'breadcrumbs-sn-number', name: jobCardNumberArr[0], route: '/cm-task/' + jobCardNumberArr[0] },
      {
        id: 'breadcrumbs-task-number',
        name: taskNumber,
        route: '/cm-task/' + jobCardNumberArr[0] + '/' + this.jobCardNumber,
        currentPage: false,
      },
      {
        id: 'breadcrumbs-equipment',
        name: JMLanguage.translate('pages.select-equipment.equipment'),
        route: null,
        currentPage: true,
      },
    ];
  }

  initSelectedCol() {
    this.selectedColId = [
      'objId',
      'equipmentId',
      'description',
      'locationCode',
      'locationDescription',
      'client',
      'equipmentType',
      'equipmentModel',
      'equipmentSerialNumber',
      'matchSeverity',
      'selection',
    ];
    // let cachedUserSettings = Session.userInfo.userSettings;
    // if (cachedUserSettings && cachedUserSettings[VPUserSettings.VP_SELECT_EQUIPMENT_LIST_COLUMNS]) {
    //   Object.assign(this.selectedColId, cachedUserSettings[VPUserSettings.VP_SELECT_EQUIPMENT_LIST_COLUMNS]);
    // }

    this.onColFiltered(this.selectedColId);
  }

  initActionButtons(): void {
    this.actionButtonData = [];
    this.addActionBtn(ActionButtonSelectEquipment.save);
    this.addActionBtn(ActionButtonSelectEquipment.clear);
    this.addActionBtn(ActionButtonSelectEquipment.close);
  }

  addActionBtn(buttonStatus: ActionButtonSelectEquipment): void {
    let actionButton = ActionButtonDefinition[ActionButtonType.selectEquipment][buttonStatus];
    let buttonHandler = () => {};

    switch (buttonStatus) {
      case ActionButtonSelectEquipment.save:
        buttonHandler = () => {};
        break;
      case ActionButtonSelectEquipment.clear:
        buttonHandler = () => {};
        break;
      case ActionButtonSelectEquipment.close:
        buttonHandler = () => {
          let jobCardNumberArr = this.jobCardNumber.split('-');
          AppDelegate.routeToPage('/cm-task/' + jobCardNumberArr[0] + '/' + this.jobCardNumber);
        };
        break;
      default:
        break;
    }

    actionButton.buttons = [
      {
        name: actionButton.buttons && actionButton.buttons.length >= 1 ? actionButton.buttons[0].name : 'global.yes',
        handler: buttonHandler,
      },
      {
        name: actionButton.buttons && actionButton.buttons.length >= 2 ? actionButton.buttons[1].name : 'global.no',
        handler: () => {
          this.uiIsLoading = false;
        },
      },
    ];
    this.actionButtonData.push(actionButton);
  }

  onActionButtonClicked(actionButton: any) {
    if (actionButton.showPopup) {
      let data = {
        msg: { content: actionButton.popupTitle },
        buttons: actionButton.buttons,
      };
      AppDelegate.popUpDialog().open(data);
    } else {
      actionButton.buttons[0].handler();
    }
  }

  hideActionButton(button) {
    let actionButton = this.actionButtonData.find(({ action }) => action == button);
    actionButton.isShow = false;
  }

  buttonHandling() {
    if (!this.hasUpdatePermission) {
      // this.hideActionButton(ActionButtonSnView.reject);
    }
  }

  // ----------- Tablex UI function ----------- //
  public onRowSelected = (index, row) => {
    if (!this.equipmentList) {
      return;
    }
    // this.selectedTeam = this.equipmentList.find(record => record._id == row[0]);
    // this.onSelectTeam.emit(this.selectedTeam);
  };

  public onRowUnselected = (index, row) => {
    // this.selectedTeam = undefined;
    // this.onSelectTeam.emit(new JMOBJ.VendorTeam());
  };

  onColFiltered = (selectedColId) => {
    this.selectedColId = [...selectedColId];
    this.renderTable();

    // if (!Session.userInfo['userSettings'] || (Session.userInfo['userSettings'] && Session.userInfo.userSettings[VPUserSettings.VP_SELECT_EQUIPMENT_LIST_COLUMNS] != this.selectedColId)) {
    //   this.requestUpdateUserSettings();
    //   let userSettings = Session.userInfo['userSettings']? Session.userInfo['userSettings'] : {};
    //   userSettings[VPUserSettings.VP_SELECT_EQUIPMENT_LIST_COLUMNS] = this.selectedColId;
    //   Session.userInfo.userSettings = userSettings;
    //   Session.setUserInfo(Session.userInfo);
    // }
  };

  onFilterChanged = (event, index, header, filter) => {
    for (let key in filter) {
      if (!filter[key]) {
        delete this.tablexFilter[key];
      } else {
        this.tablexFilter[key] = filter[key];
      }
    }
    this.currentPage = 1;
    this.requestEquipmentSummary();
  };

  onFilterClear = () => {
    this.tablexFilter = {};
    this.tablexParam['filter'] = {};
    this.requestEquipmentSummary();
  };

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.resetTableAction();
    this.requestEquipmentSummary();
  };

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.resetTableAction();
    this.requestEquipmentSummary();
  };

  onCheckSelection = (id, value) => {
    //override onRowClicked()
  };

  public resetTableAction() {
    this.selectedTableRows = [];
    this.tablexParam['selectedRowIndex'] = undefined;
    this.tablexParam['highlightedRows'] = [];
    // this.selectedTeam = undefined;
    // this.onSelectTeam.emit(new JMOBJ.VendorTeam());
    // this.onSelectTeam.emit(this.selectedTeam);
  }

  renderTable() {
    this.tablexParam['headers'] = this.allColHeaders.filter((col) => {
      return this.selectedColId.includes(col.id);
    });
    this.tablexParam['content'] = this.equipmentList.map((data, index) => {
      let originRow = [
        data._id,
        data.equipmentId,
        data.description,
        data.locationCode,
        data.locationDescription,
        data.client,
        data.equipmentType,
        data.equipmentModel,
        data.equipmentSerialNumber,
        data.matchSeverity,
        {
          id: 'selection-checkbox-' + data._id,
          objId: data._id,
          value: false,
          isDisabled: false,
          onChange: this.onCheckSelection,
        },
      ];

      let row = [];
      originRow.forEach((value, i) => {
        if (this.selectedColId.includes(this.allColHeaders[i].id)) {
          row.push(originRow[i]);
        }
      });
      return row;
    });
    this.resetTableAction();
    this.tablexParam['isLoadingTable'] = false;
    this.tablexParam['totalRowCount'] = this.tablexParam['content'].length;
  }

  initTablex() {
    this.tablexParam = {
      enableSetPageSize: true,
      enablePagination: true,
      enableClearFilter: true,
      enableColFilter: true,
      enableSelectedRowCount: false,
      enableSort: true,
      minifyButton: true,
      tableRow: 'row',
      tableClass: 'equipment-list-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      selectedColId: this.selectedColId,
      fullColNameList: this.allColHeaders.map((col) => {
        return { id: col.id, name: col.name };
      }),
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowSelected: this.onRowSelected,
      onRowUnselected: this.onRowUnselected,
      onColFiltered: this.onColFiltered,
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };

    this.initTableHeader();
  }

  initTableHeader() {
    this.allColHeaders = [
      {
        id: 'objId',
        name: '',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'd-none ',
      },
      {
        id: 'equipmentId',
        name: 'pages.select-equipment.table-column.equipment-id',
        enableSort: true,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'description',
        name: 'pages.select-equipment.table-column.description',
        enableSort: true,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'locationCode',
        name: 'pages.select-equipment.table-column.location-code',
        enableSort: true,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'locationDescription',
        name: 'pages.select-equipment.table-column.location-description',
        enableSort: true,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'client',
        name: 'pages.select-equipment.table-column.client',
        enableSort: true,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'equipmentType',
        name: 'pages.select-equipment.table-column.equipment-type',
        enableSort: true,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'equipmentModel',
        name: 'pages.select-equipment.table-column.equipment-model',
        enableSort: true,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'equipmentSerialNumber',
        name: 'pages.select-equipment.table-column.equipment-serial-number',
        enableSort: true,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'matchSeverity',
        name: 'pages.select-equipment.table-column.match-severity',
        enableSort: true,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'selection',
        name: 'pages.select-equipment.table-column.selection',
        enableSort: false,
        enableFilter: false,
        enableSelectCol: true,
        type: TablexColumnType.CheckBox,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
        selectAll: false,
        onSelectAll: () => {},
        onClickCheckbox: () => {},
      },
    ];
    this.selectedCol = this.allColHeaders;
  }
}
