import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { UserHelper } from 'src/app/core/user-helper';
import { ActionButtonI } from 'src/app/shared/action-sidebar-v2/action-sidebar-v2.model';
import { ActionSidebarV2Service } from '../../shared/action-sidebar-v2.service';
import { PmJobService } from 'src/app/pm/shared/pm-job.service'
import { PmJobViewActionButtonList } from './pm-job-view.model';

import { PmJobDescriptionComponent } from '../shared/pm-job-description/pm-job-description.component';
import { PmJobClientInfoComponent } from '../shared/pm-job-client-info/pm-job-client-info.component';
import { PmJobProgressComponent } from '../shared/pm-job-progress/pm-job-progress.component';
import { PmJobEquipmentInfoComponent } from '../shared/pm-job-equipment-info/pm-job-equipment-info.component';
import { CustomSliderPanelComponent } from '../../shared/custom-slider-panel/custom-slider-panel.component';

@Component({
  selector: 'app-pm-job-view',
  templateUrl: './pm-job-view.component.html',
  styleUrls: ['./pm-job-view.component.scss']
})
export class PmJobViewComponent implements OnInit {
  @ViewChild('pmJobDescriptionElem', { static: false }) pmJobDescriptionElem: PmJobDescriptionComponent;
  @ViewChild('pmJobClientInfoElem', { static: false }) pmJobClientInfoElem: PmJobClientInfoComponent;
  @ViewChild('pmJobProgressElem', { static: false }) pmJobProgressElem: PmJobProgressComponent;
  @ViewChild('pmJobEquipmentInfoElem', { static: false }) pmJobEquipmentInfoElem: PmJobEquipmentInfoComponent;

  @ViewChild('pm_job_cancel_form_panel', { static: false }) pmJobCancelFormPanel: CustomSliderPanelComponent;

  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.VIEW;
  JMPage = JMENUM.JMPage;
  breadcrumbs: any = [];
  jobNumber: string;
  pmJob: JMOBJ.PmJob = new JMOBJ.PmJob();

  // action buttons
  actionSidebar: {
    dataList: ActionButtonI[];
    isDisabled: boolean;
    isLoading: boolean;
  } = {
    dataList: [],
    isDisabled: false,
    isLoading: false,
  };

  constructor(private route: ActivatedRoute, private actionSidebarV2Service: ActionSidebarV2Service, private pmJobService: PmJobService) { }

  ngOnInit(): void {
    const route = AppDelegate.getRoute();
    const trimRoute = route.replace(/^(\/pm\/jobs).*$/g, '$1');

    if (!AppDelegate.checkPagePermissions(trimRoute)) {
      return;
    }
    this.initPage();
  }

  async initPage() {
    this.jobNumber = this.route.snapshot.paramMap.get('jobNumber');

    if (this.jobNumber) {
      this.initBreadCrumbs();
      this.fetchJobData();
    }
  };

  initBreadCrumbs() {
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-pm-job',
        name: JMLanguage.translate('pages.pm-job-view.breadcrumbs.task-list'),
        route: '/pm/jobs',
      },
      {
        id: 'breadcrumbs-pm-job-number',
        name: this.jobNumber,
        route: null,
        currentPage: true,
      },
    ];
  }

  triggerSubmitButtonAction() {
    if(this.pmJobDescriptionElem.validate() && this.pmJobClientInfoElem.validate()
    && this.pmJobProgressElem.validate()) {
      const data = {
        msg: {
          content: 'action.button.popup.confirm-submit',
        },
        buttons: [
          {
            name: 'global.yes',
            handler: async () => {
              const response = await this.pmJobService.submitPmJob(this.pmJob);
              if (response?.payload) {
                this.pmJob = response.payload.pmJob;
                this.resetActionBar(response.payload.actionList);
              }
            },
          },
          {
            name: 'global.no',
            handler: null,
          },
        ],
      };
      AppDelegate.popUpDialog().open(data);
    }
  }

  // API
  async fetchJobData() {
    this.actionSidebar.isLoading = true;
    const response: JM.JMResponseVpGetPmJob = await this.pmJobService.getPmJob(this.jobNumber);
    this.actionSidebar.isLoading = false;

    this.pmJob = response.payload?.pmJob;
    if (Array.isArray(response.payload?.actionList)) {
      this.resetActionBar(response.payload.actionList);
    }
  }

  // ----------- action button function -----------
  onClickedActionButton(actionButton: ActionButtonI) {
    actionButton.handler();
  }

  private resetActionBar(apiActionList): void {
    this.actionSidebar.dataList = [];
    if (apiActionList.length) {
      this.filterActionButton(apiActionList);
    }
  }

  private filterActionButton(actionList: string[]) {
    const sortedButtonList = [
      JMENUM.VpPmJobAction.SUBMIT,
      JMENUM.VpPmJobAction.CANCEL,
      JMENUM.VpPmJobAction.UPDATE,
      JMENUM.VpPmJobAction.EQUIPMENT,
      JMENUM.VpPmJobAction.ATTACHMENT,
    ];

    for (const sortedButton of sortedButtonList) {
      const showActionButton = actionList.includes(sortedButton);

      if (showActionButton) {
        const button: ActionButtonI = PmJobViewActionButtonList[sortedButton];
        const hasPermissionList = button && button.permissionList && button.permissionList.length;

        if (hasPermissionList) {
          const allowAction = UserHelper.hasEitherOnePermission(button.permissionList);
          if (allowAction) {
            this.addActionButton(button);
          }
        } else {
          this.addActionButton(button);
        }
      }
    }
  }

  private addActionButton(actionButton: ActionButtonI) {
    let handler = () => {};

    switch (actionButton.action) {
      case JMENUM.VpPmJobAction.SUBMIT:
        handler = async () => {
          if(Array.isArray(this.pmJob.equipmentList) && this.pmJob.equipmentList.length > 0) {
            this.triggerSubmitButtonAction();
          } else {
            AppDelegate.toastMsg().showMsg(JMLanguage.translate("pages.pm-job.popup.message.mandatory-equipment"));
          }
        };
        break;
      case JMENUM.VpPmJobAction.CANCEL:
        handler = () => {
          this.pmJobCancelFormPanel.toggle();
        };
        break;
      case JMENUM.VpPmJobAction.UPDATE:
        handler = () => {
          AppDelegate.routeToPage(`/pm/jobs/${this.jobNumber}/edit`);
        };
        break;
      case JMENUM.VpPmJobAction.EQUIPMENT:
        handler = async () => {
          AppDelegate.routeToPage(`/pm/jobs/${this.jobNumber}/equipment-list`);
        };
        break;
      case JMENUM.VpPmJobAction.ATTACHMENT:
        handler = async () => {
          const attachmentPageUrl = `/pm/jobs/${this.jobNumber}/attachment`;
          AppDelegate.routeToPage(attachmentPageUrl);
        };
        break;
      default:
        break;
    }

    actionButton.handler = handler;
    this.actionSidebar.dataList.push(actionButton);
  }

  //------------------------
  // button callback
  onCancelledPmJob(pmJob: JMOBJ.PmJob) {
    this.fetchJobData();
    this.pmJobCancelFormPanel.close();
  }


  validationFieldsOnAllComponents() {
    const validDescription = this.pmJobDescriptionElem.validate();
    const validClient = this.pmJobClientInfoElem.validate();
    const validProgress = this.pmJobProgressElem.validate();

    return validDescription && validClient && validProgress;
  }
}
