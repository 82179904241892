<div *ngIf="messages.length > 0" class="sn-cancel-remarks-component">
  <!-- <div class="font-size-xl m-0 mb-2">
    {{ 'component.sn-cancel-remarks.title' | translate }}
  </div>

  <hr class="underline" /> -->

  <div class="messages">
    <div *ngFor="let msg of messages; index as i" class="message">
      <p class="left">{{ msg.title }}</p>
      <p class="right">{{ msg.details }}</p>
    </div>
  </div>
</div>
