<div class="copy-office-hour-form row mx-0 align-items-center">
  <div class="mr-2">{{ "pages.settings-team.copy-hours-from" | translate }}</div>
  <ng-select class="col px-0 mr-2">
  </ng-select>
  <button class="btn standard brand-blue">
    <div *ngIf="parameters.isLoading" class="d-flex align-items-center loading-spinner spinner-small"></div>
    <ng-container *ngIf="!parameters.isLoading">
      {{ "global.submit" | translate }}
    </ng-container>
  </button>
</div>