
<div class="wrapper">
  <app-nav [navbarTitle]="'pages.role-settings.page-title' | translate" ></app-nav>
  <app-sidebar [contentType]="'settings'"></app-sidebar>

  <div id="content" class="role-settings-content ">

    <div class="row ">
      <div class="col-4">
        <div class="header p-2 font-size-l bold">{{ "pages.role-settings.header.role" | translate }}</div>
        <div class="item-container border" *ngIf="roleList">
          <a *ngIf="isLoading.getAllRoles" class="loading-spinner spinner-small p-2 d-flex justify-content-center" ></a>
          <div class="p-2 border item role-item" [class.active]="selectedRoleId == role.roleId" *ngFor="let role of roleList" (click)="onSelectRole(role)">
            {{ role.name }}
          </div>
        </div>
        
      </div>

      <div class="col-4">
        <div class="header p-2 font-size-l bold">{{ "pages.role-settings.header.given-permission" | translate }}</div>
        <div class=" item-container border" (dragenter)="onDragEnter('givenPermission')" [class.active]="dropOnArea == 'givenPermission'">
          <a *ngIf="isLoading.getAllPermissions || isLoading.getAllRoles || isLoading.updatePermissions" class="loading-spinner spinner-small p-2 d-flex justify-content-center" ></a>
          <ng-container *ngIf="selectedRole">
            <div class="item-wrap" *ngFor="let permission of selectedRole.permissions">
              <div class="item p-2 border" [draggable]="userCanEdit" [class]="{'d-none':permissionOnDrag==permission}" 
                (drag)="permissionOnDrag = permission" (dragstart)="onDragStart('givenPermission')" (dragend)="onDragEnd()">
                {{ permission }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="col-4">
        <div class="header p-2 font-size-l bold">{{ "pages.role-settings.header.available-permission" | translate }}</div>
        <div class=" item-container border" (dragenter)="onDragEnter('availablePermission')" [class.active]="dropOnArea == 'availablePermission'">
          <a *ngIf="isLoading.getAllPermissions || isLoading.getAllRoles || isLoading.updatePermissions" class="loading-spinner spinner-small p-2 d-flex justify-content-center" ></a>
          <ng-container *ngIf="selectedRole && availablePermissionList">
            <div class="item-wrap" *ngFor="let availablePermission of availablePermissionList">
              <div class="item p-2 border" [draggable]="userCanEdit" [class]="{'d-none':permissionOnDrag==availablePermission}" 
                (drag)="permissionOnDrag = availablePermission" (dragstart)="onDragStart('availablePermission')" (dragend)="onDragEnd()">
                  {{ availablePermission }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>

    </div>
  </div>

</div>