import { AppDelegate } from 'src/app/core/AppDelegate';
import { Component, OnInit } from '@angular/core';
import { JMOBJ } from '@ccep/CCEPConnector-ts';
import { RoutingCoverageI } from '../shared/contract-routing-coverage-list-table/contract-routing-coverage-list-table.component';

@Component({
  selector: 'app-setting-contract-emsd-contact',
  templateUrl: './setting-contract-emsd-contact.component.html',
  styleUrls: ['./setting-contract-emsd-contact.component.scss']
})
export class SettingContractEmsdContactComponent implements OnInit {
  
  selectedContract?: JMOBJ.MaintenanceTermContract;
  selectedRoutingCriteria?: RoutingCoverageI;

  constructor() { }

  ngOnInit(): void {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }
  }


  onAssignedContract(contract: JMOBJ.MaintenanceTermContract) {
    this.selectedContract = contract;
  }

  onAssignedRoutingCoverage(coverage: RoutingCoverageI) {
    this.selectedRoutingCriteria = coverage;
  }
}
