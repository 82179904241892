<div class="col-12 font-size-xl m-0 mb-2 p-0">
    <div class="d-flex justify-content-between">
        <div>
            {{ "component.contractor-team-list.title" | translate}}
        </div>
    </div>
</div>
<hr class="underline" />
<div class="row">
    <app-tablex id="contractor-team-list-table" class="col-12" [tablexParam]='tablexParam'></app-tablex>
</div>
