<div class="wrapper">
  <app-nav [navbarTitle]="'sidebar.subitem.pm-job-list' | translate"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="content pm-job-list-content">
    <div class="component-wrap row">
      <div class="col-12 col-xl-9 ">
        <app-tablex 
          #pmJobListTable
          id="pm-job-list-table"
          [tablexParam]="tablexParam"
        ></app-tablex>
      </div>

      <div class="col-12 col-xl-3">
        <app-pm-job-search-criteria
          (onSearch)="onAdvancedSearch($event)"
        ></app-pm-job-search-criteria>
      </div>
    </div>
  </div>
</div>
