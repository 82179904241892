import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import {
  JMResponseAddEquipmentToPmPlan,
  JMResponseRemoveEquipmentFromPmPlan,
} from '@ccep/CCEPConnector-ts/lib/JMConnector/JMConnector';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { ActionSidebarV2Service } from 'src/app/shared/action-sidebar-v2.service';
import { ActionButtonI } from 'src/app/shared/action-sidebar-v2/action-sidebar-v2.model';
import { breadcrumbsComponentI } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';

@Component({
  selector: 'app-pm-job-equipment-list',
  templateUrl: './pm-job-equipment-list.component.html',
  styleUrls: ['./pm-job-equipment-list.component.scss'],
})
export class PmJobEquipmentListComponent {
  //Service
  // route: ActivatedRoute;
  location: Location;
  actionSidebarV2Service: ActionSidebarV2Service;
  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.EDIT;
  JMPageMode = JMENUM.JMPageMode;

  //Tabs
  tabOptions = {
    assigned: JMLanguage.translate('component.pm-plan-equipment-list.assigned'),
    unassigned: JMLanguage.translate('component.pm-plan-equipment-list.unassigned'),
  };
  activeTab = this.tabOptions.unassigned;

  //TableX
  tablexParam: { [key: string]: any } = {};

  //Action buttons
  actionSidebar: {
    dataList: ActionButtonI[];
    isDisabled: boolean;
    isLoading: boolean;
  } = {
    dataList: [],
    isDisabled: false,
    isLoading: false,
  };

  //Others
  pmJobNumber: string;
  breadcrumbs: breadcrumbsComponentI[];
  equipmentList: JM.JMOBJ.Equipment[] = [];
  equipmentListVersion: number;
  isSubmitting: boolean;

  constructor(private route: ActivatedRoute, location: Location, actionSidebarV2Service: ActionSidebarV2Service) {
    route.data.subscribe((data) => {
      if (data.mode) {
        this.pageMode = data.mode;
      }
    });
    this.location = location;
    this.actionSidebarV2Service = actionSidebarV2Service;
  }

  async ngOnInit() {
    const route = AppDelegate.getRoute();
    const trimRoute = route.replace(/^(\/pm\/jobs)(\/.*)(\/.*)$/g, '$1$3');

    if (!AppDelegate.checkPagePermissions(trimRoute)) {
      return;
    }

    this.pmJobNumber = this.route.snapshot.paramMap.get('jobNumber');
    this.setBreadcrumbs();
    this.initTablex();
    await this.requestTableData();
    this.renderTable();
    this.initActionBtns();
  }

  setBreadcrumbs = () => {
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-job-list',
        name: JMLanguage.translate('sidebar.item.pm-job-standard'),
        route: `/pm/jobs`,
      },
      {
        id: 'breadcrumbs-pm-job-number',
        name: this.pmJobNumber,
        route: `/pm/jobs/${this.pmJobNumber}`,
      },
      {
        id: 'breadcrumbs-sn-number',
        name: JMLanguage.translate('breadcrumbs.equipment'),
        route: ``,
        currentPage: true,
      },
    ];
  };

  initActionBtns = () => {
    this.actionSidebar.dataList = [];

    const actionList = ['close'];

    actionList.forEach((e) => this.addActionButton(PmJobEquipmentListButtonList[e]));
  };

  private addActionButton(actionButton: ActionButtonI) {
    let handler = () => {};

    switch (actionButton.action) {
      case 'close':
        handler = () => {
          if (this.pmJobNumber) {
            AppDelegate.routeToPage(`/pm/jobs/${this.pmJobNumber}`);
          }
        };
        break;
      default:
        break;
    }

    actionButton.handler = handler;
    this.actionSidebar.dataList.push(actionButton);
  }

  onClickedActionButton(actionButton: ActionButtonI) {
    actionButton.handler();
  }

  initTablex() {
    this.tablexParam = {
      pageSizeOptions: [10, 25, 100],
      currentPageSize: 10,
      currentPage: 1,
      pageCount: 0,
      enableSetPageSize: true,
      enablePagination: true,
      enableSort: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      isLoadingTable: false,
      // tableRow: 'd-flex',
      // tableClass: 'user-table',
      // tableWrapperClass: 'table-min-width',
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowClicked: this.onRowClicked,
      // onRowUnselected: this.onRowUnselected,
      content: [],
      highlightedRows: [],
    };
    this.initTableHeader();
  }

  initTableHeader() {
    this.tablexParam.headers = [
      {
        id: GetPmJobAssignedEquipmentListParams.equipmentNumber,
        name: 'component.pm-plan-equipment-list.table-column.equipment-id',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: GetPmJobAssignedEquipmentListParams.description,
        name: 'component.pm-plan-equipment-list.table-column.description',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: GetPmJobAssignedEquipmentListParams.location,
        name: 'component.pm-plan-equipment-list.table-column.location-code',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: GetPmJobAssignedEquipmentListParams.locationDescription,
        name: 'component.pm-plan-equipment-list.table-column.location-description',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: GetPmJobAssignedEquipmentListParams.clientShortName,
        name: 'component.pm-plan-equipment-list.table-column.client',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: GetPmJobAssignedEquipmentListParams.equipmentType,
        name: 'component.pm-plan-equipment-list.table-column.equipment-type',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
      {
        id: GetPmJobAssignedEquipmentListParams.status,
        name: 'component.pm-plan-equipment-list.table-column.status',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        // class: 'w-20',
      },
    ];
  }

  renderTable() {
    this.tablexParam.content = this.equipmentList.map((eq) => {
      let equipmentNumber = eq[GetPmJobAssignedEquipmentListParams.equipmentNumber];
      let description = eq[GetPmJobAssignedEquipmentListParams.description];
      let location = eq[GetPmJobAssignedEquipmentListParams.location] || [];
      let locationDescription = eq[GetPmJobAssignedEquipmentListParams.locationDescription];
      let clientShortName = eq[GetPmJobAssignedEquipmentListParams.clientShortName];
      let equipmentType = eq[GetPmJobAssignedEquipmentListParams.equipmentType];
      let status = eq[GetPmJobAssignedEquipmentListParams.status];
      return [
        equipmentNumber,
        description,
        location,
        locationDescription ? locationDescription[JMLanguage.getCurrentLanguage()] : '',
        clientShortName,
        equipmentType,
        JMLanguage.translate('pm-period.status.'+status) || '',
      ];
    });
  }

  async requestTableData(pageNumber?: number, pageSize?: number): Promise<boolean> {
    this.selectRow(null);
    let request: JM.JMRequestVpGetPmJobAssignedEquipmentList | JM.JMRequestVpGetPmJobUnassignedEquipmentList;
    switch (this.activeTab) {
      case this.tabOptions.assigned:
        request = new JM.JMRequestVpGetPmJobAssignedEquipmentList();
        break;
      case this.tabOptions.unassigned:
        request = new JM.JMRequestVpGetPmJobUnassignedEquipmentList();
        break;
    }
    this.tablexParam.isLoadingTable = true;
    request.pmJobNumber = this.pmJobNumber;
    request.sortBy = GetPmJobAssignedEquipmentListParams.equipmentNumber;
    request.sortOrder = JMENUM.SortOrder.ASC;
    request.pageNumber = pageNumber ? pageNumber : 1;
    request.pageSize = pageSize ? pageSize : this.tablexParam.currentPageSize;
    request.parameters = Object.values(GetPmJobAssignedEquipmentListParams);

    this.equipmentList = [];
    const response: JM.JMResponseVpGetPmJobAssignedEquipmentList | JM.JMResponseVpGetPmJobUnassignedEquipmentList =
    await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return false;
    }
    this.equipmentList = response.payload.records;
    this.equipmentListVersion = response.payload.jobVersion;
    this.tablexParam.totalCount = response.payload.totalCount;
    this.tablexParam.pageCount = Math.ceil(response.payload.totalCount / request.pageSize);
    this.tablexParam.currentPage = request.pageNumber;
    this.tablexParam.currentPageSize = request.pageSize;
    return true;
  }

  selectRow(index: number) {
    this.tablexParam.selectedRowIndex = null;
    if (index == null) {
      this.tablexParam.highlightedRows = [];
    } else {
      let prevState = this.tablexParam.highlightedRows[index];
      this.tablexParam.highlightedRows[index] = !prevState;
    }
  }

  async submitEquipment(equipmentlist: string[]) {
    this.isSubmitting = true;
    let submitResult: boolean;
    switch (this.activeTab) {
      case this.tabOptions.assigned:
        submitResult = await this.removeEquipmentFromPmPlan(equipmentlist);
        break;
      case this.tabOptions.unassigned:
        submitResult = await this.addEquipmentToPmPlan(equipmentlist);
        break;
    }
    this.isSubmitting = false;

    submitResult && (await this.requestTableData(1)) && this.renderTable();
  }

  private async addEquipmentToPmPlan(equipmentlist: string[]): Promise<boolean> {
    if (!this.pmJobNumber) return false;

    let request = new JM.JMRequestVpAddEquipmentToPmJob();
    request.pmJobNumber = this.pmJobNumber;
    request.equipmentNumbers = equipmentlist;
    request.version = this.equipmentListVersion;

    const response: JM.JMResponseVpAddEquipmentToPmJob = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return false;
    }
    return true;
  }

  private async removeEquipmentFromPmPlan(equipmentlist: string[]): Promise<boolean> {
    if (!this.pmJobNumber) return false;

    let request = new JM.JMRequestVpRemoveEquipmentFromPmJob();
    request.pmJobNumber = this.pmJobNumber;
    request.equipmentNumbers = equipmentlist;
    request.version = this.equipmentListVersion;

    const response: JM.JMResponseVpRemoveEquipmentFromPmJob = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return false;
    }
    return true;
  }

  onSubmitClick = () => {
    let selectedEquipmentList = [];
    this.tablexParam.highlightedRows.forEach((el, index) => {
      if (el) {
        selectedEquipmentList.push(this.tablexParam.content[index][0]);
      }
    });
    if (selectedEquipmentList.length > 0) {
      this.submitEquipment(selectedEquipmentList);
    }
  };

  onRowClicked = (index, row) => {
    this.selectRow(index);
  };

  onPageSizeClicked = async (pageSize: number) => {
    await this.requestTableData(1, pageSize);
    this.renderTable();
  };

  onPageNumberClicked = async (pageNumber: number) => {
    await this.requestTableData(pageNumber);
    this.renderTable();
  };

  onClickSetActiveTab = async (tab) => {
    // this.setActiveTab(tab);
    this.activeTab = tab;
    await this.requestTableData(1);
    this.renderTable();
  };

  onSelectAll = () => {
    this.tablexParam.highlightedRows = this.tablexParam.content.map((e) => true);
  };

  onUnselectAll = () => {
    this.tablexParam.highlightedRows = [];
  };

  get submitBtnName() {
    switch (this.activeTab) {
      case this.tabOptions.unassigned:
        return JMLanguage.translate('component.pm-plan-equipment-list.assign');
      case this.tabOptions.assigned:
        return JMLanguage.translate('component.pm-plan-equipment-list.unassign');
      default:
        return undefined;
    }
  }

  get isActionBarEnabled() {
    return this.actionSidebar.dataList && this.actionSidebar.dataList.length > 0;
  }

  get numberOfRowsSelected(): number {
    if (this.tablexParam && this.tablexParam.highlightedRows) {
      return this.tablexParam.highlightedRows.filter((e) => e).length;
    }
    return 0;
  }
}

enum GetPmJobAssignedEquipmentListParams {
  _id = '_id',
  equipmentNumber = 'equipmentNumber',
  description = 'description',
  location = 'location',
  locationDescription = 'locationDescription',
  clientShortName = 'clientShortName',
  equipmentType = 'equipmentType',
  status = 'status',
}

const PmJobEquipmentListButtonList: { [x: string]: ActionButtonI } = {
  close: {
    action: 'close',
    permissionList: [],
    actionDescription: 'action.button.close',
    buttonId: 'right-tool-bar_close_button',
    imageUrl: '/assets/svg/btn_action_reject.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: 'action.button.popup.confirm-close',
    popUpButtonList: [],
  },
};
