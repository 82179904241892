<div class="container-fluid mb-3">

  <div class="d-flex align-items-center justify-content-end my-3">
    <app-loading-button  
    [id]="'equipment_select_button'"
    [text]="'global.select'"
    [buttonClass]="'w-2 standard brand-blue'"
    [iconClass]="'fa-check'"
    [isDisabled]="false"
    (clickedButton)="onClickedSelectButton()"
  ></app-loading-button>
  </div>

  <div class="d-flex align-items-center justify-content-between">
    <h3 class="font-size-xl">
      {{ 'component.cm-equipment-selector.title' | translate }}
    </h3>
    <div class="custom-checkbox" *ngIf="false">
      <input id="severity-criteria-equipment-checkbox" type="checkbox" class="custom-control-input"
        [(ngModel)]="isSeverityCriteriaEquipment" (change)="onChangedSeverityCriteriaEquipmentCheckbox()">
      <label class="custom-control-label ml-2" for="severity-criteria-equipment-checkbox">
        {{ 'component.cm-equipment-selector.show-all-contracted-equipment' | translate }}
      </label>
    </div>
  </div>
  <hr class="underline" />
  
  <div>
    <equipment-table-by-contract [contractNumber]="contract?.contractNumber" [sn]="sn"
      (equipmentChange)="onEquipmentChanged($event)"></equipment-table-by-contract>
  </div>

</div>