import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';

@Component({
  selector: 'app-cm-additional-info',
  templateUrl: './cm-additional-info.component.html',
  styleUrls: ['./cm-additional-info.component.scss'],
})
export class CmAdditionalInfoComponent implements OnInit, OnChanges {
  @Input() jobCard: JMOBJ.JobCard;
  @Input() sn: JMOBJ.ServiceNotification;
  @Input() pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.VIEW;
  @Input() contract?: JMOBJ.MaintenanceTermContract;

  //input fields
  faultType: string;

  // FaultType Dropdown
  faultTypeOptions: any = [];

  errorFields = {};

  constructor() {}

  ngOnInit(): void {
    this.initFaultTypeOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isViewMode) {
      if (this.jobCard._id) {
      }
    } else if (this.isCreateMode || this.isEditMode) {
      if (changes.contract && !changes.contract.isFirstChange()) {
        this.resetFaultType();
      }
    }
  }

  // ----------- API ----------- //

  // ----------- UI function ----------- //
  private resetFaultType() {
    this.faultType = null;
  }

  private initFaultTypeOptions() {
    //TODO: get options list
    this.faultTypeOptions = [
      { label: 'All Dark', value: 'All Dark' },
      { label: 'LED', value: 'LED' },
      { label: 'Push Button', value: 'Push Button' },
      { label: 'Detector Fault', value: 'Detector Fault' },
    ];
  }

  public onChangeFaultType() {
    this.errorFields['faultType'] = false;
    this.jobCard['faultType'] = this.faultType;
  }

  public validation() {
    if (!this.faultType) {
      this.errorFields['faultType'] = true;
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.missing-mandatory-fields'));
      return false;
    }
    return true;
  }

  //------------------------
  // get property
  get isCreateMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.CREATE;
  }
  get isEditMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.EDIT;
  }
  get isViewMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.VIEW;
  }
}
