import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Constants } from 'src/constants';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { ngbDateToString, stringToNgbDate } from 'src/app/core/Formatter';
import { Session } from 'src/app/core/session';
import { DropdownControl, DropdownSearch, InputControl } from '../../../shared/form.model';
import { ContractService } from 'src/app/shared/contract.service';
import { PmPlanService } from 'src/app/pm/shared/pm-plan.service';

@Component({
  selector: 'app-pm-job-search-criteria',
  templateUrl: './pm-job-search-criteria.component.html',
  styleUrls: ['./pm-job-search-criteria.component.scss'],
})
export class PmJobSearchCriteriaComponent implements OnInit, OnDestroy {
  @Output() onSearch: EventEmitter<any> = new EventEmitter();

  isSearching: boolean = false;
  isCollapse: boolean = false;
  isDisabled: boolean = false; //For Not Ready Function

  startDateNgb: any;
  endDateNgb: any;
  createTimeFromNgb: any;
  createTimeToNgb: any;

  frequencyOptions: any[];
  searchSelections: {
    contractNumber: string[];
    pmPlanNumber: string;
    pmPeriod: string;
    pmJobNumber: string;
    jobDescription: string;
    equipmentNumber: string;
    status: { [key: string]: boolean };
    scheduleType: { [key: string]: boolean };
    startRange: 'on-or-before' | 'after';
    startDate: string;
    endRange: 'on-or-before' | 'after';
    endDate: string;
    frequency: string[];
    handlingTeam: string[];
    createTimeFrom: string;
    createTimeTo: string;
    createdBy: string;
  };

  // unSubObservable
  private unSubNotifier = new Subject();
  // Team Dropdown
  private searchTeamObserver = new Subject<any[]>();
  teamOptions: any = [];
  searchTeamKeyword: string = null;
  teamPageSize: number = 100;
  teamPageNumber: number = 1;
  teamTotalPage: number;
  isLoadingTeam: boolean = false;

  enableInputPmPlan = false;
  enableInputPmPeriod = false;

  // Input
  contractInput = new PmJobSearchContractInput();
  pmPlanInput = new PmJobSearchPmPlanInput();
  pmPeriodInput = new PmJobSearchPmPeriodInput();
  equipmentInput = new PmJobSearchEquipmentInput();
  private contractSearch = new DropdownSearch();
  private pmPlanSearch = new DropdownSearch();
  private pmPeriodSearch = new DropdownSearch();
  private equipmentSearch = new DropdownSearch();

  constructor(
    private contractService: ContractService,
    private pmPlanService: PmPlanService
  ) {}

  ngOnInit(): void {
    this.initFilters();
    this.loadFilterOptionsFromLocal();
    this.onSearch.emit(this.searchSelections);
  }

  ngOnDestroy(): void {
    this.unSubNotifier.next();
    this.unSubNotifier.complete();
    Session.pmTaskListGeneralSearchCriteria = null
  }

  async initFilters() {
    this.searchSelections = {
      contractNumber: [],
      pmPlanNumber: null,
      pmPeriod: null,
      pmJobNumber: null,
      jobDescription: null,
      equipmentNumber: null,
      status: {},
      scheduleType: {},
      startRange: null,
      startDate: null,
      endRange: null,
      endDate: null,
      frequency: [],
      handlingTeam: [],
      createTimeFrom: null,
      createTimeTo: null,
      createdBy: null
    };

    let statusEnum = Object.values(JMENUM.PMJobStatus);
    for (let i = 0; i < statusEnum.length; i++) {
      this.searchSelections.status[statusEnum[i]] = false;
    }

    let scheduleEnum = Object.values(JMENUM.PMScheduleType);
    for (let i = 0; i < scheduleEnum.length; i++) {
      this.searchSelections.scheduleType[scheduleEnum[i]] = false;
    }

    let frequencyEnum = Object.values(JMENUM.PMPlanFrequency);
    this.frequencyOptions = [];
    for (let i = 0; i < frequencyEnum.length; i++) {
      this.frequencyOptions.push({
        value: frequencyEnum[i],
        label: JMLanguage.translate(`pm-plan.frequency.${frequencyEnum[i]}`),
      });
    }

    // contract list init
    this.contractSearch.search$.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      takeUntil(this.unSubNotifier),
    ).subscribe(() => {
      this.resetContractDropDown();
      this.searchContracts();
    });
    this.contractSearch.search$.next();

    this.pmPlanSearch.search$.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      takeUntil(this.unSubNotifier),
    ).subscribe(() => {
      this.resetPmPlanDropDown();
      this.searchSelections.contractNumber && this.searchPmPlans();
    });

    this.pmPeriodSearch.search$.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      takeUntil(this.unSubNotifier),
    ).subscribe(() => {
      this.resetPmPeriodDropDown();
      this.searchSelections.pmPlanNumber && this.searchPmPeriods();
    });

    this.equipmentSearch.search$.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      takeUntil(this.unSubNotifier),
    ).subscribe(() => {
      this.resetEquipmentDropDown();
      this.searchEquipments();
    });
    this.equipmentSearch.search$.next();

    // team list init
    this.searchTeamObserver.pipe(
      debounceTime(Constants.DEBOUNCE_TIME),
      takeUntil(this.unSubNotifier),
    ).subscribe(this.searchTeams.bind(this));
    this.teamOptions = await this.requestTeamList(this.teamPageNumber);
  }

  async requestTeamList(pageNumber?: number) {
    let request: JM.JMRequestContractsGetVendorTeamList = new JM.JMRequestContractsGetVendorTeamList();

    request.parameters = ['_id', 'name'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.teamPageSize;

    if (this.searchTeamKeyword) {
      request.filter = { name: this.searchTeamKeyword };
    }

    this.isLoadingTeam = true;
    let response: JM.JMResponseContractsGetVendorTeamList = await JM.JMConnector.sendJMRequest(request);
    this.isLoadingTeam = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (pageNumber === 0) {
      let totalCount = response.payload.totalCount;
      this.teamTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    return response.payload.records.map((team) => {
      return { value: team._id, label: team.name };
    });
  }


  resetFilters() {
    this.searchSelections.contractNumber = [];
    this.searchSelections.pmPlanNumber = null;
    this.searchSelections.pmPeriod = null;
    this.searchSelections.pmJobNumber = null;
    this.searchSelections.jobDescription = null;
    this.searchSelections.equipmentNumber = null;
    this.searchSelections.startRange = null;
    this.searchSelections.startDate = null;
    this.searchSelections.endRange = null;
    this.searchSelections.endDate = null;
    this.searchSelections.frequency = [];
    this.searchSelections.handlingTeam = [];
    this.searchSelections.createTimeFrom = null;
    this.searchSelections.createTimeTo = null;
    this.searchSelections.createdBy = null;

    for (const key in this.searchSelections.status) {
      this.searchSelections.status[key] = false;
    }
    // this.searchSelections.status[JMENUM.PMJobStatus.DRAFT] = true;

    for (const key in this.searchSelections.scheduleType) {
      this.searchSelections.scheduleType[key] = false;
    }
    this.startDateNgb = null; // { year: 2010, month: 1, day: 1 };
    this.endDateNgb = null; // { year: 2010, month: 1, day: 1 };
    this.createTimeFromNgb = null;
    this.createTimeToNgb = null;
  }

  // date picker handler
  onBlurDateInput(event) {
    switch(event.fieldName) {
      case 'startDate':
        this.startDateNgb = event.date;
        this.searchSelections.startDate = ngbDateToString(event.date);
        break;
      case 'endDate':
        this.endDateNgb = event.date;
        this.searchSelections.endDate = ngbDateToString(event.date);
        break;
      case 'createTimeFrom':
        this.createTimeFromNgb = event.date;
        this.searchSelections.createTimeFrom = ngbDateToString(event.date);
        break;
      case 'createTimeTo':
        this.createTimeToNgb = event.date;
        this.searchSelections.createTimeTo = ngbDateToString(event.date);
        break;
    }
  }

  // Start: Contract ng select handler
  onContractSearch(event) {
    this.contractSearch.keywords = event.term;
    this.contractSearch.search$.next();
  }

  onContractChange(event) {
    this.onPmPlanClear();
    this.onPmPeriodClear();
  }

  onContractClear() {
    this.searchSelections.contractNumber = null;

    this.contractSearch.keywords = null;
    this.contractSearch.search$.next();
  }

  async onContractScrollToEnd() {
    if (this.contractSearch.totalPageNumber && this.contractSearch.pageNumber < this.contractSearch.totalPageNumber) {
      let filter;
      this.contractSearch.pageNumber++;
      this.contractSearch.keywords && (filter = { contractNumber: this.contractSearch.keywords });

      const payload = await this.contractService.requestContractList({
        pageNumber: this.contractSearch.pageNumber,
        pageSize: this.contractSearch.pageSize,
        parameters: ['contractNumber'],
        filter,
      });

      if(payload) {
        this.contractSearch.totalPageNumber = Math.ceil(payload.totalCount / this.contractSearch.pageSize);
        this.contractInput.optionList = this.contractInput.optionList.concat(payload.records);
      }
    }
  }

  resetContractDropDown() {
    this.contractInput.optionList = [];
    this.contractSearch.totalPageNumber = null;
    this.contractSearch.pageNumber = 1;
  }

  async searchContracts() {
    let filter;
    this.contractSearch.keywords && (filter = { contractNumber: this.contractSearch.keywords });

    const payload = await this.contractService.requestContractList({
      pageNumber: this.contractSearch.pageNumber,
      pageSize: this.contractSearch.pageSize,
      parameters: ['contractNumber'],
      filter,
    });

    if(payload) {
      this.contractSearch.totalPageNumber = Math.ceil(payload.totalCount / this.contractSearch.pageSize);
      this.contractInput.optionList = payload.records;
    }
  }

  // Start: plan ng select handler
  onPmPlanSearch(event) {
    this.pmPlanSearch.keywords = event.term;
    this.pmPlanSearch.search$.next();
  }

  onPmPlanChange(event) {
    this.onPmPeriodClear();
  }

  onPmPlanClear() {
    this.searchSelections.pmPlanNumber = null;

    this.pmPlanSearch.keywords = null;
    this.pmPlanSearch.search$.next();
  }

  async onPmPlanScrollToEnd() {
    if (this.pmPlanSearch.totalPageNumber && this.pmPlanSearch.pageNumber < this.pmPlanSearch.totalPageNumber) {
      let filter;
      this.pmPlanSearch.pageNumber++;
      this.pmPlanSearch.keywords && (filter = { pmPlanNumber: this.pmPlanSearch.keywords });

      const payload = await this.pmPlanService.requestPmPlanList({
        pageNumber: this.pmPlanSearch.pageNumber,
        pageSize: this.pmPlanSearch.pageSize,
        contractNumber: this.searchSelections.contractNumber,
        parameters: ['pmPlanNumber'],
        filter,
      });

      if(payload) {
        this.pmPlanSearch.totalPageNumber = Math.ceil(payload.totalCount / this.pmPlanSearch.pageSize);
        this.pmPlanInput.optionList = this.pmPlanInput.optionList.concat(payload.records);
      }
    }
  }

  resetPmPlanDropDown() {
    this.pmPlanInput.optionList = [];
    this.pmPlanSearch.totalPageNumber = null;
    this.pmPlanSearch.pageNumber = 1;
  }

  async searchPmPlans() {
    let filter;
    this.pmPlanSearch.keywords && (filter = { pmPlanNumber: this.pmPlanSearch.keywords });

    const payload = await this.pmPlanService.requestPmPlanList({
      pageNumber: this.pmPlanSearch.pageNumber,
      pageSize: this.pmPlanSearch.pageSize,
      contractNumber: this.searchSelections.contractNumber,
      parameters: ['pmPlanNumber'],
      filter,
    });

    if(payload) {
      this.pmPlanSearch.totalPageNumber = Math.ceil(payload.totalCount / this.pmPlanSearch.pageSize);
      this.pmPlanInput.optionList = payload.records;
    }
  }

  // Start: plan period ng select handler
  onPmPeriodSearch(event) {
  }

  onPmPeriodChange(event) {
  }

  onPmPeriodClear() {
    this.searchSelections.pmPeriod = null;

    this.pmPeriodSearch.keywords = null;
    this.pmPeriodSearch.search$.next();
  }

  onPmPeriodScrollToEnd() {
  }

  resetPmPeriodDropDown() {
    this.pmPeriodInput.optionList = [];
    this.pmPeriodSearch.totalPageNumber = null;
    this.pmPeriodSearch.pageNumber = 1;
  }

  async searchPmPeriods() {
    const payload = await this.pmPlanService.requestPmPeriodList({
      pageNumber: this.pmPeriodSearch.pageNumber,
      pageSize: this.pmPeriodSearch.pageSize,
      pmPlanNumber: [this.searchSelections.pmPlanNumber],
      parameters: ['_id', 'periodStartDate', 'periodEndDate'],
    });

    if(payload) {
      this.pmPeriodSearch.totalPageNumber = Math.ceil(payload.totalCount / this.pmPeriodSearch.pageSize);
      this.pmPeriodInput.optionList = payload.records.map((record) => {
        return {
          name: `${record.periodStartDate} - ${record.periodEndDate}`,
          code: record._id,
        }
      });
    }
  }

  // Start: equipment ng select handler
  onEquipmentSearch(event) {
    this.equipmentSearch.keywords = event.term;
    this.equipmentSearch.search$.next();
  }

  onEquipmentChange(event) {
  }

  onEquipmentClear() {
    this.equipmentSearch.keywords = null;
    this.equipmentSearch.search$.next();
  }

  async onEquipmentScrollToEnd() {
    if (this.equipmentSearch.totalPageNumber && this.equipmentSearch.pageNumber < this.equipmentSearch.totalPageNumber) {
      let filter;
      this.equipmentSearch.pageNumber++;
      this.equipmentSearch.keywords && (filter = { equipmentNumber: this.equipmentSearch.keywords });

      const request = new JM.JMRequestEquipmentsEquipmentSummary();
      request.filter = filter;
      request.parameters = ['equipmentNumber', 'description'];
      request.pageSize = this.equipmentSearch.pageSize;
      request.pageNumber = this.equipmentSearch.pageNumber;
  
      this.equipmentInput.isLoading = true;
      const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
      this.equipmentInput.isLoading = false;
  
      if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
        AppDelegate.toastMsg().showResponseMsg(response);
        return;
      }

      if(response.payload) {
        this.equipmentSearch.totalPageNumber = Math.ceil(response.payload.totalCount / this.equipmentSearch.pageSize);
        this.equipmentInput.optionList = this.equipmentInput.optionList.concat(response.payload.records.map((record) => {
          return {
            name: `${record.equipmentNumber} ${record.description}`,
            code: record.equipmentNumber,
          }
        }));
      }
    }
  }

  resetEquipmentDropDown() {
    this.equipmentInput.optionList = [];
    this.equipmentSearch.totalPageNumber = null;
    this.equipmentSearch.pageNumber = 1;
  }

  async searchEquipments() {
    let filter;
    this.equipmentSearch.keywords && (filter = { equipmentNumber: this.equipmentSearch.keywords });

    const request = new JM.JMRequestEquipmentsEquipmentSummary();
    request.filter = filter;
    request.parameters = ['equipmentNumber', 'description'];
    request.pageSize = this.equipmentSearch.pageSize;
    request.pageNumber = this.equipmentSearch.pageNumber;

    this.equipmentInput.isLoading = true;
    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
    this.equipmentInput.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if(response.payload) {
      this.equipmentSearch.totalPageNumber = Math.ceil(response.payload.totalCount / this.equipmentSearch.pageSize);
      this.equipmentInput.optionList = response.payload.records.map((record) => {
        return {
          name: `${record.equipmentNumber} ${record.description}`,
          code: record.equipmentNumber,
        }
      });
    }
  }

  // Start: team ng select handler
  onSearchTeam(event) {
    this.searchTeamKeyword = event.term;
    this.searchTeamObserver.next();
  }

  clearTeamFilter() {
    this.searchTeamKeyword = null;
    this.searchTeams();
  }

  async searchTeams() {
    this.teamOptions = [];
    this.teamPageNumber = 1;
    this.teamTotalPage = null;
    this.teamOptions = await this.requestTeamList(this.teamPageNumber);
  }

  async onTeamScrollToEnd() {
    if (this.teamPageNumber < this.teamTotalPage) {
      this.teamPageNumber++;

      let teams = await this.requestTeamList(this.teamPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.teamOptions = [...this.teamOptions, ...teams];
    }
  }
  // End: team ng select handler

  // Start: search icon handler
  onClickSearch() {
    this.onSearch.emit(this.searchSelections);
    this.saveFilterOptionsToLocal();
  }

  onClickReset() {
    this.resetFilters();
    this.onSearch.emit(this.searchSelections);
    this.saveFilterOptionsToLocal();
  }
  // End: search icon handler

  private saveFilterOptionsToLocal = (): void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.searchSelections));
      delete cache.frequencyOptions;
      Session.setPmJobSettingsSearchFilters(cache);
    } catch (e) {
      console.warn(e);
    }
  };

  private loadFilterOptionsFromLocal = (): void => {
    try {
      let cache = Session.getPmJobSettingsSearchFilters();
      if (cache) {
        let cacheParsed = JSON.parse(JSON.stringify(cache));
        this.searchSelections = {
          ...this.searchSelections,
          ...cacheParsed,
        };
      }
    } catch (e) {
      console.warn(e);
    }
    
    if(Session.pmTaskListGeneralSearchCriteria && Session.pmTaskListGeneralSearchCriteria.has("status") && JMENUM.PMJobStatus.REWORKING === Session.pmTaskListGeneralSearchCriteria.get("status")){
      this.searchSelections.status[JMENUM.PMJobStatus.REWORKING] = true;
    }
    if(Session.pmTaskListGeneralSearchCriteria && Session.pmTaskListGeneralSearchCriteria.has("selectedContract") && Session.pmTaskListGeneralSearchCriteria.get("selectedContract").length>0){
      this.searchSelections.contractNumber = Session.pmTaskListGeneralSearchCriteria.get("selectedContract")
    }

    if (this.searchSelections.startDate) {
      this.startDateNgb = stringToNgbDate(this.searchSelections.startDate, 'YYYYMMDD');
    }
    if (this.searchSelections.endDate) {
      this.endDateNgb = stringToNgbDate(this.searchSelections.endDate, 'YYYYMMDD');
    }
    if (this.searchSelections.createTimeFrom) {
      this.createTimeFromNgb = stringToNgbDate(this.searchSelections.createTimeFrom, 'YYYYMMDD');
    }
    if (this.searchSelections.createTimeTo) {
      this.createTimeToNgb = stringToNgbDate(this.searchSelections.createTimeTo, 'YYYYMMDD');
    }
    if (this.searchSelections.contractNumber) {
      this.pmPlanSearch.search$.next();
    }
    if (this.searchSelections.pmPlanNumber) {
      this.pmPeriodSearch.search$.next();
    }
    if (this.searchSelections.equipmentNumber) {
      this.equipmentSearch.keywords = this.searchSelections.equipmentNumber;
      this.equipmentSearch.search$.next();
    }
  };
}

class PmJobSearchContractInput extends DropdownControl {
  value?: string; // JMOBJ.MaintenanceTermContract;
  optionList: JMOBJ.MaintenanceTermContract[] = [];

  constructor() {
    super();
  }
}

class PmJobSearchPmPlanInput extends DropdownControl {
  value?: string; // JMOBJ.PmPlan;
  optionList: JMOBJ.PmPlan[] = [];

  constructor() {
    super();
  }
}

class PmJobSearchPmPeriodInput extends DropdownControl {
  value?: string; // JMOBJ.PmPeriod;
  optionList: any[] = []; // JMOBJ.PmPeriod[] = [];

  constructor() {
    super();
  }
}

class PmJobSearchEquipmentInput extends DropdownControl {
  value?: string; // JMOBJ.PmPeriod;
  optionList: any[] = []; // JMOBJ.PmPeriod[] = [];

  constructor() {
    super();
  }
}
