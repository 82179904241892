<div class="wrapper">
  <app-nav [navbarTitle]="'pages.pm-plan-create.title' | translate"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="content cm-task-create-content action-btn-padding">
    <div class="component-wrap row">
      <div class="col-12 col-xl-6">
        <app-pm-plan-summary
          #pmPlanSummaryElem
          class="d-block mb-5"
          [plan]="plan"
          [pageMode]="pageMode"
          (onChangeContractSelection)="onChangeContractSelection()"
          [(startDateStr)]="plan.startDate"
          [(endDateStr)]="plan.endDate"
          [(secondStartDateStr)]="plan.secondStartDate"
          (onChangeSchTypeAndFreq)="onChangeSchTypeAndFreq($event)"
        ></app-pm-plan-summary>
        <app-pm-plan-particulars
          #pmPlanParticularsElem
          [plan]="plan"
          [pageMode]="pageMode"
        ></app-pm-plan-particulars>
        <ng-container *ngIf="hasPMReminderFeatureToggle">
          <app-pm-plan-reminders
            #pmPlanRemindersElem
            [plan]="plan"
            [pageMode]="pageMode"
          ></app-pm-plan-reminders>
        </ng-container>
      </div>

      <div class="col-12 col-xl-6">
        <app-pm-plan-periods
          #pmPlanPeriodsElem
          class="d-block mb-5"
          [plan]="plan"
          [pageMode]="pageMode"
          (onRefreshPeriod)="onRefreshPeriod()"
        ></app-pm-plan-periods>
        <app-pm-plan-remarks
          #pmPlanRemarksElem
          [plan]="plan"
          [pageMode]="pageMode"
        ></app-pm-plan-remarks>
      </div>
    </div>


    <!-- Action Sidebar -->
    <ng-container *ngIf="actionSidebar.dataList && actionSidebar.dataList.length > 0">
      <app-action-sidebar-v2
        [actionButtonList]="actionSidebar.dataList"
        [isDisabled]="actionSidebar.isDisabled"
        [isLoading]="actionSidebar.isLoading"
        (clickedButton)="onClickedActionButton($event)">
      </app-action-sidebar-v2>
    </ng-container>
  </div>

</div>
