import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from 'src/app/core/base';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { SidebarItems } from 'src/app/shared/sidebar/sidebar-items';
import { UserHelper } from 'src/app/core/user-helper';

@Component({
  selector: 'setting',
  templateUrl: 'settings.component.html',
  styleUrls: ['settings.component.scss'],
})
export class SettingsComponent extends BasePage implements OnInit {
  constructor(private injector: Injector) {
    super(injector, true);
  }

  ngOnInit() {
    // if (!AppDelegate.checkPagePermissions()) { return; }

    /* 
      ********************************
      Redirect to first side bar item
      ********************************
    */

    let subPages = SidebarItems['settings'][0].subItems.map((item) => item.route);

    for (let route of subPages) {
      if (UserHelper.hasPagePermission(route)) {
        AppDelegate.routeToPage(route);
        break;
      }
    }
  }
  //-------------------------------
}
