import { Component, Injector, OnInit } from '@angular/core';
import { BasePage } from 'src/app/core/base';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Util } from 'src/app/core/util';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { UserHelper } from 'src/app/core/user-helper';
import { Constants } from 'src/constants';

@Component({
  selector: 'settings-vendor-info',
  templateUrl: 'settings-vendor-info.component.html',
  styleUrls: ['settings-vendor-info.component.scss'],
})
export class SettingsVendorInfoComponent extends BasePage implements OnInit {
  readonly ActionType: any = {
    VIEW: 'VIEW',
    EDIT: 'EDIT',
  };

  actionMode: string = this.ActionType.VIEW;
  hasUpdatePermission: boolean = false;

  // ng model
  vendorDetail: any = {};
  originVendorDetail: any = {};

  postList: any[] = [];

  errorFields: string[] = [];
  mandatoryFields: string[] = ['email', 'responsibleOfficer'];
  isLoadingSubmit: boolean = false;

  constructor(private injector: Injector) {
    super(injector, true);
  }

  ngOnInit() {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }
    this.hasUpdatePermission = UserHelper.hasPermission(JMENUM.VpPermission.COMPANY_UPDATE);

    this.requestVendorDetail();
    this.requestPostList();
  }

  // -------- Request funtion -------
  requestUpdateVendor() {
    if (this.isLoadingSubmit) {
      return;
    }

    if (!this.vendorDetail.vendorNumber) {
      return;
    }

    let request = new JM.JMRequestContractsUpdateVendorInfo();
    request.vendorNumber = this.vendorDetail.vendorNumber;

    request.email = this.vendorDetail.email ? this.vendorDetail.email : null;
    request.responsibleOfficer = this.vendorDetail.responsibleOfficer ? this.vendorDetail.responsibleOfficer : null;

    this.isLoadingSubmit = true;

    JM.JMConnector.sendContractsUpdateVendorInfo(
      request,
      (error: JM.JMNetworkError, response: JM.JMResponseContractsUpdateVendorInfo) => {
        this.isLoadingSubmit = false;

        if (error) {
          AppDelegate.handleJMError(error);
          return;
        }

        if (!response || !response.code || response.code !== 200 || !response.payload) {
          this.toastMsg.showResponseMsg(response);
          return;
        }

        this.originVendorDetail = response.payload;
        this.resetVendorDetail();
        this.actionMode = this.ActionType.VIEW;

        AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.settings-vendor-info.toast.udpated'));
      }
    );
  }

  requestVendorDetail() {
    let vendorNumber = UserHelper.getVendorNumber();
    if (!vendorNumber) {
      return;
    }

    let request = new JM.JMRequestContractsVendorSummary();
    request.vendorNumber = [vendorNumber];
    request.parameters = [
      'vendorName',
      'vendorNumber',
      'postNamePrefix',
      'telephone',
      'fax',
      'email',
      'responsibleOfficer',
    ];

    JM.JMConnector.sendContractsVendorSummary(
      request,
      (error: JM.JMNetworkError, response: JM.JMResponseContractsVendorSummary) => {
        if (error) {
          AppDelegate.handleJMError(error);
          return;
        }

        if (
          !response ||
          !response.code ||
          response.code !== 200 ||
          !response.payload ||
          !response.payload.records ||
          !response.payload.records.length
        ) {
          this.toastMsg.showResponseMsg(response);
          return;
        }

        this.originVendorDetail = response.payload.records[0];
        this.resetVendorDetail();
      }
    );
  }

  requestPostList() {
    let vendorNumber = UserHelper.getVendorNumber();
    if (!vendorNumber) {
      return;
    }

    let request = new JM.JMRequestPostsPostSummary();
    request.systemName = Constants.SYSTEM_NAME;
    request.vendorNumber = [vendorNumber];
    request.active = JMENUM.RequestActive.ACTIVE;

    JM.JMConnector.sendPostsPostSummary(
      request,
      (error: JM.JMNetworkError, response: JM.JMResponsePostsPostSummary) => {
        if (error) {
          AppDelegate.handleJMError(error);
          return;
        }

        if (
          !response ||
          !response.code ||
          response.code !== 200 ||
          !response.payload ||
          !response.payload.records ||
          !response.payload.records.length
        ) {
          this.toastMsg.showResponseMsg(response);
          return;
        }

        this.postList = response.payload.records;
      }
    );
  }

  // ------- Custom funtion ---------
  resetVendorDetail() {
    if (!this.originVendorDetail) {
      return;
    }

    this.vendorDetail = {
      vendorName: this.originVendorDetail.vendorName,
      vendorNumber: this.originVendorDetail.vendorNumber,
      telephone: this.originVendorDetail.telephone,
      fax: this.originVendorDetail.fax,
      postNamePrefix: this.originVendorDetail.postNamePrefix,
      email: this.originVendorDetail.email,
      responsibleOfficer: this.originVendorDetail.responsibleOfficer,
    };
  }

  trimData() {
    let textFields = ['email'];

    for (let field of textFields) {
      if (this.vendorDetail[field]) {
        this.vendorDetail[field] = this.vendorDetail[field].trim();
      }
    }
  }

  isValidToSubmit() {
    this.errorFields = [];

    // trim data
    this.trimData();

    // check mandatory fields
    for (let field of this.mandatoryFields) {
      if (!this.vendorDetail[field]) {
        this.errorFields.push(field);
        continue;
      }
    }

    if (this.errorFields.length) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.settings-vendor-info.toast.missing-mandatory-fields'));
      return false;
    }

    // check email
    if (this.vendorDetail.email && !Util.isValidEmail(this.vendorDetail.email)) {
      this.errorFields.push('email');
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('pages.settings-vendor-info.toast.wrong-email-format'));
      return false;
    }

    return true;
  }

  // ------- Event handling function ---------
  onClickedEdit() {
    this.actionMode = this.ActionType.EDIT;
  }

  onClickedSubmit() {
    let popupDetail = {
      msg: { content: 'popup.confirm-to-save' },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            // actions
            if (!this.isValidToSubmit()) {
              return;
            }
            this.requestUpdateVendor();
          },
        },
        { name: 'global.no', handler: null },
      ],
    };
    AppDelegate.popUpDialog().open(popupDetail);
  }

  onClickedDiscard() {
    this.errorFields = [];

    this.resetVendorDetail();
    this.actionMode = this.ActionType.VIEW;
  }
}
