import { Component, OnInit } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { stringToMoment } from 'src/app/core/Formatter';
import { Session } from 'src/app/core/session';
import { TablexComponentParams } from 'src/app/shared/tablex/tablex.component';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';

@Component({
  selector: 'app-pm-outstanding-equipment-list',
  templateUrl: './pm-outstanding-equipment-list.component.html',
  styleUrls: ['./pm-outstanding-equipment-list.component.scss']
})
export class PmOutstandingEquipmentListComponent implements OnInit {
  //TableX
  tablexParam: TablexComponentParams
  tablexHeaders: { [key: string]: any }[];
  tablexSelectedColId: string[] = [
    'equipment.equipmentNumber',
    'description',
    'equipment.location',
    'equipment.locationDescription',
    'equipment.clientShortName',
    'equipment.equipmentType',
    'equipment.status',
    'pmPlanNumber',
    'period.periodStartDate',
    'period.periodEndDate',
  ];
  pmJobs: any;
  tablexPageSizeOptions = [10, 25, 100];
  tablexDefaultPageSize: number = 10;
  tablexDefaultPageNumber: number = 1;
  tablexDefaultPageCount = 0;
  tablexDefaultSortBy = 'period.periodEndDate';
  tablexDefaultSortOrder: JMENUM.SortOrder = JMENUM.SortOrder.ASC;

  searchFilters: any = null;
  searchStatus: JMENUM.PMPeriodEquipmentStatus[] = null;
  searchParams: any = null;
  locationDict: {} = {}; 
  constructor() { }

  ngOnInit(): void {
    this.initTablex();
  }
   // API
   async requestTableData(pageNumber?: number, pageSize?: number): Promise<boolean> {
    // this.selectRow(null);
    const request: JM.JMRequestVpGetPmPeriodEquipmentListByCriteria = new JM.JMRequestVpGetPmPeriodEquipmentListByCriteria();

    request.sortBy = this.tablexParam.sortBy;
    request.sortOrder = this.tablexParam.sortOrder;
    request.pageNumber = pageNumber ? pageNumber : 1;
    request.pageSize = pageSize ? pageSize : this.tablexParam.currentPageSize;
    if (this.searchFilters) {
      request.filter = this.searchFilters;
    }
    if (this.searchStatus) {
      request.statusList = this.searchStatus;
    }

    if (this.searchParams) {
      for (const key in this.searchParams) {
        request[key] = this.searchParams[key];
      }
    }

    this.tablexParam.isLoadingTable = true;
    const response: JM.JMResponseVpGetPmPeriodEquipmentListByCriteria = await AppDelegate.sendJMRequest(request);
    this.tablexParam.isLoadingTable = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return false;
    }
    this.pmJobs = response.payload.records;
    this.tablexParam.pageCount = Math.ceil(response.payload.totalCount / request.pageSize);
    this.tablexParam.currentPage = request.pageNumber;
    this.tablexParam.currentPageSize = request.pageSize;
    
    this.preRenderTable();
    return true;
  }
  private preRenderTable() {
    if (this.pmJobs.length > 0) {
      let promiseArray = [];
      const locationCodeSet: Set<string> = new Set();
      this.pmJobs
        .filter(pmJob => pmJob && pmJob.equipment.location && !this.locationDict.hasOwnProperty(pmJob.equipment.location))
        .forEach(pmJob => locationCodeSet.add(pmJob.equipment.location));
      if (locationCodeSet.size > 0) {
        promiseArray.push(this.requestLocationSummary(Array.from(locationCodeSet)));
      }
      Promise.all(promiseArray).finally(() => {
        this.renderTable();
      });
    }else {
      this.renderTable();
    }
  }
  private renderTable() {
    const lang = Session.selectedLanguage;
    this.tablexParam.headers = this.tablexHeaders.filter((col) => {
      return this.tablexParam.selectedColId.includes(col.id);
    });
    this.tablexParam.content = this.pmJobs.map((element, index)=>{
      //location
      let locationArray = [];
      if (element.equipment && element.equipment.location) {
        const description = this.locationDict[element.equipment.location];
        if (description && description[lang]) {
          locationArray.push(description[lang]);
        }
        locationArray.push(`(${element.equipment.location})`);
      }
      let {
        _id,
        equipment = {},
        pmPlan = {},
        period = {},
        status,
        updatedAt,
      } = element;
      
      let fullRow =  [
        _id,
        equipment && equipment['equipmentNumber']? equipment['equipmentNumber'] : '',
        equipment && equipment['description']? equipment['description']: '',
        equipment && equipment['location'] ?  equipment['location']  : '',
        locationArray,
        equipment && equipment['clientShortName']? equipment['clientShortName']: '',
        equipment && equipment['equipmentType']? equipment['equipmentType']: '',
        status ? JMLanguage.translate(`pm-equipment.status.${status}`) : '',
        pmPlan && pmPlan['pmPlanNumber']? {url: `/pm/plans/${pmPlan['pmPlanNumber']}`, value: pmPlan['pmPlanNumber']} : '',
        period && period['periodStartDate']? period['periodStartDate']:'',
        period && period['periodEndDate']? period['periodEndDate']:'',
      ];

      let rowFiltered = []
      fullRow.forEach((value, i) => {
        if (this.tablexParam.selectedColId.includes(this.tablexHeaders[i].id)) {
          rowFiltered.push(fullRow[i]);
        }
      });

      return rowFiltered;
    })
  }

  initTablex() {
    this.initAllHeaders();
    const sessionColId = this.loadColumnSettingsFromLocal();
    
    this.tablexParam = {
      isLoadingTable: false,
      enableStickyHeader: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableColFilter: true,
      enableSort: true,
      minifyButton: true,
      pageSizeOptions: this.tablexPageSizeOptions,
      currentPageSize: this.tablexDefaultPageSize,
      currentPage: this.tablexDefaultPageNumber,
      pageCount: this.tablexDefaultPageCount,
      fullColNameList: this.tablexHeaders.map((col) => {
        return { id: col.id, name: col.name };
      }),
      selectedColId: sessionColId || this.tablexSelectedColId,
      sortOrder: this.tablexDefaultSortOrder,
      sortBy: this.tablexDefaultSortBy,
      filter: {},
      tableRow: 'row',
      onPageNumberClicked: this.onPageNumberClicked.bind(this),
      onPageSizeClicked: this.onPageSizeClicked.bind(this),
      onColFiltered: this.onColFiltered.bind(this),
      onSortOrderChanged: this.onSortOrderChanged.bind(this),
      headers: this.tablexHeaders,
    };
  }
  initAllHeaders() {
    const tableClass='col justify-content-center align-items-center d-flex'
    this.tablexHeaders = [
      {
        id: 'objId',
        name: 'pages.pm-plan-equipment-list.table-column.id',
        enableFilter: false,
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'd-none',
      },
      {
        id: 'equipment.equipmentNumber',
        name: 'pages.pm-plan-equipment-list.table-column.equipment-id',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: tableClass,
      },
      {
        id: 'description',
        name: 'pages.pm-plan-equipment-list.table-column.description',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: tableClass,
      },
      {
        id: 'equipment.location',
        name: 'pages.pm-plan-equipment-list.table-column.location-code',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: tableClass,
      },
      {
        id: 'equipment.locationDescription',
        name: 'pages.pm-plan-equipment-list.table-column.location-description',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: tableClass,
      },
      {
        id: 'equipment.clientShortName',
        name: 'pages.pm-plan-equipment-list.table-column.client',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: tableClass,
      },
      {
        id: 'equipment.equipmentType',
        name: 'pages.pm-plan-equipment-list.table-column.equipment-type',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: tableClass,
      },
      {
        id: 'equipment.status',
        name: 'pages.pm-plan-equipment-list.table-column.equipment-status',
        enableSort: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: tableClass,
      },
      {
        id: 'pmPlanNumber',
        name: 'pages.pm-plan-list.table-column.pm-plan-no',
        enableSort: false,
        type: TablexColumnType.Hyperlink,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: tableClass,
      },
      {
        id: 'period.periodStartDate',
        name: 'pages.pm-plan-list.table-column.start-date',
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: tableClass,
      },
      {
        id: 'period.periodEndDate',
        name: 'pages.pm-plan-list.table-column.end-date',
        enableSort: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: tableClass,
      },

    ];
  }

  private saveColumnSettingsToLocal(columnSetting) {
    try {
      let cache = JSON.parse(JSON.stringify(columnSetting));
      Session.setPmOutstandingEquipmentListSettingsColumns(cache);
    } catch (e) {
      console.warn(e);
    }
  }

  private loadColumnSettingsFromLocal() {
    try {
      let cache = Session.getPmOutstandingEquipmentListSettingsColumns();
      if (cache) {
        return JSON.parse(JSON.stringify(cache));
      }
    } catch (e) {
      console.warn(e);
    }
  }
  async onAdvancedSearch(searchCriteria) {
    if (searchCriteria) {
      this.translateFilters(searchCriteria);
    }
    await this.requestTableData(1);
    this.renderTable();
  };

  async onPageNumberClicked(pageNumber: number) {
    await this.requestTableData(pageNumber);
    this.renderTable();
  }

  async onPageSizeClicked(pageSize: number) {
    await this.requestTableData(1, pageSize);
    this.renderTable();
  }

  onColFiltered(selectedColId) {
    this.tablexParam.selectedColId = selectedColId;
    this.renderTable();
    this.saveColumnSettingsToLocal(this.tablexParam.selectedColId);
  }

  async onSortOrderChanged(header, sortOrder) {
    this.tablexParam.sortBy = header ? header : this.tablexDefaultSortBy;
    this.tablexParam.sortOrder = sortOrder;
    await this.requestTableData(1);
    this.renderTable();
  }
  translateFilters(searchCriteria) {
    const filters: { [x: string]: any } = {};
    const requestParams: {[x:string]:any} = {};
    const status = [];

    searchCriteria.pmPlanNumberList && (requestParams.pmPlanNumberList = [searchCriteria.pmPlanNumberList]);
    searchCriteria.contractNumberList && searchCriteria.contractNumberList.length > 0 && (requestParams.contractNumberList = searchCriteria.contractNumberList);
    searchCriteria.isOutstanding && status.push(JMENUM.PMPeriodEquipmentStatus.OUTSTANDING)
    searchCriteria.isCompleted && status.push(JMENUM.PMPeriodEquipmentStatus.COMPLETED)
    searchCriteria.periodStartDateFrom && (requestParams.periodStartDateFrom = moment(new Date(searchCriteria.periodStartDateFrom.year,searchCriteria.periodStartDateFrom.month -1,searchCriteria.periodStartDateFrom.day)).format(Constants.API_DATE_FORMAT))
    searchCriteria.periodStartDateTo && (requestParams.periodStartDateTo = moment(new Date(searchCriteria.periodStartDateTo.year,searchCriteria.periodStartDateTo.month -1,searchCriteria.periodStartDateTo.day)).format(Constants.API_DATE_FORMAT))
    searchCriteria.periodEndDateFrom && (requestParams.periodEndDateFrom = moment(new Date(searchCriteria.periodEndDateFrom.year,searchCriteria.periodEndDateFrom.month -1,searchCriteria.periodEndDateFrom.day)).format(Constants.API_DATE_FORMAT))
    searchCriteria.periodEndDateTo && (requestParams.periodEndDateTo = moment(new Date(searchCriteria.periodEndDateTo.year,searchCriteria.periodEndDateTo.month -1,searchCriteria.periodEndDateTo.day)).format(Constants.API_DATE_FORMAT))
    searchCriteria.equipmentNumber && (requestParams.equipmentNumberList = [searchCriteria.equipmentNumber]);
    searchCriteria.equipmentDesc && (filters.equipmentDescription = searchCriteria.equipmentDesc);
    searchCriteria.locationCodeList && (requestParams.locationCodeList = [searchCriteria.locationCodeList]);
    searchCriteria.clientList && (requestParams.locationCodeList = [searchCriteria.clientList]);
    searchCriteria.equipmentType && (requestParams.equipmentTypeList = [searchCriteria.equipmentType]);

    let size = Object.keys(filters).length;
    this.searchFilters = size > 0 ? filters : null;
    this.searchParams = Object.keys(requestParams).length > 0 ? requestParams : null;
    this.searchStatus = status.length > 0 ? status : null;
  }

  private async requestLocationSummary(locationCodeList: string[]) {
    let request = new JM.JMRequestLocationsLocationSummary();
    request.active = JMENUM.RequestActive.ACTIVE;
    request.parameters = ['code', 'description', 'districtCode'];
    request.location = locationCodeList;
    request.locationOnly = true;
    request.pageSize = this.tablexDefaultPageSize;
    request.pageNumber = 1;

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    for (const location of response.payload.records) {
      this.locationDict[location.code] = location.description;
    }
  }
}
