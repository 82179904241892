<div class="wrapper">
    <app-nav></app-nav>
    <app-sidebar></app-sidebar>
  
    <!-- Page Content -->
    <div id="content" class="dashboard-content">
        <!--Event Count Layout-->
        <div class="row justify-content-start col-12 m-0 p-0">
            <div class="col-8 col-md-9 col-lg-9 col-xl-10">
              <ng-container *ngFor="let group of statisticGroups">
                <ng-container *ngIf="group.mode == 'tab'">
                  <div
                    class="col border border-secondary rounded-bottom mb-4" >
                    <ng-container *ngFor="let groupItem of group.tabGroups[group.type].subGroups">
                      <ng-container *ngTemplateOutlet="statGroup; context: {$implicit: groupItem}">
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
      
                <ng-container *ngIf="group.mode != 'tab'">
                  <div class="col border border-secondary rounded mt-4">
                    <ng-container *ngTemplateOutlet="statGroup; context: {$implicit: group}"></ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <!--Contract List-->
            <div class="work-centre-wrap col-4 col-md-3 col-lg-3 col-xl-2 p-2">
              <div class="title dropdown-label text-left text-md-center">
                {{ 'pages.dashboard.contract' | translate}}
              </div>
              <ul class="list-group work-centre-list">
                <li class="list-group-item" (change)="selectAllContract($event)">
                  <input class="form-check-input" type="checkbox" value="workCentre-all" id="workCentre-all"
                    change="selectAllContract($event)" [checked]="contractOptions.length == selectedContracts.length">
                  <label class="form-check-label select-all-label" for="workCentre-all">
                    <span class="btn" id="select-all" *ngIf="contractOptions.length != selectedContracts.length">{{
                      'pages.dashboard.select-all' | translate}}</span>
                    <span class="btn" id="unselect-all" *ngIf="contractOptions.length == selectedContracts.length">{{
                      'pages.dashboard.unselect-all' | translate}}</span>
                  </label>
                </li>
                <li class="list-group-item" *ngFor="let contract of contractOptions; let i = index">
                  <input class="form-check-input" type="checkbox" value="{{contract}}" id="contract{{i}}"
                    (change)="selectContract($event, contract)" [checked]="selectedContracts.includes(contract) ">
                  <label class="form-check-label" for="contract{{i}}">
                    {{contract}}
                  </label>
                </li>
              </ul>
            </div>
        </div>
    </div>
  
  </div>
  
  <ng-template #statGroup let-group>
    <ng-container *ngIf="authorizationService.hasPermission(group.permission)">
      <h2 class="title">{{ group.description | translate }}</h2>
      <hr class="underline" />
    </ng-container>

    
    <div class="row sn-stat-row">
      <ng-container *ngFor="let statistic of group.statistics">
        
          <div class="col-12 col-lg-6 col-xl-4 my-lg-0 my-3">
            <div class="d-flex justify-content-center">
              <img style="width:96px;height:120px" src="{{ statistic.icon }}">
              <ng-container *ngIf="statistic.recordsSplitted">
                <ng-container *ngFor="let item of statistic.value; let i = index">
                  <div *ngIf="item" class="dashboard-item ml-3" [id]="statistic.id+'_'+i"
                    (click)="clickStatistic(statistic.key, item)"
                    [ngClass]="{'sn-stat-wrap':item > 0, 'blue':i > 0 && item > 0}">
                    {{ item }}
                  </div>
                  <div *ngIf="!item" class="dashboard-item ml-3" [id]="statistic.id+'_'+i">
                    0
                  </div>
                  <div class="dashboard-item ml-3" *ngIf="i < statistic.value.length-1">/</div>
                </ng-container>
                <ng-container *ngIf="statistic.value.length <= 0">
                  <div class="dashboard-item ml-3">
                    {{ "0" }} </div>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="!statistic.recordsSplitted">
                <div class="dashboard-item ml-3" (click)="clickStatistic(statistic.key, statistic.value[0])"
                  [ngClass]="{'sn-stat-wrap':statistic.value > 0}">{{ statistic.value }}
                </div>
              </ng-container>
            </div>
            <p class="font-size-m">{{ statistic.description | translate}}</p>
          </div>
        
      </ng-container>
    </div>
  </ng-template>
  