<ng-container *ngIf="currentContractNumber">
    <div class="col-12 font-size-xl m-0 mb-2 p-0">
        <div class="d-flex justify-content-end">
            {{ currentContractNumber }}
        </div>
    </div>
    <hr class="underline" />
</ng-container>

<!-- 
<div class="col-12 m-0 mb-2">
    <div class="overflow-hidden">
        <div class="overflow-hidden d-inline-block float-right">
            <span class="red">*</span>{{ "component.contract-general.note.mandatory" | translate}}
        </div>
    </div>
</div>
<div class="col-12 font-size-xl m-0 mb-2 p-0">
    <div class="d-flex justify-content-between">
        <div>
            {{ "component.contract-general.title.general" | translate}}
        </div>
        <div class="d-flex justify-content-between">
            <ng-container *ngIf="editmode">
                <button class="mr-2" (click)="onRollBack()">
                    <i class="fas fa-eraser red"></i>
                </button>
                <button class="mr-2" (click)="onSubmitGeneral()">
                    <i class="fa fa-check amber"></i>
                </button>
            </ng-container>
            <ng-container *ngIf="contractNumber">
                <ng-container *ngIf="hasEditPermission">
                    <button class="mr-2" *ngIf="!editmode" (click)="onEdit()">
                        <i class="fa fa-pencil amber"></i>
                    </button>
                </ng-container>
                <div>
                    {{ currentContract.contractNumber }}
                </div>
            </ng-container>
        </div>
    </div>
</div>
<hr class="underline" />
<div class="row mb-3">
    <div class="col-3 mt-3 p-0 pl-4 text-right font-size-m gray-label align-self-center">
        {{ "component.contract-general.label.default-team" | translate }}
    </div>
    <div class="col-3 mt-3">
        <button [class.hyper-link]="defaultTeamName && hasRoutePermission" (click)="onClickDefaultTeam()"
            [disabled]="!defaultTeamName || !hasRoutePermission">
            <app-display-text-field id="contract-general-defaultTeamName" [textField]="defaultTeamName">
            </app-display-text-field>
        </button>
    </div>
    <div class="col-2 mt-3 text-right">
        {{ "component.contract-general.label.lift-contract" | translate }}
    </div>
    <div class="col-4 mt-3">
        <app-display-text-field id="contract-general-liftContract" *ngIf="!editmode"
            [textField]="((liftContract)?'global.yes':'global.no') | translate">
        </app-display-text-field>
        <input type="checkbox" *ngIf="editmode" [(ngModel)]="liftContract" />
    </div>
</div>

<hr class="underline w-95 mx-auto" />

<div class="row mb-4">
    <div class="col-auto mt-4 p-0 pl-5">
        <input type="checkbox" *ngIf="editmode" class="mr-2" [(ngModel)]="cmEquipmentFindBy" />
        {{ "component.contract-general.label.corrective-maintenance" | translate }}
    </div>
    <div class="col-3 mt-4">
        <app-display-text-field id="contract-general-cmEquipmentFindBy" *ngIf="!editmode"
            [textField]="'component.contract-general.equipment-find-by.'+cmEquipmentFindBy | translate ">
        </app-display-text-field>
        <div id="cmEquipmentFindBy-wrap" class="mb-2" *ngIf="editmode">
            <div class="custom-control custom-radio mb-2">
                <input type="radio" class="custom-control-input mr-1" id="cm-equipment-find-by-routing-rules"
                    name="cm-equipment-find-by-radio" value="{{equipmentFindBy.ROUTING_RULE}}"
                    [(ngModel)]="cmEquipmentFindBy">
                <label class="custom-control-label" for="cm-equipment-find-by-routing-rules">{{
                    "component.contract-general.equipment-find-by.routingRule" | translate }}</label>
            </div>
            <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input mr-1" id="cm-equipment-find-by-workcentre"
                    name="cm-equipment-find-by-radio" value="{{equipmentFindBy.WORK_CENTRE}}"
                    [(ngModel)]="cmEquipmentFindBy">
                <label class="custom-control-label" for="cm-equipment-find-by-workcentre">{{
                    "component.contract-general.equipment-find-by.workCentre" | translate }}</label>
            </div>
        </div>
    </div>
    <div class="col-auto mt-4 p-0 pl-5">
        <input type="checkbox" *ngIf="editmode" class="mr-2" [(ngModel)]="pmEquipmentFindBy" />
        {{ "component.contract-general.label.preventive-maintenance" | translate }}
    </div>
    <div class="col-3 mt-4">
        <app-display-text-field id="contract-general-pmEquipmentFindBy" *ngIf="!editmode"
            [textField]="'component.contract-general.equipment-find-by.'+pmEquipmentFindBy | translate ">
        </app-display-text-field>
        <div id="pmEquipmentFindBy-wrap" class="mb-2" *ngIf="editmode">
            <div class="custom-control custom-radio mb-2">
                <input type="radio" class="custom-control-input mr-1" id="pm-equipment-find-by-routing-rules"
                    name="pm-equipment-find-by-radio" value="{{equipmentFindBy.ROUTING_RULE}}"
                    [(ngModel)]="pmEquipmentFindBy">
                <label class="custom-control-label" for="pm-equipment-find-by-routing-rules">{{
                    "component.contract-general.equipment-find-by.routingRule" | translate }}</label>
            </div>
            <div class="custom-control custom-radio ">
                <input type="radio" class="custom-control-input mr-1" id="pm-equipment-find-by-workcentre"
                    name="pm-equipment-find-by-radio" value="{{equipmentFindBy.WORK_CENTRE}}"
                    [(ngModel)]="pmEquipmentFindBy">
                <label class="custom-control-label" for="pm-equipment-find-by-workcentre">{{
                    "component.contract-general.equipment-find-by.workCentre" | translate }}</label>
            </div>
        </div>
    </div>
    <div class="col-3 mt-5 p-0 pl-5 font-size-m gray-label">
        {{ "component.contract-general.label.reminder-recipients" | translate }}
    </div>
    <div class="col-9 mt-5 text-left">
        <app-display-text-field id="contract-general-reminderRecipients" *ngIf="!editmode"
            [textField]="reminderRecipients">
        </app-display-text-field>
        <ng-select *ngIf="editmode" [(ngModel)]="reminderRecipients" [multiple]="true" [clearable]="false"
            [ngModelOptions]="{standalone: true}" [closeOnSelect]="true" [ngStyle]="h-100" bindLabel="label"
            bindValue="value" [items]="postList" (scrollToEnd)="onReminderRecipientsScrollToEnd()">
        </ng-select>
    </div>
</div>

<div class="col-12 font-size-xl m-0 mb-2 p-0">
    <div class="d-flex justify-content-between">
        <div>
            {{ "component.contract-general.title.max-time" | translate}}
        </div>
    </div>
</div>
<hr class="underline" />
<div class="row">
    <div class="col-auto mt-3 font-size-m">
        <span class="red">*</span>{{ "component.contract-general.label.non-urgent" | translate }}
    </div>
    <div class="mt-3 text-left px-0" [class.col-1]="editmode" [class.col-auto]="!editmode">
        <app-display-text-field id="contract-general-nonUrgentMaxAckTime" *ngIf="!editmode"
            [textField]="nonUrgentMaxAckTime">
        </app-display-text-field>
        <input type="number" class="form-control" *ngIf="editmode" [(ngModel)]="nonUrgentMaxAckTime" min=1
            [class.input-error]="errorFields.nonUrgentMaxAckTime" />
    </div>
    <div class="col-auto mt-3 font-size-m">
        <span class="red">*</span>{{ "component.contract-general.label.urgent" | translate }}
    </div>
    <div class="mt-3 text-left px-0" [class.col-1]="editmode" [class.col-auto]="!editmode">
        <app-display-text-field id="contract-general-urgentMaxAckTime" *ngIf="!editmode" [textField]="urgentMaxAckTime">
        </app-display-text-field>
        <input type="number" class="form-control" *ngIf="editmode" [(ngModel)]="urgentMaxAckTime" min=1
            [class.input-error]="errorFields.urgentMaxAckTime" />
    </div>
    <div class="col-auto mt-3 font-size-m">
        <span class="red">*</span>{{ "component.contract-general.label.emergency" | translate }}
    </div>
    <div class="mt-3 text-left px-0" [class.col-1]="editmode" [class.col-auto]="!editmode">
        <app-display-text-field id="contract-general-emergencyMaxAckTime" *ngIf="!editmode"
            [textField]="emergencyMaxAckTime">
        </app-display-text-field>
        <input type="number" class="form-control" *ngIf="editmode" [(ngModel)]="emergencyMaxAckTime" min=1
            [class.input-error]="errorFields.emergencyMaxAckTime" />
    </div>

</div>
<div class="row mb-5">
    <div class="col-auto mt-3 font-size-m">
        {{ "component.contract-general.label.pm" | translate }}
    </div>
    <div class="mt-3 text-left px-0" [class.col-1]="editmode" [class.col-auto]="!editmode">
        <app-display-text-field id="contract-general-pmMaxAckTime" *ngIf="!editmode" [textField]="pmMaxAckTime">
        </app-display-text-field>
        <input type="number" class="form-control" *ngIf="editmode" [(ngModel)]="pmMaxAckTime" min=1 />
    </div>
</div>
-->