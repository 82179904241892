export const Constants = {
  SYSTEM_NAME: "CCEPVP",  // for calling api use
  SYSTEM_VERSION: "v4.0.50.1-1-g6919d79",
  DEBOUNCE_TIME: 500,  // 0.5 second
  TEXTAREA_MAX_LENGTH: 1000,
  REMARKS_TEXTAREA_MAX_LENGTH: 100,
  DATETIME_FORMAT: "YYYY-MM-DD(ddd) HH:mm",
  DATETIME_FORMAT_2: "YYYY-MM-DD HH:mm",
  DATE_FORMAT_2: "YYYY-MM-DD(ddd)",
  DATE_FORMAT: "YYYY-MM-DD",
  API_DATE_FORMAT:"YYYYMMDD",
  SUPPORT_INFO: {
    ldapId: "PMSMCADMIN/HQ/EMSD",
    email: "pmsmcadmin@emsd.gov.hk",
    tel: "2808 3713"
  },
  ATTACHMENT_SIZE: 10485760, // 10 MB
  GOOGLE_MAP_URL: "https://www.google.com/maps/search/",
};
