import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { JMENUM } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/core/session';

@Component({
  selector: 'app-gps-location-submitted-table',
  templateUrl: './gps-location-submitted-table.component.html',
  styleUrls: ['./gps-location-submitted-table.component.scss']
})
export class GpsLocationSubmittedTableComponent {

  arrivalGpsParam = undefined

  constructor(
    public dialogRef: MatDialogRef<GpsLocationSubmittedTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.data.gpsLocations.sort(this.compareTime);
    this.arrivalGpsParam = this.data
  }

  private compareTime(a, b) {
    return moment(b.reportedAt).diff(moment(a.reportedAt));
  }

  public setSubmittedTime(reportedAt: Date) {
    if (reportedAt == undefined) {
      return
    }
    return moment(reportedAt).format(Constants.DATETIME_FORMAT);
  }

  public setGpsUrl(location: any) {
    const map_url = Constants.GOOGLE_MAP_URL;
    if (location == undefined || location.coordinates == undefined) {
      return new URL(`${map_url}`);
    }

    const lng = location.coordinates[0]
    const lat = location.coordinates[1]
    
    const searchParams = new URLSearchParams();

    searchParams.set("api", "1");
    searchParams.set("query", `${lat},${lng}`);
    searchParams.set("hl", Session.selectedLanguage === JMENUM.Language.ZH ? `zh-TW` : `en`);

    return new URL(`${map_url}?${searchParams}`);
  }

  public checkIsReportingArrivalTimeNotMatchInterval(reportedAt: Date, startTime: Date, completionTime: Date) {
    if (reportedAt == undefined || startTime == undefined || completionTime == undefined) return false
    let reportedAtMoment = moment(reportedAt)
    let earliestMoment = moment(startTime).subtract({ hours: 4 });
    let latestMoment = moment(completionTime).add({ hours: 4 });
    if (reportedAtMoment < earliestMoment || reportedAtMoment > latestMoment) return true
    return false
  }

  onDirectCloseBtnClicked() {
    this.dialogRef.close();
  }
}
