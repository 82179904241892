import { Component, Injector, Input, OnInit } from '@angular/core';

@Component({
  selector: 'attachment-previewer',
  templateUrl: './attachment-previewer.component.html',
  styleUrls: ['./attachment-previewer.component.scss']
})

export class AttachmentPreviewerComponent implements OnInit {
  @Input() file: any;
  @Input() fileDetails: any;
  @Input() isLoading: boolean;
    
  fileType: AttachmentFileType;
  previewContent: string;
  attachmentFileType = AttachmentFileType;  // for html use

  constructor(private injector: Injector) {}

  ngOnInit() {}

  ngOnDestroy() {}

  ngOnChanges() {
    if (this.file) this.updatePreview();
  }

  //============================================================================
  updatePreview(file?) {
    if(!this.file) return;
    
    var mimeType = this.file.type;
    this.fileType = this.getAttachmentFileType(mimeType);

    if (this.fileType == AttachmentFileType.Text) {
      let promise = this.file.text();
      promise.then(result => {
        this.previewContent = result; 
      });
      return;
    }

    var reader = new FileReader();      
    reader.readAsDataURL(this.file); 
    reader.onload = (_event) => { 
      this.previewContent = reader.result.toString(); 
    }
  }

  getAttachmentFileType(mimeType) {
    if (mimeType.startsWith("image/")) {
      return AttachmentFileType.Image;
    }

    if (mimeType.startsWith("application/pdf")) {
      return AttachmentFileType.Pdf;
    }

    if (mimeType.startsWith("text/")) {
      return AttachmentFileType.Text;
    }

    return AttachmentFileType.Other;
  }
    
  public getFileSize(fileSize) {
    let size = fileSize / 1024;
    let unit = "KB";
    
    if (size > 1024) {
      size = size / 1024;
      unit = "MB";
    }

    return size.toFixed(2) + " " + unit;
  }
}

enum AttachmentFileType {
  Image = "Image",
  Pdf = "PDF",
  Text = "Text",
  Other = "Other"
}
