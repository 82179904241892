
<div class="contract-list-table-container">
  <div class="d-flex justify-content-between align-items-center">
    <div class="font-size-xl align-self-end">
      {{ "component.contract-list.title" | translate}}
    </div>
  
    <div>
      <div class="text-right">
        <span class="red">*</span>{{ "component.contract-list.note.expired-contract" | translate}}
      </div>
      <div class="d-flex justify-content-end">
        <button class="mr-2 font-size-xl" (click)="onClickDownload()" *ngIf="parameters.enableDownload">
          <div *ngIf="isDownloading" class="d-flex align-items-center loading-spinner spinner-small blue"></div>
          <i *ngIf="!isDownloading" class="fa fa-download blue"></i>
        </button>
        <div class="search-box" *ngIf="parameters.enableSearch">
          <input type="text" class="form-control search-input" />
          <i class="fas fa-search blue" (click)="onClickSearch()"></i>
        </div>
      </div>
    </div>
    
  </div>
  <hr class="underline" style="width: unset" />
  
  <app-tablex class="custom-tablex" style="flex: 1" [tablexParam]='tablexParam'></app-tablex>
</div>