
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { VPUserSettings } from '@enum/vp-user-settings.enum';
import { Session } from 'src/app/core/session';
import { UserHelper } from 'src/app/core/user-helper';
import { Util } from 'src/app/core/util';
import * as moment from 'moment';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Constants } from 'src/constants';
import {
  TablexColumnType,
  TablexColumnHorizontalAlign,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';

@Component({
  selector: 'app-contract-list',
  templateUrl: './contract-list.component.html',
  styleUrls: ['./contract-list.component.scss'],
})
export class ContractListComponent implements OnInit {
  @Input() hasUpdatePermission: boolean;
  @Input() parameters: {
    defaultRequestSummary: boolean;
    enableSearch: boolean;
    enableDownload: boolean;
    userSettingListName: string;
  } = {
      defaultRequestSummary: false,
      enableSearch: false,
      enableDownload: false,
      userSettingListName: VPUserSettings.VP_CONTRACT_LIST_COLUMNS, //if no specific page
    };
  @Output() onSelectContract = new EventEmitter<any>();

  selectedContract: JMOBJ.MaintenanceTermContract = new JMOBJ.MaintenanceTermContract();
  selectedContractorNumber: string;
  selectedContractList: any = undefined;

  //Status
  isDownloading: boolean = false;

  // tablex parameters
  tablexParam: {} = {};
  tablexFilter = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize = 10;
  currentPage = 1;
  pageCount = 1;
  sortBy = undefined;
  sortOrder = undefined;
  selectedIndex = undefined;
  selectedColId: any = [];
  allColHeaders: any = [];
  selectedCol: any = [];
  selectedRows: any = undefined;
  contractList: any[] = [];

  constructor() { }

  async ngOnInit(): Promise<void> {
    await this.initTableHeader();
    await this.initSelectedCol();
    await this.initTablex();
    if (this.parameters.defaultRequestSummary) {
      await this.requestContractList();
    }
  }

  onClickSearch() { }

  onClickDownload() {
    this.isDownloading = true;
  }

  public requestContractListByContractor(selectedContractorNumber) {
    if (!selectedContractorNumber) {
      this.contractList = [];
      this.selectedContractList = undefined;
      this.renderTable();
      return;
    } else {
      this.selectedContractorNumber = selectedContractorNumber;
      this.requestContractList();
    }
  }

  public requestContractListByContract(selectedContract) {
    if (!selectedContract) {
      this.contractList = [];
      this.selectedContractList = undefined;
      this.renderTable();
      return;
    } else {
      this.selectedContractList = selectedContract;
      this.requestContractList();
    }
  }

  //#region Tablex Config Function
  private async initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableSort: true,
      enableColFilter: false,
      enableSelectedRowCount: false,
      minifyButton: true,
      tableRow: 'd-flex flex-nowrap',
      tableClass: 'contract-list-table',
      tableWrapperClass: 'overflow-auto',
      enableClearFilter: true,
      sortBy: "contractNumber",
      sortOrder: -1,
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      selectedColId: this.selectedColId,
      fullColNameList: this.allColHeaders.map((col) => {
        return { id: col.id, name: col.name };
      }),
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      onRowSelected: this.onRowSelected,
      onRowUnselected: this.onRowUnselected,
      onColFiltered: this.onColFiltered,
      onSortOrderChanged: this.onSortOrderChanged,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };
  }

  private async initSelectedCol() {
    this.selectedColId = [
      'objId',
      'contractNumber',
      'title',
      'sbu',
      'snr-reviewer',
      'reviewer',
      'vendorStartDate',
      'vendorEndDate',
    ];
    let cachedUserSettings = Session.userInfo.userSettings;
    if (cachedUserSettings && cachedUserSettings[this.parameters.userSettingListName]) {
      this.selectedColId = cachedUserSettings[this.parameters.userSettingListName];
    }
    this.onColFiltered(this.selectedColId);
  }

  private async initTableHeader() {
    this.allColHeaders = [
      {
        id: 'objId',
        name: 'component.contract-list.table.title.contract-no',
        enableSort: false,
        enableFilter: false,
        filterAttribute: '_id',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'd-none ',
      },
      {
        id: 'contractNumber',
        name: 'component.contract-list.table.title.contract-no',
        enableSort: true,
        enableFilter: true,
        filterAttribute: 'contractNumber',
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col custom-column',
      },
      {
        id: 'title',
        name: 'component.contract-list.table.title.contract-title',
        enableSort: true,
        enableFilter: true,
        filterAttribute: 'title',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col custom-column min-width-200',
      },
      {
        id: 'responsibleDivision',
        name: 'component.contract-list.table.title.sbu',
        enableSort: true,
        enableFilter: true,
        filterAttribute: 'responsibleDivision',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col custom-column',
      },
      {
        id: 'responsibleSeniorEngineer',
        name: 'component.contract-list.table.title.snr-reviewer',
        enableSort: true,
        enableFilter: true,
        filterAttribute: 'responsibleSeniorEngineer',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col custom-column',
      },
      {
        id: 'responsibleEngineer',
        name: 'component.contract-list.table.title.reviewer',
        enableSort: true,
        enableFilter: true,
        filterAttribute: 'responsibleEngineer',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col custom-column',
      },
      {
        id: 'vendorStartDate',
        name: 'component.contract-list.table.title.start-date',
        enableSort: false,
        enableFilter: false,
        filterAttribute: 'vendorStartDate',
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col custom-column',
      },
      {
        id: 'vendorEndDate',
        name: 'component.contract-list.table.title.end-date',
        enableSort: false,
        enableFilter: false,
        filterAttribute: 'vendorEndDate',
        type: TablexColumnType.Html,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col custom-column',
      },
    ];
    this.selectedCol = this.allColHeaders;
  }
  //#endregion

  //#region Tablex Reaction Function
  public onFilterChanged = (event, index, header, filter) => {
    this.resetTableAction();

    for (let key in filter) {
      if (!filter[key]) {
        delete this.tablexFilter[key];
      } else {
        if (key != 'vendorStartDate' && key != 'vendorEndDate') {
          this.tablexFilter[key] = filter[key];
        } else {
          let convertedDate;
          if (filter[key].indexOf('-') >= 0) {
            convertedDate = moment(filter[key], 'YYYY-MM-DD');
          } else if (filter[key].indexOf('/') >= 0) {
            convertedDate = moment(filter[key], 'DD/MM/YYYY');
          } else {
            convertedDate = moment(filter[key], 'YYYYMMDD');
          }
          this.tablexFilter[key] = convertedDate.format('YYYYMMDD');
        }
      }
    }

    this.currentPage = 1;
    this.requestContractList();
  };

  public onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.resetTableAction();
    this.requestContractList();
  };

  public onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.resetTableAction();
    this.requestContractList();
  };

  public onRowSelected = (index, row) => {
    if (!this.contractList) {
      return;
    }
    this.selectedContract = this.contractList.find((record) => record._id == row[0]);
    this.onSelectContract.emit(this.selectedContract);
  };

  public onRowUnselected = (index, row) => {
    this.selectedContract = new JMOBJ.MaintenanceTermContract();
    this.onSelectContract.emit(new JMOBJ.MaintenanceTermContract());
  };

  public onSortOrderChanged = (sortBy, sortOrder) => {
    this.tablexParam['sortBy'] = sortBy;
    this.tablexParam['sortOrder'] = sortOrder;
    this.requestContractList();
  };

  public onColFiltered = (selectedColId) => {
    this.selectedColId = [...selectedColId];
    this.renderTable();

    if (
      !Session.userInfo['userSettings'] ||
      (Session.userInfo['userSettings'] &&
        Session.userInfo.userSettings[this.parameters.userSettingListName] != this.selectedColId)
    ) {
      this.requestUpdateUserSettings();
      let userSettings = Session.userInfo['userSettings'] ? Session.userInfo['userSettings'] : {};
      userSettings[this.parameters.userSettingListName] = this.selectedColId;
      Session.userInfo.userSettings = userSettings;
      Session.setUserInfo(Session.userInfo);
    }
  };

  public onFilterClear = () => {
    this.tablexFilter = {};
    this.tablexParam['filter'] = {};
    this.requestContractList();
  };

  public resetTableAction() {
    this.tablexParam['selectedRowIndex'] = undefined;
    this.tablexParam['highlightedRows'] = [];
    this.selectedContract = new JMOBJ.MaintenanceTermContract();
    this.onSelectContract.emit(this.selectedContract);
  }

  private renderTable() {
    if (this.contractList == null) {
      return;
    }
    // this.tablexParam['headers'] = this.allColHeaders.filter(col => {
    //   return this.selectedColId.includes(col.id);
    // });

    if (this.contractList.length > 0) {
      this.tablexParam['content'] = this.contractList.map((data) => {
        let expiredClass = '';

        let vendorEndDate = moment(data.vendorEndDate, 'YYYYMMDD');
        let today = moment().startOf('day');
        if (!today.isSameOrBefore(vendorEndDate)) {
          expiredClass = "class='red'";
        }

        let originRow = [
          data._id,
          [
            expiredClass
              ? '<span ' + expiredClass + ' >*</span>' + Util.toString(data.contractNumber)
              : Util.toString(data.contractNumber),
          ],
          Util.toString(data.title),
          Util.toString(data.responsibleDivision),
          Util.toString(data.responsibleSeniorEngineer),
          Util.toString(data.responsibleEngineer),
          Util.toString(moment(data.vendorStartDate, 'YYYYMMDD').format('DD/MM/YYYY')),
          ['<span ' + expiredClass + '>' + Util.toString(vendorEndDate.format('DD/MM/YYYY')) + '</span>'],
        ];

        let row = [];
        originRow.forEach((value, i) => {
          // if (this.selectedColId.includes(this.allColHeaders[i].id)) {
          row.push(originRow[i]);
          // }
        });
        return row;
      });
    } else {
      this.tablexParam['content'] = undefined;
    }
  }
  //#endregion

  //#region API Call Function
  private requestUpdateUserSettings() {
    let userSettings = Session.userInfo['userSettings'] ? Session.userInfo['userSettings'] : {};
    let request = new JM.JMRequestPostsUpdateUserSettings();
    request.name = Session.userInfo.name;
    request.systemName = Constants.SYSTEM_NAME;
    userSettings[this.parameters.userSettingListName] = this.tablexParam['selectedColId'];
    request.userSettings = userSettings;

    JM.JMConnector.sendPostsUpdateUserSettings(request, (error: JM.JMNetworkError, response: Object) => {
      if (error) {
        AppDelegate.toastMsg().showResponseMsg(error);
        return;
      }
    });
  }

  public async requestContractList() {
    if (!UserHelper.hasPermission(JMENUM.VpPermission.CONTRACT_VIEW)) {
      return;
    }

    this.tablexParam['isLoadingTable'] = true;

    let request = new JM.JMRequestContractsMaintenanceTermContractSummary();
    request.expired = JMENUM.RequestExpired.BOTH;
    request.pageSize = this.currentPageSize;
    request.pageNumber = this.currentPage;
    request.filter = this.tablexFilter;
    request.sortBy = this.tablexParam['sortBy'];
    request.sortOrder = this.tablexParam['sortOrder'];

    // request.vendorNumber = UserHelper.getAccessibleVendors();
    request.workCentres = Session.userWorkCentres;

    if (this.selectedContractorNumber) {
      request.vendorNumber = [this.selectedContractorNumber];
    }

    if (this.selectedContract) {
      request.contractNumber = this.selectedContractList;
    }

    JM.JMConnector.sendContractsMaintenanceTermContractSummary(
      request,
      (error: JM.JMNetworkError, response: JM.JMResponseContractsMaintenanceTermContractSummary) => {
        this.tablexParam['isLoadingTable'] = false;

        if (error) {
          AppDelegate.handleJMError(error);
          return;
        }

        if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
          AppDelegate.toastMsg().showResponseMsg(response);
          return;
        }

        this.contractList = response.payload.records;
        this.tablexParam['totalRowCount'] = response.payload.totalCount;
        this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
        this.renderTable();
      }
    );
  }

  //#endregion
}