import { Component, Input, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { JM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage, Language } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';
import { Constants } from 'src/constants';
import {
  TablexColumnFilterOption,
  TablexColumnFilterType,
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign
} from '../tablex/tablexColumnType';

@Component({
  selector: 'equipment-table-by-contract',
  templateUrl: './equipment-table-by-contract.component.html',
  styleUrls: ['./equipment-table-by-contract.component.scss']
})
export class EquipmentTableByContractComponent implements OnInit {

  @Input() contractNumber?: string;
  @Input() sn?: JMOBJ.ServiceNotification;

  @Output() equipmentChange = new EventEmitter<JMOBJ.Equipment>();

  selectedEquipment?: JMOBJ.Equipment;
  equipmentList: JMOBJ.Equipment[] = [];
  locationDescriptionMap: Map<string, JMOBJ.Location> = new Map();

  // Tablex
  tablexParam: {} = {};
  tablexFilter: any = {};
  allColHeaders: any = [];
  currentPageSize = 10;
  currentPageNumber = 1;
  pageCount = 1;
  districtOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  locationOptions: TablexColumnFilterOption = new TablexColumnFilterOption();

  // location Dropdown
  locationList: JMOBJ.Location[] = [];
  private locationSearch: {
    keywords: string;
    search$: Subject<any[]>;
    totalPageNumber: number;
    pageNumber: number;
    pageSize: number;
  } = {
      search$: new Subject<any[]>(),
      keywords: '',
      totalPageNumber: 0,
      pageNumber: 1,
      pageSize: 10,
    };


  constructor() { }

  ngOnInit(): void {
    this.requestLocationOptions();

    this.initAllTableHeader();
    this.initFilterOptions();
    this.initTablex();

    this.setLocationSearchObserver();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contractNumber && changes.contractNumber.currentValue) {
      if (this.sn?.client && this.sn?.location) {
        this.initTable();
      } else {
        this.resetTable();
      }
    }
  }

  //------------------------
  // API
  private async requestGetEquipmentsByContractNumber() {
    if (!this.contractNumber) { return; }

    let request = new JM.JMRequestVpGetEquipmentsByContract();
    request.contractNumber = this.contractNumber;
    request.pageSize = this.currentPageSize;
    request.pageNumber = this.currentPageNumber;
    request.active = 'active';

    request.filter = {};
    for (let key in this.tablexFilter) {
      if (this.tablexFilter[key]) {
        switch (key) {
          default:
            request.filter[key] = this.tablexFilter[key];
            break;
          case 'districtCode':
            request[key] = [this.tablexFilter[key]];
            break;
          case 'locationList':
            request[key] = [this.tablexFilter[key]];
            break;
        }
      }
    }

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseVpGetEquipmentsByContract = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.equipmentList = response.payload.records;
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);

    this.renderTable();
    this.getLocationDescription();
  }

  private getLocationDescription() {
    const locationCodeList = this.equipmentList
      .filter((equipment) => equipment.location && !this.locationDescriptionMap.has(equipment.location))
      .map((equipment) => equipment.location);

    if (locationCodeList.length > 0) {
      this.requestLocationDescription(locationCodeList);
    }
  }

  private async requestLocationDescription(locationCodeList: string[]) {
    let request = new JM.JMRequestLocationsLocationSummary();
    request.location = locationCodeList;
    request.includeSummary = true;
    request.pageSize = 1000;
    request.pageNumber = 1;
    request.locationOnly = true;
    request.parameters = ['code', 'description'];

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    for (const location of response.payload.records) {
      this.locationDescriptionMap.set(location.code, location);
    }

    this.renderTable();
  }

  private async requestLocationOptions() {
    let request = new JM.JMRequestLocationsLocationSummary();
    request.includeSummary = true;
    request.pageSize = this.locationSearch.pageSize;
    request.pageNumber = this.locationSearch.pageNumber;
    request.locationOnly = true;
    request.parameters = ['code', 'description'];

    if (!!this.locationSearch.keywords) {
      request.locationDescription = this.locationSearch.keywords;
    }

    this.locationOptions.isLoading = true;
    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.locationOptions.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.locationSearch.totalPageNumber = Math.ceil(response.payload.totalCount / request.pageSize);

    const options = response.payload.records.map((location) => {
      const description = location.description[Session.selectedLanguage]
        ? location.description[Session.selectedLanguage]
        : location.description.en;
      const name = `${description} (${location.code})`;
      const obj = {
        value: location.code,
        label: name,
        descriptionEn: location.description.en ? location.description.en : '',
        descriptionZh: location.description.zh ? location.description.zh : '',
      };
      return obj;
    });
    this.locationOptions.items = this.locationOptions.items.concat(options);
  }

  //------------------------
  // tablex function
  onFilterChanged = (event, index, header, filter) => {
    this.resetSelectedRow();
    this.tablexFilter = filter;
    for (let key in this.tablexFilter) {
      if (!this.tablexFilter[key]) {
        delete this.tablexFilter[key];
      }
    }
    this.currentPageNumber = 1;
    this.requestGetEquipmentsByContractNumber();
  };

  onFilterClear = () => {
    this.resetFilter();
    this.resetSelectedRow();
    this.currentPageNumber = 1;
    this.requestGetEquipmentsByContractNumber();
  };

  onPageSizeClicked = (pageSize: number) => {
    this.currentPageNumber = 1;
    this.currentPageSize = pageSize;
    this.resetSelectedRow();
    this.requestGetEquipmentsByContractNumber();
  };

  onRowClicked = (index: number, row) => {
    if (this.tablexParam['highlightedRows'][index]) {
      this.resetSelectedRow();
      this.selectedEquipment = null;
    } else {
      this.tablexParam['highlightedRows'] = []; //only selected row is highlighted
      this.tablexParam['highlightedRows'][index] = true;
      this.selectedEquipment = this.equipmentList.find((record) => record._id == row[0]);
    }

    this.equipmentChange.emit(this.selectedEquipment);
  };

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPageNumber = pageIndex;
    this.resetSelectedRow();
    this.requestGetEquipmentsByContractNumber();
  };

  private initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableColFilter: false,
      enableClearFilter: true,
      minifyButton: true,
      filter: {},
      tableRow: 'row',
      tableClass: 'equipment-list-table',
      tableWrapperClass: '',
      pageSizeOptions: [10, 25, 100],
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPageNumber,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowClicked: this.onRowClicked,
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      highlightedRows: [],
      headers: this.allColHeaders,
      // content: [],
    };
  }

  private initHeaderFilter() {
    this.tablexFilter = {
      clientShortName: this.sn.client,
      location: this.sn.location,
    };
    this.tablexParam['filter'] = this.tablexFilter;
    this.locationOptions.selectedValue = this.sn.location;
    this.locationSearch.keywords = this.sn.location;
    this.locationSearch.search$.next();
  }

  private initAllTableHeader() {
    this.allColHeaders = [
      {
        id: 'objId',
        name: '',
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'd-none ',
      },
      {
        id: 'equipmentNumber',
        name: 'component.contract-equipment-list.table-column.equipment-id',
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-2 ',
      },
      {
        id: 'description',
        name: 'component.contract-equipment-list.table-column.description',
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-3 ',
      },
      {
        id: 'districtCode',
        name: 'component.contract-equipment-list.table-column.districtCode',
        enableFilter: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.districtOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-2 ',
      },
      {
        id: 'location',
        name: 'component.contract-equipment-list.table-column.location',
        enableFilter: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.locationOptions,
        showOptionTitle: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-3 ',
      },
      {
        id: 'clientShortName',
        name: 'component.contract-equipment-list.table-column.clientShortName',
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Middle,
        class: 'col-2 ',
      },
    ];
  }

  private renderTable() {
    this.tablexParam['content'] = this.equipmentList.map((data) => {
      let locationString = data.location;
      if (this.locationDescriptionMap.has(data.location)) {
        const location = this.locationDescriptionMap.get(data.location);
        const description = location.description[Session.selectedLanguage]
          ? location.description[Session.selectedLanguage]
          : location.description.en;
        locationString = `${description} (${data.location})`;
      }
      const districtText = JMLanguage.translate(`enum.district.${data.districtCode}`);
      return [
        data._id,
        data.equipmentNumber,
        data.description,
        districtText,
        locationString,
        data.clientShortName
      ];
    });
  }

  private resetFilter() {
    this.tablexFilter = {};
    this.tablexParam['filter'] = this.tablexFilter;
  }

  private resetSelectedRow() {
    this.tablexParam['selectedRowIndex'] = undefined;
    this.tablexParam['highlightedRows'] = [];
  }

  private resetTable() {
    this.selectedEquipment = null;

    this.resetFilter();
    this.resetLocationOptions();
    this.resetSelectedRow();

    this.currentPageNumber = 1;
    this.requestGetEquipmentsByContractNumber();
  }

  private initTable() {
    this.selectedEquipment = null;

    this.initHeaderFilter();
    this.resetSelectedRow();

    this.currentPageNumber = 1;
    this.requestGetEquipmentsByContractNumber();
  }

  //------------------------
  // tablex filter dropdown function
  private initFilterOptions() {
    this.locationOptions.items = [];
    this.locationOptions.bindLabel = 'label';
    this.locationOptions.bindValue = 'value';
    this.locationOptions.change = this.onOptionFilterChanged;
    this.locationOptions.onScrollToEnd = this.onLocationScrollToEnd;
    this.locationOptions.onSearch = this.onSearchLocationOptions;
    this.locationOptions.searchFn = this.onFilterLocationOptions;


    this.districtOptions.items = JM.JMConnector.getAllDistrictCode().map((code) => {
      return {
        value: code,
        label: JMLanguage.translate(`enum.district.${code}`),
        descriptionEn: JMLanguage.translate(`enum.district.${code}`, null, Language.EN),
        descriptionZh: JMLanguage.translate(`enum.district.${code}`, null, Language.ZH)
      };
    });
    this.districtOptions.bindLabel = 'label';
    this.districtOptions.bindValue = 'value';
    this.districtOptions.change = this.onOptionFilterChanged;
    // this.districtOptions.onSearch = this.onSearchLocationOptions;
    this.districtOptions.searchFn = this.onFilterDistrictOptions;
  }

  private onOptionFilterChanged = (option, i, header) => {
    this.resetSelectedRow();
    if (!option) {
      this.resetLocationOptions();
    }

    // update equipment list
    if (header.filterDropdownOption.selectedValue) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }
    this.currentPageNumber = 1;
    this.requestGetEquipmentsByContractNumber();
  };

  private onSearchLocationOptions = (event) => {
    this.locationSearch.keywords = event.term;
    this.locationSearch.search$.next();
  };

  private onLocationScrollToEnd = () => {
    if (this.locationSearch.pageNumber < this.locationSearch.totalPageNumber) {
      this.locationSearch.pageNumber++;
      this.requestLocationOptions();
    }
  };

  private onFilterLocationOptions = (term: string, item: any) => {
    return (
      item.value.toLowerCase().includes(term.toLowerCase()) ||
      item.descriptionEn.toLowerCase().includes(term.toLowerCase()) ||
      item.descriptionZh.toLowerCase().includes(term.toLowerCase())
    );
  };

  private clearLocationOption() {
    this.locationOptions.items = [];
    this.locationSearch.pageNumber = 1;
    this.locationSearch.totalPageNumber = 0;
  }

  private setLocationSearchObserver() {
    this.locationSearch.search$.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.clearLocationOption();
      this.requestLocationOptions();
    });
  }

  private resetLocationOptions() {
    this.locationSearch.keywords = '';
    this.locationSearch.search$.next();
  }

  private onFilterDistrictOptions = (term: string, item: any) => {
    return (
      item.value == term.toLowerCase() ||
      item.descriptionEn.toLowerCase().includes(term.toLowerCase()) ||
      item.descriptionZh.toLowerCase().includes(term.toLowerCase())
    );
  };

}
