<div class="d-flex justify-content-between" >
  <div class="font-size-xl">
    <div class="section-title d-inline">{{ 'component.cm-task-description.title' | translate }}</div>
    <div *ngIf="isViewMode || isEditMode" class="contract-number-tag d-inline ml-3">{{ jobCard?.contract?.contractNumber }}</div>
  </div>
  <div *ngIf="isViewMode || isEditMode" class="font-size-xl">
    {{ jobStatusString }}
  </div>
  <ng-select *ngIf="isCreateMode" 
    class="col-4 col-xl-3 px-0 mb-2"
    id="edit-cm-task-description-contract" 
    bindLabel="contractNumber"
    [class.input-error]="contractInput.isError"
    [loading]="contractInput.isLoading"
    [clearable]="true"
    [items]="contractInput.optionList"
    [(ngModel)]="contractInput.value"
    (scrollToEnd)="onContractScrollToEnd()"
    (search)="onSearchContract($event)" 
    (change)="onChangedContract()"
    (clear)="onClearContract()"
  >
    <ng-template ng-option-tmp let-item="item">
      <div [title]="item.contractNumber">{{item.contractNumber}}</div>
    </ng-template>
  </ng-select>
</div>
<hr class="underline" />

<div class="row pt-3 mb-3 mx-0 align-items-center position-relative">
  <div class="mandatory-label font-size-m">
    <span class="red">*</span>
    {{ 'global.mandatory-fields' | translate }}
  </div>
  <div class="mandatory-complete-label font-size-m">
    <span class="red">#</span>
    {{ 'global.mandatory-fields-complete' | translate }}
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    <span *ngIf="equipmentInput.isMandatory" class="red">*</span>
    {{ 'component.cm-task-description.label.equipmentId' | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4 equipment-input-wrap">
    <ng-container *ngIf="isCreateMode || isEditMode; else displayEquipmentText">
      <input type="text"
        class="form-control date-picker-input-box"
        id="cm-equipment-info-equipment-input"
        [disabled]="!contractInput.value"
        [class.input-error]="equipmentInput.isError"
        [(ngModel)]="equipmentNumber"
        (click)="onClickEquipmentInputBox()"
        READONLY
        />
      <button class="btn clear-icon" type="button"
        [class.d-none]="!contractInput.value"
        (click)="onClearEquipment()">
          <i class="fa fa-trash-o" aria-hidden="true"></i>
      </button>
    </ng-container>
    <ng-template #displayEquipmentText>
      <app-display-text-field id="cm-task-description-equipment-id" [textField]="jobCard?.equipment">
      </app-display-text-field>
    </ng-template>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label"></div>
  <div class="col-9 col-md-4 mt-4"></div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.cm-equipment-info.label.description" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="cm-equipment-info-description" [textField]="equipmentDescription">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.cm-equipment-info.label.owner" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="cm-equipment-info-owner" [textField]="equipmentOwner">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ "component.cm-equipment-info.label.functional-location" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="cm-equipment-info-functional-location" [textField]="equipmentFunctionalLocation">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    <span *ngIf="contractSeverityInput.isMandatory" class="red">*</span>
    {{ 'component.cm-task-description.label.severity' | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <ng-container *ngIf="isCreateMode || isEditMode; else displaySeverityText">
      <ng-select class="px-0"
        id="edit-cm-task-description-severity"
        bindLabel="name"
        [class.input-error]="contractSeverityInput.isError"
        [loading]="contractSeverityInput.isLoading"
        [clearable]="true"
        [disabled]="!equipmentInput.value"
        [items]="contractSeverityInput.optionList"
        [(ngModel)]="contractSeverityInput.value"
        (scrollToEnd)="onContractSeverityScrollToEnd()"
        (search)="onSearchContractSeverity($event)" 
        (change)="onChangedContractSeverity()"
        (clear)="onClearContractSeverity()"
      >
      </ng-select>
      <div *ngIf="severityDescription" class="mt-2 px-2 font-italic" id="edit-cm-task-description-severity-description">
        {{ severityDescription }}
      </div>
    </ng-container>
    <ng-template #displaySeverityText>
      <app-display-text-field id="cm-task-description-severity" [textField]="jobCard?.contractSeverity?.name">
      </app-display-text-field>
      <div *ngIf="jobCard?.contractSeverity?.description" class="mt-2 px-2 font-italic" id="cm-task-description-severity-description">
        {{ jobCard?.contractSeverity?.description }}
      </div>
    </ng-template>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    <span *ngIf="taskDescriptionInput.isMandatory" class="red">*</span>
    {{ 'component.cm-task-description.label.task-description' | translate }}
  </div>
  <div class="col-9 col-md-10 mt-4">
    <ng-container *ngIf="isCreateMode || isEditMode; else displayTaskDescriptionText">
      <textarea id="edit-cm-task-description-task-desc" class="form-control" rows="3" [maxlength]="textAreaMaxLength"
        [class.input-error]="taskDescriptionInput.isError" [(ngModel)]="taskDescriptionInput.value"
      ></textarea>
    </ng-container>
    <ng-template #displayTaskDescriptionText>
      <app-display-text-field id="cm-task-description-task-desc" [textField]="jobCard?.jobDescription">
      </app-display-text-field>
    </ng-template>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label ">
    <span *ngIf="vendorTeamInput.isMandatory" class="red">*</span>
    {{ 'component.cm-task-description.label.handled-by' | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <ng-container *ngIf="isCreateMode; else displayVendorTeamText">
      <ng-select class="px-0" id="edit-cm-task-description-handled-by" [class.input-error]="vendorTeamInput.isError"
        [loading]="vendorTeamInput.isLoading"
        [clearable]="true"
        [disabled]="!contractInput.value"
        [items]="vendorTeamInput.optionList" bindLabel="name" [(ngModel)]="vendorTeamInput.value"
        (scrollToEnd)="onVendorTeamScrollToEnd()" (search)="onSearchVendorTeam($event)" 
        (change)="onChangedVendorTeam()"  (clear)="onClearVendorTeam()"
      >
      </ng-select>
    </ng-container>
    <ng-template #displayVendorTeamText>
      <app-display-text-field id="cm-task-description-handled-by" [textField]="jobCard?.vendorTeam?.name">
      </app-display-text-field>
    </ng-template>
  </div>
</div>

<div class="row mb-3 mx-0 align-items-center" *ngIf="isViewMode || isEditMode">
  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ 'component.cm-task-description.label.created-time' | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="cm-task-description-created-time" [textField]="jobCard?.createdAt | dateTimeWeek">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label ">
    {{ 'component.cm-task-description.label.created-by' | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="cm-task-description-created-by" [textField]="jobCard?.createdBy">
    </app-display-text-field>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    {{ 'component.cm-task-description.label.last-updated-time' | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <app-display-text-field id="cm-task-description-last-updated-time" [textField]="jobCard?.updatedAt | dateTimeWeek">
    </app-display-text-field>
  </div>
</div>

<app-custom-slider-panel [widthPx]=1000>
  <app-cm-equipment-selector
    [jobCard]="jobCard"
    [sn]="sn"
    [contract]="contractInput.value"
    [pageMode]="pageMode"
    (updatedEquipment)="onUpdatedEquipment($event)">
  </app-cm-equipment-selector>
</app-custom-slider-panel>
