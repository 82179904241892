<div class="wrapper">
  <app-nav [navbarTitle]="'pages.settings-user-profile.title' | translate"></app-nav>
  <app-sidebar [contentType]="'settings'"></app-sidebar>

  <div id="content" class="content">
    
    <app-tab-menu [contentType]="'settings-general'"></app-tab-menu>

    <div class="row">
      <div class="col-xl-5">
        <div class="row justify-content-between mx-0">
          <div class="title">{{ 'pages.settings-user-profile.title.account-information' | translate }}</div>
          <div class="row justify-content-right mx-0">
            <div *ngIf="actionMode === ActionType.EDIT" class="pt-2 pr-2"><span class="red pr-1">*</span>{{ 'global.mandatory-fields' | translate }}</div>
            <app-icon-button *ngIf="actionMode === ActionType.VIEW && hasUpdatePermission"
              [id]="'user-profile-edit-button'"
              [color]="'blue'"
              [icon]="'fa-edit'"
              (onClick)="onClickedEdit()" 
            >
            </app-icon-button>
            <app-icon-button *ngIf="actionMode === ActionType.EDIT"
              [id]="'user-profile-dicard-button'"
              [color]="'red'"
              [icon]="'fa-times'"
              (onClick)="onClickedDiscard()" 
            >
            </app-icon-button>
            <app-icon-button *ngIf="actionMode === ActionType.EDIT"
              [id]="'user-profile-submit-button'"
              [color]="'amber'"
              [icon]="'fa-check'"
              [isLoading]="isLoadingSubmit"
              (onClick)="onClickedSubmit()" 
            >
            </app-icon-button>
          </div>
        </div>
        <hr class="underline" />
        <div class="my-3">
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="user-profile-username-text">
              {{ 'pages.settings-user-profile.account' | translate }}
            </label>
            <div id="user-profile-username-text" class="px-3 col-7">{{ userName }}</div>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="user-profile-roles-text">
              {{ 'pages.settings-user-profile.roles' | translate }}
            </label>
            <div id="user-profile-roles-text" class="px-3 col-7">{{ roleText }}</div>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="user-profile-employee-name-input">
              <span *ngIf="mandatoryFields.includes('employeeName') && actionMode === ActionType.EDIT" class="red">*</span>
              {{ 'pages.settings-user-profile.employee-name' | translate }}
            </label>
            <div class="px-3 col-7">
              <input *ngIf="actionMode === ActionType.EDIT" id="user-profile-employee-name-input" type="text" class="form-control" maxlength="150" [(ngModel)]="userInfo.employeeName" 
              [ngClass]="{'input-error': errorFields.includes('employeeName')}" [disabled]="!isLocalAccount"/>
              <div *ngIf="actionMode === ActionType.VIEW">{{ userInfo.employeeName }}</div>
            </div>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="user-profile-email-input">
              <span *ngIf="mandatoryFields.includes('email') && actionMode === ActionType.EDIT" class="red">*</span>
              {{ 'pages.settings-user-profile.email' | translate }}
            </label>
            <div class="px-3 col-7">
              <input *ngIf="actionMode === ActionType.EDIT" id="user-profile-email-input" type="text" class="form-control" [(ngModel)]="userInfo.email" [ngClass]="{'input-error': errorFields.includes('email')}"
              [disabled]="!isLocalAccount"/>
              <div *ngIf="actionMode === ActionType.VIEW">{{ userInfo.email }}</div>
            </div>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="user-profile-phone-input">
              <span *ngIf="mandatoryFields.includes('phone') && actionMode === ActionType.EDIT" class="red">*</span>
              {{ 'pages.settings-user-profile.phone' | translate }}
            </label>
            <div class="px-3 col-7">
              <input *ngIf="actionMode === ActionType.EDIT" id="user-profile-phone-input" type="text" class="form-control" maxlength="8" (keyup)="inputNumber($event)" [(ngModel)]="userInfo.phone"
               [ngClass]="{'input-error': errorFields.includes('phone')}" [disabled]="!isLocalAccount"/>
              <div *ngIf="actionMode === ActionType.VIEW">{{ userInfo.phone }}</div>
            </div>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="user-profile-mobile-input">
              <span *ngIf="mandatoryFields.includes('mobile') && actionMode === ActionType.EDIT" class="red">*</span>
              {{ 'pages.settings-user-profile.mobile' | translate }}
            </label>
            <div class="px-3 col-7">
              <input *ngIf="actionMode === ActionType.EDIT" id="user-profile-mobile-input" type="text" class="form-control" maxlength="8" (keyup)="inputNumber($event)" [(ngModel)]="userInfo.mobile"
               [ngClass]="{'input-error': errorFields.includes('mobile')}" [disabled]="!isLocalAccount"/>
              <div *ngIf="actionMode === ActionType.VIEW">{{ userInfo.mobile }}</div>
            </div>
          </div>
          <div *ngIf="actionMode === ActionType.EDIT" class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="user-profile-old-password-input">
              <span *ngIf="mandatoryFields.includes('oldPassword') && actionMode === ActionType.EDIT" class="red">*</span>
              {{ 'pages.settings-user-profile.old-password' | translate }}
            </label>
            <div class="px-3 col-7">
              <input id="user-profile-old-password-input" type="password" class="form-control" autocomplete="new-password" [(ngModel)]="userInfo.oldPassword" [ngClass]="{'input-error': errorFields.includes('oldPassword')}"
              [disabled]="!isLocalAccount"/>
            </div>
          </div>
          <div *ngIf="actionMode === ActionType.EDIT" class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="user-profile-new-password-input">
              <span *ngIf="mandatoryFields.includes('newPassword') && actionMode === ActionType.EDIT" class="red">*</span>
              {{ 'pages.settings-user-profile.new-password' | translate }}
            </label>
            <div class="px-3 col-7">
              <input id="user-profile-new-password-input" type="password" class="form-control" autocomplete="new-password" [(ngModel)]="userInfo.newPassword" [ngClass]="{'input-error': errorFields.includes('newPassword')}"
              [disabled]="!isLocalAccount"/>
            </div>
          </div>
          <div *ngIf="actionMode === ActionType.EDIT" class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="user-profile-confirm-password-input">
              <span *ngIf="mandatoryFields.includes('confirmationPassword') && actionMode === ActionType.EDIT" class="red">*</span>
              {{ 'pages.settings-user-profile.confirm-password' | translate }}
            </label>
            <div class="px-3 col-7">
              <input id="user-profile-confirm-password-input" type="password" class="form-control" autocomplete="new-password" [(ngModel)]="userInfo.confirmationPassword" 
              [ngClass]="{'input-error': errorFields.includes('confirmationPassword')}" [disabled]="!isLocalAccount"/>
            </div>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-5 mb-0 px-4 text-right" for="user-profile-reminder-language-radio-wrap">
              <span *ngIf="mandatoryFields.includes('reminderLanguage') && actionMode === ActionType.EDIT" class="red">*</span>
              {{ 'pages.settings-user-profile.reminder-language' | translate }}
            </label>
            <div *ngIf="actionMode === ActionType.VIEW" class="px-3">
              <div *ngIf="userInfo.reminderLanguage === 'en' || !userInfo.reminderLanguage">{{ 'pages.settings-user-profile.english' | translate }}</div>
              <div *ngIf="userInfo.reminderLanguage === 'zh'">{{ 'pages.settings-user-profile.chinese' | translate }}</div>
            </div>
            <div *ngIf="actionMode === ActionType.EDIT" id="user-profile-reminder-language-radio-wrap" class="px-3 col-7 d-flex mb-2">
              <div class="custom-control custom-radio col-4">
                <input type="radio" class="custom-control-input mr-1" id="user-profile-reminder-language-english" name="user-profile-reminder-language-radio"
                  value="en" [(ngModel)]="userInfo.reminderLanguage">
                <label class="custom-control-label" for="user-profile-reminder-language-english">{{ 'pages.settings-user-profile.english' | translate }}</label>
              </div>
              <div class="custom-control custom-radio">
                <input type="radio" class="custom-control-input mr-1" id="user-profile-reminder-language-chinese" name="user-profile-reminder-language-radio"
                  value="zh" [(ngModel)]="userInfo.reminderLanguage">
                <label class="custom-control-label" for="user-profile-reminder-language-chinese">{{ 'pages.settings-user-profile.chinese' | translate }}</label>
              </div>
            </div>
          </div>

        </div>
        <div [ngClass]="{hidden: !showPasswordError}" class="m-3">
            <app-password-error-list #passwordErrorList>
            </app-password-error-list>
        </div>
      </div>

      <div class="col-xl-7 pl-4">
        <div class="title">{{ 'pages.settings-user-profile.title.team-information' | translate }}</div>
        <hr class="underline" />
        <app-tablex id="user-profile-team-info-table" [tablexParam]='tablexParam'></app-tablex>
      </div>
    </div>

  </div>

</div>
