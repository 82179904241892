import { Component, Injector, OnInit } from '@angular/core';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { DashboardStatisticType } from 'src/app/entity/enum/dashboard-statistic-type';
import { BasePage } from 'src/app/core/base';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Session } from 'src/app/core/session';
import { ContractService } from 'src/app/shared/contract.service';
import * as moment from 'moment';
import { Constants } from 'src/constants';
import { content } from 'html2canvas/dist/types/css/property-descriptors/content';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BasePage implements OnInit {

  statistics = [
    {
      id: "dashboard_sn-pending-ack-count_text",
      description: 'pages.dashboard.sn.pending-acknowledge',
      key: DashboardStatisticType.SN_PENDING_ACK,
      permission: [],
      icon: '../assets/svg/dashboard_mobile_pending_fault.svg', value: [], records: [[], []], recordsSplitted: true
    },
    {
      id: "dashboard_sn-further-action-required-count_text",
      description: 'pages.dashboard.sn.further-action-required',
      key: DashboardStatisticType.SN_FURTHER_ACTION_REQUIRED,
      permission: [],
      icon: '../assets/svg/icon_further_job_action.svg', value: [], records: [[], []], recordsSplitted: true
    },
    {
      id: "dashboard_cm-task-in-progress-count",
      description: 'pages.dashboard.cm-task.in-progress',
      key: DashboardStatisticType.CMTASK_IN_PROGRESS,
      permission: [],
      icon: '../assets/svg/icon_to_ack_96px.svg',
      value: [],
      records: [[], []],
      recordsSplitted: true,
    },
    {
      id: "dashboard_cm-task-rejected-count",
      description: 'pages.dashboard.cm-task.rejected',
      key: DashboardStatisticType.CMTASK_REJECTED,
      permission: [],
      icon: '../assets/svg/icon_non_urgent_96px.svg',
      value: [],
      records: [[], []],
      recordsSplitted: true,
    },
    {
      id: "dashboard_pm-task-outstanding-equipment-count",
      description: 'pages.dashboard.pm-task.outstanding-equipment',
      key: DashboardStatisticType.PMTASK_OUTSTANDING_EQUIPMENT,
      permission: [],
      icon: '../assets/svg/icon_to_ack_96px.svg',
      value: [],
      records: [[], []],
      recordsSplitted: true,
    },
    {
      id: "dashboard_pm-task-job-rejected-count",
      description: 'pages.dashboard.pm-task.job-rejected',
      key: DashboardStatisticType.PMTASK_JOB_REJECTED,
      permission: [],
      icon: '../assets/svg/icon_non_urgent_96px.svg',
      value: [],
      records: [[], []],
      recordsSplitted: true,
    },
    {
      id: "dashboard_pm-task-overdue-equipment-count",
      description: 'pages.dashboard.pm-task.overdue-equipment',
      key: DashboardStatisticType.PMTASK_OVERDUE_EQUIPMENT,
      permission: [],
      icon: '../assets/svg/icon_urgent_96px.svg',
      value: [],
      records: [[], []],
      recordsSplitted: true,
    },
  ]

  statisticGroups = [
    {
      mode: 'tab',
      type: 'sn',
      tabs: [
        { key: 'sn', text: 'pages.dashboard.tab.sn-job' },
        { key: 'pm', text: 'pages.dashboard.tab.pm' },
      ],
      tabGroups: {
        'sn': {
          permission: [JMENUM.Permission.SN_VIEW, JMENUM.Permission.DASHBOARD_SBU],
          subGroups: [
            {
              type: 'sn',
              description: 'pages.dashboard.service-notification',
              permission: JMENUM.Permission.SN_VIEW,
              statistics: [
                this.statistics[DashboardStatisticType.SN_PENDING_ACK],
                this.statistics[DashboardStatisticType.SN_FURTHER_ACTION_REQUIRED]
              ]
            },
          ]
        },
      },
    }, {
      type: 'cm',
      description: 'pages.dashboard.cm-task',
      permission: JMENUM.VpPermission.CMTASK_VIEW,
      statistics: [
        this.statistics[DashboardStatisticType.CMTASK_IN_PROGRESS],
        this.statistics[DashboardStatisticType.CMTASK_REJECTED],
      ]
    }, {
      type: 'pm',
      description: 'pages.dashboard.pm-task',
      permission: JMENUM.VpPermission.PMJOB_VIEW,
      statistics: [
        this.statistics[DashboardStatisticType.PMTASK_OUTSTANDING_EQUIPMENT],
        this.statistics[DashboardStatisticType.PMTASK_JOB_REJECTED],
        this.statistics[DashboardStatisticType.PMTASK_OVERDUE_EQUIPMENT],
      ]
    }
  ];
  searchKeywords: string;
  sbuPermission: boolean = false;
  selectedContracts: any[] = [];
  contractOptions: string[] = [];
  contractService: ContractService;

  apiPageSize = 1000;

  constructor(injector: Injector) {
    super(injector);
  }

  async ngOnInit() {
    this.checkAndShowChangePWReminder();
    await this.getContractList();
  }

  setSession() {
    let matchedContractList = (Session.dashboardSelectedContract) ? Session.dashboardSelectedContract.filter(contract => { return this.contractOptions.includes(contract) }) : [];
    if (matchedContractList && matchedContractList.length > 0) {
      this.selectedContracts = matchedContractList;
    } else {
      this.selectedContracts = [...this.contractOptions];
    }

    Session.setDashboardSelectedContract(this.selectedContracts)
    this.getDashboardInformation();
  }

  getDashboardInformation() {
    this.getPendingAckStatistic();
    this.getWaitingForCreateJobStatistic();
    this.getCmTaskInprogressStatistic();
    this.getCmTaskRejectedStatistic();
    this.getPmOutstandingEquipmentStatistic();
    this.getPmJobRejectedStatistic();
    this.getPmOverdueEquipmentStatistic();
  }

  async getContractList() {
    const request = new JM.JMRequestContractsGetContractList();
    request.isPmsmcContract = true;
    request.expired = 'viewable';
    request.pageSize = this.apiPageSize;

    const response: JM.JMResponseContractsGetContractList = await AppDelegate.sendJMRequest(request);
    if (response?.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    const contractNumbers = new Set(response.payload.records.map(record => record.contractNumber));
    this.contractOptions = Array.from(contractNumbers);
    this.setSession()
  }

  async getPendingAckStatistic() {
    if (!this.selectedContracts || this.selectedContracts.length === 0) {
      this.statistics[DashboardStatisticType.SN_PENDING_ACK].value = [];
      this.statistics[DashboardStatisticType.SN_PENDING_ACK].records = [[], []];
      return;
    }

    let currentPage = 1;
    let totalCount;
    const payload = await this.getPendingAckSnNo(currentPage);
    if (payload) {
      totalCount = payload.totalCount;
    }
    this.statistics[DashboardStatisticType.SN_PENDING_ACK].value[0] = totalCount;
  }

  async getPendingAckSnNo(pageNumber = 0) {
    let statusList = []
    statusList.push(JMENUM.SnStatus.PENDING_ACKNOWLEDGE)
    statusList.push(JMENUM.SnStatus.NO_RESPONSE)

    const request = new JM.JMRequestSnGetVpSnList();
    request.contractNumberList = this.selectedContracts;
    request.statusList = statusList;
    request.pageSize = this.apiPageSize;
    request.pageNumber = pageNumber;
    request.parameters = ["snNumber"];

    const response: JM.JMResponseSnGetVpSnList = await AppDelegate.sendJMRequest(request);
    if (response?.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    return response.payload;
  }

  async getWaitingForCreateJobStatistic() {
    if (!this.selectedContracts || this.selectedContracts.length === 0) {
      this.statistics[DashboardStatisticType.SN_FURTHER_ACTION_REQUIRED].value = [];
      this.statistics[DashboardStatisticType.SN_FURTHER_ACTION_REQUIRED].records = [[], []];
      return;
    }

    let currentPage = 1;
    let totalCount;
    const payload = await this.getAcknowledgeSnNo(currentPage);
    if (payload) {
      totalCount = payload.totalCount;
    }
    this.statistics[DashboardStatisticType.SN_FURTHER_ACTION_REQUIRED].value[0] = totalCount;
  }

  async getAcknowledgeSnNo(pageNumber = 0) {
    let statusList = []
    statusList.push(JMENUM.SnStatus.ACKNOWLEDGED)
    const request = new JM.JMRequestSnGetVpSnList();
    request.contractNumberList = this.selectedContracts;
    request.statusList = statusList;
    request.pageSize = this.apiPageSize;
    request.pageNumber = pageNumber;
    request.parameters = ["snNumber"];

    const response: JM.JMResponseSnGetVpSnList = await AppDelegate.sendJMRequest(request);
    if (response?.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    return response.payload;
  }

  async getCmTaskInprogressStatistic() {
    if (!this.selectedContracts || this.selectedContracts.length === 0) {
      this.statistics[DashboardStatisticType.CMTASK_IN_PROGRESS].value = [];
      this.statistics[DashboardStatisticType.CMTASK_IN_PROGRESS].records = [[], []];
      return;
    }

    let currentPage = 1;
    let totalCount;
    const payload = await this.getCmTaskInprogress(currentPage);
    if (payload) {
      totalCount = payload.totalCount;
    }
    this.statistics[DashboardStatisticType.CMTASK_IN_PROGRESS].value[0] = totalCount;
  }

  async getCmTaskInprogress(pageNumber = 0) {
    const request = new JM.JMRequestJobCardsGetCmTaskList();
    request.contractNumberList = this.selectedContracts;
    request.statusList = [JMENUM.JobCardStatus.IN_PROGRESS];
    request.pageSize = this.apiPageSize;
    request.pageNumber = pageNumber;
    request.parameters = ["jobCardNumber"];

    const response: JM.JMResponseSnGetVpSnList = await AppDelegate.sendJMRequest(request);
    if (response?.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    return response.payload;
  }

  async getCmTaskRejectedStatistic() {
    if (!this.selectedContracts || this.selectedContracts.length === 0) {
      this.statistics[DashboardStatisticType.CMTASK_REJECTED].value = [];
      this.statistics[DashboardStatisticType.CMTASK_REJECTED].records = [[], []];
      return;
    }

    let currentPage = 1;
    let totalCount;
    const payload = await this.getCmTaskRejected(currentPage);
    if (payload) {
      totalCount = payload.totalCount;
    }
    this.statistics[DashboardStatisticType.CMTASK_REJECTED].value[0] = totalCount;
  }

  async getCmTaskRejected(pageNumber = 0) {
    const request = new JM.JMRequestJobCardsGetCmTaskList();
    request.contractNumberList = this.selectedContracts;
    request.statusList = [JMENUM.JobCardStatus.REWORKING];
    request.pageSize = this.apiPageSize;
    request.pageNumber = pageNumber;
    request.parameters = ["jobCardNumber"];

    const response: JM.JMResponseSnGetVpSnList = await AppDelegate.sendJMRequest(request);
    if (response?.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    return response.payload;
  }

  async getPmOutstandingEquipmentStatistic() {
    if (!this.selectedContracts || this.selectedContracts.length === 0) {
      this.statistics[DashboardStatisticType.PMTASK_OUTSTANDING_EQUIPMENT].value = [];
      this.statistics[DashboardStatisticType.PMTASK_OUTSTANDING_EQUIPMENT].records = [[], []];
      return;
    }

    let currentPage = 1;
    let totalCount;
    const payload = await this.getPmOutstandingEquipment(currentPage);
    if (payload) {
      totalCount = payload.totalCount;
    }
    this.statistics[DashboardStatisticType.PMTASK_OUTSTANDING_EQUIPMENT].value[0] = totalCount;
  }

  async getPmOutstandingEquipment(pageNumber = 0) {
    let today = moment().startOf('day');
    const request = new JM.JMRequestVpGetPmPeriodEquipmentListByCriteria();
    request.contractNumberList = this.selectedContracts;
    request.statusList = [JMENUM.PMPeriodEquipmentStatus.OUTSTANDING];
    request.periodStartDateTo = today.format(Constants.API_DATE_FORMAT);
    request.pageSize = this.apiPageSize;
    request.pageNumber = pageNumber;

    const response: JM.JMResponseVpGetPmPeriodEquipmentListByCriteria = await AppDelegate.sendJMRequest(request);
    if (response?.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    return response.payload;
  }
  async getPmJobRejectedStatistic() {
    if (!this.selectedContracts || this.selectedContracts.length === 0) {
      this.statistics[DashboardStatisticType.PMTASK_JOB_REJECTED].value = [];
      this.statistics[DashboardStatisticType.PMTASK_JOB_REJECTED].records = [[], []];
      return;
    }

    let currentPage = 1;
    let totalCount;
    const payload = await this.getPmJobRejected(currentPage);
    if (payload) {
      totalCount = payload.totalCount;
    }
    this.statistics[DashboardStatisticType.PMTASK_JOB_REJECTED].value[0] = totalCount;
  }

  async getPmJobRejected(pageNumber = 0) {
    const request = new JM.JMRequestVpGetPmJobList();
    request.pmPlanContractNumbers = this.selectedContracts;
    request.status = [JMENUM.PMJobStatus.REWORKING];
    request.pageSize = this.apiPageSize;
    request.pageNumber = pageNumber;

    const response: JM.JMResponseVpGetPmJobList = await AppDelegate.sendJMRequest(request);
    if (response?.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    return response.payload;
  }
  async getPmOverdueEquipmentStatistic() {
    if (!this.selectedContracts || this.selectedContracts.length === 0) {
      this.statistics[DashboardStatisticType.PMTASK_OVERDUE_EQUIPMENT].value = [];
      this.statistics[DashboardStatisticType.PMTASK_OVERDUE_EQUIPMENT].records = [[], []];
      return;
    }

    let currentPage = 1;
    let totalCount;
    const payload = await this.getPmOverdueEquipment(currentPage);
    if (payload) {
      totalCount = payload.totalCount;
    }
    this.statistics[DashboardStatisticType.PMTASK_OVERDUE_EQUIPMENT].value[0] = totalCount;
  }

  async getPmOverdueEquipment(pageNumber = 0) {
    let today = moment().startOf('day');
    const request = new JM.JMRequestVpGetPmPeriodEquipmentListByCriteria();
    request.contractNumberList = this.selectedContracts;
    request.statusList = [JMENUM.PMPeriodEquipmentStatus.OUTSTANDING];
    request.periodEndDateTo = today.subtract(1, "days").format(Constants.API_DATE_FORMAT);
    request.pageSize = this.apiPageSize;
    request.pageNumber = pageNumber;

    const response: JM.JMResponseVpGetPmPeriodEquipmentListByCriteria = await AppDelegate.sendJMRequest(request);
    if (response?.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    return response.payload;
  }

  refreshDashboard() {
    this.getDashboardInformation();
  }

  selectAllContract(event) {
    this.selectedContracts = event.target.checked ? JSON.parse(JSON.stringify(this.contractOptions)) : [];  // assign to copy
    Session.setDashboardSelectedContract(this.selectedContracts)
    this.refreshDashboard();
  }

  selectContract(event, contract) {
    if (event.target.checked) {
      this.selectedContracts.push(contract);
    }
    else {
      this.selectedContracts.splice(this.selectedContracts.indexOf(contract), 1);
    }

    Session.setDashboardSelectedContract(this.selectedContracts)
    this.refreshDashboard();
  }

  clickStatistic(key, value) {
    let searchCriteria = {};
    let status = {};
    const today = new Date;
    const selectedContractSet = new Set(this.selectedContracts);
    const snStatusList = Object.values(JMENUM.SnStatus).filter(val => typeof val === "number");
    const jobCardStatusList = [
      JMENUM.JobCardStatus.IN_PROGRESS,
      JMENUM.JobCardStatus.REWORKING,
      JMENUM.JobCardStatus.CANCELLING,
      JMENUM.JobCardStatus.CANCELLED,
      JMENUM.JobCardStatus.COMPLETED,
      JMENUM.JobCardStatus.SUBMITTED_FOR_COMPLETION,
    ];
    switch (key) {

      case DashboardStatisticType.SN_PENDING_ACK:
        if (value <= 0) return;
        status = {};
        for (const statusNum of snStatusList) {
          status[statusNum] = false;
        }
        status[JMENUM.SnStatus.PENDING_ACKNOWLEDGE] = true;
        status[JMENUM.SnStatus.NO_RESPONSE] = true;
        searchCriteria = {
          contracts: Array.from(selectedContractSet),
          status
        }
        Session.setSnSettingsSearchFilters(searchCriteria);
        this.router.navigate(['/pending-notification']);
        return;
      case DashboardStatisticType.SN_FURTHER_ACTION_REQUIRED:
        if (value <= 0 ) return;
        status = {};
        for (const statusNum of snStatusList) {
          status[statusNum] = false;
        }
        status[JMENUM.SnStatus.ACKNOWLEDGED] = true;
        searchCriteria = {
          contracts: Array.from(selectedContractSet),
          status
        }
        Session.setSnSettingsSearchFilters(searchCriteria);
        this.router.navigate(['/pending-notification']);
        return;

      case DashboardStatisticType.CMTASK_IN_PROGRESS:
        status = {};
        for (const statusNum of jobCardStatusList) {
          status[statusNum] = false;
        }
        status[JMENUM.JobCardStatus.IN_PROGRESS] = true;
        searchCriteria = {
          contracts: Array.from(selectedContractSet),
          status
        }
        Session.setCmTaskSettingsSearchFilters(searchCriteria);
        this.router.navigate(['/cm-task']);
        return;
      case DashboardStatisticType.CMTASK_REJECTED:
        if (value <= 0) return;
        status = {};
        for (const statusNum of jobCardStatusList) {
          status[statusNum] = false;
        }
        status[JMENUM.JobCardStatus.REWORKING] = true;
        searchCriteria = {
          contracts: Array.from(selectedContractSet),
          status
        }
        Session.setCmTaskSettingsSearchFilters(searchCriteria);
        this.router.navigate(['/cm-task']);
        return;
      case DashboardStatisticType.PMTASK_JOB_REJECTED:
        if (value <= 0 ) return;
        const searchCriteriaMap = new Map();
        searchCriteriaMap.set('selectedContract', Array.from(selectedContractSet));
        searchCriteriaMap.set('status', JMENUM.PMJobStatus.REWORKING);
        Session.pmTaskListGeneralSearchCriteria = searchCriteriaMap;

        this.router.navigate(['/pm/jobs']);
        return;
      case DashboardStatisticType.PMTASK_OUTSTANDING_EQUIPMENT:
        if (value <= 0 ) return;
        searchCriteria = {
          contractNumberList: Array.from(selectedContractSet),
          isOutstanding: true,
          periodStartDateTo: {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
          }
        }
        Session.setPmEquipmentSearchFilters(JSON.stringify(searchCriteria));
        this.router.navigate(['/pm/equipments/outstanding']);
        return;

      case DashboardStatisticType.PMTASK_OVERDUE_EQUIPMENT:
        if (value <= 0 ) return;
        searchCriteria = {
          contractNumberList: Array.from(selectedContractSet),
          isOutstanding: true,
          periodEndDateTo: {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate() - 1
          }
        }
        Session.setPmEquipmentSearchFilters(JSON.stringify(searchCriteria));
        this.router.navigate(['/pm/equipments/outstanding']);
        return;

    }
  }

  checkAndShowChangePWReminder() {
    const userName = Session.userInfo.name;
    const reminderDays = [14, 7, 4, 3, 2, 1];
    const passwordRemainingValidDays = Session.userInfo.passwordRemainingValidDays;
    const passwordExpiryDaysConfig = Session.userInfo.passwordExpiryDaysConfig;
    const shdRemindChangePW = reminderDays.includes(passwordRemainingValidDays) && !this.authorizationService.isUserRemindedPwChange(userName);
    if (!shdRemindChangePW) {
      return;
    }
    AppDelegate.popUpDialog().openDetailModal({
      header: { title: JMLanguage.translate('dialog.change-pw-reminder.title'), iconCss: 'fas fa-bell' }, content:
        [{ msg: JMLanguage.translate('dialog.change-pw-reminder.content.1', [passwordExpiryDaysConfig]) }, { msg: JMLanguage.translate('dialog.change-pw-reminder.content.2', [passwordRemainingValidDays]) }],
      buttons: [{name: JMLanguage.translate('dialog.change-pw-reminder.action.reset-now'), cssClass: "reset-now-btn", handler: ()=>{this.changePWNow()}}, 
      {name: JMLanguage.translate('dialog.change-pw-reminder.action.maybe-later'),  cssClass: "maybe-later-btn"}]
    }, {
      backdrop: 'static', windowClass: 'blur-background reminder-popup'
    }).result.then(null, (dismissReason) => {
      this.authorizationService.addUserRemindedPwChange(userName);
    });
  }
  
  changePWNow() {
    AppDelegate.routeToPage('./settings/general/user-profile', null, {queryParams: {actionMode: 'EDIT' }});
  }


}
