import { JMENUM } from '@ccep/CCEPConnector-ts';
import {
  TablexColumnFilterType,
  TablexColumnFilterOption,
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';
import { ActionButtonI } from 'src/app/shared/action-sidebar-v2/action-sidebar-v2.model';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';

export enum GetPmPeriodAssignedEquipmentListParams {
  equipmentNumber = 'equipmentNumber',
  description = 'description',
  location = 'location',
  locationDescription = 'locationDescription',
  clientShortName = 'clientShortName',
  equipmentType = 'equipmentType',
  status = 'status',
}
export enum GetPmPeriodUnassignedEquipmentListParams {
  equipmentNumber = 'equipmentNumber',
  description = 'description',
  location = 'location',
  locationDescription = 'locationDescription',
  clientShortName = 'clientShortName',
  equipmentType = 'equipmentType',
}

export enum PmPeriodAssignedEquipmentTableColumnIndex {
  equipmentNumber,
  description,
  location,
  clientShortName,
  equipmentType,
  status,
}

const equipmentTableHeader = [
  {
    id: GetPmPeriodAssignedEquipmentListParams.equipmentNumber,
    name: 'component.pm-period-equipment-list.table-column.equipment-id',
    enableSort: false,
    enableFilter: true,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-20',
  },
  {
    id: GetPmPeriodAssignedEquipmentListParams.description,
    name: 'component.pm-period-equipment-list.table-column.description',
    enableSort: false,
    enableFilter: true,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-20',
  },
  {
    id: GetPmPeriodAssignedEquipmentListParams.location,
    name: 'component.pm-period-equipment-list.table-column.location',
    enableSort: false,
    enableFilter: true,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-20',
  },
  {
    id: GetPmPeriodAssignedEquipmentListParams.clientShortName,
    name: 'component.pm-period-equipment-list.table-column.client',
    enableSort: false,
    enableFilter: true,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-20',
  },
  {
    id: GetPmPeriodAssignedEquipmentListParams.equipmentType,
    name: 'component.pm-period-equipment-list.table-column.equipment-type',
    enableSort: false,
    enableFilter: true,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-20',
  },
  {
    id: GetPmPeriodAssignedEquipmentListParams.status,
    name: 'component.pm-period-equipment-list.table-column.status',
    enableSort: false,
    enableFilter: true,
    filterType: TablexColumnFilterType.Dropdown,
    showOptionTitle: true,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    class: 'w-10 d-none',
  },
];

enum equipmentActionTableIndex {
  equipmentNumber,
  description,
  location,
  clientShortName,
  equipmentType,
  actionChangeText,
  deleteEquipment,
  action,
}
const equipmentActionTableHeader = [
  {
    id: GetPmPeriodAssignedEquipmentListParams.equipmentNumber,
    name: 'component.pm-period-equipment-list.table-column.equipment-id',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-20',
  },
  {
    id: GetPmPeriodAssignedEquipmentListParams.description,
    name: 'component.pm-period-equipment-list.table-column.description',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-20',
  },
  {
    id: GetPmPeriodAssignedEquipmentListParams.location,
    name: 'component.pm-period-equipment-list.table-column.location',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-20',
  },
  {
    id: GetPmPeriodAssignedEquipmentListParams.clientShortName,
    name: 'component.pm-period-equipment-list.table-column.client',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-20',
  },
  {
    id: GetPmPeriodAssignedEquipmentListParams.equipmentType,
    name: 'component.pm-period-equipment-list.table-column.equipment-type',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-20',
  },
  {
    id: 'actionChangeText',
    name: 'component.pm-period-equipment-list.table-column.change',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Text,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-20',
  },
  {
    id: 'deleteEquipment',
    name: 'component.pm-period-equipment-list.table-column.delete',
    enableSort: false,
    enableFilter: false,
    type: TablexColumnType.Buttons,
    horizontalAlign: TablexColumnHorizontalAlign.Center,
    verticalAlign: TablexColumnVerticalAlign.Middle,
    // class: 'w-20',
  },
];

const PmPeriodEquipmentListButtonList: { [x: string]: ActionButtonI } = {
  save: {
    action: 'save',
    permissionList: [JMENUM.VpPermission.PMPLAN_UPDATE],
    actionDescription: 'action.button.save',
    buttonId: 'right-tool-bar_save_button',
    imageUrl: '/assets/svg/btn_action_save.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: 'action.button.popup.confirm-save.pm-period.equipment',
    popUpButtonList: [],
  },
  close: {
    action: 'close',
    permissionList: [],
    actionDescription: 'action.button.close',
    buttonId: 'right-tool-bar_close_button',
    imageUrl: '/assets/svg/btn_action_reject.svg',
    isEnable: true,
    isShow: true,
    handler: null,
    popUpTitle: 'action.button.popup.confirm-close',
    popUpButtonList: [],
  },
};


export {
  equipmentTableHeader,
  equipmentActionTableHeader,
  equipmentActionTableIndex,
  PmPeriodEquipmentListButtonList,
}
