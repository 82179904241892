<div class="wrapper">
  <app-nav [navbarTitle]="'pages.settings-user-management.title' | translate"></app-nav>
  <app-sidebar [contentType]="'settings'"></app-sidebar>

  <div id="content" class="content">
    
    <div class="row justify-content-between">
      <div *ngIf="hasViewPermission" class="col-md-9" [ngClass]="{'col-md-9': hasUpdatePermission}">
        <div class="row justify-content-between mx-0">
          <div class="title">{{ 'pages.settings-user-management.title.user-list' | translate }}</div>
          <app-icon-button  *ngIf="hasUpdatePermission && actionMode == ActionType.EDIT_USER"
            [id]="'user-manage-add-user-button'"
            [color]="'blue'"
            [icon]="'fa-plus'"
            (onClick)="onClickedAddUser()" 
          >
          </app-icon-button>
        </div>
        <hr class="underline" />
        <app-tablex id="user-management-table" [tablexParam]='tablexParam'></app-tablex>
      </div>

      <div *ngIf="hasUpdatePermission" class="col-md-3" [ngClass]="{'col-md-3': hasViewPermission}">
        <div class="row justify-content-between mx-0">
          <div *ngIf="actionMode == ActionType.CREATE_USER" class="title">{{ 'pages.settings-user-management.title.user-create' | translate }}</div>
          <div *ngIf="actionMode == ActionType.EDIT_USER" class="title">{{ 'pages.settings-user-management.title.user-edit' | translate }}</div>
          <div class="row justify-content-right mx-0">
            <div class="pt-2 pr-2"><span class="red pr-1">*</span>{{ 'global.mandatory-fields' | translate }}</div>
            <app-icon-button 
              [id]="'user-management-clear-button'"
              [color]="'red'"
              [icon]="'fa-eraser'"
              (onClick)="onClickedClear()" 
            >
            </app-icon-button>
            <app-icon-button 
              [id]="'user-management-submit-button'"
              [color]="'amber'"
              [icon]="'fa-check'"
              [isLoading]="isLoadingSubmit" (onClick)="onClickedSubmit()" 
            >
            </app-icon-button>
          </div>
        </div>
        <hr class="underline" />
        <div class="my-3">
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-xl-5 px-0" for="user-management-contractor-dropdown">
              {{'pages.settings-user-management.contractor' | translate}}
              <span *ngIf="mandatoryFields.includes('vendorNumber')" class="red">*</span>
            </label>
            <ng-select 
              id="user-management-contractor-dropdown" 
              class="col-xl-7 p-0"
              placeholder="{{'pages.settings-user-management.contractor' | translate}}"
              [items]="vendorList"
              bindValue="vendorNumber"
              bindLabel="vendorName"
              [(ngModel)]="userInfo.vendorNumber"
              (ngModelChange)="onSelectedVendorChanged()"
              (click)="validateSelectedVendor()"
              (clear)="onClearVendor()"
              (scrollToEnd)="onVendorScrollToEnd()"
              (search)="onSearchVendor($event)"
              (blur)="onClearVendor()"
              [disabled]="actionMode == ActionType.EDIT_USER"
              [ngClass]="{'input-error': errorFields.includes('vendorNumber')}"
            >
            </ng-select>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-xl-5 px-0" for="user-management-username-input">
              {{'pages.settings-user-management.username' | translate}}
              <span *ngIf="mandatoryFields.includes('name')" class="red">*</span>
            </label>
            <div class="input-wrap d-flex align-items-center mx-auto p-0 col-xl-7" [ngClass]="{ 'username-input-disabled': !userInfo.vendorNumber || actionMode == ActionType.EDIT_USER, 'input-error': errorFields.includes('name') }">
              <span class="pl-2 pr-1">{{ usernamePrefix }}</span>
              <input id="user-management-username-input" 
                type="text" class="form-control mb-0 pl-0" 
                placeholder="{{'pages.settings-user-management.table-column.username' | translate}}" 
                [(ngModel)]="userInfo.name" 
                [disabled]="!userInfo.vendorNumber || actionMode == ActionType.EDIT_USER"
                [ngClass]="{ 'username-input-disabled': !userInfo.vendorNumber || actionMode == ActionType.EDIT_USER }"
              />
            </div>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-xl-5 px-0" for="user-management-staff-name-input">
              {{'pages.settings-user-management.staff-name' | translate}}
              <span *ngIf="mandatoryFields.includes('employeeName')" class="red">*</span>
            </label>
            <input id="user-management-staff-name-input" type="text" class="form-control col-xl-7" placeholder="{{'pages.settings-user-management.staff-name' | translate}}" [(ngModel)]="userInfo.employeeName" [ngClass]="{'input-error': errorFields.includes('employeeName')}"/>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-xl-5 px-0" for="user-management-password-input">
              {{'pages.settings-user-management.password' | translate}}
              <span *ngIf="mandatoryFields.includes('password')" class="red">*</span>
            </label>
            <input id="user-management-password-input" type="password" class="form-control col-xl-7" placeholder="{{'pages.settings-user-management.password' | translate}}" autocomplete="new-password" [(ngModel)]="userInfo.password" [ngClass]="{'input-error': errorFields.includes('password')}" />
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-xl-5 px-0" for="user-management-confirm-password-input">
              {{'pages.settings-user-management.confirm-password' | translate}}
              <span *ngIf="mandatoryFields.includes('confirmationPassword')" class="red">*</span>
            </label>
            <input id="user-management-confirm-password-input" type="password" class="form-control col-xl-7" placeholder="{{'pages.settings-user-management.confirm-password' | translate}}" autocomplete="new-password" [(ngModel)]="userInfo.confirmationPassword" [ngClass]="{'input-error': errorFields.includes('confirmationPassword')}" />
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-xl-5 px-0" for="user-management-phone-input">
              {{'pages.settings-user-management.phone' | translate}}
              <span *ngIf="mandatoryFields.includes('phone')" class="red">*</span>
            </label>
            <input id="user-management-phone-input" type="text" class="form-control col-xl-7" placeholder="{{'pages.settings-user-management.phone' | translate}}" maxlength="8" [(ngModel)]="userInfo.phone" (keyup)="inputNumber($event)" [ngClass]="{'input-error': errorFields.includes('phone')}"/>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-xl-5 px-0" for="user-management-mobile-input">
              {{'pages.settings-user-management.mobile' | translate}}
              <span *ngIf="mandatoryFields.includes('mobile')" class="red">*</span>
            </label>
            <input id="user-management-mobile-input" type="text" class="form-control col-xl-7" placeholder="{{'pages.settings-user-management.mobile' | translate}}" maxlength="8" [(ngModel)]="userInfo.mobile" (keyup)="inputNumber($event)" [ngClass]="{'input-error': errorFields.includes('mobile')}"/>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-xl-5 px-0" for="user-management-email-input">
              {{'pages.settings-user-management.email' | translate}}
              <span *ngIf="mandatoryFields.includes('email')" class="red">*</span>
            </label>
            <input id="user-management-email-input" type="text" class="form-control col-xl-7" placeholder="{{'pages.settings-user-management.email' | translate}}" [(ngModel)]="userInfo.email" [ngClass]="{'input-error': errorFields.includes('email')}"/>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-xl-5 px-0" for="user-management-role-dropdown">
              {{'pages.settings-user-management.roles' | translate}}
              <span *ngIf="mandatoryFields.includes('roles')" class="red">*</span>
            </label>
            <ng-select 
              id="user-management-role-dropdown" 
              class="col-xl-7 p-0" 
              placeholder="{{'pages.settings-user-management.roles' | translate}}"
              [multiple]="true"
              [items]="roleList"
              bindValue="roleId"
              bindLabel="name"
              [(ngModel)]="userInfo.roles" 
              [ngClass]="{'input-error': errorFields.includes('roles')}"
            >
            </ng-select>
          </div>
          <div *ngIf="hasTeamUpdatePermission" class="row mx-0 align-items-center my-2">
            <label class="label-text col-xl-5 px-0" for="user-management-team-dropdown">{{'pages.settings-user-management.team' | translate}}</label>
            <ng-select 
              id="user-management-team-dropdown" 
              class="col-xl-7 p-0" 
              placeholder="{{'pages.settings-user-management.team' | translate}}"
              [multiple]="true"
              [items]="vendorTeamList"
              bindValue="_id"
              bindLabel="name"
              [(ngModel)]="userInfo.vendorTeams" 
              [disabled]="!userInfo.vendorNumber"
              (scrollToEnd)="onVendorTeamScrollToEnd()"
              (search)="onSearchVendorTeam($event)"
              (clear)="onClearVendorTeam()"
              [ngClass]="{'input-error': errorFields.includes('vendorTeams')}"
            >
            </ng-select>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-xl-5 px-0" for="user-management-active-checkbox"> {{ 'pages.settings-user-management.active' | translate }} </label>
            <div class="col-xl-7 custom-checkbox">
              <input id="user-management-active-checkbox" type="checkbox" class="custom-control-input" [(ngModel)]="userInfo.isActive">
              <label class="custom-control-label ml-2" for="user-management-active-checkbox"></label>
            </div>
          </div>
          <div class="row mx-0 align-items-center my-2">
            <label class="label-text col-xl-5 px-0" for="user-management-use-2fa-checkbox"> {{ 'pages.settings-user-management.use-2fa' | translate }} </label>
            <div class="col-xl-7 custom-checkbox">
              <input id="user-management-use-2fa-checkbox" type="checkbox" class="custom-control-input" [(ngModel)]="userInfo.use2fa" [disabled]="true">
              <label class="custom-control-label ml-2" for="user-management-use-2fa-checkbox"></label>
            </div>
          </div>
        </div>
        <div [ngClass]="{hidden: !showPasswordError}">
          <app-password-error-list #passwordErrorList>
          </app-password-error-list>
      </div>
      </div>
    </div>

  </div>

</div>
