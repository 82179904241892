<div class="wrapper">
  <app-nav></app-nav>
  <app-sidebar [contentType]="sideBarContentType"></app-sidebar>


  <div id="content" class="content ">
    <app-tab-menu *ngIf="tabMenuContentType" [contentType]="tabMenuContentType"></app-tab-menu>
    <h1> {{ 'global.coming-soon' | translate }} </h1>
  </div>

</div>
