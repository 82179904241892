<div id="pmPeriodSearchCriteriaComponent">
  <div class="d-flex justify-content-between">
    <span class="font-size-xl">
      {{ 'component.pm-period-search-criteria.title' | translate }}
    </span>

    <div class="d-flex justify-content-end mb-2">
      <button class="btn icon-btn brand-amber mr-2 img-btn" (click)="onClickClear()">
        <i class="fas fa-undo fa-sm"></i>
      </button>

      <button class="btn icon-btn brand-blue img-btn" (click)="onClickSearch()">
        <i class="fas fa-search fa-sm"></i>
      </button>
    </div>
  </div>
  <hr class="underline" />

  <div id="search-field-wrap" class="my-3">
    <!-- pm plan number -->
    <div class="my-3">
      <input
        id="search-plan-number"
        class="form-control mb-3"
        placeholder="{{ 'component.pm-period-search-criteria.plan-number' | translate }}"
        [(ngModel)]="searchFilters.planNumber"
      />
    </div>

    <hr class="underline" />

    <!-- period status -->
    <div class="my-3">
      <span class="d-block mb-1 gray-40">{{ 'component.pm-period-search-criteria.period-status' | translate }}</span>
      <div class="d-flex flex-wrap" *ngIf="searchFilters.periodStatus">
        <label
          *ngFor="let status of searchFilters.periodStatus | keyvalue"
          class="checkbox-tag brand-blue flex-grow-1 text-center mb-1"
          [for]="'search-period-status-' + status.key + '-checkbox'"
          [class.checked]="searchFilters.periodStatus[status.key]"
        >
          {{ 'pm-plan.status.' + status.key | translate }}
          <input
            type="checkbox"
            hidden="true"
            name="period-status"
            [id]="'search-period-status-' + status.key + '-checkbox'"
            [(ngModel)]="searchFilters.periodStatus[status.key]"
            (ngModelChange)="ngModelChangeCheckIfNoCheck('periodStatus')"
          />
        </label>
      </div>
    </div>

    <hr class="underline" />

    <!-- period schedule -->
    <div class="my-3">
      <span class="d-block mb-1 gray-40">{{ 'component.pm-period-search-criteria.period-schedule' | translate }}</span>
      <div class="d-flex  flex-wrap" *ngIf="searchFilters.periodSchedule">
        <label
          *ngFor="let status of searchFilters.periodSchedule | keyvalue"
          class="checkbox-tag brand-blue flex-grow-1 text-center mb-1"
          [for]="'search-period-status-' + status.key + '-checkbox'"
          [class.checked]="searchFilters.periodSchedule[status.key]"
        >
          {{ 'pm-period.schedule.' + status.key | translate }}
          <input
            type="checkbox"
            hidden="true"
            name="period-status"
            [id]="'search-period-status-' + status.key + '-checkbox'"
            [(ngModel)]="searchFilters.periodSchedule[status.key]"
            (ngModelChange)="ngModelChangeCheckIfNoCheck('periodSchedule')"
          />
        </label>
      </div>
    </div>

    <hr class="underline" />

    <!-- start end dates -->
    <div class="my-3">
      <div>
        <span class="d-block mb-1 gray-40">{{ 'component.pm-period-search-criteria.start' | translate }}</span>
        <div class="d-flex">
          <div
            ngbRadioGroup
            class="mr-2 btn-group btn-group-toggle flex-grow-1 w-100"
            [(ngModel)]="searchFilters.startRange"
          >
            <label ngbButtonLabel class="radio-tag brand-blue">
              <input ngbButton type="radio" value="on-or-before" />{{
                'component.pm-period-search-criteria.on-or-before' | translate
              }}
            </label>
            <label ngbButtonLabel class="radio-tag brand-blue">
              <input ngbButton type="radio" value="after" />{{
                'component.pm-period-search-criteria.after' | translate
              }}
            </label>
          </div>
          <app-date-input
            class="flex-grow-1 w-100"
            [input]="searchFilters.startDate"
            (updatedDate)="onUpdatedDateStart($event)"
          >
          </app-date-input>
        </div>
      </div>
      <div class="mt-2">
        <span class="d-block mb-1 gray-40">{{ 'component.pm-period-search-criteria.end' | translate }}</span>
        <div class="d-flex">
          <div
            ngbRadioGroup
            class="mr-2 btn-group btn-group-toggle flex-grow-1 w-100"
            [(ngModel)]="searchFilters.endRange"
          >
            <label ngbButtonLabel class="radio-tag brand-blue">
              <input ngbButton type="radio" value="on-or-before" />{{
                'component.pm-period-search-criteria.on-or-before' | translate
              }}
            </label>
            <label ngbButtonLabel class="radio-tag brand-blue">
              <input ngbButton type="radio" value="after" />{{
                'component.pm-period-search-criteria.after' | translate
              }}
            </label>
          </div>
          <app-date-input
            class="flex-grow-1 w-100"
            [input]="searchFilters.endDate"
            (updatedDate)="onUpdatedDateEnd($event)"
          >
          </app-date-input>
        </div>
      </div>
    </div>

    <hr class="underline" />
  </div>
</div>
