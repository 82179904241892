import { Pipe, PipeTransform } from '@angular/core';
import { formatDateTimeWithWeek } from './Formatter';

@Pipe({
  name: 'dateTimeWeek',
})
export class DateTimeWeekPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    let result = value ? formatDateTimeWithWeek(value) : value;
    return result;
  }
}
