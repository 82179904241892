import { Component, OnInit } from '@angular/core';
import { JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { UserHelper } from 'src/app/core/user-helper';

@Component({
  selector: 'app-setting-contract-severity-list',
  templateUrl: './setting-contract-severity-list.component.html',
  styleUrls: ['./setting-contract-severity-list.component.scss'],
})
export class SettingContractSeverityListComponent implements OnInit {
  
  selectedContract?: JMOBJ.MaintenanceTermContract;
  contractParamList = ['contractNumber', 'liftTrapped'];

  constructor() {}

  ngOnInit(): void {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }
  }

  onAssignedContract(contract: JMOBJ.MaintenanceTermContract) {
    this.selectedContract = contract;
  }

  // ----------- get property function ----------- //
  get hasUpdatePermission(): boolean {
    return UserHelper.hasPermission(JMENUM.VpPermission.SEVERITY_UPDATE);
  }
}
