import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {

  @Input() breadcrumbs:breadcrumbsComponentI[] = [];
  constructor() { }

  ngOnInit(): void {
  }
}

export interface breadcrumbsComponentI {
  id?: string
  currentPage?: boolean
  route?: string
  name?: string
}