<div class="d-flex justify-content-between mb-2 align-items-center">
  <div class="font-size-xl align-self-end">
    {{ "component.cm-task-search-criteria.search-criteria" | translate }}
  </div>

  <div class="d-flex justify-content-end">
    <button *ngIf="false" class="btn icon-btn brand-amber mr-2 img-btn"
      [disabled]="isDisabled"
      (click)="onClickReset()"
    >
      <i class="fas fa-undo" ></i>
    </button>

    <button
      class="btn icon-btn brand-blue mr-2 img-btn"
      (click)="onClickDownload()"
      [disabled]="isDownloading || isDisabled || showCreationTimeErrorMsg"
    >
      <div *ngIf="isDownloading" class="d-flex align-items-center loading-spinner spinner-small"></div>
      <i *ngIf="!isDownloading" class="fas fa-download white font-size-l" ></i>
    </button>

    <button
      class="btn icon-btn brand-blue img-btn"
      (click)="onClickSearch()"
      [disabled]="isSearching || isDisabled || showCreationTimeErrorMsg"
    >
      <div *ngIf="isSearching" class="d-flex align-items-center loading-spinner spinner-small blue"></div>
      <i class="fas fa-search white font-size-l" ></i>
    </button>

    <button
      class="btn icon-btn ml-2 d-inline-block d-xl-none navbar-toggler"
      (click)="isCollapse = !isCollapse"
    >
      <i class="fas fa-chevron-up" [class.d-none]="isCollapse"></i>
      <i class="fas fa-chevron-down" [class.d-none]="!isCollapse"></i>
    </button>
  </div>
</div>
<hr class="underline" class="underline" />

<div class="mt-2 px-0">
  <div id="search-field-wrap" class="row justify-content-between mx-0 collapse navbar-collapse align-items-start" [ngbCollapse]="isCollapse">
    <div class="col-12 col-md-6 col-xl-12  pl-0 pr-0 pr-md-3 pr-xl-0">

      <input
        id="search-sn-number"
        class="form-control mb-3"
        placeholder="{{'component.cm-task-search-criteria.sn-number' | translate }}"
        [(ngModel)]="searchSelections.snNumber" 
      >
      <input
        id="search-job-card-number"
        class="form-control mb-3"
        placeholder="{{'component.cm-task-search-criteria.cm-task-number' | translate }}"
        [(ngModel)]="searchSelections.jobCardNumber" 
      >

      <hr class="d-block d-md-none d-xl-block" />
      <ng-select
        id="search-client"
        class="px-0 mb-3"
        placeholder="{{'component.cm-task-search-criteria.client' | translate}}"
        bindLabel="label"
        bindValue="value"
        [items]="clientOptions" 
        [loading]="isLoadingClient"
        [multiple]="false"
        (clear)="clearClientFilter()"
        (scrollToEnd)="onClientScrollToEnd()"
        (search)="onSearchClient($event)"
        (open)="clearClientFilter()"
        [(ngModel)]="searchSelections.client"
      >
      </ng-select>
      <ng-select
        id="search-contract"
        class="px-0 mb-3"
        placeholder="{{'component.cm-task-search-criteria.contract' | translate}}"
        [items]="contractOptions" 
        [loading]="isLoadingContract"
        [multiple]="true"
        (clear)="clearContractFilter()"
        (scrollToEnd)="onContractScrollToEnd()"
        (search)="onSearchContract($event)"
        (open)="clearContractFilter()"
        [(ngModel)]="searchSelections.contracts"
      >
      </ng-select>
      <ng-select
        id="search-handling-team"
        class="px-0 mb-3"
        placeholder="{{'component.cm-task-search-criteria.team' | translate}}"
        bindLabel="label"
        bindValue="value"
        [items]="teamOptions" 
        [loading]="isLoadingTeam"
        [multiple]="false"
        (clear)="clearTeamFilter()"
        (scrollToEnd)="onTeamScrollToEnd()"
        (search)="onSearchTeam($event)"
        (open)="clearTeamFilter()"
        [(ngModel)]="searchSelections.handlingTeam"
      >
      </ng-select>

      <hr class="d-block d-md-none d-xl-block" />
      <ng-select
        id="search-equipment"
        class="px-0 mb-3"
        placeholder="{{'component.cm-task-search-criteria.equipment' | translate}}"
        bindLabel="label"
        bindValue="value"
        [items]="equipmentOptions" 
        [loading]="isLoadingEquipment"
        [multiple]="false"
        (clear)="clearEquipmentFilter()"
        (scrollToEnd)="onEquipmentScrollToEnd()"
        (search)="onSearchEquipment($event)"
        (open)="clearEquipmentFilter()"
        [(ngModel)]="searchSelections.equipment"
      >
      </ng-select>
      <ng-select
        id="search-location"
        class="px-0 mb-3"
        placeholder="{{'component.cm-task-search-criteria.location' | translate}}"
        bindLabel="label"
        bindValue="value"
        [items]="locationOptions" 
        [loading]="isLoadingLocation"
        [multiple]="false"
        (clear)="clearLocationFilter()"
        (scrollToEnd)="onLocationScrollToEnd()"
        (search)="onSearchLocation($event)"
        (open)="clearLocationFilter()"
        [(ngModel)]="searchSelections.location"
      >
      </ng-select>

      <hr class="d-block d-md-none d-xl-block" />
      <!-- creation time -->
      <div>
        <div class="gray-40">
          {{ 'component.cm-task-search-criteria.job-creation-time-form' | translate }}
        </div>
        <div class="d-inline-block">
          <app-date-input #startDateElem
            class="mt-1 d-inline-block"
            [class.input-error]="isCreationPeriodError"
            [input]="startDateNgb"
            [inputId]="'startDateInput'"
            [field]="'startDate'" 
            (updatedDate)="onBlurDateInput($event)">
          </app-date-input>
        </div>
      </div>
      <div class="mt-2">
        <div class="gray-40">
          {{ 'component.cm-task-search-criteria.job-creation-time-to' | translate }}
        </div>
        <div class="d-inline-block">
          <app-date-input #endDateElem
            class="mt-1 d-inline-block"
            [class.input-error]="isCreationPeriodError"
            [input]="endDateNgb"
            [inputId]="'endDateInput'"
            [field]="'endDate'" 
            (updatedDate)="onBlurDateInput($event)">
          </app-date-input>
        </div>
        <div *ngIf="showCreationTimeErrorMsg" class="red font-italic">
          {{ 'component.cm-task-search-criteria.error-msg.wrong-creation-time' | translate }}
        </div>
      </div>
      
      <hr class="d-block d-md-none d-xl-block" />
      <!-- status -->
      <div class="mt-3">
        <span class="d-inline d-md-block d-xl-inline gray-40 mr-2">
          {{ 'component.cm-task-search-criteria.status' | translate }}
        </span>
        <div class="d-inline-block" *ngIf="searchSelections.status">
          <label class="checkbox-tag brand-blue"
            *ngFor="let status of searchSelections.status | keyvalue"
            [for]="'search-job-status-'+status.key+'-checkbox'"
            [class.checked]="searchSelections.status[status.key]"
          >
            {{ 'jobcard.status.'+status.key | translate }}
            <input type="checkbox" hidden="true" name="job-status"
              [id]="'search-job-status-'+status.key+'-checkbox'"
              [(ngModel)]="searchSelections.status[status.key]"
            />
          </label>
        </div>
      </div>

    </div>
  </div>
</div>
