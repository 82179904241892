import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { debounceTime } from 'rxjs/operators';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { trimString } from 'src/app/core/Formatter';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Constants } from 'src/constants';
import { Session } from 'src/app/core/session';
import { DropdownControl, DropdownSearch, InputControl } from '../../../shared/form.model';
import { CustomSliderPanelComponent } from 'src/app/shared/custom-slider-panel/custom-slider-panel.component';

@Component({
  selector: 'app-cm-task-description',
  templateUrl: './cm-task-description.component.html',
  styleUrls: ['./cm-task-description.component.scss'],
})
export class CmTaskDescriptionComponent implements OnInit, OnChanges {
  @ViewChild(CustomSliderPanelComponent, { static: false }) equipmentSelectorPanel: CustomSliderPanelComponent;

  @Input() jobCard: JMOBJ.JobCard;
  @Input() sn: JMOBJ.ServiceNotification;
  @Input() pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.VIEW;

  @Output() updatedContract = new EventEmitter<JMOBJ.MaintenanceTermContract | undefined>();
  @Output() updatedEquipment = new EventEmitter<JMOBJ.Equipment | undefined>();
  @Output() updatedContractSeverity = new EventEmitter<JMOBJ.ContractSeverity | undefined>();

  // Input
  contractInput = new CmTaskContractInput();
  equipmentInput = new CmTaskEquipmentInput();
  contractSeverityInput = new CmTaskContractSeverityInput();
  taskDescriptionInput = new CmTaskTaskDescriptionInput();
  vendorTeamInput = new CmTaskVendorTeamInput();

  private contractSearch = new DropdownSearch();
  private equipmentIdSearch = new DropdownSearch();
  private contractSeveritySearch = new DropdownSearch();
  private vendorTeamSearch = new DropdownSearch();

  private ngOptionPrefill = 'prefill';

  equipmentNumber: string;
  equipmentOwner: string;
  equipmentDescription: string;
  equipmentFunctionalLocation: string;
  severityDescription: string;

  constructor() {}

  ngOnInit(): void {
    this.resetInput();

    if (this.isEditMode || this.isCreateMode) {
      this.setContractSeveritySearchObserver();
      this.setVendorTeamSearchObserver();
    }

    if (this.isCreateMode) {
      this.setContractSearchObserver();
      this.resetContractOption();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // get equipment data not provided in jobcard by additional API
    if (this.isViewMode) {
      if (changes.jobCard && !changes.jobCard.isFirstChange() && this.jobCard && this.jobCard._id && this.jobCard.equipment) {
        this.requestEquipmentDetail(this.jobCard.equipment).then(async (equipment) => {
          this.equipmentNumber = equipment.equipmentNumber;
          this.equipmentOwner = equipment.clientShortName;
          this.equipmentDescription = equipment.description;
          if(equipment.location) {
            this.equipmentFunctionalLocation = await this.requestLocationDescription(equipment.location);
          }
        });
      }
      return;
    }
  }

  //------------------------
  // parent component trigger function
  public setCreateStandaloneCmTaskData(request: JM.JMRequestSnCreateStandaloneCmTask) {
    const contract = this.contractInput.value;
    const equipment = this.equipmentInput.value;
    const contractSeverity = this.contractSeverityInput.value;
    const vendorTeam = this.vendorTeamInput.value;

    request.contractNumber = contract && contract.contractNumber ? contract.contractNumber : undefined;
    request.equipment = equipment?.equipmentNumber;
    request.contractSeverityId = contractSeverity && contractSeverity._id ? contractSeverity._id : undefined;
    request.jobDescription = !!this.taskDescriptionInput.value.trim()
      ? this.taskDescriptionInput.value.trim()
      : undefined;
    request.vendorTeamId = vendorTeam && vendorTeam._id ? vendorTeam._id : undefined;
  }

  public setUpdateCmTaskData(request: JM.JMRequestJobCardsUpdateCmTask) {
    const equipment = this.equipmentInput.value;
    const contractSeverity = this.contractSeverityInput.value;
    const vendorTeam = this.vendorTeamInput.value;

    request.equipment = equipment?.equipmentNumber;
    request.contractSeverityId = contractSeverity && contractSeverity._id ? contractSeverity._id : null;
    request.jobDescription = !!this.taskDescriptionInput.value.trim() ? this.taskDescriptionInput.value.trim() : null;
    request.vendorTeamId = vendorTeam && vendorTeam._id ? vendorTeam._id : null;
  }

  public async setValuesInEditMode(
    jobCard: JMOBJ.JobCard,
    contract: JMOBJ.MaintenanceTermContract,
    contractSeverity: JMOBJ.ContractSeverity
  ) {
    // fill by jobCard.contract
    this.contractInput.value = contract;

    // fill by jobCard.equipment
    if(jobCard.equipment) {
      const equipment = await this.requestEquipmentDetail(jobCard.equipment);
      this.equipmentInput.value = equipment;
      this.equipmentNumber = equipment.equipmentNumber;
      this.equipmentOwner = equipment.clientShortName;
      this.equipmentDescription = equipment.description;
      if(equipment.location) {
        this.requestLocationDescription(equipment.location).then((description) => {
          this.equipmentFunctionalLocation = description;
        });
      }

      this.requestContractSeverityList(false,
        this.contractInput.value.contractNumber,
        this.equipmentInput.value.clientShortName,
        this.equipmentInput.value.equipmentType,
        this.equipmentInput.value.location,
        this.equipmentInput.value.districtCode);
    }

    this.contractSeveritySearch.totalPageNumber = 0;
    // fill by jobCard.contractSeverity
    if (contractSeverity) {
      this.contractSeverityInput.value = contractSeverity;
    }
    this.severityDescription = this.contractSeverityInput.value?.description || "";

    if (jobCard.jobDescription) {
      this.taskDescriptionInput.value = jobCard.jobDescription;
    }

    if (jobCard.vendorTeam) {
      let vendorTeam = new JMOBJ.VendorTeam();
      vendorTeam._id = jobCard.vendorTeam.id;
      vendorTeam.name = jobCard.vendorTeam.name;
      this.vendorTeamInput.value = vendorTeam;
      this.vendorTeamInput.optionList = [vendorTeam];
      this.vendorTeamSearch.totalPageNumber = 0;
    }
  }

  public resetFields() {
    this.resetInput();
    this.resetContractOption();
  }

  public validateMandatoryFields(): boolean {
    this.setAllFieldsNoError();
    return !this.isMissingMandatoryField();
  }

  public validateData(): boolean {
    this.setAllFieldsNoError();

    // checking - mandatory fields
    if (this.isMissingMandatoryField()) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate('toast.missing-mandatory-fields'));
      return false;
    }

    return true;
  }

  //------------------------
  // checking function
  private isMissingMandatoryField(): boolean {
    let hasError = false;

    if (this.contractInput.isMandatory && !this.contractInput.value) {
      this.contractInput.isError = true;
      hasError = true;
    }
    if (this.equipmentInput.isMandatory && !this.equipmentInput.value) {
      this.equipmentInput.isError = true;
      hasError = true;
    }
    if (this.contractSeverityInput.isMandatory && !this.contractSeverityInput.value) {
      this.contractSeverityInput.isError = true;
      hasError = true;
    }
    if (this.taskDescriptionInput.isMandatory && !trimString(this.taskDescriptionInput.value)) {
      this.taskDescriptionInput.isError = true;
      hasError = true;
    }
    if (this.vendorTeamInput.isMandatory && !this.vendorTeamInput.value) {
      this.vendorTeamInput.isError = true;
      hasError = true;
    }

    return hasError;
  }

  //------------------------
  // reset function
  private resetInput() {
    // assume observe contract, contractSeverity and vendorTeam
    this.resetContractInput();
    this.resetEquipmentInput();
    this.resetEquipmentDisplayField();
    this.resetContractSeverityInput();
    this.resetTaskDescriptionInput();
    this.resetVendorTeamInput();
  }

  private resetContractInput() {
    this.contractInput.isDisabled = false;
    this.contractInput.isError = false;
    this.contractInput.isLoading = false;
    this.contractInput.isMandatory = true;
    this.contractInput.value = undefined;

    this.updatedContract.emit(undefined);
  }

  private resetEquipmentInput() {
    this.equipmentInput.isDisabled = false;
    this.equipmentInput.isError = false;
    this.equipmentInput.isLoading = false;
    this.equipmentInput.isMandatory = true;
    this.equipmentInput.value = undefined;

    this.updatedEquipment.emit(undefined);
  }

  private resetContractSeverityInput() {
    this.contractSeverityInput.isDisabled = false;
    this.contractSeverityInput.isError = false;
    this.contractSeverityInput.isLoading = false;
    this.contractSeverityInput.isMandatory = true;
    this.contractSeverityInput.value = undefined;
    this.severityDescription = undefined;

    this.updatedContractSeverity.emit(undefined);
  }

  private resetTaskDescriptionInput() {
    this.taskDescriptionInput.isDisabled = false;
    this.taskDescriptionInput.isError = false;
    this.taskDescriptionInput.isMandatory = true;
    this.taskDescriptionInput.value = '';
  }

  private resetVendorTeamInput() {
    this.vendorTeamInput.isDisabled = false;
    this.vendorTeamInput.isError = false;
    this.vendorTeamInput.isLoading = false;
    this.vendorTeamInput.isMandatory = true;
    this.vendorTeamInput.value = undefined;
  }

  private setAllFieldsNoError() {
    this.contractInput.isError = false;
    this.equipmentInput.isError = false;
    this.contractSeverityInput.isError = false;
    this.taskDescriptionInput.isError = false;
    this.vendorTeamInput.isError = false;
  }

  //------------------------
  // API
  private async requestContractList(isAppend: boolean = false) {
    let request = new JM.JMRequestContractsGetContractList();
    request.pageSize = this.contractSearch.pageSize;
    request.pageNumber = this.contractSearch.pageNumber;

    if (!!this.contractSearch.keywords) {
      request.filter = {
        contractNumber: this.contractSearch.keywords,
      };
    }

    this.contractInput.isLoading = true;
    const response: JM.JMResponseContractsGetContractList = await AppDelegate.sendJMRequest(request);
    this.contractInput.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.contractSearch.totalPageNumber = Math.ceil(response.payload.totalCount / request.pageSize);
    if (isAppend) {
      this.contractInput.optionList = this.contractInput.optionList.concat(response.payload.records);
    } else {
      this.contractInput.optionList = response.payload.records;
    }
  }

  private async requestEquipmentDetail(equipmentNumber: string) {
    let request = new JM.JMRequestEquipmentsEquipmentSummary();
    request.equipmentNumber = [equipmentNumber];
    request.includeSummary = true;
    request.pageSize = 1;
    request.pageNumber = 1;

    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (response.payload.records.length) {
      return response.payload.records[0];
    }
  }

  private async requestLocationDescription(locationCode: string) {
    let request = new JM.JMRequestLocationsLocationSummary();
    request.location = [locationCode];
    request.includeSummary = true;
    request.pageSize = 1000;
    request.pageNumber = 1;
    request.locationOnly = true;
    request.parameters = ['code', 'description'];

    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (response.payload.records.length) {
      const location = response.payload.records[0];
      const description = location.description[Session.selectedLanguage]
        ? location.description[Session.selectedLanguage]
        : location.description.en;
      return `${description} (${locationCode})`;
    }
  }

  private async requestContractSeverityList(isAppend: boolean = false,
    contractNumber, clientShortName, equipmentType, locationCode, districtCode) {
    let request = new JM.JMRequestContractsGetContractSeverityCriteriaList();
    request['contractNumber'] = contractNumber;
    request['clientShortName'] = clientShortName;
    request['equipmentType'] = equipmentType;
    request['locationCode'] = locationCode;
    request['district'] = districtCode;

    request.pageSize = this.contractSeveritySearch.pageSize;
    request.pageNumber = this.contractSeveritySearch.pageNumber;

    if (!!this.contractSeveritySearch.keywords) {
      request.filter = {
        name: this.contractSeveritySearch.keywords,
      };
    }

    this.contractSeverityInput.isLoading = true;
    const response: JM.JMResponseContractsGetContractSeverityCriteriaList = await AppDelegate.sendJMRequest(request);
    this.contractSeverityInput.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.contractSeveritySearch.totalPageNumber = Math.ceil(response.payload.totalCount / request.pageSize);

    const severitySet: Map<string, any> = new Map();
    response.payload.records.forEach((record) => {
      let severity = new JM.JMOBJ.ContractSeverity();
      severity._id = record['contractSeverityId'];
      severity.liftTrapped = record['contractSeverity'].liftTrapped;
      severity.name = record['contractSeverity'].name;

      !severitySet.has(severity._id) && severitySet.set(severity._id, severity);
    });

    if (isAppend) {
      this.contractSeverityInput.optionList = this.contractSeverityInput.optionList.concat(Array.from(severitySet.values()));
    } else {
      this.contractSeverityInput.optionList = Array.from(severitySet.values());
    }
  }

  private async requestContractSeverity(contractSeverityId, contractNumber) {
    let request = new JM.JMRequestContractsGetContractSeverityList();
    request.contractNumber = contractNumber;

    const response: JM.JMResponseContractsGetContractSeverityList = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if(response.payload && response.payload.records.length > 0) {
      return response.payload.records.find((record) => {
        return record._id == contractSeverityId;
      });
    }
  }

  private async requestVendorTeamList(isAppend: boolean = false) {
    let request = new JM.JMRequestContractsGetVendorTeamListByContract();
    request.contractNumber = this.contractInput.value.contractNumber;
    request.pageSize = this.vendorTeamSearch.pageSize;
    request.pageNumber = this.vendorTeamSearch.pageNumber;
    request.parameters = ['_id', 'name'];

    if (!!this.vendorTeamSearch.keywords) {
      request.filter = {
        name: this.vendorTeamSearch.keywords,
      };
    }

    this.vendorTeamInput.isLoading = true;
    const response: JM.JMResponseContractsGetVendorTeamListByContract = await AppDelegate.sendJMRequest(request);
    this.vendorTeamInput.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.vendorTeamSearch.totalPageNumber = Math.ceil(response.payload.totalCount / request.pageSize);
    if (isAppend) {
      this.vendorTeamInput.optionList = this.vendorTeamInput.optionList.concat(response.payload.records);
    } else {
      this.vendorTeamInput.optionList = response.payload.records;
    }
  }

  //------------------------
  // Contract dropdown event
  onClearContract() {
    this.resetContractOption();
  }

  onContractScrollToEnd() {
    if (this.contractSearch.pageNumber < this.contractSearch.totalPageNumber) {
      this.contractSearch.pageNumber++;
      this.requestContractList(true);
    }
  }

  onSearchContract(event) {
    this.contractSearch.keywords = event.term;
    this.contractSearch.search$.next();
  }

  onChangedContract() {
    this.updatedContract.emit(this.contractInput.value);

    this.resetEquipmentValue();
    this.resetEquipmentOption();
    this.resetEquipmentDisplayField();
    this.resetContractSeverityValue();
    this.resetContractSeverityOption();

    this.vendorTeamInput.value = undefined;
    this.resetVendorTeamOption();
  }

  private clearContractOption() {
    this.contractInput.optionList = [];
    this.contractSearch.pageNumber = 1;
    this.contractSearch.totalPageNumber = 0;
  }

  private resetContractOption() {
    this.contractSearch.keywords = null;
    this.contractSearch.search$.next();
  }

  private setContractSearchObserver() {
    this.contractSearch.search$.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.clearContractOption();
      this.requestContractList(false);
    });
  }

  //------------------------
  // Equipment ID dropdown event
  onClickEquipmentInputBox() {
    this.equipmentSelectorPanel.toggle();
  }

  onClearEquipment() {
    this.onUpdatedEquipment(undefined); // manual trigger onUpdate(undefined) as ng-select behaviour
    this.resetEquipmentOption();
  }

  async onUpdatedEquipment(equipment? : JM.JMOBJ.Equipment) {
    this.equipmentInput.value = equipment;
    this.equipmentSelectorPanel.close();

    this.updatedEquipment.emit(this.equipmentInput.value);
    
    if (this.equipmentInput.value) {
      this.equipmentNumber = this.equipmentInput.value.equipmentNumber;
      this.equipmentOwner =  this.equipmentInput.value.clientShortName;
      this.equipmentDescription = this.equipmentInput.value.description;
      this.requestLocationDescription(this.equipmentInput.value.location).then((description) => {
        this.equipmentFunctionalLocation = description;
      });
    } else {
      this.resetEquipmentDisplayField();
    }
    this.resetContractSeverityValue();

    const firstPrefill = true;
    this.resetContractSeverityOption(firstPrefill);
  }

  private resetEquipmentValue() {
    this.equipmentInput.value = undefined;
    this.updatedEquipment.emit(this.equipmentInput.value);
  }

  private resetEquipmentOption() {
    this.equipmentIdSearch.keywords = null;
    this.equipmentIdSearch.search$.next();
  }

  private resetEquipmentDisplayField() {
    this.equipmentNumber = undefined;
    this.equipmentOwner = undefined;
    this.equipmentDescription = undefined;
    this.equipmentFunctionalLocation = undefined;
  }

  //------------------------
  // Contract Severity dropdown event
  onClearContractSeverity() {
    this.resetContractSeverityOption();
  }

  onContractSeverityScrollToEnd() {
    if (this.contractSeveritySearch.pageNumber < this.contractSeveritySearch.totalPageNumber) {
      this.contractSeveritySearch.pageNumber++;

      if (this.contractInput.value && this.equipmentInput.value) {
        this.requestContractSeverityList(true,
          this.contractInput.value.contractNumber,
          this.equipmentInput.value.clientShortName,
          this.equipmentInput.value.equipmentType,
          this.equipmentInput.value.location,
          this.equipmentInput.value.districtCode);
      }
    }
  }

  onSearchContractSeverity(event) {
    this.contractSeveritySearch.keywords = event.term;
    this.contractSeveritySearch.search$.next();
  }

  onChangedContractSeverity() {
    if (this.contractSeverityInput.value) {
      this.requestContractSeverity(
        this.contractSeverityInput.value._id,
        this.contractInput.value.contractNumber
      ).then((contractSeverity) => {
        if(contractSeverity) {
          this.severityDescription = contractSeverity.description;
        }
        this.updatedContractSeverity.emit(contractSeverity);
      });
    } else {
      this.severityDescription = undefined;
    }
  }

  private clearContractSeverityOption() {
    this.contractSeverityInput.optionList = [];
    this.contractSeveritySearch.pageNumber = 1;
    this.contractSeveritySearch.totalPageNumber = 0;
    this.severityDescription = undefined;
  }

  private resetContractSeverityValue() {
    this.contractSeverityInput.value = undefined;
    this.updatedContractSeverity.emit(this.contractSeverityInput.value);
  }

  private resetContractSeverityOption(firstPrefill?: boolean) {
    const options = [];
    firstPrefill && options.push(this.ngOptionPrefill);
    this.contractSeveritySearch.keywords = null;
    this.contractSeveritySearch.search$.next(options);
  }

  private setContractSeveritySearchObserver() {
    this.contractSeveritySearch.search$.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(async (options) => {
      this.clearContractSeverityOption();

      if (this.contractInput.value && this.equipmentInput.value) {
        await this.requestContractSeverityList(false,
          this.contractInput.value.contractNumber,
          this.equipmentInput.value.clientShortName,
          this.equipmentInput.value.equipmentType,
          this.equipmentInput.value.location,
          this.equipmentInput.value.districtCode);

        if(options.includes(this.ngOptionPrefill)) {
          this.contractSeverityInput.value = this.contractSeverityInput.optionList[0];
          this.onChangedContractSeverity();
        }
      }
    });
  }

  //------------------------
  // VendorTeam dropdown event
  onClearVendorTeam() {
    this.resetVendorTeamOption();
  }

  onVendorTeamScrollToEnd() {
    if (this.vendorTeamSearch.pageNumber < this.vendorTeamSearch.totalPageNumber) {
      this.vendorTeamSearch.pageNumber++;
      this.requestVendorTeamList(true);
    }
  }

  onSearchVendorTeam(event) {
    this.vendorTeamSearch.keywords = event.term;
    this.vendorTeamSearch.search$.next();
  }

  onChangedVendorTeam() {}

  private clearVendorTeamOption() {
    this.vendorTeamInput.optionList = [];
    this.vendorTeamSearch.pageNumber = 1;
    this.vendorTeamSearch.totalPageNumber = 0;
  }

  private resetVendorTeamOption() {
    this.vendorTeamSearch.keywords = null;
    this.vendorTeamSearch.search$.next();
  }

  private setVendorTeamSearchObserver() {
    this.vendorTeamSearch.search$.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
      this.clearVendorTeamOption();

      if (this.contractInput.value) {
        this.requestVendorTeamList(false);
      }
    });
  }

  //------------------------
  // get property
  get isCreateMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.CREATE;
  }
  get isEditMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.EDIT;
  }
  get isViewMode(): boolean {
    return this.pageMode === JMENUM.JMPageMode.VIEW;
  }

  get textAreaMaxLength(): number {
    return Constants.TEXTAREA_MAX_LENGTH;
  }

  //-------- for view mode --------
  get jobStatusString(): string {
    if (this.jobCard && this.jobCard.status) {
      return JMLanguage.translate(`jobcard.status.${this.jobCard.status}`);
    }
    return '';
  }
}

class CmTaskContractInput extends DropdownControl {
  value?: JMOBJ.MaintenanceTermContract;
  optionList: JMOBJ.MaintenanceTermContract[] = [];

  constructor() {
    super();
  }
}

class CmTaskEquipmentInput extends DropdownControl {
  value?: JMOBJ.Equipment;
  optionList: JMOBJ.Equipment[] = [];

  constructor() {
    super();
  }
}

class CmTaskContractSeverityInput extends DropdownControl {
  value?: JMOBJ.ContractSeverity;
  optionList: JMOBJ.ContractSeverity[] = [];

  constructor() {
    super();
  }
}

class CmTaskTaskDescriptionInput extends InputControl {
  value: string;

  constructor() {
    super();
  }
}

class CmTaskVendorTeamInput extends DropdownControl {
  value?: JMOBJ.VendorTeam;
  optionList: JMOBJ.VendorTeam[] = [];

  constructor() {
    super();
  }
}
