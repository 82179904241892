import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import {
  JMResponseAddEquipmentToPmPlan,
  JMResponseRemoveEquipmentFromPmPlan,
} from '@ccep/CCEPConnector-ts/lib/JMConnector/JMConnector';

import { TablexComponentParams } from 'src/app/shared/tablex/tablex.component';
import { TablexColumnFilterOption, TablexColumnFilterType } from 'src/app/shared/tablex/tablexColumnType';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { UserHelper } from 'src/app/core/user-helper';
import { ActionSidebarV2Service } from 'src/app/shared/action-sidebar-v2.service';
import { ActionButtonI } from 'src/app/shared/action-sidebar-v2/action-sidebar-v2.model';
import { breadcrumbsComponentI } from 'src/app/shared/breadcrumbs/breadcrumbs.component';
import { GetPmPeriodAssignedEquipmentListParams, GetPmPeriodUnassignedEquipmentListParams,
  PmPeriodAssignedEquipmentTableColumnIndex,
  PmPeriodEquipmentListButtonList,
  equipmentTableHeader, equipmentActionTableHeader, equipmentActionTableIndex } from './pm-period-equipment-list.model';

@Component({
  selector: 'app-pm-period-equipment-list',
  templateUrl: './pm-period-equipment-list.component.html',
  styleUrls: ['./pm-period-equipment-list.component.scss'],
})
export class PmPeriodEquipmentListComponent {
  //Service
  // route: ActivatedRoute;
  location: Location;
  actionSidebarV2Service: ActionSidebarV2Service;
  pageMode: JMENUM.JMPageMode = JMENUM.JMPageMode.EDIT;
  JMPageMode = JMENUM.JMPageMode;

  //Tabs
  tabOptions = {
    assigned: JMLanguage.translate('component.pm-plan-equipment-list.assigned'),
    unassigned: JMLanguage.translate('component.pm-plan-equipment-list.unassigned'),
  };
  activeTab = this.tabOptions.unassigned;

  //TableX
  equipmentTablexParam: TablexComponentParams = {};
  tablexFilter: any = {};
  statusOptions: TablexColumnFilterOption = new TablexColumnFilterOption();
  
  equipmentActionTablexParam: TablexComponentParams = {};

  //Action buttons
  actionSidebar: {
    dataList: ActionButtonI[];
    isDisabled: boolean;
    isLoading: boolean;
  } = {
    dataList: [],
    isDisabled: false,
    isLoading: false,
  };

  //Others
  pmPlanNumber: string;
  pmPeriodId: string;
  pmPlan: JM.JMOBJ.PmPlan;
  pmPeriod: JM.JMOBJ.PmPeriod;
  breadcrumbs: breadcrumbsComponentI[];
  equipmentList: JM.JMOBJ.Equipment[] = [];
  equipmentActionList: Map<String, any> = new Map;
  equipmentListVersion: number;
  isSubmitting: boolean;

  constructor(private route: ActivatedRoute, location: Location, actionSidebarV2Service: ActionSidebarV2Service) {
    // route.data.subscribe((data) => {
    //   if (data.mode) {
    //     this.pageMode = data.mode;
    //   }
    // });
    this.location = location;
    this.actionSidebarV2Service = actionSidebarV2Service;
  }

  async ngOnInit() {
    const route = AppDelegate.getRoute();
    const trimRoute = route.replace(/^(\/pm\/periods)(\/.*)(\/.*)(\/.*)$/g, '$1$4');

    if (!AppDelegate.checkPagePermissions(trimRoute)) {
      return;
    }

    this.pmPlanNumber = this.route.snapshot.paramMap.get('planNumber');
    this.pmPeriodId = this.route.snapshot.paramMap.get('periodId');

    this.initEquipmentTable();
    this.initEquipmentActionTable();

    this.getPmPlan(this.pmPlanNumber).then(async () => {
      if (this.isEquipmentApprovalPending) {
        const equipmentChangeList = this.pmPlan.approvalObject.customField?.pmPeriodEquipmentEdit?.equipmentChangeList;
        if (Array.isArray(equipmentChangeList) && equipmentChangeList.length > 0) {
          await this.getEquipmentPendingSummary(equipmentChangeList);
          this.renderEquipmentActionTable();
        }
      }
      this.initActionBtns();
    });
    this.getPmPeriod(this.pmPeriodId);
    this.setBreadcrumbs();
    
    await this.requestTableData();
    this.renderEquipmentTable();
  }

  async getPmPlan(pmPlanNumber) {
    const request = new JM.JMRequestVpGetPmPlan();
    request.pmPlanNumber = pmPlanNumber;

    const response: JM.JMResponseVpGetPmPlan= await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }
    
    this.pmPlan = response.payload.pmPlan;
  }

  async getPmPeriod(pmPeriodId) {
    const request = new JM.JMRequestGetPmPeriod();
    request.periodId = pmPeriodId;

    const response: JM.JMResponseGetPmPeriod = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.pmPeriod = response.payload;
  }

  async getEquipmentPendingSummary(equipmentChangeList: JM.JMOBJ.VpPmPeriodEquipmentChangeObject[]) {
    const request = new JM.JMRequestEquipmentsEquipmentSummary();
    request.equipmentNumber = equipmentChangeList.map(equipChange => equipChange.equipmentNumber);

    const response: JM.JMResponseEquipmentsEquipmentSummary = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    Array.isArray(response.payload.records) && response.payload.records.forEach((record, index) => {
      const equipmentNumber = record[GetPmPeriodAssignedEquipmentListParams.equipmentNumber];
      const description = record[GetPmPeriodAssignedEquipmentListParams.description];
      const location = record[GetPmPeriodAssignedEquipmentListParams.location] ? `(${record[GetPmPeriodAssignedEquipmentListParams.location]})` : [];
      const locationDescription = record[GetPmPeriodAssignedEquipmentListParams.locationDescription];
      const clientShortName = record[GetPmPeriodAssignedEquipmentListParams.clientShortName];
      const equipmentType = record[GetPmPeriodAssignedEquipmentListParams.equipmentType];
      const action = equipmentChangeList[index].action;

      this.equipmentActionList.set(equipmentNumber, [
        equipmentNumber,
        description,
        `${(locationDescription? locationDescription[JMLanguage.getCurrentLanguage()] : '')} ${location}`,
        clientShortName,
        equipmentType,
        JMLanguage.translate(`component.pm-plan-equipment-list.${action}`),
        null,
        action,
      ]);
    });
  }

  setBreadcrumbs = () => {
    this.breadcrumbs = [
      {
        id: 'breadcrumbs-plan-list',
        name: JMLanguage.translate('breadcrumbs.pm-plan'),
        route: `/pm/plans`,
      },
      {
        id: 'breadcrumbs-pm-plan-number',
        name: this.pmPlanNumber,
        route: `/pm/plans/${this.pmPlanNumber}`,
      },
      {
        id: 'breadcrumbs-sn-number',
        name: JMLanguage.translate('breadcrumbs.equipment'),
        route: ``,
        currentPage: true,
      },
    ];
  };

  initActionBtns = () => {
    const actionList = ['close'];
    !this.isEquipmentApprovalPending && actionList.unshift('save');
    this.filterActionButton(actionList);
  };

  private filterActionButton(actionList: string[]) {
    for (const action of actionList) {
      const button: ActionButtonI = PmPeriodEquipmentListButtonList[action];
      const hasPermissionList = button && button.permissionList && button.permissionList.length;

      if (hasPermissionList) {
        const allowAction = UserHelper.hasEitherOnePermission(button.permissionList);
        if (allowAction) {
          this.addActionButton(button);
        }
      } else {
        this.addActionButton(button);
      }
    }
  }

  private addActionButton(actionButton: ActionButtonI) {
    let handler = () => {};

    switch (actionButton.action) {
      case 'save':
        handler = async () => {
          if (this.equipmentActionList.size > 0) {
            const buttonHandler = async () => {
              await this.submitEquipment(Array.from(this.equipmentActionList.values()).map((entry) => {
                return {
                  equipmentNumber: entry[equipmentActionTableIndex.equipmentNumber],
                  action: entry[equipmentActionTableIndex.action]
                }
              }));
              AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.request-submitted'));
              AppDelegate.routeToPage(`/pm/plans/${this.pmPlanNumber}`);
            };
            this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, buttonHandler);
          }
        };
        break;
      case 'close':
        handler = () => {
          if (this.pmPlanNumber) {
            const buttonHandler = async () => {
              AppDelegate.routeToPage(`/pm/plans/${this.pmPlanNumber}`);
            };

            if(!this.isEquipmentApprovalPending && this.equipmentActionList.size > 0) {
              this.actionSidebarV2Service.popUpConfirmationDialog(actionButton, buttonHandler);
            } else buttonHandler();
          }
        };
        break;
      default:
        break;
    }

    actionButton.handler = handler;
    this.actionSidebar.dataList.push(actionButton);
  }

  onClickedActionButton(actionButton: ActionButtonI) {
    actionButton.handler();
  }

  // action tablex functions
  initEquipmentActionTable() {
    this.equipmentActionTablexParam = {
      pageSizeOptions: [10, 25, 100],
      currentPageSize: 10,
      currentPage: 1,
      pageCount: 0,
      enableSetPageSize: true,
      enablePagination: true,
      enableSort: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      isLoadingTable: false,
      // tableRow: 'd-flex',
      // tableClass: 'user-table',
      // tableWrapperClass: 'table-min-width',
      // onFilterChanged: this.onFilterChanged,
      // onFilterClear: this.onFilterClear,
      onPageNumberClicked: this.onActionPageNumberClicked.bind(this),
      onPageSizeClicked: this.onActionPageSizeClicked.bind(this),
      // onRowClicked: this.onRowClicked,
      // onRowUnselected: this.onRowUnselected,
      content: [],
      highlightedRows: [],
    };
    this.equipmentActionTablexParam.headers = equipmentActionTableHeader;
  }

  renderEquipmentActionTable() {
    const {currentPageSize, currentPage} = this.equipmentActionTablexParam;
    const startIndex = (currentPage-1) * currentPageSize;
    this.equipmentActionTablexParam.content = Array.from(this.equipmentActionList.values()).slice(startIndex, startIndex+currentPageSize);

    this.equipmentActionTablexParam.pageCount = Math.ceil(this.equipmentActionList.size / currentPageSize);
  }

  onActionPageSizeClicked(pageSize: number) {
    this.equipmentActionTablexParam.currentPageSize = pageSize;
    this.equipmentActionTablexParam.currentPage = 1;
    this.renderEquipmentActionTable();
  };

  onActionPageNumberClicked(pageNumber: number) {
    this.equipmentActionTablexParam.currentPage = pageNumber;
    this.renderEquipmentActionTable();
  };

  // Equip tablex functions
  initEquipmentTable() {
    this.equipmentTablexParam = {
      pageSizeOptions: [10, 25, 100],
      currentPageSize: 10,
      currentPage: 1,
      pageCount: 0,
      enableSetPageSize: true,
      enablePagination: true,
      enableSort: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      isLoadingTable: false,
      // tableRow: 'd-flex',
      // tableClass: 'user-table',
      // tableWrapperClass: 'table-min-width',
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowClicked: this.onRowClicked,
      // onRowUnselected: this.onRowUnselected,
      content: [],
      highlightedRows: [],
    };

    this.statusOptions.items = [
      { label: JMLanguage.translate('pm-period.status.completed'), value: JMENUM.PMPeriodEquipmentStatus.COMPLETED },
      { label: JMLanguage.translate('pm-period.status.outstanding'), value: JMENUM.PMPeriodEquipmentStatus.OUTSTANDING },
    ];
    this.statusOptions.bindLabel = "label";
    this.statusOptions.bindValue = "value";
    this.statusOptions.change = this.onOptionFilterChanged.bind(this);
    // this.statusOptions.onScrollToEnd = this.onStatusScrollToEnd;
    // this.statusOptions.onClear = this.onStatusKeywordsClear;
    // this.statusOptions.onSearch = this.onSearchStatusOptions.bind(this);
    // this.statusOptions.searchFn = this.onFilterStatusOptions;

    this.equipmentTablexParam.headers = equipmentTableHeader;
    this.equipmentTablexParam.headers[PmPeriodAssignedEquipmentTableColumnIndex.status].filterDropdownOption = this.statusOptions;
  }

  renderEquipmentTable() {
    switch (this.activeTab) {
      case this.tabOptions.assigned:
        this.equipmentTablexParam.headers[PmPeriodAssignedEquipmentTableColumnIndex.status].class = 'w-10';
        break;
      case this.tabOptions.unassigned:
        this.equipmentTablexParam.headers[PmPeriodAssignedEquipmentTableColumnIndex.status].class = 'w-10 d-none';
        break;
    }

    this.equipmentTablexParam.content = this.equipmentList.map((eq, index) => {
      const equipmentNumber = eq[GetPmPeriodAssignedEquipmentListParams.equipmentNumber];
      const description = eq[GetPmPeriodAssignedEquipmentListParams.description];
      const location = eq[GetPmPeriodAssignedEquipmentListParams.location] ? `(${eq[GetPmPeriodAssignedEquipmentListParams.location]})` : [];
      const locationDescription = eq[GetPmPeriodAssignedEquipmentListParams.locationDescription];
      const clientShortName = eq[GetPmPeriodAssignedEquipmentListParams.clientShortName];
      const equipmentType = eq[GetPmPeriodAssignedEquipmentListParams.equipmentType];
      const status = eq[GetPmPeriodAssignedEquipmentListParams.status];

      this.equipmentActionList.has(equipmentNumber) && this.selectRow(index);
      return [
        equipmentNumber,
        description,
        `${(locationDescription? locationDescription[JMLanguage.getCurrentLanguage()] : '')} ${location}`,
        clientShortName,
        equipmentType,
        status ? JMLanguage.translate(`pm-period.status.${status}`) : "",
      ];
    });
  }

  async requestTableData(pageNumber?: number, pageSize?: number): Promise<boolean> {
    this.selectRow(null);
    let request: JM.JMRequestGetVpPmPeriodAssignedEquipmentList | JM.JMRequestGetVpPmPeriodUnassignedEquipmentList;
    switch (this.activeTab) {
      case this.tabOptions.assigned:
        request = new JM.JMRequestGetVpPmPeriodAssignedEquipmentList();
        request.parameters = Object.values(GetPmPeriodAssignedEquipmentListParams);
        break;
      case this.tabOptions.unassigned:
        request = new JM.JMRequestGetVpPmPeriodUnassignedEquipmentList();
        request.parameters = Object.values(GetPmPeriodUnassignedEquipmentListParams);
        break;
    }
    this.equipmentTablexParam.isLoadingTable = true;
    request.periodId = this.pmPeriodId;
    request.sortBy = GetPmPeriodAssignedEquipmentListParams.equipmentNumber;
    request.sortOrder = JMENUM.SortOrder.ASC;
    request.pageNumber = pageNumber ? pageNumber : 1;
    request.pageSize = pageSize ? pageSize : this.equipmentTablexParam.currentPageSize;
    request = this.translateTableFilter(request);

    this.equipmentList = [];
    const response: JM.JMResponseGetVpPmPeriodAssignedEquipmentList | JM.JMResponseGetVpPmPeriodUnassignedEquipmentList =
    await AppDelegate.sendJMRequest(request);
    this.equipmentTablexParam.isLoadingTable = false;
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return false;
    }
    this.equipmentList = response.payload.records;
    this.equipmentListVersion = response.payload.periodVersion;
    this.equipmentTablexParam.pageCount = Math.ceil(response.payload.totalCount / request.pageSize);
    this.equipmentTablexParam.currentPage = request.pageNumber;
    this.equipmentTablexParam.currentPageSize = request.pageSize;
    return true;
  }

  selectRow(index: number) {
    this.equipmentTablexParam.selectedRowIndex = null;
    if (index == null) {
      this.equipmentTablexParam.highlightedRows = [];
    } else {
      let prevState = this.equipmentTablexParam.highlightedRows[index];
      this.equipmentTablexParam.highlightedRows[index] = !prevState;
    }
  }

  async submitEquipment(equipmentlist: unknown) {
    let submitResult: boolean;

    const request = new JM.JMRequestSubmitPmPeriodEquipmentChangeRequest();
    request.equipmentChangeList = equipmentlist as JMOBJ.VpPmPeriodEquipmentChangeObject[];
    request.pmPeriodId = this.pmPeriodId;
    request.version = this.equipmentListVersion;

    this.isSubmitting = true;
    const response: JM.JMResponseSubmitPmPeriodEquipmentChangeRequest = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }
    this.isSubmitting = false;

    submitResult && (await this.requestTableData(1)) && this.renderEquipmentTable();
  }

  private async addEquipmentToPmPlan(equipmentlist: string[]): Promise<boolean> {
    if (!this.pmPlanNumber) return false;

    let request = new JM.JMRequestAddEquipmentToPmPlan();
    request.pmPlanNumber = this.pmPlanNumber;
    request.equipmentNumbers = equipmentlist;
    request.version = this.equipmentListVersion;

    const response: JMResponseAddEquipmentToPmPlan = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return false;
    }
    return true;
  }

  private async removeEquipmentFromPmPlan(equipmentlist: string[]): Promise<boolean> {
    if (!this.pmPlanNumber) return false;

    let request = new JM.JMRequestRemoveEquipmentFromPmPlan();
    request.pmPlanNumber = this.pmPlanNumber;
    request.equipmentNumbers = equipmentlist;
    request.version = this.equipmentListVersion;

    const response: JMResponseRemoveEquipmentFromPmPlan = await AppDelegate.sendJMRequest(request);
    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return false;
    }
    return true;
  }

  
  translateTableFilter(request) {
    request.filter = {};
    for (let key in this.tablexFilter) {
      if (this.tablexFilter[key]) {
        switch (key) {
          case 'status':
            request[key] = [this.tablexFilter[key]];
            break;
          default:
            request.filter[key] = this.tablexFilter[key];
            break;
        }
      }
    }
    return request;
  }

  async onOptionFilterChanged(event, i, header) {
    if (header.filterDropdownOption.selectedValue) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }

    await this.requestTableData(1);
    this.renderEquipmentTable();
  }

  onFilterChanged = async (event, index, header, filter) => {
    for (let key in filter) {
      if(!filter[key]){
        delete this.tablexFilter[key];
      }else{
        this.tablexFilter[key] = filter[key];
      }
    }

    await this.requestTableData(1);
    this.renderEquipmentTable();
  }

  onFilterClear = async () => {
    this.tablexFilter = {};
    this.equipmentTablexParam['filter'] = {};
    await this.requestTableData(1);
    this.renderEquipmentTable();
  }

  onSubmitClick = () => {
    let action, currentLength, newLength;
    switch (this.activeTab) {
      case this.tabOptions.assigned:
        action = JMENUM.VpPMPeriodEquipmentChangeAction.UNASSIGN;
        break;
      case this.tabOptions.unassigned:
        action = JMENUM.VpPMPeriodEquipmentChangeAction.ASSIGN;
        break;
    }

    currentLength = this.equipmentActionList.size;
    this.equipmentTablexParam.highlightedRows.forEach((el, index) => {
      if (el) {
        let equipmentNumber = this.equipmentTablexParam.content[index][equipmentActionTableIndex.equipmentNumber];
        const buttons = [{
          "id": `delete-button_${equipmentNumber}`,
          "name": "",
          "class": "delete-button btn p-1",
          "icon": "fas fa-times font-size-xl",
          "onClicked": this.onDeleteButtonClicked.bind(this),
          "equipmentNumber": equipmentNumber
        }];

        this.equipmentActionList.set(equipmentNumber, [
          ...this.equipmentTablexParam.content[index].slice(0, PmPeriodAssignedEquipmentTableColumnIndex.status),
          JMLanguage.translate(`component.pm-plan-equipment-list.${action}`),
          buttons,
          action
        ]);
      }
    });
    newLength = this.equipmentActionList.size;
    
    if(newLength > currentLength) {
      this.renderEquipmentActionTable();
    }
  };

  onDeleteButtonClicked(button) {
    if(button.equipmentNumber) {
      this.equipmentActionList.delete(button.equipmentNumber);
      this.renderEquipmentActionTable();
    }
  }

  onRowClicked = (index, row) => {
    this.selectRow(index);
  };

  onPageSizeClicked = async (pageSize: number) => {
    await this.requestTableData(1, pageSize);
    this.renderEquipmentTable();
  };

  onPageNumberClicked = async (pageNumber: number) => {
    await this.requestTableData(pageNumber);
    this.renderEquipmentTable();
  };

  onClickSetActiveTab = async (tab) => {
    this.activeTab = tab;
    await this.requestTableData(1);
    this.renderEquipmentTable();
  };

  onSelectAll = () => {
    this.equipmentTablexParam.highlightedRows = this.equipmentTablexParam.content.map((e) => true);
  };

  onUnselectAll = () => {
    this.equipmentTablexParam.highlightedRows = [];
  };

  get submitBtnName() {
    switch (this.activeTab) {
      case this.tabOptions.unassigned:
        return JMLanguage.translate('component.pm-plan-equipment-list.assign');
      case this.tabOptions.assigned:
        return JMLanguage.translate('component.pm-plan-equipment-list.unassign');
      default:
        return undefined;
    }
  }

  get isActionBarEnabled() {
    return this.actionSidebar.dataList && this.actionSidebar.dataList.length > 0;
  }

  get numberOfRowsSelected(): number {
    if (this.equipmentTablexParam && this.equipmentTablexParam.highlightedRows) {
      return this.equipmentTablexParam.highlightedRows.filter((e) => e).length;
    }
    return 0;
  }

  get enableAssignButton(): boolean {
    if (this.isEquipmentApprovalPending) return false;

    return this.pmPeriod?.status != JMENUM.PMPeriodStatus.COMPLETED || this.activeTab != this.tabOptions.assigned; 
  }

  get isEquipmentApprovalPending(): boolean {
    return this.pmPlan?.approvalObject?.type == JMENUM.ApprovalType.PMPERIOD_EQUIPMENT_EDIT;
  } 
}
