import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { formatDate } from '@angular/common';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { saveAs } from 'file-saver';

import { AppDelegate } from 'src/app/core/AppDelegate';
import { ngbDateToString, stringToMoment } from 'src/app/core/Formatter';
import { Session } from 'src/app/core/session';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
})
export class AdvancedSearchComponent implements OnInit {
  @Output() onSearch: EventEmitter<SnSearchCriteriaI> = new EventEmitter();

  isSearching: boolean = false;
  isCollapse: boolean = false;
  isDisabled: boolean = false; //For Not Ready Function
  isDownloading: boolean = false;

  showCreationTimeErrorMsg: boolean = false;
  isCreationPeriodError: boolean = false;

  searchSelections: SearchSelectionI;

  startDateNgb: NgbDateStruct;
  endDateNgb: NgbDateStruct;
  // Client Dropdown
  private searchClientObserver = new Subject<any[]>();
  clientOptions: any = [];
  searchClientKeyword: string = null;
  clientPageSize: number = 100;
  clientPageNumber: number = 1;
  clientTotalPage: number;
  isLoadingClient: boolean = false;

  // Contract Dropdown
  private searchContractObserver = new Subject<any[]>();
  contractOptions: any = [];
  searchContractKeyword: string = null;
  contractPageSize: number = 100;
  contractPageNumber: number = 1;
  contractTotalPage: number;
  isLoadingContract: boolean = false;

  // Team Dropdown
  private searchTeamObserver = new Subject<any[]>();
  teamOptions: any = [];
  searchTeamKeyword: string = null;
  teamPageSize: number = 100;
  teamPageNumber: number = 1;
  teamTotalPage: number;
  isLoadingTeam: boolean = false;

  // Equipment Category Dropdown
  private searchEquipmentCategoryObserver = new Subject<any[]>();
  equipmentCategoryOptions: any = [];
  searchEquipmentCategoryKeyword: string = null;
  equipmentCategoryPageSize: number = 100;
  equipmentCategoryPageNumber: number = 1;
  equipmentCategoryTotalPage: number;
  isLoadingEquipmentCategory: boolean = false;

  // Equipment Type Dropdown
  equipmentTypeOptionsFull: any = [];
  private searchEquipmentTypeObserver = new Subject<any[]>();
  equipmentTypeOptions: any = [];
  searchEquipmentTypeKeyword: string = null;
  equipmentTypePageSize: number = 100;
  equipmentTypePageNumber: number = 1;
  equipmentTypeTotalPage: number;
  isLoadingEquipmentType: boolean = false;

  // Location Dropdown
  private searchLocationObserver = new Subject<any[]>();
  locationOptions: any = [];
  searchLocationKeyword: string = null;
  locationPageSize: number = 100;
  locationPageNumber: number = 1;
  locationTotalPage: number;
  isLoadingLocation: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.initFilters();
    this.loadFilterOptionsFromLocal();
    this.onClickSearch();
  }

  async initFilters() {
    this.searchSelections = {
      snNumber: null,

      client: null,
      contracts: null,
      handlingTeam: null,
      equipmentCategory: null,
      equipmentType: null,
      location: null,
      faultDetail: null,

      priority: {},
      status: {},
      startDate: null,
      endDate: null,
    };

    const priorityList = Object.values(JMENUM.JMPriority).filter(val => typeof val === "number");
    for (const priority of priorityList) {
      this.searchSelections.priority[priority] = false;
    }

    this.startDateNgb = null;
    this.endDateNgb = null;
    const statusList = Object.values(JMENUM.SnStatus).filter(val => typeof val === "number");
    for (const status of statusList) {
      this.searchSelections.status[status] = false;
    }

    this.searchClientObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchClients.bind(this));
    this.searchContractObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchContracts.bind(this));
    this.searchTeamObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchTeams.bind(this));
    this.searchLocationObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(this.searchLocations.bind(this));


    this.equipmentCategoryOptions = Object.values(Session.getEquipmentCategory()).map(category => {
      const cat: any = category;
      return {
        value: cat.code,
        label: `${cat.code} - ${cat.description[JMLanguage.getCurrentLanguage()]}`,
        types: cat.equipmentType,
      };
    });
    this.equipmentTypeOptionsFull = Object.values(Session.getEquipmentType()).map(type => {
      const t: any = type;
      return {
        value: t.code,
        label: `${t.code} - ${t.description[JMLanguage.getCurrentLanguage()]}`,
      };
    });
    this.equipmentTypeOptions = this.equipmentTypeOptionsFull;
  }

  resetFilters() {
    this.searchSelections.snNumber = null;

    this.searchSelections.client = null;
    this.searchSelections.contracts = null;
    this.searchSelections.handlingTeam = null;
    this.searchSelections.equipmentCategory = null;
    this.searchSelections.equipmentType = null;
    this.searchSelections.location = null;
    this.searchSelections.faultDetail = null;

    for (const key in this.searchSelections.priority) {
      this.searchSelections.priority[key] = false;
    }
    for (const key in this.searchSelections.status) {
      this.searchSelections.status[key] = false;
    }
    this.searchSelections.startDate = null;
    this.searchSelections.endDate = null;

    this.startDateNgb = null;
    this.endDateNgb = null;
  }

  private getSearchCriteria(searchSelections: SearchSelectionI) {
    let criteria: SnSearchCriteriaI = {} as SnSearchCriteriaI;

    if (!!searchSelections.snNumber) {
      criteria.filter = {
        snNumber: !!searchSelections.snNumber ? searchSelections.snNumber : undefined,
      }
    }

    criteria.clientList = !!searchSelections.client ? [searchSelections.client] : undefined;
    criteria.contractNumberList = !!searchSelections.contracts ? searchSelections.contracts : undefined;
    criteria.vendorTeamIdList = !!searchSelections.handlingTeam ? [searchSelections.handlingTeam] : undefined;
    criteria.equipmentCategoryList = !!searchSelections.equipmentCategory ? [searchSelections.equipmentCategory] : undefined;
    criteria.equipmentTypeList = !!searchSelections.equipmentType ? [searchSelections.equipmentType] : undefined;
    criteria.locationCodeList = !!searchSelections.location ? [searchSelections.location] : undefined;
    criteria.faultDetail = !!searchSelections.faultDetail ? searchSelections.faultDetail : undefined;

    const timeFrom = stringToMoment(searchSelections.startDate, 'YYYYMMDD');
    const timeTo = stringToMoment(searchSelections.endDate, 'YYYYMMDD');
    criteria.createTimeFrom = timeFrom ? timeFrom.toDate() : undefined;
    criteria.createTimeTo = timeTo ? timeTo.add(1, 'd').subtract(1, 'ms').toDate() : undefined;

    const priority = [];
    for (const key in searchSelections.priority) {
      searchSelections.priority[key] && priority.push(key);
    }
    criteria.priorityList = priority.length > 0 ? priority : undefined;

    const status = [];
    for (const key in searchSelections.status) {
      searchSelections.status[key] && status.push(key);
    }
    criteria.statusList = status.length > 0 ? status : undefined;

    return criteria;
  }

  private emitSearchEvent() {
    this.isCreationPeriodError = false;
    const searchCriteria = this.getSearchCriteria(this.searchSelections);
    this.onSearch.emit(searchCriteria);
  }


  // client
  onChangeClientFilter(event) {
    this.searchClientKeyword = event.term;
    this.searchClientObserver.next();
  }

  onClearClientFilter() {
    this.searchClientKeyword = null;
    this.searchClients();
  }

  async onScrollToEndClient() {
    if (this.clientPageNumber < this.clientTotalPage) {
      this.clientPageNumber++;

      let clients = await this.requestClientList(this.clientPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.clientOptions = [...this.clientOptions, ...clients];
    }
  }

  async searchClients() {
    this.clientOptions = [];
    this.clientPageNumber = 1;
    this.clientTotalPage = null;
    this.clientOptions = await this.requestClientList(this.clientPageNumber);
  }

  private async requestClientList(pageNumber?: number) {
    const request = new JM.JMRequestClientsClientSummary();

    request.parameters = ['clientShortName', 'name'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.clientPageSize;

    if (this.searchClientKeyword) {
      request.filter = { clientShortName: this.searchClientKeyword };
    }

    this.isLoadingClient = true;
    const response: JM.JMResponseClientsClientSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingClient = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.clientTotalPage = Math.ceil(totalCount / request.pageSize);
    }

    return response.payload.records.map((client) => {
      const lang = Session.selectedLanguage;
      const description = client.name[lang] ? client.name[lang] : client.name.en;
      return {
        value: client.clientShortName,
        label: `${client.clientShortName} - ${description}`
      };
    });
  }

  // contract
  onChangeContractFilter(event) {
    this.searchContractKeyword = event.term;
    this.searchContractObserver.next();
  }

  onClearContractFilter() {
    this.searchContractKeyword = null;
    this.searchContracts();
  }

  async onScrollToEndContract() {
    if (this.contractPageNumber < this.contractTotalPage) {
      this.contractPageNumber++;

      let contracts = await this.requestContractList(this.contractPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.contractOptions = [...this.contractOptions, ...contracts];
    }
  }

  async searchContracts() {
    this.contractOptions = [];
    this.contractPageNumber = 1;
    this.contractTotalPage = null;
    this.contractOptions = await this.requestContractList(this.contractPageNumber);
  }

  private async requestContractList(pageNumber?: number) {
    const request = new JM.JMRequestContractsGetContractList();

    request.parameters = ['contractNumber'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.contractPageSize;

    if (this.searchContractKeyword) {
      request.filter = { contractNumber: this.searchContractKeyword };
    }

    this.isLoadingContract = true;
    const response: JM.JMResponseContractsGetContractList = await AppDelegate.sendJMRequest(request);
    this.isLoadingContract = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.contractTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    return response.payload.records.map((contract) => {
      return contract.contractNumber;
    });
  }

  // Team
  onChangeTeamFilter(event) {
    this.searchTeamKeyword = event.term;
    this.searchTeamObserver.next();
  }

  onClearTeamFilter() {
    this.searchTeamKeyword = null;
    this.searchTeams();
  }

  async onScrollToEndTeam() {
    if (this.teamPageNumber < this.teamTotalPage) {
      this.teamPageNumber++;

      let teams = await this.requestTeamList(this.teamPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.teamOptions = [...this.teamOptions, ...teams];
    }
  }

  async searchTeams() {
    this.teamOptions = [];
    this.teamPageNumber = 1;
    this.teamTotalPage = null;
    this.teamOptions = await this.requestTeamList(this.teamPageNumber);
  }

  private async requestTeamList(pageNumber?: number) {
    const request = new JM.JMRequestContractsGetVendorTeamList();

    request.parameters = ['_id', 'name'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.teamPageSize;

    if (this.searchTeamKeyword) {
      request.filter = { name: this.searchTeamKeyword };
    }

    this.isLoadingTeam = true;
    const response: JM.JMResponseContractsGetVendorTeamList = await AppDelegate.sendJMRequest(request);
    this.isLoadingTeam = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.teamTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    return response.payload.records.map((team) => {
      return { value: team._id, label: team.name };
    });
  }

  // Equipment Category
  onChangeEquipmentCategory(event) {
    const typeArray = Object.values(this.equipmentCategoryOptions.find(category => category.value == event.value).types);
    this.equipmentTypeOptions = this.equipmentTypeOptionsFull.filter(type => {
      return typeArray.includes(type.value);
    });

    this.clearEquipmentTypeInput();
  }

  onClearEquipmentCategory() {
    this.equipmentTypeOptions = this.equipmentTypeOptionsFull;
  }

  // Equipment Type
  clearEquipmentTypeInput() {
    this.searchSelections.equipmentType = null;
  }

  // Location
  onChangeLocationFilter(event) {
    this.searchLocationKeyword = event.term;
    this.searchLocationObserver.next();
  }
  onClearLocationFilter() {
    this.searchLocationKeyword = null;
    this.searchLocations();
  }

  async onScrollToEndLocation() {
    if (this.locationPageNumber < this.locationTotalPage) {
      this.locationPageNumber++;

      let locations = await this.requestLocationList(this.locationPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.locationOptions = [...this.locationOptions, ...locations];
    }
  }

  async searchLocations() {
    this.locationOptions = [];
    this.locationPageNumber = 1;
    this.locationTotalPage = null;
    this.locationOptions = await this.requestLocationList(this.locationPageNumber);
  }

  private async requestLocationList(pageNumber?: number) {
    const request = new JM.JMRequestLocationsLocationSummary();

    request.locationOnly = true;
    request.parameters = ['code', 'description'];
    request.pageNumber = pageNumber || 1;
    request.pageSize = this.locationPageSize;

    if (this.searchLocationKeyword) {
      request.locationDescription = this.searchLocationKeyword;
    }

    this.isLoadingLocation = true;
    const response: JM.JMResponseLocationsLocationSummary = await AppDelegate.sendJMRequest(request);
    this.isLoadingLocation = false;

    if (!response || !response.code || response.code != 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (pageNumber == 1) {
      let totalCount = response.payload.totalCount;
      this.locationTotalPage = Math.ceil(totalCount / request.pageSize);
    }
    return response.payload.records.map((location) => {
      const lang = Session.selectedLanguage;
      const description = location.description[lang] ? location.description[lang] : location.description.en;
      return {
        value: location.code,
        label: `${description} (${location.code})`,
      };
    });
  }

  // UI buttons
  onClickDownload() {
    const searchCriteria = this.getSearchCriteria(this.searchSelections);
    this.isCreationPeriodError = false;

    if (!searchCriteria || !searchCriteria.createTimeTo || !searchCriteria.createTimeFrom) {
      this.popUpDonwloadErrorDialog('component.sn-search-criteria.error-msg.fill-creation-time');
      this.isCreationPeriodError = true;
      return;
    }

    const fromDate = searchCriteria.createTimeFrom;
    const toDate = searchCriteria.createTimeTo;

    if (toDate.getTime() < fromDate.getTime()) {
      this.popUpDonwloadErrorDialog('component.sn-search-criteria.error-msg.wrong-creation-time');
      this.isCreationPeriodError = true;
      return;
    }

    const diffMilliSeconds = Math.round(toDate.getTime() - fromDate.getTime());
    const diffDays = diffMilliSeconds / (1000 * 60 * 60 * 24);

    if (diffDays > 60) {
      this.popUpDonwloadErrorDialog('component.sn-search-criteria.error-msg.creation-time-over-period');
      this.isCreationPeriodError = true;
      return;
    }

    this.requestCMTaskListExcel(searchCriteria);
  }

  private popUpDonwloadErrorDialog(errorMsgKey: string) {
    const data = {
      msg: {
        content: errorMsgKey,
      },
      buttons: [
        {
          name: 'global.ok',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(data);
  }

  private async requestCMTaskListExcel(searchCriteria) {
    const request = new JM.JMRequestSnExportVpSnSummary();

    if (searchCriteria) {
      if (searchCriteria.filter) {
        request.filter = searchCriteria.filter;
      }
      request.clientList = searchCriteria.clientList;
      request.contractNumberList = searchCriteria.contractNumberList;
      request.vendorTeamIdList = searchCriteria.vendorTeamIdList;
      request.equipmentCategoryList = searchCriteria.equipmentCategoryList;
      request.equipmentTypeList = searchCriteria.equipmentTypeList;
      request.locationCodeList = searchCriteria.locationCodeList;
      request.faultDetail = searchCriteria.faultDetail;
      request.priorityList = searchCriteria.priorityList;
      request.statusList = searchCriteria.statusList;
      request.createTimeFrom = searchCriteria.createTimeFrom;
      request.createTimeTo = searchCriteria.createTimeTo;
    }

    this.isDownloading = true;
    const response: JM.JMResponseSnExportVpSnSummary = await AppDelegate.sendJMRequest(request);
    this.isDownloading = false;

    if (!response) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    const dateTime = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    const fileName = `SN ${dateTime}.xlsx`;
    saveAs(response, fileName);
  }

  onClickSearch() {
    this.emitSearchEvent();
    // this.saveFilterOptionsToLocal();
  }

  onClickReset() {
    this.resetFilters();
    this.emitSearchEvent();
    // this.saveFilterOptionsToLocal();
  }

  // date picker handler
  onBlurDateInput(event) {
    this.isCreationPeriodError = false;

    if (event.fieldName == 'startDate') {
      this.startDateNgb = event.date;
      this.searchSelections.startDate = ngbDateToString(event.date);
    } else if (event.fieldName == 'endDate') {
      this.endDateNgb = event.date;
      this.searchSelections.endDate = ngbDateToString(event.date);
    }

    const valid = this.validateTimePeriod(this.searchSelections.startDate, this.searchSelections.endDate);
    this.showCreationTimeErrorMsg = !valid;
  }

  private validateTimePeriod(fromNgbDate?: String, toNgbDateDate?: String) {
    if (fromNgbDate && toNgbDateDate) {
      return toNgbDateDate >= fromNgbDate ? true : false;
    }

    return true;
  }

  //------------------------
  // cache function
  private saveFilterOptionsToLocal = (): void => {
    try {
      let cache = JSON.parse(JSON.stringify(this.searchSelections));
      Session.setSnSettingsSearchFilters(cache);
    } catch (e) {
      console.warn(e);
    }
  };

  private loadFilterOptionsFromLocal = (): void => {
    try {
      let cache = Session.getSnSettingsSearchFilters();
      if (cache) {
        let cacheParsed = JSON.parse(JSON.stringify(cache));
        this.searchSelections = {
          ...this.searchSelections,
          ...cacheParsed,
        };
        Session.setSnSettingsSearchFilters(null);
      }
    } catch (e) {
      console.warn(e);
    }
  };

}

interface SearchSelectionI {
  snNumber: string;

  client: string; // clientShortName
  contracts: string[]; // contracts
  handlingTeam: string; // vendorTeam
  equipmentCategory: string; // equipmentCategory
  equipmentType: string; // equipmentType
  location: string; // locationCode
  faultDetail: string;

  priority: { [key: number]: boolean };
  status: { [key: number]: boolean };
  startDate: string; // job creation time from
  endDate: string; // job creation time to
}

export interface SnSearchCriteriaI {
  filter?: {
    snNumber?: string,
  },
  clientList?: string[],
  contractNumberList?: string[],
  vendorTeamIdList?: string[],
  equipmentCategoryList?: string[],
  equipmentTypeList?: string[],
  locationCodeList?: string[],
  faultDetail: string;
  statusList?: number[],
  priorityList?: number[],
  createTimeFrom?: Date,
  createTimeTo?: Date,
}
