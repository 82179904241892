import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionButtonI } from './action-sidebar-v2.model';

/**
 ******************************************************************
 !!!!!!!!!!!!!!!! Replace action-sidebar.component !!!!!!!!!!!!!!!!
 ********* Display a set action button on right hand side *********
 ******************************************************************
 */
@Component({
  selector: 'app-action-sidebar-v2',
  templateUrl: './action-sidebar-v2.component.html',
  styleUrls: ['./action-sidebar-v2.component.scss'],
})
export class ActionSidebarV2Component implements OnInit {
  @Input() actionButtonList: ActionButtonI[] = [];
  @Input() isDisabled: boolean = false;
  @Input() isLoading: boolean = false;

  @Output() clickedButton = new EventEmitter<ActionButtonI>();

  constructor() {}

  ngOnInit() {}

  onClickedButton(button: ActionButtonI) {
    this.clickedButton.emit(button);
  }
}
