import { Component, Injectable, TemplateRef } from '@angular/core';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { ToastService } from './toast.service';

@Component({
  selector: 'app-toast-msg',
  templateUrl: './toast-msg.component.html',
  styleUrls: ['./toast-msg.component.scss'],
})
@Injectable({ providedIn: 'root' })
export class ToastMsgComponent {
  constructor(public toastService: ToastService) {}

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  showMsg(msg?) {
    this.toastService.show(msg);
  }

  showResponseMsg(error?: Object) {
    let code = error && error['code'] ? error['code'] : 99999;
    let message = JMLanguage.translate('api.error.' + code);
    if (error && message.includes('api.error.')) {
      message = error['error'];
    }
    this.toastService.show(message);
  }
}
