import { Component, OnInit, ViewChild } from '@angular/core';
import { UserHelper } from 'src/app/core/user-helper';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMOBJ, JMENUM } from '@ccep/CCEPConnector-ts';
import { VPUserSettings } from '@enum/vp-user-settings.enum';
import { Session } from 'src/app/core/session';

@Component({
  selector: 'app-settings-contractor',
  templateUrl: './settings-contractor.component.html',
  styleUrls: ['./settings-contractor.component.scss'],
})
export class SettingsContractorComponent implements OnInit {
  @ViewChild('contractor_list', { static: false }) contractorListElem;
  @ViewChild('contractor_general', { static: false }) contractorGeneralElem;
  @ViewChild('contract_list', { static: false }) contractListElem;

  hasUpdatePermission: boolean = false;
  contractListParam = {};
  selectedContractor: any;

  constructor() {}

  ngOnInit(): void {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }
    this.hasUpdatePermission = UserHelper.hasPermission(JMENUM.VpPermission.CONTRACTOR_UPDATE);
    this.initTable();
  }

  // ----------- UI function ----------- //
  initTable() {
    this.contractListParam['userSettingListName'] = VPUserSettings.VP_CONTRACTOR_SETTING_CONTRACT_LIST_COLUMNS;
  }

  public assignContractor = (contractor: JMOBJ.Vendor) => {
    this.selectedContractor = contractor;
    if (this.selectedContractor && this.contractListElem) {
      this.contractListElem.requestContractListByContractor(contractor.vendorNumber);
    }
  };

  public onFinishUpdateInfo() {
    this.contractorListElem.requestContractorSummary();
  }
}
