<div class="sidebar" [class.show]="showSidebar">
  <button type="button" id="sidebar-toggler" class="sidebar-toggler ml-2 px-0 float-right" [class.show]="showSidebar" (click)="showSidebar = showSidebar? false : true">
    <i class="fas fa-bars" [class.d-none]="showSidebar"></i>
  </button>

  <div class="sidebar-content pt-3">
    <div class="sidebar-header text-center pb-2">
      <span id="sys-name" class="bold font-size-xl">{{ "system.short-title" | translate }}</span>
      <span id="sys-version" class="sys-version d-block font-size-m">{{ systemVersion }}</span>
    </div> 

    <div class="sidebar-container d-flex flex-column py-4">
      <div *ngFor="let item of items[contentType]" class="nav-item mb-3">
        <ng-container *ngIf="item.type == 'normal'">
          <a *ngIf="item.route" class="nav-link href-link font-size-l"
            [id]="item.id"
            [ngClass]="{'selected-item': routeUrl === item.route}"
            routerLink="{{item.route}}">
            {{ item.title | translate }}
          </a>
          <div *ngIf="!item.route" class="nav-link font-size-l"
            [id]="item.id">
            {{ item.title | translate }}
          </div>
        </ng-container>
        
        <ng-container *ngIf="item.subItems">
          <div class="submenu">
            <ng-container *ngIf="item.type == 'normal'">
              <ng-container *ngFor="let subItem of item.subItems">
                <a *ngIf="isEnabled(subItem)" class="nav-link href-link py-1"
                  [id]="subItem.id"
                  [ngClass]="{'selected-item': routeUrl === (subItem.route + '/') || routeUrl === subItem.route}" 
                  routerLink="{{subItem.route}}">
                  {{ subItem.title | translate }}
                </a>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="item.type == 'collapse'">
              <a class="nav-link py-1 font-size-l dropdown-toggle" (click)="clickedMenu = (clickedMenu == item.key)? '': item.key ">{{ item.title | translate }}</a>
              <div class="submenu-item-wrap" [class.show]="clickedMenu == item.key">
                <ng-container *ngFor="let subItem of item.subItems">
                  <a *ngIf="isEnabled(subItem)" class="submenu-item nav-link href-link"
                    [id]="subItem.id"
                    [ngClass]="{'selected-item': routeUrl === (subItem.route + '/') || routeUrl === subItem.route}" 
                    routerLink="{{subItem.route}}">
                    {{ subItem.title | translate }}
                  </a>
                </ng-container>
                
              </div>
            </ng-container>
          </div>
        </ng-container>

      </div>
    </div>
    
    <div class="footer">
      <p (click)="onClickedRealseNoteLink()">
        <i class="fas fa-headphones pr-2"></i>{{ 'pages.login.system-support' | translate }}
      </p>
      <p><i class="fas fa-envelope pr-2"></i>{{ supportInfo.ldapId }}</p>
      <p><i class="fas fa-envelope pr-2"></i>{{ supportInfo.email }}</p>
      <p><i class="fas fa-phone pr-2"></i>{{ supportInfo.tel }}</p>
    </div>
  </div>

</div>
<div class="sidebar-overlay" [class.d-none]="!showSidebar" (click)="showSidebar = showSidebar? false : true"></div>
