<div class="wrapper">
  <app-nav></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="content cm-task-list-content">
    <div class="row mx-0 flex-xl-row">
      <div class="col-12 col-xl-9">
        <div class="d-flex justify-content-between mb-2 align-items-center">
          <div class="font-size-xl align-self-end">
            {{ "pages.notification-list.title" | translate}}
          </div>
        
          <div class="d-flex px-0 col-6 justify-content-end">
            <!-- Not Ready For Download -->
            <button *ngIf="false" class="btn icon-btn mr-2 font-size-xl" (click)="onClickDownload()" [disabled]="isDownloading || isDisabled" >
              <div *ngIf="isDownloading" class="d-flex align-items-center loading-spinner spinner-small blue"></div>
              <i *ngIf="!isDownloading" class="fa fa-download blue"></i>
            </button>
            <div class="search-box col-11 px-0">
              <input type="search" class="form-control search-input"
                placeholder="{{'pages.notification-list.search.placeHolder' | translate}}"
                [(ngModel)]="generalSearchWords"
                (keyup)="onKeyup($event, 'generalSearch')"
                (ngModelChange)="checkSearchEmpty()"
              />
              <i class="fas fa-search blue" (click)="onClickSearch()"></i>
            </div>
          </div>
        </div>
        <hr class="underline" />
        <app-tablex id="cm-task-list-table" class="" [tablexParam]='tablexParam'></app-tablex>
      </div>

      <div class="col-12 col-xl-3">
        <app-advanced-search
          (onSearch)="onAdvancedSearch($event)"
        ></app-advanced-search>
      </div>
    </div>
  </div>

</div>
