<div class="d-flex justify-content-between" >
  <div class="font-size-xl">
    {{ "component.settings-team.team-list" | translate }}
  </div>

  <div class="d-flex align-items-center">
    <!-- TBC: Not sure routeTeamId using -->
    <!-- <div class="team-name-tag px-2 mr-2" *ngIf="routeTeamId">
      <span class="pr-1 gray-40" id="route-team-name">{{ routeTeamName }}</span>
      <button class="btn font-size-m badge red px-0" (click)="onClearTeamName()">
        <i class="fa fa-times"></i>
      </button>
    </div> -->
    <button id="add-team-button" class="mr-1 btn icon-btn" (click)="onClickAddTeamButton()" [disabled]="!vendorTeamList || !hasUpdatePermission">
      <i class="fa fa-plus blue"></i>
    </button>
  </div>
</div>
<hr class="underline" />

<app-tablex class="" [tablexParam]="tablexParam"></app-tablex>

<slider-panel #add_team_panel >
  <app-form-add-team #add_team_form [isEditMode]="false" [vendorNumber]="vendorNumber" (updatedVendorTeam)="onAddedVendorTeam($event)"></app-form-add-team>
</slider-panel>

<slider-panel #edit_team_panel >
  <app-form-add-team #edit_team_form [isEditMode]="true" [vendorNumber]="vendorNumber" (updatedVendorTeam)="onUpdatedVendorTeam($event)"></app-form-add-team>
</slider-panel>