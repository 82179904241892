import { Component, OnInit, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';

import { Constants } from 'src/constants';
import { inputNumber } from 'src/app/core/Formatter';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';
import { UserHelper } from 'src/app/core/user-helper';
import { Util } from 'src/app/core/util';

@Component({
  selector: 'app-pm-plan-reminders',
  templateUrl: './pm-plan-reminders.component.html',
  styleUrls: ['./pm-plan-reminders.component.scss'],
})
export class PmPlanRemindersComponent implements OnInit {
  JMPageMode = JMENUM.JMPageMode;

  @Input() plan: JMOBJ.PmPlan;
  @Input() pageMode: JMENUM.JMPageMode;

  // Validation
  inputNumber = inputNumber;
  valid: boolean;
  errorFields: any = {};
  editable: any = {};
  mandatory: any = {};

  // Input Fields
  toBeOverhaulActiveStatus: boolean;
  toBeOverhaulDays: number;
  toBeOverhaulInterval: number;
  overdueActiveStatus: boolean;
  overdueInterval: number;
  reminderRecipients: any;

  // recipients Dropdown
  recipientsOptions: any = [];
  private searchPostObserver = new Subject<any[]>();
  searchPostKeywords: string = null;
  postPageSize: number = 50;
  postPageNumber: number = 1;
  postTotalPageNumber: number;
  isLoadingPost: boolean = false;
  isDisabledRecipients: boolean = true;

  componentPlanRemindersIn: string = Session.selectedLanguage === JMENUM.Language.ZH ? JMLanguage.translate('component.pm-plan-reminders.days-to-overdue-prefix') : '';
  enableEditReminderSession: boolean = false;
  constructor() { }

  ngOnInit() {
    this.enableEditReminderSession = UserHelper.hasPermission(JMENUM.VpPermission.PMREMINDER_UPDATE) && this.pageMode !== JMENUM.JMPageMode.VIEW;
    if (this.pageMode !== JMENUM.JMPageMode.VIEW) {
      this.fieldsControl();
      if (this.pageMode === JMENUM.JMPageMode.CREATE) {
        this.prefillReminders();
      }
      this.searchPostObserver.pipe(debounceTime(Constants.DEBOUNCE_TIME)).subscribe(() => {
        this.searchPost();
      });
    }
    this.initPlan();
  }

  ngOnChanges() {
    this.initPlan();
  }


  prefillReminders() {
    this.toBeOverhaulActiveStatus = true;
    this.overdueActiveStatus = true;
    this.updatePlanReminders();
  }

  initPlan() {
    if (!this.plan) return;
    this.toBeOverhaulActiveStatus = this.plan.vpToBeOverdueReminder ? this.plan.vpToBeOverdueReminder.activeStatus === JMENUM.ActiveStatus.ACTIVE : false;
    this.toBeOverhaulDays = this.plan.vpToBeOverdueReminder ? this.plan.vpToBeOverdueReminder.toBeDueDay : undefined;
    this.toBeOverhaulInterval = this.plan.vpToBeOverdueReminder ? this.plan.vpToBeOverdueReminder.interval : undefined;
    this.overdueActiveStatus = this.plan.vpOverduePeriodReminder ? this.plan.vpOverduePeriodReminder.activeStatus === JMENUM.ActiveStatus.ACTIVE : false;
    this.overdueInterval = this.plan.vpOverduePeriodReminder ? this.plan.vpOverduePeriodReminder.interval : undefined;
    this.reminderRecipients = this.plan.vpReminderRecipients ? this.plan.vpReminderRecipients : undefined;
  }

  updateReminderDefaultValues() {
    if (!this.plan) return;
    this.toBeOverhaulActiveStatus = this.plan.vpToBeOverdueReminder ? this.plan.vpToBeOverdueReminder.activeStatus === JMENUM.ActiveStatus.ACTIVE : false;
    this.toBeOverhaulDays = this.plan.vpToBeOverdueReminder ? this.plan.vpToBeOverdueReminder.toBeDueDay : undefined;
    this.toBeOverhaulInterval = this.plan.vpToBeOverdueReminder ? this.plan.vpToBeOverdueReminder.interval : undefined;
    this.overdueActiveStatus = this.plan.vpOverduePeriodReminder ? this.plan.vpOverduePeriodReminder.activeStatus === JMENUM.ActiveStatus.ACTIVE : false;
    this.overdueInterval = this.plan.vpOverduePeriodReminder ? this.plan.vpOverduePeriodReminder.interval : undefined;
  }

  async fetchNewPostList() {
    // TODO: handle edit mode
    if (this.plan.vendor) {
      this.postPageNumber = 1;
      this.recipientsOptions = await this.requestPostSummary(this.postPageNumber);
    }
  }

  recipientAddFunction = (term: string) => {
    if (Util.isValidEmail(term)) {
      return term;
    }
    AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.msg.incorrect-email-format'));
    return null;
  }

  // ----------- API ----------- //
  private async requestPostSummary(postPageNumber?: number) {
    let request: JM.JMRequestPostsGetVendorPostList = new JM.JMRequestPostsGetVendorPostList();

    request.pageNumber = this.postPageNumber || 1;
    request.pageSize = this.postPageSize;
    request.vendorNumber = this.plan.vendor.vendorNumber;
    if (this.searchPostKeywords) {
      request.filter = { name: this.searchPostKeywords };
    }

    this.isLoadingPost = true;
    let response: JM.JMResponsePostsGetVendorPostList = await AppDelegate.sendJMRequest(request);
    this.isLoadingPost = false;

    if (response == null || response.code != 200 || response.payload == null) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    if (postPageNumber === 1) {
      let totalPostCount = response.payload.totalCount;
      this.postTotalPageNumber = Math.ceil(totalPostCount / request.pageSize);
    }

    return response.payload.records.map((record) => {
      return { label: record.name, value: record.name };
    });
  }

  // ----------- UI function ----------- //
  private fieldsControl() {
    this.editable = {
      toBeOverhaulDays: true,
      toBeOverhaulInterval: true,
      overdueInterval: true,
      reminderRecipients: true,
    };
  }

  public clearAll() {
    // for (let field in this.editable) {
    //   this.plan[field] = undefined;
    // }
    this.clearRecipients();
  }

  public clearRecipients() {
    this.recipientsOptions = [];
    this.postPageNumber = 1;
    this.postTotalPageNumber = null;
    this.plan.vpReminderRecipients = undefined;
    this.searchPostKeywords = undefined;
  }

  public toggleRecipientsInput() {
    if (this.plan.vendor) {
      this.isDisabledRecipients = false;
    } else {
      this.isDisabledRecipients = true;
    }
  }

  public onChangeInterval() {
    this.updatePlanReminders();
  }

  public onChangeIntervalAndCheckMin(keyName: string, ele: any, min: number) {
    let str = ele.value;
    let newValue = str.replace(/[^\d]/g, '');
    if (+newValue < min) {
      newValue = null;
      this[keyName] = null;
    }
    if (ele) {
      ele.value = newValue;
    }
    this.updatePlanReminders();
  }

  public toggleActiveStatus() {
    this.updatePlanReminders();
    this.removeErrorFieldIfDisabled();
  }

  public removeErrorFieldIfDisabled() {
    if (!this.toBeOverhaulActiveStatus) {
      this.errorFields['toBeOverhaulDays'] = false;
      this.errorFields['toBeOverhaulInterval'] = false;
    }
    if (!this.overdueActiveStatus) {
      this.errorFields['overdueInterval'] = false;
    }
  }

  updatePlanReminders() {
    this.plan.vpToBeOverdueReminder = { toBeDueDay: this.toBeOverhaulDays, interval: this.toBeOverhaulInterval, activeStatus: this.toBeOverhaulActiveStatus ? JMENUM.ActiveStatus.ACTIVE : JMENUM.ActiveStatus.INACTIVE };
    this.plan.vpOverduePeriodReminder = { interval: this.overdueInterval, activeStatus: this.overdueActiveStatus ? JMENUM.ActiveStatus.ACTIVE : JMENUM.ActiveStatus.INACTIVE };
  }

  public async onPostScrollToEnd() {
    if (this.postPageNumber < this.postTotalPageNumber) {
      this.postPageNumber += 1;
      let posts = await this.requestPostSummary(this.postPageNumber);

      // just append will not trigger ng-select change detection, need to update array reference
      this.recipientsOptions = [...this.recipientsOptions, ...posts];
    }
  }

  public onSearchPost(event) {
    this.searchPostKeywords = event.term;
    this.searchPostObserver.next();
  }


  clearRecipientFilter() {
    this.searchPostKeywords = null;
    this.searchPost();
  }

  public async searchPost() {
    this.recipientsOptions = [];
    this.postPageNumber = 1;
    this.postTotalPageNumber = null; 
    this.recipientsOptions = await this.requestPostSummary(this.postPageNumber);
  }

  public validate() {
    this.errorFields = {};
    if (this.toBeOverhaulActiveStatus) {
      if (!this.plan.vpToBeOverdueReminder || (!this.plan.vpToBeOverdueReminder.toBeDueDay && this.plan.vpToBeOverdueReminder.toBeDueDay !== 0)) {
        this.errorFields['toBeOverhaulDays'] = true;
      }
      if (!this.plan.vpToBeOverdueReminder || (!this.plan.vpToBeOverdueReminder.interval && this.plan.vpToBeOverdueReminder.interval !== 0)) {
        this.errorFields['toBeOverhaulInterval'] = true;
      }
    }
    if (this.overdueActiveStatus) {
      if (!this.plan.vpOverduePeriodReminder || (!this.plan.vpOverduePeriodReminder.interval && this.plan.vpOverduePeriodReminder.interval !== 0)) {
        this.errorFields['overdueInterval'] = true;
      }
    }
    if (!this.isDisabledRecipients && (this.overdueActiveStatus || this.toBeOverhaulActiveStatus)) {
      if (!this.plan.vpReminderRecipients || this.plan.vpReminderRecipients.length === 0) {
        this.errorFields['reminderRecipients'] = true;
      }
    }
    const isValid = Object.keys(this.errorFields).length === 0;
    if (!isValid) {
      AppDelegate.toastMsg().showMsg(JMLanguage.translate("pages.pm-plan.toast.please-fill-in-mandatory-fields"));
    }
    return isValid;
  }
}
