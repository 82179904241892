<div class="wrapper">
  <app-nav [breadcrumbs]="breadcrumbs"></app-nav>
  <app-sidebar></app-sidebar>

  <div id="content" class="row mx-0">
    <div class="col-12 col-lg-6">
      <div class="mb-2">
        <span class="title">{{ "component.attachment.attachment-title" | translate }}</span>
        <app-upload-attachment-tooltip #upload_previewer
          id="jobcard-upload-preview"
          [parameters]="uploadPreviewParam"
          (onSubmitAttachment)="onClickSubmitAttachment($event)"
        ></app-upload-attachment-tooltip>
      </div>
      
      <hr class="underline" />
      <app-tablex id="jobcard-attachment-table" [tablexParam]='tablexParam' class='mt-3'></app-tablex>
    </div>
    <div class="col-12 col-lg-6">
      <div class="title mb-2">{{ "component.attachment.preview-title" | translate }}</div>
      <hr class="underline" />
      <attachment-previewer #attachment_preview id="attachment-preview" class="d-block mt-3" [file]="file" [fileDetails]="fileDetails" [isLoading]="isPreviewerLoading"></attachment-previewer>
    </div>

  </div>
</div>
