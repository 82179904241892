import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage, Language } from 'src/app/core/JMLanguage/JMLanguage';
import { UserHelper } from 'src/app/core/user-helper';
import { CustomSliderPanelComponent } from 'src/app/shared/custom-slider-panel/custom-slider-panel.component';
import {
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';
import { Constants } from 'src/constants';
import { PopUpDialogI } from '../../../shared/pop-up-dialog/pop-up-dialog.model';
import { SeverityCriteriaEditorComponent } from '../severity-criteria-editor/severity-criteria-editor.component';

@Component({
  selector: 'app-severity-criteria-list-table',
  templateUrl: './severity-criteria-list-table.component.html',
  styleUrls: ['./severity-criteria-list-table.component.scss'],
})
export class SeverityCriteriaListTableComponent implements OnInit {
  @ViewChild('add_criteria_panel', { static: false }) addCriteriaPanel: CustomSliderPanelComponent;
  @ViewChild('add_criteria_form', { static: false }) addCriteriaEditor: SeverityCriteriaEditorComponent;

  @Input() hasUpdatePermission: boolean = false;
  @Input() contractSeverity?: JMOBJ.ContractSeverity;

  contractSeverityCriteriaList: JMOBJ.ContractSeverityCriteria[] = [];
  selectedContractSeverityCriteria?: JMOBJ.ContractSeverityCriteria;

  // Table
  tablexParam: {} = {};
  tablexFilter: {} = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize: number = 10;
  currentPage: number = 1;
  pageCount: number = 1;
  allColHeaders: any = [];
  selectedRows: any = [];
  selectedTableRows: any = [];

  constructor() {}

  ngOnInit(): void {
    this.initTableHeader();
    this.initTablex();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.reloadContractSeverityListTable();
  }

  // ----------- relaod ----------- //
  private reloadContractSeverityListTable() {
    if (this.contractSeverity) {
      this.requestContractSeverityCriteriaList();
    } else {
      this.contractSeverityCriteriaList = [];
      this.resetTable();
      this.renderTable();
    }
  }

  // ----------- API ----------- //
  private async requestContractSeverityCriteriaList() {
    if (!UserHelper.hasPermission(JMENUM.VpPermission.SEVERITY_VIEW)) {
      return;
    }

    let request = new JM.JMRequestContractsGetContractSeverityCriteriaList();
    request.contractSeverityId = this.contractSeverity._id;
    request.pageSize = this.currentPageSize;
    request.pageNumber = this.currentPage;
    request.filter = this.tablexFilter;

    this.tablexParam['isLoadingTable'] = true;
    const response: JM.JMResponseContractsGetContractSeverityCriteriaList = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.records) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.contractSeverityCriteriaList = response.payload.records;
    this.tablexParam['totalRowCount'] = response.payload.totalCount;
    this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
    this.renderTable();
  }

  private async requestRemoveContractSeverityCriteria(criteriaId: string[]) {
    let request = new JM.JMRequestContractsRemoveContractSeverityCriteria();
    request.contractSeverityId = this.contractSeverity._id;
    request.criteriaIdList = criteriaId;

    const response: JM.JMResponseContractsRemoveContractSeverityCriteria = await AppDelegate.sendJMRequest(request);

    if (!response || !response.code || response.code !== 200 || !response.payload) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.deleted'));
    this.resetTable();
    this.requestContractSeverityCriteriaList();
  }

  // ----------- UI function ----------- //
  onClickedDeleteButton() {
    const button: PopUpDialogI = {
      msg: {
        content: 'popup.confirm-to-delete',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            const contractSeverityId = this.selectedContractSeverityCriteria._id;
            this.requestRemoveContractSeverityCriteria([contractSeverityId]);
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(button);
  }

  onClickedAddButton() {
    this.addCriteriaEditor.refreshData();
    this.addCriteriaPanel.toggle();
  }

  onAddedCriteria(contractSeverityCriteria: JMOBJ.ContractSeverityCriteria) {
    this.requestContractSeverityCriteriaList();
    this.addCriteriaPanel.close();
  }

  // ----------- Tablex UI function ----------- //
  onRowSelected = (index, row) => {
    if (!this.contractSeverityCriteriaList) {
      return;
    }
    this.selectedContractSeverityCriteria = this.contractSeverityCriteriaList.find((team) => team._id == row[0]);
  };

  onRowUnselected = (index, row) => {
    this.selectedContractSeverityCriteria = undefined;
  };

  onFilterChanged = (event, index, header, filter) => {
    for (let key in filter) {
      if (!filter[key]) {
        delete this.tablexFilter[key];
      } else {
        this.tablexFilter[key] = filter[key];
      }
    }
    this.currentPage = 1;
    this.resetTable();
    this.requestContractSeverityCriteriaList();
  };

  onFilterClear = () => {
    this.tablexFilter = {};
    this.tablexParam['filter'] = {};
    this.resetTable();
    this.requestContractSeverityCriteriaList();
  };

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.resetTable();
    this.requestContractSeverityCriteriaList();
  };

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.resetTable();
    this.requestContractSeverityCriteriaList();
  };

  private resetTable() {
    this.selectedTableRows = [];
    this.tablexParam['selectedRowIndex'] = undefined;
    this.tablexParam['highlightedRows'] = [];
    this.selectedContractSeverityCriteria = undefined;
  }

  private renderTable() {
    this.tablexParam['content'] = this.contractSeverityCriteriaList.map((criterial, index) => {
      const star = '*';
      let client = star;
      let district = star;
      let functionLocationCode = star;
      let functionLocationDescription = star;
      let equipmentCategory = star;
      let equipmentType = star;

      if (criterial.client && criterial.client.clientShortName) {
        client = criterial.client.clientShortName;
      }

      if (!!criterial.district) {
        district = JMLanguage.translate(`enum.district.${criterial.district}`);
      }

      if (criterial.location) {
        if (criterial.location.code) {
          functionLocationCode = criterial.location.code;
        }
        if (criterial.location.description) {
          functionLocationDescription = this.isChinese
            ? criterial.location.description.zh
            : criterial.location.description.en;
        }
      }

      if (criterial.equipmentCategory) {
        equipmentCategory = criterial.equipmentCategory;
      }

      if (criterial.equipmentType) {
        equipmentType = criterial.equipmentType;
      }

      const row = [
        criterial._id,
        client,
        district,
        functionLocationCode,
        functionLocationDescription,
        equipmentCategory,
        equipmentType,
      ];

      return row;
    });
  }

  private initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableClearFilter: false,
      enableColFilter: false,
      enableSelectedRowCount: false,
      enableSort: false,
      minifyButton: true,
      tableRow: 'row',
      tableClass: 'severity-criteria-list-table',
      tableWrapperClass: 'table-min-width',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowSelected: this.onRowSelected,
      onRowUnselected: this.onRowUnselected,
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };

    this.initTableHeader();
  }

  private initTableHeader() {
    this.allColHeaders = [
      {
        id: 'objId',
        name: '',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'd-none ',
      },
      {
        id: 'client',
        name: 'component.severity-criteria-list-table.table-column.client',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'district',
        name: 'component.severity-criteria-list-table.table-column.district',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'functional-location-code',
        name: 'component.severity-criteria-list-table.table-column.functional-location-code',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'functional-location-description',
        name: 'component.severity-criteria-list-table.table-column.functional-location-description',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'equipment-category',
        name: 'component.severity-criteria-list-table.table-column.equipment-category',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
      {
        id: 'equipment-type',
        name: 'component.severity-criteria-list-table.table-column.equipment-type',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col ',
      },
    ];
  }

  // ----------- get property function ----------- //
  get isChinese(): boolean {
    return JMLanguage.getCurrentLanguage() === Language.ZH;
  }
}
