<div class="font-size-xl m-0">
  <div class="overflow-hidden">
    <div class="d-inline-block">
      {{ "component.pm-plan-periods.title" | translate}}
    </div>
    <div class="overflow-hidden d-inline-block float-right" *ngIf="pageMode != JMPageMode.VIEW && (!plan.status || plan.status == PMStatus.DRAFT)">
      <button id="generate-period-button" class="col-auto btn standard-btn brand-blue bold" (click)="onClickRefresh()">
        {{ "component.pm-plan-periods.refresh" | translate }}
      </button>
    </div>
  </div>
</div>

<hr class="underline" class="mt-1 mb-3" />

<div class="period-table-wrap" [class.scroll-y]="plan?.periods && plan?.periods.length > 0">
  <table class="period-table w-100 text-center">
    <tr class="table-header">
      <th>{{ "component.pm-plan-periods.table.period" | translate }}</th>
      <th>{{ "component.pm-plan-periods.table.start-date" | translate }}</th>
      <th>
        {{ "component.pm-plan-periods.table.end-date" | translate }}
        <span class="red-text" *ngIf="pageMode !== JMPageMode.CREATE && (plan?.scheduleType == ScheduleType.NORMAL || plan?.scheduleType == ScheduleType.ONCE)">
          {{ "component.pm-plan-periods.table.expired" | translate }}</span>
      </th>
      <th *ngIf="plan?.scheduleType == ScheduleType.OVERHAUL">{{ "component.pm-plan-periods.table.overhaul-start-date" | translate }}</th>
      <th *ngIf="plan?.scheduleType == ScheduleType.OVERHAUL">
        {{ "component.pm-plan-periods.table.overhaul-end-date" | translate }}
        <span class="red-text" *ngIf="pageMode !== JMPageMode.CREATE">{{ "component.pm-plan-periods.table.expired" | translate }}</span>
      </th>
      <th *ngIf="plan?.periods && plan?.periods[0]?.status">
        {{ "component.pm-plan-periods.table.status" | translate }}
        <ng-select id="filter-period-status" class="form-control p-0 text-left" [(ngModel)]="selectedStatus" 
          [items]="statusOptions"  bindLabel="label" bindValue="value" >
        </ng-select>
      </th>
      <th *ngIf="plan?.planCoverage">
        <ng-container *ngIf="plan?.planCoverage == PlanCoverage.EQUIPMENT">
          {{ "component.pm-plan-periods.table.equipment" | translate }}
        </ng-container>
        <ng-container *ngIf="plan?.planCoverage == PlanCoverage.LOCATION">
          {{ "component.pm-plan-periods.table.location" | translate }}
        </ng-container>
      </th>
      <th *ngIf="plan?.periods">
        {{ "component.pm-period-list.table-column.number-of-job" | translate }}
      </th>
    </tr>
  
    <ng-container *ngIf="plan?.periods && plan?.periods.length > 0">
      <ng-container *ngFor="let period of plan.periods; let p = index;">
        <tr *ngIf="!selectedStatus || period.status == selectedStatus" >
          <td> {{ period?.index }} </td>
          <td> {{ FormatDate(period?.periodStartDate, 'YYYY/MM/DD') }} </td>
          <td> <span [ngClass]="{'red-text': isNormalOncePeroidExpired(period) }">{{ FormatDate(period?.periodEndDate, 'YYYY/MM/DD') }}</span> </td>
          <td *ngIf="plan?.scheduleType == ScheduleType.OVERHAUL">
            <ng-container *ngIf="pageMode === JMPageMode.VIEW">
              {{ FormatDate(period?.overhaulStartDate, 'YYYY/MM/DD') }} 
            </ng-container>
            <ng-container *ngIf="pageMode !== JMPageMode.VIEW && overhaulDateNgb && overhaulDateNgb.length > 0">
              <app-date-input
                #overhaulEndDateElem
                class="mt-1 d-inline-block"
                [input]="overhaulDateNgb[p]['startDate']"
                [inputId]="'overhaulStartDate'+period.index"
                [field]="period.index" 
                [isError]="overhaulDateNgb[p]['isErrorStartDate']"
                (updatedDate)="onBlurStartDateInput($event)"
              >
              </app-date-input>
            </ng-container>
          </td>
          <td *ngIf="plan?.scheduleType == ScheduleType.OVERHAUL">
            <ng-container *ngIf="pageMode === JMPageMode.VIEW">
              <span [ngClass]="{'red-text': isOverhaulPeroidExpired(period) }"> {{ FormatDate(period?.overhaulEndDate, 'YYYY/MM/DD') }} </span>
            </ng-container>
            <ng-container *ngIf="pageMode !== JMPageMode.VIEW && overhaulDateNgb && overhaulDateNgb.length > 0">
              <app-date-input
                #overhaulEndDateElem
                class="mt-1 d-inline-block" 
                [input]="overhaulDateNgb[p]['endDate']"
                [inputId]="'overhaulEndDate'+period.index"
                [field]="period.index" 
                [isError]="overhaulDateNgb[p]['isErrorEndDate']"
                (updatedDate)="onBlurEndDateInput($event)"
              >
              </app-date-input>
            </ng-container>
          </td>
  
          <td *ngIf="period?.status">
            <span [ngClass]="{'red-text': isNormalOncePeroidExpired(period) || isOverhaulPeroidExpired(period) }">{{ "pm-plan.status." + period.status | translate }}</span>
          </td>
          <td>
            <ng-container *ngIf="plan.planCoverage == PlanCoverage.EQUIPMENT">
              {{ period?.equipmentCount }}
              <!-- TODO: link for redirect to pm period equipment list  -->
              <ng-container *ngIf="plan.status == PMStatus.OUTSTANDING || plan.status == PMStatus.IN_PROGRESS || plan.status == PMStatus.COMPLETED || plan.status == PMStatus.CANCELLED">
                (<a [href]="'/pm/periods/'+plan?.pmPlanNumber+'/'+period?._id+'/equipment-list'">{{ "component.pm-plan-periods.detail" | translate }}</a>)
              </ng-container>
            </ng-container>
            <ng-container *ngIf="plan.planCoverage == PlanCoverage.LOCATION && period.functionalLocations">
              {{ period?.functionalLocations.length }} 
              <ng-container *ngIf="plan.status == PMStatus.OUTSTANDING || plan.status == PMStatus.IN_PROGRESS || plan.status == PMStatus.COMPLETED || plan.status == PMStatus.CANCELLED">
                (<a [href]="period?.coverage?.link">{{ "component.pm-plan-periods.detail" | translate }}</a>)
              </ng-container>
            </ng-container>
          </td>
          <td>
            <ng-container *ngIf="period?.pmJobList?.length > 0 ; else empty">
              <button class="custom-numberOfJobsButton" (click)="onNumberOfJobsClick(period)">{{period.pmJobList.length}}</button>
            </ng-container>
            <ng-template #empty>0</ng-template>
          </td>
        </tr>
      </ng-container>
    </ng-container>
    
  </table>
</div>
