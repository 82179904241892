import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JM, JMOBJ, JMREASON } from '@ccep/CCEPConnector-ts';
import { JMReason } from '@ccep/CCEPConnector-ts/lib/JMConnector/class/Reason';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';
import { CmTaskService } from 'src/app/shared/cm-task.service';
import { Constants } from 'src/constants';

@Component({
  selector: 'cm-task-complete-editor',
  templateUrl: './cm-task-complete-editor.component.html',
  styleUrls: ['./cm-task-complete-editor.component.scss'],
})
export class CmTaskCompleteEditorComponent implements OnInit, OnChanges {
  @Input() sn: JMOBJ.ServiceNotification;
  @Input() jobCard: JMOBJ.JobCard;

  @Output() updatedJobCard = new EventEmitter<JMOBJ.JobCard>();

  // data
  completeForm = this.fb.group({
    completeReason: [undefined, [Validators.required]],
    completeRemarks: [''],
    followUpReason: [undefined, []],
    followUpRemarks: [''],
  });

  completeReasonList: {
    code: number;
    label: string;
  }[] = [];
  followUpReasonList: {
    code: number;
    label: string;
  }[] = [];

  hasCompleteWithFollowUpReason = false;
  submitBtn = {
    isLoading: false,
  };

  constructor(
    private fb: FormBuilder,
    private cmTaskService: CmTaskService,
  ) { }

  ngOnInit(): void {
    this.resetFollowUpReasonList();
    this.resetForm();

    this.observeCompleteReasonValue();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetCompleteReasonList();
  }

  private resetForm() {
    this.completeForm.reset({
      completeReason: undefined,
      completeRemarks: '',
      followUpReason: undefined,
      followUpRemarks: '',
    });
  }

  private resetCompleteReasonList() {
    const isFirstAttendedJob = this.cmTaskService.isFirstAttendedJob(this.jobCard);
    const isSlaJob = this.cmTaskService.isSlaJob(this.jobCard);
    const hasFollowUpJob = this.cmTaskService.hasFollowUpJob(this.sn);

    const filterCriteria = [JMREASON.Attribute.VP, JMREASON.Attribute.CM];
    let filterReasonList = JMREASON.JOB_COMPLETE_REASON_LIST.filterReason(filterCriteria);

    /**
      1. first attended job && no follow up job => all reason
      2. first attended job && has follow up job => "complete with follow up" reason only
      3. follow up job => all reason exclude "compelte with follow up" reason
    */
    filterReasonList = filterReasonList.filter((reason) => {
      const isCompleteWithFollowUpReason = reason.checkAttribute([JMREASON.Attribute.CREATE_FOLLOWUP_JOB]);
      if (isCompleteWithFollowUpReason) {
        // follow up job cannot complete with follow up (3)
        if (isFirstAttendedJob == false) {
          return false;
        }

        // ad-hoc and transfer job cannot complete with follow up
        if (isSlaJob == false) {
          return false;
        }
      } else {
        // first attended job can only choose "complete with follow up" if follow up job existing (2)
        if (isFirstAttendedJob == true && hasFollowUpJob == true) {
          return false;
        }
      }

      return true;
    });

    this.completeReasonList = this.getReasonList(filterReasonList);
  }

  private resetFollowUpReasonList() {
    const filterCriteria = [JMREASON.Attribute.VP, JMREASON.Attribute.CM];
    const filterReasonList = JMREASON.JOB_FOLLOW_UP_REASON_LIST.filterReason(filterCriteria);

    this.followUpReasonList = this.getReasonList(filterReasonList);
  }

  private getReasonList(reasonList: JMReason[]) {
    return reasonList.map((reason) => {
      const description = reason.description;
      let label = '';
      if (description) {
        label = description[Session.selectedLanguage] ? description[Session.selectedLanguage] : description.en;
      }
      return {
        code: reason.code,
        label: label,
      };
    });
  }

  private observeCompleteReasonValue() {
    this.completeReason.valueChanges.subscribe(value => {
      const reason = JMREASON.JOB_COMPLETE_REASON_LIST.getReason(value);
      this.hasCompleteWithFollowUpReason = reason ? reason.checkAttribute([JMREASON.Attribute.CREATE_FOLLOWUP_JOB]) : false;

      if (this.needInputFollowUpReason) {
        this.followUpReason.setValidators(Validators.required);
      } else {
        this.followUpReason.clearValidators();
      }
      this.followUpReason.updateValueAndValidity();
    })
  }

  //------------------------
  // API
  private async requestCompleteCmTask() {
    let request = new JM.JMRequestJobCardsCompleteCmTask();
    request.jobCardNumber = this.jobCard.jobCardNumber;
    request.version = this.jobCard.version;
    request.completeCode = this.completeReason.value;
    request.completeRemarks = this.completeRemarks.value.trim() || undefined;

    if (this.needInputFollowUpReason) {
      request.followUpReasonCode = this.followUpReason.value;
      request.followUpRemarks = this.followUpRemarks.value.trim() || undefined;
    }

    this.submitBtn.isLoading = true;
    const response: JM.JMResponseJobCardsCompleteCmTask = await AppDelegate.sendJMRequest(request);
    this.submitBtn.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.jobCard) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.submitted'));
    this.updatedJobCard.emit(this.jobCard);
    this.resetForm();
  }

  //------------------------
  // button callback
  onSubmitBtnClicked() {
    const data = {
      msg: {
        content: 'component.cm-task-complete-editor.pop-up.confirm-to-complete',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestCompleteCmTask();
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(data);
  }

  //------------------------
  // get property
  get completeReason() {
    return this.completeForm.get('completeReason');
  }
  get completeRemarks() {
    return this.completeForm.get('completeRemarks');
  }
  get followUpReason() {
    return this.completeForm.get('followUpReason');
  }
  get followUpRemarks() {
    return this.completeForm.get('followUpRemarks');
  }

  get needInputFollowUpReason() {
    return this.hasCompleteWithFollowUpReason && this.cmTaskService.isFirstAttendedJob(this.jobCard) == true;
  }
  get inputBoxMaxLength() {
    return Constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }
}
