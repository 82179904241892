import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { JM, JMENUM, JMOBJ } from '@ccep/CCEPConnector-ts';
import { VPUserSettings } from '@enum/vp-user-settings.enum';
import { Session } from 'src/app/core/session';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import {
  TablexColumnFilterOption,
  TablexColumnFilterType,
  TablexColumnHorizontalAlign,
  TablexColumnType,
  TablexColumnVerticalAlign,
} from 'src/app/shared/tablex/tablexColumnType';

@Component({
  selector: 'app-contractor-list-table',
  templateUrl: './contractor-list-table.component.html',
  styleUrls: ['./contractor-list-table.component.scss'],
})
export class ContractorListTableComponent implements OnInit {
  @Output() onSelectContractor = new EventEmitter<any>();

  //Status
  isDownloading: boolean = false;

  contractorList: any = [];
  selectedContractor: JMOBJ.Vendor = new JMOBJ.Vendor();

  //Team List Table
  tablexParam: {} = {};
  tablexFilter: {} = {};
  pageSizeOptions = [10, 25, 100];
  currentPageSize: number = 10;
  currentPage: number = 1;
  pageCount: number = 1;
  sortBy = undefined;
  sortOrder = undefined;
  selectedColId: any = [];
  allColHeaders: any = [];
  selectedCol: any = [];
  selectedRows: any = [];
  selectedTableRows: any = [];

  statusListOptions: TablexColumnFilterOption = new TablexColumnFilterOption();

  constructor() {}

  ngOnInit(): void {
    this.initTableHeader();
    this.initSelectedCol();
    this.initTablex();
    this.initFilterOptions();
    this.requestContractorSummary();
  }

  initFilterOptions() {

    this.statusListOptions.items = [];
    for(const key in JMENUM.ActiveStatus) {
      this.statusListOptions.items.push({
        label: JMLanguage.translate(`global.${JMENUM.ActiveStatus[key]}`),
        value: JMENUM.ActiveStatus[key]
      })
    }
    this.statusListOptions.bindLabel = "label";
    this.statusListOptions.bindValue = "value";
    this.statusListOptions.change = this.onOptionFilterChanged;
  }

  onOptionFilterChanged = (event, i, header) => {
    if (header.filterDropdownOption.selectedValue) {
      this.tablexFilter[header.id] = header.filterDropdownOption.selectedValue;
    } else {
      delete this.tablexFilter[header.id];
    }

    this.currentPage = 1;
    this.requestContractorSummary();
  }

  // ----------- API ----------- //
  requestUpdateUserSettings() {
    let userSettings = Session.userInfo['userSettings'] ? Session.userInfo['userSettings'] : {};
    let request = new JM.JMRequestPostsUpdateUserSettings();
    request.name = Session.userInfo.name;
    request.systemName = Constants.SYSTEM_NAME;
    userSettings[VPUserSettings.VP_CONTRACTOR_SETTING_CONTRACTOR_LIST_COLUMNS] = this.selectedColId;
    request.userSettings = userSettings;

    JM.JMConnector.sendPostsUpdateUserSettings(request, (error: JM.JMNetworkError, response: Object) => {
      if (error) {
        AppDelegate.toastMsg().showResponseMsg(error);
        return;
      }
    });
  }

  private async requestContractorSummary() {
    this.tablexParam['isLoadingTable'] = true;
    let request: JM.JMRequestContractsGetVendorList = new JM.JMRequestContractsGetVendorList();
    request.pageNumber = this.currentPage;
    request.pageSize = this.currentPageSize;
    request.filter = {};
    request.sortBy = "vendorNumber";
    Object.keys(this.tablexFilter).forEach(key => {
      if(this.tablexFilter[key]) {
        if(key === 'prefix') {
          request.filter["postNamePrefix"] = this.tablexFilter['prefix'];
        } else if(key === 'status') {
          request["activeStatusList"] = [this.tablexFilter['status']];
        } else {
          request.filter[key] = this.tablexFilter[key];
        }
      }
    });
    if (!request["activeStatusList"]) {
      request["activeStatusList"] = Object.values(JMENUM.ActiveStatus);
    }

    let response: JM.JMResponseContractsVendorList = await AppDelegate.sendJMRequest(request);
    this.tablexParam['isLoadingTable'] = false;
    if (response && response.code == 200) {
      this.contractorList = response.payload.records;
      this.renderTable();
      this.tablexParam['pageCount'] = Math.ceil(response.payload.totalCount / this.currentPageSize);
    } else {
      AppDelegate.toastMsg().showResponseMsg(response);
    }
  }

  // ----------- UI function ----------- //
  initSelectedCol() {
    this.selectedColId = [
      'vendorNumber',
      'vendorName',
      'status',
      'prefix',
      'responsibleOfficer',
      'telephone'
    ];
    let cachedUserSettings = Session.userInfo.userSettings;
    if (cachedUserSettings && cachedUserSettings[VPUserSettings.VP_CONTRACTOR_SETTING_CONTRACTOR_LIST_COLUMNS]) {
      Object.assign(
        this.selectedColId,
        cachedUserSettings[VPUserSettings.VP_CONTRACTOR_SETTING_CONTRACTOR_LIST_COLUMNS]
      );
    }

    this.onColFiltered(this.selectedColId);
  }

  onClickDownload() {
    this.isDownloading = true;
  }

  onClickSearch() {}

  // ----------- Tablex UI function ----------- //
  onRowClicked = (index, row) => {
    // this.selectedRows = row;
    // let record:EAPPOBJ.Reminder = this.appraisalSummary.find(record => record._id == row[0]);
  };

  public onRowSelected = (index, row) => {
    if (!this.contractorList) {
      return;
    }
    this.selectedContractor = this.contractorList.find((record) => record._id == row[0]);
    this.onSelectContractor.emit(this.selectedContractor);
  };

  public onRowUnselected = (index, row) => {
    this.selectedContractor = null;
    this.onSelectContractor.emit(null);
  };

  onColFiltered = (selectedColId) => {
    this.selectedColId = [...selectedColId];
    this.renderTable();

    if (
      !Session.userInfo['userSettings'] ||
      (Session.userInfo['userSettings'] &&
        Session.userInfo.userSettings[VPUserSettings.VP_CONTRACTOR_SETTING_CONTRACTOR_LIST_COLUMNS] !=
          this.selectedColId)
    ) {
      this.requestUpdateUserSettings();
      let userSettings = Session.userInfo['userSettings'] ? Session.userInfo['userSettings'] : {};
      userSettings[VPUserSettings.VP_CONTRACTOR_SETTING_CONTRACTOR_LIST_COLUMNS] = this.selectedColId;
      Session.userInfo.userSettings = userSettings;
      Session.setUserInfo(Session.userInfo);
    }
  };

  onFilterChanged = (event, index, header, filter) => {
    for (let key in filter) {
      if (!filter[key]) {
        delete this.tablexFilter[key];
      } else {
        this.tablexFilter[key] = filter[key];
      }
    }
    this.currentPage = 1;
    this.requestContractorSummary();
  };

  onFilterClear = () => {
    this.tablexFilter = {};
    this.tablexParam['filter'] = {};
    this.requestContractorSummary();
  };

  onPageSizeClicked = (pageSize: number) => {
    this.currentPage = 1;
    this.currentPageSize = pageSize;
    this.resetTableAction();
    this.requestContractorSummary();
  };

  onPageNumberClicked = (pageIndex: number) => {
    this.currentPage = pageIndex;
    this.resetTableAction();
    this.requestContractorSummary();
  };

  public resetTableAction() {
    this.selectedTableRows = [];
    this.tablexParam['selectedRowIndex'] = undefined;
    this.tablexParam['highlightedRows'] = [];
  }

  renderTable() {
    this.tablexParam['headers'] = this.allColHeaders.filter((col) => {
      return this.selectedColId.includes(col.id);
    });
    this.tablexParam['content'] = this.contractorList.map((data, index) => {
      let status = data.activeStatus
        ? JMLanguage.translate('global.' + data.activeStatus.toLowerCase())
        : JMLanguage.translate('global.inactive');
      let originRow = [
        data._id,
        data.vendorNumber,
        data.vendorName,
        status,
        data.postNamePrefix,
        data.responsibleOfficer,
        data.telephone
      ];

      let row = [];
      originRow.forEach((value, i) => {
        if (this.selectedColId.includes(this.allColHeaders[i].id)) {
          row.push(originRow[i]);
        }
      });
      return row;
    });
    this.resetTableAction();
    this.tablexParam['isLoadingTable'] = false;
    this.tablexParam['totalRowCount'] = this.tablexParam['content'].length;
    if(this.contractorList.length > 0) {
      this.selectedContractor = this.contractorList[0];
      this.tablexParam['selectedRowIndex'] = 0;
    } else {
      this.selectedContractor = null;
    }
    this.onSelectContractor.emit(this.selectedContractor);
  }

  initTablex() {
    this.tablexParam = {
      isLoadingTable: false,
      enableSetPageSize: true,
      enablePagination: true,
      enableClearFilter: true,
      enableColFilter: true,
      enableSelectedRowCount: false,
      enableSort: false,
      minifyButton: true,
      tableRow: 'd-flex flex-nowrap',
      tableClass: 'contractor-list-table',
      tableWrapperClass: 'overflow-auto',
      pageSizeOptions: this.pageSizeOptions,
      currentPageSize: this.currentPageSize,
      currentPage: this.currentPage,
      pageCount: this.pageCount,
      selectedRowCount: 0,
      totalRowCount: 0,
      selectedColId: this.selectedColId,
      fullColNameList: this.allColHeaders.map((col) => {
        return { id: col.id, name: col.name };
      }),
      onPageNumberClicked: this.onPageNumberClicked,
      onPageSizeClicked: this.onPageSizeClicked,
      onRowClicked: this.onRowClicked,
      onRowSelected: this.onRowSelected,
      onRowUnselected: this.onRowUnselected,
      onColFiltered: this.onColFiltered,
      onFilterChanged: this.onFilterChanged,
      onFilterClear: this.onFilterClear,
      enableStickyHeader: false,
      filterDebounceTime: Constants.DEBOUNCE_TIME,
      headers: this.allColHeaders,
      filter: {},
      content: [],
      highlightedRows: [],
      customClassRows: [],
    };

    this.initTableHeader();
  }

  initTableHeader() {
    this.allColHeaders = [
      {
        id: 'objId',
        name: '',
        enableSort: false,
        enableFilter: false,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'd-none ',
      },
      {
        id: 'vendorNumber',
        name: 'pages.settings-contractor.table-column.contractor-number',
        enableSort: false,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col custom-column',
      },
      {
        id: 'vendorName',
        name: 'pages.settings-contractor.table-column.contractor-name',
        enableSort: false,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col custom-column',
      },
      {
        id: 'status',
        name: 'pages.settings-contractor.table-column.status',
        enableSort: false,
        enableFilter: true,
        filterType: TablexColumnFilterType.Dropdown,
        filterDropdownOption: this.statusListOptions,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col custom-column',
      },
      {
        id: 'prefix',
        name: 'pages.settings-contractor.table-column.prefix',
        enableSort: false,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col custom-column',
      },
      {
        id: 'responsibleOfficer',
        name: 'pages.settings-contractor.table-column.responsible-officer',
        enableSort: false,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col custom-column',
      },
      {
        id: 'telephone',
        name: 'pages.settings-contractor.table-column.hotline',
        enableSort: false,
        enableFilter: true,
        type: TablexColumnType.Text,
        horizontalAlign: TablexColumnHorizontalAlign.Center,
        verticalAlign: TablexColumnVerticalAlign.Bottom,
        class: 'col custom-column',
      }
    ];
    this.selectedCol = this.allColHeaders;
  }
}
