import { Component, OnInit, Injector } from '@angular/core';
import { JM, JMENUM } from '@ccep/CCEPConnector-ts';
import { Constants } from 'src/constants';
import * as _ from 'underscore';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { UserHelper } from 'src/app/core/user-helper';

@Component({
  selector: 'app-settings-role-management',
  templateUrl: './settings-role-management.component.html',
  styleUrls: ['./settings-role-management.component.scss'],
})
export class SettingsRoleManagementComponent implements OnInit {
  roleList: any[] = [];
  permissionList: string[];
  availablePermissionList: string[];
  selectedRole: any;
  selectedRoleId: number;
  permissionOnDrag: string;
  dropOnArea: string;
  dragFromArea: string;
  userCanEdit = UserHelper.hasPermission(JMENUM.VpPermission.ROLE_UPDATE);
  isLoading = {
    getAllRoles: false,
    getAllPermissions: false,
    updatePermissions: false,
  };

  constructor() {}

  ngOnInit(): void {
    if (!AppDelegate.checkPagePermissions()) {
      return;
    }
    this.requestAllRoles();
    this.requestAllPermissions();
  }

  onSelectRole(role) {
    this.selectedRole = role;
    this.selectedRoleId = role.roleId;
    this.updateAvailablePermissions();
  }

  updateAvailablePermissions() {
    if (this.selectedRole) {
      this.availablePermissionList = _.difference(this.permissionList, this.selectedRole.permissions);
    }
  }

  onDragStart(area) {
    this.dragFromArea = area;
  }

  onDrag() {}

  onDragEnter(area) {
    this.dropOnArea = area;
  }

  onDragEnd() {
    if (this.userCanEdit) {
      const permissions = this.selectedRole.permissions;
      if (this.dragFromArea !== this.dropOnArea) {
        if (this.dropOnArea === 'givenPermission') {
          permissions.push(this.permissionOnDrag);
        }
        if (this.dropOnArea === 'availablePermission') {
          permissions.splice(permissions.indexOf(this.permissionOnDrag), 1);
        }
        this.requestAllPermissionsByRole(permissions);
      }
    }
    this.permissionOnDrag = null;
    this.dragFromArea = null;
    this.dropOnArea = null;
  }

  requestAllRoles = () => {
    if (this.isLoading.getAllRoles) {
      return;
    }
    this.isLoading.getAllRoles = true;

    const request = new JM.JMRequestAuthRoleSummary();
    request.systemName = [Constants.SYSTEM_NAME];

    JM.JMConnector.sendAuthRoleSummary(request, (error: JM.JMNetworkError, response: JM.JMResponseAuthRoleSummary) => {
      if (error) {
        AppDelegate.handleJMError(error);
      } else if (response && response.code === 200) {
        this.roleList = response.payload.records;

        if (!this.selectedRole) {
          this.selectedRole = this.roleList[0];
          this.selectedRoleId = this.selectedRole.roleId;
        }
        this.updateAvailablePermissions();
      } else {
        AppDelegate.toastMsg().showResponseMsg(response);
      }
      this.isLoading.getAllRoles = false;
    });
  };

  requestAllPermissions = () => {
    if (this.isLoading.getAllPermissions) {
      return;
    }
    this.isLoading.getAllPermissions = true;

    const request = new JM.JMRequestPermissions();
    request.systemName = Constants.SYSTEM_NAME;

    JM.JMConnector.sendPermissions(request, (error: JM.JMNetworkError, response: any) => {
      if (error) {
        AppDelegate.handleJMError(error);
      } else if (response) {
        this.permissionList = response;
        this.updateAvailablePermissions();
      } else {
        AppDelegate.toastMsg().showResponseMsg(response);
      }
      this.isLoading.getAllPermissions = false;
    });
  };

  requestAllPermissionsByRole = (permissions) => {
    if (this.isLoading.updatePermissions && !this.userCanEdit) {
      return;
    }
    this.isLoading.updatePermissions = true;

    const request = new JM.JMRequestRolesPermissions();
    request.systemName = Constants.SYSTEM_NAME;
    request.roleId = this.selectedRole.roleId;
    request.permissions = permissions.sort();

    JM.JMConnector.sendRolesPermissions(
      request,
      (error: JM.JMNetworkError, response: JM.JMResponseRolesPermissions) => {
        if (error) {
          AppDelegate.handleJMError(error);
        } else if (response && response.code === 200) {
          this.requestAllRoles();
        } else {
          AppDelegate.toastMsg().showResponseMsg(response);
        }
        this.isLoading.updatePermissions = false;
      }
    );
  };
}
