import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JM, JMOBJ, JMREASON } from '@ccep/CCEPConnector-ts';
import { JMReason } from '@ccep/CCEPConnector-ts/lib/JMConnector/class/Reason';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';
import { Session } from 'src/app/core/session';
import { Constants } from 'src/constants';
@Component({
  selector: 'cm-create-follow-up-job-editor',
  templateUrl: './cm-create-follow-up-job-editor.component.html',
  styleUrls: ['./cm-create-follow-up-job-editor.component.scss']
})
export class CmCreateFollowUpJobEditorComponent implements OnInit {
  @Input() sn: JMOBJ.ServiceNotification;
  @Output() createdFollowUpJob = new EventEmitter<JMOBJ.JobCard>();

  // data
  createFollowUpForm = this.fb.group({
    followUpReason: [undefined, [Validators.required]],
    followUpRemarks: [''],
  });

  followUpReasonList: {
    code: number;
    label: string;
  }[] = [];

  submitBtn = {
    isLoading: false,
  };

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.resetFollowUpReasonList();
    this.resetForm();
  }

  private resetForm() {
    this.createFollowUpForm.reset({
      followUpReason: undefined,
      followUpRemarks: '',
    });
  }

  private resetFollowUpReasonList() {
    const filterCriteria = [JMREASON.Attribute.VP, JMREASON.Attribute.CM];
    const filterReasonList = JMREASON.JOB_FOLLOW_UP_REASON_LIST.filterReason(filterCriteria);

    this.followUpReasonList = this.getReasonList(filterReasonList);
  }

  private getReasonList(reasonList: JMReason[]) {
    return reasonList.map((reason) => {
      const description = reason.description;
      let label = '';
      if (description) {
        label = description[Session.selectedLanguage] ? description[Session.selectedLanguage] : description.en;
      }
      return {
        code: reason.code,
        label: label,
      };
    });
  }
  //------------------------
  // API
  private async requestCompleteCmTask() {
    let request = new JM.JMRequestJobCardsCreateVpFollowUp();
    request.snNumber = this.sn.snNumber;
    request.snVersion = this.sn.version;
    request.followUpReasonCode = this.followUpReason.value;
    request.followUpRemarks = this.followUpRemarks.value.trim() || undefined;

    this.submitBtn.isLoading = true;
    const response: JM.JMResponseJobCardsCreateVpFollowUp = await AppDelegate.sendJMRequest(request);
    this.submitBtn.isLoading = false;

    if (!response || !response.code || response.code !== 200 || !response.payload || !response.payload.jobCard) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.created'));
    this.createdFollowUpJob.emit(response.payload.jobCard);
    this.resetForm();
  }

  //------------------------
  // button callback
  onSubmitBtnClicked() {
    const data = {
      msg: {
        content: 'component.cm-create-follow-up-job-editor.pop-up.confirm-to-create',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestCompleteCmTask();
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(data);
  }

  //------------------------
  // get property
  get followUpReason() {
    return this.createFollowUpForm.get('followUpReason');
  }
  get followUpRemarks() {
    return this.createFollowUpForm.get('followUpRemarks');
  }

  get inputBoxMaxLength() {
    return Constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }

}
