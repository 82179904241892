<div class="wrapper">
  <app-nav [navbarTitle]="'component.settings-contract-severity-list.title' | translate"></app-nav>
  <app-sidebar [contentType]="'settings'"></app-sidebar>

  <div id="content" class="content">
    <app-tab-menu [contentType]="'settings-contract'"></app-tab-menu>

    <div class="row">
      <!-- TODO: Phrase 2 - move left layout to component -->
      <div class="col-12 col-xl-6">
        <contract-dropdown [contractParameterList]="contractParamList" (assignedContract)="onAssignedContract($event)"></contract-dropdown>
        <hr class="underline mt-2" />
      </div>
      <div class="col-12 col-xl-6">
        <app-contract-severity-list-table #severity_list_table [hasUpdatePermission]="hasUpdatePermission" [contract]="selectedContract"></app-contract-severity-list-table>
    
      </div>
    </div>

  </div>
</div>