<div class="calendar-input-wrap position-relative">
  <input type="text"
    #customDatePicker="ngbDatepicker"
    class="form-control date-picker-input-box"
    name="customDatePicker"
    ngbDatepicker
    placeholder="YYYY-MM-DD"
    autocomplete="off"
    maxlength="10"
    [id]="inputId"
    [class.input-error]="isErrorField"
    [ngClass]="customClass"
    [(ngModel)]="input"
    (click)="customDatePicker.toggle();onClickDatePicker(inputId)"
    (blur)="onBlurInput()"
    (ngModelChange)="formatDate(input)"
    [disabled]="disabled"
  >
  <button class="btn calendar" (click)="customDatePicker.toggle();onClickDatePicker(inputId)" type="button">
      <i class="fa fa-calendar" aria-hidden="true"></i>
  </button>
</div>