<div class="d-flex justify-content-between" >
  <div class="font-size-xl">
    {{ "component.cm-client-info.title" | translate }}
  </div>
</div>
<hr class="underline" />

<!-- Editable fields -->
<div class="row mb-3 mx-0 align-items-center">
  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    <span *ngIf="clientInput.isMandatory" class="red">*</span>
    {{ "component.cm-client-info.label.client" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <ng-container *ngIf="isCreateMode || isEditMode; else displayClientText">
      <ng-select class="px-0" id="edit-cm-client-info-client"
        bindLabel="label"
        bindValue="value"
        [class.input-error]="clientInput.isError"
        [items]="clientInput.optionList"
        [loading]="clientInput.isLoading"
        [disabled]="!sn?.client"
        [(ngModel)]="clientInput.value"
        (change)="onChangedClient()"
        (scrollToEnd)="onClientScrollToEnd()" 
        (search)="onSearchClient($event)"
        (clear)="onClearClient()"
      >
      </ng-select>
    </ng-container>
    <ng-template #displayClientText>
      <app-display-text-field id="cm-client-info-client" [textField]="clientInfo?.label">
      </app-display-text-field>
    </ng-template>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label ">
    <span *ngIf="contactNumberInput.isMandatory" class="red">*</span>
    {{ "component.cm-client-info.label.contact-number" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <ng-container *ngIf="isCreateMode; else displayContactNumberText">
      <input id="edit-cm-client-info-contact-number" class="form-control" type="text" maxlength="8"
      [class.input-error]="contactNumberInput.isError" [(ngModel)]="contactNumberInput.value"
      (blur)="inputNumber($event)" (keyup)="inputNumber($event)"
        />
    </ng-container>
    <ng-template #displayContactNumberText>
      <app-display-text-field id="cm-client-info-contact-number" [textField]="sn?.contactNumber">
      </app-display-text-field>
    </ng-template>
  </div>
  

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label">
    <span *ngIf="contactPersonInput.isMandatory" class="red">*</span>
    {{ "component.cm-client-info.label.contact-person" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <ng-container *ngIf="isCreateMode; else displayContactPersonText">
      <input id="edit-cm-client-info-contact-person" class="form-control" type="text" [maxlength]="textAreaMaxLength"
        [class.input-error]="contactPersonInput.isError" [(ngModel)]="contactPersonInput.value" />
    </ng-container>
    <ng-template #displayContactPersonText>
      <app-display-text-field id="cm-client-info-contact-person" [textField]="sn?.contactPerson">
      </app-display-text-field>
    </ng-template>
  </div>

  <div class="col-3 col-md-2 mt-4 p-0 text-right font-size-m gray-label ">
    <span *ngIf="emailInput.isMandatory" class="red">*</span>
    {{ "component.cm-client-info.label.email" | translate }}
  </div>
  <div class="col-9 col-md-4 mt-4">
    <ng-container *ngIf="isCreateMode; else displayEamilText">
      <input id="edit-cm-client-info-email" class="form-control" type="text" [maxlength]="textAreaMaxLength"
        [class.input-error]="emailInput.isError" [(ngModel)]="emailInput.value" (change)="onChangedEmail()"
      />
    </ng-container>
    <ng-template #displayEamilText>
      <app-display-text-field id="cm-client-info-email" [textField]="sn?.email">
      </app-display-text-field>
    </ng-template>
  </div>