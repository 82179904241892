export const SidebarItems = {
  main: [
    {
      id: 'sidebar-item-cm-task',
      title: 'sidebar.item.cm-task',
      key: 'cmTask',
      type: 'normal',
      // route: '/cm-task',
      subItems: [
        {
          id: 'sidebar-subitem-cm-task-create',
          key: 'cmTaskCreate',
          title: 'sidebar.subitem.cm-task-create',
          route: '/cm-task/create',
        },
        {
          id: 'sidebar-subitem-pending-notification',
          key: 'cmTaskPendingNotification',
          title: 'sidebar.subitem.pending-notification',
          route: '/pending-notification',
        },
        {
          id: 'sidebar-subitem-cm-task-list',
          key: 'cmTaskList',
          title: 'sidebar.subitem.cm-task-list',
          route: '/cm-task',
        },
      ],
    },
    // {
    //   id: 'sidebar-item-pm-job-standard',
    //   title: 'sidebar.item.pm-job-standard',
    //   key: 'pmTaskStandard',
    //   type: 'normal',
    //   route: '/pm/jobs',
    //   subItems: [
    //     {
    //       id: 'sidebar-subitem-pm-task-create',
    //       key: 'pmTaskCreate',
    //       title: 'sidebar.subitem.pm-job-standard-create',
    //       route: '/pm/jobs/create',
    //     },
    //   ],
    // },
    // {
    //   id: 'sidebar-item-pm-job-compliance',
    //   title: 'sidebar.item.pm-job-compliance',
    //   key: 'pmTaskCompliance',
    //   type: 'normal',
    //   route: '/pm/jobs',
    //   subItems: [
    //     {
    //       id: 'sidebar-subitem-pm-task-create',
    //       key: 'pmTaskCreate',
    //       title: 'sidebar.subitem.pm-job-compliance-create',
    //       route: '/pm/jobs/create',
    //     },
    //   ],
    // },
    {
      id: 'sidebar-item-pm-plans',
      title: 'sidebar.item.pm-plans',
      key: 'pmPlans',
      type: 'normal',
      // route: '/pm/plans',
      subItems: [
        {
          id: 'sidebar-subitem-pm-plans-standard-create',
          key: 'pmPlansCreate',
          title: 'sidebar.subitem.pm-plans-standard-create',
          route: '/pm/plans/create',
        },
        // {
        //   id: 'sidebar-subitem-pm-plans-compliance-create',
        //   key: 'pmPlansCreate',
        //   title: 'sidebar.subitem.pm-plans-compliance-create',
        //   route: '/pm/plans/create',
        // },
        {
          id: 'sidebar-subitem-pm-task-create',
          key: 'pmTaskCreate',
          title: 'sidebar.subitem.pm-job-standard-create',
          route: '/pm/jobs/create',
        },
        {
          id: 'sidebar-subitem-pm-plans-list',
          key: 'pmPlansList',
          title: 'sidebar.subitem.pm-plans-list',
          route: '/pm/plans',
        },
        {
          id: 'sidebar-subitem-pm-period-list',
          key: 'pmPeriodList',
          title: 'sidebar.subitem.pm-period-list',
          route: '/pm/periods',
        },
        {
          id: 'sidebar-subitem-pm-job-list',
          key: 'pmJobList',
          title: 'sidebar.subitem.pm-job-list',
          route: '/pm/jobs',
        },
      ],
    },
    // {
    //   id: 'sidebar-item-reports',
    //   title: 'sidebar.item.reports',
    //   key: 'reports',
    //   type: 'normal',
    //   route: '/reports',
    // },
  ],

  settings: [
    {
      id: 'sidebar-item-settings',
      title: 'sidebar.item.settings',
      key: 'settings',
      type: 'normal',
      route: '/settings',
      subItems: [
        {
          id: 'sidebar-subitem-general',
          key: 'settingsGeneral',
          title: 'sidebar.subitem.general',
          route: '/settings/general',
        },
        {
          id: 'sidebar-subitem-contract',
          key: 'settingContract',
          title: 'sidebar.subitem.contract',
          route: '/settings/contract',
        },
        {
          id: 'sidebar-subitem-contractor',
          key: 'settingsContractor',
          title: 'sidebar.subitem.contractor',
          route: '/settings/contractor-management'
        },
        {
          id: 'sidebar-subitem-user',
          key: 'settingsUser',
          title: 'sidebar.subitem.user',
          route: '/settings/user',
        },
        {
          id: 'sidebar-subitem-emsd-user',
          key: 'settingsEmsdUser',
          title: 'sidebar.subitem.emsd-user',
          route: '/settings/emsd-user',
        },
        {
          id: 'sidebar-subitem-team',
          key: 'settingsTeam',
          title: 'sidebar.subitem.team',
          route: '/settings/team',
        },
        {
          id: 'sidebar-subitem-role',
          key: 'settingRole',
          title: 'sidebar.subitem.role',
          route: '/settings/role',
        },
      ],
    },
  ],
};
