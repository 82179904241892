import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JM, JMOBJ, JMENUM, JMREASON } from '@ccep/CCEPConnector-ts';
import { Session } from 'src/app/core/session';
import { AppDelegate } from 'src/app/core/AppDelegate';
import { StringValidator } from 'src/app/core/string-validator';
import { Constants } from 'src/constants';
import { JMLanguage } from 'src/app/core/JMLanguage/JMLanguage';

@Component({
  selector: 'sn-reject-editor',
  templateUrl: './sn-reject-editor.component.html',
  styleUrls: ['./sn-reject-editor.component.scss'],
})
export class SnRejectEditorComponent implements OnInit {
  @Input() sn: JMOBJ.ServiceNotification;
  @Output() updatedSn = new EventEmitter<{
    sn: JMOBJ.ServiceNotification, 
    actionList: JMENUM.VpSnAction[]
  }>();

  // data
  rejectForm = this.fb.group({
    reason: [undefined, [Validators.required]],
    remarks: ['', [Validators.required, StringValidator.noWhiteSpaceBothEnd]],
  });

  reasonList: {
    code: number;
    label: string;
  }[] = [];

  submitBtn = {
    isLoading: false,
  };

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.getReasonList();
    this.resetForm();
  }

  private resetForm() {
    this.rejectForm.reset({
      reason: undefined,
      remarks: '',
    });
  }

  private getReasonList() {
    const filterCriteria = [JMREASON.Attribute.VP, JMREASON.Attribute.CM];
    const filterReasonList = JMREASON.SN_REJECT_REASON_LIST.filterReason(filterCriteria);
    this.reasonList = filterReasonList.map((reason) => {
      const description = reason.description;
      let label = '';
      if (description) {
        label = description[Session.selectedLanguage] ? description[Session.selectedLanguage] : description.en;
      }
      return {
        code: reason.code,
        label: label,
      };
    });
  }

  //------------------------
  // API
  private async requestRejectSn() {
    let request = new JM.JMRequestSnRejectVpSn();
    request.snNumber = this.sn.snNumber;
    request.version = this.sn.version;
    request.rejectCode = this.reason.value;
    request.rejectRemarks = this.remarks.value.trim() || undefined;

    this.submitBtn.isLoading = true;
    const response: JM.JMResponseSnRejectVpSn = await AppDelegate.sendJMRequest(request);
    this.submitBtn.isLoading = false;

    if (response?.code !== 200 || !response?.payload?.sn) {
      AppDelegate.toastMsg().showResponseMsg(response);
      return;
    }

    this.sn = response.payload.sn;
    AppDelegate.toastMsg().showMsg(JMLanguage.translate('global.rejected'));
    
    this.updatedSn.emit({
      sn: this.sn,
      actionList: response.payload.actionList
    });
    this.resetForm();
  }

  //------------------------
  // button callback
  onSubmitBtnClicked() {
    const data = {
      msg: {
        content: 'component.sn-reject-editor.pop-up.confirm-to-reject',
      },
      buttons: [
        {
          name: 'global.yes',
          handler: () => {
            this.requestRejectSn();
          },
        },
        {
          name: 'global.no',
          handler: null,
        },
      ],
    };
    AppDelegate.popUpDialog().open(data);
  }

  //------------------------
  // get property
  get reason() {
    return this.rejectForm.get('reason');
  }
  get remarks() {
    return this.rejectForm.get('remarks');
  }

  get inputBoxMaxLength() {
    return Constants.REMARKS_TEXTAREA_MAX_LENGTH;
  }
}
